import { salesTransactionEmployeeFetchFailure, salesTransactionEmployeeFetchSuccess, salesTransactionFetchFailure, salesTransactionFetchStart, salesTransactionFetchSuccess, salesTransactionsSummaryFetchFailure, salesTransactionsSummaryFetchStart, salesTransactionsSummaryFetchSuccess } from './transaction.actions';
import { createReducer, on } from '@ngrx/store';
import { initialState } from './transaction.state';

const _salesTransactionReducer = createReducer(
    initialState,
    on(salesTransactionsSummaryFetchStart, (state) => {
        return {
            ...state,
            showLoader: true
        };
    }),
    on(salesTransactionsSummaryFetchSuccess, (state, action) => {
        return {
            ...state,
            data: action.data,
            meta: action.meta,
            showLoader: false
        };
    }),
    on(salesTransactionsSummaryFetchFailure, (state, action) => {
        return {
            ...state,
            showLoader: false
        };
    }),
    on(salesTransactionEmployeeFetchSuccess, (state, action) => {
        return {
            ...state,
            employee: action.employee,
            activeEmployees: action.activeEmployees
        };
    }),
    on(salesTransactionEmployeeFetchFailure, (state, action) => {
        return {
            ...state,
            showLoader: false
        };
    }),
    on(salesTransactionFetchStart, (state) => {
        return {
            ...state,
            showLoader: true
        };
    }),
    on(salesTransactionFetchSuccess, (state, action) => {
        return {
            ...state,
            transactions: action.transactions,
            pageMetaData: action.pageMetaData,
            showLoader: false
        };
    }),
    on(salesTransactionFetchFailure, (state, action) => {
        return {
            ...state,
            showLoader: false
        };
    }),
);

export function SalesTransactionReducer(state, action) {
    return _salesTransactionReducer(state, action);
}