import { AuthRes } from "src/app/core/models/auth.models";

export interface AuthState {
  user: AuthRes | null;
  showLoader: Boolean
  loginTry: any
}

export const initialState: AuthState = {
  user: null,
  showLoader: false,
  loginTry: 0
};