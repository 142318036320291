<div class="w-100">
  <div class="row">
    <div class="col-lg-12 p-0">
      <div class="card">
        <div class="card-body padd1670">
          <div class="mt-2 mb-2 rstin mb-3">
            <h6 class="f-14 fw-600">Release Version</h6>
          </div>
          <div class="row">
            <div class="col-lg-7">
              <div class="row mb-3" *ngFor="let versionObj of dummyData">
                <div class="info-box">
                  <div class="iconbox">
                    <img src="../../../../assets/images/arrow.png" alt="" height="18">
                  </div>
                  <div>
                    <div>{{versionObj.version}}</div>
                    <div> {{versionObj.description||"NA"}}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
