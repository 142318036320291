import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AuthState } from './auth.state';
export const AUTH_STATE_NAME = 'auth';

const getAuthState = createFeatureSelector<AuthState>(AUTH_STATE_NAME);


//selector for getting userAuthentication state 
export const isAuthenticated = createSelector(getAuthState, (state) => {
  return state.user ? true : false;
});
//selector for getting userAuthentication state 
export const getLoginTry = createSelector(getAuthState, (state) => {
  return state.loginTry
});

//selector for getting loading status
export const getLoading = createSelector(getAuthState, (state) => {
  return state.showLoader
} )

//selector for getting token
export const getAccessToken = createSelector(getAuthState, (state) => {
  return state.user ? state.user.response.token : null;
});