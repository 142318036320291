import { Injectable } from '@angular/core';
import * as moment from 'moment-timezone';
@Injectable({
  providedIn: 'root'
})

/**
 * @Refactor This a refactor code of timezoneService
 */
export class TimezoneService {
  
  private storageListener:boolean = false
  private momment: any;

  constructor() {
    if(!this.storageListener){
      this.momment = moment
      const intervalId = setInterval(() => {
        const timezone = localStorage.getItem('Timezone')
        if (timezone && timezone != '') {
          this.momment.tz.setDefault(timezone);
          clearInterval(intervalId)
        } else {
          this.momment.tz.setDefault();
        }
      }, 1000)
      window.addEventListener('storage', this.storageEventListener.bind(this));
    }
  }

  // Function to handle the storage event
  storageEventListener(event: StorageEvent) {
    this.storageListener = true
    // Check if the event is for localStorage
    if (event.storageArea === localStorage) {
      console.log(event.key)
      if(event.key === 'Timezone'){
        const timezone = localStorage.getItem(event.key)
        if (timezone && timezone != '') {
          this.momment.tz.setDefault(timezone);
        } else {
          this.momment.tz.setDefault();
        }
      }
    }
  }
  
  get = () => this.momment
  
  ngOnDestroy() {
    window.removeEventListener('storage', this.storageEventListener.bind(this));
  }

}

// import { Injectable } from '@angular/core';
// import * as moment from 'moment-timezone';
// import { CookieService } from "ngx-cookie-service";
// @Injectable({
//   providedIn: 'root'
// })
// export class TimezoneService {

//   momment: any;
//   tz
//   constructor(private cookieService: CookieService) {
//     // Get the initial value of the cookie.
//     this.tz = this.cookieService.get('timezone');

//     // Set up an interval that periodically checks for changes in the cookie value.
//     setInterval(() => {
//       const currentCookieValue = this.cookieService.get('timezone');

//       if (currentCookieValue !== this.tz) {
//         // Do something if the cookie value has changed.
//         // console.log('The cookie value has changed:', currentCookieValue);
//         this.tz = currentCookieValue;
//       }
//     }, 1000); // Check for changes every second.
//     this.momment = moment;
//   }


//   get():any {

//     if (this.tz && this.tz != '') {
//       moment.tz.setDefault(this.cookieService.get('timezone'));
//     } else {
//       moment.tz.setDefault();
//     }

//     return this.momment
//   }

// }

// import { Injectable, OnDestroy } from '@angular/core';
// import * as moment from 'moment-timezone';
// import { CookieService } from 'ngx-cookie-service';

// @Injectable({
//   providedIn: 'root'
// })
// export class TimezoneService implements OnDestroy {
//   private intervalId: any;
//   public momment: any;
//   private tz: string;

//   constructor(private cookieService: CookieService) {
//     this.momment = moment;
//     this.tz = this.cookieService.get('timezone');
//     this.setDefaultTimezone();

//     // Set up an interval that periodically checks for changes in the cookie value.
//     this.intervalId = setInterval(() => {
//       const currentCookieValue = this.cookieService.get('timezone');
//       if (currentCookieValue !== this.tz) {
//         this.tz = currentCookieValue;
//         this.setDefaultTimezone();
//       }
//     }, 1000); // Check for changes every second.
//   }

//   private setDefaultTimezone(): void {
//     if (this.tz && this.tz !== '') {
//       this.momment.tz.setDefault(this.tz);
//     } else {
//       this.momment.tz.setDefault();
//     }
//   }

//   get(): any {
//     console.log(this.tz, this.cookieService.get('timezone'))
//     return this.momment;
//   }

//   ngOnDestroy(): void {
//     if (this.intervalId) {
//       clearInterval(this.intervalId);
//     }
//   }
// }