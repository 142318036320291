import { Injectable } from "@angular/core";
import { AuthenticationService } from "./auth.service";

@Injectable({
  providedIn: "root",
})
export class PermissionService {

    private permissions:any = []

    constructor(private authService:AuthenticationService){
        this.initLocalValues()
    }
    
    /**
     * Initializes local values including user permissions.
     * @private
     * @returns {void}
     */
    private initLocalValues(): void {
        const user = this.authService.getUserFromLocalStorage();

        if(user){
            this.permissions = user.response.role.permissions;
        }
    }

    /**
     * Checks if the user has a specific permission.
     * @param {string} permissionName - The name of the permission to check.
     * @returns {boolean} - Returns true if the user has the permission, false otherwise.
     * @public
     * @example
     * const canEdit = this.hasPermission('edit');
     * if (canEdit) {
     *    // User can edit
     * } else {
     *    // User cannot edit
     * }
     */
    public hasPermission(permissionName: string): boolean {
        if(this.permissions.length){
            return !!this.permissions.find(permission => permission.permissionName === permissionName);
        } else {
            return false;
        }
    }

    /**
     * Retrieves the value of a specific permission.
     * @param {string} permissionName - The name of the permission.
     * @returns {number} - The value of the permission. Returns 0 if the permission is not found.
     * @public
     * @example
     * const editPermissionValue = this.getPermissionValue('edit');
     * console.log(editPermissionValue); // Outputs the value of the 'edit' permission
     */
    public getPermissionValue(permissionName: string): number {
        if(this.permissions.length){
            const found = this.permissions.find(permission => permission.permissionName === permissionName);
            return found ? found.permissionValue : 0;
        } else {
            return 0;
        }
    }

      /**
     * Checks if the user has access to a specific permission with a certain value.
     * If a value is provided, it checks whether the permission value is greater than that value.
     * If no value is provided, it checks if the user has the permission with any non-zero value.
     * @param {string} permissionName - The name of the permission to check.
     * @param {number} [value] - The value against which the permission is checked. Optional.
     * @returns {boolean} - Returns true if the user has access to the permission, false otherwise.
     * @public
     * @example
     * const canAccessEditWithValue = this.canAccess('edit', 5);
     * if (canAccessEditWithValue) {
     *    // User can edit with a value greater than 5
     * } else {
     *    // User cannot edit with a value greater than 5
     * }
     * const canAccessEdit = this.canAccess('edit');
     * if (canAccessEdit) {
     *    // User can edit with any non-zero value
     * } else {
     *    // User cannot edit
     * }
     */
    public canAccess(permissionName: string, value?: any){

        const hasPermission = this.hasPermission(permissionName)
        const permissionValue = this.getPermissionValue(permissionName)

        const valueArray = Array.isArray(value)

        if(valueArray && value.length > 1){
            return hasPermission && value.includes(permissionValue) 
        }else{
            if(valueArray) value = value[0]
            return value > -1 ?
            hasPermission && permissionValue === value :
            hasPermission && permissionValue > 0
        }
    }

  
}