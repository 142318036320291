import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SalesOrderEffect } from './pages/sales/orders/state/salesorders.effects';

import { environment } from '../environments/environment';

import {
  NgbAccordionModule,
  NgbModule,
  NgbNavModule,
  NgbTooltipModule
} from '@ng-bootstrap/ng-bootstrap';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { ExtrapagesModule } from './extrapages/extrapages.module';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { initFirebaseBackend } from './authUtils';
import { LayoutsModule } from './layouts/layouts.module';

import { NgOptimizedImage } from '@angular/common';
import { ErrorHandler } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { DataTablesModule } from "angular-datatables";
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { NgcCookieConsentConfig } from 'ngx-cookieconsent';
import { ToastrModule } from 'ngx-toastr';
import { ReleaseVersion } from './account/auth/release-version/release-version.component';
import { AuthEffects } from './account/auth/state/auth.effects';
import { EmptyScreenComponent } from './components/empty-screen/empty-screen.component';
import { ErrorInterceptor } from './core/helpers/error.interceptor';
import { FakeBackendInterceptor } from './core/helpers/fake-backend';
import { JwtInterceptor } from './core/helpers/jwt.interceptor';
import { OnlyNumber } from './core/pipes/onlynumber.directive';
import { LocationService } from './core/services/location.service';
import { GraphQLModule } from './graphql.module';
import { SalesTransactionEffect } from './pages/saletransactions/state/transaction.effects';
import { ApiService } from './services/api';
import { TimezoneService } from './services/timezone';
import { LocationEffects } from './shared/ui/locationstate/location.effects';
import { appReducer, metaReducers } from './store/app.state';
// import { PrimeNGConfig } from 'primeng/api';
// import { DropdownModule } from 'primeng/dropdown';
import { MultiSelectModule } from 'primeng/multiselect';
// Import the module from the SDK for AuthO
import { AuthHttpInterceptor, AuthModule } from '@auth0/auth0-angular';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import * as Sentry from "@sentry/angular-ivy";
import { CoreModule } from './core/core.module';
import { CustomErrorHandler } from './core/services/errors/custom-error-handler';
if (environment.defaultauth === 'firebase') {
  initFirebaseBackend(environment.firebaseConfig);
} else {
  // tslint:disable-next-line: no-unused-expression
  FakeBackendInterceptor;
}

export function createTranslateLoader(http: HttpClient): any {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}
const cookieConfig: NgcCookieConsentConfig = {
  cookie: {
    domain: 'localhost' // or 'your.domain.com' // it is mandatory to set a domain, for cookies to work properly (see https://goo.gl/S2Hy2A)
  },
  palette: {
    popup: {
      background: '#000'
    },
    button: {
      background: '#f1d600'
    }
  },
  theme: 'edgeless',
  type: 'opt-out'
};

// @dynamic
@NgModule({
  declarations: [
    AppComponent,
    EmptyScreenComponent,
    OnlyNumber,
    ReleaseVersion,
    // SearchbarComponent,
  ],
  imports: [
    BrowserModule,
    NgOptimizedImage,
    NgbModule,
    MatDialogModule,
    FormsModule,
    CKEditorModule,
    ReactiveFormsModule,
    DataTablesModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MultiSelectModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }),
    LayoutsModule,
    AppRoutingModule,
    ToastrModule.forRoot({
      preventDuplicates: true,
      positionClass: 'toast-bottom-right',
    }),
    EffectsModule.forRoot([AuthEffects, LocationEffects, SalesTransactionEffect, SalesOrderEffect]),
    StoreModule.forRoot(appReducer, { metaReducers }),
    StoreDevtoolsModule.instrument({
      logOnly: environment.production
    }),
    ExtrapagesModule,
    CarouselModule,
    NgbAccordionModule,
    NgbNavModule,
    NgbTooltipModule,
    ScrollToModule.forRoot(),
    NgMultiSelectDropDownModule.forRoot(),
    NgbModule,
    GraphQLModule,
    // Import the module into the application, with configuration
    AuthModule.forRoot({
      ...environment.auth,
      // httpInterceptor: {
      //   ...environment.httpInterceptor,
      // },
    }),
    // AuthModule.forRoot({
    //   domain: 'dev-od0fnhvugbhw5orl.us.auth0.com',
    //   clientId: 'fUGyfCJEiGgghUndQclk77dVUWi2B4hI',
    //   authorizationParams: {
    //     redirect_uri: window.location.origin
    //   }
    // }),
    CoreModule
  ],
  bootstrap: [AppComponent],
  providers: [
    ApiService,
    TimezoneService,
    LocationService,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: FakeBackendInterceptor, multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthHttpInterceptor,
      multi: true,
    },
    // {
    //   provide: ErrorHandler,
    //   useValue: Sentry.createErrorHandler({
    //     showDialog: false,
    //   }),
    // },
    { provide: ErrorHandler, useClass: CustomErrorHandler },
  ]
})

export class AppModule { }
