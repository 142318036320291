import { debounce } from "@agentepsilon/decko";
import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem,
} from "@angular/cdk/drag-drop";
import { DOCUMENT } from "@angular/common";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
} from "@angular/core";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import {
  NgbModal,
  NgbModalConfig,
  NgbOffcanvas,
} from "@ng-bootstrap/ng-bootstrap";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import MetisMenu from "metismenujs";
import { CookieService } from "ngx-cookie-service";
import { Subject, interval } from "rxjs";
import { filter, shareReplay, takeUntil } from "rxjs/operators";
import {
  DragAndDropManagerDirective,
  DragAndDropManagerRootDirective,
} from "src/app/core/directives/drag-and-drop-manager.directive";
import { AlertService } from "src/app/core/services/AlertService/alert.service";
import { AppAliasService } from "src/app/core/services/app-alias.service";
import { AuthenticationService } from "src/app/core/services/auth.service";
import { MenuService } from "src/app/core/services/menu.service";
import { ApiService } from "src/app/services/api";
import { AppState } from "src/app/store/app.state";
import { setLoadingSpinner } from "src/app/store/sharedstate/shared.actions";
import { EventService } from "../../core/services/event.service";
import { NEW_MENU } from "./menu";
import { MenuItem } from "./menu.model";
import { DropInfo } from "./sub-item/data";
export type Item = {
  id: string;
  name: string;
  children: Array<Item>;
};
@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.scss"],
  providers: [DragAndDropManagerDirective, DragAndDropManagerRootDirective],
})

/**
 * Sidebar component
 */
export class SidebarComponent implements OnInit, AfterViewInit, OnChanges,OnDestroy {
  private destroy$ = new Subject<void>();
  @Output() event: EventEmitter<any> = new EventEmitter<any>();
  //new changes
  nodes: MenuItem[] = [];
  GLOBALURL: any;
  // ids for connected drop lists
  dropTargetIds = [];
  nodeLookup = {};
  dropActionTodo: DropInfo = null;
  //new changes
  ActiveclassName: any;
  public root: Item;
  @ViewChild("componentRef") scrollRef;
  @ViewChild("sidebarSetting", {}) private sidebarSetting: ElementRef;
  @Input() isCondensed = false;
  @Input() isMenuResetOnTestMode = false;
  @Input() SendCanavaDetails = false;
  menu: any;
  // data: any;
  menuItems: any = [];
  userSetting: MenuItem[] = [];

  @ViewChild("sideMenu") sideMenu: ElementRef;
  public data: Array<Item> = [];

  public invert: boolean = true;
  public onDragDrop$ = new Subject<CdkDragDrop<Array<Item>>>();
  currentRoute: string;
  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2,
    private toastr: AlertService,
    private store: Store<AppState>,
    @Inject(DOCUMENT) private document: Document,
    config: NgbModalConfig,
    private eventService: EventService,
    private router: Router,
    public translate: TranslateService,
    private http: HttpClient,
    private cookieService: CookieService,
    private modalService: NgbModal,
    private ApiService: ApiService,
    private auth: AuthenticationService,
    private menuservice: MenuService,
    private offcanvasService: NgbOffcanvas,
    private appAliasService: AppAliasService,
    private route: ActivatedRoute,
  ) {
    this.GLOBALURL = this.ApiService.getBaseURL();
    router.events.forEach((event) => {
      if (event instanceof NavigationEnd) {
        this._activateMenuDropdown();
        this._scrollElement();
      }
    });
    config.backdrop = "static";
    config.keyboard = false;
    // //Akshata
    this.nodes = NEW_MENU;
    this.prepareDragDrop(this.nodes);

    // this.root = { uId: '-1', name: 'root', children: DEMO_DATA } as Item;
  }

  headerToken = {
    "Content-Type": "application/json",
    Authorization: this.cookieService.get("token"),
  };
  httpOptions1 = { headers: new HttpHeaders(this.headerToken) };
  httpOptions2;
  user = JSON.parse(localStorage.getItem("userData"));
  appAlias: any;
  superAdminPage: any = this.cookieService.get("superAdminPage");
  // getBodyClass(): void {
  //   const bodyClasses = document.body?.classList;
  // }
  interval$ = interval(1000);
  ngOnInit() {
    // this.OnChangeSetActive();
    this.auth.runTimeoutInterval(this.user);
    this.currentRoute = this.router.url; // Get current route
       // Subscribe to router events to detect URL changes
       this.router.events.pipe(
        filter(event => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
        this.currentRoute = event.urlAfterRedirects; // Update current route on URL change
        this.updateChatWithUsLink(this.currentRoute); // Update menu items on URL change
    });
    // this.applyLabelStyles();
    // setTimeout(() => {
    //   this.SidebarActivemenu();
    // }, 3000);
    // setTimeout(() => {
    //   this.NewActiveMenu();
    // }, 0);
    // setInterval(() => {
    //   this.NewActiveMenu();
    //   this._SetSidebarResponsive();
    // }, 0);

    interval(1000)
    .pipe(takeUntil(this.destroy$))
    .subscribe(() => {
      this.NewActiveMenu();
      this._SetSidebarResponsive();
    });
    setTimeout(() => {

    }, 1000);
    this.initialize(NEW_MENU);
    // this.getMenuData();
    this.httpOptions2 = { headers: new HttpHeaders(this.headerToken) };
    this._scrollElement();
    this.data = [
      {
        id: "1",
        name: "Dashboard",
        children: [
          {
            id: "1-1",
            name: "Home",

            children: [],
          },
          {
            id: "1-2",
            name: "Analytics & Reports",
            children: [
              {
                id: "1-2-1",
                name: "Sales",
                children: [
                  {
                    id: "1-2-1-1",
                    name: "Sales 1",
                    children: [],
                  },
                  {
                    id: "1-2-1-2",
                    name: "Sales 2",
                    children: [],
                  },
                ],
              },
              {
                id: "1-2-2",
                name: "Exceptions",
                children: [],
              },
              {
                id: "1-2-3",
                name: "Deposits",
                children: [],
              },
              {
                id: "1-2-4",
                name: "Operations",
                children: [],
              },
              {
                id: "2-5",
                name: "Menu",
                children: [],
              },
            ],
          },
          {
            id: "1-3",
            name: "Sales",
            children: [
              {
                id: "1-3-1",
                name: "Transactions",
                children: [],
              },
              {
                id: "1-3-2",
                name: "Orders",
                children: [],
              },
              {
                id: "1-3-3",
                name: "Cash Drawers",
                children: [],
              },
              {
                id: "1-3-4",
                name: "Discounts",
                children: [],
              },
            ],
          },
        ],
      },
      // {
      //   id: '1-4',
      //   name: 'AI',
      //   children: [
      //     {
      //       id: '1-4-1',
      //       name: 'Product Price Comparision',
      //       children: []
      //     },
      //   ]
      // },
      {
        id: "2",
        name: "Restaurant Setup",
        children: [
          {
            id: "2-1",
            name: "Restaurant",
            children: [],
          },
        ],
      },
    ];
    this.iconeColorChane = this.AddClassName();
    this.RemoveunwantedClass();
    this.appAliasService.getValue().subscribe(value => {
      this.setManuChange(value) // replace with your logic
    });

  }
  ngOnDestroy() {

    this.destroy$.next();
    this.destroy$.complete();
  }
  updateChatWithUsLink(currentRoute: string) {
    this.menuItems = this.menuItems.map(item => {
        return {
            ...item,
            subItems: item?.subItems?.map(sub => {
                if (sub.label === "Chat with us") {
                    return {
                        ...sub,
                        link: currentRoute  // Update link with current route
                    }
                } else {
                    return sub;
                }
            })
        }
    });
}
  setManuChange(values: any) {
    this.appAlias = values;
    console.log("valuevaluevaluevalue", values, this.menuItems);
    let resturentLabel = this.appAlias.type === "eatos" ? "Restaurant" : "Store";
    let resturentsettingLabel = this.appAlias.type === "eatos" ? "Restaurant Settings" : "Store Settings";
    this.menuItems = this.menuItems.map(item => {
      return {
        ...item,
        subItems: item?.subItems?.map(sub => {
          if (sub.label === "Product Management")
            return {
              ...sub,
              visible: this.appAlias.productManagement
            }
          else if (sub.label === "Menu Management")
            return {
              ...sub,
              visible: this.appAlias.menuManagement
            }
          else if (sub.label === "Restaurant")
            return {
              ...sub,
              label: resturentLabel
            }
          else if (sub.label === "Store")
            return {
              ...sub,
              label: resturentLabel
            }
          else if (sub.label === "Restaurant Settings")
            return {
              ...sub,
              label: resturentsettingLabel
            }
          else if (sub.label === "Store Settings")
            return {
              ...sub,
              label: resturentsettingLabel
            }
          else if (sub.label === "Online Ordering")
            return {
              ...sub,
              visible: this.appAlias.onlineOrdering
            }
            else if (sub.label === "Chat with us")
              return {
                ...sub,
                link: this.currentRoute  // Ensure no redirection happens
              }
          else return sub
        })
      }
    })
  }

  getMenuData() {
    this.http
      .get<MenuItem[]>(
        this.GLOBALURL + "/user/setting?settingName=MENU_SETTING",
        this.httpOptions2
      )

      .subscribe(
        (resp: any) => {
          if (resp.success != 0) {
            if (resp.success == 1) {
              // debugger
              this.menuItems = JSON.parse(resp.response.settingValue);
              // this.userSetting = JSON.parse(resp.response.settingValue)
              console.log("resp====", this.menuItems);
              this.initialize(this.menuItems);
              this.nodes = this.menuItems; //this.userSetting;
              this.prepareDragDrop(this.nodes);
            }
          } else {
            this.initialize(NEW_MENU);
            this.nodes = NEW_MENU;
            this.prepareDragDrop(this.nodes);
          }
        },
        (error) => {
          this.nodes = NEW_MENU;
          this.prepareDragDrop(this.nodes);
        }
      );
  }

  openSidebarSettignModal() {
    this.modalService.open(this.sidebarSetting, { centered: true, size: "sm" });
  }

  redirectToHome() {
    // Redirect to the home page
    this.router.navigate(["/home"]);
  }

  navigateToHome(e: Event) {
    if ((e.target as HTMLElement).tagName === "LI") {
      // location.href = '/home'
      this.router.navigate(["/home"]);
    }
  }

  ngAfterViewInit() {
    this.menu = new MetisMenu(this.sideMenu.nativeElement);
    this._activateMenuDropdown();
    // this.getBodyClass();
  }

  toggleMenu(event) {
    event.currentTarget?.nextElementSibling?.classList?.toggle("mm-show");
  }

  chatWithUs(item) {
    if (item.label == "Chat with us") {
      // set window to access crisp object
      let Window: any = window;
      if (Window && Window.$crisp) {
        Window.$crisp.push(["do", "chat:show"]);
        Window.$crisp.push(["do", "chat:open"]);
        localStorage.setItem("crisp_chat_opened", "true");
      }
    }
  }

  ngOnChanges() {
    if ((!this.isCondensed && this.sideMenu) || this.isCondensed) {
      setTimeout(() => {
        this.menu = new MetisMenu(this.sideMenu.nativeElement);
        this._activateMenuDropdown();
      });
    } else if (this.menu) {
      this.menu.dispose();
    }
    if (this.isMenuResetOnTestMode || !this.isMenuResetOnTestMode) {
      setTimeout(() => {
        this._activateMenuDropdown();
      });
    }
  }
  _scrollElement() {
    setTimeout(() => {
      // if(this.document)
      if (document.getElementsByClassName("mm-active").length > 0) {
        const currentPosition =
          document.getElementsByClassName("mm-active")[0]["offsetTop"];
        if (currentPosition > 500) {
          if (this.scrollRef.SimpleBar !== null) {
            if(this.scrollRef && this.scrollRef.SimpleBar && this.scrollRef.SimpleBar.getScrollElement()) {
              this.scrollRef.SimpleBar.getScrollElement().scrollTop =
              currentPosition + 300;
            }

          }

        }

      }
    }, 300);
  }

  /**
   * remove active and mm-active class
   */
  _removeAllClass(className) {
    const els = document.getElementsByClassName(className);
    while (els[0]) {
      els[0].classList.remove(className);
    }
  }

  /**
   * Activate the parent dropdown
   */
  _activateMenuDropdown() {
    this._removeAllClass("mm-active");
    this._removeAllClass("mm-show");
    const links = document.getElementsByClassName("side-nav-link-ref");
    let menuItemEl = null;
    // tslint:disable-next-line: prefer-for-of
    const paths = [];
    for (let i = 0; i < links.length; i++) {
      paths.push(links[i]["hash"]);
    }
    var itemIndex = paths.indexOf(window.location.hash);
    if (itemIndex === -1) {
      if (window.location.hash.includes("update")) {
        let str = window.location.hash.split(
          "update/",
          window.location.hash.length - 1
        )[0];
        const strIndex = str.lastIndexOf("/");
        const item = window.location.hash.substr(0, strIndex).toString();
        menuItemEl = links[paths.indexOf(item)];
      } else {
        const strIndex = window.location.hash.lastIndexOf("/");
        const item = window.location.hash.substr(0, strIndex).toString();
        menuItemEl = links[paths.indexOf(item)];
      }
    } else {
      menuItemEl = links[itemIndex];
    }
    if (menuItemEl) {
      menuItemEl?.classList.add("active");
      const parentEl = menuItemEl?.parentElement;
      if (parentEl) {
        parentEl?.classList.add("mm-active");
        const parent2El = parentEl?.parentElement?.closest("ul");
        if (parent2El && parent2El.id !== "side-menu") {
          parent2El?.classList?.add("mm-show");
          const parent3El = parent2El?.parentElement;
          if (parent3El && parent3El.id !== "side-menu") {
            parent3El?.classList?.add("mm-active");
            const childAnchor = parent3El.querySelector(".has-arrow");
            const childDropdown = parent3El.querySelector(".has-dropdown");
            //if (childAnchor) { childAnchor.classList.add('mm-active'); }
            if (childDropdown) {
              childDropdown?.classList?.add("mm-active");
            }
            const parent4El = parent3El.parentElement;
            if (parent4El && parent4El.id !== "side-menu") {
              parent4El?.classList?.add("mm-show");
              const parent5El = parent4El?.parentElement;
              if (parent5El && parent5El.id !== "side-menu") {
                parent5El?.classList?.add("mm-active");
                const childanchor = parent5El.querySelector(".is-parent");
                if (childanchor && parent5El.id !== "side-menu") {
                  childanchor?.classList?.add("mm-active");
                }
              }
            }
          }
        }
      }
    }
  }

  /**
   * Initialize
   */
  initialize(data: any): void {
    console.log("INITIALIZE");
    // debugger
    // if (data) {
    //   // save current route first
    //   const currentRoute = this.router.url;
    //   this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    //   this.router.onSameUrlNavigation = 'reload';
    //   this.router.navigate([currentRoute]);
    // } else {
    // this.menuItems = NEW_MENU;

    if (this.cookieService.get("superAdminPage") === "3") {
      // console.log("superAdmine");
      //if super admin grant access in full sidebar
      // console.log("data", data);
      // console.log("NEW_MENU", NEW_MENU);
     // Filter the menu items
     const filterSuperAdminSidebar = NEW_MENU.filter(item => item.id !== '10001' && item.id !== '10002')
    .map(item => {
      console.log("item--=-=-=-=-<><><><><>", item);

        if (item.subItems) {
            item.subItems = item.subItems.filter(subItem => !['26', '41', '42', '90', '48', '53', '63', '66', '86'].includes(subItem.id));
        }
        return item;
    });

    // EMPLOYEE
    const updatedSubItemEmployee = {
      "id": "59",
      "label": "Teams",
      "icon": "assets/images/feature-page-image/workforce/Employee-Employees.svg",
      // "Activeicon": "assets/images/newsidemenu-image/active/employee.svg",
      "link": "/employees/employeelist",
      "color": "bg-Workforce",
      "isExpanded": false
  };
  // ROLES
  const updatedSubItemRoles = {
    "id": "59",
    "label": "Roles",
    "icon": "assets/images/feature-page-image/Employee/Employee-Roles.svg",
    // "Activeicon": "assets/images/newsidemenu-image/active/employee.svg",
    "link": "/employees/roles",
    "color": "bg-employeeset",
    "isExpanded": false
}

const updatedSubItemsitenetwork = {
  id: "60",  // Unique ID for the Sigma item
  label: "Site Network",
  icon: "assets/images/feature-page-image/Employee/Employee-Roles.svg",  // Correct icon for Sigma
  link: "/employees/network-summery",  // Correct link for Sigma
  color: "bg-employeeset",
  isExpanded: true,
};




//   const updatedFilterSuperAdminSidebar = filterSuperAdminSidebar.map(item => {
//     if (item.id === "25" && item.subItems) {
//         item.subItems = item.subItems.map(subItem => subItem.id === "59" ? updatedSubItemEmployee : subItem);
//     }
//     // if(item.id === "47" && item.subItems) {
//     //   item.subItems = item.subItems.map(subItem => subItem.id === "59" ? updatedSubItemRoles : subItem);
//     // }
//     if (item.id === "47") {
//       if (!Array.isArray(item.subItems)) {
//         // Initialize subItems if it doesn't exist or is not an array
//         item.subItems = [];
//       }

//       // Log current subItems to verify state
//       console.log('Current subItems:', item.subItems);

//       // Check if the Sigma item already exists
//       const existingSubItemSigma = item.subItems.some(subItem => subItem.id === "60");

//       // If Sigma does not exist, add it to subItems
//       if (!existingSubItemSigma) {
//         // Log the addition for debugging purposes
//         console.log('Adding new Sigma menu item');

//         // Push the new Sigma item
//         item.subItems = [...item.subItems, newSubItemSigma];
//       }
//     }


//     return item;
// });

const updatedFilterSuperAdminSidebar = filterSuperAdminSidebar.map(item => {

  if (item.id === "25" && item.subItems) {
    // Remove sub-item with id "59"
    item.subItems = item.subItems.filter(subItem => subItem.id !== "59");
    console.log("item.subItems", item.subItems);
    // Sort subItems according to the specified order
    const order = ["67", "68", "102", "101"];
    item.subItems.sort((a, b) => order.indexOf(a.id) - order.indexOf(b.id));
  }

  if (item.id === "47" && item.subItems) {
    const updatedSubItems = item.subItems.map(subItem => {

      if (subItem.id === "59") {
        return updatedSubItemRoles;
      }
      return subItem;
    });

    // // Add sub-item with id "59" to sub-items of item with id "47"
    // const existingSubItem59 = updatedSubItems.find(subItem => subItem.id === "59" && subItem.label !== "Roles");
    // if (!existingSubItem59) {
      updatedSubItems.push(updatedSubItemEmployee); // Assuming updatedSubItemEmployee is the sub-item with id "59"
    // }

    const existingSubItemSigma = updatedSubItems.find(subItem => subItem.id === "60");
    if (!existingSubItemSigma) {
      updatedSubItems.push(updatedSubItemsitenetwork);
    }

    // Sort subItems according to the specified order based on label
    const labelOrder = ["Teams", "Roles", "Site Network"];
    updatedSubItems.sort((a, b) => labelOrder.indexOf(a.label) - labelOrder.indexOf(b.label));

    item.subItems = updatedSubItems;
  }

  return item;
});


      this.menuItems = updatedFilterSuperAdminSidebar;
      console.log("SuperAdmin_Sidebar", this.menuItems);
    } else {
      //if normal user remove license and olo in sidebar
      try {
        // console.log(data,NEW_MENU)
        // let filterOperations = data[1].subItems.filter(item => item.id !== '90' && item.id !== '68' && item.id !== '67')
        // let filterOperations1 =  NEW_MENU[1].subItems.filter(item => item.id !== '90' && item.id !== '68' && item.id !== '67')
        // console.log("filterOperations========",filterOperations,filterOperations1)
        // let filterOperations = NEW_MENU[1].subItems.filter(item => item.id !=='99999999')

        // data[1].subItems = filterOperations
        // this.menuItems = data//NEW_MENU;

        let filterOperations = data[3].subItems.filter(
          (item) => item.id !== "90" && item.id !== "68" && item.id !== "67" && item.id !== "101" && item.id !== "102"
        );
        data[3].subItems = filterOperations;
        this.menuItems = data;
        console.log("MENU ITEMS", this.menuItems)
      } catch (error) {
        console.log("error-------------", error);
      }
    }
    // }
  }

  /**
   * Returns true or false if given menu item has child or not
   * @param item menuItem
   */
  hasItems(item: MenuItem) {
    // console.log(item)
    return item.subItems !== undefined ? item.subItems.length > 0 : false;
  }
  //new changes
  prepareDragDrop(nodes: MenuItem[]) {
    nodes?.forEach((node) => {
      this.dropTargetIds.push(node.id);
      this.nodeLookup[node.id] = node;
      this.prepareDragDrop(node.subItems);
    });
  }

  @debounce(50)
  dragMoved(event) {
    let e = this.document?.elementFromPoint(
      event?.pointerPosition?.x,
      event?.pointerPosition?.y
    );

    if (!e) {
      this.clearDragInfo();
      return;
    }
    let container = e?.classList?.contains("node-item")
      ? e
      : e?.closest(".node-item");
    if (!container) {
      this.clearDragInfo();
      return;
    }
    this.dropActionTodo = {
      targetId: container.getAttribute("data-id"),
    };
    const targetRect = container?.getBoundingClientRect();
    const oneThird = targetRect.height / 3;

    if (event.pointerPosition.y - targetRect.top < oneThird) {
      // before
      this.dropActionTodo["action"] = "before";
    } else if (event.pointerPosition.y - targetRect.top > 2 * oneThird) {
      // after
      this.dropActionTodo["action"] = "after";
    } else {
      // inside
      this.dropActionTodo["action"] = "inside";
    }
    this.showDragInfo();
  }

  drop(event) {
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    }
    // if (!this.dropActionTodo) return;
    // const draggedItemId = event.item.data;
    // const parentItemId = event.previousContainer.id;
    // const targetListId = this.getParentNodeId(this.dropActionTodo.targetId, this.nodes, 'main');
    // console.log(
    //   '\nmoving\n[' + draggedItemId + '] from list [' + parentItemId + ']',
    //   '\n[' + this.dropActionTodo.action + ']\n[' + this.dropActionTodo.targetId + '] from list [' + targetListId + ']');

    // const draggedItem = this.nodeLookup[draggedItemId];
    // const targetedItem = this.nodeLookup[targetListId];

    // const oldItemContainer = parentItemId != 'main' ? this.nodeLookup[parentItemId].subItems : this.nodes;
    // const newContainer = targetListId != 'main' ? this.nodeLookup[targetListId].subItems : this.nodes;

    // let i = oldItemContainer.findIndex(c => c.id === draggedItemId);
    // oldItemContainer.splice(i, 1);

    // switch (this.dropActionTodo.action) {
    //   case 'before':
    //   case 'after':
    //     const targetIndex = newContainer.findIndex(c => c.id === this.dropActionTodo.targetId);
    //     if (this.dropActionTodo.action == 'before') {
    //       newContainer.splice(targetIndex, 0, draggedItem);
    //     } else {
    //       newContainer.splice(targetIndex + 1, 0, draggedItem);
    //     }
    //     break;

    //   case 'inside':
    //     this.nodeLookup[this.dropActionTodo.targetId].subItems.push(draggedItem)
    //     this.nodeLookup[this.dropActionTodo.targetId].isExpanded = true;
    //     break;
    // }

    // this.clearDragInfo(true)
  }
  getParentNodeId(
    id: string,
    nodesToSearch: MenuItem[],
    parentId: string
  ): string {
    for (let node of nodesToSearch) {
      if (node.id == id) return parentId;
      let ret = this.getParentNodeId(id, node.subItems, node.id);
      if (ret) return ret;
    }
    return null;
  }
  showDragInfo() {
    this.clearDragInfo();
    if (this.dropActionTodo) {
      this.document
        .getElementById("node-" + this.dropActionTodo?.targetId)
        .classList.add("drop-" + this.dropActionTodo?.action);
    }
  }
  clearDragInfo(dropped = false) {
    if (dropped) {
      this.dropActionTodo = null;
    }
    this.document
      .querySelectorAll(".drop-before")
      .forEach((element) => element.classList.remove("drop-before"));
    this.document
      .querySelectorAll(".drop-after")
      .forEach((element) => element.classList.remove("drop-after"));
    this.document
      .querySelectorAll(".drop-inside")
      .forEach((element) => element.classList.remove("drop-inside"));
  }

  // applyLabelStyles() {
  //   const labelElement = this.elementRef.nativeElement.querySelector('.label-class');
  //   this.renderer.setStyle(labelElement, 'font-weight', 'bold');
  //   this.renderer.setStyle(labelElement, 'color', '#666666');
  // }

  constructSettingValue() {
    return this.nodes.map((item) => item);
  }

  saveMenu() {
    var settingValue = [];
    this.store.dispatch(setLoadingSpinner({ status: true }));

    const params = {
      settingName: "MENU_SETTING",
      settingValue: JSON.stringify(this.nodes),
    };

    return this.http
      .post(this.GLOBALURL + "/user/setting", params, this.httpOptions1)
      .pipe(shareReplay(1))
      .subscribe(
        (response: any) => {
          this.store.dispatch(setLoadingSpinner({ status: false }));
          if (response) {
            this.toastr.success(response.message);
            this.initialize(this.nodes);
          } else {
            this.toastr.error(response.message);
          }
        },
        (error) => {
          this.toastr.error(error.message);
          this.store.dispatch(setLoadingSpinner({ status: false }));
        }
      );
    //     // localStorage.setItem('menus', JSON.stringify(this.nodes))
    // console.log(this.nodes);

    //     this.initialize(this.nodes);
    //     this.modalService.dismissAll()
  }
  //new changes
  sendActiveSubmenu: boolean = false;
  // iconeColorChane:any;
  iconeColorChane = "Dashboard";
  GetbodyClass(bodyclass: any, subitem) {
    const body = this.renderer.selectRootElement("body", true);
    const bodyClasses = body.classList;
    const bodyClassesArray = Array.from(bodyClasses);
    if (bodyClassesArray[0] === "sidebar-enable") {
      console.log("subitem.label", subitem.label);
      // ### SET BACKGROUND-COLOR on BASIS of subitem.label IF REQUIRED
      this.iconeColorChane = subitem.label;
    } else {
      console.log("subitem.label", subitem.label);
      this.iconeColorChane = subitem.label;
    }
  }
  addCssChaticon: boolean = false;
  SendChatDetails: boolean = false;
  @Output() SubmenuDetails: EventEmitter<string> = new EventEmitter<string>();
  sendDataToTopbar(value: any) {
    this.SubmenuDetails.emit(value);
  }
  AddActiveClass(subitem: any, isTrue: boolean) {
    if (subitem.link === "/business-settings/activity-logs") {
       this.ActiveclassName = subitem.link;
       this.iconeColorChane = subitem.label;
       return;
    }

    this.SendChatDetails = true;


    if (
      subitem.label === "Hardware" ||
      subitem.label === "Activity Logs" ||
      subitem.label === "Dashboard" ||
      subitem.label === "Merchants" ||
      subitem.label === "Users" ||
      subitem.label === "Manage license"
    ) {
      setTimeout(() => {
        this.renderer.removeClass(document.body, "vertical-collpsed");
        this.renderer.addClass(document.body, "sidebar-enable");
      }, 0);
      if (isTrue) {
        this.offcanvasService.dismiss("cross click");
      }
      this.iconeColorChane = subitem.label;
    } else {
      const body = this.document.body;
      body.className = "";
      body.classList.add("vertical-collpsed");
      this.sendActiveSubmenu != this.sendActiveSubmenu;
      this.menuservice.setData(this.sendActiveSubmenu);
      setTimeout(() => {
        this.GetbodyClass(body, subitem);
      }, 0);
      if (isTrue) {
        this.sendDataToTopbar("openSubmenu");
        setTimeout(() => {
          body.classList.remove("vertical-collpsed");
          body.classList.add("res-sidebar-enable");
        }, 1000);
      }
      this.AddSubmneuActive(subitem.link);
    }

    //
    // if(this.cookieService.get("superAdminPage") === "3") {
    //   console.log("this is SuperAdmin")
    //   if (
    //     subitem.label === "Employees"
    //   ) {
    //     console.log("Clicked_ON_Employees")
    //     setTimeout(() => {
    //       this.renderer.removeClass(document.body, "vertical-collpsed");
    //       this.renderer.addClass(document.body, "sidebar-enable");
    //     }, 0);
    //     if (isTrue) {
    //       this.offcanvasService.dismiss("cross click");
    //     }
    //     this.iconeColorChane = subitem.label;
    //   }
    // }

    this.ActiveclassName = subitem.link;
    if (subitem.label == "Chat with us") {
      // set window to access crisp object
      let Window: any = window;
      if (Window && Window.$crisp) {
        Window.$crisp.push(["do", "chat:show"]);
        Window.$crisp.push(["do", "chat:open"]);
        localStorage.setItem("crisp_chat_opened", "true");
        this.addCssChaticon = true;
        this.offcanvasService.dismiss("cross click");
        this.sendDataToTopbar("closeSubmenu");
        this.SendChatDetails = false;
      }
    }
    localStorage.setItem("ActivemenuClass", this.ActiveclassName);
  }
  SidebarActivemenu() {
    const activemenu = localStorage.getItem("ActivemenuClass");

    console.log("activeeMenu", activemenu);
    this.ActiveclassName = activemenu;
  }
  AddClassName() {
    const GetDetails = localStorage.getItem("subDivPath");
    if (GetDetails) {
      const addClass = JSON.parse(GetDetails).show_menu;

      if (addClass === "home") {
        return "Dashboard";
      } else if (addClass === "account") {
        return "Dashboard";
      } else if (addClass === "reports") {
        return "Analytics";
      } else if (addClass === "sales") {
        return "Sales";
      } else if (addClass === "menumanagement") {
        return "Management";
      } else if (addClass === "guests") {
        return "Guests";
      } else if (addClass === "employees") {
        return "Workforce";
      } else if (addClass === "employees-setup") {
        return "Employee";
      } else if (addClass === "restaurant") {
        return "Restaurant";
      } else if (addClass === "onlinestore") {
        return "Ordering";
      } else if (addClass === "hardware") {
        return "Hardware";
      } else if (addClass === "integration-marketplace") {
        return "Integrations";
      } else if (addClass === "business-settings") {
        return "Settings";
      } else if (addClass === "master") {
        return "Logs";
      } else {
        return "Chat";
      }
    }
  }
  OnChangeSetActive() {
    this.eventService.watchActiveMenuCalss().subscribe((newValue) => {
      this.ActiveclassName = newValue;
      this.iconeColorChane = this.AddClassName();
    });
  }
  RemoveunwantedClass() {
    setTimeout(() => {
      if(this.renderer && this.document && this.document.body && this.document.body.classList.contains('ng-tns-0-0')) {
        this.renderer.removeClass(this.document.body, "ng-tns-0-0");
      }

    }, 2000);
  }
  Showmenu: any;
  AddSubmneuActive(path: any) {
    if (path === "/business-settings/activity-logs") {
      const linkDetails: any = {
        show_menu: "activity-logs",
        active_path: path,
      };

      return this.SetmenuPath(linkDetails);
    }
    const firstWord = path.split("/")[1];
    // this.setAccodianActive(path);
    // this.GetRouterDetails(path);
    if (firstWord == "employees") {
      let baseUrl = path.substring(0, path.lastIndexOf("/") + 1);
      if (baseUrl == "/employees/employeelist/") {
        this.Showmenu = "employees";
        const linkDetails: any = {
          show_menu: "employees",
          active_path: path,
        };
        this.SetmenuPath(linkDetails);
      } else if (baseUrl === "/employees/jobtypes/") {
        this.Showmenu = "employees-setup";
        const linkDetails: any = {
          show_menu: "employees-setup",
          active_path: path,
        };
        this.SetmenuPath(linkDetails);
      }
    } else {
      this.Showmenu = firstWord;
      if (firstWord === "profile") {
        const linkDetails: any = {
          show_menu: "home",
          active_path: path,
        };
        this.SetmenuPath(linkDetails);
      } else {
        const linkDetails: any = {
          show_menu: this.Showmenu,
          active_path: path,
        };

        this.SetmenuPath(linkDetails);
      }
    }
  }
  SetmenuPath(path: any) {
    localStorage.setItem("subDivPath", JSON.stringify(path));
  }
  NewActiveMenu() {
    const Links = [
      "/employees/roles",
      "/employees/department",
      "/employees/jobtypes",
      "/employees/network-summery",
      "jobtypes",
      "roles",
      "department",
      "site network",
    ];
    const workforece = [
      "employeelist",
      "edit-employees",
      "add-employee",
      "timesheet",
    ];

    const currentURL = window.location.href;
    const path = currentURL.replace(/^.*?#/, "");
    const workSpl = path.split("/")[2];
    // console.log("workSpl==================>", workSpl, path);

    if (workSpl === "transactions") {
      return this.setSalesDetails();
    }

    if (workforece.includes(workSpl)) {
      this.ActiveclassName = "/employees/employeelist/employee-home";
      this.iconeColorChane = "Workforce";
    } else if (Links.includes(workSpl)) {
      this.ActiveclassName = "/employees/jobtypes/employee-stup-home";
      this.iconeColorChane = "Employee";
    } else {
      this.AddSubmneuActive1(path);
    }
  }
  setSalesDetails() {
    this.ActiveclassName = "/sales/transactions/sales-home";
    this.iconeColorChane = "Sales";
    localStorage.setItem("ActivemenuClass", "/sales/transactions/sales-home");
  }
  AddSubmneuActive1(path: any) {
    const firstWord = path.split("/")[1];
    const secondWord = path.split("/")[2];
    if (firstWord == "employees") {
      let baseUrl = path.substring(0, path.lastIndexOf("/") + 1);
      if (baseUrl == "/employees/employeelist/") {
        this.ActiveclassName = "/employees/employeelist/employee-home";
        this.iconeColorChane = "Workforce";
      } else if (baseUrl === "/employees/jobtypes/") {
        this.ActiveclassName = "/employees/jobtypes/employee-stup-home";
        this.iconeColorChane = "Employee";
      }
    } else {
      if (firstWord === "profile") {
        this.ActiveclassName = "/home";
        this.iconeColorChane = "Dashboad";
      } else {
        this._newMenuActive(firstWord,secondWord);
      }
    }
  }
  _newMenuActive(Value: any,secondValue:any) {
    if (secondValue === "activity-logs") {
      this.ActiveclassName = "/business-settings/activity-logs";
      this.iconeColorChane = "Activity Logs"
      return;    }

    switch (Value) {
      case "home":
        this.ActiveclassName = "/home";
        this.iconeColorChane = "Dashboad";
        break;

      case "reports":
        this.ActiveclassName = "/reports/sales/gross-transaction/report-home";
        this.iconeColorChane = "Analytics & Reports";
        break;

      case "sales":
        this.ActiveclassName = "/sales/transactions/sales-home";
        this.iconeColorChane = "Sales";
        break;

      case "menumanagement":
        this.ActiveclassName = "/menumanagement/menus/menu-home";
        this.iconeColorChane = "Menu Management";
        break;

      case "guests":
        this.ActiveclassName = "/guests/guestbook/guest-home";
        this.iconeColorChane = "Guests";
        break;

      case "restaurant":
        this.ActiveclassName = "/restaurant/general/restaurat-home";
        this.iconeColorChane = "Restaurant";
        break;

      case "onlinestore":
        this.ActiveclassName = "/onlinestore/online-store-home";
        this.iconeColorChane = "Online Ordering";
        break;

      case "hardware":
        this.ActiveclassName = "/hardware/hardware";
        this.iconeColorChane = "Hardware";
        break;

      case "integration-marketplace":
        this.ActiveclassName = "/integration-marketplace/integration-home";
        this.iconeColorChane = "Integrations";
        break;

      case "business-settings":
        this.ActiveclassName =
          "/business-settings/location/resturant-setting-home";
        this.iconeColorChane = "Restaurant Settings";
        break;
      case "master":
        this.ActiveclassName = "/business-settings/activity-logs";
        this.iconeColorChane = "Activity Logs";
        break;
      case "loyalty":
        this.ActiveclassName = "/loyalty/loyalty-home";
        this.iconeColorChane = "Loyalty";
        break;
      case "generativeai":
        this.ActiveclassName = "/generativeai/generativeai-home";
        this.iconeColorChane = "Generative AI";
        break;

      default:
        break;
    }
  }
  _SetSidebarResponsive() {
    const screenwidth = window.innerWidth;
    if (screenwidth > 576) {
      this.SendCanavaDetails = false
      this.isCondensed = true
    }
  }
}
