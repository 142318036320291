import { DOCUMENT } from "@angular/common";
import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
  Renderer2,
} from "@angular/core";
import { Router } from "@angular/router";
import { NgbModal, NgbOffcanvas } from "@ng-bootstrap/ng-bootstrap";
import { AppAliasService } from "src/app/core/services/app-alias.service";
import { SupportDetails } from "./searchDetails";

@Component({
  selector: "app-searchbar",
  templateUrl: "./searchbar.component.html",
  styleUrls: ["./searchbar.component.scss"],
})
export class SearchbarComponent implements OnInit {
  @Input() Data: any;
  retailView: string;
  managment: string;
  managmentURL: string;
  StoreName: any;
  restaurantNstore: string;
  constructor(
    private router: Router,
    @Inject(DOCUMENT) private document: any,
    private modalService: NgbModal,
    private renderer: Renderer2,
    private appAliasService: AppAliasService,
    private offcanvasService: NgbOffcanvas
  ) { }
  Browse = [];
  QuickLinksModule = [];

  searchedLinksModule = [
    // ## HOME
    {
      name: "Dashboard",
      keyword: ["Home", "Homepage", "PIN", "Password", "Profile", "Summary"],
      link: "/home",
      discription:
        "Get an overview of your store's Sales, Orders, Transactions and discounts.",
    },
    // ## ANALYTICS
    {
      name: "Analytics and Reports",
      link: "/reports/sales/gross-transaction/report-home",
      support:
        "Understand how to use the Analytics and Reports page using our extensive support documents",
      discription:
        "The Analytics and Reports page offers comprehensive insights into your restaurant's sales, transactions, discounts, payroll, and cashout, empowering stakeholders to make data-driven decisions and optimize business performance. Get a clear overview of financial health and customer trends with user-friendly reports and visualizations.",
    },
    {
      name: "Sales Transaction",
      keyword: ["Sales", "Sales transaction"],
      link: "/reports/sales/gross-transaction",
      support:
        "Understand how to use the Sales Transaction page using our extensive support documents",
      discription:
        "Review and analyze sales transactional data with our comprehensive Sales Transactions Reports page.",
    },
    {
      name: "Daily Services Transactions",
      keyword: [
        "Service breakdown report",
        "Service breakdown",
        "Daily service transactions",
        "Service",
      ],
      link: "/reports/sales/gross-shift",
      support:
        "Understand how to use the Daily Services Transactions page using our extensive support documents",
      discription:
        "Monitor daily service transactions with ease using our intuitive Daily Service Transaction page.",
    },
    {
      name: "Itemized Sales",
      keyword: [
        "Itemized",
        "Itemized report",
        "Itemized sales report",
        "Itemized sales",
      ],
      link: "/reports/sales/gross-details",
      support:
        "Understand how to use the Itemized Sales page using our extensive support documents",
      discription:
        "View a detailed itemized sales report for better insights on restaurant performance with our Itemized Sales Report page.",
    },
    {
      name: "Cashout",
      keyword: ["Cashout", "Cashout report"],
      link: "/reports/labor/cashout",
      support:
        "Understand how to use the Cashout page using our extensive support documents",
      discription:
        "Simplify cash handling and reconcile registers with our Cashout Report page.",
    },
    {
      name: "Payroll",
      keyword: ["Payroll", "Payroll report", "Payouts", "Employee"],
      link: "/reports/labor/payroll",
      support:
        "Understand how to use the Payroll page using our extensive support documents",
      discription:
        "Access comprehensive payroll reports for restaurant staff management and financial analysis.",
    },
    {
      name: "Reports Discounts",
      keyword: ["Discounts report", "Discount usage", "Discounts"],
      link: "/reports/exception/discount",
      support:
        "Understand how to use the Reports Discounts page using our extensive support documents",
      discription:
        "Explore detailed reports and a comprehensive list of discounts offered at your restaurant.",
    },
    {
      name: "Refunds",
      keyword: ["Refunds", "Refund Authorizer"],
      link: "/reports/exception/refunds",
      support:
        "Understand how to use the Refunds page using our extensive support documents",
      discription:
        "Access detailed reports and a comprehensive list of refunds for better financial insights.",
    },
    {
      name: "Voids",
      keyword: ["Void", "Void Authorizer"],
      link: "/reports/exception/voids-reports",
      support:
        "Understand how to use the Voids page using our extensive support documents",
      discription:
        "Get detailed reports of and a comprehensive list of transactions that were voided.",
    },
    {
      name: "Cancelled Orders",
      keyword: ["Cancelled Orders"],
      link: "/reports/exception/cancel-order",
      support:
        "Understand how to use the Cancelled Orders page using our extensive support documents",
      discription:
        "Review our comprehensive reports for actionable insights regarding the cancelled orders for better order management.",
    },

    {
      name: "Sales",
      keyword: ["Sales Report", "Taxes", "Net Sales"],
      link: "/sales/transactions/sales-home",
      discription:
        "Gain valuable insights into your restaurant's performance. Easily access transaction lists, orders, cash drawers, deposits, service charges, discounts, and customer feedback. Utilize flexible filters to tailor the data to your specific needs, empowering you to make well-informed decisions for your business.",
    },
    {
      name: "Transactions",
      keyword: ["Total Transactions", "Transaction", "Closed Transactions"],
      link: "/sales/transactions",
      support:
        "Understand how to use the Transactions page using our extensive support documents",
      discription:
        "View a comprehensive list of all transactions within any desired timeline.",
    },
    {
      name: "Orders",
      keyword: [
        "Total Orders",
        "Sales Orders",
        "Closed Orders",
        "Order Status",
      ],
      link: "/sales/orders",
      support:
        "Understand how to use the Orders page using our extensive support documents",
      discription:
        "Explore a detailed list of all orders and their corresponding details within any desired timeline.",
    },
    {
      name: "Cash Drawers",
      keyword: [
        "Cash Drawer",
        "Pay In",
        "Pay Out",
        "Pay-In",
        "Pay-Out",
        "Opening Balance",
        "Closing Balance",
        "Transaction History",
      ],
      link: "/sales/cashdrawers",
      support:
        "Understand how to use the Cash Drawers page using our extensive support documents",
      discription:
        "Discover an extensive record of all cash drawer activities and associated details for any specified timeline.",
    },
    {
      name: "Deposits",
      keyword: ["Deposit", "Deposit Overview"],
      link: "/sales/deposits",
      support:
        "Understand how to use the Deposits page using our extensive support documents",
      discription:
        "Review a comprehensive list of deposits and their corresponding details within a specified timeline using our Deposit page.",
    },
    {
      name: "Sales Service charges",
      keyword: ["Service Charge", "Charges"],
      link: "/sales/service-charges",
      support:
        "Understand how to use the Sales Service charges page using our extensive support documents",
      discription:
        "Explore a detailed list and specific details of all service charges applied to orders within a chosen timeline on our Service Charge page.",
    },
    {
      name: "Sales Discounts",
      keyword: ["Discount", "Total Discount", "Payment Method", "Order Type"],
      link: "/sales/discounts",
      support:
        "Understand how to use the Sales Discounts page using our extensive support documents",
      discription:
        "View a comprehensive list and detailed information of all discounts applied to orders within a selected timeline on our Discounts page.",
    },
    {
      name: "Feedback",
      keyword: [
        "Customer Feedback",
        "Digital Receipt",
        "Feedback Report",
        "Feedback Status",
        "Feedback Collection",
      ],
      link: "/sales/feedback",
      support:
        "Understand how to use the Feedback page using our extensive support documents",
      discription:
        "Gain valuable insights and view all the feedback received by your restaurant on our Comprehensive Feedback History page.",
    },

    // ## MENU MANAGEMENT
    {
      name: "Menu Management",
      keyword: [
        "Menu Template",
        "Menu Management",
        "Real-Time Updates",
        "Menu Workflow",
        "Restaurant Menu",
        "Managing Menus",
      ],
      link: "/menumanagement/menus/menu-home",
      support:
        "Understand how to use the Menu Management page using our extensive support documents",
      discription:
        "Effortlessly manage and customize the various aspects of your Menus with our Menu Management page.",
    },
    {
      name: "Menu Builder",
      keyword: [
        "Menu Builder",
        "New menu",
        "Import menu",
        "Create a menu",
        "Menu availability",
        "Third party platform",
        "AI menu creation",
        "Menu date",
      ],
      link: "/menumanagement/menu-builder",
      support:
        "Understand how to use the Menus page using our extensive support documents",
      discription:
        "Create and customize your menu with ease! Build categories and items directly, tailoring your culinary offerings to perfection for a truly unforgettable dining experience.",
    },
    {
      name: "Menus",
      keyword: [
        "Menus",
        "New menu",
        "Import menu",
        "Create a menu",
        "Menu availability",
        "Third party platform",
        "AI menu creation",
        "Menu date",
      ],
      link: "/menumanagement/menus",
      support:
        "Understand how to use the Menus page using our extensive support documents",
      discription:
        "View and customize your restaurant menus with ease using our Menus page.",
    },
    {
      name: "Products",
      keyword: [
        "Products",
        "Product Image",
        "Product Inventory",
        "Price",
        "Display Name",
        "86 this product",
        "Variant",
      ],
      link: "/menumanagement/products",
      support:
        "Understand how to use the Products page using our extensive support documents",
      discription:
        "Manage and organize your menu products efficiently with our Products page.",
    },
    {
      name: "Modifiers",
      keyword: ["Modifiers", "Add New Modifier", "Modifier Name"],
      link: "/menumanagement/modifiers",
      support:
        "Understand how to use the Modifiers page using our extensive support documents",
      discription:
        "Customize and adjust menu modifiers effortlessly with our Modifiers page.",
    },
    {
      name: "Groups (Modifiers/Add-ons)",
      keyword: [
        "Groups (Modifiers/Add-Ons)",
        "Add-on Options",
        "Default Modifier",
        "Groups (Modifiers/Add-Ons) Type",
      ],
      link: "/menumanagement/Groups-Modifiers-Add-Ons",
      support:
        "Understand how to use the Groups (Modifiers/Add-Ons) page using our extensive support documents",
      discription:
        "Organize and modify menu groups (Modifiers/Add-Ons) with ease on our Groups (Modifiers/Add-Ons) page.",
    },
    {
      name: "Add On",
      keyword: ["Add-ons", "Add-on Group"],
      link: "/menumanagement/addons",
      support:
        "Understand how to use the Add On page using our extensive support documents",
      discription:
        "Add, edit, and manage menu add-ons conveniently using our Add Ons page.",
    },
    {
      name: "Ingredients",
      keyword: ["New Ingredient", "Adding Ingredient", "Ingredient"],
      link: "/menumanagement/ingredients",
      support:
        "Understand how to use the Ingredients page using our extensive support documents",
      discription:
        "Easily manage and track the ingredients required in your products with our Ingredient page.",
    },
    {
      name: "Categories",
      keyword: [
        "Categories",
        "Category Name",
        "Create Category",
        "Parent Category",
        "Sub-Category",
      ],
      link: "/menumanagement/categories",
      support:
        "Understand how to use the Categories page using our extensive support documents",
      discription:
        "Organize and categorize your menu items efficiently with our Categories page.",
    },
    {
      name: "Courses",
      keyword: [
        "Course",
        "New Course",
        "Auto Fire",
        "CourseTrigger",
        "Time Interval",
      ],
      link: "/menumanagement/courses",
      support:
        "Understand how to use the Courses page using our extensive support documents",
      discription:
        "Manage and customize menu courses effectively with our Courses page.",
    },
    {
      name: "Menu Management Service Charges",
      keyword: [
        "Service Charge",
        "Service Charge Type",
        "Transaction Charges",
        "Service Charge Value",
      ],
      link: "/menumanagement/service-charge",
      support:
        "Understand how to use the Menu Management Service Charges page using our extensive support documents",
      discription:
        "Explore service charge details and apply changes easily using our Service Charge page.",
    },
    {
      name: "Menu Management Discounts",
      keyword: [
        "Discount",
        "Create a Discount",
        "Discount Name",
        "Discount Value",
        "Discount Type",
      ],
      link: "/menumanagement/discounts",
      support:
        "Understand how to use the Menu Management Discounts page using our extensive support documents",
      discription:
        "View and manage discounts on your menu items seamlessly with our Discounts page.",
    },
    {
      name: "Taxes",
      keyword: [
        "Taxes",
        "Tax Name",
        "Tax Value",
        "Tax Type",
        "Tax Entry",
        "Exclusive Tax",
        "Tax Management",
        "Create a Tax",
      ],
      link: "/menumanagement/taxes",
      support:
        "Understand how to use the Taxes page using our extensive support documents",
      discription:
        "Monitor and modify restaurant taxes effortlessly with our Taxes page.",
    },

    // ## GUEST
    {
      name: "Guests",
      keyword: ["Guestbook", "New Guest", "Archiving a guest"],
      link: "/guests/guestbook/guest-home",
      support:
        "Understand how to use the Guests page using our extensive support documents",
      discription:
        "Explore our Guestbook page for a list of guests, our Loyalty Program, and exciting Marketing updates. Join us in fostering meaningful connections and rewarding experiences with your valued patrons.",
    },
    {
      name: "Guestbook",
      keyword: [
        "Guest",
        "Guestbook",
        "Guest Data",
        "Edit Guest",
        "Guest Info",
        "Guest Details",
        "Add a Guest",
      ],
      link: "/guests/guestbook",
      support:
        "Understand how to use the Guestbook page using our extensive support documents",
      discription:
        "Keep track of your restaurant's guest information and manage your Loyalty Program effectively using our Guestbook page.",
    },
    // { name: "Guestbook", link: "/guests/guestbook",discription:"Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad" },
    // loyalty
    {
      name: "Loyalty",
      keyword: [
        "Loyalty ",
        "Loyalty ",
        "Loyalty Settings",
        "Loyalty Management",
        "Loyalty Rules",
        "Loyalty Benefits",
      ],
      link: "/loyalty/loyalty-home",
      support:
        "Understand how to use the Loyalty Program page using our extensive support documents",
      discription:
        "Explore our Guestbook page for a list of guests, our Loyalty Program, and exciting Marketing updates. Join us in fostering meaningful connections and rewarding experiences with your valued patrons.",
    },

    // Generative AI
    {
      name: "Generative AI",
      keyword: ["Generative AI", "AI Menu Creation", "AI Menu", "AI" , "Generative" ],
      link: "/generativeai/generativeai-home",
      support:
        "Understand how to use the Generative AI page using our extensive support documents",
      discription:
        "Generation of dynamic and informative content.",
    },
    //employees
    {
      name: "Workforce",
      keyword: [
        "Employee",
        "Edit Employee",
        "Total Weekly Hours",
        "Break Time",
        "Employee Information",
        "Archiving Employee",
      ],
      link: "/employees/employeelist/employee-home",
      support:
        "Understand how to use the Employee page using our extensive support documents",
      discription:
        "The Ultimate tool for efficient workforce management. Access and track employee clock-in and clock-out times, create and edit schedules effortlessly, and keep essential employee information up-to- date. Simplify your operations and enhance productivity with ease.",
    },
    {
      name: "Employees",
      keyword: ["Employee", "Edit Employee", "Role", "Archive Employee"],
      link: "/employees/employeelist",
      support:
        "Understand how to use the Employees page using our extensive support documents",
      discription:
        "Organize and manage your restaurant employees' information effectively using our Employees page.",
    },
    {
      name: "Timesheet",
      keyword: [
        "Timesheet",
        "Employee Hours",
        "Hours Worked",
        "Employee Timesheet",
        "Daily Work Hours",
      ],
      link: "/employees/timesheet",
      support:
        "Understand how to use the Timesheet page using our extensive support documents",
      discription:
        "Easily track employee working hours and generate timesheets with our Timesheet page.",
    },

    {
      name: "Employee Job and Role",
      keyword: [
        "Employee",
        "Employee Job Type",
        "Role Permission",
        "Full Access",
      ],
      link: "/employees/jobtypes/employee-stup-home",
      support:
        "Understand how to use the Employee page using our extensive support documents",
      discription:
        "Effortlessly create and edit job types, roles, and departments. Organize your workforce with ease and optimize restaurant operations. Simplify your hiring process and enhance staff management for a smoother running restaurant.",
    },
    {
      name: "Job Types",
      keyword: [
        "Job Types",
        "Job Name",
        "Rates",
        "Add a New Job",
        "Job Code",
        "Overtime Rate",
        "Double Rate",
      ],
      link: "/employees/jobtypes",
      support:
        "Understand how to use the Job Types page using our extensive support documents",
      discription:
        "Manage and customize job types effectively to streamline workforce organization with our Job Types page.",
    },
    {
      name: "Roles",
      keyword: [
        "employee job Roles",
        "Provisions",
        "Access",
        "Permissions",
        "Responsibilities",
        "Selecting Permission",
        "Full Access",
      ],
      link: "/employees/roles",
      support:
        "Understand how to use the Roles page using our extensive support documents",
      discription:
        "Easily manage and assign roles to your restaurant employees with our Roles page.",
    },
    {
      name: "Department",
      keyword: ["Departments", "New Department"],
      link: "/employees/department",
      support:
        "Understand how to use the Department page using our extensive support documents",
      discription:
        "Organize and categorize employees into different departments for efficient management with our Departments page.",
    },
    //restaurant

    {
      name: "Restaurant",
      keyword: [
        "Restaurant Schedule",
        "Service Area",
        "Revenue Center",
        "Restaurant Address",
      ],
      link: "/restaurant/general/restaurat-home",
      support:
        "Understand how to use the Restaurant page using our extensive support documents",
      discription:
        "Easily change restaurant settings, create revenue centers, and add service areas for efficient restaurant management. Take full control of your establishment with our user-friendly interface.",
    },
    {
      name: "Restaurant General",
      keyword: ["General Settings", "Contact Details"],
      link: "/restaurant/general",
      support:
        "Understand how to use the Restaurant General page using our extensive support documents",
      discription:
        "Customize general settings for your restaurant effortlessly with our General page.",
    },
    {
      name: "Restaurant Schedule",
      keyword: [
        "Restaurant Schedule",
        "Add Schedule",
        "Schedule Name",
        "Edit Schedule",
        "Delete Schedule",
      ],
      link: "/restaurant/schedule",
      support:
        "Understand how to use the Restaurant Schedule page using our extensive support documents",
      discription:
        "Create and manage employee work schedules efficiently with our Schedule page.",
    },
    {
      name: "Revenue Center",
      keyword: [
        "Revenue Centers",
        "Revenue Center Name",
        "Edit Revenue Center",
        "Delete A Revenue Center",
      ],
      link: "/restaurant/revenuecenter",
      support:
        "Understand how to use the Revenue Center page using our extensive support documents",
      discription:
        "Create and manage different revenue centers within your restaurant to streamline operations and create better reports.",
    },
    {
      name: "Service Areas",
      keyword: [
        "Service Areas",
        "New Floor",
        "Seat Direction",
        "Add Tables",
        "Service Area Templates",
        "Floor Name",
        "Restaurant Setup",
      ],
      link: "/restaurant/service-areas",
      support:
        "Understand how to use the Service Areas page using our extensive support documents",
      discription:
        "Customize service areas in your restaurant to optimize order management with our Service Area page.",
    },

    //online orderring
    {
      name: "Online Ordering",
      keyword: ["Online Ordering", "Online Store"],
      link: "/onlinestore/online-store-home",
      support:
        "Understand how to use the Online Ordering page using our extensive support documents",
      discription:
        "Take full control of your restaurant's online ordering system. Our page allows you to easily set up and customize the portal according to your preferences. Modify menu items, adjust delivery options, and manage settings effortlessly. Enjoy the flexibility of changing your online ordering settings whenever you need to, providing your customers with a seamless and tailored ordering experience.",
    },
    {
      name: "Online Ordering General",
      keyword: [
        "Online Store Information",
        "Restaurant Name",
        "Online Logo",
        "Images",
      ],
      link: "/onlinestore",
      support:
        "Understand how to use the Online Ordering General page using our extensive support documents",
      discription: "Manage and customize your online restaurant portal.",
    },
    {
      name: "Storefront Settings",
      keyword: [
        "Storefront Settings",
        "Order type Settings",
        "Accept Order",
        "Dine in",
        "Dine-in",
        "Pickup",
        "Delivery",
        "Curbside",
        "Receiving Orders",
        "Online Store",
      ],
      link: "/onlinestore/store-front-settings",
      support:
        "Understand how to use the Storefront Settings page using our extensive support documents",
      discription:
        "Customize the look of your Online restaurant portal and set default values for online orders.",
    },
    {
      name: "Delivery",
      keyword: [
        "Online Ordering",
        "Delivery Services",
        "Doordash",
        "Delivery Fee",
        "Delivery Zone",
      ],
      link: "/onlinestore/delivery",
      support:
        "Understand how to use the Delivery page using our extensive support documents",
      discription:
        "Manage and customize delivery options to suit your restaurant's needs with our Delivery page.",
    },
    {
      name: "Payment",
      keyword: [
        "Online Payment",
        "Checkout Process",
        "Payment System",
        "Enable Online Payment",
        "Payment Processor",
        "Payment Type",
        "Payments"
      ],
      link: "/onlinestore/payment",
      support:
        "Understand how to use the Payment page using our extensive support documents",
      discription:
        "Easily manage online payment options and preferences with our Payment page.",
    },
    {
      name: "Online Ordering Schedule",
      keyword: ["Order Ahead"],
      link: "/onlinestore/schedule",
      support:
        "Understand how to use the Online Ordering Schedule page using our extensive support documents",
      discription:
        "Create and manage delivery schedules efficiently with our Schedule page.",
    },
    {
      name: "Support",
      keyword: [
        "Online Ordering Support",
        "Support Tickets",
        "Customer Support",
        "Open Support Ticket",
        "Closing Support Ticket",
      ],
      link: "/onlinestore/support",
      support:
        "Understand how to use the Support page using our extensive support documents",
      discription:
        "Manage and access the support tickets raised on your online portal with ease.",
    },
    {
      name: "Promo Code",
      keyword: ["Promo Code", "online Discount", " Create Promo Code"],
      link: "/onlinestore/promo-code",
      support:
        "Understand how to use the Promo Code page using our extensive support documents",
      discription:
        "Offer and manage promotional codes for online orders with our Promo Code page.",
    },
    {
      name: "QR Order at Table",
      keyword: [
        "QR Code",
        "Assign Table",
        "Generating QR Code",
        "QR Code Customization",
        "Table Service",
        "QR Order at Table",
      ],
      link: "/onlinestore/qr-code",
      support:
        "Understand how to use the QR Order at Table page using our extensive support documents",
      discription:
        "Implement and customize the QR codes at tables for a seamless and interactive dining experience with our QR code at table page.",
    },

    // ## HARDWARE
    {
      name: "Hardware",
      keyword: [
        "Hardware",
        "Hardware Setup",
        "Device Configuration",
        "Add New Hardware",
        "Delete a device",
        "New Hardware",
        "Add Hardware",
      ],
      link: "/hardware/hardware",
      discription:
        "A list of all the devices that are connected to your system.",
    },

    // ## INTEGRATIONS
    {
      name: "Integrations",
      keyword: [
        "Integration",
        "Enabling Integration",
        "Integration Activation",
        "Disabling Integration",
        "Archiving Integration",
        "Add Integration",
      ],
      link: "/integration-marketplace",
      discription:
        "Connect eatOS with 3rd party applications to seamlessly manage your restaurants data from a single point.",
    },

    //Restarurant setting
    {
      name: "Restaurant Settings",
      keyword: [
        "Restaurant Locations",
        "Deposit Time",
        "Reports",
        "Payroll settings",
        "Receipt Builder",
      ],
      link: "/business-settings/location/resturant-setting-home",
      support:
        "Understand how to use the Restaurant Settings page using our extensive support documents",
      discription:
        "Effortlessly manage restaurant location details and customize digital receipts for a personalized touch. Update information for all your restaurant locations and leave a lasting impression on customers with branded digital receipts.",
    },
    {
      name: "Locations",
      keyword: [
        "Restaurant Settings",
        "new Location",
        "restaurant Information",
        "Restaurant Type",
        "Restaurant Features",
        "Inactivity timer",
        "Currency",
        "Split Check",
        "Offline mode",
        "Language",
      ],
      link: "/business-settings/location",
      support:
        "Understand how to use the Locations page using our extensive support documents",
      discription:
        "Easily manage and update information for all your restaurant locations with our Locations page.",
    },
    {
      name: "Receipt Builder",
      keyword: [
        "Customize Receipt",
        "Receipt Information",
        "Digital Receipt",
        "email Receipts",
      ],
      link: "/business-settings/receipt-builder",
      support:
        "Understand how to use the Receipt Builder page using our extensive support documents",
      discription:
        "Customize and design branded digital receipts for a lasting impression on customers with our Receipt Builder page.",
    },

    // ACTIVITY LOGS
    {
      name: "Activity Logs",
      keyword: ["Activity log", "IP Address", "Module", "Service"],
      link: "/business-settings/activity-logs",
      discription:
        "A list of all the activity that is going on throughout your system.",
    },
    // CHAT WITH US
    {
      name: "Chat with us",
      keyword: ["Chat with us", "Helpdesk", "Customer Support", "Talk to support", "Connect to eatOS",],
      discription:
        "How can I help your restaurant today?",
      link: "/#",
    },
  ];
  searchedLinksModuleRetail = [
    // ## HOME
    {
      name: "Dashboard",
      keyword: ["Home", "Homepage", "PIN", "Password", "Profile", "Summary"],
      link: "/home",
      discription:
        "Get an overview of your store's Sales, Orders, Transactions and discounts.",
    },
    // ## ANALYTICS
    {
      name: "Analytics and Reports",
      link: "/reports/sales/gross-transaction/report-home",
      support:
        "Understand how to use the Analytics and Reports page using our extensive support documents",
      discription:
        "The Analytics and Reports page offers comprehensive insights into your restaurant's sales, transactions, discounts, payroll, and cashout, empowering stakeholders to make data-driven decisions and optimize business performance. Get a clear overview of financial health and customer trends with user-friendly reports and visualizations.",
    },
    {
      name: "Sales Transaction",
      keyword: ["Sales", "Sales transaction"],
      link: "/reports/sales/gross-transaction",
      support:
        "Understand how to use the Sales Transaction page using our extensive support documents",
      discription:
        "Review and analyze sales transactional data with our comprehensive Sales Transactions Reports page.",
    },
    {
      name: "Daily Services Transactions",
      keyword: [
        "Service breakdown report",
        "Service breakdown",
        "Daily service transactions",
        "Service",
      ],
      link: "/reports/sales/gross-shift",
      support:
        "Understand how to use the Daily Services Transactions page using our extensive support documents",
      discription:
        "Monitor daily service transactions with ease using our intuitive Daily Service Transaction page.",
    },
    {
      name: "Itemized Sales",
      keyword: [
        "Itemized",
        "Itemized report",
        "Itemized sales report",
        "Itemized sales",
      ],
      link: "/reports/sales/gross-details",
      support:
        "Understand how to use the Itemized Sales page using our extensive support documents",
      discription:
        "View a detailed itemized sales report for better insights on restaurant performance with our Itemized Sales Report page.",
    },
    {
      name: "Cashout",
      keyword: ["Cashout", "Cashout report"],
      link: "/reports/labor/cashout",
      support:
        "Understand how to use the Cashout page using our extensive support documents",
      discription:
        "Simplify cash handling and reconcile registers with our Cashout Report page.",
    },
    {
      name: "Payroll",
      keyword: ["Payroll", "Payroll report", "Payouts", "Employee"],
      link: "/reports/labor/payroll",
      support:
        "Understand how to use the Payroll page using our extensive support documents",
      discription:
        "Access comprehensive payroll reports for restaurant staff management and financial analysis.",
    },
    {
      name: "Reports Discounts",
      keyword: ["Discounts report", "Discount usage", "Discounts"],
      link: "/reports/exception/discount",
      support:
        "Understand how to use the Reports Discounts page using our extensive support documents",
      discription:
        "Explore detailed reports and a comprehensive list of discounts offered at your restaurant.",
    },
    {
      name: "Refunds",
      keyword: ["Refunds", "Refund Authorizer"],
      link: "/reports/exception/refunds",
      support:
        "Understand how to use the Refunds page using our extensive support documents",
      discription:
        "Access detailed reports and a comprehensive list of refunds for better financial insights.",
    },
    {
      name: "Voids",
      keyword: ["Void", "Void Authorizer"],
      link: "/reports/exception/voids-reports",
      support:
        "Understand how to use the Voids page using our extensive support documents",
      discription:
        "Get detailed reports of and a comprehensive list of transactions that were voided.",
    },
    {
      name: "Cancelled Orders",
      keyword: ["Cancelled Orders"],
      link: "/reports/exception/cancel-order",
      support:
        "Understand how to use the Cancelled Orders page using our extensive support documents",
      discription:
        "Review our comprehensive reports for actionable insights regarding the cancelled orders for better order management.",
    },

    {
      name: "Sales",
      keyword: ["Sales Report", "Taxes", "Net Sales"],
      link: "/sales/transactions/sales-home",
      discription:
        "Gain valuable insights into your restaurant's performance. Easily access transaction lists, orders, cash drawers, deposits, service charges, discounts, and customer feedback. Utilize flexible filters to tailor the data to your specific needs, empowering you to make well-informed decisions for your business.",
    },
    {
      name: "Transactions",
      keyword: ["Total Transactions", "Transaction", "Closed Transactions"],
      link: "/sales/transactions",
      support:
        "Understand how to use the Transactions page using our extensive support documents",
      discription:
        "View a comprehensive list of all transactions within any desired timeline.",
    },
    {
      name: "Orders",
      keyword: [
        "Total Orders",
        "Sales Orders",
        "Closed Orders",
        "Order Status",
      ],
      link: "/sales/orders",
      support:
        "Understand how to use the Orders page using our extensive support documents",
      discription:
        "Explore a detailed list of all orders and their corresponding details within any desired timeline.",
    },
    {
      name: "Cash Drawers",
      keyword: [
        "Cash Drawer",
        "Pay In",
        "Pay Out",
        "Pay-In",
        "Pay-Out",
        "Opening Balance",
        "Closing Balance",
        "Transaction History",
      ],
      link: "/sales/cashdrawers",
      support:
        "Understand how to use the Cash Drawers page using our extensive support documents",
      discription:
        "Discover an extensive record of all cash drawer activities and associated details for any specified timeline.",
    },
    {
      name: "Deposits",
      keyword: ["Deposit", "Deposit Overview"],
      link: "/sales/deposits",
      support:
        "Understand how to use the Deposits page using our extensive support documents",
      discription:
        "Review a comprehensive list of deposits and their corresponding details within a specified timeline using our Deposit page.",
    },
    {
      name: "Sales Service charges",
      keyword: ["Service Charge", "Charges"],
      link: "/sales/service-charges",
      support:
        "Understand how to use the Sales Service charges page using our extensive support documents",
      discription:
        "Explore a detailed list and specific details of all service charges applied to orders within a chosen timeline on our Service Charge page.",
    },
    {
      name: "Sales Discounts",
      keyword: ["Discount", "Total Discount", "Payment Method", "Order Type"],
      link: "/sales/discounts",
      support:
        "Understand how to use the Sales Discounts page using our extensive support documents",
      discription:
        "View a comprehensive list and detailed information of all discounts applied to orders within a selected timeline on our Discounts page.",
    },
    {
      name: "Feedback",
      keyword: [
        "Customer Feedback",
        "Digital Receipt",
        "Feedback Report",
        "Feedback Status",
        "Feedback Collection",
      ],
      link: "/sales/feedback",
      support:
        "Understand how to use the Feedback page using our extensive support documents",
      discription:
        "Gain valuable insights and view all the feedback received by your restaurant on our Comprehensive Feedback History page.",
    },

    // ## PRODUCT MANAGEMENT
    {
      name: "Product Management",
      keyword: [
        "Product Management",
        "Productmanagement",
        "Product Template",
        "product management",
        "Real-Time Updates",
        "product Workflow",
        "Restaurant Menu",
        "Managing Menus",
      ],
      link: "/productmanagment",
      support:
        "Effortlessly manage and customize the various aspects of your Menus with our Menu Management page.",
      discription:
        "Effortlessly manage and customize the various aspects of your Menus with our Menu Management page.",
    },
    {
      name: "Products",
      keyword: [
        "Products",
        "Product Image",
        "Product Inventory",
        "Price",
        "Display Name",
        "86 this product",
        "Variant",
      ],
      link: "/productmanagment/products",
      support:
        "Understand how to use the Products page using our extensive support documents",
      discription:
        "Manage and organize your menu products efficiently with our Products page.",
    },
    {
      name: "Productmanagment Categories",
      keyword: [
        "Categories",
        "Category Name",
        "Create Category",
        "Parent Category",
        "Sub-Category",
      ],
      link: "/productmanagment/categories",
      support:
        "Understand how to use the Categories page using our extensive support documents",
      discription:
        "Organize and categorize your menu items efficiently with our Categories page.",
    },
    {
      name: "Productmanagment Courses",
      keyword: [
        "Course",
        "New Course",
        "Auto Fire",
        "CourseTrigger",
        "Time Interval",
      ],
      link: "/menumanagement/courses",
      support:
        "Understand how to use the Courses page using our extensive support documents",
      discription:
        "Manage and customize menu courses effectively with our Courses page.",
    },
    {
      name: "Productmanagment Service Charges",
      keyword: [
        "Service Charge",
        "Service Charge Type",
        "Transaction Charges",
        "Service Charge Value",
      ],
      link: "/productmanagment/service-charge",
      support:
        "Understand how to use the Menu Management Service Charges page using our extensive support documents",
      discription:
        "Explore service charge details and apply changes easily using our Service Charge page.",
    },
    {
      name: "productmanagment Discounts",
      keyword: [
        "Discount",
        "Create a Discount",
        "Discount Name",
        "Discount Value",
        "Discount Type",
      ],
      link: "/productmanagment/discounts",
      support:
        "Understand how to use the Menu Management Discounts page using our extensive support documents",
      discription:
        "View and manage discounts on your menu items seamlessly with our Discounts page.",
    },
    {
      name: "Taxes",
      keyword: [
        "Taxes",
        "Tax Name",
        "Tax Value",
        "Tax Type",
        "Tax Entry",
        "Exclusive Tax",
        "Tax Management",
        "Create a Tax",
      ],
      link: "/productmanagment/taxes",
      support:
        "Understand how to use the Taxes page using our extensive support documents",
      discription:
        "Monitor and modify restaurant taxes effortlessly with our Taxes page.",
    },

    // ## GUEST
    {
      name: "Guests",
      keyword: ["Guestbook", "New Guest", "Archiving a guest"],
      link: "/guests/guestbook/guest-home",
      support:
        "Understand how to use the Guests page using our extensive support documents",
      discription:
        "Explore our Guestbook page for a list of guests, our Loyalty Program, and exciting Marketing updates. Join us in fostering meaningful connections and rewarding experiences with your valued patrons.",
    },
    {
      name: "Guestbook",
      keyword: [
        "Guest",
        "Guestbook",
        "Guest Data",
        "Edit Guest",
        "Guest Info",
        "Guest Details",
        "Add a Guest",
      ],
      link: "/guests/guestbook",
      support:
        "Understand how to use the Guestbook page using our extensive support documents",
      discription:
        "Keep track of your restaurant's guest information and manage your Loyalty Program effectively using our Guestbook page.",
    },
    // { name: "Guestbook", link: "/guests/guestbook",discription:"Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad" },

    //employees
    {
      name: "Workforce",
      keyword: [
        "Employee",
        "Edit Employee",
        "Total Weekly Hours",
        "Break Time",
        "Employee Information",
        "Archiving Employee",
      ],
      link: "/employees/employeelist/employee-home",
      support:
        "Understand how to use the Employee page using our extensive support documents",
      discription:
        "The Ultimate tool for efficient workforce management. Access and track employee clock-in and clock-out times, create and edit schedules effortlessly, and keep essential employee information up-to- date. Simplify your operations and enhance productivity with ease.",
    },
    {
      name: "Employees",
      keyword: ["Employee", "Edit Employee", "Role", "Archive Employee"],
      link: "/employees/employeelist",
      support:
        "Understand how to use the Employees page using our extensive support documents",
      discription:
        "Organize and manage your restaurant employees' information effectively using our Employees page.",
    },
    {
      name: "Timesheet",
      keyword: [
        "Timesheet",
        "Employee Hours",
        "Hours Worked",
        "Employee Timesheet",
        "Daily Work Hours",
      ],
      link: "/employees/timesheet",
      support:
        "Understand how to use the Timesheet page using our extensive support documents",
      discription:
        "Easily track employee working hours and generate timesheets with our Timesheet page.",
    },

    {
      name: "Employee Job and Role",
      keyword: [
        "Employee",
        "Employee Job Type",
        "Role Permission",
        "Full Access",
      ],
      link: "/employees/jobtypes/employee-stup-home",
      support:
        "Understand how to use the Employee page using our extensive support documents",
      discription:
        "Effortlessly create and edit job types, roles, and departments. Organize your workforce with ease and optimize restaurant operations. Simplify your hiring process and enhance staff management for a smoother running restaurant.",
    },
    {
      name: "Job Types",
      keyword: [
        "Job Types",
        "Job Name",
        "Rates",
        "Add a New Job",
        "Job Code",
        "Overtime Rate",
        "Double Rate",
      ],
      link: "/employees/jobtypes",
      support:
        "Understand how to use the Job Types page using our extensive support documents",
      discription:
        "Manage and customize job types effectively to streamline workforce organization with our Job Types page.",
    },
    {
      name: "Roles",
      keyword: [
        "employee job Roles",
        "Provisions",
        "Access",
        "Permissions",
        "Responsibilities",
        "Selecting Permission",
        "Full Access",
      ],
      link: "/employees/roles",
      support:
        "Understand how to use the Roles page using our extensive support documents",
      discription:
        "Easily manage and assign roles to your restaurant employees with our Roles page.",
    },
    {
      name: "Department",
      keyword: ["Departments", "New Department"],
      link: "/employees/department",
      support:
        "Understand how to use the Department page using our extensive support documents",
      discription:
        "Organize and categorize employees into different departments for efficient management with our Departments page.",
    },
    //restaurant

    {
      name: "Store",
      keyword: [
        "Store Schedule",
        "Service Area",
        "Store Address",
      ],
      link: "/restaurant/general/restaurat-home",
      support:
        "Understand how to use the Restaurant page using our extensive support documents",
      discription:
        "Easily change restaurant settings, create revenue centers, and add service areas for efficient restaurant management. Take full control of your establishment with our user-friendly interface.",
    },
    {
      name: "store General",
      keyword: ["General Settings", "Contact Details" , "store General"],
      link: "/restaurant/general",
      support:
        "Understand how to use the Restaurant General page using our extensive support documents",
      discription:
        "Customize general settings for your restaurant effortlessly with our General page.",
    },
    {
      name: "Restaurant Schedule",
      keyword: [
        "Restaurant Schedule",
        "Add Schedule",
        "Schedule Name",
        "Edit Schedule",
        "Delete Schedule",
      ],
      link: "/restaurant/schedule",
      support:
        "Understand how to use the Restaurant Schedule page using our extensive support documents",
      discription:
        "Create and manage employee work schedules efficiently with our Schedule page.",
    },
    {
      name: "Revenue Center",
      keyword: [
        "Revenue Centers",
        "Revenue Center Name",
        "Edit Revenue Center",
        "Delete A Revenue Center",
      ],
      link: "/restaurant/revenuecenter",
      support:
        "Understand how to use the Revenue Center page using our extensive support documents",
      discription:
        "Create and manage different revenue centers within your restaurant to streamline operations and create better reports.",
    },
    {
      name: "Service Areas",
      keyword: [
        "Service Areas",
        "New Floor",
        "Seat Direction",
        "Add Tables",
        "Service Area Templates",
        "Floor Name",
        "Restaurant Setup",
      ],
      link: "/restaurant/service-areas",
      support:
        "Understand how to use the Service Areas page using our extensive support documents",
      discription:
        "Customize service areas in your restaurant to optimize order management with our Service Area page.",
    },
    // ## HARDWARE
    {
      name: "Hardware",
      keyword: [
        "Hardware",
        "Hardware Setup",
        "Device Configuration",
        "Add New Hardware",
        "Delete a device",
        "New Hardware",
        "Add Hardware",
      ],
      link: "/hardware/hardware",
      discription:
        "A list of all the devices that are connected to your system.",
    },

    // ## INTEGRATIONS
    {
      name: "Integrations",
      keyword: [
        "Integration",
        "Enabling Integration",
        "Integration Activation",
        "Disabling Integration",
        "Archiving Integration",
        "Add Integration",
      ],
      link: "/integration-marketplace",
      discription:
        "Connect eatOS with 3rd party applications to seamlessly manage your restaurants data from a single point.",
    },

    //Store setting
    {
      name: "Store Settings",
      keyword: [
        "Store Locations",
        "Deposit Time",
        "Reports",
        "Payroll settings",
        "Receipt Builder",
      ],
      link: "/business-settings/location/resturant-setting-home",
      support:
        "Understand how to use the Restaurant Settings page using our extensive support documents",
      discription:
        "Effortlessly manage restaurant location details and customize digital receipts for a personalized touch. Update information for all your restaurant locations and leave a lasting impression on customers with branded digital receipts.",
    },
    {
      name: "Locations",
      keyword: [
        "store Settings",
        "new Location",
        "store Information",
        "store Type",
        "store Features",
        "Inactivity timer",
        "Currency",
        "Split Check",
        "Offline mode",
        "Language",
      ],
      link: "/business-settings/location",
      support:
        "Understand how to use the Locations page using our extensive support documents",
      discription:
        "Easily manage and update information for all your restaurant locations with our Locations page.",
    },
    {
      name: "Receipt Builder",
      keyword: [
        "Customize Receipt",
        "Receipt Information",
        "Digital Receipt",
        "email Receipts",
      ],
      link: "/business-settings/receipt-builder",
      support:
        "Understand how to use the Receipt Builder page using our extensive support documents",
      discription:
        "Customize and design branded digital receipts for a lasting impression on customers with our Receipt Builder page.",
    },

    // ACTIVITY LOGS
    {
      name: "Activity Logs",
      keyword: ["Activity log", "IP Address", "Module", "Service"],
      link: "/business-settings/activity-logs",
      discription:
        "A list of all the activity that is going on throughout your system.",
    },
    // CHAT WITH US
    {
      name: "Chat with us",
      keyword: ["Chat with us", "Helpdesk", "Customer Support", "Talk to support", "Connect to eatOS",],
      discription:
        "How can I help your restaurant today?",
      link: "/#",
    },
  ];
  support: any = SupportDetails;
  QuickLinks = [];
  searchitem: string = "";
  supportResults = [];
  searchQuery: string = "";
  recentSearches: any = [];
  rsearch = [];
  retailOsrsearch = [];
  searching = false;
  SupportFilter = [];
  IsLinkhide: boolean = false;
  SupportQucikLinks = [];
  @Output() dataEvent = new EventEmitter<string>();

  ngOnInit(): void {
    this.appAliasService.getValue().subscribe(res => {
      this.StoreName = res.type;

      this.retailView = this.StoreName == "eatos" ? "Restaurant" : "Store";
      this.managment = this.StoreName == "eatos" ? "Menu Management" : "Product Management";
      this.managmentURL = this.StoreName == "eatos" ? "menumanagement/menus/menu-home" : "productmanagment";
      this.restaurantNstore = this.StoreName == "eatos" ? "Restaurant Settings" : "Store Settings";
    });
    this.Browsedata();
    this.QuickLinkData();
    if (this.Data) {
      this.searchitem = this.Data;
    } else {
      this.searchitem = "";
    }
    // get recent search for eatos
    const Details = localStorage.getItem("RecentSearch");
    const Rsearch = JSON.parse(Details);
    const seenNames = {};
    const uniqueArray = Rsearch?.filter((item) => {
      if (!seenNames[item.name]) {
        seenNames[item.name] = true;
        return true;
      }
      return false;
    });
    if (uniqueArray) {
      this.rsearch = uniqueArray;
    } else {
      this.rsearch = [];
    }
    // get recent search for retailos
    const RetailDetails = localStorage.getItem("RetailRecentSearch");
    const RetailRsearch = JSON.parse(RetailDetails);
    const RetailseenNames = {};
    const RetailuniqueArray = RetailRsearch?.filter((item) => {
      if (!RetailseenNames[item.name]) {
        RetailseenNames[item.name] = true;
        return true;
      }
      return false;
    });
    if (RetailuniqueArray) {
      this.retailOsrsearch = RetailuniqueArray;
    } else {
      this.retailOsrsearch = [];
    }

    // console.log("this.QuickLinks", this.QuickLinks);
    // console.log("this.searching", this.searching);
    // console.log("this.searchitem", this.searchitem);
  }

  clearResentSearch() {
   if(this.StoreName === 'Retailos'){
    this.clearRetailResentSearch();
   }
   else{
    this.clearEatosResentSearch();
   }
  }
  clearEatosResentSearch() {
    this.rsearch = [];
    localStorage.removeItem("RecentSearch");
  }
  clearRetailResentSearch() {
    this.retailOsrsearch = [];
    localStorage.removeItem("RetailRecentSearch");
  }
  searchname: any;
  search(query: any): void {
    this.searching = true;
    this.searchname = true;
    this.searchQuery = query.toLowerCase();
    if (this.StoreName === 'eatos') {
      this.QuickLinks = this.searchedLinksModule.filter(
        (item) =>
          item.name.toLowerCase().includes(query.toLowerCase()) ||
          item.keyword?.some((kw) => kw.toLowerCase().includes(this.searchQuery))
      );
    }
    else {
      this.QuickLinks = this.searchedLinksModuleRetail.filter(
        (item) =>
          item.name.toLowerCase().includes(query.toLowerCase()) ||
          item.keyword?.some((kw) => kw.toLowerCase().includes(this.searchQuery))
      );
    }

    this.supportResults = this.support.filter((item) =>
      item.suportName.toLowerCase().startsWith(this.searchQuery)
    );
    if (this.searchitem === "") {
      this.QuickLinks = [];
      this.IsLinkhide = false;
      this.searching = false;
    }
    let search;
    if (this.StoreName === 'eatOS') {
      search = this.searchedLinksModule.filter((item) => {
        if (item.name != query.toLowerCase()) {
          this.IsLinkhide = false;
          this.searching = false;
        }
      });
    } else {
      search = this.searchedLinksModuleRetail.filter((item) => {
        if (item.name != query.toLowerCase()) {
          this.IsLinkhide = false;
          this.searching = false;
        }
      });
    }
  }

  Browsedata() {
    if (this.StoreName === 'eatos') {
    this.Browse = [
      {
        name: "Analytics",
        link: "/reports/sales/gross-transaction/report-home",
        discription:
          "The Analytics and Reports page offers comprehensive insights into your restaurant's sales, transactions, discounts, payroll, and cashout, empowering stakeholders to make data-driven decisions and optimize business performance. Get a clear overview of financial health and customer trends with user-friendly reports and visualizations.",
      },
      {
        name: "Sales",
        link: "/sales/transactions/sales-home",
        discription:
          "Gain valuable insights into your restaurant's performance. Easily access transaction lists, orders, cash drawers, deposits, service charges, discounts, and customer feedback. Utilize flexible filters to tailor the data to your specific needs, empowering you to make well-informed decisions for your business.",
      },
      {
        name: "Transactions",
        link: "/sales/transactions",
        discription:
          "View a comprehensive list of all transactions within any desired timeline.",
      },
      {
        name: "Menu Management",
        link: `/menumanagement/menus/menu-home`,
        // link: "/menumanagement/menus/menu-home",
        discription:
          "Effortlessly manage and customize the various aspects of your Menus with our Menu Management page.",
      },
      {
        name: "Guest",
        link: "/guests/guestbook/guest-home",
        discription:
          "Explore our Guestbook page for a list of guests, our Loyalty Program, and exciting Marketing updates. Join us in fostering meaningful connections and rewarding experiences with your valued patrons.",
      },
      {
        name: "Workforce",
        link: "/employees/employeelist/employee-home",
        discription:
          "The Ultimate tool for efficient workforce management. Access and track employee clock-in and clock-out times, create and edit schedules effortlessly, and keep essential employee information up-to- date. Simplify your operations and enhance productivity with ease.",
      },
      {
        name: "Online Ordering",
        link: "/onlinestore/online-store-home",
        discription:
          "Take full control of your restaurant's online ordering system. Our page allows you to easily set up and customize the portal according to your preferences. Modify menu items, adjust delivery options, and manage settings effortlessly. Enjoy the flexibility of changing your online ordering settings whenever you need to, providing your customers with a seamless and tailored ordering experience.",
      },
      {
        name: "Hardware",
        link: "/hardware/hardware",
        discription:
          "A list of all the devices that are connected to your system.",
      },
      // { name: "Inventory", link: "" ,discription:"Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad"},
      {
        name: "Integrations",
        link: "/integration-marketplace",
        discription:
          "Connect eatOS with 3rd party applications to seamlessly manage your restaurants data from a single point.",
      },
      {
        name: "Restaurant Settings",
        link: "/business-settings/location/resturant-setting-home",
        discription:
          "Effortlessly manage restaurant location details and customize digital receipts for a personalized touch. Update information for all your restaurant locations and leave a lasting impression on customers with branded digital receipts.",
      },
      {
        name: "Activity Logs",
        link: "/business-settings/activity-logs",
        discription:
          "A list of all the activity that is going on throughout your system.",
      },
    ];
  }
  else {
    this.Browse = [
      {
        name: "Analytics",
        link: "/reports/sales/gross-transaction/report-home",
        discription:
          "The Analytics and Reports page offers comprehensive insights into your restaurant's sales, transactions, discounts, payroll, and cashout, empowering stakeholders to make data-driven decisions and optimize business performance. Get a clear overview of financial health and customer trends with user-friendly reports and visualizations.",
      },
      {
        name: "Sales",
        link: "/sales/transactions/sales-home",
        discription:
          "Gain valuable insights into your restaurant's performance. Easily access transaction lists, orders, cash drawers, deposits, service charges, discounts, and customer feedback. Utilize flexible filters to tailor the data to your specific needs, empowering you to make well-informed decisions for your business.",
      },
      {
        name: "Transactions",
        link: "/sales/transactions",
        discription:
          "View a comprehensive list of all transactions within any desired timeline.",
      },
      {
        name: "Product Management",
        link: `/productmanagment`,
        // link: "/menumanagement/menus/menu-home",
        discription:
          "Effortlessly manage and customize the various aspects of your Menus with our Menu Management page.",
      },
      {
        name: "Guest",
        link: "/guests/guestbook/guest-home",
        discription:
          "Explore our Guestbook page for a list of guests, our Loyalty Program, and exciting Marketing updates. Join us in fostering meaningful connections and rewarding experiences with your valued patrons.",
      },
      {
        name: "Workforce",
        link: "/employees/employeelist/employee-home",
        discription:
          "The Ultimate tool for efficient workforce management. Access and track employee clock-in and clock-out times, create and edit schedules effortlessly, and keep essential employee information up-to- date. Simplify your operations and enhance productivity with ease.",
      },
      // {
      //   name: "Online Ordering",
      //   link: "/onlinestore/online-store-home",
      //   discription:
      //     "Take full control of your restaurant's online ordering system. Our page allows you to easily set up and customize the portal according to your preferences. Modify menu items, adjust delivery options, and manage settings effortlessly. Enjoy the flexibility of changing your online ordering settings whenever you need to, providing your customers with a seamless and tailored ordering experience.",
      // },
      {
        name: "Hardware",
        link: "/hardware/hardware",
        discription:
          "A list of all the devices that are connected to your system.",
      },
      // { name: "Inventory", link: "" ,discription:"Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad"},
      {
        name: "Integrations",
        link: "/integration-marketplace",
        discription:
          "Connect eatOS with 3rd party applications to seamlessly manage your restaurants data from a single point.",
      },
      {
        name: "Store Settings",
        link: "/business-settings/location/resturant-setting-home",
        discription:
          "Effortlessly manage restaurant location details and customize digital receipts for a personalized touch. Update information for all your restaurant locations and leave a lasting impression on customers with branded digital receipts.",
      },
      {
        name: "Activity Logs",
        link: "/business-settings/activity-logs",
        discription:
          "A list of all the activity that is going on throughout your system.",
      },
    ];

  }
  }
  QuickLinkData(){
    if (this.StoreName === 'eatos') {
      this.QuickLinksModule = [
        {
          name: "Sales",
          link: "/sales/transactions/sales-home",
          discription:
            "Gain valuable insights into your restaurant's performance. Easily access transaction lists, orders, cash drawers, deposits, service charges, discounts, and customer feedback. Utilize flexible filters to tailor the data to your specific needs, empowering you to make well-informed decisions for your business.",
        },

        // {
        //   name: "Summary",
        //   link: "",discription:"Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad"
        // },
        // { name: "Sales Transaction", link: "/reports/sales/gross-transaction",discription:"Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad" },
        // { name: "Daily Services Transactions", link: "/reports/sales/gross-shift",discription:"Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad" },
        // { name: "ltemized Sales", link: "/reports/sales/gross-details",discription:"Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad" },
        // { name: "Cashout", link: "/reports/labor/cashout",discription:"Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad" },
        // { name: "Payroll", link: "/reports/labor/payroll",discription:"Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad" },
        // { name: "Discounts", link: "/reports/exception/discount",discription:"Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad" },
        // { name: "Refunds And Voids", link: "/reports/exception/refunds-and-voids",discription:"Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad" },
        // { name: "Cancelled Orders", link: "/reports/exception/cancel-order",discription:"Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad" },

        // { name: "Transactions", link: "/sales/transactions",discription:"Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad" },
        {
          name: "Summary",
          link: "/home",
          discription:
            "Get an overview of your store's Sales, Orders, Transactions and discounts.",
        },
        // { name: "Cash Drawers", link: "/sales/cashdrawers",discription:"Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad" },
        // { name: "Deposits", link: "/sales/deposits",discription:"Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad" },
        // { name: "Service charges", link: "/sales/service-charges" ,discription:"Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad"},
        // { name: "Discounts", link: "/sales/discounts",discription:"Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad" },
        // { name: "Feedback", link: "/sales/feedback",discription:"Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad" },

        {
          name: "Menus",
          link: "/menumanagement/menus",
          discription:
            "View and customize your restaurant menus with ease using our Menus page.",
        },
        {
          name: "Orders",
          link: "/sales/orders",
          discription:
            "Explore a detailed list of all orders and their corresponding details within any desired timeline.",
        },
        {
          name: "Payments",
          link: "/onlinestore/payment",
          discription:
            "Easily manage online payment options and preferences with our Payment page.",
        },
        {
          name: "Employees",
          link: "/employees/employeelist",
          discription:
            "Discover the intuitive interface that enables restaurant owners and managers to access and manage employee information, work schedules, and time tracking data.",
        },
        {
          name: "Roles",
          link: "/employees/roles",
          discription:
            "Uncover the power of creating and customizing roles, such as manager, staff, supervisor, and more, to efficiently organize your workforce hierarchy. Learn how to define specific permissions and access levels for each role, ensuring secure data handling and task delegation.",
        },
        {
          name: "Products",
          link: "/menumanagement/products",
          discription:
            "Manage and organize your menu products efficiently with our Products page.",
        },
        {
          name: "Ingredients",
          link: "/menumanagement/ingredients",
          discription:
            "Easily manage and track the ingredients required in your products with our Ingredient page.",
        },

        {
          name: "Modifiers",
          link: "/menumanagement/modifiers",
          discription:
            "Customize and adjust menu modifiers effortlessly with our Modifiers page.",
        },
      ];
    }
    else {
      this.QuickLinksModule = [
        {
          name: "Sales",
          link: "/sales/transactions/sales-home",
          discription:
            "Gain valuable insights into your restaurant's performance. Easily access transaction lists, orders, cash drawers, deposits, service charges, discounts, and customer feedback. Utilize flexible filters to tailor the data to your specific needs, empowering you to make well-informed decisions for your business.",
        },
        {
          name: "Summary",
          link: "/home",
          discription:
            "Get an overview of your store's Sales, Orders, Transactions and discounts.",
        },
        {
          name: "Orders",
          link: "/sales/orders",
          discription:
            "Explore a detailed list of all orders and their corresponding details within any desired timeline.",
        },
        {
          name: "Employees",
          link: "/employees/employeelist",
          discription:
            "Discover the intuitive interface that enables restaurant owners and managers to access and manage employee information, work schedules, and time tracking data.",
        },
        {
          name: "Roles",
          link: "/employees/roles",
          discription:
            "Uncover the power of creating and customizing roles, such as manager, staff, supervisor, and more, to efficiently organize your workforce hierarchy. Learn how to define specific permissions and access levels for each role, ensuring secure data handling and task delegation.",
        },
        {
          name: "Products",
          link: "/productmanagment/products",
          discription:
            "Manage and organize your menu products efficiently with our Products page.",
        },
      ];
    }
  }
  closeModel() {
    setTimeout(() => {
      this.modalService.dismissAll();
    }, 1000);
  }
  completeSearch() {
    this.closeModel();
    this.searching = false;
    this.IsLinkhide = true;
    this.searchname = false;

    // Check if we have a matching quick link first
    const selectedLink = this.searchedLinksModule.find(
      (item) => item.name.toLowerCase() === this.searchitem.toLowerCase()
    ) || this.searchedLinksModuleRetail.find(
      (item) => item.name.toLowerCase() === this.searchitem.toLowerCase()
    );
    if (selectedLink) {
      console.log("selectedLink", selectedLink);
      if (selectedLink.link === '/#') {
        this.chatWithUs(selectedLink.name);
      } else {
        window.location.href = '/#/' + selectedLink.link;
      }
      return;
    }

    // Then try support items
    this.SupportFilter = this.support.filter((item) =>
      item.suportName.toLowerCase().includes(this.searchitem.toLowerCase())
    );
    this.SupportQucikLinks = this.Browse.filter((result) => {
      if (result.name == this.searchitem) {
        return result;
      }
    },
);
    // Close the search overlay if any
    this.offcanvasService.dismiss("cross click");
}
  highlightSearchTerm(item: string): string {
    if (!this.searchitem) {
      return item;
    }
    const startIndex = item
      .toLowerCase()
      .indexOf(this.searchitem.toLowerCase());
    const endIndex = startIndex + this.searchitem.length;
    const highlightedItem =
      item.substring(0, startIndex) +
      `<span class="highlight">${item.substring(startIndex, endIndex)}</span>` +
      item.substring(endIndex);

    return highlightedItem;
  }
  updateRecentSearches(query: any): void {
    console.log("query", query);
    if (query) {
      this.rsearch.push(query);
    }
    if (this.rsearch.length > 4) {
      this.rsearch.shift();
    }
    localStorage.setItem("RecentSearch", JSON.stringify(this.rsearch));
  }
  updateRetailRecentSearches(query: any): void {
    console.log("query", query);
    if (query) {
      this.retailOsrsearch.push(query);
    }
    if (this.retailOsrsearch.length > 4) {
      this.retailOsrsearch.shift();
    }
    localStorage.setItem("RetailRecentSearch", JSON.stringify(this.retailOsrsearch));
  }
  DeleteRSearch(value: any) {
    const indexToRemove = this.rsearch.findIndex((item) => item.name === value);

    if (indexToRemove !== -1) {
      this.rsearch.splice(indexToRemove, 1);
    }
    localStorage.setItem("RecentSearch", JSON.stringify(this.rsearch));
  }
  DeleteRetailRSearch(value: any) {
    const indexToRemove = this.retailOsrsearch.findIndex((item) => item.name === value);

    if (indexToRemove !== -1) {
      this.retailOsrsearch.splice(indexToRemove, 1);
    }
    localStorage.setItem("RetailRecentSearch", JSON.stringify(this.retailOsrsearch));
  }
  setMenuAcrivation(value: any) {

    if (this.StoreName === 'Retailos') {
      this.updateRetailRecentSearches(value);
    }
    else this.updateRecentSearches(value);

    if (value.link != "/sales/transactions") {
      this.SetActiveMenuClass(value.link);
    } else {
      this.SetActiveMenuClass("/sales/transactions/sales-home");
    }
    setTimeout(() => {
      this.modalService.dismissAll();
    }, 1000);
    const sendDetails = "Focus-none";
    this.dataEvent.emit(sendDetails);
  }

  QuickLink(query: any) {
    if (this.StoreName === 'Retailos') {
      this.updateRetailRecentSearches(query);
      this.DeleteRetailRSearch(query);
    }
    else {
      this.updateRecentSearches(query);
      this.DeleteRSearch(query);
    }
    const path = query.link;
    const moduleName = path.split("/")[1];
    const activeMenu = this.menuActive(path);

    console.log("pathpathpathpath", path);
    this.SetActiveMenuClass(activeMenu);
  }
  menuActive(value: any) {
    if (value === "/home") {
      return "/home";
    }
    const path = value.split("/")[1];
    if (path === "sales") {
      return "/sales/transactions/sales-home";
    } else if (path === "reports") {
      return "/reports/sales/gross-transaction/report-home";
    } else if (path === "menumanagement") {
      return "/menumanagement/menus/menu-home";
    } else if (path === "guests") {
      return "/guests/guestbook/guest-home";
    } else if (path === "employees") {
      if (
        value === "/employees/jobtypes/employee-stup-home" ||
        value === "/employees/roles"
      ) {
        const linkDetails: any = {
          show_menu: "employees-setup",
          active_path: value,
        };
        localStorage.setItem("subDivPath", JSON.stringify(linkDetails));
        return "/employees/jobtypes/employee-stup-home";
      } else {
        const linkDetails: any = {
          show_menu: "employees",
          active_path: value,
        };
        localStorage.setItem("subDivPath", JSON.stringify(linkDetails));
        return "/employees/employeelist/employee-home";
      }
    } else if (path === "restaurant") {
      return "/restaurant/general/restaurat-home";
    } else if (path === "onlinestore") {
      return "/onlinestore/online-store-home";
    } else if (path === "business-settings") {
      return "/business-settings/location/resturant-setting-home";
    }
  }

  QueryUpdateFuncation(Details: any) {
    console.log("Details", Details)
    if (Details.name === "Chat with us") {
      this.chatWithUs(Details.name , Details.link);
      this.router.navigate([this.router.url]);
    } else {
      if (this.StoreName === 'Retailos') {
        this.updateRetailRecentSearches(Details);
      }
      else this.updateRecentSearches(Details);
      const path = Details.link;
      const moduleName = path.split("/")[1];
      const activeMenu = this.menuActive(path);
      this.SetActiveMenuClass(activeMenu);
    }
    setTimeout(() => {
      this.addFadeOutEffect();
      this.modalService.dismissAll();
    }, 0);

    const sendDetails = "Focus-none";
    // const sendDetails = "";

    this.dataEvent.emit(sendDetails);
    this.offcanvasService.dismiss("cross click");
  }
  chatWithUs(item, link?) {
    if (item == "Chat with us") {
      // set window to access crisp object
      let Window: any = window;
      console.log("link", link);
      if (Window.$crisp) {
        Window.$crisp.push(["do", "chat:show"]);
        Window.$crisp.push(["do", "chat:open"]);
        localStorage.setItem("crisp_chat_opened", "true");
      } else {
        return;
      }
    } else {
      return;
    }
  }

  addFadeOutEffect() {
    let bodyClass = this.document.body;
    setTimeout(() => {
      bodyClass.classList.add("Focus-none");
    }, 0);
  }

  // navigateToHome(e:Event){
  //     // location.href = '/home'
  //     this.router.navigate(['/home']);
  // }
  Backfunction() {
    const sendDetails = "Focus-none";
    this.dataEvent.emit(sendDetails);
    this.SetActiveMenuClass("/home");
    this.router.navigate(["/home"]);
    this.modalService.dismissAll();
    this.offcanvasService.dismiss("cross click");
   setTimeout(() => {
    const bodyClasses = document.body.classList;
    if (bodyClasses[1] === 'Focus-none') {
      this.renderer.removeClass(this.document.body, "Focus-none");
    }
   }, 1000);
  }
  SetActiveMenuClass(Data: any) {
    localStorage.setItem("ActivemenuClass", Data);
    this.offcanvasService.dismiss("cross click");
  }

  clearSearched() {
    this.searchitem = "";
    this.QuickLinks = [];
    this.supportResults = [];
  }
}
