import { locationFetchStart, locationFetchSuccess, locationSelect, locationSelectStart } from './location.actions';
import { createReducer, on, Store } from '@ngrx/store';
import { initialState } from './location.state';

const _locationReducer = createReducer(
  initialState,
  on(locationFetchStart, (state) => {
    return {
      ...state,
      locations: []
    };
  }),
  on(locationFetchSuccess, (state, action) => {
    return {
      ...state,
      locations: action.locations !== null ? [...action.locations] : [],
      show: true
    };
  }),
  on(locationSelect, (state, action) => {
    return {
      ...state,
      selected: action.selected,
      show: true
    };
  }),
  on(locationSelectStart, (state, action) => {
    return {
      ...state,
      show: action.show
    };
  })
);

export function LocationReducer(state, action) {
  return _locationReducer(state, action);
}