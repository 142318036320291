import { Observable } from "rxjs";
import { AuthRes } from "../models/auth.models";
import { environment } from "../../../environments/environment";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AppState } from "../../store/app.state";
import { Store } from "@ngrx/store";
// import { autoLogout } from '../../account/auth/state/auth.actions';
import { CookieService } from "ngx-cookie-service";
import { AlertService } from "./AlertService/alert.service";
import { ApiService } from "./../../services/api";

// AuthO
import { AuthService } from '@auth0/auth0-angular';

@Injectable({
  providedIn: "root",
})
export class AuthenticationService {
  timeoutInterval: any;
  GLOBALURL: String = environment.globalUrlApi;
  constructor(
    private http: HttpClient,
    private store: Store<AppState>,
    private cookieService: CookieService,
    private toastr: AlertService,
    private ApiService: ApiService,
    private authO: AuthService
  ) {}

  accessToken: any = this.cookieService.get("token");

  // AuthO Testing
  loginWithSocialProvider(provider: string): void {
    this.authO.loginWithRedirect(
      // {
      // screen_hint: 'login', connection: 'google-authO'
    // }
    );
  }

  //sending login credentials to server for validate
  login(email: string, password: string): Observable<AuthRes> {
    const params = {
      username: email,
      password: password,
    };
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
    };
    return this.http.post<AuthRes>(
      this.GLOBALURL + "/user/login",
      params,
      httpOptions
    );
  }

  validate2FA(payload) {
    return this.http
      .post(this.GLOBALURL + "/user/2fa/validate", payload, {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
          Authorization: this.accessToken,
        }),
      })
      .subscribe(
        (response: any) => {
          if (response.success === 0) {
            this.toastr.error(response.message);
          } else {
            this.toastr.success(response.message);
          }
        },
        (error) => {
          console.log(error);
          let message = this.ApiService.errorMessage(error);

          this.toastr.error(message);
        }
      );
  }

  create2FA(payload) {
    return this.http
      .post(this.GLOBALURL + "/user/2fa/create", payload, {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
          Authorization: this.accessToken,
        }),
      })
      .subscribe(
        (response: any) => {
          if (response.success === 0) {
            this.toastr.error(response.message);
          } else {
            this.toastr.success(response.message);
          }
        },
        (error) => {
          console.log(error);
          let message = this.ApiService.errorMessage(error);

          this.toastr.error(message);
        }
      );
  }
  //sending login credentials to server for validate
  magicLinkLogin(token: any): Observable<AuthRes> {
    const params = {
      token: token,
    };
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
    };
    return this.http.post<AuthRes>(
      this.GLOBALURL + "/user/loginmagiclink",
      params,
      httpOptions
    );
  }
  //sending login credentials to server for validate
  crossLinkLogin(token: any): Observable<AuthRes> {
    const params = {
      token: token,
    };
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
    };
    return this.http.post<AuthRes>(
      this.GLOBALURL + "/user/loginmagiclink",
      params,
      httpOptions
    );
  }
  //Get New Acccss token using existing
  refreshToken() {
    let token = this.cookieService.get("token");
    const params = {
      token: token,
    };
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
    };
    return this.http.post<AuthRes>(
      this.GLOBALURL + "/user/loginmagiclink",
      params,
      httpOptions
    );
  }

  loginWithSSO(payload) {
    return this.http.post(this.GLOBALURL + "/user/social/login", payload, {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        // Authorization: this.cookieService.get("token"),
      }),
    });
  }

  signUp(email: string, password: string): Observable<AuthRes> {
    return this.http.post<AuthRes>(this.GLOBALURL + "/user/login", {
      email,
      password,
      returnSecureToken: true,
    });
  }
  //get token
  getToken() {
    this.cookieService.get("token");
  }
  //save token
  saveToken(token: any) {
    this.cookieService.set("token", token, 70, "/");
  }

  //   formatUser(data: AuthRes) {

  //     const user = new User(
  //       isReseller: data.response.isReSelleer,
  //       employeeId: data.response.employeeId,
  //       username: data.response.username,
  //       merchantId: data.response.merchantId,
  //       storeId: data.response.storeId,
  //       role: data.response.role,
  //       subscription: data.subscription.,
  //       deviceInfo: data.subscription.,
  //       token: data.subscription.

  //     );
  //     return user;
  //   }

  getErrorMessage(message: string) {
    switch (message) {
      case "EMAIL_NOT_FOUND":
        return "Email Not Found";
      case "INVALID_PASSWORD":
        return "Invalid Password";
      case "EMAIL_EXISTS":
        return "Email already exists";
      default:
        return "Unknown error occurred. Please try again";
    }
  }

  async setUserInLocalStorage(user: AuthRes, has2FA?: any) {
    localStorage.setItem("userData", JSON.stringify(user));
    // Call the userStorePermission method if has2FA is 0
    // console.log("has2FA", has2FA)
    if (has2FA == 0) {
      await this.userStorePermission(user)
    }
    //this.runTimeoutInterval(user);
  }

  // This implementation can be removed
  // This implementation can be removed
  // This implementation can be removed
  async userStorePermission({ response: { storeId, employeeId } }) {
    localStorage.setItem("userStorePermissions", JSON.stringify([]))
    // try {
    //   const { success, response } : any = await this.http
    //     .get(this.GLOBALURL + "/role/user-store-permissions", {
    //       headers: new HttpHeaders({
    //         "Content-Type": "application/json",
    //         Authorization: this.accessToken,
    //       }),
    //       params: new HttpParams()
    //       .set("storeId", storeId)
    //       .set("employeeId", employeeId)
    //     })
    //     .toPromise();

    //     localStorage.setItem(
    //       "userStorePermissions",
    //       JSON.stringify(
    //         success ? response.permissions : []
    //       )
    //     )

    // } catch (error) {
    //   console.error("Error fetching store permissions:", error);
    //   // Handle the error as needed
    // }
  }




  runTimeoutInterval(user: any) {
    console.log("RUN TIMEOUT INTERVAL");
    const todaysDate = new Date();
    const dateObj = new Date(todaysDate);
    const seconds = dateObj.getTime() / 1000;
    const convertedTime = new Date(seconds * 1000);
    const year = convertedTime.getFullYear();
    const month = convertedTime.getMonth() + 1; // Months are zero-based, so adding 1
    const day = convertedTime.getDate();
    const hours = convertedTime.getHours();
    const minutes = convertedTime.getMinutes();
    const secondss = convertedTime.getSeconds();
    const formattedTime = `${year}-${month < 10 ? "0" + month : month}-${
      day < 10 ? "0" + day : day
    } ${hours}:${minutes}:${secondss}`;
    console.log("formattedTime", formattedTime);
    const expirationDate = user?.response?.sessionDetails?.expiryTime;

    // const expirationDate =1684996713
    const expiryTime = new Date(expirationDate * 1000);

    const eyear = expiryTime.getFullYear();
    const emonth = expiryTime.getMonth() + 1; // Months are zero-based, so adding 1
    const eday = expiryTime.getDate();
    const ehours = expiryTime.getHours();
    const eminutes = expiryTime.getMinutes();
    const esecondss = expiryTime.getSeconds();
    const eformattedTime = `${eyear}-${emonth < 10 ? "0" + emonth : emonth}-${
      eday < 10 ? "0" + eday : eday
    } ${ehours}:${eminutes}:${esecondss}`;
    console.log("eformattedTime", eformattedTime);

    if (formattedTime === eformattedTime) {
      this.logout();
    }

    // const timeInterval = expirationDate - todaysDate;
    // console.log("timeInterval",timeInterval);

    // this.timeoutInterval = setTimeout(() => {
    //   this.logout()
    //   //logout functionality or get the refresh token
    // }, timeInterval);
  }

  getUserFromLocalStorage() {
    const userDataString = localStorage.getItem("userData");
    if (userDataString) {
      const userData = JSON.parse(userDataString);
      const user = userData;
      //this.runTimeoutInterval(user);
      return user;
    }
    return null;
  }
  getClosingDay(){
    const closingDay = localStorage.getItem("ClosingDay");
    if (closingDay) {
      return closingDay;
    }
    return null;
  }

  logout() {
    localStorage.clear();
    const email = this.cookieService.get("email");
    const password = this.cookieService.get("password");
    const rememberMe = this.cookieService.get("rememberMe");
    // if(this.cookieService.get('securityCheck')) {
    //   this.cookieService.deleteAll('/');
    //   let cookieData = this.cookieService.get('securityCheck')
    //   this.cookieService.set("securityCheck", cookieData, 1);
    // } else {
    this.cookieService.deleteAll("/");
    if (rememberMe) {
      if (email) {
        localStorage.setItem('email', email);
        this.cookieService.set("email", email, 1);
      }
      if (password) {
        localStorage.setItem('password', password);
        this.cookieService.set("password", password, 1);
      }
      localStorage.setItem('rememberMe', rememberMe);
      this.cookieService.set("rememberMe", rememberMe, 1);
    }
    // }

    // if (this.timeoutInterval) {
    //   clearTimeout(this.timeoutInterval);
    //   this.timeoutInterval = null;
    // }
  }
}
