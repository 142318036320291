<!-- breadcrumb item -->
<ng-container *ngIf="!layout">
  <div class="d-flex justify-content-between welcome-note align-items-center">
    <div class="">
      <h5 class="fw-sm-700 fw-600 f-sm-16 f-14 text-capitalize m-0 page-data">
        <span class="ts" *ngIf="hasUrlBreadrumb()">
          <ng-container *ngFor="let item of breadcrumbList">
            <ng-container *ngIf="!item.active">
              <a routerLink="{{item.url}}">
                {{ item.label | translate | async }}
              </a> /
            </ng-container>
            <ng-container *ngIf="item.active">
              <a class="text-black">
                {{ item.label | translate | async }}
              </a>
            </ng-container>
          </ng-container>
        </span>
      </h5>
    </div>
    <div class="d-flex align-items-baseline flex-wrap gap-3 trening-part justify-content-end"
      *ngIf="storeName !== 'undefined'">
      <div *ngIf="timezoneName1" class="px-3 d-flex align-items-center gap-0 welcome-text">
        <p class="m-0 f-14 fw-500" style="color: #666">{{ formateDate }}</p>
        <p class="m-0 f-14 fw-500" style="color: #666">({{ timezoneName1 }})</p>
      </div>
      <!-- <div>
        <div class="d-flex align-items-center gap-4">
          <div class="form-check form-switch header-switch f-14 align-items-center">
            <ui-switch defaultBoColor="#dfdfdf" color="#666666" uncheckedLabel="OFF" checkedLabel="ON" size="small"
              [checked]="checkBoxValue" (change)="toggleTestMode($event)"></ui-switch>
            <label class="form-check-label me-2" for="flexSwitchCheckChecked1">Training Mode</label>
          </div>
        </div>
      </div> -->
      <!-- <div class="form-check form-switch header-switch mb-3 f-14">
        <label class="form-check-label me-2" for="flexSwitchCheckChecked1">{{storeName}}</label>
      </div> -->
    </div>
  </div>
</ng-container>
<ng-container class="d-flex align-items-center gap-2" *ngIf="layout && !isSuperAdmin">
  <app-location-menu (changed)="change($event)" (newStore)="toggleTestModeByNewStore()"
    [class]="'mb-0 w-100 pl-10px location-dropdown'"></app-location-menu>
</ng-container>
