
import { createAction, props } from '@ngrx/store';
import { AuthRes } from 'src/app/core/models/auth.models';
export const LOGIN_START = '[auth page] login start';
export const MAGIC_LINK_LOGIN_START = '[auth page] magic link login start';
export const CROSS_LINK_LOGIN_START = '[auth page] cross link login start';
export const CROSS_LINK_LOGIN_SUCCESS = '[auth page] cross link login success';
export const LOGIN_SUCCESS = '[auth page] login Success';
export const LOGIN_FAIL = '[auth page] login Fail';

export const LOGIN_SSO_START = '[auth page] login sso start';
export const LOGIN_SSO_SUCCESS = '[auth page] login sso Success';
export const LOGIN_SSO_FAIL = '[auth page] login sso Fail';

export const SIGNUP_START = '[auth page] signup start';
export const SIGNUP_SUCCESS = '[auth page] signup success';
export const AUTO_LOGIN_ACTION = '[auth page] auto login';
export const LOGOUT_ACTION = '[auth page] logout';
//for login start action
export const loginStart = createAction(
  LOGIN_START,
  props<{ email: string; password: string }>()
);
//for login start action
export const magicLinkLoginStart = createAction(
  MAGIC_LINK_LOGIN_START,
  props<{ token:any, returnUrl:any}>()
);
//for login start action
export const crossLinkLoginStart = createAction(
  CROSS_LINK_LOGIN_START,
  props<{ token:any, returnUrl:any}>()
);
export const crossLinkLoginSuccess = createAction(
  CROSS_LINK_LOGIN_SUCCESS,
  props<{ user: AuthRes; redirect: boolean, returnUrl:any }>()
);
//for login fail action
export const loginSuccess = createAction(
  LOGIN_SUCCESS,
  props<{ user: AuthRes; redirect: boolean, redirect2fa?: boolean }>()
);
//for login failure action
export const loginFailure = createAction(
  LOGIN_FAIL,
  props<{ loader: Boolean }>()
);

export const loginWithSSOStart = createAction(
  LOGIN_SSO_START,
  props<{ payload: any }>()
);

export const signupStart = createAction(
  SIGNUP_START,
  props<{ email: string; password: string }>()
);

export const signupSuccess = createAction(
  SIGNUP_SUCCESS,
  props<{ user: AuthRes; redirect: boolean, redirect2fa: boolean }>()
);

export const autoLogin = createAction(AUTO_LOGIN_ACTION);
export const autoLogout = createAction(LOGOUT_ACTION);
