import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SalesTransactionState } from './transaction.state';
export const SALES_TRANSACTION_SUMMARY_STATE_NAME = 'salesTransaction';

const getSalesTransactionState = createFeatureSelector<SalesTransactionState>(SALES_TRANSACTION_SUMMARY_STATE_NAME);


//selector for getting Sales orders summary info 
export const getSalesTransactionSummaryData = createSelector(getSalesTransactionState, (state) => {
  return state.data 
});

//selector for getting Sales orders meta data info
export const getSalesOrderSummaryMetaInfo = createSelector(getSalesTransactionState, (state) => {
  return state.meta
} )

//selector for getting Sales employee data
export const getSalesTransactionEmployeeData = createSelector(getSalesTransactionState, (state) => {
  return state.employee
} )

//selector for getting Sales orders data
export const getSalesTransactions = createSelector(getSalesTransactionState, (state) => {
  return state.transactions
} )
//selector for getting Sales orders data
export const getSalesTransactionMetaData = createSelector(getSalesTransactionState, (state) => {
  return state.pageMetaData
} )

//selector for getting Sales orders data
export const getActiveEmployees = createSelector(getSalesTransactionState, (state) => {
  return state.activeEmployees
} )