import { DOCUMENT } from "@angular/common";
import {
  Component,
  EventEmitter,
  HostListener,
  Inject,
  NgZone,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
} from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { NgbAccordion, NgbOffcanvas } from "@ng-bootstrap/ng-bootstrap";
import { Store } from "@ngrx/store";
import { CookieService } from "ngx-cookie-service";
import { Subject, interval } from "rxjs";
import { filter, takeUntil } from "rxjs/operators";
import { AppAliasService } from "src/app/core/services/app-alias.service";
import { EventService } from "src/app/core/services/event.service";
import { MenuService } from "src/app/core/services/menu.service";
import { AppState } from "src/app/store/app.state";
import { getLoading } from "src/app/store/sharedstate/shared.selector";

@Component({
  selector: "app-submenuhome",
  templateUrl: "./submenuhome.component.html",
  styleUrls: ["./submenuhome.component.scss"],
})
export class SubmenuhomeComponent implements OnInit,OnDestroy {
  // private destroy$ = new Subject<void>();
  private destroy$: Subject<void> = new Subject<void>();

  @ViewChild("acc") accordion: NgbAccordion;
  @Output() SubmenuDetails: EventEmitter<string> = new EventEmitter<string>();

  taxAliasValue: any = this.cookieService.get("taxAliasValue");
  loading: any = false;

  subsidemenuActive: any;
  ActiveReportID: any;
  initialActiveIdSet = false;
  retailView: any;
  constructor(
    private router: Router,
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    private appAliasService: AppAliasService,
    private menuService: MenuService,
    private store: Store<AppState>,
    private eventService: EventService,
    private ngZone: NgZone,
    private offcanvasService: NgbOffcanvas,
    private cookieService: CookieService,
  ) {
    this.taxAliasValue = this.cookieService.get("taxAliasValue");
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event) => {
        this.urlsegment = event;
        const path = this.urlsegment.url;

        this.SetFormatedCode(path);
        //   if (path === '/reports/sales/gross-transaction/report-home') {
        //   this.setActiveId('config-panel-salesf')
        // }
      });

      // create a observable so when the value of the cookie changes, we can update the taxAliasValue
      // this.taxAliasValue = new Observable((observer) => {
        // setInterval(() => {
        //   // observer.next(this.cookieService.get("taxAliasValue"));
        //   this.taxAliasValue = this.cookieService.get("taxAliasValue");
        // }, 3000);
        interval(1000)
    .pipe(takeUntil(this.destroy$))
    .subscribe(() => {
      this.taxAliasValue = this.cookieService.get("taxAliasValue");
    });
      // });
  }

  className: any;
  Showmenu: any;
  urlsegment: any;
  screenWidth: any;
  ServiceShareData: any;
  isSuperAdmin = this.cookieService.get("superAdminPage") === "3" ? true : false;

  classmenu: boolean = false;
  mainscreenWidth: any;
  activeMenuLink: any;
  interval$ = interval(1000);
  ngOnInit(): void {
    this.taxAliasValue = this.cookieService.get("taxAliasValue");
    this.screenWidth = window.innerWidth;
    this.appAliasService.getValue().subscribe(res => {
      let Name = res.type;
      Name == "eatos" ? this.retailView = "Restaurant" : this.retailView = "Store";
      });
    this.GetSubmenuStatus();
    setTimeout(() => {
      this.ActiveSubSideMenu();
    }, 0);
    const name = localStorage.getItem("subDivPath");
    this.activeMenuLink = JSON.parse(name);

    // this.Showmenu = this.activeMenuLink.show_menu;
    const currentURL = window.location.href;
    // Remove the base URL and hash
    const path = currentURL.replace(/^.*?#/, "");
    this.SetFormatedCode(path);

    const segments = path.split("/").filter((segment) => segment !== "");

    // Create the object
    const result = {
      show_menu: segments[0] || "",
      active_path: "/" + segments.slice(1).join("/"),
    };

    console.log("currentURL", currentURL);

    if (this.accordion) {
      setTimeout(() => {
        this.setAccodianActive(this.activeMenuLink.active_path);
      }, 0);
    }
    const body = this.document.body;
    this.screenWidth = window.innerWidth;
    if (this.Showmenu != undefined) {
      if (
        this.Showmenu === "merchants" ||
        this.Showmenu === "integration-marketplace" ||
        this.Showmenu === "Activity Logs" ||
        this.Showmenu === "home"
      ) {
        body.className = "";
        if (this.screenWidth < 768) {
          body.classList.remove("sidebar-enable");
          body.classList.add("vertical-collpsed");
        } else {
          body.classList.remove("vertical-collpsed");
          body.classList.add("sidebar-enable");
        }
      } else {
        body.className = "";
        body.classList.remove("sidebar-enable");
        body.classList.add("vertical-collpsed");
      }
    }

    this.loading = this.store.select(getLoading);
    this.UpgradeSubmenu();
    this.SetSubActiveClass();
    console.log("this.show", this.Showmenu);
  }
  @HostListener("window:resize", ["$event"])
  onResize(event) {
    this.screenWidth = window.innerWidth;
    this.mainscreenWidth = event.target.innerWidth;
    this.checkScreenWidth(event.target.innerWidth); // Update the class on screen width change
  }

  checkScreenWidth(width: number) {
    if (width < 992) {
      this.classmenu = true;
    } else {
      this.classmenu = false;
    }
  }
  AddSubmneuActive(path: any) {
    if (path === "/business-settings/activity-logs") {
      this.Showmenu = "Activity-Logs";
      const linkDetails: any = {
        show_menu: "Activity-Logs",
        active_path: path,
      };
      return this.SetmenuPath(linkDetails);
    }

    const firstWord = path.split("/")[1];


    this.setAccodianActive(path);
    this.GetRouterDetails(path);
    if (firstWord == "employees") {
      let baseUrl = path.substring(0, path.lastIndexOf("/") + 1);
      if (baseUrl == "/employees/employeelist/") {
        this.Showmenu = "employees";
        const linkDetails: any = {
          show_menu: "employees",
          active_path: path,
        };
        this.SetmenuPath(linkDetails);
      } else if (baseUrl === "/employees/jobtypes/") {
        this.Showmenu = "employees";
        const linkDetails: any = {
          show_menu: "employees",
          active_path: path,
        };
        this.SetmenuPath(linkDetails);
      }
    } else {
      this.Showmenu = firstWord;
      if (firstWord === "profile") {
        const linkDetails: any = {
          show_menu: "home",
          active_path: path,
        };
        this.SetmenuPath(linkDetails);
      } else {
        const linkDetails: any = {
          show_menu: this.Showmenu,
          active_path: path,
        };

        this.SetmenuPath(linkDetails);
      }
    }
  }

  // Merchant SUB MENU HTML CODE
//   <div
//   *ngIf="Showmenu === 'merchants'"
//   class="sub-menu-main"
//   [ngClass]="classmenu ? 'hide-sidebar' : ' '"
// >
//   <div>
//     <div class="discription-section">
//       <button class="left-menu" (click)="sendDataToTopbar()">
//         <i class="fa-solid fa-chevron-left"></i>
//       </button>
//       <div class="heading-part">
//         <img
//           src="assets\images\merchants-img\merchant.svg"
//           width="40px"
//           height="40px"
//           alt=""
//         />
//         <h4>{{ "Merchants" | translate | async }}</h4>
//       </div>
//       <div>
//         <p class="f-12 m-0 menu-txt">
//           {{ "Lorem ipsum dolor sit amet, consectetur adipisicing elit." | translate | async }}
//         </p>
//       </div>
//     </div>
//     <div class="main-menu">
//       <div>
//         <ul class="m-0 p-0">
//           <li>
//             <a
//               (click)="Activemenu('merchant-list')"
//               [ngClass]="
//                 className === 'merchant-list' ? 'Activemenu' : ''
//               "
//               routerLink="/merchants"
//               >{{ "Merchants" | translate | async }}</a
//             >
//           </li>
//         </ul>
//       </div>
//     </div>
//   </div>
// </div>

  SalesGrossAcive: any;
  Activemenu(sidemenu) {
    this.offcanvasService.dismiss("cross click");
    const body = this.document.body;
    this.GetbodyClass(body);
    this.className = sidemenu;
    this.SetClassDetais(this.className);
    localStorage.setItem("subMenuClass", this.className);
    if (this.mainscreenWidth < 992) {
      this.classmenu = true;
    }

    this.sendDataToTopbar();
  }

  sendDataToTopbar() {
    const Value = "closeSubmenu";
    this.SubmenuDetails.emit(Value);
  }

  SetClassDetais(value: any) {
    localStorage.setItem("subMenuClass", value);
  }
  ActiveSubSideMenu() {
    const activeClass = localStorage.getItem("subMenuClass");
    this.className = activeClass;

    switch (activeClass) {
      case "gross-transaction":
      case "gross-shift":
      case "gross-details":
        this.SalesGrossAcive = "GrossSales";
        break;

      case "payroll":
      case "cashout":
        this.SalesGrossAcive = "Labor";
        break;


          case "view-report":
            this.SalesGrossAcive = "daily-operations-report";
            break;

      case "discount":
      case "refunds-and-voids":
      case "refunds":
      case "voids-reports":
      case "cancel-order":
        this.SalesGrossAcive = "Exceptions";
        break;

      case "top-selling-items-report":
      case "weekly-net-sales-report":
      case "guest-count-report":
        this.SalesGrossAcive = "Weekly";
        break;

      case "campaign-manage":
        this.SalesGrossAcive = "Loyalty";
        break;
      default:
        break;
    }
  }
  GetbodyClass(bodyclass: any) {
    const body = this.renderer.selectRootElement("body", true);
    const bodyClasses = body.classList;
    const bodyClassesArray = Array.from(bodyClasses);
    if (bodyClassesArray[0] === "sidebar-enable") {
      bodyclass.classList.remove("sidebar-enable");
      bodyclass.classList.add("vertical-collpsed");
    }
    if (bodyClassesArray[0] === "res-sidebar-enable") {
      bodyclass.classList.remove("res-sidebar-enable");
      bodyclass.classList.add("vertical-collpsed");
    }
  }
  path: string = "";
  GetRouterDetails(link) {
    console.log("link==================================////////==>", link);

    const path = link;
    this.path = path;
    const array = [
      "/menumanagement/menus/menu-home",
      "/sales/transactions/sales-home",
      "/guests/guestbook/guest-home",
      "/employees/employeelist/employee-home",
      "/restaurant/general/restaurat-home",
      "/onlinestore/online-store-home",
      "/employees/jobtypes/employee-stup-home",
      "/business-settings/location/resturant-setting-home",
      "/reports/sales/gross-transaction/report-home",
      "/loyalty/loyalty-home,",
      "/integration-marketplace/integration-home",
    ];

    const EmployeeFix = ["add-employee", "edit-employees"];
    const EmployeeSplit = path.split("/")[2];

    if (EmployeeFix.includes(EmployeeSplit)) {
      this.SetClassDetais(this.className);
      return (this.className = "employeelist");
    }
    if (array.includes(path)) {
      this.className = "";
      this.SalesGrossAcive = "";
      this.SetClassDetais(this.className);
    } else {
      const parts = path.split("/");

      if (parts.length === 2) {
        this.className = parts[1];
        this.SetClassDetais(this.className);
      }

      if (parts.length >= 3) {
        const moduleroute = parts[2];
        const mainrout = moduleroute.split("?")[0];

        if (parts[1] === "reports") {
          const salesDetails = parts[3];
          this.className = salesDetails;
          this.SetClassDetais(this.className);
        } else {
          this.className = moduleroute;
          this.SetClassDetais(this.className);
        }
        if (
          mainrout === "new-service-area" ||
          mainrout === "update-service-area"
        ) {
          this.className = "service-areas";
          this.SetClassDetais(this.className);
        }
      }
    }
  }
  GetSubmenuStatus() {
    const com = this.menuService.sharedData$.subscribe(
      (sharedData) => (this.classmenu = sharedData)
    );
  }
  setAccodianActive(Active: any) {
    if (this.accordion) {
      // console.log("Active-PATH", Active)
      const GrossSalesReport = [
        "/reports/sales/gross-transaction",
        "/reports/sales/gross-shift",
        "/reports/sales/gross-details",
      ];
      const laborReport = ["/reports/labor/cashout", "/reports/labor/payroll"];
      const exception = [
        "/reports/exception/discount",
        "/reports/exception/refunds",
        "/reports/exception/cancel-order",
        "/reports/exception/voids-reports",
      ];
      const weekly = [
        "/reports/weekly/top-selling-items-report",
        "/reports/weekly/weekly-net-sales-report",
        "reports/weekly/guest-count-report"
      ]

      const sales = [
        "/reports/sales1/sales-summary-report",
        "/reports/sales1/orders-report",
        "/reports/sales1/payments-report",
        "/reports/sales1/sales-breakdown-report",
        "/reports/sales1/hourly-sales-report",
      ]

      const payments = [
        "/reports/payments/credit-card-report",
        "/reports/payments/deposits-report",
        "/reports/payments/payments-type-report"
      ]

      const employee = [
        "/reports/employee/time-entries-report",
        "/reports/employee/break-entries-report"
      ]

      const menus = [
        "/reports/menus/product-mix-report",
        "/reports/menus/menu-dropdown-report",
        "/reports/menus/top-menu-item-report",
        "/reports/menus/top-modifiers-group-report",
        "/reports/menus/top-modifiers-report",
        "/reports/menus/item-details-report",
        "/reports/menus/modifiers-detail-repor",
      ]


      const cash_loss_management = [
        "/reports/cash-loss-management/cash-activity-audit-report",
        "/reports/cash-loss-management/cash-drawer-history-report",
        "/reports/cash-loss-management/unpaid-orders-report",
        "/reports/cash-loss-management/tax-exempt-report",

      ]

      const accounts = [
        "/reports/accounts/accouting-by-location-report"
      ]

      const kitchen_operations = [
        "/reports/kitchen-operations/ticket-details-report"
      ]

      const guest_engagement = [
        "/reports/guest-engagement/guest-sales-summary-report",
        "/reports/guest-engagement/customer-product-summary-report"

      ]

      const daily_operations_report = [
        "/reports/daily-operations-report"
      ]





      if (GrossSalesReport?.includes(Active)) {
        this.setActiveId("config-panel-sales");
        this.SalesGrossAcive = "GrossSales";
      }
      if (laborReport?.includes(Active)) {
        this.setActiveId("config-panel-labour");
        this.SalesGrossAcive = "Labor";
      }
      if (exception?.includes(Active)) {
        this.setActiveId("config-panel-exception");
        this.SalesGrossAcive = "Exceptions";
      }
      if (weekly?.includes(Active)) {
        this.setActiveId("config-panel-weekly");
        this.SalesGrossAcive = "Weekly";
      }
      if (sales?.includes(Active)) {
        this.setActiveId("config-panel-sales1");
        this.SalesGrossAcive = "Sales1";
      }
      if (payments?.includes(Active)) {
        this.setActiveId("config-panel-payments");
        this.SalesGrossAcive = "Payments";
      }
      if (employee?.includes(Active)) {
        this.setActiveId("config-panel-employee");
        this.SalesGrossAcive = "Employee";
      }
      if (menus?.includes(Active)) {
        this.setActiveId("config-panel-menus");
        this.SalesGrossAcive = "Menus";
      }

      if (cash_loss_management?.includes(Active)) {
        this.setActiveId("config-panel-cash-loss-management");
        this.SalesGrossAcive = "CashLossManagement";
      }

      if (accounts?.includes(Active)) {
        this.setActiveId("config-panel-accounts");
        this.SalesGrossAcive = "Accounts";
      }

      if (kitchen_operations?.includes(Active)) {
        this.setActiveId("config-panel-kitchen-operations");
        this.SalesGrossAcive = "Accounts";
      }

      if (guest_engagement?.includes(Active)) {
        this.setActiveId("config-panel-kitchen-operations");
        this.SalesGrossAcive = "Accounts";
      }

      if (daily_operations_report?.includes(Active)) {
        this.setActiveId("config-panel-daily-operations-report");
        this.SalesGrossAcive = "daily-operations-report";
      }

      // if (Active === '/reports/sales/gross-transaction/report-home') {
      //   this.setActiveId('config-panel-salesf')
      // }
    }
  }

  // ## OLD setActiveId
  // setActiveId(activeId: string | string[]) {
  //   if (this.accordion && this.accordion?.activeIds.length) {
  //     this.accordion.activeIds = activeId;
  //   } else {
  //     return;
  //   }
  // }
  // ## NEW setActiveId
  setActiveId(activeId: string | string[]) {
    // Check if this.accordion is defined before accessing its properties
    // console.log("this.accordion", this.accordion)
    if (this.accordion != undefined && this.accordion != null) {
      // console.log("this.accordion?.activeIds.length", this.accordion?.activeIds.length)
      if (this.accordion?.activeIds.length) {
        // console.log("Array.isArray(activeId)", Array.isArray(activeId))
        if (Array.isArray(activeId)) {
          this.accordion.activeIds = activeId;
          // console.log("this.accordion.activeIds", this.accordion.activeIds)
        } else {
          this.accordion.activeIds = [activeId];
          // console.log("this.accordion.activeIds_ELSE", this.accordion.activeIds)
        }
      }
    } else {
      // You can add an else branch here to handle the case when this.accordion is undefined.
      return;
    }
  }

  SetmenuPath(path: any) {
    localStorage.setItem("subDivPath", JSON.stringify(path));
  }
  UpgradeSubmenu() {
    this.eventService.watchLocalStorageValue().subscribe((newValue) => {
      if (newValue !== null) {
        // this.Showmenu = newValue;
      }
      this.getBodyClass();
    });
  }
  getBodyClass(): void {
    const bodyClasses = document.body.classList;
    if (this.screenWidth < 576) {
      if (bodyClasses[0] === "res-sidebar-enable") {
        this.renderer.removeClass(document.body, "sidebar-enable");
      }
    } else {
      if (bodyClasses[0] != "vertical-collpsed") {
        this.renderer.addClass(document.body, "sidebar-enable");
      }
    }
  }

  SetSubActiveClass() {
    const routerInterval = interval(1000);
    routerInterval.pipe(takeUntil(this.destroy$)).subscribe(() => {
      // console.log("this.router.url", this.router.url);

      const parts = this.router.url.split("/");
      this.EmployeeListActive(parts[2], parts[3], parts[4]);
    });
  }
  EmployeeListActive(path: any, fullpath: any, fouthpath: any) {
    const link = ["edit-employees", "employeelist", "add-employee"];
    const menuHomeLink = [
      "employee-home",
      "employee-stup-home",
      "sales-home",
      "menu-home",
      "guest-home",
      "loyalty-home",
      "restaurat-home",
      "result",
    ];

    if (path) {
      if (
        path.split("?")[0] === "new-service-area" ||
        path.split("?")[0] === "update-service-area"
      ) {
        return (this.className = "service-areas");
      }
    }

    if (menuHomeLink.includes(fullpath)) {
      return (this.className = "");
    }
    if (fouthpath === "report-home") {
      return (this.className = "");
    }
    if (path === "labor") {
      return (this.className = fullpath);
    }
    if (path === "exception") {
      return (this.className = fullpath);
    }
    if (path === "sales") {
      return (this.className = fullpath);
    }

    if (path === "weekly") {
      return (this.className = fullpath)
    }

    if (path === "sales1") {
      return (this.className = fullpath)
    }

    if (path === "employee") {
      return (this.className = fullpath)
    }

    if (path === "payments") {
      return (this.className = fullpath)
    }

    if (path === "cash-loss-management") {
      return (this.className = fullpath)
    }

    if (path === "accounts") {
      return (this.className = fullpath)
    }

    if (path === "kitchen-operations") {
      return (this.className = fullpath)
    }

    if (path === "guest-engagement") {
      return (this.className = fullpath)
    }
    if (path === "daily-operations-report") {
      return (this.className = fullpath)
    }

    if (link.includes(path)) {
      this.className = "employeelist";
    } else {
      this.className = path;
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
  SetFormatedCode(path: any) {
    const Links = [
      "/employees/roles",
      "/employees/department",
      "/employees/jobtypes",
      "jobtypes",
      "roles",
      "department",
    ];
    const workforece = [
      "employeelist",
      "edit-employees",
      "add-employee",
      "timesheet",
      "jobtypes",
      "roles",
      "department"
    ];
    const workSpl = path.split("/")[2];
    console.log("WORK SPL");

    if (workforece.includes(workSpl)) {
      this.Showmenu = "employees";
      this.GetRouterDetails(path);
    } else if (Links.includes(workSpl)) {
      this.Showmenu = "employees-setup";
      this.GetRouterDetails(path);
    } else {
      this.AddSubmneuActive(path);
      this.GetRouterDetails(path);
    }
  }
}
