
import { createAction, props } from '@ngrx/store';
import { ILocaData, ILocation } from 'src/app/core/models/location.model';
export const LOCATION_FETCH_START = '[locations page] locations fetch start';
export const LOCATION_FETCH_SUCCESS = '[locations page] locations fetch Success';
export const LOCATION_FETCH_FAIL = '[locations page] locations fetch Fail';

export const LOCATION_SELECT_START = '[locations select] locations select start'
export const LOCATION_SELECT = '[locations select] locations select'
//for locations fetch start action
export const locationFetchStart = createAction(
  LOCATION_FETCH_START
);
//for locations fetch success action
export const locationFetchSuccess = createAction(
  LOCATION_FETCH_SUCCESS,
  props<{ locations: any }>()
);

///for location select 
export const locationSelect = createAction(
  LOCATION_SELECT,
  props<{ selected: any }>()
);
///for location select 
export const locationSelectStart = createAction(
  LOCATION_SELECT_START,
  props<{ show: Boolean }>()
);
//for locations fetch failure action
// export const loginFailure = createAction(
//     LOCATION_FETCH_FAIL,
//   props<{ loader: Boolean }>()
// );