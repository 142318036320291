<div class="topbar-main-page">
<header id="page-topbar">
  <div class="navbar-header p-0">
    <div class="d-flex dropdown" ngbDropdown>
      <!-- LOGO -->
      <button class="menu-bar" (click)="OpenTopsidebarList()">
        <i class="fa-regular fa-bars"></i>
      </button>
      <div class=" d-flex justify-content-between">
        <!-- <div class="d-flex menu-head-logo"> -->
          <!-- <a routerLink="/" class="logo logo-light">
            <span class="logo-sm" ngbDropdownToggle>
              <img
                src="assets/images/small-black-logo.png"
                alt=""
                height="40"
              />
            </span>
            <span class="logo-lg" ngbDropdownToggle>
              <img src="assets/images/eat-logo.svg" alt="" height="55" />
            </span>
          </a> -->

          <!-- <app-page-title [layout]="true" (changed)="changeStore($event)"></app-page-title> -->
        <!-- </div> -->
        <!-- <div class="align-self-center m-dnone">
          <div class="dropdown d-inline-block" ngbDropdown display="dynamic" #myDrop="ngbDropdown">
            <button type="button" class="btn header-item noti-icon d-flex align-items-center"
              id="page-header-notifications-dropdown" ngbDropdownToggle>
              <i class="bx bx-grid-alt v-align-middle" id="morePopup"> </i>
            </button>
            <div class="dropdown-menu-right dropdown-menu" aria-labelledby="page-header-notifications-dropdown"
              ngbDropdownMenu style="width: 830px">
              <div class="row mt-2">
                <div class="col-md-4">
                  <a type="button" (click)="redirect('/sites/point-of-sale'); myDrop.close()">
                    <div class="d-flex align-items-center p-3" (mouseover)="addClass($event)"
                      (mouseleave)="removeClass($event)">
                      <img src="assets/images/POS.png" alt="" height="45" />
                      <h6 class="ms-2">Point of Sale</h6>
                    </div>
                  </a>
                </div>
                <div class="col-md-4">
                  <a type="button" (click)="
                      redirect('/sites/point-of-purchase'); myDrop.close()
                    ">
                    <div class="d-flex align-items-center p-3">
                      <img src="assets/images/POP.png" alt="" height="45" />
                      <h6 class="ms-2">Point of Purchase</h6>
                    </div>
                  </a>
                </div>
                <div class="col-md-4">
                  <a type="button" (click)="
                      redirect('/sites/online-ordering-and-delivery');
                      myDrop.close()
                    ">
                    <div class="d-flex align-items-center p-3">
                      <img src="assets/images/OrderOS.png" alt="" height="45" />
                      <h6 class="ms-2">Online Ordering and Delivery</h6>
                    </div>
                  </a>
                </div>
              </div>

              <div class="row">
                <div class="col-md-4">
                  <a type="button" (click)="
                      redirect('/sites/kitchen-display-system'); myDrop.close()
                    ">
                    <div class="d-flex align-items-center p-3">
                      <img src="assets/images/KDS.png" alt="" height="45" />
                      <h6 class="ms-2">Kitchen Display System</h6>
                    </div>
                  </a>
                </div>
                <div class="col-md-4">
                  <a type="button" (click)="
                      redirect('/sites/self-serving-kiosk'); myDrop.close()
                    ">
                    <div class="d-flex align-items-center p-3">
                      <img src="assets/images/Kiosk.png" alt="" height="45" />
                      <h6 class="ms-2">Self Serving Kiosk</h6>
                    </div>
                  </a>
                </div>
                <div class="col-md-4">
                  <a type="button" (click)="
                      redirect('/sites/workforce-management'); myDrop.close()
                    ">
                    <div class="d-flex align-items-center p-3">
                      <img src="assets/images/WFM.png" alt="" height="45" />
                      <h6 class="ms-2">Workforce Management</h6>
                    </div>
                  </a>
                </div>
              </div>

              <div class="row mb-2">
                <div class="col-md-4">
                  <a type="button" (click)="
                      redirect('/sites/customer-facing-display'); myDrop.close()
                    ">
                    <div class="d-flex align-items-center p-3">
                      <img src="assets/images/CFD.png" alt="" height="45" />
                      <h6 class="ms-2">Customer Facing Display</h6>
                    </div>
                  </a>
                </div>
                <div class="col-md-4">
                  <a type="button" (click)="
                      redirect('/sites/table-side-order-and-ay'); myDrop.close()
                    ">
                    <div class="d-flex align-items-center p-3">
                      <img src="assets/images/Tableside Ordering and Pay.png" alt="" height="45" />
                      <h6 class="ms-2">Table-side Order and Pay</h6>
                    </div>
                  </a>
                </div>
                <div class="col-md-4">
                  <a type="button" (click)="
                      redirect('/sites/analytics-and-Reporting'); myDrop.close()
                    ">
                    <div class="d-flex align-items-center p-3">
                      <img src="assets/images/Analytics.png" alt="" height="45" />
                      <h6 class="ms-2">Analytics and Reporting</h6>
                    </div>
                  </a>
                </div>
              </div>

            </div>
          </div>
        </div> -->
      </div>
      <div class="navbar-brand-box d-flex justify-content-between" style="overflow: visible; position: relative; z-index: 1002;">
        <div class="d-flex menu-head-logo" ngbDropdown style="overflow: visible;">
          <!-- <a routerLink="/" class="logo logo-light" ngbDropdownToggle (click)="onLogoClick()" >
            <span class="logo-sm">
              <img src="assets/images/small-black-logo.png" alt="" height="40" />
            </span>
            <span class="logo-lg">
              <img src="assets/images/eat-logo.svg" alt="" height="55" />
            </span>
          </a>
          <div ngbDropdownMenu>
            <button class="dropdown-item" *ngFor="let item of osapps" (click)="onSelectOfLogo(item.appName)">
              <img [src]="item.iconPath" [alt]="item.appName" height="40" />
            </button>
          </div> -->
                    <!-- routerLink="/"  -->
          <a
          class="logo logo-light" ngbDropdownToggle>
            <span class="logo-sm">
              <img
              [src]="selectedSmallLogoPath || 'assets/images/small-black-logo.png'" alt="" width="40" height="40" />
            </span>
            <span class="logo-lg">
              <img [src]="selectedLogoPath || 'assets/images/eat-logo.svg'" alt="" width="132" height="55" />
            </span>
          </a>
          <div ngbDropdownMenu>
            <button class="dropdown-item" *ngFor="let item of osapps" (click)="onSelectOfLogo(item.appName, item.iconPath)">
              <img [src]="item.appLogoImageFile || 'assets/images/eat-logo.svg'" [alt]="item.appName" height="40" />
            </button>
          </div>
        </div>
        <!-- Rest of your code -->
      </div>
      <button
        type="button"
        class="btn btn-sm p-0 font-size-16 header-item header-menu"
        id="vertical-menu-btn"
        (click)="toggleMobileMenu($event)"
      >
        <div class="d-flex align-items-center">
          <!-- <i *ngIf="greather === false " class="fa fa-fw fa-bars"></i> -->
          <img
            *ngIf="!greather"
            src="assets/images/topbar-icons/menu.svg"
            alt="menu"
          />
          <img
            *ngIf="greather"
            src="assets/images/topbar-icons/enable.svg"
            alt="enable"
            width="34px"
          />
        </div>
      </button>
      <div class="location-new">
        <app-page-title
          [layout]="true"
          (changed)="changeStore($event)"
        ></app-page-title>
      </div>
    </div>
    <div class="d-flex align-items-center">
      <div class="d-flex align-items-center header-part-two header-RTL">
        <!-- <div class="px-3 d-flex align-items-center gap-2">
        <p class="m-0 f-14 fw-500">{{ formateDate }}</p>
        <p class="m-0 f-14 fw-500" style="color: #beb7b7">{{timezoneName1}}</p>
      </div> -->
        <!-- <div class="dropdown d-inline-block d-lg-none ms-2" ngbDropdown>
        <button type="button" class="btn header-item noti-icon" id="page-header-search-dropdown" data-toggle="dropdown"
          aria-haspopup="true" ngbDropdownToggle aria-expanded="false">
          <i class="mdi mdi-magnify"></i>
        </button>
        <div class="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0" ngbDropdownMenu
          aria-labelledby="page-header-search-dropdown">
          <form class="p-3">
            <div class="m-0">
              <div class="input-group">
                <input type="text" class="form-control" placeholder="Search ..." aria-label="Recipient's username" />
                <div class="input-group-append">
                  <button class="btn btn-primary" type="submit">
                    <i class="mdi mdi-magnify"></i>
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div> -->
        <!-- <div class="d-flex align-items-center gap-4">
        <div class="form-check form-switch header-switch f-14 align-items-center">
          <ui-switch defaultBoColor="#dfdfdf" color="#666666" uncheckedLabel="OFF" checkedLabel="ON" size="small"
            [checked]="checkBoxValue" (change)="toggleTestMode($event)"></ui-switch>
          <label class="form-check-label me-2" for="flexSwitchCheckChecked1">Training Mode</label>
        </div>
      </div> -->
        <!-- (click)="OpenSearchbarModel()" -->
        <div *ngIf="!isSuperAdmin" class="container p-0 mt-1" style="height: auto; line-height: normal;" (click)="OpenSearchbarModel()">
          <div class="dot-com">
          <form role="search" method="get" class="search-form" action="">
            <label class="m-0">
              <!-- <input
            type="search"
            class="search-field {{receivedData}}"
            placeholder="Search …" value="" name="s"
            title="Search for:" /> -->

              <img
                class="search-field {{ receivedData }} hover-shadow"

                src="../../../assets/images/home-search-icn.svg"
                alt="search"
                height="24px"
                width="24px"
              />
            </label>
            <input type="submit" class="search-submit" value="Search" />
          </form>
          <div class="train-main">
            <div
              class="d-flex justify-content-between align-items-center training-mode"
            >
              <h2 class="m-0 f-14 fw-500 ps-1">{{ "Search" | translate | async }}</h2>
              <div
                class="form-check form-switch header-switch f-14 align-items-center"
              >
              </div>
            </div>
            <p *hasPermission="['TrainingMode', 1]" class="f-12 fw-500 mb-0 train-RTL-details text-start">
              {{ "Click here to quickly search for a module." | translate | async }}
            </p>
          </div>
        </div>
          <!-- <div>
          <button class="bg-white border-0 text-black f-24" routerLink="/search"><i class="fa-regular fa-magnifying-glass"></i></button>
        </div> -->
        </div>
        <div *ngIf="!isSuperAdmin" class="dot-com">
          <button
            class="p-0 btn header-item noti-icon d-flex align-items-center"
          >
            <img
              class="hover-shadow"
              src="../../../assets/images/companies/mayachatai.svg"
              alt="jamboard_kiosk"
              height="30px"
              width="30px"
              (click)="onSettingsButtonClicked()"
            />
          </button>
          <div class="train-main">
            <div
              class="d-flex justify-content-between align-items-center training-mode"
            >
              <h2 class="m-0 f-14 fw-500">{{ "Maya" | translate | async }}</h2>
              <div
                class="form-check form-switch header-switch f-14 align-items-center"
              >
              </div>
            </div>
            <p *hasPermission="['TrainingMode', 1]" class="f-12 fw-500 mb-0 train-RTL-details">
              {{ "Generate daily sales reports with Maya." | translate | async }}
            </p>
          </div>
        </div>
        <div *ngIf="!isSuperAdmin" class="dot-com">
          <button
            class="p-0 btn header-item noti-icon d-flex align-items-center"
          >
            <img
              class="hover-shadow"
              src="../../../assets/images/topbar-icons/Newtopbar/jamboard_kiosk.svg"
              alt="jamboard_kiosk"
              height="24px"
              width="24px"
            />
          </button>
          <div *ngIf="checkBoxValue" class="dot-div"></div>
          <div class="train-main">
            <div
              class="d-flex justify-content-between align-items-center training-mode"
            >
              <h2 class="m-0 f-14 fw-500">{{ "Training Mode" | translate | async }}</h2>
              <div
                class="form-check form-switch header-switch f-14 align-items-center"
              >
                <ui-switch
                  *hasPermission="['TrainingMode', 3]"
                  defaultBoColor="#dfdfdf"
                  color="#666666"
                  uncheckedLabel="OFF"
                  checkedLabel="ON"
                  size="small"
                  [checked]="checkBoxValue"
                  (change)="toggleTestMode($event)"
                ></ui-switch>
                <ui-switch
                  *hasPermission="['TrainingMode', 0]"
                  defaultBoColor="#dfdfdf"
                  color="#666666"
                  size="small"
                  disabled
                ></ui-switch>
              </div>
            </div>

            <p *hasPermission="['TrainingMode', 3]" class="f-12 fw-500 mb-0 train-RTL-details">
              {{ "Turn on the training mode to test and learn our system in a controlled environment. Changes made in Training Mode stay isolated and does not affect the live data." | translate | async }}
            </p>
            <p *hasPermission="['TrainingMode', 0]" class="f-12 fw-500 mb-0">
              {{ "It appears that you do not have access to training mode. Please get in touch with your manager." | translate | async }}
            </p>
          </div>
        </div>
        <div *ngIf="!isSuperAdmin"  class="align-self-center m-dnone">

          <div
            class="dropdown d-inline-block"
            ngbDropdown
            display="dynamic"
            #myDrop="ngbDropdown"
          >
           <div class=" dot-com">
            <button
            type="button"
            class="p-0 btn header-item noti-icon d-flex align-items-center"
            id="page-header-notifications-dropdown"
            ngbDropdownToggle
            (click)="sitedropdown()"
            (clickOutside)="siteclosePanel()"
          >
            <!-- <i class="bx bx-grid-alt v-align-middle" id="morePopup"> </i> -->
            <img
              class="hover-shadow"
              src="../../../assets/images/topbar-icons/Newtopbar/Group 2520.svg"
              alt="grid"
              height="24px"
              width="24px"
            />
          </button>
          <div class="train-main" *ngIf="!siteheaderValue">
            <div
              class="d-flex justify-content-between align-items-center training-mode"
            >
              <h2 class="m-0 f-14 fw-500">{{ "Sites" | translate | async }}</h2>
              <div
                class="form-check form-switch header-switch f-14 align-items-center"
              >
              </div>
            </div>
            <p *hasPermission="['TrainingMode', 1]" class="f-12 fw-500 mb-0 train-RTL-details">
              {{ "Discover our cloud-based, AI-driven restaurant technology solutions." | translate | async }}
            </p>
          </div>
           </div>
            <div
              class="dropdown-menu-right dropdown-menu point-drop"
              aria-labelledby="page-header-notifications-dropdown"
              ngbDropdownMenu
              style="width: 830px"
            >
              <div class="row mt-2 mx-0 menu-list-topbar-main">
                <div class="col-xl-4 col-md-6">
                  <a
                    type="button"
                    (click)="redirect('/sites/point-of-sale'); myDrop.close()"
                  >
                    <div
                      class="d-flex align-items-center p-xl-3 p-1 menu-list-topbar-details"
                      (mouseover)="addClass($event)"
                      (mouseleave)="removeClass($event)"
                    >
                      <img src="assets/images/POS.png" alt="" height="45" />
                      <h6 class="ms-2 sale-data">{{ "Point of Sale" | translate | async }}</h6>
                    </div>
                  </a>
                </div>
                <div class="col-xl-4 col-md-6">
                  <a
                    type="button"
                    (click)="
                      redirect('/sites/point-of-purchase'); myDrop.close()
                    "
                  >
                    <div class="d-flex align-items-center p-xl-3 p-1 menu-list-topbar-details">
                      <img src="assets/images/POP.png" alt="" height="45" />
                      <h6 class="ms-2 sale-data">{{ "Point of Purchase" | translate | async }}</h6>
                    </div>
                  </a>
                </div>
                <div class="col-xl-4 col-md-6">
                  <a
                    type="button"
                    (click)="
                      redirect('/sites/online-ordering-and-delivery');
                      myDrop.close()
                    "
                  >
                    <div class="d-flex align-items-center p-xl-3 p-1 menu-list-topbar-details">
                      <img src="assets/images/OrderOS.png" alt="" height="45" />
                      <h6 class="ms-2 sale-data">
                        {{ "Online Ordering and Delivery" | translate | async }}
                      </h6>
                    </div>
                  </a>
                </div>
                <div class="col-xl-4 col-md-6">
                  <a
                    type="button"
                    (click)="
                      redirect('/sites/kitchen-display-system'); myDrop.close()
                    "
                  >
                    <div class="d-flex align-items-center p-xl-3 p-1 menu-list-topbar-details">
                      <img src="assets/images/KDS.png" alt="" height="45" />
                      <h6 class="ms-2 sale-data">{{ "Kitchen Display System" | translate | async }}</h6>
                    </div>
                  </a>
                </div>
                <div class="col-xl-4 col-md-6">
                  <a
                    type="button"
                    (click)="
                      redirect('/sites/self-serving-kiosk'); myDrop.close()
                    "
                  >
                    <div class="d-flex align-items-center p-xl-3 p-1 menu-list-topbar-details">
                      <img src="assets/images/Kiosk.png" alt="" height="45" />
                      <h6 class="ms-2 sale-data">{{ "Self Serving Kiosk" | translate | async }}</h6>
                    </div>
                  </a>
                </div>
                <div class="col-xl-4 col-md-6">
                  <a
                    type="button"
                    (click)="
                      redirect('/sites/workforce-management'); myDrop.close()
                    "
                  >
                    <div class="d-flex align-items-center p-xl-3 p-1 menu-list-topbar-details">
                      <img src="assets/images/WFM.png" alt="" height="45" />
                      <h6 class="ms-2 sale-data">{{ "Workforce Management" | translate | async }}</h6>
                    </div>
                  </a>
                </div>
                <div class="col-xl-4 col-md-6">
                  <a
                    type="button"
                    (click)="
                      redirect('/sites/customer-facing-display'); myDrop.close()
                    "
                  >
                    <div class="d-flex align-items-center p-xl-3 p-1 menu-list-topbar-details">
                      <img src="assets/images/CFD.png" alt="" height="45" />
                      <h6 class="ms-2 sale-data">{{ "Customer Facing Display" | translate | async }}</h6>
                    </div>
                  </a>
                </div>
                <div class="col-xl-4 col-md-6">
                  <a
                    type="button"
                    (click)="
                      redirect('/sites/table-side-order-and-ay'); myDrop.close()
                    "
                  >
                    <div class="d-flex align-items-center p-xl-3 p-1 menu-list-topbar-details">
                      <img
                        src="assets/images/Tableside Ordering and Pay.png"
                        alt=""
                        height="45"
                      />
                      <h6 class="ms-2 sale-data">{{ "Table-side Order and Pay" | translate | async }}</h6>
                    </div>
                  </a>
                </div>
                <div class="col-xl-4 col-md-6">
                  <a
                    type="button"
                    (click)="
                      redirect('/sites/analytics-and-Reporting'); myDrop.close()
                    "
                  >
                    <div class="d-flex align-items-center p-xl-3 p-1 menu-list-topbar-details">
                      <img
                        src="assets/images/Analytics.png"
                        alt=""
                        height="45"
                      />
                      <h6 class="ms-2 sale-data">{{ "Analytics and Reporting" | translate | async }}</h6>
                    </div>
                  </a>
                </div>
              </div>
              <!--  -->
              <!-- <div class="row mx-0">
                <div class="col-md-4">
                  <a
                    type="button"
                    (click)="
                      redirect('/sites/kitchen-display-system'); myDrop.close()
                    "
                  >
                    <div class="d-flex align-items-center p-3">
                      <img src="assets/images/KDS.png" alt="" height="45" />
                      <h6 class="ms-2">Kitchen Display System</h6>
                    </div>
                  </a>
                </div>
                <div class="col-md-4">
                  <a
                    type="button"
                    (click)="
                      redirect('/sites/self-serving-kiosk'); myDrop.close()
                    "
                  >
                    <div class="d-flex align-items-center p-3">
                      <img src="assets/images/Kiosk.png" alt="" height="45" />
                      <h6 class="ms-2">Self Serving Kiosk</h6>
                    </div>
                  </a>
                </div>
                <div class="col-md-4">
                  <a
                    type="button"
                    (click)="
                      redirect('/sites/workforce-management'); myDrop.close()
                    "
                  >
                    <div class="d-flex align-items-center p-3">
                      <img src="assets/images/WFM.png" alt="" height="45" />
                      <h6 class="ms-2">Workforce Management</h6>
                    </div>
                  </a>
                </div>
              </div> -->
              <!--  -->
              <!-- <div class="row mb-2 mx-0">
                <div class="col-md-4">
                  <a
                    type="button"
                    (click)="
                      redirect('/sites/customer-facing-display'); myDrop.close()
                    "
                  >
                    <div class="d-flex align-items-center p-3">
                      <img src="assets/images/CFD.png" alt="" height="45" />
                      <h6 class="ms-2">Customer Facing Display</h6>
                    </div>
                  </a>
                </div>
                <div class="col-md-4">
                  <a
                    type="button"
                    (click)="
                      redirect('/sites/table-side-order-and-ay'); myDrop.close()
                    "
                  >
                    <div class="d-flex align-items-center p-3">
                      <img
                        src="assets/images/Tableside Ordering and Pay.png"
                        alt=""
                        height="45"
                      />
                      <h6 class="ms-2">Table-side Order and Pay</h6>
                    </div>
                  </a>
                </div>
                <div class="col-md-4">
                  <a
                    type="button"
                    (click)="
                      redirect('/sites/analytics-and-Reporting'); myDrop.close()
                    "
                  >
                    <div class="d-flex align-items-center p-3">
                      <img
                        src="assets/images/Analytics.png"
                        alt=""
                        height="45"
                      />
                      <h6 class="ms-2">Analytics and Reporting</h6>
                    </div>
                  </a>
                </div>
              </div> -->
              <!--  -->
            </div>
          </div>
        </div>
        <!-- <div class="dropdown d-inline-block ms-1" ngbDropdown *ngIf="!testMode">
        <div class="dropdown d-inline-block" ngbDropdown>
          <button type="button" class="btn header-item noti-icon" ngbDropdownToggle>
            <i class="bx bx-support align-middle"></i>
          </button>
          <div class="dropdown-menu dropdown-menu-end p-2" ngbDropdownMenu>
            <div style="
                font-size: 13px !important;
                padding-left: 20px;
                padding-right: 20px;
                text-align: center;
              ">
              <span class="">Support PIN
                <i *ngIf="supportLoader" class="fas fa-sync-alt m-1 rotate"></i>
                <i *ngIf="!supportLoader" class="fas fa-sync-alt m-1 cs-pointer" (click)="generateSupportPin()"></i>
              </span>
              <div class="support-pin cs-pointer" ngxClipboard [cbContent]="supportPin" ngbTooltip="Copied!"
                [closeDelay]="2000" triggers="manual" #t="ngbTooltip" (click)="t.open()">
                <span class="fw-bolder" ngbTooltip="click to copy pin" [closeDelay]="2000" #t="ngbTooltip">
                  <ng-container *ngIf="displayPin.length > 0">
                    <span *ngFor="let pin of displayPin; let index = i" class="">
                      {{ pin }}
                    </span>
                  </ng-container>
                  <ng-container *ngIf="displayPin.length == 0">
                    <span class=""></span><span class=""></span><span class=""></span><span class=""></span><span
                      class=""></span><span class=""></span>
                  </ng-container>
                </span>
              </div>
            </div>
            <div class="btn-group">
              <button class="btn btn-outline-dark f-14 fw-700" style="margin-top: 10px; margin-left: 15px; width: 100px"
                (click)="chatWithUs()">
                Live Chat
              </button>
              <button class="btn btn-outline-dark f-14 fw-700"
                style="margin-top: 10px; margin-right: 15px; width: 137px" (click)="goToSupportCenter()">
                Support center
              </button>
            </div>
          </div>
        </div>
      </div> -->

        <ng-container *canAccess="['QuickStore']">
          <div
          *ngIf="!testMode && showQuickStore && hasStoreFront && !retailview"
          class="dropdown d-lg-inline-block ms-1"
            ngbDropdown
          >
            <div class="dropdown d-inline-block m-dnone " ngbDropdown>
              <div class="dot-com">
              <button
                type="button"
                class="btn header-item noti-icon p-0"
                ngbDropdownToggle
                (click)="dropdown()"
                (clickOutside) = "ClosePanel()"
              >
                <img
                  class="hover-shadow"
                  src="../../../assets/images/topbar-icons/Newtopbar/bx-store.svg"
                  height="24px"
                  width="24px"
                />
              </button>
              <div class="train-main" *ngIf="!headerValue">
                <div
                  class="d-flex justify-content-between align-items-center training-mode"
                >
                  <h2 class="m-0 f-14 fw-500">{{ "Quick Store Settings" | translate | async }}</h2>
                  <div
                    class="form-check form-switch header-switch f-14 align-items-center"
                  >
                  </div>
                </div>
                <p *canAccess="['QuickStore']" class="f-12 fw-500 mb-0 train-RTL-details">
                  {{ "Easily manage dine-in and online orders with Quick Store Settings." | translate | async }}
                </p>
              </div>
              </div>
              <div
                class="dropdown-menu dropdown-menu-end fw-500 p-3 quick-RTL-store"
                ngbDropdownMenu
              >
                <div class="">
                  <div class="card-body my-0 py-0 px-0">
                    <div
                      class="quickStore-loader"
                      *ngIf="showQuickStoreLoader === true"
                    >
                      <img
                        src="../../../assets/images/loaders/eatos_loading_0-Black.gif"
                        class="quickStore-loader-spinner"
                        alt=""
                      />
                      <p class="mb-0 mt-2">{{ "Please wait..." | translate | async }}</p>
                    </div>
                    <div class="font-size-14 fw-700 text-center mb-3">
                      {{ "Quick Store Settings" | translate | async }}
                    </div>
                    <div class="font-size-14 fw-700 mb-2 dine-RLT-title">{{ "Dine In" | translate | async }}</div>
                    <div class="d-flex justify-content-between dine-RLT-togglee">
                      <div class="store-menu fw-500 f-12">{{ "Auto Accept Order" | translate | async }}</div>
                      <div>
                        <div class="form-check form-switch mb-3">
                          <input
                            [disabled]="!permissionService.canAccess('QuickStore', 3)"
                            class="form-check-input"
                            type="checkbox"
                            id="flexSwitchCheckChecked"
                            [checked]="canAutoAcceptOrderDineIn"
                            (change)="
                              enableDisable($event, 'OnlineStoreDineInAutoAccept')
                            "
                          />
                        </div>
                      </div>
                    </div>
                    <div class="d-flex justify-content-between dine-RLT-togglee">
                      <div class="store-menu fw-500 f-12">{{ "Pause Order" | translate | async }}</div>
                      <div>
                        <div class="form-check form-switch mb-3">
                          <input
                            [disabled]="!permissionService.canAccess('QuickStore', 3)"
                            class="form-check-input"
                            type="checkbox"
                            id="flexSwitchCheckChecked"
                            [checked]="canPauseOrderDineIn"
                            (change)="
                              enableDisable($event, 'OnlineStoreDineInPauseOrder')
                            "
                          />
                        </div>
                      </div>
                    </div>
                    <div class="font-size-14 fw-700 mb-2 dine-RLT-title">{{ "Online Ordering" | translate | async }}</div>
                    <div class="d-flex justify-content-between dine-RLT-togglee">
                      <div class="store-menu fw-500 f-12">{{ "Auto Accept Order" | translate | async }}</div>
                      <div>
                        <div class="form-check form-switch mb-3">
                          <input
                            [disabled]="!permissionService.canAccess('QuickStore', 3)"
                            class="form-check-input"
                            type="checkbox"
                            id="flexSwitchCheckChecked"
                            [checked]="canAutoAcceptOrderOnline"
                            (change)="
                              enableDisable($event, 'OnlineStoreAutoAccept')
                            "
                          />
                        </div>
                      </div>
                    </div>
                    <div class="d-flex justify-content-between dine-RLT-togglee">
                      <div class="store-menu fw-500 f-12">{{ "Pause Order" | translate | async }}</div>
                      <div>
                        <div class="form-check form-switch mb-3">
                          <input
                            [disabled]="!permissionService.canAccess('QuickStore', 3)"
                            class="form-check-input"
                            type="checkbox"
                            id="flexSwitchCheckChecked"
                            [checked]="canPauseOrderOnline"
                            (change)="
                              enableDisable($event, 'OnlineStorePauseOrder')
                            "
                          />
                        </div>
                      </div>
                    </div>
                    <div class="font-size-14 fw-700 mb-2 dine-RLT-title">{{ "Prep Time Orders" | translate | async }}</div>
                    <div>
                      <input
                        [disabled]="!permissionService.canAccess('QuickStore', 3)"
                        type="number"
                        [ngClass]="prepTimeError !== '' ? 'input-error' : ''"
                        (keypress)="
                          ($event.charCode >= 48 && $event.charCode < 58)
                        "
                        (keyup)="prepTime($event)"
                        [(ngModel)]="prepTimeValue"
                        class="form-control f-12 dine-RLT-input"
                        min="10"
                        max="60"
                        [placeholder]="('Enter Time' | translate | async) + '(MM)'"
                      />
                      <div class="error mt-1 f-12">{{ prepTimeError | translate | async }}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
        <!--       -->
        <div
          class="dropdown-box d-inline-block"
          [ngClass]="active == 1 ? 'drop-main' : ''"
          ngbDropdown
          #myDroph="ngbDropdown"
        >
          <div class="d-flex header-part-two p-0 ">
            <div class="dot-com">
            <button
              *ngIf="!isSuperAdmin"
              type="button"
              (click)="AcitvTab(4)"
              class="p-0 btn header-item noti-icon d-flex align-items-center"
              id="page-header-wether-dropdown"
              ngbDropdownToggle
            >
              <img
                src="../../../assets/images/home-weather-icon.svg"
                class="w-icon hover-shadow"
                alt="weather"
                height="24px"
                width="24px"
              />
            </button>
            <div class="train-main">
              <div
                class="d-flex justify-content-between align-items-center training-mode"
              >
                <h2 class="m-0 f-14 fw-500">{{ "Weather Report" | translate | async }}</h2>
                <div
                  class="form-check form-switch header-switch f-14 align-items-center"
                >
                </div>
              </div>
              <p *hasPermission="['TrainingMode', 1]" class="f-12 fw-500 mb-0 train-RTL-details">
                {{ "Stay informed and plan your restaurant operations effectively with our latest weather updates!" | translate | async }}
            </p>
            </div>
          </div>
            <div class="dot-com">
            <button
              type="button"
              (click)="AcitvTab(1)"
              class="p-0 btn header-item noti-icon d-flex align-items-center dot-com"
              id="page-header-wether-dropdown"
              ngbDropdownToggle
              (click)="notifydropdown()"
              (clickOutside)="notifyclosePanel()"

            >
              <!-- <i [ngClass]="notificationCount === 0 ? 'bx-tada' : ''" class="bx bx-bell"></i> -->
              <img
                class="hover-shadow"
                src="../../../assets/images/home-notification-icon.svg"
                alt="notification"
                height="24px"
                width="24px"
              />
              <span
                class="badge rounded-pill"
                [ngClass]="{
                  'bg-danger':
                    notificationCount !== 0 && notificationCount !== undefined
                }"
                [ngStyle]="{'font-size' : notificationCount > 99 ? '10px' : '12px'}"
                >{{ notificationCount > 99 ? '99+' : notificationCount }}</span
              >
            </button>
            <div class="train-main" *ngIf="!notifyheaderValue">
              <div
                class="d-flex justify-content-between align-items-center training-mode"
              >
                <h2 class="m-0 f-14 fw-500">{{ "Notifications" | translate | async }}</h2>
                <div
                  class="form-check form-switch header-switch f-14 align-items-center"
                >
                </div>
              </div>
              <p *hasPermission="['TrainingMode', 1]" class="f-12 fw-500 mb-0 train-RTL-details">
                {{ "Click the icon to view important dashboard updates." | translate | async }}
              </p>
            </div>
          </div>
          </div>
          <div
            class="dropdown-menu dropdown-menu-lg dropdown-size dropdown-menu-end p-0"
            aria-labelledby="page-header-wether-dropdown"
            ngbDropdownMenu
          >
            <div class="weather-main">
              <div class="row align-items-center">
                <div class="col">
                  <h6 class="m-0 fw-600 wnot" *ngIf="active === 1">
                    <!-- All {{ "HEADER.NOTIFICATIONS.TITLE" | translate }} -->
                  </h6>
                  <!-- <h6 class="m-0 fw-600 wnot" *ngIf="active === 4">
                  Weather Notifications
                </h6> -->
                </div>
              </div>
              <div class="tab-main">
                <div class="manage-tabddd">
                  <ul
                    ngbNav
                    #nav="ngbNav"
                    [(activeId)]="active"
                    class="nav-tabs justify-content-between align-items-center"
                  >
                    <li [ngbNavItem]="1" class="relative">
                      <a class="p-0" ngbNavLink>
                        <i
                          class="fa-solid fa-list-ul f-20 tab_icon_two"
                          [ngClass]="
                            active === 1 ? 'tab_icon2_two' : 'tab_icon_two'
                          "
                        ></i
                      ></a>

                      <span
                      *ngIf="notificationCount > 0"
                        class="badge rounded-pill"
                        [ngClass]="
                          active === 1 ? 'rounded_pill_two' : 'rounded-pill'
                        "
                        [attr.data-limit]="notificationCount > 99 ? 'true' : 'false'"
                        >{{ notificationCount > 99 ? "99+" : notificationCount }}</span
                      >
                      <ng-template ngbNavContent>
                        <ngx-simplebar
                          style="
                            position: relative;
                            min-height: fit-content;
                            max-height: 358px;
                          "
                          [ngClass]="
                            notificationCount > 3 ? 'notification-height' : ''
                          "
                        >
                          <ng-container
                            *ngFor="let notif of notificationList; index as i"
                          >
                            <a
                              href="javascript: void(0);"
                              class="text-reset notification-item"
                            >
                              <div
                                class="d-flex flex-column title"
                                [ngClass]="
                                  notif.isMarkedRead ? 'markAsRead' : ''
                                "
                              >
                                <div class="d-flex notification-RTL-main">
                                  <div class="avatar-xs me-2">
                                    <span
                                      class="avatar-title bg-primary rounded-circle font-size-16"
                                      style="background: #212121 !important"
                                    >
                                      <img
                                        *ngIf="notif.image != ''"
                                        class="rounded-circle header-profile-user"
                                        [src]="'https://' + notif.image"
                                      />
                                      <span
                                        *ngIf="notif.image == ''"
                                        style="
                                          background: #212121;
                                          width: 29.5px;
                                          height: 27.5px;
                                          border-radius: 50%;
                                          display: grid;
                                          color: #fff;
                                          align-items: center;
                                          text-align: center;
                                        "
                                      >
                                        <span style="font-weight: 700">
                                          {{ notif.char }}
                                        </span>
                                      </span>
                                    </span>
                                  </div>
                                  <div class="flex-grow-1 notification-RTL-details">
                                    <div
                                      class="d-flex align-itrems-center justify-content-between mb-1 notification-RTL-titles"
                                    >
                                      <h6 class="mb-1 font-size-15 fw-600">
                                        {{ notif.title | translate | async }}
                                      </h6>
                                      <div
                                        class="d-flex gap-4 align-items-center notification-RTL-allicon"
                                      >
                                        <i
                                          *ngIf="notif.isFlagged"
                                          [ngClass]="
                                            notif.isFlagged
                                              ? 'notification-show'
                                              : 'notification-hover'
                                          "
                                          class="fa-regular fa-flag font-size-16"
                                          (click)="isFlagged(i, false)"
                                          style="color: #212121"
                                        ></i>
                                        <i
                                          *ngIf="!notif.isFlagged"
                                          class="fa-regular fa-flag font-size-16 notification-hover"
                                          (click)="isFlagged(i, true)"
                                          style="color: #d2d2d2"
                                        ></i>
                                        <img
                                          [ngClass]="
                                            notif.isPinned
                                              ? 'notification-show'
                                              : 'notification-hover'
                                          "
                                          *ngIf="notif.isPinned"
                                          (click)="isPinned(i, false)"
                                          src="../../../assets/images/pin.svg"
                                          alt=""
                                        />
                                        <img
                                          class="notification-hover"
                                          *ngIf="!notif.isPinned"
                                          (click)="isPinned(i, true)"
                                          src="../../../assets/images/grey-pin.svg"
                                          alt=""
                                        />
                                        <button
                                          class="arrow_theme"
                                          (click)="notiseen(i)"
                                        >
                                          <i
                                            class="fa-solid fa-chevron-down text-black"
                                            *ngIf="!notif.show"
                                          ></i>
                                          <i
                                            class="fa-solid fa-chevron-up text-black"
                                            *ngIf="notif.show"
                                          ></i>
                                        </button>
                                      </div>
                                    </div>
                                    <div class="font-size-14 text-muted notification-RTL-description">
                                      <p class="mb-1">
                                        {{ notif.message | translate | async }}
                                      </p>
                                      <p class="mb-0 text-black notification-RTL-timer">
                                        <i class="mdi mdi-clock-outline"></i>
                                        {{ notif.timeElapsed | translate | async }}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  class="d-flex justify-content-between pt-2 px-4 notification-mark-btn"
                                  *ngIf="notif.show"
                                >
                                  <a
                                  *ngIf="!notif.isMarkedRead"
                                    href="javascript: void(0);"
                                    class="font-size-16 fw-500"
                                    (click)="markAsRead(i, true)"
                                    >{{ "Mark as read" | translate | async }}</a
                                  >
                                  <a
                                    href="javascript: void(0);"
                                    class="font-size-16 fw-500"
                                    (click)="dismissNotif(i)"
                                    >{{ "Dismiss" | translate | async }}</a
                                  >
                                </div>
                              </div>
                            </a>
                          </ng-container>
                          <ng-container *ngIf="notificationList?.length == 0">
                            <div
                              class="py-3 px-4 d-flex justify-content-between align-items-center"
                            >
                              {{ "No new Notifications" | translate | async }}
                            </div>
                          </ng-container>
                        </ngx-simplebar>
                        <ng-container *ngIf="notificationList?.length != 0">
                          <div
                            class="py-3 px-4 d-flex justify-content-between align-items-center"
                          >
                            <a
                              href="javascript: void(0);"
                              class="fw-600 f-14"
                              (click)="markAllAsRead()"
                            >
                              {{ "Mark all as read" | translate | async }}
                            </a>
                            <a
                              href="javascript: void(0);"
                              class="fw-600 f-14"
                              (click)="dismissAllNotif()"
                            >
                              {{ "Dismiss all" | translate | async }}
                            </a>
                          </div>
                        </ng-container>
                        <ng-container *ngIf="notificationList?.length != 0">
                          <div class="close_btn">
                            <button
                              class="fw-600 close_btn_theme bg-white"
                              (click)="myDroph?.toggle()"
                            >
                              {{ "CLOSE" | translate | async }}
                            </button>
                          </div>
                        </ng-container>
                      </ng-template>
                    </li>
                    <li [ngbNavItem]="2" [disabled]="true">
                      <a ngbNavLink>
                        <img
                          src="../../../assets/images/speaker.png"
                          class="tab_icon"
                          alt="speaker"
                      /></a>
                      <ng-template ngbNavContent> </ng-template>
                    </li>
                    <li [ngbNavItem]="3" [disabled]="true">
                      <a ngbNavLink
                        ><img
                          src="../../../assets/images/upload1.png"
                          class="tab_icon"
                          alt="upload1"
                      /></a>
                      <ng-template ngbNavContent> </ng-template>
                    </li>
                    <li [ngbNavItem]="4" [disabled]="retailview">
                      <a ngbNavLink
                        ><img
                          src="../../../assets/images/weather.png"
                          class="tab_icon"
                          alt="weather"
                      /></a>
                      <span
                          class="badge rounded-pill rounded_pill_two"
                          [attr.data-limit]="notificationCount > 99 ? 'true' : 'false'"
                          *ngIf="!retailview"
                       >
                              {{
                           weatherCount > 99 ? "99+" : weatherCount
                               }}
                       </span>
                      <ng-template ngbNavContent>
                        <ng-container *ngIf="weatherList.length > 0">
                          <div class="strom-data-main">
                            <div class="dinning-main m-0">
                              <ngb-accordion #acc="ngbAccordion">
                                <ng-container
                                  *ngFor="
                                    let weather of weatherList;
                                    index as i
                                  "
                                >
                                  <ngb-panel>
                                    <ng-template ngbPanelTitle>
                                      <div (click)="Showtext(i)">
                                        <div class="ts f-14">
                                          <div class="strom-main thund">
                                            <!-- weatherbased images -->
                                            <img
                                              *ngIf="weather.iconName == 'sun'"
                                              src="../../../assets/images/sun.png"
                                              alt="Thunderstorm"
                                            />
                                            <img
                                              *ngIf="
                                                weather.iconName ==
                                                'broken_cloud'
                                              "
                                              src="../../../assets/images/broken_cloud.png"
                                              alt="Thunderstorm"
                                              height="40px"
                                            />
                                            <img
                                              *ngIf="weather.iconName == 'rain'"
                                              src="../../../assets/images/Rain.png"
                                              alt="Thunderstorm"
                                              height="40px"
                                            />
                                            <img
                                              *ngIf="weather.iconName == 'snow'"
                                              src="../../../assets/images/snow.png"
                                              alt="Thunderstorm"
                                              height="40px"
                                            />
                                            <div class="date-strom">
                                              <div class="rain-strom">
                                                <h1>{{ weather.dayOfWeek | translate | async }}</h1>
                                                <p>{{ weather.weatherText | translate | async }}</p>
                                              </div>
                                              <div class="strom-no">
                                                <p class="pb-2">
                                                  {{ weather.maxTemp }} &deg;F
                                                  <span>/ </span
                                                  >{{ weather.minTemp }} &deg;F
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            class="d-flex align-items-center justify-content-between dashboard-RTL-weather"
                                            *ngIf="!weather.isShown"
                                          >
                                            <p class="f-14 mb-0">
                                              {{ weather.trncDesc | translate | async  }}
                                            </p>
                                            <p
                                              *ngIf="
                                                weather.affectAreasCount > 0
                                              "
                                              class="warning_txt mb-0 d-flex align-items-center f-14 gap-2"
                                            >
                                              <i
                                                class="fa-regular fa-circle-exclamation"
                                              ></i
                                              >{{ weather.affectAreasCount }}
                                              {{ "Warnings" | translate | async }}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </ng-template>
                                    <ng-template ngbPanelContent>
                                      <div class="">
                                        <p class="f-14 fw-400 m-0 heading_p">
                                          {{ weather.description | translate | async }}
                                        </p>

                                        <div
                                          *ngIf="weather.affectAreasCount > 0"
                                        >
                                          <p
                                            class="f-14 fw-600 m-0 heading_p_two"
                                          >
                                            {{ "Affected Service Area:" | translate | async }}
                                          </p>
                                          <p class="f-14 fw-600 m-0 heading_p">
                                            {{ weather.affectAreas | translate | async  }}
                                          </p>
                                        </div>
                                      </div>
                                    </ng-template>
                                  </ngb-panel>
                                </ng-container>

                                <!-- <ngb-panel>
                                <ng-template ngbPanelTitle>
                                  <div (click)="isShown = !isShown">
                                    <div class="ts f-14">
                                      <div class="strom-main thund">
                                        <img src="../../../assets/images/Thunderstorm.png" alt="Thunderstorm" />
                                        <div class="date-strom">
                                          <div class="rain-strom">
                                            <h1>Today, Monday</h1>
                                            <p>Rain Strom</p>
                                          </div>
                                          <div class="strom-no">
                                            <p class="pb-2">72 &deg;F <span>/ </span>67 &deg;F</p>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="d-flex  align-items-center justify-content-between" *ngIf='!isShown' >
                                        <p  class="f-14 mb-0">AI: Tomorrow, there is a 70% ch...</p>
                                        <p class="warning_txt mb-0 d-flex align-items-center f-14 gap-2"><i
                                          class="fa-regular fa-circle-exclamation"></i>3 Warnings</p>
                                      </div>
                                    </div>
                                  </div>
                                </ng-template>
                                <ng-template ngbPanelContent>
                                  <div class="">
                                    <p class="f-14 fw-400 m-0 heading_p ">AI: Tomorrow, there is a 70% chance of rain from
                                      10:00 am to 4:00 pm. Please plan accordingly and stay safe.</p>

                                    <div>
                                      <p class="f-14 fw-600 m-0 heading_p_two">Affected Service Area:</p>
                                      <p class="f-14 fw-600 m-0 heading_p">Patio, Regular Terrace Dining and Top Bar</p>
                                    </div>
                                  </div>
                                </ng-template>
                              </ngb-panel>
                              <ngb-panel>
                                <ng-template ngbPanelTitle>
                                  <div>
                                    <div class="ts f-14">
                                      <div class="strom-main thund">
                                        <img src="../../../assets/images/Rain.png" alt="Thunderstorm" />
                                        <div class="date-strom">
                                          <div class="rain-strom">
                                            <h1>Wednesday</h1>
                                            <p>Cloud</p>
                                          </div>
                                          <div class="strom-no">
                                            <p class="pb-2">72 &deg;F <span>/ </span>67 &deg;F</p>
                                            <p class="warning_txt mt-1 d-flex align-items-center gap-2"><i
                                                class="fa-regular fa-circle-exclamation"></i>3 Warnings</p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </ng-template>
                                <ng-template ngbPanelContent>
                                  <div class="">
                                    <p class="f-14 fw-400 m-0 heading_p">AI: Tomorrow, there is a 70% chance of rain from
                                      10:00 am to 4:00 pm. Please plan accordingly and stay safe.</p>

                                    <div>
                                      <p class="f-14 fw-600 m-0 heading_p_two">Affected Service Area:</p>
                                      <p class="f-14 fw-600 m-0 heading_p">Patio, Regular Terrace Dining and Top Bar</p>
                                    </div>
                                  </div>
                                </ng-template>
                              </ngb-panel>
                              <ngb-panel>
                                <ng-template ngbPanelTitle>
                                  <div>
                                    <div class="ts f-14">
                                      <div class="strom-main thund">
                                        <img src="../../../assets/images/sun.png" alt="Thunderstorm" />
                                        <div class="date-strom">
                                          <div class="rain-strom">
                                            <h1>Thursday</h1>
                                            <p>Clear</p>
                                          </div>
                                          <div class="strom-no">
                                            <p>72 &deg;F <span>/ </span>67 &deg;F</p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </ng-template>
                                <ng-template ngbPanelContent>
                                  <div class="">
                                    <p class="f-14 fw-400 m-0 heading_p">AI: Tomorrow, there is a 70% chance of rain from
                                      10:00 am to 4:00 pm. Please plan accordingly and stay safe.</p>

                                    <div>
                                      <p class="f-14 fw-600 m-0 heading_p_two">Affected Service Area:</p>
                                      <p class="f-14 fw-600 m-0 heading_p">Patio, Regular Terrace Dining and Top Bar</p>
                                    </div>
                                  </div>
                                </ng-template>
                              </ngb-panel>
                              <ngb-panel>
                                <ng-template ngbPanelTitle>
                                  <div>
                                    <div class="ts f-14">
                                      <div class="strom-main thund">
                                        <img src="../../../assets/images/sun.png" alt="Thunderstorm" />
                                        <div class="date-strom">
                                          <div class="rain-strom">
                                            <h1>Friday</h1>
                                            <p>Rain Strom</p>
                                          </div>
                                          <div class="strom-no">
                                            <p>72 &deg;F <span>/ </span>67 &deg;F</p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </ng-template>
                                <ng-template ngbPanelContent>
                                  <div class="">
                                    <p class="f-14 fw-400 m-0 heading_p">AI: Tomorrow, there is a 70% chance of rain from
                                      10:00 am to 4:00 pm. Please plan accordingly and stay safe.</p>

                                    <div>
                                      <p class="f-14 fw-600 m-0 heading_p_two">Affected Service Area:</p>
                                      <p class="f-14 fw-600 m-0 heading_p">Patio, Regular Terrace Dining and Top Bar</p>
                                    </div>
                                  </div>
                                </ng-template>
                              </ngb-panel>
                              <ngb-panel>
                                <ng-template ngbPanelTitle>
                                  <div>
                                    <div class="ts f-14">
                                      <div class="strom-main thund">
                                        <img src="../../../assets/images/Rain.png" alt="Thunderstorm" />
                                        <div class="date-strom">
                                          <div class="rain-strom">
                                            <h1>Saturday</h1>
                                            <p>Rain Strom</p>
                                          </div>
                                          <div class="strom-no">
                                            <p>72 &deg;F <span>/ </span>67 &deg;F</p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </ng-template>
                                <ng-template ngbPanelContent>
                                  <div class="">
                                    <p class="f-14 fw-400 m-0 heading_p">AI: Tomorrow, there is a 70% chance of rain from
                                      10:00 am to 4:00 pm. Please plan accordingly and stay safe.</p>

                                    <div>
                                      <p class="f-14 fw-600 m-0 heading_p_two">Affected Service Area:</p>
                                      <p class="f-14 fw-600 m-0 heading_p">Patio, Regular Terrace Dining and Top Bar</p>
                                    </div>
                                  </div>
                                </ng-template>
                              </ngb-panel>
                              <ngb-panel>
                                <ng-template ngbPanelTitle>
                                  <div>
                                    <div class="ts f-14">
                                      <div class="strom-main thund">
                                        <img src="../../../assets/images/sun.png" alt="Thunderstorm" />
                                        <div class="date-strom">
                                          <div class="rain-strom">
                                            <h1>Sunday</h1>
                                            <p>Rain Strom</p>
                                          </div>
                                          <div class="strom-no">
                                            <p>72 &deg;F <span>/ </span>67 &deg;F</p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </ng-template>
                                <ng-template ngbPanelContent>
                                  <div class="">
                                    <p class="f-14 fw-400 m-0 heading_p">AI: Tomorrow, there is a 70% chance of rain from
                                      10:00 am to 4:00 pm. Please plan accordingly and stay safe.</p>

                                    <div>
                                      <p class="f-14 fw-600 m-0 heading_p_two">Affected Service Area:</p>
                                      <p class="f-14 fw-600 m-0 heading_p">Patio, Regular Terrace Dining and Top Bar</p>
                                    </div>
                                  </div>
                                </ng-template>
                              </ngb-panel> -->
                              </ngb-accordion>
                            </div>
                          </div>
                        </ng-container>
                        <ng-container *ngIf="weatherList.length == 0">
                          <div
                            class="strom-data-main"
                            style="height: fit-content"
                          >
                            <div>
                              <ngb-accordion #acc="ngbAccordion">
                                <!-- <ng-container> -->
                                <ngb-panel>
                                  <ng-template ngbPanelTitle>
                                    <div
                                      class="align-items-center"
                                      style="
                                        margin-top: 10px;
                                        margin-bottom: 10px;
                                      "
                                    >
                                      <div class="f-14 mb-0 text-center">
                                        <img
                                          src="../../../assets/images/location.png"
                                          height="50px"
                                        />
                                      </div>
                                      <div
                                        class="f-16 mt-2 mb-0 text-center fw-bold"
                                      >
                                        {{ "Please select a location" | translate | async }}
                                      </div>
                                      <div
                                        class="mb-0 mt-2 d-flex align-items-center f-12 gap-2 text-center"
                                      >
                                        {{ "Location with a valid email-address is needed to get the weather-based insights" | translate | async }}
                                      </div>
                                    </div>
                                  </ng-template>
                                </ngb-panel>
                                <!-- </ng-container> -->
                              </ngb-accordion>
                            </div>
                          </div>
                        </ng-container>
                        <div class="close_btn">
                          <button
                            class="close_btn_theme bg-white"
                            (click)="myDroph?.toggle()"
                          >
                            {{ "CLOSE" | translate | async }}
                          </button>
                        </div>
                      </ng-template>
                    </li>
                    <li [ngbNavItem]="5" [disabled]="true">
                      <a ngbNavLink
                        ><img
                          src="../../../assets/images/ai.png"
                          style="width: 32px"
                          alt="artificial_intelligence"
                          class="tab_icon"
                      /></a>
                      <ng-template ngbNavContent> </ng-template>
                    </li>
                    <li [ngbNavItem]="6" [disabled]="true">
                      <a ngbNavLink
                        ><img
                          src="../../../assets/images/group.png"
                          class="tab_icon"
                          alt="group"
                      /></a>
                      <ng-template ngbNavContent> </ng-template>
                    </li>
                  </ul>
                  <div [ngbNavOutlet]="nav"></div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- <div class="dropdown-box d-inline-block" ngbDropdown #myDroph="ngbDropdown">
        <button type="button" class="btn header-item noti-icon d-flex align-items-center"
          id="page-header-notifications-dropdown" ngbDropdownToggle>
          <i [ngClass]="notificationCount == 0 ? '' : 'bx-tada'" class="bx bx-bell"></i>
          <span class="badge bg-danger rounded-pill">{{
            notificationCount
            }}</span>
        </button>
        <div class="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
          aria-labelledby="page-header-notifications-dropdown" ngbDropdownMenu>
          <div class="p-3">
            <div class="row align-items-center">
              <div class="col">
                <h6 class="m-0 fw-600">
                  {{ "HEADER.NOTIFICATIONS.TITLE" | translate }}
                </h6>
              </div>
              <div class="col-auto cursor-pointer fs-5" ngbDropdownAnchor (click)="myDroph.toggle()">
                <i class="fa-solid fa-xmark"></i>
              </div>
            </div>
          </div>
          <ngx-simplebar style="
              position: relative;
              min-height: fit-content;
              max-height: 358px;
            " [ngClass]="notificationCount > 3 ? 'notification-height' : ''">
            <ng-container *ngFor="let notif of notificationList; index as i">
              <a href="javascript: void(0);" class="text-reset notification-item">
                <div class="d-flex flex-column title" [ngClass]="notif.isMarkedRead ? 'markAsRead' : ''">
                  <div class="d-flex">
                    <div class="avatar-xs me-2">
                      <span class="avatar-title bg-primary rounded-circle font-size-16"
                        style="background: #212121 !important">
                        <img *ngIf="notif.image != ''" class="rounded-circle header-profile-user"
                          [src]="'https://' + notif.image" />
                        <span *ngIf="notif.image == ''" style="
                            background: #212121;
                            width: 29.5px;
                            height: 27.5px;
                            border-radius: 50%;
                            display: grid;
                            color: #fff;
                            align-items: center;
                            text-align: center;
                          ">
                          <span style="font-weight: 700">
                            {{ notif.char }}
                          </span>
                        </span>
                      </span>
                    </div>
                    <div class="flex-grow-1">
                      <div class="d-flex align-itrems-center justify-content-between mb-1">
                        <h6 class="mb-1 font-size-15 fw-600">
                          {{ notif.title | translate }}
                        </h6>
                        <div class="d-flex gap-4 align-items-center">
                          <i *ngIf="notif.isFlagged" [ngClass]="
                              notif.isFlagged
                                ? 'notification-show'
                                : 'notification-hover'
                            " class="fa-regular fa-flag font-size-16" (click)="isFlagged(i, false)"
                            style="color: #212121"></i>
                          <i *ngIf="!notif.isFlagged" class="fa-regular fa-flag font-size-16 notification-hover"
                            (click)="isFlagged(i, true)" style="color: #d2d2d2"></i>
                          <img [ngClass]="
                              notif.isPinned
                                ? 'notification-show'
                                : 'notification-hover'
                            " *ngIf="notif.isPinned" (click)="isPinned(i, false)" src="../../../assets/images/pin.svg"
                            alt="" />
                          <img class="notification-hover" *ngIf="!notif.isPinned" (click)="isPinned(i, true)"
                            src="../../../assets/images/grey-pin.svg" alt="" />
                          <button class="arrow_theme" (click)="notiseen(i)">
                            <i class="fa-solid fa-chevron-down text-black" *ngIf="!notif.show"></i>
                            <i class="fa-solid fa-chevron-up text-black" *ngIf="notif.show"></i>
                          </button>
                        </div>
                      </div>
                      <div class="font-size-14 text-muted">
                        <p class="mb-1">
                          {{ notif.message | translate }}
                        </p>
                        <p class="mb-0 text-black">
                          <i class="mdi mdi-clock-outline"></i>
                          {{ notif.timeElapsed | translate }}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="d-flex justify-content-between pt-2 px-4" *ngIf="notif.show">
                    <a href="javascript: void(0);" class="font-size-16 fw-500" (click)="markAsRead(i, true)">Mark as
                      read</a>
                    <a href="javascript: void(0);" class="font-size-16 fw-500" (click)="dismissNotif(i)">Dismiss</a>
                  </div>
                </div>
              </a>
            </ng-container>
            <ng-container *ngIf="notificationList.length == 0">
              <div class="py-3 px-4 d-flex justify-content-between align-items-center">
                No new Notifications
              </div>
            </ng-container>
          </ngx-simplebar>
          <ng-container *ngIf="notificationList.length != 0">
            <div class="py-3 px-4 d-flex justify-content-between align-items-center">
              <a href="javascript: void(0);" class="fw-600 font-size-14" (click)="markAllAsRead()">
                Mark all as read
              </a>
              <a href="javascript: void(0);" class="fw-600 font-size-14" (click)="dismissAllNotif()">
                Dismiss all
              </a>
            </div>
          </ng-container>
        </div>
      </div> -->

        <div class="dropdown d-inline-block" ngbDropdown>
          <ngx-skeleton-loader
            *ngIf="loader$"
            count="1"
            [theme]="{}"
            ngClass="skeleton-profile"
            appearance="circle"
            animation="progress"
          >
          </ngx-skeleton-loader>
          <!-- <ngx-skeleton-loader *ngIf="loader$" count="1" [theme]="{
            width: '120px'
          }" appearance="line" animation="progress"></ngx-skeleton-loader> -->
          <ng-container *ngIf="!loader$">
            <div class="dot-com">


            <button
              type="button"
              class="p-0 btn header-item "
              ngbDropdownToggle
              id="page-header-user-dropdown"
              (click)="profiledropdown()"
              (clickOutside)="profileclosePanel()"
            >
              <!-- <span
                *ngIf="employeeImage == ''"
                class="rounded-circle header-profile-user d-flex align-items-center justify-content-center"
                [ngStyle]="{
                  'background-color':
                    employeeColor == 'null' || employeeColor == ''
                      ? '#ed2890'
                      : employeeColor,
                  color: categoryColorNamee
                }"
                >{{ cutName }}</span
              > -->
              <img
                class="hover-shadow"
                *ngIf="employeeImage == ''"
                src="../../../assets/images/topbar-icons/Newtopbar/profile.svg"
                alt=""
              />
              <img
                *ngIf="employeeImage != ''"
                class="rounded-circle header-profile-user hover-shadow"
                (error)="employeeImage = ''"
                [src]="'https://' + employeeImage"
                alt="Header Avatar"
              />
            </button>
            <div class="train-main" *ngIf="!profileheaderValue">
              <div
                class="d-flex justify-content-between align-items-center training-mode"
              >
                <h2 class="m-0 f-14 fw-500">{{ "Account" | translate | async }}</h2>
                <div
                  class="form-check form-switch header-switch f-14 align-items-center"
                >
                </div>
              </div>
                  <p *hasPermission="['TrainingMode', 1]" class="f-12 fw-500 mb-0 train-RTL-details">
                    {{ "Easily manage your account and ensure your security with these options." | translate | async }}
                </p>

            </div>
          </div>
          </ng-container>
          <div class="dropdown-menu dropdown-menu-end profile-dropdown" ngbDropdownMenu>
            <a class="dropdown-item" (click)="navigateToProfile(false)" style="cursor: pointer;"
              ><i class="bx bx-user font-size-16 align-middle me-1"></i>
              <!-- {{ "HEADER.LOGIN.PROFILE" | translate }} -->
              {{ "Profile" | translate | async }}
            </a>
            <a
              class="dropdown-item"
              href="javascript: void(0);"
              (click)="showChangePasswordModal()"
            >
              <img
                src="assets/images/change-password-icon.svg"
                style="width: 25px; margin-left: -5px"
              />
              {{ "Change Password" | translate | async }}</a
            >
            <a
              class="dropdown-item"
              href="javascript: void(0);"
              (click)="showChangePINModal()"
            >
              <img
                src="assets/images/change-pin-icon.svg"
                style="width: 25px; margin-left: -5px"
              />
              {{ "Change PIN" | translate | async }}</a
            >
            <a
              class="dropdown-item text-black"
              href="javascript: void(0);"
              (click)="conFirmLogout()"
              ><i
                class="bx bx-power-off font-size-16 align-middle me-1 text-black"
              ></i>
              <!-- {{ "HEADER.LOGIN.LOGOUT" | translate | async }} -->
              {{ "Logout" | translate | async }}
            </a>

            <a href="" class="dropdown-item disabled-div" >
              <div class="form-switch dashboard-RTL-toggleBTN" >
                <span>{{ "RTL" | translate | async }}</span>
                <!-- formControlName="requirePin" (removed because it is causing errors on sentry, it also doesn't exist on ts file) -->
                <input
                  class="form-check-input"
                  type="checkbox"
                  role="switch"
                  (click)="toggleRTL()"
                  [checked]="Layoutselect === 'right'"
                  />
                  </div>
            </a>
          </div>
        </div>
        <div class="box-theme"></div>
        <div class="dropdown d-inline-block ms-1" ngbDropdown *ngIf="!testMode">
          <div class="dropdown d-inline-block " ngbDropdown>
            <div class="dot-com">
            <button
              type="button"
              class=" p-0 btn header-item noti-icon"
              ngbDropdownToggle
              (click)="supportdropdown()"
              (clickOutside)="supportclosePanel()"
            >
              <!-- <i class="bx bx-support align-middle"></i> -->
              <img
                class="hover-shadow"
                src="../../../assets/images/topbar-icons/Newtopbar/home-support-icon.svg"
                alt="headphone"
                height="24px"
                width="24px"
              />
            </button>
            <div class="main-head-img" *ngIf="!supportheaderValue">
              <div
                class="d-flex justify-content-between align-items-center training-mode"
              >
                <h2 class="m-0 f-14 fw-500">{{ "Support" | translate | async }}</h2>
                <div
                  class="form-check form-switch header-switch f-14 align-items-center"
                >
                </div>
              </div>
                <p *hasPermission="['TrainingMode', 1]" class="f-12 fw-500 mb-0 train-RTL-details">
                  {{ " Get quick assistance and resolve issues promptly." | translate | async }}
              </p>
            </div>
          </div>
            <div class="dropdown-menu dropdown-menu-end p-2" ngbDropdownMenu>
              <div
                style="
                  font-size: 13px !important;
                  padding-left: 20px;
                  padding-right: 20px;
                  text-align: center;
                "
              >
                <span class=""
                  >{{ "Support PIN" | translate | async }}
                  <i
                    *ngIf="supportLoader"
                    class="fas fa-sync-alt m-1 rotate"
                  ></i>
                  <i
                    *ngIf="!supportLoader"
                    class="fas fa-sync-alt m-1 cs-pointer"
                    (click)="generateSupportPin()"
                  ></i>
                </span>
                <div
                  class="support-pin cs-pointer"
                  ngxClipboard
                  [cbContent]="supportPin"
                  [ngbTooltip]="'Copied!' | translate | async"
                  [closeDelay]="2000"
                  triggers="manual"
                  #t="ngbTooltip"
                  (click)="t.open()"
                >
                  <span
                    class="fw-bolder"
                    [ngbTooltip]="'Click to copy PIN' | translate | async"
                    [closeDelay]="2000"
                    #t="ngbTooltip"
                  >
                    <ng-container *ngIf="displayPin.length > 0">
                      <span
                        *ngFor="let pin of displayPin; let index = i"
                        class=""
                      >
                        {{ pin }}
                      </span>
                    </ng-container>
                    <ng-container *ngIf="displayPin.length == 0">
                      <span class=""></span><span class=""></span
                      ><span class=""></span><span class=""></span
                      ><span class=""></span><span class=""></span>
                    </ng-container>
                  </span>
                </div>
              </div>
              <div class="btn-group">
                <button
                  class="btn btn-outline-dark f-14 fw-700"
                  style="margin-top: 10px; margin-left: 15px; width: 100px"
                  (click)="chatWithUs()"
                >
                  {{ "Live Chat" | translate | async }}
                </button>
                <button
                  class="btn btn-outline-dark f-14 fw-700"
                  style="margin-top: 10px; margin-right: 15px; width: 137px"
                  (click)="goToSupportCenter()"
                >
                  {{ "Support center" | translate | async }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <button class="menu-btn" (click)="OpenTopmenuList(content1)">
        <i class="fa-solid fa-ellipsis-vertical"></i>
      </button>
    </div>
  </div>
</header>
<!-- Modal for confirm logout -->
<ng-template #content role="document" let-modal>
  <div class="modal-body border-0">
    <div class="delete-icon-info">
      <div class="swal2-icon swal2-warning swal2-icon-show">
        <div class="swal2-icon-content">!</div>
      </div>
      <h2
        class="swal2-title m-0"
        id="swal2-title"
        style="display: flex; text-align: center"
      >
        {{ "Are you sure you want to logout?" | translate | async }}
      </h2>
    </div>
  </div>
  <div class="modal-footer border-0">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-12 text-center">
          <button class="btn fw-bold add-btn-one" (click)="logout()">
            {{ "Yes, do it!" | translate | async }}
          </button>
          <button
            class="btn btn-dark fw-bold add-btn-two"
            style="margin-left: 15px"
            (click)="cancelLogout()"
          >
            {{ "Cancel" | translate | async }}
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<!-- search bar ---->
<ng-template #SearchbarModel role="document" let-modal>
  <div class="modal-header uplode-header border-0 modal-doc">
    <!-- <h5 class="modal-title mt-0 text-dark fw-bold">Search Bar</h5> -->
    <button
      type="button"
      class="btn-close search-RTL_closeBTN"
      aria-hidden="true"
      (click)="closeModel()"
    ></button>
  </div>
  <div class="">
    <app-searchbar
      [Data]="SearchedValue"
      (dataEvent)="receiveDataFromSearchbar($event)"
    ></app-searchbar>
  </div>
</ng-template>

<ng-template #TrainingModel role="document" let-modal>
  <div class="modal-header uplode-header border-0 modal-doc">
    <!-- <h5 class="modal-title mt-0 text-dark fw-bold">Search Bar</h5> -->
    <button
      type="button"
      class="btn-close"
      aria-hidden="true"
      (click)="closeModel()"
    ></button>
  </div>
  <div class="">
    <!-- <app-searchbar [Data]="SearchedValue" (dataEvent)="receiveDataFromSearchbar($event)"></app-searchbar> -->
  </div>
</ng-template>

<div class="">
  <ng-template #content1 let-offcanvas>
    <div
      class="action-canva offcanvas-body p-md-3"
      [ngClass]="
        TopMenuDiscValue === 'Notification' ? 'notification-canva' : ''
      "
    >
      <div *ngIf="TopMenuDiscValue === 'main'">
        <div class="action-data">
          <h2 class="mb-0">{{ "Quick Actions" | translate | async }}</h2>
          <button
            type="button"
            class="btn-close"
            aria-label="Close"
            (click)="offcanvas.dismiss('Cross click')"
          ></button>
        </div>
        <div class="d-flex align-items-center gap-0 welcome-text date-data">
          <p class="m-0 f-12 fw-500">{{ formateDate | translate | async }}</p>
          <p *ngIf="timezoneName1" class="m-0 f-12 fw-500">
            ({{ timezoneName1 | translate | async }})
          </p>
        </div>
        <div class="search-main" (click)="OpenSearchbarModel()">
          <img src="../../../assets/images/home-search-icn.svg" alt="search" />
          <p class="f-14 fw-600 mb-0">{{ "Search" | translate | async }}</p>
        </div>
        <div class="search-main gap-1" (click)="onSettingsButtonClicked()">
          <button
            class="search-main m-0 p-0 btn noti-icon d-flex align-items-center"
          >
            <img
              src="../../../assets/images/companies/mayachatai.svg"
              alt="jamboard_kiosk"
              height="30px"
              width="30px"
                        />
          </button> <p class="f-14 fw-600 mb-0">{{ "Maya" | translate | async }}</p>
        </div>
        <div class="search-main" (click)="TopMenuDiscOpen('Apps')">
          <img
            src="../../../assets/images/topbar-icons/Newtopbar/Group 2520.svg"
            alt="grid"
          />
          <p class="f-14 fw-600 mb-0">{{ "Apps" | translate | async }}</p>
        </div>
        <div class="search-main" (click)="TopMenuDiscOpen('Account')">
          <img
            src="../../../assets/images/topbar-icons/Newtopbar/profile.svg"
            alt="Account"
          />
          <p class="f-14 fw-600 mb-0">{{ "Account" | translate | async }}</p>
        </div>
        <div class="search-main" (click)="TopMenuDiscOpen('Weather')">
          <img
            src="../../../assets/images/home-weather-icon.svg"
            style="width: 24px"
            alt="weather"
          />
          <p class="f-14 fw-600 mb-0">{{ "Weather" | translate | async }}</p>
        </div>
        <div class="search-main" (click)="TopMenuDiscOpen('Notification')">
          <button
            type="button"
            (click)="AcitvTab(1)"
            class="p-0 btn noti-icon d-flex align-items-center position-relative"
            id="page-header-wether-dropdown"
          >
            <!-- <i [ngClass]="notificationCount === 0 ? 'bx-tada' : ''" class="bx bx-bell"></i> -->
            <img
              class="hover-shadow"
              src="../../../assets/images/home-notification-icon.svg"
              alt="notification"
              height="24px"
              width="24px"
            />
            <span
              class="badge rounded-pill"
              [ngClass]="{
                'bg-danger':
                  notificationCount !== 0 && notificationCount !== undefined
              }"
              >{{ notificationCount }}</span
            >
          </button>
          <p class="f-14 fw-600 mb-0">{{ "Notification" | translate | async }}</p>
        </div>
        <div class="search-main" (click)="TopMenuDiscOpen('Training')">
          <div class="dot-com">
            <button class="p-0 btn noti-icon d-flex align-items-center">
              <img
                class="hover-shadow"
                src="../../../assets/images/topbar-icons/Newtopbar/jamboard_kiosk.svg"
                alt="jamboard_kiosk"
                height="24px"
                width="24px"
              />
            </button>
            <div *ngIf="checkBoxValue" class="dot-div"></div>
          </div>
          <p class="f-14 fw-600 mb-0">{{ "Training Mode" | translate | async }}</p>
        </div>
        <div class="search-main" (click)="TopMenuDiscOpen('Quick-Store')">
          <button type="button" class="btn noti-icon p-0">
            <img
              class="hover-shadow"
              src="../../../assets/images/topbar-icons/Newtopbar/bx-store.svg"
              height="24px"
              width="24px"
            />
          </button>
          <p class="f-14 fw-600 mb-0">{{ "Quick Store Settings" | translate | async }}</p>
        </div>
        <div
          class="search-main border-support"
          (click)="TopMenuDiscOpen('Support')"
        >
          <img
            src="../../../assets/images/topbar-icons/Newtopbar/home-support-icon.svg"
            style="width: 24px"
            alt="support"
          />
          <p class="f-14 fw-600 mb-0">{{ "Support" | translate | async }}</p>
        </div>
      </div>
      <div *ngIf="TopMenuDiscValue === 'Apps'">
        <div class="app-main app-border">
          <button (click)="TopMenuDiscOpen('main')">
            <i class="fa-solid fa-arrow-left"></i>
          </button>
          <p class="f-14 fw-600 mb-0">{{ "Apps" | translate | async }}</p>
        </div>
        <!-- <div class="app-manager">
          <div class="app-data">
            <a
              type="button"
              (click)="redirect('/sites/point-of-sale'); myDrop.close()"
            >
              <div
                class="d-flex align-items-center"
                (mouseover)="addClass($event)"
                (mouseleave)="removeClass($event)"
              >
                <img src="assets/images/POS.png" alt="" height="48" />
                <h6 class="sale-data">{{ "Point of Sale" | translate | async }}</h6>
              </div>
            </a>
          </div>
          <div class="app-data">
            <a
              type="button"
              (click)="redirect('/sites/point-of-purchase'); myDrop.close()"
            >
              <div class="d-flex align-items-center">
                <img src="assets/images/POP.png" alt="" height="48" />
                <h6 class="sale-data">{{ "Point of Purchase" | translate | async }}</h6>
              </div>
            </a>
          </div>
          <div class="app-data">
            <a
              type="button"
              (click)="
                redirect('/sites/online-ordering-and-delivery'); myDrop.close()
              "
            >
              <div class="d-flex align-items-center">
                <img src="assets/images/OrderOS.png" alt="" height="48" />
                <h6 class="sale-data">{{ "Online Ordering and Delivery" | translate | async }}</h6>
              </div>
            </a>
          </div>
          <div class="app-data">
            <a
              type="button"
              (click)="
                redirect('/sites/kitchen-display-system'); myDrop.close()
              "
            >
              <div class="d-flex align-items-center">
                <img src="assets/images/KDS.png" alt="" height="48" />
                <h6 class="sale-data">{{ "Kitchen Display System" | translate | async }}</h6>
              </div>
            </a>
          </div>
          <div class="app-data">
            <a
              type="button"
              (click)="redirect('/sites/self-serving-kiosk'); myDrop.close()"
            >
              <div class="d-flex align-items-center">
                <img src="assets/images/Kiosk.png" alt="" height="48" />
                <h6 class="sale-data">{{ "Self Serving Kiosk" | translate | async }}</h6>
              </div>
            </a>
          </div>
          <div class="app-data">
            <a
              type="button"
              (click)="redirect('/sites/workforce-management'); myDrop.close()"
            >
              <div class="d-flex align-items-center">
                <img src="assets/images/WFM.png" alt="" height="48" />
                <h6 class="sale-data">{{ "Workforce Management" | translate | async }}</h6>
              </div>
            </a>
          </div>
          <div class="app-data">
            <a
              type="button"
              (click)="
                redirect('/sites/customer-facing-display'); myDrop.close()
              "
            >
              <div class="d-flex align-items-center">
                <img src="assets/images/CFD.png" alt="" height="48" />
                <h6 class="sale-data">{{ "Customer Facing Display" | translate | async }}</h6>
              </div>
            </a>
          </div>
          <div class="app-data">
            <a
              type="button"
              (click)="
                redirect('/sites/table-side-order-and-ay'); myDrop.close()
              "
            >
              <div class="d-flex align-items-center">
                <img
                  src="assets/images/Tableside Ordering and Pay.png"
                  alt=""
                  height="48"
                />
                <h6 class="sale-data">{{ "Table-side Order and Pay" | translate | async }}</h6>
              </div>
            </a>
          </div>
          <div class="app-data">
            <a
              type="button"
              (click)="
                redirect('/sites/analytics-and-Reporting'); myDrop.close()
              "
            >
              <div class="d-flex align-items-center">
                <img src="assets/images/Analytics.png" alt="" height="48" />
                <h6 class="sale-data">{{ "Analytics and Reporting" | translate | async }}</h6>
              </div>
            </a>
          </div>
        </div> -->
      </div>
      <div class="" *ngIf="TopMenuDiscValue === 'Account'">
        <div class="app-main app-border">
          <button (click)="TopMenuDiscOpen('main')">
            <i class="fa-solid fa-arrow-left"></i>
          </button>
          <p class="f-14 fw-600 mb-0">{{ "Account" | translate | async }}</p>
        </div>
        <div class="quick-store">
          <a class="dropdown-item" (click)="navigateToProfile(true)">
            <img src="../../../assets/images/profile.svg" alt="profile" />
            <span>{{ "HEADER.LOGIN.PROFILE" | translate }}</span></a
          >
          <a
            class="dropdown-item"
            href="javascript: void(0);"
            (click)="showChangePasswordModal()"
          >
            <img src="assets/images/lock-password.svg" alt="lock" />
            <span>{{ "Change Password" | translate | async }}</span>
          </a>
          <a
            class="dropdown-item"
            href="javascript: void(0);"
            (click)="showChangePINModal()"
          >
            <img src="assets/images/key.svg" alt="key" />
            <span>{{ "Change PIN" | translate | async }}</span></a
          >
          <a
            class="dropdown-item text-black"
            href="javascript: void(0);"
            (click)="conFirmLogout()"
          >
            <img src="assets/images/logout-circle.svg" alt="logout-circle" />
            <span>
              <!-- {{ "HEADER.LOGIN.LOGOUT" | translate }} -->
              {{ "Logout" | translate | async }}
            </span>
          </a>
        </div>
      </div>
      <div class="" *ngIf="TopMenuDiscValue === 'Weather'">
        <div class="app-main app-border">
          <button (click)="TopMenuDiscOpen('main')">
            <i class="fa-solid fa-arrow-left"></i>
          </button>
          <p class="f-14 fw-600 mb-0">Apps</p>
        </div>
        <div class="">{{ "Weather" | translate | async }}</div>
      </div>
      <div class="" *ngIf="TopMenuDiscValue === 'Notification'">
        <div class="app-main app-border">
          <button (click)="TopMenuDiscOpen('main')">
            <i class="fa-solid fa-arrow-left"></i>
          </button>
          <p
            *ngIf="NotificationMenu === 'Notification'"
            class="f-14 fw-600 mb-0"
          >
            {{ "Notification" | translate | async }}
          </p>
          <p *ngIf="NotificationMenu === 'Weather'" class="f-14 fw-600 mb-0">
            {{ "Weather" | translate | async }}
          </p>
        </div>
        <div
          class="dropdown-menu dropdown-menu-lg dropdown-size dropdown-menu-end d-block p-0"
        >
          <div class="weather-main">
            <div class="row align-items-center">
              <div class="col">
                <h6 class="m-0 fw-600 wnot" *ngIf="active === 1">
                  <!-- All {{ "HEADER.NOTIFICATIONS.TITLE" | translate }} -->
                </h6>
                <!-- <h6 class="m-0 fw-600 wnot" *ngIf="active === 4">
              Weather Notifications
            </h6> -->
              </div>
            </div>
            <div class="tab-main">
              <div class="manage-tabddd">
                <ul
                  ngbNav
                  #nav="ngbNav"
                  [(activeId)]="active"
                  class="nav-tabs justify-content-between align-items-center flex-nowrap"
                >
                  <li [ngbNavItem]="1" class="relative menu-nav">
                    <a class="p-0" ngbNavLink>
                      <i
                        class="fa-solid fa-list-ul f-20 tab_icon_two"
                        [ngClass]="
                          active === 1 ? 'tab_icon2_two' : 'tab_icon_two'
                        "
                      ></i
                    ></a>

                    <span
                      class="badge rounded-pill"
                      [ngClass]="
                        active === 1 ? 'rounded_pill_two' : 'rounded-pill'
                      "
                      >{{ notificationCount }}</span
                    >
                    <ng-template ngbNavContent>
                      <ngx-simplebar
                        style="
                          position: relative;
                          min-height: fit-content;
                          max-height: 358px;
                        "
                        [ngClass]="
                          notificationCount > 3 ? 'notification-height' : ''
                        "
                      >
                        <ng-container
                          *ngFor="let notif of notificationList; index as i"
                        >
                          <a
                            href="javascript: void(0);"
                            class="text-reset notification-item"
                          >
                            <div
                              class="d-flex flex-column title"
                              [ngClass]="notif.isMarkedRead ? 'markAsRead' : ''"
                            >
                              <div class="d-flex">
                                <div class="avatar-xs me-2">
                                  <span
                                    class="avatar-title bg-primary rounded-circle font-size-16"
                                    style="background: #212121 !important"
                                  >
                                    <img
                                      *ngIf="notif.image != ''"
                                      class="rounded-circle header-profile-user"
                                      [src]="'https://' + notif.image"
                                    />
                                    <span
                                      *ngIf="notif.image == ''"
                                      style="
                                        background: #212121;
                                        width: 29.5px;
                                        height: 27.5px;
                                        border-radius: 50%;
                                        display: grid;
                                        color: #fff;
                                        align-items: center;
                                        text-align: center;
                                      "
                                    >
                                      <span style="font-weight: 700">
                                        {{ notif.char }}
                                      </span>
                                    </span>
                                  </span>
                                </div>
                                <div class="flex-grow-1">
                                  <div
                                    class="d-flex align-itrems-center justify-content-between mb-1"
                                  >
                                    <h6 class="mb-1 font-size-15 fw-600">
                                      {{ notif.title | translate }}
                                    </h6>
                                    <div
                                      class="d-flex gap-4 align-items-center"
                                    >
                                      <i
                                        *ngIf="notif.isFlagged"
                                        [ngClass]="
                                          notif.isFlagged
                                            ? 'notification-show'
                                            : 'notification-hover'
                                        "
                                        class="fa-regular fa-flag font-size-16"
                                        (click)="isFlagged(i, false)"
                                        style="color: #212121"
                                      ></i>
                                      <i
                                        *ngIf="!notif.isFlagged"
                                        class="fa-regular fa-flag font-size-16 notification-hover"
                                        (click)="isFlagged(i, true)"
                                        style="color: #d2d2d2"
                                      ></i>
                                      <img
                                        [ngClass]="
                                          notif.isPinned
                                            ? 'notification-show'
                                            : 'notification-hover'
                                        "
                                        *ngIf="notif.isPinned"
                                        (click)="isPinned(i, false)"
                                        src="../../../assets/images/pin.svg"
                                        alt=""
                                      />
                                      <img
                                        class="notification-hover"
                                        *ngIf="!notif.isPinned"
                                        (click)="isPinned(i, true)"
                                        src="../../../assets/images/grey-pin.svg"
                                        alt=""
                                      />
                                      <button
                                        class="arrow_theme"
                                        (click)="notiseen(i)"
                                      >
                                        <i
                                          class="fa-solid fa-chevron-down text-black"
                                          *ngIf="!notif.show"
                                        ></i>
                                        <i
                                          class="fa-solid fa-chevron-up text-black"
                                          *ngIf="notif.show"
                                        ></i>
                                      </button>
                                    </div>
                                  </div>
                                  <div class="font-size-14 text-muted">
                                    <p class="mb-1">
                                      {{ notif.message | translate | async }}
                                    </p>
                                    <p class="mb-0 text-black">
                                      <i class="mdi mdi-clock-outline"></i>
                                      {{ notif.timeElapsed | translate | async }}
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div
                                class="d-flex justify-content-between pt-2 px-4"
                                *ngIf="notif.show"
                              >
                                <a
                                  href="javascript: void(0);"
                                  class="font-size-16 fw-500"
                                  (click)="markAsRead(i, true)"
                                  >Mark as read</a
                                >
                                <a
                                  href="javascript: void(0);"
                                  class="font-size-16 fw-500"
                                  (click)="dismissNotif(i)"
                                  >{{ "Dismiss" | translate | async }}</a
                                >
                              </div>
                            </div>
                          </a>
                        </ng-container>
                        <ng-container *ngIf="notificationList?.length == 0">
                          <div
                            class="py-3 px-4 d-flex justify-content-between align-items-center"
                          >
                            {{ "No new Notifications" | translate | async }}
                          </div>
                        </ng-container>
                      </ngx-simplebar>
                      <ng-container *ngIf="notificationList?.length != 0">
                        <div
                          class="py-3 px-4 d-flex justify-content-between align-items-center"
                        >
                          <a
                            href="javascript: void(0);"
                            class="fw-600 f-14"
                            (click)="markAllAsRead()"
                          >
                            {{ "Mark all as read" | translate | async }}
                          </a>
                          <a
                            href="javascript: void(0);"
                            class="fw-600 f-14"
                            (click)="dismissAllNotif()"
                          >
                            {{ "Dismiss all" | translate | async }}
                          </a>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="notificationList?.length != 0">
                        <div class="close_btn">
                          <button
                            class="fw-600 close_btn_theme bg-white"
                            (click)="myDroph?.toggle()"
                          >
                            {{ "CLOSE" | translate | async }}
                          </button>
                        </div>
                      </ng-container>
                    </ng-template>
                  </li>
                  <li [ngbNavItem]="2" [disabled]="true">
                    <a ngbNavLink>
                      <img
                        src="../../../assets/images/speaker.png"
                        class="tab_icon"
                        style="width: 30px !important"
                        alt="speaker"
                    /></a>
                    <ng-template ngbNavContent> </ng-template>
                  </li>
                  <li [ngbNavItem]="3" [disabled]="true">
                    <a ngbNavLink
                      ><img
                        src="../../../assets/images/upload1.png"
                        class="tab_icon"
                        alt="upload1"
                    /></a>
                    <ng-template ngbNavContent> </ng-template>
                  </li>
                  <li [ngbNavItem]="4">
                    <a ngbNavLink
                      ><img
                        src="../../../assets/images/weather.png"
                        class="tab_icon"
                        alt="weather"
                    /></a>
                    <span class="badge rounded-pill rounded_pill_two">{{
                      weatherCount
                    }}</span>
                    <ng-template ngbNavContent>
                      <ng-container *ngIf="weatherList.length > 0">
                        <div class="strom-data-main">
                          <div class="dinning-main m-0">
                            <ngb-accordion #acc="ngbAccordion">
                              <ng-container
                                *ngFor="let weather of weatherList; index as i"
                              >
                                <ngb-panel>
                                  <ng-template ngbPanelTitle>
                                    <div (click)="Showtext(i)">
                                      <div class="ts f-14">
                                        <div class="strom-main thund">
                                          <!-- weatherbased images -->
                                          <img
                                            *ngIf="weather.iconName == 'sun'"
                                            src="../../../assets/images/sun.png"
                                            alt="Thunderstorm"
                                          />
                                          <img
                                            *ngIf="
                                              weather.iconName == 'broken_cloud'
                                            "
                                            src="../../../assets/images/broken_cloud.png"
                                            alt="Thunderstorm"
                                            height="40px"
                                          />
                                          <img
                                            *ngIf="weather.iconName == 'rain'"
                                            src="../../../assets/images/Rain.png"
                                            alt="Thunderstorm"
                                            height="40px"
                                          />
                                          <img
                                            *ngIf="weather.iconName == 'snow'"
                                            src="../../../assets/images/snow.png"
                                            alt="Thunderstorm"
                                            height="40px"
                                          />
                                          <div class="date-strom">
                                            <div class="rain-strom">
                                              <h1>{{ weather.dayOfWeek | translate | async }}</h1>
                                              <p>{{ weather.weatherText | translate | async }}</p>
                                            </div>
                                            <div class="strom-no">
                                              <p class="pb-2">
                                                {{ weather.maxTemp }} &deg;F
                                                <span>/ </span
                                                >{{ weather.minTemp }} &deg;F
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                        <div
                                          class="d-flex align-items-center justify-content-between"
                                          *ngIf="!weather.isShown"
                                        >
                                          <p class="f-14 mb-0">
                                            {{ weather.trncDesc }}
                                          </p>
                                          <p
                                            *ngIf="weather.affectAreasCount > 0"
                                            class="warning_txt mb-0 d-flex align-items-center f-14 gap-2"
                                          >
                                            <i
                                              class="fa-regular fa-circle-exclamation"
                                            ></i
                                            >{{ weather.affectAreasCount }}
                                            {{ "Warnings" | translate | async }}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </ng-template>
                                  <ng-template ngbPanelContent>
                                    <div class="">
                                      <p class="f-14 fw-400 m-0 heading_p">
                                        {{ weather.description | translate | async }}
                                      </p>

                                      <div *ngIf="weather.affectAreasCount > 0">
                                        <p
                                          class="f-14 fw-600 m-0 heading_p_two"
                                        >
                                          {{ "Affected Service Area:" | translate | async }}
                                        </p>
                                        <p class="f-14 fw-600 m-0 heading_p">
                                          {{ weather.affectAreas }}
                                        </p>
                                      </div>
                                    </div>
                                  </ng-template>
                                </ngb-panel>
                              </ng-container>

                              <!-- <ngb-panel>
                            <ng-template ngbPanelTitle>
                              <div (click)="isShown = !isShown">
                                <div class="ts f-14">
                                  <div class="strom-main thund">
                                    <img src="../../../assets/images/Thunderstorm.png" alt="Thunderstorm" />
                                    <div class="date-strom">
                                      <div class="rain-strom">
                                        <h1>Today, Monday</h1>
                                        <p>Rain Strom</p>
                                      </div>
                                      <div class="strom-no">
                                        <p class="pb-2">72 &deg;F <span>/ </span>67 &deg;F</p>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="d-flex  align-items-center justify-content-between" *ngIf='!isShown' >
                                    <p  class="f-14 mb-0">AI: Tomorrow, there is a 70% ch...</p>
                                    <p class="warning_txt mb-0 d-flex align-items-center f-14 gap-2"><i
                                      class="fa-regular fa-circle-exclamation"></i>3 Warnings</p>
                                  </div>
                                </div>
                              </div>
                            </ng-template>
                            <ng-template ngbPanelContent>
                              <div class="">
                                <p class="f-14 fw-400 m-0 heading_p ">AI: Tomorrow, there is a 70% chance of rain from
                                  10:00 am to 4:00 pm. Please plan accordingly and stay safe.</p>

                                <div>
                                  <p class="f-14 fw-600 m-0 heading_p_two">Affected Service Area:</p>
                                  <p class="f-14 fw-600 m-0 heading_p">Patio, Regular Terrace Dining and Top Bar</p>
                                </div>
                              </div>
                            </ng-template>
                          </ngb-panel>
                          <ngb-panel>
                            <ng-template ngbPanelTitle>
                              <div>
                                <div class="ts f-14">
                                  <div class="strom-main thund">
                                    <img src="../../../assets/images/Rain.png" alt="Thunderstorm" />
                                    <div class="date-strom">
                                      <div class="rain-strom">
                                        <h1>Wednesday</h1>
                                        <p>Cloud</p>
                                      </div>
                                      <div class="strom-no">
                                        <p class="pb-2">72 &deg;F <span>/ </span>67 &deg;F</p>
                                        <p class="warning_txt mt-1 d-flex align-items-center gap-2"><i
                                            class="fa-regular fa-circle-exclamation"></i>3 Warnings</p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </ng-template>
                            <ng-template ngbPanelContent>
                              <div class="">
                                <p class="f-14 fw-400 m-0 heading_p">AI: Tomorrow, there is a 70% chance of rain from
                                  10:00 am to 4:00 pm. Please plan accordingly and stay safe.</p>

                                <div>
                                  <p class="f-14 fw-600 m-0 heading_p_two">Affected Service Area:</p>
                                  <p class="f-14 fw-600 m-0 heading_p">Patio, Regular Terrace Dining and Top Bar</p>
                                </div>
                              </div>
                            </ng-template>
                          </ngb-panel>
                          <ngb-panel>
                            <ng-template ngbPanelTitle>
                              <div>
                                <div class="ts f-14">
                                  <div class="strom-main thund">
                                    <img src="../../../assets/images/sun.png" alt="Thunderstorm" />
                                    <div class="date-strom">
                                      <div class="rain-strom">
                                        <h1>Thursday</h1>
                                        <p>Clear</p>
                                      </div>
                                      <div class="strom-no">
                                        <p>72 &deg;F <span>/ </span>67 &deg;F</p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </ng-template>
                            <ng-template ngbPanelContent>
                              <div class="">
                                <p class="f-14 fw-400 m-0 heading_p">AI: Tomorrow, there is a 70% chance of rain from
                                  10:00 am to 4:00 pm. Please plan accordingly and stay safe.</p>

                                <div>
                                  <p class="f-14 fw-600 m-0 heading_p_two">Affected Service Area:</p>
                                  <p class="f-14 fw-600 m-0 heading_p">Patio, Regular Terrace Dining and Top Bar</p>
                                </div>
                              </div>
                            </ng-template>
                          </ngb-panel>
                          <ngb-panel>
                            <ng-template ngbPanelTitle>
                              <div>
                                <div class="ts f-14">
                                  <div class="strom-main thund">
                                    <img src="../../../assets/images/sun.png" alt="Thunderstorm" />
                                    <div class="date-strom">
                                      <div class="rain-strom">
                                        <h1>Friday</h1>
                                        <p>Rain Strom</p>
                                      </div>
                                      <div class="strom-no">
                                        <p>72 &deg;F <span>/ </span>67 &deg;F</p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </ng-template>
                            <ng-template ngbPanelContent>
                              <div class="">
                                <p class="f-14 fw-400 m-0 heading_p">AI: Tomorrow, there is a 70% chance of rain from
                                  10:00 am to 4:00 pm. Please plan accordingly and stay safe.</p>

                                <div>
                                  <p class="f-14 fw-600 m-0 heading_p_two">Affected Service Area:</p>
                                  <p class="f-14 fw-600 m-0 heading_p">Patio, Regular Terrace Dining and Top Bar</p>
                                </div>
                              </div>
                            </ng-template>
                          </ngb-panel>
                          <ngb-panel>
                            <ng-template ngbPanelTitle>
                              <div>
                                <div class="ts f-14">
                                  <div class="strom-main thund">
                                    <img src="../../../assets/images/Rain.png" alt="Thunderstorm" />
                                    <div class="date-strom">
                                      <div class="rain-strom">
                                        <h1>Saturday</h1>
                                        <p>Rain Strom</p>
                                      </div>
                                      <div class="strom-no">
                                        <p>72 &deg;F <span>/ </span>67 &deg;F</p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </ng-template>
                            <ng-template ngbPanelContent>
                              <div class="">
                                <p class="f-14 fw-400 m-0 heading_p">AI: Tomorrow, there is a 70% chance of rain from
                                  10:00 am to 4:00 pm. Please plan accordingly and stay safe.</p>

                                <div>
                                  <p class="f-14 fw-600 m-0 heading_p_two">Affected Service Area:</p>
                                  <p class="f-14 fw-600 m-0 heading_p">Patio, Regular Terrace Dining and Top Bar</p>
                                </div>
                              </div>
                            </ng-template>
                          </ngb-panel>
                          <ngb-panel>
                            <ng-template ngbPanelTitle>
                              <div>
                                <div class="ts f-14">
                                  <div class="strom-main thund">
                                    <img src="../../../assets/images/sun.png" alt="Thunderstorm" />
                                    <div class="date-strom">
                                      <div class="rain-strom">
                                        <h1>Sunday</h1>
                                        <p>Rain Strom</p>
                                      </div>
                                      <div class="strom-no">
                                        <p>72 &deg;F <span>/ </span>67 &deg;F</p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </ng-template>
                            <ng-template ngbPanelContent>
                              <div class="">
                                <p class="f-14 fw-400 m-0 heading_p">AI: Tomorrow, there is a 70% chance of rain from
                                  10:00 am to 4:00 pm. Please plan accordingly and stay safe.</p>

                                <div>
                                  <p class="f-14 fw-600 m-0 heading_p_two">Affected Service Area:</p>
                                  <p class="f-14 fw-600 m-0 heading_p">Patio, Regular Terrace Dining and Top Bar</p>
                                </div>
                              </div>
                            </ng-template>
                          </ngb-panel> -->
                            </ngb-accordion>
                          </div>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="weatherList.length == 0">
                        <div
                          class="strom-data-main"
                          style="height: fit-content"
                        >
                          <div>
                            <ngb-accordion #acc="ngbAccordion">
                              <!-- <ng-container> -->
                              <ngb-panel>
                                <ng-template ngbPanelTitle>
                                  <div
                                    class="align-items-center"
                                    style="
                                      margin-top: 10px;
                                      margin-bottom: 10px;
                                    "
                                  >
                                    <div class="f-14 mb-0 text-center">
                                      <img
                                        src="../../../assets/images/location.png"
                                        height="50px"
                                      />
                                    </div>
                                    <div
                                      class="f-16 mt-2 mb-0 text-center fw-bold"
                                    >
                                      {{ "Please select a location" | translate | async }}
                                    </div>
                                    <div
                                      class="mb-0 mt-2 d-flex align-items-center f-12 gap-2 text-center"
                                    >
                                      {{ "Location with a valid email-address is needed to get the weather-based insights" | translate | async }}
                                    </div>
                                  </div>
                                </ng-template>
                              </ngb-panel>
                              <!-- </ng-container> -->
                            </ngb-accordion>
                          </div>
                        </div>
                      </ng-container>
                      <div class="close_btn">
                        <button
                          class="close_btn_theme bg-white"
                          (click)="myDroph?.toggle()"
                        >
                          {{ "CLOSE" | translate | async }}
                        </button>
                      </div>
                    </ng-template>
                  </li>
                  <li [ngbNavItem]="5" [disabled]="true">
                    <a ngbNavLink
                      ><img
                        src="../../../assets/images/ai.png"
                        style="width: 32px"
                        alt="artificial_intelligence"
                        class="tab_icon"
                    /></a>
                    <ng-template ngbNavContent> </ng-template>
                  </li>
                  <li [ngbNavItem]="6" [disabled]="true">
                    <a ngbNavLink
                      ><img
                        src="../../../assets/images/group.png"
                        class="tab_icon"
                        alt="group"
                    /></a>
                    <ng-template ngbNavContent> </ng-template>
                  </li>
                </ul>
                <div [ngbNavOutlet]="nav"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="" *ngIf="TopMenuDiscValue === 'Training'">
        <div class="app-main app-border">
          <button (click)="TopMenuDiscOpen('main')">
            <i class="fa-solid fa-arrow-left"></i>
          </button>
          <p class="f-14 fw-600 mb-0">{{ "Training Mode" | translate | async }}</p>
        </div>
        <div>
          <div class="train-main">
            <div
              class="d-flex justify-content-between align-items-center training-mode"
            >
              <h2 class="m-0 f-14 fw-500">{{ "Training Mode" | translate | async }}</h2>
              <div
                class="form-check form-switch header-switch f-14 align-items-center"
              >
                <ui-switch
                  defaultBoColor="#dfdfdf"
                  color="#666666"
                  uncheckedLabel="OFF"
                  checkedLabel="ON"
                  size="small"
                  [checked]="checkBoxValue"
                  (change)="toggleTestMode($event)"
                ></ui-switch>
              </div>
            </div>

            <p class="f-12 fw-500 mb-0">
              {{ "Turn on the training mode to test and learn our system in a controlled environment. Changes made in Training Mode stay isolated and doesn't affect the live data." | translate | async }}
            </p>
          </div>
        </div>
      </div>
      <div class="" *ngIf="TopMenuDiscValue === 'Quick-Store'">
        <div class="app-main app-border">
          <button (click)="TopMenuDiscOpen('main')">
            <i class="fa-solid fa-arrow-left"></i>
          </button>
          <p class="f-14 fw-600 mb-0">{{ "Quick Store Settings" | translate | async }}</p>
        </div>
        <div class="quick-store">
          <!-- <h2 class="f-16 fw-600 mode-store">Training Mode</h2> -->
          <div class="card-body my-0 py-0 px-0">
            <div
              class="quickStore-loader"
              *ngIf="showQuickStoreLoader === true"
            >
              <img
                src="../../../assets/images/loaders/eatos_loading_0-Black.gif"
                class="quickStore-loader-spinner"
                alt=""
              />
              <p class="mb-0 mt-2">{{ "Please wait..." | translate | async }}</p>
            </div>
            <!-- <div class="font-size-14 fw-700 text-center mb-3">
            Quick Store Settings
          </div> -->
            <div class="f-16 fw-600 mode-store">{{ "Training Mode" | translate | async }}</div>
            <div class="d-flex justify-content-between">
              <div class="store-menu fw-500 f-14">{{ "Auto Accept Order" | translate | async }}</div>
              <div>
                <div class="form-check form-switch mb-3">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="flexSwitchCheckChecked"
                    [checked]="canAutoAcceptOrderDineIn"
                    (change)="
                      enableDisable($event, 'OnlineStoreDineInAutoAccept')
                    "
                  />
                </div>
              </div>
            </div>
            <div class="d-flex justify-content-between">
              <div class="store-menu fw-500 f-14">{{ "Pause Order" | translate | async }}</div>
              <div>
                <div class="form-check form-switch mb-3">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="flexSwitchCheckChecked"
                    [checked]="canPauseOrderDineIn"
                    (change)="
                      enableDisable($event, 'OnlineStoreDineInPauseOrder')
                    "
                  />
                </div>
              </div>
            </div>
            <div class="f-16 fw-600 mode-store">{{ "Online Ordering" | translate | async }}</div>
            <div class="d-flex justify-content-between">
              <div class="store-menu fw-500 f-14">{{ "Auto Accept Order" | translate | async }}</div>
              <div>
                <div class="form-check form-switch mb-3">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="flexSwitchCheckChecked"
                    [checked]="canAutoAcceptOrderOnline"
                    (change)="enableDisable($event, 'OnlineStoreAutoAccept')"
                  />
                </div>
              </div>
            </div>
            <div class="d-flex justify-content-between">
              <div class="store-menu fw-500 f-14">{{ "Pause Order" | translate | async }}</div>
              <div>
                <div class="form-check form-switch mb-3">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="flexSwitchCheckChecked"
                    [checked]="canPauseOrderOnline"
                    (change)="enableDisable($event, 'OnlineStorePauseOrder')"
                  />
                </div>
              </div>
            </div>
            <div class="f-14 fw-600 mode-store mb-2">{{ "Prep Time Orders" | translate | async }}</div>
            <div>
              <div class="order-pre">
                <input
                  type="number"
                  [ngClass]="prepTimeError !== '' ? 'input-error' : ''"
                  (keypress)="($event.charCode >= 48 && $event.charCode < 58)"
                  (keyup)="prepTime($event)"
                  [(ngModel)]="prepTimeValue"
                  class="form-control f-14 pr-4"
                  min="10"
                  max="60"
                  [placeholder]="('Enter Time ' | translate | async) + '(MM)'"
                />
                <p>min</p>
              </div>
              <div class="error mt-1 f-12">{{ prepTimeError | translate | async }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="" *ngIf="TopMenuDiscValue === 'Support'">
        <div class="app-main app-border">
          <button (click)="TopMenuDiscOpen('main')">
            <i class="fa-solid fa-arrow-left"></i>
          </button>
          <p class="f-14 fw-600 mb-0">{{ "Support" | translate | async }}</p>
        </div>
        <div
          class="quick-store"
          style="font-size: 13px !important; text-align: center"
        >
          <span class="d-flex justify-content-between">
            <p class="support-data mb-0">{{ "Support PIN" | translate | async }}</p>
            <i *ngIf="supportLoader" class="fas fa-sync-alt m-1 rotate"></i>
            <i
              *ngIf="!supportLoader"
              class="fas fa-sync-alt m-1 cs-pointer"
              (click)="generateSupportPin()"
            ></i>
          </span>
          <div
            class="support-pin cs-pointer"
            ngxClipboard
            [cbContent]="supportPin"
            ngbTooltip="Copied!"
            [closeDelay]="2000"
            triggers="manual"
            #t="ngbTooltip"
            (click)="t.open()"
          >
            <span
              class="fw-600"
              ngbTooltip="click to copy pin"
              [closeDelay]="2000"
              #t="ngbTooltip"
            >
              <ng-container *ngIf="displayPin.length > 0">
                <span *ngFor="let pin of displayPin; let index = i" class="">
                  {{ pin }}
                </span>
              </ng-container>
              <ng-container *ngIf="displayPin.length == 0">
                <span class=""></span><span class=""></span
                ><span class=""></span><span class=""></span
                ><span class=""></span><span class=""></span>
              </ng-container>
            </span>
          </div>
        </div>
        <div class="btn-group d-block">
          <button
            class="btn btn-outline-dark f-14 fw-700"
            style="width: 100%; padding: 8.5px; border-radius: 6px"
            (click)="chatWithUs()"
          >
            {{ "Live Chat" | translate | async }}
          </button>
          <button
            class="btn btn-outline-dark f-14 fw-700"
            style="
              margin-top: 14px;
              width: 100%;
              padding: 8.5px;
              border-radius: 6px;
            "
            (click)="goToSupportCenter()"
          >
            {{ "Support center" | translate | async }}
          </button>
        </div>
      </div>
    </div>
  </ng-template>
  <!-- <div class="chat-UI"> -->
  <ng-template #Mayamodal let-c="close" let-d="dismiss" class="">
    <app-co-pilot-chat></app-co-pilot-chat>
  </ng-template>
  <!-- </div> -->
</div>
</div>
