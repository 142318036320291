import { Directive, Input, OnInit, ElementRef } from "@angular/core";
import { NgbPopover, NgbPopoverConfig, NgbTooltip } from "@ng-bootstrap/ng-bootstrap";

@Directive({
  selector: '[accessControl]',
  // Required for NgbPopover Tooltip
  providers: [
    NgbPopover, NgbTooltip
  ],
  // exportAs: 'ngbPopover'
})
export class AccessControlDirective implements OnInit {
  @Input("module") module: string;
  @Input("access") access: Number;
  // @Input('isOpen') isOpen: boolean;
  // @Input('mouseEnter') mouseEnter: any;
  constructor(private elementRef: ElementRef, private popover: NgbPopover,
    config: NgbPopoverConfig) {
    // config.triggers = 'hover';
    // config.autoClose = 'outside';
    // config.triggers = 'click';
  }
  userData
  ngOnInit() {
    this.userData = JSON.parse(localStorage.getItem('userData'));
    this.elementRef.nativeElement.disabled = true;
    this.elementRef.nativeElement.ngbPopover = "You are not authorized to perform this operation";
    // this.popover.ngbPopover = "You are not authorized to perform this operation";
    // this.popover.placement = 'bottom';
    // this.popover.triggers = "manual";
    // this.popover.open();
    this.checkAccess();
  }

  checkAccess() {
    if (this.module) {
      const accessControls: any = this.userData ? this.userData.response.role.permissions : []
      // console.log("accessControls", accessControls)
      const module: any = accessControls.find(access => access.permissionName === this.module);
      if (module)
        this.elementRef.nativeElement.disabled = module['permissionValue'] >= this.access ? false : true;
      else
        this.elementRef.nativeElement.disabled = false;

      if (module && module['permissionValue'] >= this.access) {
        // this.elementRef.nativeElement.removeAttribute('title');
        // this.popover.ngbPopover = "";
      } else {
        this.elementRef.nativeElement.title = "You are not authorized to perform this operation";
        // this.popover.ngbPopover = "You are not authorized to perform this operation";
        // if (this.popover.triggers == 'hover') {
        //   this.popover.open();
        // }
        // this.popover.open();
        // this.popover.triggers = 'hover';
        // this.popover.triggers = "mouseenter:mouseleave";
      }
    } else {
      this.elementRef.nativeElement.disabled = false;
      // this.elementRef.nativeElement.removeAttribute('title');
      // this.popover.close();
    }
  }
}
