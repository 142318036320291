import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { CookieService } from "ngx-cookie-service";
import { Subject } from "rxjs";
import { environment } from "src/environments/environment";
import { ApiService } from "../../../services/api";
@Injectable({
  providedIn: "root",
})
export class SupportPinService {
  token = this.cookieService.get("token");
  storeId = this.cookieService.get("storeId");
  merchantId = this.cookieService.get("merchantId");
  isLoadingChangeSub = new Subject<boolean>();

  GLOBALURL = this.ApiService.getBaseURL();

  constructor(
    private http: HttpClient,
    private cookieService: CookieService,
    private ApiService: ApiService
  ) {}



  // create simple item
  getSupportPin() {
    return this.http.post(this.GLOBALURL + "/support/pin", {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
         Authorization: this.cookieService.get("token"),
      }),
    });
  }
}
