import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class loginTryBroadcastService {
    constructor(private cookieService: CookieService) {

    }
    private approvalStageMessage = new BehaviorSubject('');
    currentApprovalStageMessage = this.approvalStageMessage.asObservable();

    updateApprovalMessage(message: string) {
        this.approvalStageMessage.next(message)
    }
}
