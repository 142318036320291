import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  Renderer2,
  TemplateRef
} from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";

import { EventService } from "../../core/services/event.service";

import { DOCUMENT } from "@angular/common";
import { NgbOffcanvas } from "@ng-bootstrap/ng-bootstrap";
import { Store } from "@ngrx/store";
import { Subject, interval } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { AppState } from "src/app/store/app.state";
import { setLoadingSpinner } from "src/app/store/sharedstate/shared.actions";
import { getLoading } from "src/app/store/sharedstate/shared.selector";
import { SIDEBAR_TYPE } from "../layouts.model";

@Component({
  selector: "app-vertical",
  templateUrl: "./vertical.component.html",
  styleUrls: ["./vertical.component.scss"],
})

/**
 * Vertical component
 */
export class VerticalComponent implements OnInit, AfterViewInit,OnDestroy {
  private destroy$ = new Subject<void>();

  // Loader
  loading: any = false;
  // Router Dynamic Content
  // contentLoaded: boolean = false;
  // loadingContent: boolean = false;

  isCondensed = false;
  isMenuResetOnTestMode = false;
  sidebartype: string;
  openSidebarCanva = false;
  checkBoxValue: any =
    localStorage.getItem("isTestModeOn") == "1" ? "true" : "false";
  constructor(
    private router: Router,
    private store: Store<AppState>,
    private eventService: EventService,
    private offcanvasService: NgbOffcanvas,
    private renderer: Renderer2,
    private elRef: ElementRef,
    @Inject(DOCUMENT) private document: Document,
    // private locationService: LocationsService,
    // private cookieService: CookieService,
  ) {
    this.router.events.forEach((event) => {
      if (event instanceof NavigationEnd) {
        if(this.document && this.document.body && this.document.body.classList.contains("sidebar-enable")) {
          this.document.body.classList.remove("sidebar-enable");
        }
      }
    });
    // this.loading = this.store.select(getLoading)
  }
  interval$ = interval(1000);
  ngOnInit() {
    // const token = this.cookieService.get("token");
    // const payload = {
    //     storeId: this.cookieService.get("storeId"),
    //   };
    // this.locationService.setStoreCurrency(payload, token);
    this.SetFooterClass();
    // this.store.dispatch(setLoadingSpinner({ status: true }));
    this.loading = this.store.select(getLoading);

    console.log("this.loading ", this.loading);

    this.checkBoxValue =
      localStorage.getItem("isTestModeOn") == "1" ? "true" : "false";
    // handling test mode switching menu state issues
    if (this.checkBoxValue == "true") {
      this.isMenuResetOnTestMode = true;
    } else {
      this.isMenuResetOnTestMode = false;
    }

    this.sidebartype = SIDEBAR_TYPE;
    // listen to event and change the layout, theme, etc
    this.eventService.subscribe("changeSidebartype", (layout) => {
      this.sidebartype = layout;
      this.changeSidebar(this.sidebartype);
    });

    this.changeSidebar(this.sidebartype);

    document.body.setAttribute("data-layout", "");
    setTimeout(() => {
      this.store.dispatch(setLoadingSpinner({ status: false }));
    }, 1000);
    this.updateRouterLink();
    // setInterval(() => {
    //   this.updateRouterLink();
    // }, 1000);
    // setInterval(() => {this._sidebarResposnive();},0)
    interval(1000)
    .pipe(takeUntil(this.destroy$))
    .subscribe(() => {
      this.updateRouterLink();
    });

    interval(1000)
    .pipe(takeUntil(this.destroy$))
    .subscribe(() => {
      this._sidebarResposnive();
    });
    }
      ngOnDestroy(){
        this.destroy$.next();
        this.destroy$.complete();
      }
  routerLink: string = "";
  private updateRouterLink() {
    // Get the current router link from the Router service
    this.routerLink = this.router.url;
    const arrayLink: any = [
      "home",
      "master",
      "integration-marketplace",
      "hardware",
      "profile",
    ];
    const footerClass = [
      "/sales/gross-transaction/report-home",
      "/guests/guestbook/guest-home",
      "/sales/deposits",

    ];
    const FilterLink = this.routerLink.split("/")[1];

    if (arrayLink.includes(FilterLink)) {
      this.SetFooter = "";
      this.HomeTooltripClass = "Home-Tooltrip-Class";
    } else {
      this.HomeTooltripClass = "";
      if (footerClass.includes(this.routerLink)) {
        this.SetFooter = "Set-footer-mini";
      } else {
        this.SetFooter = "";
      }
    }
  }
  SetFooter: any = "";
  HomeTooltripClass = "";
  ngAfterViewInit(): void {
    // Set the flag to true once the content has finished loading
    // this.contentLoaded = true;
    // this.loadingContent = true;
  }

  isMobile() {
    const ua = navigator.userAgent;
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(
      ua
    );
  }

  /**
   * on settings button clicked from topbar
   */
  onSettingsButtonClicked() {
    document.body?.classList?.toggle("right-bar-enabled");
  }

  changeSidebar(value) {
    switch (value) {
      case "light":
        document.body.setAttribute("data-sidebar", "light");
        document.body.setAttribute("data-topbar", "dark");
        document.body.removeAttribute("data-sidebar-size");
        document.body.removeAttribute("data-layout-size");
        document.body.removeAttribute("data-keep-enlarged");
        document.body.classList.remove("vertical-collpsed");
        document.body.removeAttribute("data-layout-scrollable");
        break;
      case "compact":
        document.body.setAttribute("data-sidebar-size", "small");
        document.body.setAttribute("data-sidebar", "dark");
        document.body.removeAttribute("data-topbar");
        document.body.removeAttribute("data-layout-size");
        document.body.removeAttribute("data-keep-enlarged");
        document.body.classList.remove("sidebar-enable");
        document.body.classList.remove("vertical-collpsed");
        document.body.removeAttribute("data-layout-scrollable");
        break;
      case "dark":
        document.body.setAttribute("data-sidebar", "dark");
        document.body.removeAttribute("data-topbar");
        document.body.removeAttribute("data-layout-size");
        document.body.removeAttribute("data-keep-enlarged");
        document.body.removeAttribute("data-sidebar-size");
        // document.body.classList.remove('sidebar-enable');
        document.body.classList.remove("vertical-collpsed");
        document.body.removeAttribute("data-layout-scrollable");
        break;
      case "icon":
        document.body.classList.add("vertical-collpsed");
        document.body.setAttribute("data-sidebar", "dark");
        document.body.removeAttribute("data-layout-size");
        document.body.setAttribute("data-keep-enlarged", "true");
        document.body.removeAttribute("data-topbar");
        document.body.removeAttribute("data-layout-scrollable");
        break;
      case "colored":
        document.body.classList.remove("sidebar-enable");
        document.body.classList.remove("vertical-collpsed");
        document.body.setAttribute("data-sidebar", "colored");
        document.body.removeAttribute("data-layout-size");
        document.body.removeAttribute("data-keep-enlarged");
        document.body.removeAttribute("data-topbar");
        document.body.removeAttribute("data-layout-scrollable");
        document.body.removeAttribute("data-sidebar-size");
        break;
      default:
        document.body.setAttribute("data-sidebar", "dark");
        break;
    }
  }

  /**
   * On mobile toggle button clicked
   */
  onToggleMobileMenu() {
    this.isCondensed = !this.isCondensed;
    document.body?.classList?.toggle("sidebar-enable");
    document.body?.classList?.toggle("vertical-collpsed");

    // if (window.screen.width <= 768) {
    //   document.body.classList.remove("vertical-collpsed");
    // }
  }
  changeStore(event: any) {}
  receivedData: boolean = false;

  receiveData(data: any) {
    console.log("data", data);

    this.receivedData = data;
  }
  setFootterClass: any;
  SetFooterWidth(Data: any) {
    const submenuDetails = [
      "reports",
      "menumanagement",
      "sales",
      "guests",
      "employees",
      "restaurant",
      "onlinestore",
      "employees-setup",
      "business-settings",
    ];

    if (submenuDetails.includes(Data)) {
      this.setFootterClass = "Fotter-Class";
    } else {
      this.setFootterClass = "";
    }
  }
  SetFooterClass() {
    this.eventService.watchLocalStorageValue().subscribe((newValue) => {
      let subDivPath = JSON.parse(localStorage.getItem("subDivPath"));
      // let activePath = subDivPath.active_path;

      if (subDivPath && subDivPath.active_path && newValue && this.router.url) {
        // Also check if the active path is the same as the current path before changing the width
        if (newValue && (subDivPath.active_path == this.router.url)) {
          this.SetFooterWidth(subDivPath.active_path);
        }
      }
    });
  }

  GetSidebarDetails: any;
  OpenSidebar(data, sidebarCont: TemplateRef<any>) {
    this.GetSidebarDetails = data;
    // this.renderer.removeClass(document.body,'vertical-collpsed')
    // this.renderer.removeClass(document.body,'sidebar-enable')
    // this.renderer.addClass(document.body,'res-sidebar-enable')
    this.openSidebarCanva = true;
    this.offcanvasService.open(sidebarCont, { position: "start" });
  }
  SubmenuContant: string = "closeSubmenu";

  receiveDataForSubmenu(data: string) {
    this.SubmenuContant = data;
  }
  SetSidebar = false
  _sidebarResposnive(){
    const screenWidth = window.innerWidth;
    if (screenWidth > 576) {
      this.SetSidebar = false
      if(this.renderer && document && this.document.body && document.body.classList.contains('res-sidebar-enable')){
        this.renderer.removeClass(document.body,'res-sidebar-enable')
      }

    }else{
      this.SetSidebar = true
    }
  }
}
