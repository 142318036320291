import { Injectable, NgZone } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { CookieService } from "ngx-cookie-service";
import { OneSignal } from "onesignal-ngx";
import { of } from "rxjs";
import { catchError, concatMap, exhaustMap, map, mergeMap, switchMap, tap } from "rxjs/operators";
import { AlertService } from "src/app/core/services/AlertService/alert.service";
import { AuthAlertService } from "src/app/core/services/AlertService/auth-alert.service";
import { AuthCookieService } from "src/app/core/services/authcookies.service";
import { LocationsService } from "src/app/core/services/locations/locations.service";
import { loginTryBroadcastService } from "src/app/core/services/logintry.service";
import { ApiService } from "src/app/services/api";
import { AppState } from "src/app/store/app.state";
import { AuthenticationService } from "../../../core/services/auth.service";
import {
  setErrorMessage
} from "../../../store/sharedstate/shared.actions";
import {
  autoLogin,
  autoLogout,
  crossLinkLoginStart,
  crossLinkLoginSuccess,
  loginFailure,
  loginStart,
  loginSuccess,
  loginWithSSOStart,
  magicLinkLoginStart,
  signupSuccess
} from "./auth.actions";
import { AuthResponseService } from "./auth.response.service";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";

@Injectable()
export class AuthEffects {
  currencies: any;

  constructor(
    private actions$: Actions,
    private authService: AuthenticationService,
    private authCookieService: AuthCookieService,
    // private alertService: AlertService,
    private store: Store<AppState>,
    private router: Router,
    private ngZone: NgZone,
    private route: ActivatedRoute,
    private authAlertService: AuthAlertService,
    private loginTryService: loginTryBroadcastService,
    private cookieService: CookieService,
    private oneSignal: OneSignal,
    private locationService: LocationsService,
    private toastr: AlertService,
    private ApiService: ApiService,
    private AuthResponseService: AuthResponseService,
    private http: HttpClient,

  ) {}

  loginTry: any = 0;
  // storeCurrentCurrencyCode: any = ["USD", "$"];
  storeCurrentCurrency: any = '';
  storeTaxAlias: any = '';
  //creating login effect for getting login api reponse
  // login$ = createEffect(() => {
  //   return this.actions$.pipe(
  //     ofType(loginStart),
  //     exhaustMap((action) => {
  //       return this.authService.login(action.email, action.password).pipe(
  //         map((data) => {
  //           this.store.dispatch(setErrorMessage({ message: "" }));
  //           const user = data;
  //           //checking user having success reponse of 1 (authentication check)
  //           if (data.success == 1) {
  //             console.log("lOGIN_EFFECTS", data.response);
  //             this.cookieService.set("loginTry", this.loginTry);
  //             // set the token in the cookie
  //             //checking user have subscription or not
  //             // this.locationService.setStoreCurrency(payload, token);
  //             if (data.response.subscription._id !== undefined) {
  //               this.oneSignal.sendTag("eatos-users", 1001);
  //               this.authCookieService.addCookies(data);
  //               //set userdata in localstorage
  //               this.authService.setUserInLocalStorage(data);
  //               if (data.response.has2FA == 0) {
  //                 // auto login enabled
  //                 localStorage.setItem("autoLogin", "true");
  //               } else {
  //                 localStorage.setItem("autoLogin", "false");
  //               }

  //               //this.alertService.success(data.message, { autoClose: false })
  //               //set user in state
  //               // commenting below to not ask 2fa at login
  //               if (data.response.ask2faPermission == true) {
  //                 return loginSuccess({
  //                   user,
  //                   redirect: false,
  //                   redirect2fa: true,
  //                 });
  //               } else {
  //                 return loginSuccess({
  //                   user,
  //                   redirect: true,
  //                   redirect2fa: false,
  //                 });
  //               }
  //               //return loginSuccess({ user, redirect: true, redirect2fa: false });
  //             } else {
  //               this.loginTryService.updateApprovalMessage("failed");
  //               this.authAlertService.clear();
  //               this.authAlertService.error(
  //                 "Your account doesn't have a subscription. You need to activate it on the eatOS app"
  //               );
  //               //this.toastService.showToastMsg("Your account doesn't have a subscription. You need to activate it on the eatOS app", "error")
  //               //stop loading if any error occured
  //               return loginFailure({ loader: false });
  //             }
  //           } else {
  //             this.loginTryService.updateApprovalMessage("failed");
  //             this.authAlertService.clear();
  //             this.authAlertService.error(data.message);
  //             //this.toastService.showToastMsg(data.message, "error")
  //             return loginFailure({ loader: false });
  //           }
  //         }),
  //         catchError((errResp) => {
  //           this.loginTryService.updateApprovalMessage("failed");
  //           //console.log(errResp)
  //           //check api call fail due to internet connection loss
  //           this.authAlertService.clear();
  //           this.authAlertService.error(errResp);
  //           //stop loading if any error occured
  //           // this.store.dispatch(setLoadingSpinner({ status: false }));
  //           return of(loginFailure({ loader: false }));
  //         })
  //       );
  //     })
  //   );
  // });
// NEW LOGIN EFFECT FOR SETTING CURRENCY
  login$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loginStart),
      exhaustMap((action) => {
        return this.authService.login(action.email, action.password).pipe(
          concatMap((data) => {
            // return this.setStoreCurrency(data).pipe(
              return (data.response.has2FA == 0 ? this.setStoreCurrency(data) : of(data)).pipe(
            map(() => {
                this.store.dispatch(setErrorMessage({ message: "" }));
                const user = data;
                if (data.success == 1) {
                  console.log("lOGIN_EFFECTS", data.response);
                  this.cookieService.set("loginTry", this.loginTry);
                  if (data.response.subscription._id !== undefined) {
                    console.log("lOGIN_EFFECTS", data.response.subscription._id);
                    this.oneSignal.sendTag("eatos-users", 1001);
                    this.authCookieService.addCookies(data);
                    //set userdata in localstorage
                    this.authService.setUserInLocalStorage(data, data.response.has2FA);
                    if (data.response.has2FA == 0) {
                      // auto login enabled
                      localStorage.setItem("autoLogin", "true");
                    } else {
                      localStorage.setItem("autoLogin", "false");
                    }
                    // Check if the user has employeeLanguage and set it in the local storage
                    if (data.response.employeeLanguage) {
                      const employeeLanguage = data.response.employeeLanguage;
                      localStorage.setItem("targetLanguage", employeeLanguage);
                    } else {
                      localStorage.setItem("targetLanguage", "en");
                    }
                    //this.alertService.success(data.message, { autoClose: false })
                    //set user in state
                    // commenting below to not ask 2fa at login
                    if (data.response.ask2faPermission == true || data.response.has2FA) {
                      return loginSuccess({
                        user,
                        redirect: false,
                        redirect2fa: true,
                      });
                    } else {
                      return loginSuccess({
                        user,
                        redirect: true,
                        redirect2fa: false,
                      });
                    }
                    //return loginSuccess({ user, redirect: true, redirect2fa: false });
                  } else {
                    this.loginTryService.updateApprovalMessage("failed");
                    this.AuthResponseService.setValue(data.response);
                    this.authAlertService.clear();
                    this.authAlertService.error(
                      "Your account doesn't have a subscription. You need to activate it on the eatOS app"
                    );
                    // setTimeout(() => {
                    //   this.router.navigate(['/account/activate-account'], { queryParams: { mailId: action.email, password: action.password } });
                    // }, 2000);
                    //this.toastService.showToastMsg("Your account doesn't have a subscription. You need to activate it on the eatOS app", "error")
                    //stop loading if any error occured
                    return loginFailure({ loader: false });
                  }
                } else {
                  this.loginTryService.updateApprovalMessage("failed");
                  this.authAlertService.clear();
                  this.authAlertService.error(data.message);
                  //this.toastService.showToastMsg(data.message, "error")
                  return loginFailure({ loader: false });
                }
              }),
              // catchError((errResp) => {
              //   this.loginTryService.updateApprovalMessage("failed");
              //   //console.log(errResp)
              //   //check api call fail due to internet connection loss
              //   this.authAlertService.clear();
              //   this.authAlertService.error(errResp);
              //   //stop loading if any error occured
              //   // this.store.dispatch(setLoadingSpinner({ status: false }));
              //   return of(loginFailure({ loader: false }));
              // })
            );
          }),
          catchError((errResp) => {
            this.loginTryService.updateApprovalMessage("failed");
            //console.log(errResp)
            //check api call fail due to internet connection loss
            this.authAlertService.clear();
            this.authAlertService.error(errResp);
            //stop loading if any error occured
            // this.store.dispatch(setLoadingSpinner({ status: false }));
            return of(loginFailure({ loader: false }));
          })
        );
      })
    );
  });

// setStoreCurrency(response: any) {
//   // make a deep copy of the response object
//   const user = JSON.parse(JSON.stringify(response.response));
//   console.log("USER__", user)
//   // Only Call the API if the user.
//   return this.locationService.getStoreFullSetting(user.storeId, user.token).pipe(
//     tap((response: any) => {
//       if (response.success) {
//         response.response.forEach((element2) => {
//           if (element2.settingName == 'Currency') {
//             var currencyCode = element2.settingValue;
//             console.log("test", currencyCode);
//               if (currencyCode !== '') {
//               this.storeCurrentCurrency = currencyCode;
//             } else {
//               this.storeCurrentCurrency = "$";
//             }
//             this.cookieService.set('currency', this.storeCurrentCurrency, 70, '/');
//             localStorage.setItem('currency', this.storeCurrentCurrency);
//           }
//           if (element2.settingName == 'TaxAlias') {
//             var TaxAliasValue = element2.settingValue;
//             console.log("test", TaxAliasValue);

//               if (TaxAliasValue !== '') {
//               this.storeTaxAlias = TaxAliasValue;
//             } else {
//               this.storeTaxAlias = "Tax";
//             }
//             this.cookieService.set('taxAliasValue', this.storeTaxAlias, 70, '/');
//           }

//         });
//       } else {
//         this.toastr.error(response.message);
//         // const message = this.ApiService.errorMessage(error)
//         // this.toastr.error(error);
//       }
//     }),
//     catchError((error) => {
//       const message = this.ApiService.errorMessage(error)
//       this.toastr.error(message);
//       return of(error);
//     })
//   );
// }
setStoreCurrency(response: any) {

  // Make a deep copy of the response object
  const user = JSON.parse(JSON.stringify(response.response));
  this.getCurrancydata(user.token);
  console.log("USER__", user);
  const params2 = new HttpParams().set("storeId", user.storeId);
  const headerToken = {
    "Content-Type": "application/json",
    Authorization: user.token,
  };
  var httpOptions3 = {
    headers: new HttpHeaders(headerToken),
    params: params2,
  };
  // First API call
  return  this.http.get(this.ApiService.prodURL + "/store", httpOptions3)
    .pipe(
    switchMap((Response: any) => {
      // Process the response from the first API if needed
      console.log("Another API Response", Response.response.storeCountry);
      this.getLanguageAndCurrency(Response.response.storeCountry);
      localStorage.setItem(
        "storeZipcode",
        response?.response?.storeZipcode);
      // Only call the second API if the first API call is successful
      return this.locationService.getStoreFullSetting(user.storeId, user.token).pipe(
        tap((response: any) => {
          if (response.success) {
            response.response.forEach((element2) => {
              // if (element2.settingName == 'Currency') {
              //   var currencyCode = element2.settingValue;
              //   console.log("test", currencyCode);
              //   if (currencyCode !== '') {
              //     this.storeCurrentCurrency = currencyCode;
              //   } else {
              //     this.storeCurrentCurrency = "$";
              //   }
              //   this.cookieService.set('currency', this.storeCurrentCurrency, 70, '/');
              //   localStorage.setItem('currency', this.storeCurrentCurrency);
              // }
              // if (element2.settingName == 'TaxAlias') {
              //   var TaxAliasValue = element2.settingValue;
              //   console.log("test", TaxAliasValue);
              //   if (TaxAliasValue !== '') {
              //     this.storeTaxAlias = TaxAliasValue;
              //   } else {
              //     this.storeTaxAlias = "Tax";
              //   }
              //   this.cookieService.set('taxAliasValue', this.storeTaxAlias, 70, '/');
              // }
            });
          } else {
            this.toastr.error(response.message);
          }
        }),
        catchError((error) => {
          const message = this.ApiService.errorMessage(error);
          this.toastr.error(message);
          return of(error);
        })
      );
    }),
    catchError((error) => {
      const message = this.ApiService.errorMessage(error);
      this.toastr.error(message);
      return of(error);
    })
  );
}
  loginWithSSO$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loginWithSSOStart),
      exhaustMap((action) => {
        return this.authService.loginWithSSO(action.payload).pipe(
          map((data: any) => {
            this.store.dispatch(setErrorMessage({ message: "" }));
            const user = data;
            //checking user having success reponse of 1 (authentication check)
            if (data.success == 1) {
              this.cookieService.set("loginTry", this.loginTry);
              //checking user have subscription or not
              if (data.response.subscription._id !== undefined) {
                /*
                  Adding cookies for Auth response
                */
                this.authCookieService.addCookies(data);
                //set userdata in localstorage
                this.authService.setUserInLocalStorage(data);
                  // auto login enabled
                localStorage.setItem("autoLogin", "true");
                //this.toastService.showToastMsg(data.message, "success")
                //set user in state
                return loginSuccess({
                  user,
                  redirect: true,
                  redirect2fa: false,
                });
              } else {
                this.authAlertService.clear();
                this.AuthResponseService.setValue(data.response);
                this.authAlertService.error(
                  "Your account doesn't have a subscription. You need to activate it on the eatOS app"
                );
                //stop loading if any error occured
                return loginFailure({ loader: false });
              }
            } else {
              this.authAlertService.clear();
              this.authAlertService.error(data.message);
              return loginFailure({ loader: false });
            }
          }),
          catchError((errResp) => {
            console.log(errResp);
            if (
              errResp === "Email id is incorrect or does not exist" ||
              errResp === "Please verify your Account"
            ) {
              this.ngZone.run(() => {
                this.router.navigate(["/account/signup"]);
              });
              this.authAlertService.clear();
              this.authAlertService.error(
                "You don't have a eatOS account yet. Please sign-up first."
              );
            }

            //console.log(errResp)
            //check api call fail due to internet connection loss

            this.authAlertService.clear();
            //stop loading if any error occured
            // this.store.dispatch(setLoadingSpinner({ status: false }));
            return of(loginFailure({ loader: false }));
          })
        );
      })
    );
  });
  //creating login effect for getting login api reponse
  magicLinkLogin$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(magicLinkLoginStart),
      exhaustMap((action) => {
        return this.authService.magicLinkLogin(action.token).pipe(
          concatMap((data) => {
            return (data.response.has2FA == 0 ? this.setStoreCurrency(data) : of(data)).pipe(
            map(() => {
            this.store.dispatch(setErrorMessage({ message: "" }));
            const user = data;
            //checking user having success reponse of 1 (authentication check)
            if (data.success == 1) {
              this.cookieService.set("loginTry", this.loginTry);
              //checking user have subscription or not
              if (data.response.subscription._id !== undefined) {
                /*
                  Adding cookies for Auth response
                */
                this.authCookieService.addCookies(data);
                //set userdata in localstorage
                this.authService.setUserInLocalStorage(data);
                    if (data.response.has2FA == 0) {
                      // auto login enabled
                      localStorage.setItem("autoLogin", "true");
                    } else {
                     localStorage.setItem("autoLogin", "false");
                    }
                //this.toastService.showToastMsg(data.message, "success")
                //set user in state
                let returnUrl = action.returnUrl ? action.returnUrl : "/";
                if (returnUrl == "/") {
                  return loginSuccess({
                    user,
                    redirect: true,
                    redirect2fa: false,
                  });
                } else {
                  this.router.routeReuseStrategy.shouldReuseRoute = () => false;
                  this.router.onSameUrlNavigation = "reload";
                  this.router.navigate([returnUrl]);
                  return loginSuccess({ user, redirect: false, redirect2fa: false });
                }
              } else {
                this.authAlertService.clear();
                this.authAlertService.error(
                  "Your account doesn't have a subscription. You need to activate it on the eatOS app"
                );
                //stop loading if any error occured
                this.router.navigate(["/account/login"]);
                return loginFailure({ loader: false });
              }
            } else {
              this.authAlertService.clear();
              this.authAlertService.error("Magic Link Expired");
              this.router.navigate(["/404"]);
              return loginFailure({ loader: false });
            }
          }),
          catchError((errResp) => {
            //console.log(errResp)
            //check api call fail due to internet connection loss
            // if (errResp.message && errResp?.message.includes('Http failure response for /api/user/login: 0 Unknown Error')) {
            //   this.authAlertService.clear();
            //   this.authAlertService.error("Please check the internet connection");
            // }
            if (errResp.includes("Error Code: 400")) {
              this.authAlertService.clear();
              this.authAlertService.error("Magic Link Expired");
              this.router.navigate(["/403"]);
            }
            //stop loading if any error occured
            // this.store.dispatch(setLoadingSpinner({ status: false }));
            return of(loginFailure({ loader: false }));})
        );
          })
        );
      })
    );
  });
  //creating login effect for getting login api reponse
  crossLinkLogin$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(crossLinkLoginStart),
      exhaustMap((action) => {
        return this.authService.crossLinkLogin(action.token).pipe(
          map((data) => {
            this.store.dispatch(setErrorMessage({ message: "" }));
            const user = data;
            //checking user having success reponse of 1 (authentication check)
            if (data.success == 1) {
              this.cookieService.set("loginTry", this.loginTry);
              //checking user have subscription or not
              if (data.response.subscription._id !== undefined) {
                /*
                  Adding cookies for Auth response
                */
                this.authCookieService.addCookies(data);
                //set userdata in localstorage
                this.authService.setUserInLocalStorage(data);
                //this.alertService.success(data.message)
                //set user in state
                this.router.navigate(["/restaurant/general"]);
                return crossLinkLoginSuccess({
                  user,
                  redirect: true,
                  returnUrl: action.returnUrl,
                });
              } else {
                this.authAlertService.clear();
                this.authAlertService.error(
                  "Your account doesn't have a subscription. You need to activate it on the eatOS app"
                );
                //stop loading if any error occured
                this.router.navigate(["/account/login"]);
                return loginFailure({ loader: false });
              }
            } else {
              this.authAlertService.clear();
              this.authAlertService.error("Magic Link Expired");
              this.router.navigate(["/account/login"]);
              //this.toastService.showToastMsg("Magic Link Expired", "error")
              //this.router.navigate(['/403']);
              return loginFailure({ loader: false });
            }
          }),
          catchError((errResp) => {
            this.router.navigate(["/account/login"]);
            this.authAlertService.clear();
            this.authAlertService.error(errResp);
            //console.log(errResp)
            //check api call fail due to internet connection loss
            // if (errResp.message && errResp?.message.includes('Http failure response for /api/user/login: 0 Unknown Error')) {
            //   this.toastService.showToastMsg("Please check the internet connection", "error");
            // }
            if (errResp.includes("Error Code: 400")) {
              this.toastr.error("Magic Link Expired");
              this.router.navigate(["/403"]);
            }
            //stop loading if any error occured
            // this.store.dispatch(setLoadingSpinner({ status: false }));
            return of(loginFailure({ loader: false }));
          })
        );
      })
    );
  });
  loginRedirect$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(...[loginSuccess, signupSuccess]),
        tap((action) => {
          this.store.dispatch(setErrorMessage({ message: "" }));
          console.log("LOGIN_SUCCESS", action)
          if (action.redirect) {
            this.ngZone.run(() => {
              this.router.navigate(["/"]);
            });
          }
          if (action.redirect2fa) {
            this.ngZone.run(() => {
              let authyUpdatedAt = +this.cookieService.get("updatedAuthy2faAt");
              let smsUpdatedAt = +this.cookieService.get("updatedSMS2faAt");
              let has2FA = +this.cookieService.get("has2FA");
              if (authyUpdatedAt > 0 && has2FA == 2) {
                this.router.navigate(["/account/FA-2step-verify"]);
              }
              if (smsUpdatedAt > 0 && has2FA == 1) {
                this.router.navigate(["/account/FA-verify"]);
              }
              if (has2FA == 0) {
                this.router.navigate(["/"]);
              }
              if (this.cookieService.get("superAdminPage") === "3") {
                this.router.navigate(["/overview"]);
              }
            });
          }
        })
      );
    },
    { dispatch: false }
  );
  crossLoginRedirect$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(...[crossLinkLoginSuccess]),
        tap((action) => {
          let url = decodeURI(action.returnUrl);
          this.store.dispatch(setErrorMessage({ message: "" }));
          if (action.redirect) {
            this.ngZone.run(() => {
              this.router.navigate([url]);
            });
          }
        })
      );
    },
    { dispatch: false }
  );

  // signUp$ = createEffect(() => {
  //   return this.actions$.pipe(
  //     ofType(signupStart),
  //     exhaustMap((action) => {
  //       return this.authService.signUp(action.email, action.password).pipe(
  //         map((data) => {
  //           this.store.dispatch(setLoadingSpinner({ status: false }));
  //           const user = this.authService.formatUser(data);
  //           this.authService.setUserInLocalStorage(user);
  //           return signupSuccess({ user, redirect: true });
  //         }),
  //         catchError((errResp) => {
  //           this.store.dispatch(setLoadingSpinner({ status: false }));
  //           const errorMessage = this.authService.getErrorMessage(
  //             errResp.error.error.message
  //           );
  //           return of(setErrorMessage({ message: errorMessage }));
  //         })
  //       );
  //     })
  //   );
  // });

  autoLogin$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(autoLogin),
      mergeMap((action) => {
        const user = this.authService.getUserFromLocalStorage();
        if (user) {
          return of(loginSuccess({ user, redirect: false }));
        } else {
          // let magicLinkToken = this.route.snapshot.queryParamMap.get('token');
          // if (!magicLinkToken) {
          //   this.router.navigate(['account/login'])
          // }
          return of(loginFailure({ loader: false }));
        }
      })
    );
  });

  logout$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(autoLogout),
        map((action) => {
          this.authService.logout();

          this.ngZone.run(() => {
            this.router
              .navigate(["account/login"])
              .then(() => window.location.reload());
          });
        })
      );
    },
    { dispatch: false }
  );
  getLanguageAndCurrency(countryName: string) {
    console.log("GET LANGUAGE AND CURRENCY 2")
    if (countryName) {
      countryName = countryName.trim();
    }
    if (countryName == "USA") {
      countryName = "united states";
    }
    if (countryName) {
      const url = `https://restcountries.com/v3.1/name/${countryName}?fullText=true`;
      this.http.get(url).subscribe(
        (data: any) => {
          if (data && data.length > 0) {
            const countryData = data[0];
            const currency = `${Object.keys(countryData.currencies)[0]}-${(Object.values(countryData.currencies)[0] as any).symbol}`;
            const currencyExists = this.currencies?.find(c => c.currencyName === currency);
            // console.log("Currency", currency, currencyExists);

            // Extracting the currency code
            const currencyCode = currency.split('-')[0];
            // console.log("Language", currencyCode, currencyCode === 'AED' ? 'VAT' : 'Tax');
            console.log("CURRENCY EXISTS", currencyExists);

            // Setting values in cookies and local storage
            this.cookieService.set('currency', (currencyExists?.currencyValue || '$'), 70, '/');
            this.cookieService.set('taxAliasValue', currencyCode === 'AED' ? 'VAT' : 'Tax', 70, '/');
            localStorage.setItem('currency', (currencyExists?.currencyValue || '$'));
          }
        },
        (error) => {
          let message = this.ApiService.errorMessage(error);
        }
      );
    }
  }
  getCurrancydata(token: any) {
    return this.http
      .get(this.ApiService.prodURL + "/currency", {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
          Authorization: token,
        }),
      })
      .subscribe((response: any) => {
        // if (response.success == 1) {
        if (!response.error) {
          this.currencies = response.data;
          console.log("currencies", this.currencies);
        }
      });
  }
}
