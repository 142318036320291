<div class="Ingredients">
  <div class="modal-body p-0">
    <!-- <div class="d-flex">

          <div class="">
            <div class="mayafont">Maya<span class="betadetail">Beta</span></div>
          </div>
          <p></p>
        </div> -->

    <div class="d-flex gap-2 maya-title-RTL-main" style="border-bottom: 1px solid #ccc">
      <div class="">
        <img
          src="../../../assets/images/companies/mayachatai.svg"
          alt=""
          class="main-logo"
        />
      </div>

      <div class="w-100">
        <div class="d-flex justify-content-between align-items-center maya-title-RTL">
          <div class="mayafont d-flex f-32 fw-700">
            {{ "Maya" | translate | async }}<span class="betadetail f-16 fw-600">{{ "Beta" | translate | async }}</span>
          </div>
          <div class="d-flex iconmain">
            <div class="" (click)="fullscreen()">
              <img
                src="../../../assets/images/deshmodal/double-arrow.svg"
                alt=""
                width="18px"
                height="24px"
                class="pointer"
              />
            </div>
            <div (click)="resetForm()">
              <img
                src="../../../assets/images/deshmodal/restart_alt.svg"
                class="pointer"
                alt=""
                width="18px"
                height="24px"
              />
            </div>
            <div>
              <i
                class="fa-sharp fa-regular fa-xmark pointer"
                (click)="CloseMenu()"
              ></i>
              <!-- <img
                    src="assets\images\closeIcon.svg"
                    class=""
                    (click)="d('Cross click')"
                    width="19px"
                    height="19px"
                  /> -->
            </div>
          </div>
        </div>
        <p class="mayadetailpara f-16 fw-500">
          {{ "Fueled by AI, expect occasional surprises and errors." | translate | async }}
        </p>
      </div>
    </div>

    <div class="d-flex maya-full-scroll" style="gap: 20px">
      <div class="full-width">
        <div #scrollMe
          class="maya-scroll-full-all"
          *ngIf="ActiveSelectClass === 'grossSales'"
          >
          <!-- style="height: calc(100vh - 260px); overflow: auto" -->
          <div class="">
            <div class="gross-details">
              <div
                class="d-flex sales-main"
                *ngFor="let data of threadData; let i = index"
              >
                <img
                  *ngIf="data.role === 'assistant'"
                  src="../../../assets/images/companies/mayachatai.svg"
                  alt=""
                  width="24px"
                  height="24px"
                  class="ss-sales"
                  style="padding: 0 !important"
                />
                <!-- <img
                *ngIf="employeeName == '' && data.role === 'user'"
                src="../../../assets/images/topbar-icons/Newtopbar/profile.svg"
                alt=""
                width="24px"
                height="24px"
                class="ss-sales"
                style="padding: 0 !important"
              /> -->
                <div
                  *ngIf="employeeImage == '' && data.role === 'user'"
                  style="
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 49px;
                    height: 44px;
                    padding: 0 !important;
                    text-align: center;
                    font-size: 18px;
                    font-weight: 600;
                  "
                  class="ss-sales"
                >
                  {{ employeeName }}
                </div>
                <img
                  *ngIf="employeeImage != '' && data.role === 'user'"
                  [src]="'https://' + employeeImage"
                  alt=""
                  width="24px"
                  height="24px"
                  class="ss-sales"
                  style="padding: 0 !important"
                />

                <div
                  class="f-16 fw-600 w-100 gross-sales-all"
                  [ngClass]="isDetailsShow ? 'active-gross-sales-all' : ''"
                >
                  <!--
                  [ngClass]="isDetailsShow ? 'active-gross-sales-all' : ''" -->
                  <div [id]="setID(data.content.text)" [innerHtml]="data.content.text | translate | async"></div>
                  <!--  -->
                  <div class="">
                    <div class="full-sale-row">
                      <div
                        *ngIf="
                          (data.content.tags.length > 0 &&
                            data.content.type == 'text') ||
                          data.content.type == 'html'
                        "
                      >
                        <div
                          *ngFor="let tag of data.content.tags"
                          class="pointer "

                          (click)="SetSalesAcive('Daily', tag, data.nextLevel,i);"
                          [ngClass]="
                            ActiveSelectClass1 === 'Daily'
                              ? 'ActiveSalesBox1'
                              : 'all-sales-details'
                          "
                        >
                          <h1 class="f-18 fw-600 all-sales mb-0">
                            {{ tag.heading | translate | async }}
                          </h1>
                          <p
                            class="f-14 fw-500 all-report-des"
                            *ngIf="tag.subHeading != ''"
                          >
                            {{ tag.subHeading | translate | async }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    *ngIf="data.content.type == 'html'"
                    class="all-response-icon d-flex justify-content-between align-items-center"
                  >
                    <div class="d-flex gap-3 align-items-center">
                      <img
                        (click)="
                          copyData(data.content.text); imageshow = !imageshow
                        "
                        *ngIf="!imageshow"
                        src="../../../assets/images/deshmodal/content_copy.svg"
                        alt=""
                        class="pointer"
                      />
                      <img
                        (click)="imageshow = !imageshow"
                        *ngIf="imageshow"
                        src="../../../assets/images/deshmodal/content_copy_fill.svg"
                        alt=""
                        class="pointer"
                      />
                      <img
                        (click)="downloadReport(data.chatId)"
                        src="../../../assets/images/deshmodal/download.svg"
                        alt=""
                        class="pointer"
                      />
                      <!-- <img
                                  src="../../../assets/images/deshmodal/replay.svg"
                                  alt=""
                                /> -->
                      <img
                        (click)="sendCustomerReaction(1, data.chatId)"
                        *ngIf="!thumbshowup"
                        src="../../../assets/images/deshmodal/thumb_up.svg"
                        alt=""
                        class="pointer"
                      />
                      <img
                        (click)="sendCustomerReaction(0, data.chatId)"
                        *ngIf="thumbshowup"
                        src="../../../assets/images/deshmodal/thumb_up_fill.svg"
                        alt=""
                        class="pointer"
                      />
                      <img
                        (click)="sendCustomerReaction(2, data.chatId)"
                        *ngIf="!thumbshowdown"
                        src="../../../assets/images/deshmodal/thumb_down.svg"
                        alt=""
                        class="pointer"
                      />
                      <img
                        (click)="sendCustomerReaction(0, data.chatId)"
                        *ngIf="thumbshowdown"
                        src="../../../assets/images/deshmodal/thumb_down_fill.svg"
                        alt=""
                        class="pointer"
                      />
                    </div>
                  </div>

                  <!-- DATE-PICKER -->
                  <div *ngIf="data.content?.type === 'date-picker'" class="">
                    <div class="show-ui">
                      <div
                        class="d-flex align-items-center mt-2 gap-2 transaction-media flex-wrap"
                      >
                        <div class="transaction-input-media">
                          <input
                            type="date"
                            class="form-control pointer"
                            [(ngModel)]="startDate"
                            (change)="
                              StartDateChange(
                                $event,
                                data.content.tags[0].heading,
                                data.nextLevel
                              )
                            "
                          />
                        </div>
                        <div class="transaction-input-media">
                          <input
                            type="date"
                            class="form-control padd-input-media pointer"
                            [(ngModel)]="enddate"
                            (change)="
                              StartDateChange(
                                $event,
                                data.content.tags[0].heading,
                                data.nextLevel
                              )
                            "
                          />
                        </div>
                        <div
                          class=""
                          style="margin-left: 8px"
                          *ngIf="selectedDate"
                        >
                          <i class="fa-solid fa-check"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="d-flex"
                style="gap: 16px !important"
              >
                <div style="width: 53px !important"></div>
                <div
                  class="gross-details generate-box w-100"
                  *ngIf="
                    checkforfeedback()
                  "
                >
                  <div *ngIf="!feedbackSubmitted" class="w-100 sales-main">
                    <div class="improve-all">
                      <textarea
                        placeholder="What was the issue with the response? How could it be improved?"
                        class="f-16 fw-500 improve-details w-100 improve-width"
                        [(ngModel)]="feedbackInput"
                      ></textarea>
                      <div
                        class="d-flex width-improve"
                        style="gap: 10px"
                        *ngFor="let item of Arrayresponse"
                      >
                        <input
                          type="checkbox"
                          class="form-check-input"
                          id="item"
                          [(ngModel)]="feedbackSelection[item]"
                        />
                        <label
                          for="item"
                          class="f-16 fw-500"
                          style="color: #212121; line-height: 19px"
                          >{{ item | translate | async }}</label
                        >
                      </div>
                      <div class="main-btn">
                        <button class="cancel-btn f-16 fw-600 pointer">
                          {{ "Cancel" | translate | async }}
                        </button>
                        <button
                          class="submit-btn f-16 fw-600 pointer"
                          (click)="sendCustomerFeedback()"
                        >
                          {{ "Submit" | translate | async }}
                        </button>
                      </div>
                    </div>
                  </div>
                  <div *ngIf="feedbackSubmitted" class="d-flex sales-main">
                    <div class="improve-all">
                      <p class="f-16 fw-500 w-100 improve-details">
                        {{ "Your Feedback has been Submitted." | translate | async }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <!-- <div class="message mt-0" *ngIf="Sendmessage || IsShownresult">
                <div class="d-flex justify-content-between">
                  <input type="text" placeholder="Type your message" class="type-msg" [(ngModel)]="userInput"
                    (input)="updateCharacterCount()" />
                  <div class="f-16 fw-400 value-msg">
                    {{ characterCount }}/ 4000
                  </div>
                </div>
                <div class="d-flex justify-content-between align-items-center main-send-box">
                  <div>
                    <img src="../../../assets/images/deshmodal/mic.svg" alt="" />
                  </div>
                  <div class="send-btn f-16 fw-600 pointer" (click)="Search()">
                    Send
                  </div>
                </div>
              </div> -->
            </div>
            <div>
              <div
                class="record-btn"
                *ngIf="isloading || isSecondDetailsShow"
              >
              <div class="loader justify-content-center d-flex">
                <img
                src="assets//images/loaders/Ai_loder.gif"
                class="lodar_gif"
                alt=""
              />
             </div>
                <button
                  (click)="stopResponding()"
                  class="d-flex align-items-center stop-btn pointer"
                >
                  <div>
                    <img
                      src="../../../assets/images/deshmodal/stop_circle.svg"
                      alt=""
                    />
                  </div>
                  <p class="f-16 fw-600 stop-recod mb-0">{{ "Stop Responding" | translate | async }}</p>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="Tab">
        <ul ngbNav #nav="ngbNav" class="nav-tabs d-flex align-items-center justify-content-between">
          <li ngbNavItem>
            <a ngbNavLink>Recent Activity</a>
            <ng-template ngbNavContent>
              <div class="recent-activity">
                <div class="d-flex justify-content-between align-items-center">
                  <h1 class="f-16 fw-500 recent-sales">{{ "Sales Reports" | translate | async }}</h1>
                  <p class="f-14 fw-500 recent-today">{{ "Today" | translate | async }}</p>
                </div>

                <div
                  class="d-flex justify-content-between align-items-center"
                  style="margin: 12px 0"
                >
                  <h1 class="f-16 fw-500 recent-sales">{{ "Transaction Details" | translate | async }}</h1>
                  <p class="f-14 fw-500 recent-today">{{ "Today" | translate | async }}</p>
                </div>
                <div
                  class="d-flex justify-content-between align-items-center"
                  style="margin: 12px 0"
                >
                  <h1 class="f-16 fw-500 recent-sales">{{ "Orders" | translate | async }}</h1>
                  <p class="f-14 fw-500 recent-today">{{ "Yesterday" | translate | async }}</p>
                </div>
                <div class="d-flex justify-content-between align-items-center">
                  <h1 class="f-16 fw-500 recent-sales">{{ "Exception" | translate | async }}</h1>
                  <p class="f-14 fw-500 recent-today">{{ "Yesterday" | translate | async }}</p>
                </div>
              </div>
            </ng-template>
          </li>
          <li ngbNavItem>
            <a ngbNavLink>Plugins</a>
            <ng-template ngbNavContent>
              <div class="recent-plugins">
                <div class="plugins-pragraph" style="margin: 20px 0">
                  <p class="f-12 fw-500 choose-topic">
                    {{ "Choose up to 3 plugins for a conversation. To change plugins after you've started a conversation, select New topic." | translate | async }}
                  </p>
                  <p class="f-12 fw-500 choose-topic">
                    {{ "Data will be shared with the plugins you choose." | translate | async }}
                  </p>
                </div>
                <div class="Instacart-details" style="margin: 20px 0">
                  <div
                    class="d-flex w-100 justify-content-between align-items-center"
                  >
                    <div class="d-flex align-items-center" style="gap: 8px">
                      <img
                        src="../../../assets/images/deshmodal/Instacart.svg"
                        alt=""
                      />
                      <h1 class="f-16 fw-500 instacart-title mb-0">
                        {{ "Instacart" | translate | async }}
                      </h1>
                    </div>
                    <div>
                      <div class="form-switch">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          role="switch"
                        />
                      </div>
                    </div>
                  </div>
                  <p class="f-12 fw-400 switch-line">
                    {{ "Lorem ipsum dolor sit amet consectetur. Sit luctus turpis pellentesque amet at. Mi ultrices." | translate | async }}
                  </p>
                  <div class="d-flex align-items-center">
                    <h1
                      class="f-14 fw-500 w-100 mb-0 text-center switch-tearms"
                    >
                      {{ "Terms of use" | translate | async }}
                    </h1>
                    <h2
                      class="f-14 fw-500 w-100 mb-0 text-center switch-tearms"
                    >
                      {{ "Privacy policy" | translate | async }}
                    </h2>
                  </div>
                </div>

                <div class="Instacart-details" style="margin-top: 20px">
                  <div
                    class="d-flex w-100 justify-content-between align-items-center"
                  >
                    <div class="d-flex align-items-center" style="gap: 8px">
                      <img
                        src="../../../assets/images/deshmodal/kayak.svg"
                        alt=""
                      />
                      <h1 class="f-16 fw-500 instacart-title mb-0">{{ "kayak" | translate | async }}</h1>
                    </div>
                    <div>
                      <div class="form-switch">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          role="switch"
                        />
                      </div>
                    </div>
                  </div>
                  <p class="f-12 fw-400 switch-line">
                    {{ "Lorem ipsum dolor sit amet consectetur. Sit luctus turpis pellentesque amet at. Mi ultrices ante gravida." | translate | async }}
                  </p>
                  <div class="d-flex align-items-center">
                    <h1
                      class="f-14 fw-500 w-100 mb-0 text-center switch-tearms"
                    >
                      {{ "Terms of use" | translate | async }}
                    </h1>
                    <h2
                      class="f-14 fw-500 w-100 mb-0 text-center switch-tearms"
                    >
                      {{ "Privacy policy" | translate | async }}
                    </h2>
                  </div>
                </div>
              </div>
            </ng-template>
          </li>
          <li>
            <img src="../../../assets/images/deshmodal/Uparrow.svg" alt="" style="width: 14px;" />
          </li>
        </ul>
        <div [ngbNavOutlet]="nav"></div>
      </div>
    </div>
  </div>
</div>

<!-- <ng-container> -->
<!-- <div id="reportTemplate">
    <header>
      <img src="../../../assets/images/companies/mayachatai.svg"
      alt="Company Logo"
      style="width: 50px; height: 50px; float: left; margin-right: 10px;">
      <h1 style="font-size: 20px; text-align: right;">Report</h1>
      <hr style="border-bottom: 1px solid black;">
    </header>

    <div id="report-content"></div>

    <footer style="border-top: 1px solid black; padding: 10px;">
      Company Name | Page {{ pageNumber }}
    </footer>
  </div> -->
<!-- </ng-container> -->
