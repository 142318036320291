import { Injectable } from "@angular/core";
import { CookieService } from 'ngx-cookie-service';
import { NEW_MENU } from "src/app/layouts/sidebar/menu";
import { AuthRes } from "../models/auth.models";
import { AuthAlertService } from "./AlertService/auth-alert.service";
@Injectable({ providedIn: 'root' })

export class AuthCookieService {
    constructor(private AuthCookie: CookieService, private authAlertService: AuthAlertService) {


    }
    verifyIfSuperAccessAdmin: Boolean = false
    addCookies(data: any) {
        // localStorage.setItem('menus', JSON.stringify(NEW_MENU))
        let { response } = data
        if (response.subscription._id !== undefined) {
            this.AuthCookie.set('token', response.token, 70, '/');
            this.AuthCookie.set('employeeName', response.fullname, 70, '/')
            this.AuthCookie.set('has2FA', response.has2FA, 70, '/')
            this.AuthCookie.set('updatedSMS2faAt', response.updatedSMS2faAt, 70, '/')
            this.AuthCookie.set('updatedAuthy2faAt', response.updatedAuthy2faAt, 70, '/')
            this.AuthCookie.set('ask2faPermission', response.ask2faPermission, 70, '/')
            this.AuthCookie.set('employeeMobile', response.mobile, 70, '/')
            response.role.permissions.forEach(prms => {
                if (prms.permissionName == "superAdminAccess") {
                    this.verifyIfSuperAccessAdmin = true;
                }
            });
            if (this.verifyIfSuperAccessAdmin == false) {
                if (response.isReseller == '1') {
                    this.AuthCookie.set('isReseller', '3', 70, '/')
                    this.AuthCookie.set('loggedAccount', response.username, 70, '/');
                    this.AuthCookie.set('storeId', response.storeId, 70, '/');
                    this.AuthCookie.set('mainStoreId', response.storeId, 70, '/');
                    this.AuthCookie.set('merchantId', response.merchantId, 70, '/');
                    this.AuthCookie.set('employeeId', response.employeeId, 70, '/');
                    this.AuthCookie.set('roleName', response.role.roleName, 70, '/');
                    this.AuthCookie.set('superAdminPage', '0', 70, '/');
                } else {
                    this.AuthCookie.set('isReseller', '0', 70, '/')
                    this.AuthCookie.set('loggedAccount', response.username, 70, '/');
                    this.AuthCookie.set('storeId', response.storeId, 70, '/');
                    this.AuthCookie.set('mainStoreId', response.storeId, 70, '/');
                    this.AuthCookie.set('merchantId', response.merchantId, 70, '/');
                    this.AuthCookie.set('employeeId', response.employeeId, 70, '/');
                    this.AuthCookie.set('roleName', response.role.roleName, 70, '/');
                    this.AuthCookie.set('superAdminPage', '0', 70, '/');
                    response.role.permissions.forEach(rprms => {
                        this.AuthCookie.set('permissionName' + rprms.permissionName, rprms.permissionValue, 70, '/');
                    });
                }
            }else {
                this.AuthCookie.set('loggedAccount', response.username, 70, '/');
                this.AuthCookie.set('storeId',response.storeId, 70, '/');
                this.AuthCookie.set('mainStoreId', response.storeId, 70, '/');
                this.AuthCookie.set('merchantId', response.merchantId, 70, '/');
                this.AuthCookie.set('employeeId', response.employeeId, 70, '/');
                this.AuthCookie.set('roleName', response.role.roleName, 70, '/');
                this.AuthCookie.set('superAdminPage', '3', 70, '/');
                response.role.permissions.forEach(rprms => {
                    this.AuthCookie.set('permissionName' + rprms.permissionName, rprms.permissionValue, 70, '/');
                });
            }

        }else {
            this.authAlertService.error("Your account doesn't have a subscription. You need to activate it on the eatOS app")
            //this.toastr.error("", "Your account doesn't have a subscription. You need to activate it on the eatOS app");
        }
    }
}
