import { Component, Input, OnInit, Renderer2 } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Alert } from 'src/app/core/models/Alert/alert.model';
import { AlertSettings } from 'src/app/core/models/Alert/alert.setting';
import { AlertService } from 'src/app/core/services/AlertService/alert.service';
import { CookieService } from "ngx-cookie-service";

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent implements OnInit {

  @Input() id = 'default-alert';
  @Input() fade = true;

  alerts: Alert[] = [];
  alertSubscription: Subscription;
  routeSubscription: Subscription;

  constructor(private router: Router, private alertService: AlertService, private renderer: Renderer2, public cookieService:CookieService) { }

  ngOnInit() {
    // use the sidebar function
    // this.getSidebarClass();
    this.alertSubscription = this.alertService.onAlert(this.id)
      .subscribe(alert => {
        if (!alert.message) {
          this.alerts = [];
          return;
        }
        this.alerts = []; // This code is remove previuos alert and keep only one alert
        this.alerts.push(alert);

        if (alert.autoClose) {
          setTimeout(() => this.removeAlert(alert), 5000);
        }

        setTimeout(() => {
          let alertsEl = document.querySelectorAll<HTMLElement>('.alert-section');
          let firstAlert = alertsEl[0];
          // Check if another alert is opened somewhere and hide it,
          if (firstAlert) {
            if (alertsEl.length > 1) {
              firstAlert.style.display = 'none';
            } else { // Make the alert visible if it is the only one
              firstAlert.style.display = 'block';
            }
          }
        }, 1);
      }
    );

    // clear alerts on location change
    // this.routeSubscription = this.router.events.subscribe(event => {
    //   if (event instanceof NavigationStart) {
    //     this.alertService.clear(this.id);
    //   }
    // });
  }

  ngOnDestroy() {
    // unsubscribe to avoid memory leaks
    this.alertSubscription.unsubscribe();
    // this.routeSubscription.unsubscribe();
  }
  // create a function to get the CSS Class for the Sidebar component from the document.body
  getSidebarClass() {
    // Check if the class is applied to the body element
    const body = document.body;
    if (body.classList.contains('vertical-collpsed')) {
      // The class is present, handle accordingly
      console.log('vertical-collpsed class is present on the body element');
    } else {
      // The class is not present
      console.log('vertical-collpsed class is not present on the body element');
    }
  }

  removeAlert(alert: Alert) {
    if (!this.alerts.includes(alert)) return;

    if (this.fade) {
      this.alerts.find(x => x === alert).fade = true;

      setTimeout(() => {
          this.alerts = this.alerts.filter(x => x !== alert);
      }, 250);
    } else {
      this.alerts = this.alerts.filter(x => x !== alert);
    }
  }

  cssClass(alert: Alert) {
    if (!alert) return;

    const classes = ['alert', 'alert-dismissable'];

    const alertTypeClass = {
      [AlertSettings.SUCCESS]: 'alert alert-success',
      [AlertSettings.ERROR]: 'alert alert-danger',
      [AlertSettings.INFO]: 'alert alert-info',
      [AlertSettings.WARNING]: 'alert alert-warning'
    }

    classes.push(alertTypeClass[alert.alertType]);

    if (alert.fade) {
      classes.push('fade');
    }

    return classes.join(' ');
  }

}
