import { shareReplay } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppState } from '../../../../store/app.state';
import { Store } from '@ngrx/store';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root',
})
export class SalesEmployeeService {
  timeoutInterval: any;
  GLOBALURL: String = environment.globalUrlApi
  constructor(
    private http: HttpClient,
    private store: Store<AppState>,
    private cookieService : CookieService
    ) { }

  //sending login credentials to server for validate
  getSalesEmployees(): Observable<any> {
    const headerToken = {'Content-Type': 'application/json', 'Authorization': this.cookieService.get('token')}
    const httpOptions = {headers: new HttpHeaders(headerToken)};
    return this.http.get<any>(
      this.GLOBALURL + '/employee',
      httpOptions
    ).pipe(shareReplay(1));
  }
}
