import { MenuItem } from "./menu.model";

// export const MENU: MenuItem[] = [
//     {
//         id: 1,
//         label: 'DASHBOARD',
//         isTitle: true
//     },
//     {
//         id: 2,
//         label: 'Home',
//         icon: 'bx-home-circle',
//         link: '/home',
//     },
//     {
//         id: 3,
//         label: 'Analytics & Reports',
//         icon: 'bxs-bar-chart-alt-2',
//         subItems: [
//             {
//                 id: 5,
//                 label: "Sales",
//                 parentId: 125,
//                 subItems: [
//                     {
//                         id: 3,
//                         label: "Sales Summary",
//                         parentId: 127,
//                         link: "/analytics-reports/sales/sales-summary",
//                     },
//                     {
//                         id: 3,
//                         label: "Sales Details",
//                         parentId: 127,
//                         link: "/analytics-reports/sales/sales-details",
//                     },
//                     {
//                         id: 3,
//                         label: "Gross Transaction",
//                         parentId: 127,
//                         link: "/analytics-reports/sales/gross-transaction",
//                     },
//                     {
//                         id: 3,
//                         label: "Sales By Item",
//                         parentId: 127,
//                         link: "/analytics-reports/sales/sales-by-item",
//                     },
//                 ],
//             },
//             {
//                 id: 5,
//                 label: "Exceptions",
//                 parentId: 125,
//                 subItems: [
//                     {
//                         id: 3,
//                         label: "Coming Soon...",
//                         parentId: 127,
//                         link: "/analytics-reports/exceptions/coming-soon",
//                     },
//                 ],
//             },
//             {
//                 id: 5,
//                 label: "Deposits",
//                 parentId: 125,
//                 subItems: [
//                     {
//                         id: 3,
//                         label: "Coming Soon...",
//                         parentId: 127,
//                         link: "/analytics-reports/deposits/coming-soon",
//                     },
//                 ],
//             },
//             {
//                 id: 5,
//                 label: "Operations",
//                 parentId: 125,
//                 subItems: [
//                     {
//                         id: 3,
//                         label: "Coming Soon...",
//                         parentId: 127,
//                         link: "/analytics-reports/operations/coming-soon",
//                     },
//                 ],
//             },
//             {
//                 id: 5,
//                 label: "Menu",
//                 parentId: 125,
//                 subItems: [
//                     {
//                         id: 3,
//                         label: "Coming Soon...",
//                         parentId: 127,
//                         link: "/analytics-reports/menu/coming-soon",
//                     },
//                 ],
//             },
//         ]
//     },
//     {
//         id: 6,
//         label: 'Sales',
//         icon: 'bx-file',
//         subItems: [
//             {
//                 id: 13,
//                 label: "Transactions",
//                 link: "/sales/transactions",
//                 parentId: 12,
//             },
//             {
//                 id: 14,
//                 label: "Orders",
//                 link: "/sales/orders",
//                 parentId: 12,
//             },
//             {
//                 id: 15,
//                 label: "Cash Drawers",
//                 link: "sales/cashdrawers",
//                 parentId: 12,
//             },
//             {
//                 id: 15,
//                 label: "Deposits",
//                 link: "#",
//                 parentId: 12,
//             },
//             {
//                 id: 15,
//                 label: "Taxes",
//                 link: "#",
//                 parentId: 12,
//             },
//             {
//                 id: 15,
//                 label: "Service Charge",
//                 link: "/sales/service-charges",
//                 parentId: 12,
//             },
//             {
//                 id: 15,
//                 label: "Discounts",
//                 link: "/sales/discounts",
//                 parentId: 12,
//             },
//         ]
//     },
//     {
//         id: 9,
//         label: 'RESTAURANT SETUP',
//         isTitle: true
//     },

//     {
//         id: 10,
//         label: 'MENUITEMS.RESTAURANT.TEXT',
//         icon: 'bx-store',
//         subItems: [
//             {
//                 id: 11,
//                 label: 'MENUITEMS.RESTAURANT.LIST.GENERAL',
//                 link: '/restaurant/general',
//                 parentId: 10
//             },
//             {
//                 id: 12,
//                 label: 'MENUITEMS.RESTAURANT.LIST.SCHEDULE',
//                 link: '/restaurant/schedule',
//                 parentId: 10
//             },
//             {
//                 id: 13,
//                 label: 'MENUITEMS.RESTAURANT.LIST.REVENUECENTER',
//                 link: '/restaurant/revenuecenter',
//                 parentId: 10
//             },
//             {
//                 id: 14,
//                 label: 'MENUITEMS.RESTAURANT.LIST.SERVICEAREAS',
//                 link: '/restaurant/serviceareas',
//                 parentId: 10
//             },

//         ]
//     },
//     {
//         id: 15,
//         label: 'Online Ordering Setup',
//         icon: 'bx-basket',
//         subItems: [
//             {
//                 id: 13,
//                 label: "General",
//                 link: "/onlinestore",
//                 parentId: 12,
//             },
//             {
//                 id: 14,
//                 label: "Storefront Settings",
//                 link: "/onlineStore/storeFront",
//                 parentId: 12,
//             },
//             {
//                 id: 15,
//                 label: "Delivery",
//                 link: "/onlinestore/delivery",
//                 parentId: 12,
//             },
//             {
//                 id: 15,
//                 label: "Schedule",
//                 link: "/onlineStore/schedule",
//                 parentId: 12,
//             },
//             {
//                 id: 15,
//                 label: "Payment",
//                 link: "/onlineStore/payment",
//                 parentId: 12,
//             },
//         ],
//     },
//     {
//         id: 18,
//         label: 'MENUITEMS.MENUMANAGEMENT.TEXT',
//         icon: 'bx-file',
//         subItems: [
//             {
//                 id: 13,
//                 label: "Menus",
//                 link: "/menumanagement/menus",
//                 parentId: 12,
//             },
//             {
//                 id: 14,
//                 label: "Products",
//                 link: "/cmanagement/products",
//                 parentId: 12,
//             },
//             {
//                 id: 15,
//                 label: "Categories",
//                 link: "/menumanagement/categories",
//                 parentId: 12,
//             },
//             {
//                 id: 15,
//                 label: "Modifiers",
//                 link: "/menumanagement/modifiers",
//                 parentId: 12,
//             },
//             {
//                 id: 15,
//                 label: "Modifier Group",
//                 link: "/menumanagement/modifier-groups",
//                 parentId: 12,
//             },
//             {
//                 id: 15,
//                 label: "Ingredients",
//                 link: "/menumanagement/ingredients",
//                 parentId: 12,
//             },
//             {
//                 id: 15,
//                 label: "Taxes",
//                 link: "/menumanagement/taxes",
//                 parentId: 12,
//             },
//             {
//                 id: 15,
//                 label: "Service Charge",
//                 link: "/menumanagement/service-charge",
//                 parentId: 12,
//             },
//             {
//                 id: 15,
//                 label: "Discounts",
//                 link: "/menumanagement/discounts",
//                 parentId: 12,
//             },
//             {
//                 id: 15,
//                 label: "Courses",
//                 link: "/menumanagement/courses",
//                 parentId: 12,
//             },
//         ],
//     },
//     {
//         id: 30,
//         label: 'Employees',
//         icon: 'bx-user',
//         subItems: [
//             {
//                 id: 13,
//                 label: "Employee List",
//                 link: "/employees/employeelist",
//                 parentId: 12,
//             },
//             {
//                 id: 14,
//                 label: "Job Types",
//                 link: "/employees/jobtypes",
//                 parentId: 12,
//             },
//             {
//                 id: 15,
//                 label: "Roles",
//                 link: "/employees/roles",
//                 parentId: 12,
//             },
//         ],
//     },
//     {
//         id: 2,
//         label: 'MENUITEMS.GUESTS.TEXT',
//         icon: 'bx bx-book',
//         subItems: [
//             {
//                 id: 57,
//                 label: 'MENUITEMS.GUESTS.LIST.GUESTBOOK',
//                 link: '/guests/guestbook',
//                 parentId: 2
//             },
//             {
//                 id: 12,
//                 label: 'MENUITEMS.GUESTS.LIST.LOYALTY',
//                 link: '/guests/guestbook',
//                 parentId: 2
//             },
//             {
//                 id: 13,
//                 label: 'MENUITEMS.GUESTS.LIST.MARKETING',
//                 link: '/guests/guestbook',
//                 parentId: 2
//             },
//             {
//                 id: 14,
//                 label: 'MENUITEMS.GUESTS.LIST.FEEDBACK',
//                 link: '/guests/guestbook',
//                 parentId: 2
//             },
//             {
//                 id: 14,
//                 label: 'MENUITEMS.GUESTS.LIST.REPORTS',
//                 link: '/guests/guestbook',
//                 parentId: 2
//             },
//         ]
//     },
//     {
//         id: 33,
//         label: 'Hardware',
//         icon: 'bx-aperture',
//         subItems: [
//             {
//                 id: 13,
//                 label: "Hardware List",
//                 link: "/hardware/hardware",
//                 parentId: 12,
//             },
//             {
//                 id: 14,
//                 label: "Hardware Management",
//                 link: "/hardware/hardware-management",
//                 parentId: 12,
//             },
//         ],
//     },
//     {
//         id: 36,
//         label: 'Integrations',
//         icon: 'bx-customize',
//         subItems: [
//             {
//                 id: 13,
//                 label: "My Integrations",
//                 link: "integration",
//                 parentId: 12,
//             },
//             {
//                 id: 14,
//                 label: "Integration Marketplace",
//                 link: "integration/all",
//                 parentId: 12,
//             },
//         ],
//     },
//     {
//         id: 40,
//         label: 'Payments',
//         icon: 'bx-credit-card',
//         subItems: [
//             {
//                 id: 41,
//                 label: 'MENUITEMS.CONTACTS.LIST.USERGRID',
//                 link: '/contacts/grid',
//                 parentId: 40
//             },
//             {
//                 id: 42,
//                 label: 'MENUITEMS.CONTACTS.LIST.USERLIST',
//                 link: '/contacts/list',
//                 parentId: 40
//             },
//             {
//                 id: 43,
//                 label: 'MENUITEMS.CONTACTS.LIST.PROFILE',
//                 link: '/contacts/profile',
//                 parentId: 40
//             }
//         ]
//     },

//     {
//         id: 44,
//         label: 'Account Settings',
//         isTitle: true
//     },

//     {
//         id: 45,
//         label: 'Profile Settings',
//         icon: 'bx-user-circle',
//         subItems: [
//             {
//                 id: 46,
//                 label: 'MENUITEMS.BLOG.LIST.BLOGLIST',
//                 link: '/blog/list',
//                 parentId: 45
//             },
//             {
//                 id: 47,
//                 label: 'MENUITEMS.BLOG.LIST.BLOGGRID',
//                 link: '/blog/grid',
//                 parentId: 45
//             },

//         ]
//     },
//     {
//         id: 48,
//         label: 'Business Settings',
//         icon: 'bx-list-ul',
//         subItems: [
//             {
//                 id: 49,
//                 label: 'MENUITEMS.JOBS.LIST.JOBLIST',
//                 link: '/jobs/list',
//                 parentId: 48
//             },
//             {
//                 id: 50,
//                 label: 'MENUITEMS.JOBS.LIST.JOBGRID',
//                 link: '/jobs/grid',
//                 parentId: 48
//             },

//         ]
//     },

//     {
//         id: 51,
//         label: 'SUPPORT',
//         isTitle: true
//     },
//     {
//         id: 52,
//         label: 'Connect with eatOS',
//         icon: ' bx-task',
//         subItems: [
//             {
//                 id: 53,
//                 label: 'MENUITEMS.AUTHENTICATION.LIST.LOGIN',
//                 link: '/account/login',
//                 parentId: 52
//             },
//             {
//                 id: 54,
//                 label: 'MENUITEMS.AUTHENTICATION.LIST.LOGIN2',
//                 link: '/account/login-2',
//                 parentId: 52
//             },

//         ]
//     },
//     {
//         id: 55,
//         label: 'Learn with eatOS',
//         icon: ' bx-task',
//         subItems: [
//             {
//                 id: 56,
//                 label: 'MENUITEMS.UTILITY.LIST.STARTER',
//                 link: '/pages/starter',
//                 parentId: 55
//             },

//         ]
//     },
// ];
export const NEW_MENU: MenuItem[] = [
  {
    id: "1",
    label: "Dashboard",
    isTitle: true,
    home: false,
    // icon: 'bx-home-circle',
    icon: "assets/images/newsidemenu-image/default/dashboard.svg",
    Activeicon: "assets/images/newsidemenu-image/active/dashboard.svg",
    link: "/home",
    color: "bg-home",
    // subItems: [],
    // subItems: [
    //   {
    //     id: '2',
    //     label: 'DASHBOARD',
    //     icon: 'bx-home-circle',
    //     link: '/home',
    //     home: true,
    //     subItems: []
    //   },
    //   {
    //     id: '3',
    //     label: 'Analytics & Reports',
    //     icon: 'bxs-bar-chart-alt-2',
    //     link:'/reports/sales/gross-transaction/report-home',
    //     isExpanded: true,
    //     subItems: [
    //       {
    //         id: '4',
    //         label: "Gross Sales",
    //         parentId: 125,
    //         subItems: [
    //           // {
    //           //     id: '5',
    //           //     label: "Sales Summary",
    //           //     parentId: 127,
    //           //     link: "/analytics-reports/sales/sales-summary",
    //           //     subItems: []
    //           // },
    //           // {
    //           //     id: '6',
    //           //     label: "Sales Details",
    //           //     parentId: 127,
    //           //     link: "/analytics-reports/sales/sales-details",
    //           //     subItems: []
    //           // },
    //           {
    //             id: '5',
    //             label: "Sales Transaction",
    //             parentId: 127,
    //             link: "/reports/sales/gross-transaction",
    //             subItems: []
    //           },
    //           {
    //             id: '6',
    //             label: "Daily Services Transactions",
    //             parentId: 127,
    //             link: "/reports/sales/gross-shift",
    //             subItems: []
    //           },
    //           {
    //             id: '7',
    //             label: "ltemized Sales",
    //             parentId: 127,
    //             link: "/reports/sales/gross-details",
    //             subItems: []
    //           },
    //         ],
    //       },
    //       {
    //         id: '8',
    //         label: "Labor",
    //         parentId: 125,
    //         subItems: [
    //           {
    //             id: '9',
    //             label: "Cashout",
    //             parentId: 127,
    //             link: "reports/labor/cashout"
    //           },
    //           {
    //             id: '10',
    //             label: "Payroll",
    //             parentId: 127,
    //             link: "reports/labor/payroll"
    //           }
    //         ],
    //       },
    //       {
    //         id: '11',
    //         label: "Exceptions",
    //         parentId: 125,
    //         subItems: [
    //           {
    //             id: '12',
    //             label: "Discounts",
    //             parentId: 127,
    //             link: "reports/exception/discount"
    //           },
    //           {
    //             id: '13',
    //             label: "Refunds and voids",
    //             parentId: 127,
    //             link: "reports/exception/refunds-and-voids"
    //           },
    //           {
    //             id: '14',
    //             label: "Cancelled Orders",
    //             parentId: 127,
    //             link: "reports/exception/cancel-order"
    //           }
    //         ],
    //       }
    //     ]
    //   },
    //   {
    //     id: '17',
    //     label: 'Sales',
    //     icon: 'bx-file',
    //     link:'/sales/transactions/sales-home',
    //     isExpanded: true,
    //     subItems: [
    //       {
    //         id: '18',
    //         label: "Transactions",
    //         link: "/sales/transactions",
    //         parentId: 12,
    //         subItems: []
    //       },
    //       {
    //         id: '19',
    //         label: "Orders",
    //         link: "/sales/orders",
    //         parentId: 12,
    //         subItems: []
    //       },
    //       {
    //         id: '20',
    //         label: "Cash Drawers",
    //         link: "sales/cashdrawers",
    //         parentId: 12,
    //         subItems: []
    //       },
    //       {
    //         id: '21',
    //         label: "Deposits",
    //         link: "sales/deposits",
    //         parentId: 12,
    //         subItems: []
    //       },
    //       // {
    //       //     id: '22',
    //       //     label: "Taxes",
    //       //     link: "#",
    //       //     parentId: 12,
    //       //     subItems: []
    //       // },
    //       {
    //         id: '23',
    //         label: "Service Charge",
    //         link: "/sales/service-charges",
    //         parentId: 12,
    //         subItems: []
    //       },
    //       {
    //         id: '24',
    //         label: "Discounts",
    //         link: "/sales/discounts",
    //         parentId: 12,
    //         subItems: []
    //       },
    //       {
    //         id: '25',
    //         label: "Feedback",
    //         link: "/sales/feedback",
    //         parentId: 12,
    //         subItems: []
    //       },
    //     ]
    //   },
    // ]
  },
  {
    id: "10001",
    label: "Analytics & Reports",
    // icon: 'bxs-bar-chart-alt-2',
    icon: "assets/images/newsidemenu-image/default/Analytics.svg",
    Activeicon: "assets/images/newsidemenu-image/active/Analytics.svg",
    link: "/reports/sales/gross-transaction/report-home",
    color: "bg-analytics",

    // subItems: []
  },
  {
    id: "10002",
    label: "Sales",
    // icon: 'bx-file',
    icon: "assets/images/newsidemenu-image/default/sales.svg",
    Activeicon: "assets/images/newsidemenu-image/active/sales.svg",
    link: "/sales/transactions/sales-home",
    // subItems: []
    color: "bg-sales",
  },
  {
    id: "25",
    label: "OPERATIONS",
    isTitle: true,
    subItems: [
      {
        id: "59",
        label: "Workforce",
        // icon: 'bx-user',
        icon: "assets/images/newsidemenu-image/default/workforce.svg",
        Activeicon: "assets/images/newsidemenu-image/active/employee.svg",
        link: "/employees/employeelist/employee-home",
        color: "bg-Workforce",
        isExpanded: true,

        // subItems: [
        //   {
        //     id: '60',
        //     label: "Employees",
        //     link: "/employees/employeelist",
        //     parentId: 12,
        //     subItems: []
        //   },
        //   {
        //     id: '84',
        //     label: "Timesheet",
        //     link: "/employees/timesheet",
        //     parentId: 12,
        //     subItems: []
        //   },
        // ],
      },
      {
        id: "26",
        label: "Menu Management",
        // icon: 'bx-file',
        icon: "assets/images/newsidemenu-image/default/bx-file.svg",
        Activeicon: "assets/images/newsidemenu-image/active/bx-file.svg",
        link: "/menumanagement/menus/menu-home",
        color: "bg-menu",
        isExpanded: true,

        // subItems: [
        //   {
        //     id: '27',
        //     label: "Menus",
        //     link: "/menumanagement/menus",
        //     parentId: 12,
        //     subItems: []
        //   },
        //   {
        //     id: '28',
        //     label: "Products",
        //     link: "/menumanagement/products",
        //     parentId: 12,
        //     subItems: []
        //   },
        //   {
        //     id: '30',
        //     label: "Modifiers",
        //     link: "/menumanagement/modifiers",
        //     parentId: 12,
        //     subItems: []
        //   },
        //   {
        //     id: '53',
        //     label: "Add Ons",
        //     link: "/menumanagement/addons",
        //     parentId: 12,
        //     subItems: []
        //   },
        //   {
        //     id: '31',
        //     label: "Modifier Group",
        //     link: "/menumanagement/modifier-groups",
        //     parentId: 12,
        //     subItems: []
        //   },
        //   {
        //     id: '32',
        //     label: "Ingredients",
        //     link: "/menumanagement/ingredients",
        //     parentId: 12,
        //     subItems: []
        //   },
        //   {
        //     id: '29',
        //     label: "Categories",
        //     link: "/menumanagement/categories",
        //     parentId: 12,
        //     subItems: []
        //   },
        //   {
        //     id: '36',
        //     label: "Courses",
        //     link: "/menumanagement/courses",
        //     parentId: 12,
        //     subItems: []
        //   },
        //   {
        //     id: '34',
        //     label: "Service Charge",
        //     link: "/menumanagement/service-charge",
        //     parentId: 12,
        //     subItems: []
        //   },
        //   {
        //     id: '35',
        //     label: "Discounts",
        //     link: "/menumanagement/discounts",
        //     parentId: 12,
        //     subItems: []
        //   },
        //   {
        //     id: '33',
        //     label: "Taxes",
        //     link: "/menumanagement/taxes",
        //     parentId: 12,
        //     subItems: []
        //   },
        // ],
      },
      {
        id: "26",
        label: "Product Management",
        // icon: 'bx-file',
        icon: "assets/images/newsidemenu-image/default/bx-file.svg",
        Activeicon: "assets/images/newsidemenu-image/active/bx-file.svg",
        link: "/productmanagment",
        color: "bg-menu",
        isExpanded: true,
      },
      {
        id: "41",
        label: "Guests",
        // icon: 'bx bx-book',
        icon: "assets/images/newsidemenu-image/default/guest.svg",
        Activeicon: "assets/images/newsidemenu-image/active/guest.svg",
        link: "/guests/guestbook/guest-home",
        color: "bg-guest",
        isExpanded: true,

        // subItems: [
        //   {
        //     id: '1',
        //     label: 'Guestbook',
        //     link: '/guests/guestbook',
        //     parentId: 2,
        //     subItems: []
        //   },
        //   {
        //     id: '2',
        //     label: 'Loyalty',
        //     parentId: 2,
        //     subItems: [
        //       {
        //         id: '78',
        //         label: 'Coming Soon..',
        //         parentId: 70,
        //       },

        //     ]
        //   },
        //   {
        //     id: '3',
        //     label: 'Marketing',
        //     parentId: 2,
        //     subItems: [
        //       {
        //         id: '78',
        //         label: 'Coming Soon..',
        //         parentId: 70,
        //       },

        //     ]
        //   },
        //   // {
        //   //   id: '4',
        //   //   label: 'Feedback',
        //   //   link: '/guests/feedback',
        //   //   parentId: 41,
        //   // }
        // ]
      },
      {
        id: "42",
        label: "Loyalty",
        // icon: 'bx bx-book',
        icon: "assets/images/guestbook-book.svg",
        Activeicon: "assets/images/feature-page-image/Guest/Guest-Guestbook.svg",
        link: "/loyalty/loyalty-home",
        color: "bg-guest",
        isExpanded: true,
      },
      {
        id: "42",
        label: "Marketing",
        icon: "assets/images/newsidemenu-image/default/guest.svg",
        link: "/marketing",
        Activeicon: "assets/images/newsidemenu-image/active/guest.svg",
          subItems: [
          {
            id: "1",
            label: "Create Campaign",
            link: "/guests/guestbook",
            parentId: 2,
            subItems: [],
          },
          {
            id: "2",
            label: "Schedule/Send Campaign",
            parentId: 2,
            subItems: [],
          },
        ],
      },
      {
        id: "101",
        label: "Master",
        // icon: 'bx-store-alt',
        icon: "assets/images/newsidemenu-image/crown.svg",
        Activeicon: "assets/images/newsidemenu-image/active/dashboard.svg",
        color: "bg-oderr",

        // subItems: [
        //   {
        //     id: '95',
        //     label: "Merchant",
        //     link: "/merchants",
        //     parentId: 12,
        //   },
        //   {
        //     id: '96',
        //     label: "Merchants List",
        //     link: "/merchant-list",
        //     parentId: 12,
        //   },
        //   {
        //     id: '97',
        //     label: "Quick Setting",
        //     link: "/quick-setting",
        //     parentId: 12,
        //   }
        // ],
        link: "/master",
      },
      {
        id: "67",
        label: "Merchants",
        // icon: 'bx-store-alt',
        icon: "assets/images/Modifiers.png",
        Activeicon: "assets/images/newsidemenu-image/active/dashboard.svg",
        color: "bg-home",

        // subItems: [
        //   {
        //     id: '95',
        //     label: "Merchant",
        //     link: "/merchants",
        //     parentId: 12,
        //   },
        //   {
        //     id: '96',
        //     label: "Merchants List",
        //     link: "/merchant-list",
        //     parentId: 12,
        //   },
        //   {
        //     id: '97',
        //     label: "Quick Setting",
        //     link: "/quick-setting",
        //     parentId: 12,
        //   }
        // ],
        link: "/merchants",
      },
      {
        id: "68",
        label: "Manage license",
        // icon: 'bx-key',
        icon: "assets/images/new-menuhome-icon/menu.svg",
        Activeicon: "assets/images/newsidemenu-image/active/dashboard.svg",
        link: "/licenses",
        color: "bg-home",
      },
      {
        id: "102",
        label: "Users",
        // icon: 'bx-key',
        icon: "../../../assets/images/new-menu-icons/support-pin.svg",
        Activeicon: "assets/images/newsidemenu-image/active/dashboard.svg",
        link: "/employee-support-pin",
        color: "bg-home",
      },
      {
        id: "90",
        label: "OLO",
        // icon: 'bx bx-car',
        icon: "assets/images/new-menuhome-icon/menu.svg",
        Activeicon: "assets/images/newsidemenu-image/active/dashboard.svg",
        link: "/olo/olo-statistic/olo-home",
        color: "bg-home",
        // subItems: [
        //   {
        //     id: '91',
        //     label: "Statistics",
        //     link: "/olo/olo-statistic",
        //     parentId: 12,
        //     subItems: []
        //   },
        //   {
        //     id: '92',
        //     label: "Settings",
        //     link: "/olo/olo-setting",
        //     parentId: 12,
        //     subItems: []
        //   },
        //   {
        //     id: '93',
        //     label: "Subsidy",
        //     link: "/olo/olo-subsidie",
        //     parentId: 12,
        //     subItems: []
        //   },
        // ],
      },
    ],
  },
  {
    id: "47",
    label: "SETUP",
    isTitle: true,
    subItems: [
      {
        id: "48",
        label: "Restaurant",
        // icon: 'bx-store',
        icon: "assets/images/newsidemenu-image/default/Restaurant.svg",
        Activeicon: "assets/images/newsidemenu-image/active/Restaurant.svg",
        link: "/restaurant/general/restaurat-home",
        color: "bg-restaurant",
        isExpanded: true,

        // subItems: [
        //   {
        //     id: '49',
        //     label: 'General',
        //     link: '/restaurant/general',
        //     parentId: 10,
        //     subItems: []
        //   },
        //   {
        //     id: '50',
        //     label: 'Schedule',
        //     link: '/restaurant/schedule',
        //     parentId: 10,
        //     subItems: []
        //   },
        //   {
        //     id: '51',
        //     label: 'Revenue Center',
        //     link: '/restaurant/revenuecenter',
        //     parentId: 10,
        //     subItems: []
        //   },
        //   {
        //     id: '52',
        //     label: 'Service Areas',
        //     link: '/restaurant/service-areas',
        //     parentId: 10,
        //     subItems: []
        //   },
        //   // {
        //   //   id: '53',
        //   //   label: 'D2-Service Areas',
        //   //   link: '/restaurant/service-areas',
        //   //   parentId: 10,
        //   //   subItems: []
        //   // },

        // ]
      },
      {
        id: "53",
        label: "Online Ordering",
        // icon: 'bx-basket',
        icon: "assets/images/newsidemenu-image/default/online-order.svg",
        Activeicon: "assets/images/newsidemenu-image/active/online-order.svg",
        link: "/onlinestore/online-store-home",
        color: "bg-oderr",
        isExpanded: true,

        // subItems: [
        //   {
        //     id: '54',
        //     label: "General",
        //     link: "/onlinestore",
        //     parentId: 12,
        //     subItems: []
        //   },
        //   {
        //     id: '55',
        //     label: "Storefront Settings",
        //     link: "/onlinestore/store-front-settings",
        //     parentId: 12,
        //     subItems: []
        //   },
        //   {
        //     id: '56',
        //     label: "Delivery",
        //     link: "/onlinestore/delivery",
        //     parentId: 12,
        //     subItems: []
        //   },
        //   {
        //     id: '57',
        //     label: "Payment",
        //     link: "/onlinestore/payment",
        //     parentId: 12,
        //     subItems: []
        //   },
        //   {
        //     id: '58',
        //     label: "Schedule",
        //     link: "/onlinestore/schedule",
        //     parentId: 12,
        //     subItems: []
        //   },
        //   // new id changes
        //   {
        //     id: '82',
        //     label: "Support",
        //     link: "/onlinestore/support",
        //     parentId: 12,
        //     subItems: []
        //   },
        //   {
        //     id: '83',
        //     label: "Promo Code",
        //     link: "/onlinestore/promo-code",
        //     parentId: 12,
        //     subItems: []
        //   },
        //   {
        //     id: '84',
        //     label: "QR Order at Table",
        //     link: "/onlinestore/qr-code",
        //     parentId: 12,
        //     subItems: []
        //   },
        // ],
      },
      // {
      //   id: "59",
      //   label: "Employee",
      //   // icon: 'bx-user',
      //   icon: "assets/images/newsidemenu-image/default/employee.svg",
      //   Activeicon: "assets/images/newsidemenu-image/active/employee.svg",
      //   link: "/employees/jobtypes/employee-stup-home",
      //   color: "bg-employeeset",
      //   isExpanded: true,

      //   // subItems: [
      //   //   {
      //   //     id: '61',
      //   //     label: "Job Types",
      //   //     link: "/employees/jobtypes",
      //   //     parentId: 12,
      //   //     subItems: []
      //   //   },
      //   //   {
      //   //     id: '62',
      //   //     label: "Roles",
      //   //     link: "/employees/roles",
      //   //     parentId: 12,
      //   //     subItems: []
      //   //   },
      //   //   {
      //   //     id: '85',
      //   //     label: "Department",
      //   //     link: "/employees/department",
      //   //     parentId: 12,
      //   //     subItems: []
      //   //   },
      //   // ],
      // },
      {
        id: "63",
        label: "Hardware",
        // icon: 'bx-aperture',
        icon: "assets/images/newsidemenu-image/default/hardware.svg",
        Activeicon: "assets/images/newsidemenu-image/active/hardware.svg",
        link: "/hardware/hardware",
        color: "bg-hardware",

        // subItems: [
        //   {
        //     id: '64',
        //     label: "Hardware List",
        //     link: "/hardware/hardware",
        //     parentId: 12,
        //     subItems: []
        //   },
        // {
        //   id: '65',
        //   label: "Hardware Management",
        //   link: "/hardware/hardware-management",
        //   parentId: 12,
        //   subItems: []
        // },
        // ],
      },
      {
        id: "66",
        label: "Integrations",
        // icon: 'bx-customize',
        icon: "assets/images/newsidemenu-image/default/integration.svg",
        Activeicon: "assets/images/newsidemenu-image/active/integration.svg",
        // link: "integration/all"
        link: "/integration-marketplace/integration-home",
        color: "bg-intigr",

        // link: "my-integration",
        // subItems: [
        //   {
        //     id: '67',
        //     label: "My Integrations",
        //     link: "my-integration",
        //     parentId: 12,
        //     subItems: []
        //   },
        //   {
        //     id: '68',
        //     label: "Integration Marketplace",
        //     link: "integration-marketplace",
        //     parentId: 12,
        //     subItems: []
        //   },
        // ],
      },
      // {
      //   id: '66',
      //   label: 'Integrations1',
      //   icon: 'bx-customize',
      //   link: "integration/stripe-dashboard",
      // subItems: [
      //   {
      //     id: '67',
      //     label: "My Integrations",
      //     link: "integration",
      //     parentId: 12,
      //     subItems: []
      //   },
      //   {
      //     id: '68',
      //     label: "Integration Marketplace",
      //     link: "integration/all",
      //     parentId: 12,
      //     subItems: []
      //   },
      // ],
      // },
      {
        id: "86",
        label: "Generative AI",
        icon: "assets/images/newsidemenu-image/default/generativeai.svg",
        Activeicon: "assets/images/newsidemenu-image/active/integration.svg",
        link: "/generativeai/generativeai-home",
        color: "bg-generate",
      },
    ],
  },
  // {
  //   // id: '69',
  //   label: 'AI',
  //   isTitle: true,
  //   subItems: [
  //     {
  //       // id: '73',
  //       label: 'Price Comparison Analysis',
  //       icon: 'bxs-bar-chart-alt-2',
  //       link: '/reports/price-comparision',
  //       subItems: [
  //         // {
  //           // id: '74',
  //           // label: 'Locations',
  //           // link: '/business-settings/location',
  //           // parentId: 48,
  //           // subItems: []
  //         // },
  //       ]
  //     },
  //   ]
  // },
  {
    id: "69",
    label: "ACCOUNT",
    isTitle: true,
    subItems: [
      {
        id: "73",
        label: "Restaurant Settings",
        // icon: 'bx bx-home-circle',
        icon: "assets/images/newsidemenu-image/default/restaurant-seatting.svg",
        Activeicon:
          "assets/images/newsidemenu-image/active/restaurant-seatting.svg",
        link: "/business-settings/location/resturant-setting-home",
        color: "bg-setting",
        isExpanded: true,

        // subItems: [
        //   {
        //     id: '74',
        //     label: 'Locations',
        //     link: '/business-settings/location',
        //     parentId: 48,
        //     subItems: []
        //   },
        //   {
        //     id: '75',
        //     label: 'Receipt Builder',
        //     link: '/business-settings/receipt-builder',
        //     parentId: 48,
        //     subItems: []
        //   },

        // ]
      },
      // {
      //   id: '82',
      //   label: 'Subscription',
      //   icon: 'bx bx-credit-card',
      //   subItems: [
      //     {
      //       id: '83',
      //       label: 'Overview',
      //       link: '/business-settings/sub-overview',
      //       parentId: 48,
      //       subItems: []
      //     },
      //     {
      //       id: '84',
      //       label: 'Manage',
      //       link: '/business-settings/sub-manage',
      //       parentId: 48,
      //       subItems: []
      //     },
      //     {
      //       id: '85',
      //       label: 'Billing',
      //       link: '/business-settings/sub-Billing',
      //       parentId: 48,
      //       subItems: []
      //     },

      //   ]
      // },
      {
        id: "83",
        label: "Activity Logs",
        // icon: 'bx bx-history',
        icon: "assets/images/newsidemenu-image/default/activity-log.svg",
        Activeicon: "assets/images/newsidemenu-image/active/activity-log.svg",
        link: "/business-settings/activity-logs",
        color: "bg-log",
      },
      // {
      //   id: '103',
      //   label: 'Device Logs',
      //   icon: 'bx bx-history',
      //   link: "/master/device-logs",
      // }
    ],
  },
  {
    id: "76",
    label: "SUPPORT",
    isTitle: true,
    subItems: [
      {
        id: "77",
        label: "Chat with us",
        icon: "assets/images/newsidemenu-image/default/conectwith-os.svg",
        Activeicon: "assets/images/newsidemenu-image/active/conectwith-os.svg",
        link: "",
        color: "bg-chat",

        // subItems: [
        //   {
        //     id: '78',
        //     label: 'Chat with us',
        //     icon: ' bx bx-support',
        //     parentId: 77,
        //   },

        // ]
      },
      // {
      //   id: '80',
      //   label: 'Learn with eatOS',
      //   icon: ' bx-task',
      //   subItems: [
      //     {
      //       id: '81',
      //       label: 'Coming Soon..',
      //       parentId: 80,
      //     },

      //   ]
      // },
    ],
  },
  // {
  //   id: '82',
  //   label: 'AI',
  //   isTitle: true,
  //   subItems: [
  //     {
  //       id: '83',
  //       label: 'Break-Even Analysis',
  //       icon: ' bx bx-calculator',
  //       link: '/master/break-even-analysis',
  //     },
  //   ]
  // },
  // {
  //   id: '88',
  //   label: 'Al',
  //   isTitle: true,
  //   subItems: [
  //     {
  //       id: '89',
  //       label: 'Menu Mapping',
  //       icon: 'bx bx-file',
  //       link: "Now I'm working on this issue https://eatos.atlassian.net/browse/ED409-387Now I'm working on this issue https://eatos.atlassian.net/browse/ED409-387",
  //     },
  //     {
  //       id: '90',
  //       label: 'Price Comparison',
  //       icon: 'bx bx-file',
  //       link: "/al/price-comparison",
  //     }
  //   ]
  // },
];

// mark changes
export const NEW_MENU_TWO: MenuItem[] = [
  {
    id: "1",
    label: "DASHBOARD",
    isTitle: true,
    isExpanded: true,
    subItems: [
      {
        id: "2",
        label: "Home",
        icon: "bx-home-circle",
        link: "/home",
        subItems: [],
      },
      {
        id: "3",
        label: "Analytics & Reports",
        icon: "bxs-bar-chart-alt-2",
        subItems: [
          {
            id: "4",
            label: "Gross Sales",
            parentId: 125,
            subItems: [
              // {
              //     id: '5',
              //     label: "Sales Summary",
              //     parentId: 127,
              //     link: "/analytics-reports/sales/sales-summary",
              //     subItems: []
              // },
              // {
              //     id: '6',
              //     label: "Sales Details",
              //     parentId: 127,
              //     link: "/analytics-reports/sales/sales-details",
              //     subItems: []
              // },
              {
                id: "5",
                label: "Transaction",
                parentId: 127,
                link: "/reports/sales/gross-transaction",
                subItems: [],
              },
              {
                id: "6",
                label: "Transaction Shift",
                parentId: 127,
                link: "/reports/sales/gross-shift",
                subItems: [],
              },
              {
                id: "7",
                label: "Details",
                parentId: 127,
                link: "/reports/sales/gross-details",
                subItems: [],
              },
            ],
          },
          {
            id: "8",
            label: "Labor",
            parentId: 125,
            subItems: [
              {
                id: "9",
                label: "Cashout",
                parentId: 127,
                link: "reports/labor/cashout",
              },
              {
                id: "10",
                label: "Payroll",
                parentId: 127,
                link: "reports/labor/payroll",
              },
            ],
          },
          {
            id: "11",
            label: "Exceptions",
            parentId: 125,
            subItems: [
              {
                id: "12",
                label: "Discounts",
                parentId: 127,
                link: "reports/exception/discount",
              },
              {
                id: "13",
                label: "Refunds and voids",
                parentId: 127,
                link: "reports/exception/refunds",
              },
              {
                id: "14",
                label: "Cancelled Orders",
                parentId: 127,
                link: "reports/exception/cancel-order",
              },
            ],
          },
        ],
      },
      {
        id: "17",
        label: "Sales",
        icon: "bx-file",
        subItems: [
          {
            id: "18",
            label: "Transactions",
            link: "/sales/transactions",
            parentId: 12,
            subItems: [],
          },
          {
            id: "19",
            label: "Orders",
            link: "/sales/orders",
            parentId: 12,
            subItems: [],
          },
          {
            id: "20",
            label: "Cash Drawers",
            link: "sales/cashdrawers",
            parentId: 12,
            subItems: [],
          },
          {
            id: "21",
            label: "Deposits",
            link: "sales/deposits",
            parentId: 12,
            subItems: [],
          },
          // {
          //     id: '22',
          //     label: "Taxes",
          //     link: "#",
          //     parentId: 12,
          //     subItems: []
          // },
          {
            id: "23",
            label: "Service Charge",
            link: "/sales/service-charges",
            parentId: 12,
            subItems: [],
          },
          {
            id: "24",
            label: "Discounts",
            link: "/sales/discounts",
            parentId: 12,
            subItems: [],
          },
        ],
      },
    ],
  },
  {
    id: "25",
    label: "OPERATIONS",
    isTitle: true,
    subItems: [
      {
        id: "26",
        label: "MENUITEMS.MENUMANAGEMENT.TEXT",
        icon: "bx-file",
        subItems: [
          {
            id: "27",
            label: "Menus",
            link: "/menumanagement/menus",
            parentId: 12,
            subItems: [],
          },
          {
            id: "28",
            label: "Products",
            link: "/menumanagement/products",
            parentId: 12,
            subItems: [],
          },
          {
            id: "29",
            label: "Categories",
            link: "/menumanagement/categories",
            parentId: 12,
            subItems: [],
          },
          {
            id: "30",
            label: "Modifiers",
            link: "/menumanagement/modifiers",
            parentId: 12,
            subItems: [],
          },
          {
            id: "53",
            label: "Add Ons",
            link: "/menumanagement/addons",
            parentId: 12,
            subItems: [],
          },
          {
            id: "31",
            label: "Modifier Group",
            link: "/menumanagement/Groups-Modifiers-Add-Ons",
            parentId: 12,
            subItems: [],
          },
          {
            id: "32",
            label: "Ingredients",
            link: "/menumanagement/ingredients",
            parentId: 12,
            subItems: [],
          },
          {
            id: "33",
            label: "Taxes",
            link: "/menumanagement/taxes",
            parentId: 12,
            subItems: [],
          },
          {
            id: "34",
            label: "Service Charge",
            link: "/menumanagement/service-charge",
            parentId: 12,
            subItems: [],
          },
          {
            id: "35",
            label: "Discounts",
            link: "/menumanagement/discounts",
            parentId: 12,
            subItems: [],
          },
          {
            id: "36",
            label: "Courses",
            link: "/menumanagement/courses",
            parentId: 12,
            subItems: [],
          },
        ],
      },
      {
        id: "41",
        label: "Guests",
        icon: "bx bx-book",
        subItems: [
          {
            id: "1",
            label: "Guestbook",
            link: "/guests/guestbook",
            parentId: 2,
            subItems: [],
          },
          {
            id: "2",
            label: "Loyalty",
            parentId: 2,
            subItems: [
              {
                id: "78",
                label: "Coming Soon..",
                parentId: 70,
              },
            ],
          },
          {
            id: "3",
            label: "Marketing",
            parentId: 2,
            subItems: [
              {
                id: "78",
                label: "Coming Soon..",
                parentId: 70,
              },
            ],
          },
          {
            id: "4",
            label: "Feedback",
            link: "/guests/feedback",
            parentId: 41,
          },
        ],
      },
      {
        id: "59",
        label: "Employee",
        icon: "bx-user",
        subItems: [
          {
            id: "60",
            label: "Employees",
            link: "/employees/employeelist",
            parentId: 12,
            subItems: [],
          },
          {
            id: "84",
            label: "Timesheet",
            link: "/employees/timesheet",
            parentId: 12,
            subItems: [],
          },
        ],
      },
      {
        id: "67",
        label: "Merchants",
        icon: "bx-store-alt",
        link: "/merchants",
      },
      {
        id: "68",
        label: "Manage license",
        icon: "bx-key",
        link: "/licenses",
      },
    ],
  },
  {
    id: "47",
    label: "SETUP",
    isTitle: true,
    subItems: [
      {
        id: "48",
        label: "Restaurant",
        icon: "bx-store",
        subItems: [
          {
            id: "49",
            label: "General",
            link: "/restaurant/general",
            parentId: 10,
            subItems: [],
          },
          {
            id: "50",
            label: "Schedule",
            link: "/restaurant/schedule",
            parentId: 10,
            subItems: [],
          },
          {
            id: "51",
            label: "Revenue Center",
            link: "/restaurant/revenuecenter",
            parentId: 10,
            subItems: [],
          },
          {
            id: "52",
            label: "Service Areas",
            link: "/restaurant/service-areas",
            parentId: 10,
            subItems: [],
          },
          // {
          //   id: '53',
          //   label: 'D2-Service Areas',
          //   link: '/restaurant/service-areas',
          //   parentId: 10,
          //   subItems: []
          // },
        ],
      },
      {
        id: "53",
        label: "Online Ordering",
        icon: "bx-basket",
        subItems: [
          {
            id: "54",
            label: "General",
            link: "/onlinestore",
            parentId: 12,
            subItems: [],
          },
          {
            id: "55",
            label: "Storefront Settings",
            link: "/onlinestore/store-front-settings",
            parentId: 12,
            subItems: [],
          },
          {
            id: "56",
            label: "Delivery",
            link: "/onlinestore/delivery",
            parentId: 12,
            subItems: [],
          },
          {
            id: "57",
            label: "Payment",
            link: "/onlinestore/payment",
            parentId: 12,
            subItems: [],
          },
          {
            id: "58",
            label: "Schedule",
            link: "/onlinestore/schedule",
            parentId: 12,
            subItems: [],
          },
          // new id changes
          {
            id: "82",
            label: "Support",
            link: "/onlinestore/support",
            parentId: 12,
            subItems: [],
          },
          {
            id: "83",
            label: "Promo Code",
            link: "/onlinestore/promo-code",
            parentId: 12,
            subItems: [],
          },
          {
            id: "84",
            label: "QR Order at Table",
            link: "/onlinestore/qr-code",
            parentId: 12,
            subItems: [],
          },
          {
            id: "103",
            label: "Driver",
            link: "/onlinestore/driver",
            parentId: 12,
            subItems: [],
          },
        ],
      },
      {
        id: "59",
        label: "Employee",
        icon: "bx-user",
        subItems: [
          {
            id: "61",
            label: "Job Types",
            link: "/employees/jobtypes",
            parentId: 12,
            subItems: [],
          },
          {
            id: "62",
            label: "Roles",
            link: "/employees/roles",
            parentId: 12,
            subItems: [],
          },
          {
            id: "85",
            label: "Department",
            link: "/employees/department",
            parentId: 12,
            subItems: [],
          },
        ],
      },
      {
        id: "63",
        label: "Hardware",
        icon: "bx-aperture",
        link: "/hardware/hardware",
        // subItems: [
        //   {
        //     id: '64',
        //     label: "Hardware List",
        //     link: "/hardware/hardware",
        //     parentId: 12,
        //     subItems: []
        //   },
        // {
        //   id: '65',
        //   label: "Hardware Management",
        //   link: "/hardware/hardware-management",
        //   parentId: 12,
        //   subItems: []
        // },
        // ],
      },
      {
        id: "66",
        label: "Integrations",
        icon: "bx-customize",
        link: "integration/all",
        // subItems: [
        //   {
        //     id: '67',
        //     label: "My Integrations",
        //     link: "integration",
        //     parentId: 12,
        //     subItems: []
        //   },
        //   {
        //     id: '68',
        //     label: "Integration Marketplace",
        //     link: "integration/all",
        //     parentId: 12,
        //     subItems: []
        //   },
        // ],
      },
    ],
  },
  {
    id: "69",
    label: "ACCOUNT",
    isTitle: true,
    subItems: [
      {
        id: "73",
        label: "Restaurant Settings",
        icon: "bx bx-home-circle",
        subItems: [
          {
            id: "74",
            label: "Locations",
            link: "/business-settings/location",
            parentId: 48,
            subItems: [],
          },
          {
            id: "75",
            label: "Receipt Builder",
            link: "/business-settings/receipt-builder",
            parentId: 48,
            subItems: [],
          },
        ],
      },
      {
        id: "82",
        label: "Subscription",
        icon: "bxs-credit-card",
        subItems: [
          {
            id: "83",
            label: "Overview",
            link: "/business-settings/sub-overview",
            parentId: 48,
            subItems: [],
          },
          {
            id: "84",
            label: "Manage",
            link: "/business-settings/sub-manage",
            parentId: 48,
            subItems: [],
          },
          {
            id: "85",
            label: "Billing",
            link: "/business-settings/sub-Billing",
            parentId: 48,
            subItems: [],
          },
        ],
      },
      {
        id: "83",
        label: "Activity Logs",
        icon: "icon-history",
        link: "/business-settings/activity-logs",
      },
    ],
  },
  {
    id: "76",
    label: "SUPPORT",
    isTitle: true,
    subItems: [
      {
        id: "77",
        label: "Connect with eatOS",
        icon: " bx-task",
        subItems: [
          {
            id: "78",
            label: "Coming Soon..",
            parentId: 77,
          },
        ],
      },
      {
        id: "80",
        label: "Learn with eatOS",
        icon: " bx-task",
        subItems: [
          {
            id: "81",
            label: "Coming Soon..",
            parentId: 80,
          },
        ],
      },
    ],
  },
];
