import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, EventEmitter, Inject, NgZone, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DOCUMENT } from '@angular/common';

// import { AuthenticationService } from '../../../core/services/auth.service';
// import { AuthfakeauthenticationService } from '../../../core/services/authfake.service';

import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';
import { filter, first } from 'rxjs/operators';
import { AuthAlertService } from 'src/app/core/services/AlertService/auth-alert.service';
import { AuthenticationService } from 'src/app/core/services/auth.service';
import { AuthfakeauthenticationService } from 'src/app/core/services/authfake.service';
import { AppState } from 'src/app/store/app.state';
import { environment } from '../../../../environments/environment';
import { loginStart, loginWithSSOStart, magicLinkLoginStart } from '../state/auth.actions';
import { getLoading, getLoginTry } from '../state/auth.selector';
import { CredentialResponse } from 'google-one-tap';
import jwt_decode from 'jwt-decode';
import { loginTryBroadcastService } from 'src/app/core/services/logintry.service';
import { ApiService } from './../../../services/api';
import { url } from 'inspector';
import { Apollo } from "apollo-angular";
import CAROUSEL_QUERY from "../../../apollo/queries/carousel/carousel";
import { Subscription } from "rxjs";
import { setLoadingSpinner } from 'src/app/store/sharedstate/shared.actions';
import { TimezoneService } from 'src/app/services/timezone';

declare var FB: any;
@Component({
  selector: 'release-version',
  templateUrl: './release-version.component.html',
  styleUrls: ['./release-version.component.scss']
})

/**
 * Login component
 */
export class ReleaseVersion implements OnInit {




  // set the currenr year
  year: number = new Date().getFullYear();



  magicLinkToken: any = undefined;
  httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
  GLOBALURL: string;
  private googleAuthClientId = environment.googleAuthClientId;
  facebookPayload = null;
  captchaConfig: any = {
    type: 1,
    length: 5,
    cssClass: 'custom',
    back: {
      stroke: "#2F9688",
      solid: "#f7f7f7"
    },
    font: {
      color: "#000000",
      size: "35px"
    }
  };
  activateCaptchaVerification: Boolean = false;

  private queryCarousel: Subscription;
  data: any = {};
  loading = true;
  errors: any;
  // tslint:disable-next-line: max-line-length
  constructor(

    private router: Router,
    private authenticationService: AuthenticationService,
    private store: Store<AppState>,
    private cookieService: CookieService,
    private http: HttpClient,
    private _ngZone: NgZone,
    private alertService: AuthAlertService,
    private loginTryService: loginTryBroadcastService,
    private ApiService: ApiService,
    private apollo: Apollo,
    private timeZoneService : TimezoneService
  ) {
    this.GLOBALURL = this.ApiService.getBaseURL();

  }


  dummyData;

  ngOnInit(): void {
    var httpOptions3 = {
        headers: new HttpHeaders(),
      };

      // for store location it will load store from prod url
      // store will not fetch from preprod env
      this.http
        .get(this.ApiService.prodURL + "/recent/version", httpOptions3)
        .subscribe((response: any) => {
         if(response.success===1){
          this.dummyData = response.response;
          console.log("DUMMy-DATA-Release-Version", this.dummyData)
         } else {
          this.alertService.clear();
          this.alertService.error(response.message);
         }
        }, (error) => {
          console.log(error)
          let message = this.ApiService.errorMessage(error);
          this.alertService.clear();
          this.alertService.error(message);
        });
  }








}
