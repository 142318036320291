import { DOCUMENT } from "@angular/common";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  TemplateRef,
  ViewChild
} from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import {
  NgbAccordionConfig,
  NgbModal,
  NgbModalConfig,
  NgbOffcanvas
} from "@ng-bootstrap/ng-bootstrap";
import { Store } from "@ngrx/store";
import momenttz from "moment-timezone";
import { CookieService } from "ngx-cookie-service";
import { Subject, interval } from "rxjs";
import { filter, share, shareReplay, take, takeUntil } from "rxjs/operators";
import { autoLogout } from "src/app/account/auth/state/auth.actions";
import { AlertService } from "src/app/core/services/AlertService/alert.service";
import { AuthAlertService } from "src/app/core/services/AlertService/auth-alert.service";
import { AppAliasService } from "src/app/core/services/app-alias.service";
import { AuthenticationService } from "src/app/core/services/auth.service";
import { AuthfakeauthenticationService } from "src/app/core/services/authfake.service";
import { locationAutoSelectBroadcastService } from "src/app/core/services/location-auto-select-broadcast.service";
import { OsAppService } from "src/app/core/services/master/os-app.service";
import { PermissionService } from "src/app/core/services/permission.service";
import { ProfileService } from "src/app/core/services/profile-service/profile.service";
import {
  EmitterService,
  Utilities,
} from "src/app/core/services/server-api.service";
import { SupportPinService } from "src/app/core/services/support-pin/support-pin.service";
import { TopbarService } from "src/app/core/services/topbarService/topbar.service";
import { ApiService } from "src/app/services/api";
import { TimezoneService } from "src/app/services/timezone";
import { ChangePasswordComponent } from "src/app/shared/ui/change-password/change-password.component";
import { ChangePinComponent } from "src/app/shared/ui/change-pin/change-pin.component";
import { AppState } from "src/app/store/app.state";
import { setLoadingSpinner } from "src/app/store/sharedstate/shared.actions";
import { LocationsService } from "../../../app/core/services/locations/locations.service";
import { LanguageService } from "../../core/services/language.service";

@Component({
  selector: "app-topbar",
  templateUrl: "./topbar.component.html",
  styleUrls: ["./topbar.component.scss"],
  providers: [Utilities],
})

/**
 * Topbar component
 */
export class TopbarComponent implements OnInit,OnDestroy {
  private destroy$ = new Subject<void>();
  active = 1;
  element;
  isShown: boolean = false;
  configData;
  @ViewChild("content", { static: true }) private content: ElementRef;
  @ViewChild("SearchbarModel") SearchbarModel;
  @ViewChild("Mayamodal") Mayamodal;
  cookieValue;
  flagvalue;
  countryName;
  valueset;
  GLOBALURL = this.ApiService.getBaseURL();
  supportPin: any = "1234";

  private originalReuseStrategy: any;
  private originalOnSameUrlNavigation: any;

  private SettingName = this.locationService.storeRequirements

  // for traine mode
  isTraineeSetup: Number;
  storeDetails;
  settingDetails;
  TopMenuDiscValue = "main";
  NotificationMenu: any;
  public storeName: string | undefined = this.cookieService.get("storeName");
  public storeCountry: string | undefined =
    this.cookieService.get("storeCountry");
  token: any = this.cookieService.get("token");
  currentosapp: any;
  retailview: boolean;
  private storageListener:boolean

  isSuperAdmin = this.cookieService.get("superAdminPage") === "3" ? true : false;

  headerValue : boolean = false
  siteheaderValue : boolean = false
  profileheaderValue : boolean = false
  supportheaderValue : boolean = false
  searchheaderValue : boolean = false
  MayaheaderValue : boolean = false
  trainmodeheaderValue : boolean = false
  weatherheaderValue : boolean = false
  notifyheaderValue : boolean = false


  constructor(
    @Inject(DOCUMENT) private document: any,
    config: NgbAccordionConfig,
    private router: Router,
    private authService: AuthenticationService,
    private authFackservice: AuthfakeauthenticationService,
    private AppAliasService: AppAliasService,
    public languageService: LanguageService,
    private store: Store<AppState>,
    private cookieService: CookieService,
    private osAppService: OsAppService,
    private utils: Utilities,
    private http: HttpClient,
    private toastrservice: AlertService,
    private topbarService: TopbarService,
    private modalConfig: NgbModalConfig,
    private modalService: NgbModal,
    private locAutoService: locationAutoSelectBroadcastService,
    private authAlertService: AuthAlertService,
    private ApiService: ApiService,
    private supportPinService: SupportPinService,
    private changeDetection: ChangeDetectorRef,
    private locationService: LocationsService,
    private timezoneService: TimezoneService,
    private renderer: Renderer2,
    private offcanvasService: NgbOffcanvas,
    public permissionService: PermissionService,
    private profileService: ProfileService,
  ) {
    config.closeOthers = true;
    this.GLOBALURL = this.ApiService.getBaseURL();
    this.supportPinService.GLOBALURL = this.ApiService.getBaseURL();
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.attributeName === "class") {
          this.handleBodyClassChange();
        }
      });
    });

    observer.observe(document.body, { attributes: true });

    // if(!this.storageListener){
    //   const userData = JSON.parse(localStorage.getItem("userData"));
    //   if (userData) {
    //     const { isOwner } = userData.response
    //     isOwner && window.addEventListener('storage', this.storageEventListener.bind(this));
    //   }
    // }

  }
  bodyClass: any;
  handleBodyClassChange() {
    this.bodyClass = document.body.className;
    if (this.bodyClass === "vertical-collpsed") {
      this.greather = true;
    } else if (this.bodyClass.includes("Focus-none") && this.bodyClass.includes("vertical-collpsed")) {
      this.greather = true;
    } else {
      this.greather = false;
    }
  }

  // set window to access crisp object
  Window: any = window;

  listLang = [
    { text: "English", flag: "assets/images/flags/us.jpg", lang: "en" },
    { text: "Spanish", flag: "assets/images/flags/spain.jpg", lang: "es" },
    { text: "German", flag: "assets/images/flags/germany.jpg", lang: "de" },
    { text: "Italian", flag: "assets/images/flags/italy.jpg", lang: "it" },
    { text: "Russian", flag: "assets/images/flags/russia.jpg", lang: "ru" },
  ];

  public checkBoxValue: boolean =
    localStorage.getItem("isTestModeOn") == "1" ? true : false;

  openMobileMenu: boolean;

  @Output() settingsButtonClicked = new EventEmitter();
  @Output() mobileMenuButtonClicked = new EventEmitter();
  @Output() changed = new EventEmitter();
  params = new HttpParams();
  headerToken = {
    "Content-Type": "application/json",
    Authorization: this.cookieService.get("token"),
  };
  httpOptions: any = {};
  grettings: any = "";
  employeeRole: any = "";
  employeeImage: any = "";
  employeeColor: any = "";
  categoryColorNamee: any = "";
  fullName: any = "";
  cutName: any = "";
  osapps: any;
  selectedLogoPath: string;
  selectedSmallLogoPath: string;
  employeeInfo$: any = {
    employeeFullName: "",
    employeeCutName: "",
    employeeMobile: "",
    employeeEmail: "",
    employeeColor: "",
    employeeImage: "",
    employeePin: "",
    employeeCustomId: "",
    employeeId: "",
  };
  loader$: Boolean = false;
  empId: String = "";
  storeId = this.cookieService.get("storeId");
  merchantId = this.cookieService.get("merchantId");
  dineInAutoAcceptDetails: any;
  dineInPauseOrderDetails: any;
  onlineOrderAutoAcceptDetails: any;
  onlineOrderPauseOrderDetails: any;
  dineInAutoAccept: boolean = false;
  dineInPauseOrder: boolean = false;
  onlineOrderingAutoAccept: boolean = false;
  onlineOrderingPauseOrder: boolean = false;
  payload: any;
  prepTimeOrder: any;
  showQuickStore: Boolean = false;
  showQuickStoreLoader: Boolean = false;
  hasStoreFront: Boolean = false;
  hasStoreCity: Boolean = false;
  canPauseOrderDineIn: Boolean = false;
  canAutoAcceptOrderDineIn: Boolean = false;
  canAutoAcceptOrderOnline: Boolean = false;
  canPauseOrderOnline: Boolean = false;
  prepTimeValue: Number = 0;
  displayPin: any[] = [];
  testMode: any;
  userAppId: any;
  isPinnedList: any[] = [];
  notificationList: any[] = [];
  weatherList: any[] = [];
  storeCity: any = "";
  weatherCount: any = 0;
  notifIndex: Number = 0;
  timezone: any = "";
  notificationCount: any;
  IsShownresult: boolean = false;
  ActiveSelectClass: any = "main";
  ActiveSelectClass1: any;
  onLogoClick() {
    console.log("logo clicked");
    this.http.get(this.GLOBALURL + "/os-app", {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: this.token,
      }),
    }).subscribe((response: any) => {
      // console.log("osApps", response?.response);
      this.osapps = response?.response;
      var user = this.authService.getUserFromLocalStorage()
      this.userAppId = user?.response?.appId;
      console.log("OS APPS", this.osapps);
      if (this.osapps != undefined && this.osapps != null && this.osapps.length > 0) {
        this.currentosapp = this.osapps.find(e => e._id.toString() == this.userAppId)
        this.selectedLogoPath = this.currentosapp?.appLogoImageFile
        this.selectedSmallLogoPath = this.currentosapp?.appSmallLogoImageFile
        this.setDefaultSideMenu();
      }
    });
  }
  onOsApp() {
    // this.http.get(this.GLOBALURL + "/os-app", {
    //   params: {
    //     appId: this.userAppId
    //   },
    //   headers: new HttpHeaders({
    //     "Content-Type": "application/json",
    //     Authorization: this.token,
    //   }),
    // }).subscribe((response: any) => {
    //   console.log("currentosapp", response?.response);
    //    this.currentosapp = response?.response;
    //    this.selectedLogoPath = this.currentosapp?.appLogoImageFile
    //    this.selectedSmallLogoPath = this.currentosapp?.appSmallLogoImageFile
    //    this.setDefaultSideMenu();
    // },
    // (error) => {
    //   console.log("Error:", error);
    // }
    // );
    // this.currentosapp = this.osapps.find(app => app._id.toString() === this.userAppId)
    // this.selectedLogoPath = this.currentosapp?.appLogoImageFile
    // this.selectedSmallLogoPath = this.currentosapp?.appSmallLogoImageFile
    // this.setDefaultSideMenu();
  }
  setDefaultSideMenu() {
    // console.log("currentosapp", this.currentosapp.appName);
    if (this.currentosapp && this.currentosapp?.appName) {
      this.onSelectOfLogo(this.currentosapp.appName, "");
    }
  }
  interval$ = interval(1000);
  ngOnInit() {

    this.TodayDateFormate();
    // setInterval(() => {
    //   this.TodayDateFormate();
    // }, 60000);
    this.onLogoClick();
    this.AppAliasService.getValue().subscribe((res) => {
      let Name = res.type;
      Name == "eatos" ? (this.retailview = false) : (this.retailview = true);
    }
    );
    var user = this.authService.getUserFromLocalStorage()
    this.userAppId = user?.response?.appId;
    // this.onOsApp();
    this.testMode = localStorage.getItem("isTestModeOn") == "1" ? true : false;
    if (localStorage.getItem("crisp_chat_opened") == "true") {
      this.Window.$crisp.push(["do", "chat:show"]);
    }
    this.generateSupportPin();
    this.modalConfig.backdrop = "static";
    this.modalConfig.keyboard = false;
    if (this.storeId !== "*") {
      this.showQuickStore = true;
    }

      this.getStoreInformation()
        .then((response) => {
          // Handle successfull response
        })
        .catch((error) => {
          // Handle error
          console.error("Error getting store information:", error);
        });
    //this.getQuickStoreSettings();
    this.getNotifications();

    if (
      this.cookieService.check("token") == true &&
      this.cookieService.get("superAdminPage") == "0"
    ) {
      //this.loadAll();
      this.empId = this.cookieService.get("employeeId");
      this.params = this.params.set(
        "employeeId",
        this.cookieService.get("employeeId")
      );
      this.httpOptions = {
        headers: new HttpHeaders(this.headerToken),
        params: this.params,
      };
      this.getEmployeeInformation();
    } else if (
      this.cookieService.check("token") == true &&
      this.cookieService.get("superAdminPage") == "3"
    ) {
      this.params = this.params.set(
        "employeeId",
        this.cookieService.get("employeeId")
      );
      this.httpOptions = {
        headers: new HttpHeaders(this.headerToken),
        params: this.params,
      };
      this.getEmployeeInformation();
    } else {
      this.store.dispatch(autoLogout());
      this.router.navigate(["/account/login"]);
    }
    this.openMobileMenu = false;
    this.element = document.documentElement;

    this.cookieValue = this.cookieService.get("lang");
    const val = this.listLang.filter((x) => x.lang === this.cookieValue);
    this.countryName = val.map((element) => element.text);
    if (val.length === 0) {
      if (this.flagvalue === undefined) {
        this.valueset = "assets/images/flags/us.jpg";
      }
    } else {
      this.flagvalue = val.map((element) => element.flag);
    }
    // this.observeBodyClassChanges();
    // setInterval(() => {
    //   // this.RemoveCanvaClass()
    //   this.initializeLayout();
    // }, 1000);
    interval(1000)
    .pipe(takeUntil(this.destroy$))
    .subscribe(() => {
      this.initializeLayout();
    });

    this.profileService.currentProfileImage.subscribe(imageUrl => {
      console.log("profileImage", imageUrl);
      if (imageUrl) {
        if (imageUrl.lastIndexOf("?") >= 0) {
          imageUrl = imageUrl.substring(
            0,
            imageUrl.lastIndexOf("?")
          );
        }

        const url = imageUrl.includes("https://")
          ? imageUrl
          : `https://${imageUrl}`;
        console.log("URL", url);

        this.employeeImage = imageUrl;
      } else {
        this.employeeImage = "";
      }
    });
  }

  onSettingsButtonClicked() {
    // console.log("ss")
    // document.body.classList.toggle('right-bar-enabled');
    // // this.LoadChatBotService.triggerFunction();
    // this.router.navigate(["ai/ai-reports"]);

    this.modalService.dismissAll();
    this.modalService.open(this.Mayamodal, {
      size: "xl Copilot",
      centered: true,
    });
    const ModelEmelemt = this.document.querySelectorAll(".modal-backdrop");
    ModelEmelemt.forEach((element: any) => {
      this.renderer.setStyle(element, "opacity", "0");
    });
    setTimeout(() => {
      const name = localStorage.getItem("rtl");

      if (name === 'right') {
        const ModelEmelemt1 = this.document.querySelectorAll(".Copilot");
        ModelEmelemt1.forEach((element: any) => {
          this.renderer.setStyle(element, "margin-left", "20px");
          // this.renderer.setStyle(element, "margin-right", "0px");
          this.renderer.addClass(element, "new-right-bar-enabled");
        });
      }
    }, 0);
    this.offcanvasService.dismiss("cross click");
  }

  // formateDate: any;

  // timezoneName1: any;
  // TodayDateFormate() {
  //   const formattedTime = this.timezoneService
  //     .get()()
  //     .format("dddd, D MMMM, YYYY, h:mm A z");

  //   this.timezoneName1 = formattedTime.split(" ").at(-1);
  //   const dateArray = formattedTime.split(" ");
  //   dateArray.pop();  // Remove the last element from the array
  //   const dateString = dateArray.join(' ');  // Convert the modified array into a string
  //   this.formateDate = dateString;
  // }
  AcitvTab(result) {
    this.active = result;
  }
  ngAfterViewInit(): void {
    this.getBodyClass();
  }
  getBodyClass(): void {
    const bodyClasses = document.body.classList;
    console.log("bodyClasses-------------->", bodyClasses);

    if (bodyClasses[0] === "vertical-collpsed") {
      this.greather = true;
    }
  }

  toggleTestMode(value: boolean) {
    //checking store is active or not
    // this.checkBoxValue = !this.checkBoxValue;
    this.checkBoxValue = value;
    let locations = JSON.parse(localStorage.getItem("locations"));
    let location = locations.find(
      (x) => x.id === this.cookieService.get("storeId")
    );
    if (location.isActive === 2) {
      this.toastrservice.error("Please activate your account first.");
      this.checkBoxValue = false;
      return;
    }

    this.ApiService.enableMode(this.checkBoxValue == true ? 1 : 0);

    // save current route first
    const currentRoute = this.router.url;

    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = "reload";
    this.router.navigate([currentRoute]);
  }

  redirect(value) {
    this.offcanvasService.dismiss("cross click");
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = "reload";
    this.router.navigate([value]);
  }

  addClass(event): void {
    //event.target.className += 'shadow-lg';
  }

  removeClass(event): void {
    //event.target.className = event.target.className.replace('shadow-lg', '');
  }

  setLanguage(text: string, lang: string, flag: string) {
    this.countryName = text;
    this.flagvalue = flag;
    this.cookieValue = lang;
    this.languageService.setLanguage(lang);
  }

  /**
   * Toggles the right sidebar
   */
  toggleRightSidebar() {
    this.settingsButtonClicked.emit();
  }

  /**
   * Toggle the menu bar when having mobile screen
   */
  greather: boolean = false;
  toggleMobileMenu(event: any) {
    if(this.renderer && this.document && this.document.body && this.document.body.classList.contains('ng-tns-0-0')) {
      this.renderer.removeClass(this.document.body, "ng-tns-0-0");
    }
    if(this.renderer && this.document && this.document.body && this.document.body.classList.contains('modal-open')) {
      this.renderer.removeClass(this.document.body, "modal-open");
    }

    if (this.greather === false) {
      if(this.renderer && this.document && this.document.body && this.document.body.classList.contains('vertical-collpsed')) {
        this.renderer.removeClass(this.document.body, "vertical-collpsed");
      }
      if(this.renderer && this.document && this.document.body) {
        this.renderer.addClass(this.document.body, "sidebar-enable");
      }

    } else {
      if(this.renderer && this.document && this.document.body) {
        this.renderer.addClass(this.document.body, "vertical-collpsed");
      }
      if(this.renderer && this.document && this.document.body && this.document.body.classList.contains('sidebar-enable')) {
        this.renderer.removeClass(this.document.body, "sidebar-enable");
      }

    }
    this.greather = !this.greather;
    event.preventDefault();
    this.mobileMenuButtonClicked.emit();

    const elements = this.document.querySelectorAll(
      ".dataTables_scrollHeadInner"
    );
    console.log("elementselements", elements);

    elements.forEach((element: any) => {
      this.renderer.setStyle(element, "width", "100%");
    });
    const elements1 = this.document.querySelectorAll(".table-make-responsive");
    elements1.forEach((element: any) => {
      this.renderer.setStyle(element, "width", "100%");
    });
    const elements2 = this.document.querySelectorAll(".make-table-resp");
    elements2.forEach((element: any) => {
      this.renderer.setStyle(element, "width", "100%");
    });

    let bodyClass = this.document.body;
    if (bodyClass.classList[0] == "vertical-collpsed") {
      const elements3 = this.document.querySelectorAll(".emp-resize-name");
      elements3.forEach((element: any) => {
        this.renderer.removeStyle(element, "width");
      });
    }

    // setTimeout(() => {
    //   this.SetSidebarCloseOpen();
    // }, 3000);
  }
  SetSidebarCloseOpen(){
    this.checkScreenWidth(window.innerWidth);
  }
  checkScreenWidth(width: number) {
    const bodyClasses = document.body.classList;
   console.log("width",width,bodyClasses);
   if(this.greather){
    if(this.renderer && this.document && this.document.body && this.document.body.classList.contains('vertical-collpsed')) {
      this.renderer.removeClass(this.document.body, "vertical-collpsed");
    }
    if(this.renderer && this.document && this.document.body) {
      this.renderer.addClass(this.document.body, "sidebar-enable");
    }

   }else{
    if(this.renderer && this.document && this.document.body && this.document.body.classList.contains('sidebar-enable')) {
      this.renderer.removeClass(this.document.body, "sidebar-enable");
    }
     if(this.renderer && this.document && this.document.body) {
      this.renderer.addClass(this.document.body, "vertical-collpsed");
     }

   }
  }
  conFirmLogout() {
    this.modalService.open(this.content, { centered: true });
    this.RemoveUnwantedClass();
    this.offcanvasService.dismiss("cross click");
  }
  cancelLogout() {
    this.modalService.dismissAll(this.content);
  }

  showChangePasswordModal() {
    const modalRef = this.modalService.open(ChangePasswordComponent, {
      scrollable: true,
      // windowClass: "myCustomModalClass",
      size: "md",
      keyboard: false,
      backdrop: "static",
      centered: true,
    });
    this.RemoveUnwantedClass();
    // modalRef.componentInstance.fromParent = data;
    // console.log("dinein",data)
    // modalRef.componentInstance.action = action;
    // modalRef.componentInstance.serviceType = "Dine-In";
    // modalRef.result
    //   .then((result) => {
    //     console.log(result);
    //     if (result) {
    //       this.extractData(result);
    //     }
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   });
    this.offcanvasService.dismiss("cross click");
  }
  Showtext(i) {
    this.isShown = !this.isShown;
    this.weatherList[i].isShown = !this.weatherList[i].isShown;
    if (this.weatherList[i].isShown == true) {
      for (let j = 0; j < this.weatherList.length; j++) {
        if (i == j) {
          continue;
        } else {
          this.weatherList[j].isShown = false;
        }
      }
    }
  }
  showChangePINModal() {
    this.modalService.open(ChangePinComponent, {
      scrollable: true,
      size: "md",
      keyboard: false,
      backdrop: "static",
      centered: true,
    });
    this.RemoveUnwantedClass();
    this.offcanvasService.dismiss("cross click");
  }

  /**
   * Logout the user
   */
  logout() {
    this.Window = window;
    // for test mode session is not created so use /api for logging out
    this.http.post("/api/user/logout", {}).subscribe(
      (response: any) => {
        if (response.success == 0) {
          this.authAlertService.clear();
          this.authAlertService.error(response.message);
        } else {
          // this.Window.$crisp.push(["do", "chat:hide"]);
          // Fix the Logout issue
          if (this.Window && this.Window.$crisp) {
            this.Window.$crisp.push(["do", "chat:hide"]);
          }
          this.store.dispatch(autoLogout());
          this.utils.cleanData();
        }
        this.modalService.dismissAll(this.content);
      },
      () => { }
    );
  }

  /**
   * Fullscreen method
   */
  fullscreen() {
    document.body?.classList?.toggle("fullscreen-enable");
    if (
      !document.fullscreenElement &&
      !this.element.mozFullScreenElement &&
      !this.element.webkitFullscreenElement
    ) {
      if (this.element.requestFullscreen) {
        this.element.requestFullscreen();
      } else if (this.element.mozRequestFullScreen) {
        /* Firefox */
        this.element.mozRequestFullScreen();
      } else if (this.element.webkitRequestFullscreen) {
        /* Chrome, Safari and Opera */
        this.element.webkitRequestFullscreen();
      } else if (this.element.msRequestFullscreen) {
        /* IE/Edge */
        this.element.msRequestFullscreen();
      }
    } else {
      if (this.document.exitFullscreen) {
        this.document.exitFullscreen();
      } else if (this.document.mozCancelFullScreen) {
        /* Firefox */
        this.document.mozCancelFullScreen();
      } else if (this.document.webkitExitFullscreen) {
        /* Chrome, Safari and Opera */
        this.document.webkitExitFullscreen();
      } else if (this.document.msExitFullscreen) {
        /* IE/Edge */
        this.document.msExitFullscreen();
      }
    }
  }
  getEmployeeInformation() {
    this.loader$ = true;
    return this.http
      .get(this.GLOBALURL + "/employee", this.httpOptions)
      .pipe(shareReplay(1))
      .subscribe(
        (response: any) => {
          this.loader$ = false;
          if (response.response != undefined && response.response != null) {
            // console.log("TOPBAR_EMPLOYEE_INFORMATION", response);
            if (response.success == 0) {
              this.grettings =
                response.response?.employeeFirstName +
                " " +
                response.response?.employeeLastName;
            } else {
              this.grettings =
                response.response?.employeeFirstName +
                " " +
                response.response?.employeeLastName;
              this.cookieService.set(
                "employeeFullName",
                this.grettings,
                70,
                "/"
              );
              let cutNameFirst = response.response?.employeeFirstName
                ? response.response?.employeeFirstName.charAt(0)
                : "";
              let cutNameSecond = response.response?.employeeLastName
                ? response.response?.employeeLastName.charAt(0)
                : "";
              let cutName = cutNameFirst + cutNameSecond;
              this.cookieService.set("employeeCutName", cutName, 70, "/");
              this.cookieService.set(
                "employeeMobile",
                response.response?.employeeMobile,
                70,
                "/"
              );
              this.cookieService.set(
                "employeeEmail",
                response.response?.employeeEmail,
                70,
                "/"
              );
              const employeeColor =
                response?.response?.employeeColor || "#212121";
              this.cookieService.set("employeeColor", employeeColor, 70, "/");
              // this.cookieService.set(
              //   "employeeColor",
              //   response?.response && response?.response?.employeeColor
              //     ? response.response?.employeeColor
              //     : "#212121",
              //   70,
              //   "/"
              // );
              this.cookieService.set(
                "employeeImage",
                response.response?.employeeImage,
                70,
                "/"
              );
              this.cookieService.set(
                "employeePin",
                response.response?.employeePin,
                70,
                "/"
              );
              this.cookieService.set(
                "employeeCustomId",
                response.response?.employeeCustomId,
                70,
                "/"
              );
              this.cookieService.set(
                "employeeId",
                response.response?.employeeId,
                70,
                "/"
              );
              this.setUserProfile();
              this.loader$ = false;
            }
          }
        },
        (error) => {
          this.loader$ = false;
          console.error("Error retrieving employee information:", error);
        }
      );
  }
  async setUserProfile() {
    let employeeEmail = this.cookieService.get("employeeEmail");
    this.employeeRole = this.cookieService.get("roleName");
    this.employeeImage = this.cookieService
      .get("employeeImage")
      .replace("%", "/");
    this.employeeColor = this.cookieService.get("employeeColor");
    this.fullName = this.cookieService
      .get("employeeFullName")
      .replace("%20", " ");
    this.Window?.$crisp?.push(["set", "user:nickname", [`${this.fullName}`]]);

    if (employeeEmail) {
      this.Window?.$crisp?.push(["set", "user:email", [`${employeeEmail}`]]);
    }
    this.cutName = this.cookieService.get("employeeCutName");
    const hex = this.employeeColor.replace("#", "");
    const c_r = parseInt(hex.substr(0, 2), 16);
    const c_g = parseInt(hex.substr(2, 2), 16);
    const c_b = parseInt(hex.substr(4, 2), 16);
    const brightness = (c_r * 299 + c_g * 587 + c_b * 114) / 1000;
    if (brightness >= 155) {
      this.categoryColorNamee = "#ffffff";
    } else {
      this.categoryColorNamee = "#ffffff";
    }
    // EmitterService.get('employeeFullName').subscribe((employeeFullName: any) => {
    //   this.fullName = employeeFullName;
    // })
    EmitterService.get("employeeImage").subscribe((employeeImage: any) => {
      let imageLink = employeeImage;
      this.employeeImage = this.utils.getImage(imageLink);
    });
    // EmitterService.get('employeeCutName').subscribe((employeeCutName: any) => {
    //   this.cutName = employeeCutName;
    // })
  }
  changeStore(event: any) {
    this.params = new HttpParams();
    this.storeId = event;
    // if (event !== "*") {

      this.showQuickStore = true;

      // this.getStoreInformation();
      var storeId = this.cookieService.get("storeId");
      this.params = this.params.set("storeId", storeId);
      this.httpOptions = {
        headers: new HttpHeaders(this.headerToken),
        params: this.params,
      };

      // this.getStoreInformation();
      this.getStoreInformation('changeStore')
        .then((response) => {
          // Handle successful response (data is in `response`)
          this.navRouteAndRestore();
        })
        .catch((error) => {
          // Handle error (error message/object is in `error`)
          this.navRouteAndRestore();
          console.error("Error getting store information:", error);
        });

      this.getNotifications();
      this.updateUserStoreCachePermission()
    // } else {
      this.showQuickStore = false;
      this.notificationList = [];
      // this.httpOptions = { headers: new HttpHeaders(this.headerToken) };
      // this.getQuickStoreSettings();
    // }
    // save current route first
    // const currentRoute = this.router.url;
    // this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    // this.router.onSameUrlNavigation = "reload";

    // this.navRouteAndRestore();
  }

  navRouteAndRestore() {
    // Store the original values
    this.originalReuseStrategy =
      this.router.routeReuseStrategy.shouldReuseRoute;
    this.originalOnSameUrlNavigation = this.router.onSameUrlNavigation;

    // Modify the values
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = "reload";

    // Perform the navigation
    const currentRoute = this.router.url;
    this.router.navigate([currentRoute]);

    // Restore the original values after navigation is complete
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        take(1) // Ensure only one event is handled
      )
      .subscribe(() => {
        this.router.routeReuseStrategy.shouldReuseRoute =
          this.originalReuseStrategy;
        this.router.onSameUrlNavigation = this.originalOnSameUrlNavigation;
      });
  }

  updateUserStoreCachePermission = () => {
    const employeeId = this.cookieService.get('employeeId')
    const storeId = this.cookieService.get('storeId')
    this.authService.userStorePermission({ response: { employeeId, storeId } });
  }

  params2: any = new HttpParams();
  storeCurrentCurrency: any = '';
  storeTaxAlias: any = '';
  getStoreInformation(state?: any): Promise<any> {
    if (state === 'changeStore') {
      this.store.dispatch(setLoadingSpinner({ status: true }));
    }
    // this.params2 = this.params2.set("storeId", this.storeId);
    return new Promise((resolve, reject) => {
      // if (this.storeId !== "*") {
        // this.params2 = this.params2.set("merchantId", this.merchantId);
      // }
      this.params2 = this.params2.set("storeId", this.storeId);

      var httpOptions3 = {
        headers: new HttpHeaders(this.headerToken),
        params: this.params2,
      };

      // for store location it will load store from prod url
      // store will not fetch from preprod env
      this.http.get(this.ApiService.prodURL + "/store", httpOptions3).subscribe(
        (response: any) => {
          console.log("TOPBAR_STORE_INFORMATION", response)
          this.getQuickStoreSettings();
          if (response?.success === 0) {
            this.toastrservice.error(response.message);
            reject(response.message);
          } else {
            this.isTraineeSetup = response?.response?.isTraineeSetup;
            // console.log("Trainee-SEtupppp", this.isTraineeSetup);
            if (response?.response?.storeId) {
              this.storeDetails = {
                _id: response.response.storeId,
                isTraineeSetup: 1,
                storeName: response.response.storeName,
                merchantId: response.response.merchantId,
              };
              // console.log("this.storeDetails_TOPBAR_IF", this.storeDetails)
            }
            // console.log("this.storeDetails_TOPBAR", this.storeDetails)

            this.storeCity = response?.response?.storeCity;
            if (
              this.storeCity === "" ||
              this.storeCity == undefined ||
              this.storeCity == null
            ) {
              console.log("STORE_CITY_EMPTY", this.storeCity);
              this.hasStoreCity = false;
            } else {
              console.log("STORE_CITY_", this.storeCity);
              this.hasStoreCity = true;
              this.getWeatherNotifications();
            }
            // this.getWeatherNotifications();
            localStorage.setItem(
              "storeZipcode",
              response?.response?.storeZipcode
            );
            if (response.response?.storeFrontSubdomain === "") {
              this.hasStoreFront = false;
            } else {
              this.hasStoreFront = true;
            }

            {const
              el = response?.response
              if (el) {
                const { storeAddress1, storeCity, storeCountry } = el

                localStorage.setItem('storeAddress1', storeAddress1 || '')
                localStorage.setItem('storeCity', storeCity || '')
                localStorage.setItem('storeCountry', storeCountry || '')
                  // Call the getLanguageAndCurrency method
                 this.getLanguageAndCurrency(storeCountry);
              }
            }

            // Store the Store Currency in Cookie
            // if (response.response && response.response.settings) {
            //   response.response.settings.forEach((element2) => {
            //     if (element2.settingName == 'Currency') {
            //       // var currencyCode = element2.settingValue.replace(/ /g, "").split('-');
            //       // updated the currency logic according to the new currency setting
            //       var currencyCode = element2.settingValue;
            //       console.log("currencyCode", typeof (currencyCode), currencyCode)
            //       // if (currencyCode?.length > 1) {
            //       if (currencyCode !== '') {
            //         this.storeCurrentCurrency = currencyCode;
            //       } else {
            //         this.storeCurrentCurrency = "$";
            //       }
            //       this.cookieService.set('currency', this.storeCurrentCurrency, 70, '/');
            //       localStorage.setItem('currency', this.storeCurrentCurrency);
            //     }
            //     if (element2.settingName == 'TaxAlias') {
            //       // updated the currency logic according to the new currency setting
            //       var TaxAliasValue = element2.settingValue;
            //       if (TaxAliasValue !== '') {
            //         this.storeTaxAlias = TaxAliasValue;
            //       } else {
            //         this.storeTaxAlias = "Tax";
            //       }
            //       this.cookieService.set('taxAliasValue', this.storeTaxAlias, 70, '/');
            //       // localStorage.setItem('currency', this.storeCurrentCurrency);
            //     }

            //     for(let setting of Object.keys(this.SettingName)){
            //       const el = element2
            //       if(el.settingName === setting){
            //         localStorage.setItem(setting, el.settingValue)
            //       }
            //     }

            //   });
            // }
             // Store the Store Currency in Cookie
          if (response.response && response.response.settings) {
            response.response.settings.forEach((element2) => {
              if (element2.settingName == 'Currency') {
                // var currencyCode = element2.settingValue;
                var currencyCode = this.cookieService.get('currency');
                console.log("currencyCode", typeof (currencyCode), currencyCode)
                if (currencyCode !== '') {
                  this.storeCurrentCurrency = currencyCode;
                } else {
                  this.storeCurrentCurrency = "$";
                }
                this.cookieService.set('currency', this.storeCurrentCurrency, 70, '/');
                localStorage.setItem('currency', this.storeCurrentCurrency);
              }
              if (element2.settingName == 'TaxAlias') {
                // var TaxAliasValue = element2.settingValue;
                var TaxAliasValue = this.cookieService.get('taxAliasValue');
                if (TaxAliasValue !== '') {
                  this.storeTaxAlias = TaxAliasValue;
                } else {
                  this.storeTaxAlias = "Tax";
                }
                this.cookieService.set('taxAliasValue', this.storeTaxAlias, 70, '/');
              }
              for(let setting of Object.keys(this.SettingName)){
                const el = element2;
                if(el.settingName === setting){
                  localStorage.setItem(setting, el.settingValue);
                }
              }
            });
          }

            this.validateStoreSettings()

            resolve(response);
          }
        },
        (error: any) => {
          console.error("An error occurred:", error);
          // this.ApiService.errorMessage(error)
          reject(error);
        }
      );
    });
  }
  params3: any = new HttpParams();
  getWeatherNotifications() {
    if (this.storeId !== "*") {
      this.params3 = this.params3.set("storeId", this.storeId);
      this.params3 = this.params3.set("merchantId", this.merchantId);
      this.params3 = this.params3.set("city", this.storeCity);
    } else {
      this.weatherList = [];
      return;
    }

    var httpOptions3 = {
      headers: new HttpHeaders(this.headerToken),
      params: this.params3,
    };

    // for store location it will load store from prod url
    // store will not fetch from preprod env
    this.http.get(this.GLOBALURL + "/weather", httpOptions3).subscribe(
      (response: any) => {
        if (response.success === 0) {
          this.toastrservice.error(response.error);
          // this.toastr.error("Please select a store city.");
        } else {
          this.weatherList = response?.response;
          if (this.weatherList.length > 0) {
            this.weatherCount = response.metaData.count
              ? response.metaData.count
              : this.weatherList.length;

            this.weatherList = this.weatherList.map((item) => {
              let affectAreas = "";
              for (let i = 0; i < item.rainAffectAreas.length; i++) {
                if (item.rainAffectAreas.length == 1) {
                  affectAreas = item.rainAffectAreas[0];
                  break;
                }
                if (item.rainAffectAreas.length == 2) {
                  affectAreas =
                    item.rainAffectAreas[0] + " and " + item.rainAffectAreas[1];
                  break;
                }
                affectAreas = affectAreas
                  .concat(item.rainAffectAreas[i])
                  .concat(
                    i == item.rainAffectAreas.length - 2
                      ? "and"
                      : i == item.rainAffectAreas.length - 1
                        ? ""
                        : ","
                  );
              }
              const truncate = (input) =>
                input.length > 30 ? `${input.substring(0, 31)}...` : input;
              return {
                ...item,
                trncDesc: truncate(item.description),
                affectAreasCount: item.rainAffectAreas.length,
                affectAreas: affectAreas,
                isShown: false,
              };
            });
          }
        }
      },
      (error) => {
        // console.log(error);
        let message = this.ApiService.errorMessage(error);
        // this.toastr.error(message);
        console.log("WEATHER_NOTIFI_API_FALIED", message);
      }
    );
  }

  getLanguageAndCurrency(countryName: string) {
    if (countryName) {
      countryName = countryName.trim();
    }
    if (countryName == "USA") {
      countryName = "united states";
    }
    if (countryName) {
      const url = `https://restcountries.com/v3.1/name/${countryName}?fullText=true`;
      this.http.get(url).subscribe(
        (data: any) => {
          if (data && data.length > 0) {
            const countryData = data[0];
            const currency = `${Object.keys(countryData.currencies)[0]}-${(Object.values(countryData.currencies)[0] as any).symbol}`;

            // Setting values
            const currencyCode = currency.split('-')[0];
            // console.log("Language", currencyCode ,currencyCode == 'AED' ? 'VAT' : 'Tax');

            // this.cookieService.set('currency', currencyCode, 70, '/');
            this.cookieService.set('taxAliasValue', currencyCode === 'AED' ? 'VAT' : 'Tax', 70, '/');
            // localStorage.setItem('currency', currencyCode);
            localStorage.setItem('taxAliasValue', currencyCode === 'AED' ? 'VAT' : 'Tax');
          }
        },
        (error) => {
          let message = this.ApiService.errorMessage(error);
          this.toastrservice.error(message);
        }
      );
    }
  }
  getNotifications() {
    if (this.storeId !== "*") {
      this.params2 = this.params2.set("storeId", this.storeId);
    } else {
      this.notificationList = [];
      return;
    }

    var httpOptions3 = {
      headers: new HttpHeaders(this.headerToken),
      //params: this.params2,
    };

    // for store location it will load store from prod url
    // store will not fetch from preprod env
    this.http
      .get(this.GLOBALURL + "/push-notification", httpOptions3)
      .subscribe((response: any) => {
        if (response.success === 0) {
          this.toastrservice.error(response.message);
        } else {
          this.notificationList = response?.response;
          if (this.notificationList.length > 0) {
            // this.notificationCount = response.metaData.count
            //   ? response.metaData.count
            //   : this.notificationList.length;
            this.notificationCount = this.notificationList.filter(notification => !notification.isMarkedRead).length;

            this.isPinnedList = this.notificationList.filter(
              (notif) => notif.isPinned == true
            );
            let notPinned: any[] = this.notificationList.filter(
              (notif) => notif.isPinned == false
            );
            this.notificationList = this.isPinnedList.concat(notPinned);
            this.notificationList = this.notificationList.map((item) => {
              let character = "";
              character = item.message.trim();
              var a = character.split(" ");
              if (a[0] == "An" && a[1] == "Employee") {
                character = "E";
              }
              if (a[0] != "An") {
                character = a[0].charAt(0).toUpperCase();
              }

              let timeago = "";
              if (this.timezone != "") {
                timeago = momenttz(item.createdAt * 1000)
                  .tz(this.timezone)
                  .fromNow();
              } else {
                timeago = momenttz(item.createdAt * 1000).fromNow();
              }
              return {
                ...item,
                show: false,
                timeElapsed: timeago,
                char: character,
              };
            });
          } else {
            this.notificationCount = 0;
          }
        }
      });
  }
  toggleAuto(event: any) { }
  enableDisable(event: any, settingName: any) {
    if (this.storeId === "*" || this.storeId === undefined) {
      this.toastrservice.error("Please select a location first.");
      window.location.reload();
      return;
    }
    if (this.prepTimeValue == 0 || this.prepTimeValue == null) {
      this.prepTimeError = "Please enter preptime."
      return;
    }

    let value = this.prepTimeValue;
    if (!value || Number(value) < 10) {
      this.prepTimeError = "Please enter preptime greater than or equal to 10";
      return;
    } else if (Number(value) > 60) {
      this.prepTimeError = "Please enter preptime less than or equal to 60";
      return;
    }

    if (settingName === "OnlineStoreDineInAutoAccept") {
      this.canAutoAcceptOrderDineIn = event.target.checked;
      let dineInAutoAccept = this.canAutoAcceptOrderDineIn;
      this.payload = {
        storeId: this.storeId,
        merchantId: this.merchantId,
        settings: [
          {
            settingValue: dineInAutoAccept.toString(),
            settingValueType: 2,
            settingType: 4,
            settingName: settingName,
          },
        ],
      };
    }
    if (settingName === "OnlineStoreDineInPauseOrder") {
      this.canPauseOrderDineIn = event.target.checked;
      let dineInPauseOrder = this.canPauseOrderDineIn;
      this.payload = {
        storeId: this.storeId,
        merchantId: this.merchantId,
        settings: [
          {
            settingValue: dineInPauseOrder.toString(),
            settingValueType: 2,
            settingType: 4,
            settingName: settingName,
          },
        ],
      };
    }
    if (settingName === "OnlineStoreAutoAccept") {
      this.canAutoAcceptOrderOnline = event.target.checked;
      let onlineOrderingAutoAccept = this.canAutoAcceptOrderOnline;
      this.payload = {
        storeId: this.storeId,
        merchantId: this.merchantId,
        settings: [
          {
            settingValue: onlineOrderingAutoAccept.toString(),
            settingValueType: 2,
            settingType: 4,
            settingName: settingName,
          },
        ],
      };
    }
    if (settingName === "OnlineStorePauseOrder") {
      this.canPauseOrderOnline = event.target.checked;
      let onlineOrderingPauseOrder = this.canPauseOrderOnline;
      this.payload = {
        storeId: this.storeId,
        merchantId: this.merchantId,
        settings: [
          {
            settingValue: onlineOrderingPauseOrder.toString(),
            settingValueType: 2,
            settingType: 4,
            settingName: settingName,
          },
        ],
      };
    }
    this.topbarService
      .quickStoreSettings(this.payload)
      .subscribe((response: any) => {
        if (response.success === 0) {
          this.toastrservice.error(response.message, "error");
        } else if (!response.error) {
          if (this.showQuickStoreLoader === false) {
            setTimeout(() => {
              this.toastrservice.success(response.message, "success");
            }, 1000);
          }
        }
      });
  }
  getQuickStoreSettings() {
    this.showQuickStoreLoader = true;
    this.params2 = this.params2.set("storeId", this.storeId);
    var httpOptions3 = {
      headers: new HttpHeaders(this.headerToken),
      params: this.params2,
    };


    this.locationService.getStoreFullSetting(this.storeId, this.cookieService.get("token")).subscribe((response: any) => {
    // this.http.get(this.GLOBALURL + "/settings", httpOptions3).subscribe(
    //   (response: any) => {


    // console.log("////////////////////")
    // console.log("////////////////////")
    // console.log(response)

        this.showQuickStoreLoader = false;
        if (response.success === 0) {
          this.toastrservice.error(response.message);
        } else {
          const arr = response?.response;
          const indexOfObject = arr.findIndex((object) => {
            return object.settingName == "OloSubsidie";
          });

          if (indexOfObject !== -1) {
            arr.splice(indexOfObject, 1);
          }
          this.settingDetails = arr;
          this.updateTraineeData();
          response?.response.forEach((element) => {
            if (element.settingName === "ClosingDay") {
              if (element.settingValue !== "") {
                this.cookieService.set(
                  "ClosingDay",
                  element.settingValue,
                  70,
                  "/"
                );
                // Also set in localstorage
                localStorage.setItem("ClosingDay", element.settingValue);
              }
            }
            if (element.settingName === "StartOfHourByDay") {
              if (element.settingValue !== "") {
                this.cookieService.set(
                  "StartOfHourByDay",
                  element.settingValue,
                  70,
                  "/"
                );
                // Also set in localstorage
                localStorage.setItem("StartOfHourByDay", element.settingValue);
              }
            }
            if (element.settingName === "EndOfHourByDay") {
              if (element.settingValue !== "") {
                this.cookieService.set(
                  "EndOfHourByDay",
                  element.settingValue,
                  70,
                  "/"
                );
                // Also set in localstorage
                localStorage.setItem("EndOfHourByDay", element.settingValue);
              }
            }

            if (element.settingName === "Timezone") {
              this.timezone = element.settingValue;
            }
            if (element.settingName === "OnlineStorePrepTime") {
              this.prepTimeValue = +element.settingValue;
            }
            if (element.settingName === "OnlineStoreDineInPauseOrder") {
              if (element.settingValue === "true") {
                this.canPauseOrderDineIn = true;
              } else if (element.settingValue === "false") {
                this.canPauseOrderDineIn = false;
              }
            }
            if (element.settingName === "OnlineStoreDineInAutoAccept") {
              if (element.settingValue === "true") {
                this.canAutoAcceptOrderDineIn = true;
              } else if (element.settingValue === "false") {
                this.canAutoAcceptOrderDineIn = false;
              }
            }

            if (element.settingName === "OnlineStorePauseOrder") {
              if (element.settingValue === "true") {
                this.canPauseOrderOnline = true;
              } else if (element.settingValue === "false") {
                this.canPauseOrderOnline = false;
              }
            }

            if (element.settingName === "OnlineStoreAutoAccept") {
              if (element.settingValue === "true") {
                this.canAutoAcceptOrderOnline = true;
              } else if (element.settingValue === "false") {
                this.canAutoAcceptOrderOnline = false;
              }
            }

            // for(let setting of Object.keys(this.SettingName)){
            //   if(element.settingName === setting){
            //     localStorage.setItem(setting, element.settingValue)
            //   }
            // }

          });

          this.validateStoreSettings()
        }
      },
      (error) => {
        console.log(error);
        this.showQuickStoreLoader = false;
      }
    ),
      share();
  }
  timeout: any = null;
  prepTimeError: any = "";

  prepTime(event: any) {
    this.locationService.storeFullSettings$ = null
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      if (event?.keyCode != 13) {
        const value = parseInt(event.target.value, 10);
        console.log("=value==", value);
        if (isNaN(value) || value < 10) {
          this.prepTimeError = "Please enter preptime greater than or equal to 10";
          return;
        } else if (value > 60) {
          this.prepTimeError = "Please enter preptime less than or equal to 60";
          return;
        } else {
          if (event.keyCode != 13) {
            if (this.storeId === "*" || this.storeId === undefined) {
              this.toastrservice.error("Please select a location first.");
              return;
            }
            this.prepTimeError = "";
            let payload = {
              storeId: this.storeId,
              merchantId: this.merchantId,
              settings: [
                {
                  settingValue: value,
                  settingValueType: 2,
                  settingType: 4,
                  settingName: "OnlineStorePrepTime",
                },
              ],
            };

            this.topbarService
              .quickStoreSettings(payload)
              .subscribe((response: any) => {
                if (response.success === 0) {
                  this.toastrservice.error(response.message);
                } else if (!response.error) {
                  setTimeout(() => {
                    this.toastrservice.success(response.message);
                  }, 1500);
                  this.getQuickStoreSettings();
                }
              });
          }
        }
      }
    }, 700);
  }
  doesFileExist(url) {
    var xhr = new XMLHttpRequest();
    xhr.open("HEAD", url, false);
    xhr.send();

    if (xhr.status == 404) {
      return false;
    } else {
      return true;
    }
  }
  supportLoader: Boolean = false;
  generateSupportPin() {
    this.supportLoader = true;
    this.supportPinService.getSupportPin().subscribe(
      (res: any) => {
        if (res.success === 0) {
          this.supportLoader = false;
          this.toastrservice.error(res.message);
        } else {
          this.supportPin = res.data.supportPin;
          let array = this.supportPin.split("");
          this.displayPin = array;
          this.supportLoader = false;
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }
  updateNotif(payload: any, templist?: any) {
    this.topbarService
      .updateNotification(payload)
      .subscribe((response: any) => {
        if (response.success === 0) {
          this.toastrservice.error(response.message, "error");
        } else if (!response.error) {
          this.notificationList = templist;
          console.log("NEW NOTIFICATIN LIST", this.notificationList);
          if(this.notificationList.length > 0){
            this.notificationCount = this.notificationList.filter(notification => !notification.isMarkedRead).length;
          } else {
            this.notificationCount = 0;
          }
          return;
        }
      });
  }
  notiseen(i) {
    this.notifIndex = i;
    this.notificationList[i].show = !this.notificationList[i].show;
  }
  isPinned(i, bool) {
    let payload = {
      action: "isPinned",
      notifications: [this.notificationList[i]],
    };
    if (bool) {
      this.notificationList[i].isPinned = !this.notificationList[i].isPinned;
      this.isPinnedList.push(this.notificationList[i]);
      let notPinned: any[] = this.notificationList.filter(
        (notif) => notif.isPinned == false
      );
      this.notificationList = this.isPinnedList.concat(notPinned);
      this.updateNotif(payload, this.notificationList);
    } else {
      this.notificationList[i].isPinned = !this.notificationList[i].isPinned;

      this.isPinnedList.splice(i, 1);
      let notPinned: any[] = this.notificationList.filter(
        (notif) => notif.isPinned == false
      );
      this.notificationList = this.isPinnedList.concat(notPinned);
      this.updateNotif(payload, this.notificationList);
    }
  }
  isFlagged(i, bool) {
    let payload = {
      action: "isFlagged",
      notifications: [this.notificationList[i]],
    };
    this.notificationList[i].isFlagged = bool;
    this.updateNotif(payload, this.notificationList);
  }
  markAsRead(i, bool) {
    let payload = {
      action: "isMarkedRead",
      notifications: [this.notificationList[i]],
    };
    let templist = this.notificationList;

    templist[i].isMarkedRead = bool;
    // this.notificationList = templist;
    this.updateNotif(payload, templist);
  }
  dismissNotif(i) {
    this.notificationList[i].status = 0;
    let tempObj = [this.notificationList[i]];
    let payload = {
      action: "isDismissed",
      notifications: tempObj,
    };
    let templist = this.notificationList;
    // this.notificationList.splice(i, 1);
    templist.splice(i, 1);

    // this.notificationCount = this.notificationCount - 1;
    // updating on db
    this.updateNotif(payload, templist);
  }
  markAllAsRead() {
    let updatedList = [];
    for (let notification of this.notificationList) {
      notification.isMarkedRead = true;
      updatedList.push(notification);
    }
    console.log("updatedList", updatedList);
    // this.notificationList = [];
    // this.notificationList = updatedList; // to re render UI
    let payload = {
      action: "markAllAsRead",
      notifications: this.notificationList,
    };
    //this.changeDetection.detectChanges();
    // updating on db
    this.updateNotif(payload, updatedList);
  }
  dismissAllNotif() {
    if (this.notificationList?.constructor === Array) {
      for (let notification of this.notificationList) {
        notification.status = 0;
      }

      let tempList = [];

      // this.notificationList = []; // to re render UI
      // this.notificationCount = 0;

      let payload = {
        action: "dismissedAll",
        notifications: this.notificationList,
      };
      // updating on db
      this.updateNotif(payload, tempList);
    }
  }
  chatWithUs() {
    // set window to access crisp object
    let Window: any = window;
    if (Window && Window.$crisp) {
      Window.$crisp.push(["do", "chat:show"]);
      Window.$crisp.push(["do", "chat:open"]);
      localStorage.setItem("crisp_chat_opened", "true");
    }
  }
  goToSupportCenter() {
    let Window: any = window;
    Window.open("https://support.eatos.com/en-us/", "_blank");
  }

  updateTraineeData() {
    if (this.isTraineeSetup != 1 && this.isTraineeSetup != undefined) {
      // if (this.isTraineeSetup == 1) {

      this.locationService.updateTestStore(this.storeDetails).subscribe(
        (response: any) => {
          console.log("Trainee--RESS-Ponse-TopBAR", response);
        },
        (error) => {
          console.log("Trainee--RESSPonse-TopBAR-ERROR", error);
        }
      );
      const payload = {
        storeId: this.storeDetails?._id,
        settings: this.settingDetails,
      };
      this.locationService.updateTestSettings(payload).subscribe(
        (tresponse: any) => {
          if (tresponse.success == 1) {
            this.locationService
              .updateStore({
                storeId: this.storeDetails?._id,
                isTraineeSetup: 1,
              })
              .subscribe();
          }
        },
        (error) => {
          console.log(error);
        }
      );
    } else if (this.isTraineeSetup == undefined) {
      console.log("When-Switching-Storesss", this.isTraineeSetup);
      return;
    }
  }
  // navigateToSearch(){
  //   setTimeout(() => {
  //     this.router.navigate(['/search'])
  //   }, 3000);
  // }
  onSelectOfLogo(appName: string, iconPath: string) {
    if (this.osapps != undefined && this.osapps != null && this.osapps.length > 0) {
      let newAppId = this.osapps.find((app) => app.appName === appName)._id;
      this.selectedLogoPath = this.osapps.find((app) => app.appName === appName).appLogoImageFile
      console.log("newAppId", newAppId, this.osapps)
      if (appName == "Retailos") {
        let user = this.authService.getUserFromLocalStorage();
        if (user) {
          user.response.appId = newAppId; // replace 'new-app-id' with your actual appId
          this.authService.setUserInLocalStorage(user);
          console.log("Retailos", user);
          this.AppAliasService.setValue({
            type: "Retailos",
            "menuManagement": false,
            "productManagement": true,
            "hardware": false,
            "onlineOrdering": false,
          })
        }
      }
      else if (appName == "eatOS") {
        let user = this.authService.getUserFromLocalStorage();
        console.log("eatOS", user)
        if (user) {
          user.response.appId = newAppId; // replace 'new-app-id' with your actual appId
          this.authService.setUserInLocalStorage(user);
          this.AppAliasService.setValue({
            type: "eatos",
            "menuManagement": true,
            "productManagement": false,
            "hardware": true,
            "onlineOrdering": true,
          })
        }
      }
    }
  }
  OpenSearchbarModel() {
    this.receivedData = "";
    setTimeout(() => {
      this.modalService.dismissAll();
      this.modalService.open(this.SearchbarModel, {
        size: "lg Search-Model",
        centered: true,
      });
      setTimeout(() => {
        const name = localStorage.getItem("rtl");
        if (name === 'right') {
          const ModelEmelemt1 = this.document.querySelectorAll(".Search-Model");
          ModelEmelemt1.forEach((element: any) => {
            this.renderer.addClass(element, "dashboard-RTL-search");
          });
        }
      }, 0);
      this.renderer.removeClass(document.body, "modal-open");
    }, 500);
    this.offcanvasService.dismiss("cross click");
  }
  receivedData: string;
  receiveDataFromSearchbar(data: string) {
    let bodyClass = this.document.body;
    setTimeout(() => {
      // bodyClass.classList.remove('vertical-collpsed')
      bodyClass.classList.add("Focus-none");
      this.receivedData = data;
      // console.log("this.receivedData", this.receivedData)
    }, 0);
  }
  navigateToProfile(isShown: boolean) {
    let bodyClass = this.document.body;
    if (isShown) {
      this.offcanvasService.dismiss("cross click");
    } else {
      setTimeout(() => {
        bodyClass.classList.remove("vertical-collpsed");
        bodyClass.classList.add("sidebar-enable");
      }, 2000);
    }

    this.TopMenuDiscValue = "main";

    this.router.navigate(["/profile/profile-settings"]);
  }
  closeModel() {
    this.modalService.dismissAll();
    setTimeout(() => {
      this.receivedData = "Focus-none";
    }, 0);
  }

  SearchedValue: any;
  SendSearchValaue: any;
  SearchedValueLink() {
    this.SendSearchValaue = this.SearchedValue;
  }
  lastvalue: any;
  currenetValue: any;
  // RemoveCanvaClass() {
  //   const bodyClasses = document.body.classList;
  //   this.currenetValue = bodyClasses[1];
  //   if (bodyClasses.contains("ng-tns-0-0")) {
  //     this.renderer.removeClass(document.body, "ng-tns-0-0");
  //   }
  //   if (this.lastvalue != this.currenetValue) {
  //     if (bodyClasses[1] === "modal-open") {
  //       this.greather = true;
  //       this.renderer.removeClass(document.body, "modal-open");
  //       this.lastvalue = bodyClasses[1];
  //     }
  //   } else if (this.lastvalue === this.currenetValue)
  //     if (bodyClasses[0] === "vertical-collpsed") {
  //       this.greather = true;
  //     }
  // }
  OpenTopmenuList(content1: TemplateRef<any>) {
    this.offcanvasService.open(content1, { position: "end" });
  }
  @Output() dataEvent = new EventEmitter<any>();
  OpenTopsidebarList() {
    setTimeout(() => {
      this.renderer.removeClass(document.body, "sidebar-enable");
      this.renderer.removeClass(document.body, "vertical-collpsed");
      this.renderer.addClass(document.body, "res-sidebar-enable");
      const data = "opensidebar";
      this.dataEvent.emit(data);
    }, 100);
  }

  formateDate: any;
  timezoneName1 = "";

  TodayDateFormate() {
    try {
      const formattedTime = this.timezoneService
        .get()()
        // .format("dddd, D MMMM, YYYY, h:mm A z");
        .format("D MMM YY, h:mm A z");
      if (formattedTime && typeof formattedTime === "string") {
        this.timezoneName1 = formattedTime.split(" ").at(-1);
        // console.log("this.timezoneName1_LOCATION_TIME_ZONE", typeof(this.timezoneName1))
        const dateArray = formattedTime.split(" ");
        dateArray.pop(); // Remove the last element from the array
        const dateString = dateArray.join(" "); // Convert the modified array into a string
        this.formateDate = dateString;
      }
    } catch (error) {
      console.error("An error occurred while formatting the date:", error);
    }
  }

  TopMenuDiscOpen(value: any) {
    this.NotificationMenu = value;
    console.log("mwnu   =======>", value);
    if (value === "Weather") {
      this.AcitvTab(4);
      this.TopMenuDiscValue = "Notification";
    } else {
      if (value === "Notification") {
        this.AcitvTab(1);
      }
      this.TopMenuDiscValue = value;
    }
  }
  RemoveUnwantedClass() {
    this.renderer.removeClass(document.body, "modal-open");
  }

  // Function to handle the storage event
  storageEventListener(event: StorageEvent) {
      this.storageListener = true
      // Check if the event is for localStorage
      if (event.storageArea === localStorage) {
        // Handle the event, for example:
        if(this.SettingName.hasOwnProperty(event.key)){
          this.validateStoreSettings()
        }
      }
  }

  validateStoreSettings = () => {
    const items = Object.keys(this.SettingName)
    let isComplete:boolean = true
    for(let item of items){
      console.log('Checking item:', item); // Log each item
      console.log('Checking item1:', localStorage.getItem(item)); // Log each item
      if(!localStorage.getItem(item)){
        isComplete = false
        break;
      }
    }
    // if(!isComplete){
    //   this.toastrservice.error('Please finalize your store settings before accepting orders', { autoClose:false, completeSettings: true })
    // }else{
    //   this.toastrservice.clear()
    // }
  }

  ngOnDestroy() {
    window.removeEventListener('storage', this.storageEventListener.bind(this));
    this.destroy$.next();
    this.destroy$.complete();
  }


Layoutselect :any


toggleRTL() {
  let layout = this.ApiService.GetRtlData();

  if (layout === "right") {
    layout = "left";
    this.Layoutselect = "left";
    localStorage.setItem('rtl', layout);
  } else {
    layout = "right";
    this.Layoutselect = "right";
    localStorage.setItem('rtl', layout);
  }

  this.ApiService.setRtlData(layout);
    this.updateCheckboxState(layout);
}

updateCheckboxState(layout: string) {
  const checkbox = document.querySelector('.dashboard-RTL-toggleBTN input[type="checkbox"]') as HTMLInputElement;
  if (checkbox) {
    checkbox.checked = (layout === "right");
  }
}

initializeLayout() {
  const layout = this.ApiService.GetRtlData();
  this.updateCheckboxState(layout);
}


dropdown() {
    this.headerValue = !this.headerValue
}
notifydropdown(){
  this.notifyheaderValue = !this.notifyheaderValue

}

sitedropdown() {
  this.siteheaderValue = !this.siteheaderValue
}

profiledropdown() {
  this.profileheaderValue = !this.profileheaderValue
}

supportdropdown() {
  this.supportheaderValue = !this.supportheaderValue
}

ClosePanel() {
  this.headerValue = false;
}

siteclosePanel(){
  this.siteheaderValue = false
}

profileclosePanel(){
  this.profileheaderValue = false
}

searchclosePanel(){
  this.searchheaderValue = false
}

traningclosePanel(){
  this.trainmodeheaderValue= false
}
notifyclosePanel(){
  this.notifyheaderValue = false
}
weatherclosePanel(){
  this.weatherheaderValue = false
}
supportclosePanel(){
  this.supportheaderValue = false
}




}
