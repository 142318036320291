import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { AppState } from '../../../../store/app.state';
// import moment from 'moment';
import { TimezoneService } from 'src/app/services/timezone';
import { ApiService } from "../../../../services/api";
import { AlertService } from '../../AlertService/alert.service';
@Injectable({
  providedIn: 'root',
})
export class SalesTransactionService {
  timeoutInterval: any;
  GLOBALURL = this.ApiService.getBaseURL();
  constructor(
    private http: HttpClient,
    private store: Store<AppState>,
    private cookieService: CookieService,
    private toastr: AlertService,
    private ApiService: ApiService,
    private timezoneService: TimezoneService
  ) { }

  //sending login credentials to server for validate
  getSalesTransactionSummary(
    resultEmployeeIds: any,
    resultRevenueIds: any,
    startDate: any,
    endDate: any,
    startTime: any,
    endTime: any,
    startDateDisplay: any,
    endDateDisplay: any,
    merchantId: any,
    storeIdFixed: any,
    paymentStatusId: any,
    pageLoad: Boolean,
    orderSellStatusId: any,
    orderTypeId: any,
    lastStartDate: any,
    lastEndDate: any,
    salesCardNumber: any,
    salesCardType: any,
    serviceCharge:any,
    serviceChargeId: any,
    revenueCenterId: any,
    discount: any,
    discountId: any,
    appliedAs: any
  ): Observable<any> {
    const headerToken = {
      'Content-Type': 'application/json', 'Authorization': this.cookieService.get('token') }
    let params1 = new HttpParams()
      .set("startDate", startDateDisplay)
      .set("endDate", endDateDisplay)
      .set("lastStartDate", lastStartDate)
      .set("lastEndDate", lastEndDate);

    if (
      (startTime !== "00:00" && endTime === "23:59") ||
      (endTime !== "23:59" && startTime === "00:00") ||
      (startTime !== "00:00" && endTime !== "23:59")
    ) {
      console.log("SETTING TIME")
      params1 = params1.set("startTime", startTime);
      params1 = params1.set("endTime", endTime);
    }

    const httpOptions = {
      headers: new HttpHeaders(headerToken),
      params: params1
    };
    // Check the values for resultRevenueIds and salesCardType
    // both should be combined and sent as paymentMethodIds
    // resultRevenueIds is the payment method ids
    // salesCardType is the transaction types
    let paymentMethodIdsValues = [];
    if(resultRevenueIds.length > 0 && salesCardType != "") {
      paymentMethodIdsValues = resultRevenueIds.concat([parseInt(salesCardType)]);
    } else if (resultRevenueIds.length == 0 && salesCardType != "") {
      paymentMethodIdsValues = [parseInt(salesCardType)];
    } else if (resultRevenueIds.length > 0 && salesCardType == "") {
      paymentMethodIdsValues = resultRevenueIds;
    } else {
      paymentMethodIdsValues = [];
    }

    let appliedAsValue: any;
    if(appliedAs !== "" && appliedAs !== undefined){
      appliedAsValue = appliedAs;
    }

    let serviceChargeValue: any;
    let serviceChargeIdValue: any;
    let discountValue: any;
    let discountIdValue: any;
    if(serviceCharge) {
      serviceChargeValue = serviceCharge
      serviceChargeIdValue = serviceChargeId;
      // params = params.append("merchantId", merchantId);
    } else if (discount) {
      discountValue = discount;
      discountIdValue = discountId;
      // params = params.append("merchantId", merchantId);
    }
    const params = {
      'reportName': "transactions_summary_report",
      "query": {
        'employeeIds': resultEmployeeIds === "" ? [] : resultEmployeeIds,
        // 'paymentMethodIds': resultRevenueIds === "" ? [] : this.convertStringToNumber(resultRevenueIds),
        'paymentMethodIds': paymentMethodIdsValues,
        // 'startDate': parseInt(moment(startDate).format('X')),
        'startDate': parseInt(this.timezoneService.get()(startDate).format('X')),
        // 'endDate': parseInt(moment(endDate).format('X')),
        'endDate': parseInt(this.timezoneService.get()(endDate).format('X')),
        // 'startTime': parseInt(startTime),
        // 'endTime': parseInt(endTime),
        'merchantId': merchantId,
        'storeId': storeIdFixed,
        'paymentStatuses': paymentStatusId === "" ? [] : [parseInt(paymentStatusId)],
        'orderStatuses': pageLoad ? [3, 4, 5, 6, 8, 9] : orderSellStatusId === "" ? [] : [parseInt(orderSellStatusId)],
        "orderTypes": orderTypeId === "" ? [] : [parseInt(orderTypeId)],
        'lastStartDate': lastStartDate,
        'lastEndDate': lastEndDate,
        "maskedPAN": salesCardNumber,
        // "transactionTypes": salesCardType,
        "serviceCharge": serviceChargeValue,
        "serviceChargeId": serviceChargeIdValue,
        "revenueCenterId": revenueCenterId,
        "discount": discountValue,
        "discountId": discountIdValue,
        "appliedAs": appliedAs,
        // "showOpenOrders":this.showOpenOrders
      }
    };
    return this.http.post<any>(
      this.GLOBALURL + '/reports/query',
      params,
      httpOptions
    ).pipe(shareReplay(1));
  }

  convertStringToNumber = (arrayString) => {
    let numberArray = arrayString.map((element) => {
      return parseInt(element)
    });
    return numberArray;
  }
  //sending login credentials to server for validate
  getEmployees(): Observable<any> {
    const headerToken = { 'Content-Type': 'application/json', 'Authorization': this.cookieService.get('token') }
    const httpOptions = { headers: new HttpHeaders(headerToken) };
    return this.http.get<any>(
      this.GLOBALURL + '/employee',
      httpOptions
    ).pipe(shareReplay(1));
  }

  // getSingleOrderPayments(paymentId: any): Observable<any> {
  //   const headerToken = { 'Content-Type': 'application/json', 'Authorization': this.cookieService.get('token') }
  //   const httpOptions = {
  //     headers: new HttpHeaders(headerToken),
  //     params: new HttpParams().set('paymentId', paymentId).set('editOrder', 1)
  //   };
  //   return this.http.get<any>(
  //     this.GLOBALURL + '/payment',
  //     httpOptions
  //   ).pipe(shareReplay(1));
  // }
  // buttonApprove: boolean = false;
  // account = this.cookieService.get("loggedAccount");
  // isVerificationOk(code): Observable<any> {
  //   console.log("account", this.account);
  //   const headerToken = { 'Content-Type': 'application/json', 'Authorization': this.cookieService.get('token') }
  //   const httpOptions1 = { headers: new HttpHeaders(headerToken) };
  //   var parameters: any;
  //   if (
  //     /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
  //       this.account
  //     )
  //   ) {
  //     parameters = {
  //       otp: code,
  //       username: this.account,
  //     };
  //   } else {
  //     parameters = {
  //       otp: code,
  //       mobile: this.account,
  //     };
  //   }
  //   console.log("parameters", parameters);
  //   return this.http
  //     .post(this.GLOBALURL + "/user/verifyOTP", parameters, httpOptions1)
  // }

  getSalesTransactionsEmployee(): Observable<any> {
    const storeId = this.cookieService.get('storeId');
    const merchantId = this.cookieService.get('merchantId');
    const headerToken = { 'Content-Type': 'application/json', 'Authorization': this.cookieService.get('token') }
    const httpOptions = {
      headers: new HttpHeaders(headerToken),
      params: new HttpParams().set('storeId', storeId).set('merchantId', merchantId)
    };
    return this.http.get<any>(
      this.GLOBALURL + '/employee',
      httpOptions
    ).pipe(shareReplay(1));
  }
  getSalesTransactions(
    resultEmployeeIds: any,
    resultRevenueIds: any,
    startDate: any,
    endDate: any,
    startTime: any,
    endTime: any,
    startDateDisplay: any,
    endDateDisplay: any,
    merchantId: any,
    storeIdFixed: any,
    paymentStatusId: any,
    pageLoad: Boolean,
    orderSellStatusId: any,
    orderTypeId: any,
    page: any,
    items: any,
    resultPaymentsNames: any,
    salesCardNumber: any,
    salesCardType: any,
    resultRevenueIds2: any,
    serviceCharge:any,
    serviceChargeId: any,
    discount:any,
    discountId: any,
    revenueCenterId: any,
    appliedAs:any
  ): Observable<any> {
    var offset = 0;
    var payments = [];
    var paymentByStatus = [];
    var numberPaymentsPaid = [];
    var subTotalCollected = 0;
    var totalCollected = 0;
    var params = new HttpParams()
      .set("startDate", startDateDisplay)
      .set("endDate", endDateDisplay)

    if (
      (startTime !== "00:00" && endTime === "23:59") ||
      (endTime !== "23:59" && startTime === "00:00") ||
      (startTime !== "00:00" && endTime !== "23:59")
    ) {
      params = params.set("startTime", startTime);
      params = params.set("endTime", endTime);
    }

    if (paymentStatusId !== "") {
      params = params.append("paymentStatuses", paymentStatusId);
    }
    if (orderSellStatusId !== "") {
      params = params.append("orderStatuses", orderSellStatusId);
    }
    if(appliedAs !== "" && appliedAs !== undefined){
      params = params.append("appliedAs", appliedAs);
    }
    // check and combine the values for resultRevenueIds and salesCardType
    // resultRevenueIds is the payment method ids
    // salesCardType is the transaction types
    let paymentMethodIdsValues: any;
    if(resultRevenueIds?.length > 0 && salesCardType != "") {
      paymentMethodIdsValues = resultRevenueIds.concat([parseInt(salesCardType)]);
    } else if (resultRevenueIds?.length == 0 && salesCardType != "") {
      paymentMethodIdsValues = [parseInt(salesCardType)];
    } else if (resultRevenueIds?.length > 0 && salesCardType == "") {
      paymentMethodIdsValues = resultRevenueIds;
    } else {
      paymentMethodIdsValues = [];
    }
    // set the params transactionTypes to paymentMethodIdsValues
    if(paymentMethodIdsValues?.length > 0 || paymentMethodIdsValues != "") {
      params = params.append("transactionTypes", paymentMethodIdsValues);
    }
    // if (resultRevenueIds2 !== "" || resultRevenueIds.length > 0) {
    //   params = params.append("transactionTypes", resultRevenueIds2);
    // }
    // if (salesCardType !== "") {
    //   params = params.append("transactionTypes", salesCardType);
    // }
    if (
      resultPaymentsNames !== "" ||
      resultEmployeeIds.length > 0
    ) {
      params = params.append("serverIds", resultPaymentsNames);
    }
    if (salesCardNumber !== "") {
      params = params.append("maskedPAN", salesCardNumber);
    }

    if (orderTypeId !== "") {
      params = params.append("orderTypes", orderTypeId);
    }
    // if (startTime !== "" && endTime !== "") {
    //   params = params.append("startTime", startTime);
    //   params = params.append("endTime", endTime);
    // }

    // console.log('test', storeIdFixed)

    if (storeIdFixed !== "*") {
      params = params.append("storeId", storeIdFixed);
    }

    if(serviceCharge) {
      params = params.append('serviceCharge', serviceCharge)
      params = params.append("serviceChargeId", serviceChargeId);
      params = params.append("merchantId", merchantId);
    } else if (discount) {
      params = params.append('discount', discount)
      params = params.append("discountId", discountId);
      params = params.append("merchantId", merchantId);
    }
    if(revenueCenterId != '') {
      params = params.append("revenueCenterId", revenueCenterId);
    }//Please do not remove this as it requires for location filter
    params = params.append("page", page);
    params = params.append("items", items);
    const headerToken = { 'Content-Type': 'application/json', 'Authorization': this.cookieService.get('token') }
    const httpOptions2 = {
      headers: new HttpHeaders(headerToken),
      params: params
    };
    return this.http
      .get(this.GLOBALURL + "/payment", httpOptions2);
  }
  sortingColumnsByNumber(arr, attr, attr2, order1) {
    return arr.sort((first, second) => {
      if (parseInt(first[attr][attr2]) < parseInt(second[attr][attr2])) {
        if (order1) {
          return 1;
        } else {
          return -1;
        }
      } else if (
        parseInt(first[attr][attr2]) > parseInt(second[attr][attr2])
      ) {
        if (order1) {
          return -1;
        } else {
          return 1;
        }
      }
    });
  }

  /**
   * Export File
   * @param payload
   */
  exportReport(payload) {
    const headerToken = { 'Content-Type': 'application/json', 'Authorization': this.cookieService.get('token') }
    const httpOptions = {headers: new HttpHeaders(headerToken)};
    return this.http
    .post(this.GLOBALURL + '/reports/export', payload, httpOptions)
  }

//   exportReport = (
//     file: String,
//     merchantId: any,
//     resultEmployeeIds: any,
//     resultRevenueIds: any,
//     startDateDisplay:any,
//     endDateDisplay:any,
//     startDate:any,
//     endDate: any,
//     storeIdFixed: any
//   ) => {
//     // this.store.dispatch(setLoadingSpinner({ status: false }));
//     const headerToken = { 'Content-Type': 'application/json', 'Authorization': this.cookieService.get('token') }
//     const params1 = new HttpParams()
//         .set("startDate", startDateDisplay)
//         .set("endDate", endDateDisplay)
//     const params = {
//         'reportName': 'payments_report',
//         'format': file,
//         'merchantId': merchantId,
//         'query': {
//             "employeeIds": resultEmployeeIds === "" ? [] : resultEmployeeIds,
//             "paymentMethodIds": resultRevenueIds === "" ? [] : this.convertStringToNumber(resultRevenueIds),
//             // 'startDate': moment(startDate).format('X'),
//             'startDate': this.timezoneService.get()(startDate).format('X'),
//             // 'endDate': moment(endDate).format('X'),
//             'endDate': this.timezoneService.get()(endDate).format('X'),
//             'merchantId': merchantId,
//             'storeId': storeIdFixed,
//         }
//     }
//     const httpOptions2 = {
//       headers: new HttpHeaders(headerToken),
//       params: params1
//     };

//     this.http
//         .post(this.GLOBALURL + '/reports/export', params, httpOptions2)
//         .subscribe((response: any) => {

//             if (response.error === true) {
//                 this.toastr.error(response.message);
//                 this.store.dispatch(setLoadingSpinner({ status: false }));

//             } else {
//                 // const { base64, name, contentType } = response.data;
//                 // const blob = b64toBlob(base64, contentType);
//                 // FileSaver.saveAs(blob, name);
//                 this.store.dispatch(setLoadingSpinner({ status: false }));
//                 // this.toastr.success('File exported');
//                 let headers = new HttpHeaders();
//                 headers = headers.set('Accept', 'application/pdf');
//                 this.http.get(response.data.url, { headers: headers, responseType: 'blob' }).subscribe(
//                     (blob: Blob): void => {
//                         var blob = new Blob([blob], { type: response.data.contentType });
//                         var url = window.URL.createObjectURL(blob);
//                         var anchor = document.createElement("a");
//                         anchor.download = response.data.name;
//                         anchor.href = url;
//                         anchor.click();
//                         this.toastr.success('File exported');
//                       }
//                 );
//             }
//         });
// }
  closeBatch(payload) {
    return this.http.post<any>(
      this.GLOBALURL + '/payment/close-batch',
      payload
    )
  }
}
