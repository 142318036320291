import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject, Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { ApiService } from "src/app/services/api";
@Injectable({
  providedIn: 'root'
})
export class ProfileService {
  GLOBALURL: String ;
  storeId = this.cookieService.get("storeId");
  merchantId = this.cookieService.get("merchantId");
  employeeId = this.cookieService.get("employeeId");
  private profileImageSource = new BehaviorSubject<string>(''); // Initialize with a default image or empty string
  currentProfileImage = this.profileImageSource.asObservable();
  constructor(
    private http: HttpClient,
    private cookieService: CookieService,
    private ApiService: ApiService) {

      this.GLOBALURL = this.ApiService.getBaseURL()
    }

    updateProfileImage(imageUrl: string) {
      this.profileImageSource.next(imageUrl);
    }

    getProfile(employeeId: any): Observable<any> {
      return this.http.get<any>(
        this.GLOBALURL + `/employee?employeeId=${employeeId}`, {
          headers: new HttpHeaders({
            "Content-Type": "application/json",
            Authorization: this.cookieService.get("token"),
          }),
        }
      ).pipe(shareReplay(1));
    }

    updateProfile(payload) {
      return this.http.put(
        this.GLOBALURL + `/employee?employeeId=${payload.employeeId}`,
        payload,
        {
          headers: new HttpHeaders({
            "Content-Type": "application/json",
            Authorization: this.cookieService.get("token"),
          }),
        }
      );
    }

    changePassword(payload) {
      return this.http.post(
        this.GLOBALURL + "/user/changepassword",
        payload,
        {
          headers: new HttpHeaders({
            "Content-Type": "application/json",
            Authorization: this.cookieService.get("token"),
          }),
        }
      );
    }

    changePasswordWithInvitationLink(payload, token) {
      return this.http.post(
        this.GLOBALURL + "/user/changepassword",
        payload,
        {
          headers: new HttpHeaders({
            "Content-Type": "application/json",
            Authorization: token,
          }),
        }
      );
    }

    changePin(payload) {
      return this.http.put(
        this.GLOBALURL + "/employee",
        payload,
        {
          headers: new HttpHeaders({
            "Content-Type": "application/json",
            Authorization: this.cookieService.get("token"),
          }),
        }
      );
    }

    verifyEmailOrPhone(payload) {
      return this.http.post(
        this.GLOBALURL + `/user/verify?employeeId=${this.employeeId}`,
        payload,
        {
          headers: new HttpHeaders({
            "Content-Type": "application/json",
            Authorization: this.cookieService.get("token"),
          }),
        }
      );
    }

    verifyPhoneOTP(payload) {
      return this.http.put(
        this.GLOBALURL + `/user/verify?employeeId=${this.employeeId}`,
        payload,
        {
          headers: new HttpHeaders({
            "Content-Type": "application/json",
            Authorization: this.cookieService.get("token"),
          }),
        }
      );
    }

    //to check if mobile number or email is in use or not
    checkUser(payload){
      return this.http.post(
        this.GLOBALURL + "/user/check",
        payload,
        {
          headers: new HttpHeaders({
            "Content-Type": "application/json",
            Authorization: this.cookieService.get("token"),
          }),
        }
      );
    }

    getLoginSessions(payload){
      return this.http.post(
        this.GLOBALURL + "/user/usersession",
        payload,
        {
          headers: new HttpHeaders({
            "Content-Type": "application/json",
            Authorization: this.cookieService.get("token"),
          }),
        }
      );
    }

    deleteLoginSessions(payload){
      return this.http.post(
        this.GLOBALURL + "/user/deletesession",
        payload,
        {
          headers: new HttpHeaders({
            "Content-Type": "application/json",
            Authorization: this.cookieService.get("token"),
          }),
        }
      );
    }

    logoutAll(payload){
      return this.http.post(
        this.GLOBALURL + "/user/logoutall",
        payload,
        {
          headers: new HttpHeaders({
            "Content-Type": "application/json",
            Authorization: this.cookieService.get("token"),
          }),
        }
      );
    }

    // updatePassword(payload) {
    //   return this.http.post(
    //     this.GLOBALURL + "/user/updateEmployeePassword",
    //     payload,
    //     {
    //       headers: new HttpHeaders({
    //         "Content-Type": "application/json",
    //       }),
    //     }
    //   );
    // }
}
