import { Injectable, NgZone } from '@angular/core';

import { Observable, Subject, Subscription, interval } from 'rxjs';
import { map, filter } from 'rxjs/operators';

interface Event {
    type: string;
    payload?: any;
}

type EventCallback = (payload: any) => void;

@Injectable({
    providedIn: 'root'
})
export class EventService {
    private handler = new Subject<Event>();

    constructor(private ngZone:NgZone) { }

    /**
     * Broadcast the event
     * @param type type of event
     * @param payload payload
     */
    broadcast(type: string, payload = {}) {
        this.handler.next({ type, payload });
    }

    /**
     * Subscribe to event
     * @param type type of event
     * @param callback call back function
     */
    subscribe(type: string, callback: EventCallback): Subscription {
        return this.handler.pipe(
            filter(event => event.type === type)).pipe(
                map(event => event.payload))
            .subscribe(callback);
    }

    getLocalStorageValue(): string | null {
      try {
        const Details = JSON.parse(localStorage.getItem('subDivPath'))
        return Details?.show_menu ?? null;
      } catch {
        return null;
      }
    }

      watchLocalStorageValue(): Observable<string | null> {
        return new Observable<string | null>((observer) => {
          interval(1000).subscribe(() => {
            this.ngZone.run(() => {
              const newValue = this.getLocalStorageValue();
              observer.next(newValue);
            });
          });
        });
      }


  getActiveMenuClass(): string | null {
    return localStorage.getItem('ActivemenuClass');
  }

  watchActiveMenuCalss(): Observable<string | null> {
    return new Observable<string | null>((observer) => {
      interval(1000).subscribe(() => {
        this.ngZone.run(() => {
          const newValue = this.getActiveMenuClass();
          observer.next(newValue);
        });
      });
    });
  }
}
