import { Component, OnInit } from "@angular/core";
import { FormBuilder, NgForm, Validators } from "@angular/forms";
import {
  NgbActiveModal,
  NgbModal,
  NgbModalConfig,
} from "@ng-bootstrap/ng-bootstrap";
import { CookieService } from "ngx-cookie-service";
import { AlertService } from "src/app/core/services/AlertService/alert.service";
import { ProfileService } from "src/app/core/services/profile-service/profile.service";
import { ApiService } from "src/app/services/api";

@Component({
  selector: "app-change-password",
  templateUrl: "./change-password.component.html",
  styleUrls: ["./change-password.component.scss"],
  providers: [NgbModalConfig, NgbModal],
})
export class ChangePasswordComponent implements OnInit {
  isLoading = false;

  isShowCurrentPassword = false;
  isShowPassword = false;
  isShowConfirmPassword = false;

  constructor(
    private activeModal: NgbActiveModal,
    private cookieService: CookieService,
    private toastr: AlertService,
    private profileService: ProfileService,
    private ApiService: ApiService,
  ) {}

  ngOnInit(): void {}

  // close modal
  closeModal() {
    this.activeModal.close();
  }
  issnewhowPassword = false;
  newConfirmPassword = false;
  NewPIN = false;
  showCurrentPassword() {
    this.NewPIN = !this.NewPIN;
    this.isShowCurrentPassword = !this.isShowCurrentPassword;
  }
  showPassword() {
    this.issnewhowPassword = !this.issnewhowPassword;
    this.isShowPassword = !this.isShowPassword;
  }
  showConfirmPassword() {
    this.newConfirmPassword = !this.newConfirmPassword;
    this.isShowConfirmPassword = !this.isShowConfirmPassword;
  }

  passwordErrorMessage = "";
  // on save form
  onSubmit(form: NgForm) {
    this.passwordErrorMessage = "";
    // const storeId = this.cookieService.get("storeId");
    // if (storeId === "*") {
    //   this.toastr.clear();
    //   this.toastr.error("Please select a location first.");
    //   return;
    // }

    const { currentPassword, password, confirmPassword } = form.value;

    if (password !== confirmPassword) {
      this.passwordErrorMessage = "";
      form.form.markAllAsTouched();
      return;
    }

    if (!form.valid) {
      this.passwordErrorMessage = "";
      form.form.markAllAsTouched();

      return;
    }

    const payload: any = {
      currentPassword: currentPassword,
      newPassword: password,
      confirmPassword: confirmPassword,
    };

    this.isLoading = true;
    this.profileService.changePassword(payload).subscribe(
      (response: any) => {
        if (response.success) {
          this.passwordErrorMessage = "";
          this.isLoading = false;
          this.toastr.clear();
          this.toastr.success(response.message);
          // this.passwordErrorMessage = response.message;
          this.closeModal();
        } else {
          this.passwordErrorMessage = "";
          this.isLoading = false;
          this.toastr.clear();
          this.toastr.error(response.message);
          // this.passwordErrorMessage = response.message;
        }
      },
      (error) => {
        const message = this.ApiService.errorMessage(error);
        this.isLoading = false;
        this.toastr.clear();
        // this.toastr.error(message);
        this.passwordErrorMessage = message;
      }
    );
  }
}
