<div
  *ngIf="Showmenu === 'reports'"
  class="sub-menu-main"
  [ngClass]="classmenu ? 'hide-sidebar' : ' '"
>
  <div>
    <div class="discription-section">
      <button class="left-menu" (click)="sendDataToTopbar()">
        <i class="fa-solid fa-chevron-left"></i>
      </button>
      <div class="heading-part">
        <!-- <i class="bx bxs-bar-chart-alt-2 main-icon"></i> -->
        <img
          src="assets/images/newsidemenu-image/default/Analytics.svg"
          width="40px"
          height="40px"
          alt=""
        />
        <h4 class="m-0">{{ "Analytics & Reports" | translate | async }}</h4>
      </div>
      <div>
        <p class="f-12 m-0 menu-txt">
          {{ "Data-driven insights displayed visually." | translate | async }}
        </p>
      </div>
    </div>
    <div class="main-menu">
      <div>
        <ul class="m-0 p-0">
          <li>
            <ngb-accordion
              #acc="ngbAccordion"
              [activeIds]="['config-panel-daily-operations-report', 'config-panel-sales', 'config-panel-labour', 'config-panel-exception']"
              [closeOthers]="false"
            >
              <!-- <ngb-panel id="config-panel-daily-operations-report">
                <ng-template ngbPanelTitle>
                  <li>
                    <a
                      (click)="SalesGrossAcive = 'daily-operations-report'"
                      [ngClass]="SalesGrossAcive === 'daily-operations-report' ? 'Activemenu-two' : ''"
                      >{{ "Daily Operation Report" | translate | async }}</a
                    >
                  </li>
                </ng-template>
                <ng-template ngbPanelContent>
                  <div>
                    <li class="list-data">
                      <a
                        (click)="Activemenu('daily-operations-report')"
                        [ngClass]="className === 'daily-operations-report' ? 'Activemenu' : ''"
                        routerLink="/reports/daily-operations-report"
                        >{{ "View Report" | translate | async }}</a
                      >
                    </li>
                  </div>
                </ng-template>
              </ngb-panel> -->

              <ngb-panel id="config-panel-daily-operations-report">
                <ng-template ngbPanelTitle>
                  <li>
                    <a
                      class="fw-bold"
                      (click)="SalesGrossAcive = 'daily-operations-report'"
                      [ngClass]="SalesGrossAcive === 'daily-operations-report' ? 'Activemenu-two' : ''"
                      >{{ "Daily Operation Report" | translate | async }}</a
                    >
                  </li>
                </ng-template>
                <ng-template ngbPanelContent>
                  <div>
                    <li class="list-data">
                      <a
                        (click)="Activemenu('view-report')"
                        [ngClass]="className === 'view-report' ? 'Activemenu' : ''"
                        routerLink="/reports/daily-operations-report/view-report"
                        >{{ "View Report" | translate | async }}</a
                      >
                    </li>
                  </div>

                </ng-template>
              </ngb-panel>

              <ngb-panel id="config-panel-sales">
                <ng-template ngbPanelTitle>
                  <li>
                    <a
                      (click)="SalesGrossAcive = 'GrossSales'"
                      [ngClass]="
                        SalesGrossAcive === 'GrossSales' ? 'Activemenu-two' : ''
                      "
                      class="fw-bold"
                      >{{ "Gross Sales" | translate | async }}</a
                    >
                  </li>
                </ng-template>
                <ng-template ngbPanelContent>
                  <div>
                    <li class="list-data">
                      <a
                        (click)="Activemenu('gross-transaction')"
                        [ngClass]="
                          className === 'gross-transaction' ? 'Activemenu' : ''
                        "
                        routerLink="/reports/sales/gross-transaction"
                        >{{ "Sales Transaction" | translate | async }}</a
                      >
                    </li>
                  </div>
                  <div>
                    <li class="list-data">
                      <a
                        (click)="Activemenu('gross-shift')"
                        [ngClass]="
                          className === 'gross-shift' ? 'Activemenu' : ''
                        "
                        routerLink="/reports/sales/gross-shift"
                        >{{ "Daily Services Transactions" | translate | async }}</a
                      >
                    </li>
                  </div>
                  <div>
                    <li class="list-data">
                      <a
                        (click)="Activemenu('gross-details')"
                        [ngClass]="
                          className === 'gross-details' ? 'Activemenu' : ''
                        "
                        routerLink="/reports/sales/gross-details"
                      >
                        {{ "ltemized Sales" | translate | async }}
                      </a>
                    </li>
                  </div>
                </ng-template>
              </ngb-panel>
              <ngb-panel id="config-panel-labour">
                <ng-template ngbPanelTitle>
                  <li>
                    <a
                      class="fw-bold"
                      (click)="SalesGrossAcive = 'Labor'"
                      [ngClass]="
                        SalesGrossAcive === 'Labor' ? 'Activemenu-two' : ''
                      "
                      >{{ "Labor" | translate | async }}</a
                    >
                  </li>
                </ng-template>
                <ng-template ngbPanelContent>
                  <div>
                    <li class="list-data">
                      <a
                        (click)="Activemenu('cashout')"
                        [ngClass]="className === 'cashout' ? 'Activemenu' : ''"
                        routerLink="/reports/labor/cashout"
                        >{{ "Cashout" | translate | async }}</a
                      >
                    </li>
                  </div>
                  <div>
                    <li class="list-data">
                      <a
                        (click)="Activemenu('payroll')"
                        [ngClass]="className === 'payroll' ? 'Activemenu' : ''"
                        routerLink="/reports/labor/payroll"
                        >{{ "Payroll" | translate | async }}</a
                      >
                    </li>
                  </div>
                </ng-template>
              </ngb-panel>
              <ngb-panel id="config-panel-exception">
                <ng-template ngbPanelTitle>
                  <li class="list-data">
                    <a
                      class="fw-bold"
                      (click)="SalesGrossAcive = 'Exceptions'"
                      [ngClass]="
                        SalesGrossAcive === 'Exceptions' ? 'Activemenu-two' : ''
                      "
                      >{{ "Exceptions" | translate | async }}</a
                    >
                  </li>
                </ng-template>
                <ng-template ngbPanelContent>
                  <div>
                    <li class="list-data">
                      <a
                        (click)="Activemenu('discount')"
                        [ngClass]="className === 'discount' ? 'Activemenu' : ''"
                        routerLink="/reports/exception/discount"
                        >{{ "Discounts" | translate | async }}</a
                      >
                    </li>
                  </div>
                  <div>
                    <li class="list-data">
                      <a
                        (click)="Activemenu('refunds')"
                        [ngClass]="className === 'refunds' ? 'Activemenu' : ''"
                        routerLink="/reports/exception/refunds"
                        >{{ "Refunds" | translate | async }}</a
                      >
                    </li>
                  </div>
                  <div>
                    <li class="list-data">
                      <a
                        (click)="Activemenu('voids-reports')"
                        [ngClass]="
                          className === 'voids-reports' ? 'Activemenu' : ''
                        "
                        routerLink="/reports/exception/voids-reports"
                        >{{ "Voids" | translate | async }}</a
                      >
                    </li>
                  </div>
                  <div>
                    <li class="list-data">
                      <a
                        (click)="Activemenu('cancel-order')"
                        [ngClass]="
                          className === 'cancel-order' ? 'Activemenu' : ''
                        "
                        routerLink="/reports/exception/cancel-order"
                        >{{ "Cancelled Orders" | translate | async }}</a
                      >
                    </li>
                  </div>
                </ng-template>
              </ngb-panel>
              <!-- <ngb-panel id="config-panel-weekly">
                <ng-template ngbPanelTitle>
                  <li>
                    <a
                      (click)="SalesGrossAcive = 'Weekly'"
                      [ngClass]="
                        SalesGrossAcive === 'Weekly' ? 'Activemenu-two' : ''
                      "
                      >{{ "Weekly" | translate | async }}</a
                    >
                  </li>
                </ng-template>
                <ng-template ngbPanelContent>
                  <div>
                    <li class="list-data">
                      <a
                        (click)="Activemenu('weekly-net-sales-report')"
                        [ngClass]="className === 'weekly-net-sales-report' ? 'Activemenu' : ''"
                        routerLink="/reports/weekly/weekly-net-sales-report"
                        >{{ "Net Sales" | translate | async }}</a
                      >
                    </li>
                  </div>
                  <div>
                    <li class="list-data">
                      <a
                        (click)="Activemenu('top-selling-items-report')"
                        [ngClass]="className === 'top-selling-items-report' ? 'Activemenu' : ''"
                        routerLink="/reports/weekly/top-selling-items-report"
                        >{{ "Top Selling Items" | translate | async }}</a
                      >
                    </li>
                  </div>
                  <div>
                    <li class="list-data">
                      <a
                        (click)="Activemenu('guest-count-report')"
                        [ngClass]="className === 'guest-count-report' ? 'Activemenu' : ''"
                        routerLink="/reports/weekly/guest-count-report"
                        >{{ "Guest Count" | translate | async }}</a
                      >
                    </li>
                  </div>
                </ng-template>
              </ngb-panel> -->


              <!-- <ngb-panel id="config-panel-sales1">
                <ng-template ngbPanelTitle>
                  <li>
                    <a
                      (click)="SalesGrossAcive = 'Sales'"
                      [ngClass]="
                        SalesGrossAcive === 'Sales' ? 'Activemenu-two' : ''
                      "
                      >{{ "Sales" | translate | async }}</a
                    >
                  </li>
                </ng-template>
                <ng-template ngbPanelContent>
                  <div>
                    <li class="list-data">
                      <a
                        (click)="Activemenu('sales-summary-report')"
                        [ngClass]="className === 'sales-summary-report' ? 'Activemenu' : ''"
                        routerLink="/reports/sales1/sales-summary-report"
                        >{{ "Sales Summary" | translate | async }}</a
                      >
                    </li>
                  </div>

                  <div>
                    <li class="list-data">
                      <a
                        (click)="Activemenu('orders-report')"
                        [ngClass]="className === 'orders-report' ? 'Activemenu' : ''"
                        routerLink="/reports/sales1/orders-report"
                        >{{ "Orders" | translate | async }}</a
                      >
                    </li>
                  </div>

                  <div>
                    <li class="list-data">
                      <a
                        (click)="Activemenu('payments-report')"
                        [ngClass]="className === 'payments-report' ? 'Activemenu' : ''"
                        routerLink="/reports/sales1/payments-report"
                        >{{ "Payments" | translate | async }}</a
                      >
                    </li>
                  </div>

                  <div>
                    <li class="list-data">
                      <a
                        (click)="Activemenu('sales-breakdown-report')"
                        [ngClass]="className === 'sales-breakdown-report' ? 'Activemenu' : ''"
                        routerLink="/reports/sales1/sales-breakdown-report"
                        >{{ "Sales Breakdwon" | translate | async }}</a
                      >
                    </li>
                  </div>

                  <div>
                    <li class="list-data">
                      <a
                        (click)="Activemenu('sales-summary-report')"
                        [ngClass]="className === 'sales-summary-report' ? 'Activemenu' : ''"
                        routerLink="/reports/sales1/sales-summary-report"
                        >{{ "Sales Summary" | translate | async }}</a
                      >
                    </li>
                  </div>


                  <div>
                    <li class="list-data">
                      <a
                        (click)="Activemenu('hourly-sales-report')"
                        [ngClass]="className === 'hourly-sales-report' ? 'Activemenu' : ''"
                        routerLink="/reports/sales1/hourly-sales-report"
                        >{{ "Hourly Sales" | translate | async }}</a
                      >
                    </li>
                  </div>


                </ng-template>
              </ngb-panel> -->



              <!-- <ngb-panel id="config-panel-payments">
                <ng-template ngbPanelTitle>
                  <li>
                    <a
                      (click)="SalesGrossAcive = 'Payments'"
                      [ngClass]="
                        SalesGrossAcive === 'Payments' ? 'Activemenu-two' : ''
                      "
                      >{{ "Payments" | translate | async }}</a
                    >
                  </li>
                </ng-template>
                <ng-template ngbPanelContent>
                  <div>
                    <li class="list-data">
                      <a
                        (click)="Activemenu('credit-card-report')"
                        [ngClass]="className === 'credit-card-report' ? 'Activemenu' : ''"
                        routerLink="/reports/payments/credit-card-report"
                        >{{ "Credit Card" | translate | async }}</a
                      >
                    </li>
                  </div>
                  <div>
                    <li class="list-data">
                      <a
                        (click)="Activemenu('deposits-report')"
                        [ngClass]="className === 'deposits-report' ? 'Activemenu' : ''"
                        routerLink="/reports/payments/deposits-report"
                        >{{ "Deposits" | translate | async }}</a
                      >
                    </li>
                  </div>
                  <div>
                    <li class="list-data">
                      <a
                        (click)="Activemenu('payments-type-report')"
                        [ngClass]="className === 'payments-type-report' ? 'Activemenu' : ''"
                        routerLink="/reports/payments/payments-type-report"
                        >{{ "Payments Type" | translate | async }}</a
                      >
                    </li>
                  </div>
                </ng-template>
              </ngb-panel> -->

              <!-- <ngb-panel id="config-panel-employee">
                <ng-template ngbPanelTitle>
                  <li>
                    <a
                      (click)="SalesGrossAcive = 'Employee'"
                      [ngClass]="
                        SalesGrossAcive === 'Employee' ? 'Activemenu-two' : ''
                      "
                      >{{ "Employee" | translate | async }}</a
                    >
                  </li>
                </ng-template>
                <ng-template ngbPanelContent>
                  <div>
                    <li class="list-data">
                      <a
                        (click)="Activemenu('time-entries-report')"
                        [ngClass]="className === 'time-entries-report' ? 'Activemenu' : ''"
                        routerLink="/reports/employee/time-entries-report"
                        >{{ "Time Entries" | translate | async }}</a
                      >
                    </li>
                  </div>
                  <div>
                    <li class="list-data">
                      <a
                        (click)="Activemenu('break-entries-report')"
                        [ngClass]="className === 'break-entries-report' ? 'Activemenu' : ''"
                        routerLink="/reports/employee/break-entries-report"
                        >{{ "Break Entries" | translate | async }}</a
                      >
                    </li>
                  </div>
                </ng-template>
              </ngb-panel> -->


              <!-- <ngb-panel id="config-panel-menus">
                <ng-template ngbPanelTitle>
                  <li>
                    <a
                      (click)="SalesGrossAcive = 'Menus'"
                      [ngClass]="
                        SalesGrossAcive === 'Menus' ? 'Activemenu-two' : ''
                      "
                      >{{ "Menus" | translate | async }}</a
                    >
                  </li>
                </ng-template>
                <ng-template ngbPanelContent>

                  <div>
                    <li class="list-data">
                      <a
                        (click)="Activemenu('product-mix-report')"
                        [ngClass]="className === 'product-mix-report' ? 'Activemenu' : ''"
                        routerLink="/reports/menus/product-mix-report"
                        >{{ "Product Mix" | translate | async }}</a
                      >
                    </li>
                  </div>

                  <div>
                    <li class="list-data">
                      <a
                        (click)="Activemenu('menu-dropdown-report')"
                        [ngClass]="className === 'menu-dropdown-report' ? 'Activemenu' : ''"
                        routerLink="/reports/menus/menu-dropdown-report"
                        >{{ "Menu Dropdown" | translate | async }}</a
                      >
                    </li>
                  </div>

                  <div>
                    <li class="list-data">
                      <a
                        (click)="Activemenu('top-menu-item-report')"
                        [ngClass]="className === 'top-menu-item-report' ? 'Activemenu' : ''"
                        routerLink="/reports/menus/top-menu-item-report"
                        >{{ "Top Menus Items" | translate | async }}</a
                      >
                    </li>
                  </div>

                  <div>
                    <li class="list-data">
                      <a
                        (click)="Activemenu('top-modifiers-group-report')"
                        [ngClass]="className === 'top-modifiers-group-report' ? 'Activemenu' : ''"
                        routerLink="/reports/menus/top-modifiers-group-report"
                        >{{ "Top Modifiers Group" | translate | async }}</a
                      >
                    </li>
                  </div>

                  <div>
                    <li class="list-data">
                      <a
                        (click)="Activemenu('top-modifiers-report')"
                        [ngClass]="className === 'top-modifiers-report' ? 'Activemenu' : ''"
                        routerLink="/reports/menus/top-modifiers-report"
                        >{{ "Top Modifiers" | translate | async }}</a
                      >
                    </li>
                  </div>


                  <div>
                    <li class="list-data">
                      <a
                        (click)="Activemenu('item-details-report')"
                        [ngClass]="className === 'item-details-report' ? 'Activemenu' : ''"
                        routerLink="/reports/menus/item-details-report"
                        >{{ "Item Details" | translate | async }}</a
                      >
                    </li>
                  </div>


                  <div>
                    <li class="list-data">
                      <a
                        (click)="Activemenu('modifiers-detail-report')"
                        [ngClass]="className === 'modifiers-detail-report' ? 'Activemenu' : ''"
                        routerLink="/reports/menus/modifiers-detail-report"
                        >{{ "Modifiers Details" | translate | async }}</a
                      >
                    </li>
                  </div>




                </ng-template>
              </ngb-panel> -->


              <!-- <ngb-panel id="config-panel-cash-loss-management">
                <ng-template ngbPanelTitle>
                  <li>
                    <a
                      (click)="SalesGrossAcive = 'Cash-loss-management'"
                      [ngClass]="
                        SalesGrossAcive === 'Cash-loss-management' ? 'Activemenu-two' : ''
                      "
                      > {{ "Cash Loss Management" | translate | async }}</a
                    >
                  </li>
                </ng-template>
                <ng-template ngbPanelContent>
                  <div>
                    <li class="list-data">
                      <a
                        (click)="Activemenu('cash-activity-audit-report')"
                        [ngClass]="className === 'cash-activity-audit-report' ? 'Activemenu' : ''"
                        routerLink="/reports/cash-loss-management/cash-activity-audit-report"
                        > {{ "Cash Activity Audit" | translate | async }}</a
                      >
                    </li>
                  </div>

                  <div>
                    <li class="list-data">
                      <a
                        (click)="Activemenu('cash-drawer-history-report')"
                        [ngClass]="className === 'cash-drawer-history-report' ? 'Activemenu' : ''"
                        routerLink="/reports/cash-loss-management/cash-drawer-history-report"
                        > {{ "Cash Drawer History" | translate | async }}  </a
                      >
                    </li>
                  </div>

                  <div>
                    <li class="list-data">
                      <a
                        (click)="Activemenu('unpaid-orders-report')"
                        [ngClass]="className === 'unpaid-orders-report' ? 'Activemenu' : ''"
                        routerLink="/reports/cash-loss-management/unpaid-orders-report"
                        > {{ "Unpaid Orders" | translate | async }} </a
                      >
                    </li>
                  </div>


                  <div>
                    <li class="list-data">
                      <a
                        (click)="Activemenu('tax-exempt-report')"
                        [ngClass]="className === 'tax-exempt-report' ? 'Activemenu' : ''"
                        routerLink="/reports/cash-loss-management/tax-exempt-report"
                        > {{ "Tax Exempt" | translate | async }} </a
                      >
                    </li>
                  </div>

                </ng-template>
              </ngb-panel> -->


              <!-- <ngb-panel id="config-panel-accounts">
                <ng-template ngbPanelTitle>
                  <li>
                    <a
                      (click)="SalesGrossAcive = 'Accounts'"
                      [ngClass]="
                        SalesGrossAcive === 'Accounts' ? 'Activemenu-two' : ''
                      "
                      >{{ "Accounts" | translate | async }}</a
                    >
                  </li>
                </ng-template>
                <ng-template ngbPanelContent>
                  <div>
                    <li class="list-data">
                      <a
                        (click)="Activemenu('accouting-by-location-report')"
                        [ngClass]="className === 'accouting-by-location-report' ? 'Activemenu' : ''"
                        routerLink="/reports/accounts/accouting-by-location-report"
                        >{{ "Accounting By Location" | translate | async }}</a
                      >
                    </li>
                  </div>
                </ng-template>
              </ngb-panel> -->


              <!-- <ngb-panel id="config-panel-kitchen-operations">
                <ng-template ngbPanelTitle>
                  <li>
                    <a
                      (click)="SalesGrossAcive = 'Kitchen-Operations'"
                      [ngClass]="
                        SalesGrossAcive === 'Kitchen-Operations' ? 'Activemenu-two' : ''
                      "
                      >{{ "Kitchen Operations" | translate | async }}</a
                    >
                  </li>
                </ng-template>
                <ng-template ngbPanelContent>
                  <div>
                    <li class="list-data">
                      <a
                        (click)="Activemenu('ticket-details-report')"
                        [ngClass]="className === 'ticket-details-report' ? 'Activemenu' : ''"
                        routerLink="/reports/kitchen-operations/ticket-details-report"
                        >{{ "Ticket Details" | translate | async }}</a
                      >
                    </li>
                  </div>
                </ng-template>
              </ngb-panel> -->


              <!-- <ngb-panel id="config-panel-guest-engagement">
                <ng-template ngbPanelTitle>
                  <li>
                    <a
                      (click)="SalesGrossAcive = 'guest-engagement'"
                      [ngClass]="
                        SalesGrossAcive === 'guest-engagement' ? 'Activemenu-two' : ''
                      "
                      >{{ "Guest Engagement" | translate | async }}</a
                    >
                  </li>
                </ng-template>
                <ng-template ngbPanelContent>
                  <div>
                    <li class="list-data">
                      <a
                        (click)="Activemenu('guest-sales-summary-report')"
                        [ngClass]="className === 'guest-sales-summary-report' ? 'Activemenu' : ''"
                        routerLink="/reports/guest-engagement/guest-sales-summary-report"
                        >{{ "Guest Sales Summary" | translate | async }}</a
                      >
                    </li>
                  </div>

                  <div>
                    <li class="list-data">
                      <a
                        (click)="Activemenu('customer-product-summary-report')"
                        [ngClass]="className === 'customer-product-summary-report' ? 'Activemenu' : ''"
                        routerLink="/reports/guest-engagement/customer-product-summary-report"
                        >{{ "Customer Product Summary" | translate | async }}</a
                      >
                    </li>
                  </div>



                </ng-template>
              </ngb-panel> -->

            </ngb-accordion>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
<div
  *ngIf="Showmenu === 'menumanagement'"
  class="sub-menu-main"
  [ngClass]="classmenu ? 'hide-sidebar' : ' '"
>
  <div>
    <div class="discription-section">
      <button class="left-menu" (click)="sendDataToTopbar()">
        <i class="fa-solid fa-chevron-left"></i>
      </button>
      <div class="heading-part">
        <img
          src="assets/images/newsidemenu-image/default/bx-file.svg"
          width="40px"
          height="40px"
          alt=""
        />
        <!-- <i class="bx bx-food-menu main-icon"></i> -->

        <h4
          class="m-0 cursor-pointer"
          routerLink="/menumanagement/menus/menu-home"
        >
          {{ "Menu Management" | translate | async }}
        </h4>
      </div>
      <div>
        <p class="f-12 m-0 menu-txt">
          {{ "Effortless control and customization of your menu offerings." | translate | async }}
        </p>
      </div>
    </div>
    <div class="main-menu">
      <div>
        <ul class="m-0 p-0">
          <li>
            <a
              class="fw-bold"
              (click)="Activemenu('menu-builder')"
              routerLink="/menumanagement/menu-builder"
              [ngClass]="className === 'menu-builder' ? 'Activemenu' : ''"
              >{{ "Menu Builder" | translate | async }}</a
            >
          </li>
          <li>
            <a
              class="fw-bold"
              (click)="Activemenu('menus')"
              routerLink="/menumanagement/menus"
              [ngClass]="className === 'menus' ? 'Activemenu' : ''"
              >{{ "Menus" | translate | async }}</a
            >
          </li>

          <li>
            <a
              class="fw-bold"
              (click)="Activemenu('products')"
              routerLink="/menumanagement/products"
              [ngClass]="className === 'products' ? 'Activemenu' : ''"
              >{{ "Products" | translate | async }}</a
            >
          </li>

          <li>
            <a
              class="fw-bold"
              (click)="Activemenu('modifiers')"
              routerLink="/menumanagement/modifiers"
              [ngClass]="className === 'modifiers' ? 'Activemenu' : ''"
              >{{ "Modifiers" | translate | async }}</a
            >
          </li>
          <li>
            <a
              class="fw-bold"
              (click)="Activemenu('Groups-Modifiers-Add-Ons')"
              routerLink="/menumanagement/Groups-Modifiers-Add-Ons"
              [ngClass]="
                className === 'Groups-Modifiers-Add-Ons' ? 'Activemenu' : ''
              "
              >{{ "Groups" | translate | async }}</a
            >
          </li>
          <li>
            <a
              class="fw-bold"
              (click)="className = Activemenu('addons')"
              routerLink="/menumanagement/addons"
              [ngClass]="className === 'addons' ? 'Activemenu' : ''"
              >{{ "Add On" | translate | async }}</a
            >
          </li>
          <li>
            <a
              class="fw-bold"
              (click)="Activemenu('ingredients')"
              routerLink="/menumanagement/ingredients"
              [ngClass]="className === 'ingredients' ? 'Activemenu' : ''"
              >{{ "Default Modifiers" | translate | async }}</a
            >
          </li>
          <li>
            <a
              class="fw-bold"
              (click)="Activemenu('categories')"
              routerLink="/menumanagement/categories"
              [ngClass]="className === 'categories' ? 'Activemenu' : ''"
              >{{ "Categories" | translate | async }}</a
            >
          </li>
          <li>
            <a
              class="fw-bold"
              (click)="Activemenu('courses')"
              routerLink="/menumanagement/courses"
              [ngClass]="className === 'courses' ? 'Activemenu' : ''"
              >{{ "Courses" | translate | async }}
            </a>
          </li>
          <li>
            <a
              class="fw-bold"
              (click)="Activemenu('service-charge')"
              routerLink="/menumanagement/service-charge"
              [ngClass]="className === 'service-charge' ? 'Activemenu' : ''"
              >{{ "Service Charges" | translate | async }}</a
            >
          </li>
          <li>
            <a
              class="fw-bold"
              (click)="Activemenu('discounts')"
              routerLink="/menumanagement/discounts"
              [ngClass]="className === 'discounts' ? 'Activemenu' : ''"
              >{{ "Discounts" | translate | async }}</a
            >
          </li>
          <li>
            <a
              class="fw-bold"
              (click)="Activemenu('taxes')"
              routerLink="/menumanagement/taxes"
              [ngClass]="className === 'taxes' ? 'Activemenu' : ''"
              >{{ (taxAliasValue ? taxAliasValue : 'Taxes') | translate | async }}</a
            >
          </li>
          <li>
            <a
              class="fw-bold"
              (click)="Activemenu('price-comparison')"
              routerLink="/menumanagement/price-comparison"
              [ngClass]="className === 'price-comparison' ? 'Activemenu' : ''"
              >{{ "Price Comparison" | translate | async }}</a
            >
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
<div
  *ngIf="Showmenu === 'productmanagment'"
  class="sub-menu-main"
  [ngClass]="classmenu ? 'hide-sidebar' : ' '"
>
  <div>
    <div class="discription-section">
      <button class="left-menu" (click)="sendDataToTopbar()">
        <i class="fa-solid fa-chevron-left"></i>
      </button>
      <div class="heading-part">
        <img
          src="assets/images/newsidemenu-image/default/bx-file.svg"
          width="40px"
          height="40px"
          alt=""
        />
        <!-- <i class="bx bx-food-menu main-icon"></i> -->

        <h4
          class="m-0 cursor-pointer"
          routerLink="/productmanagment"
        >
          {{ "Product Management" | translate | async }}
        </h4>
      </div>
      <div>
        <p class="f-12 m-0 menu-txt">
          {{ "Effortless control and customization of your menu offerings." | translate | async }}
        </p>
      </div>
    </div>
    <div class="main-menu">
      <div>
        <ul class="m-0 p-0">
          <!-- <li>
            <a
              (click)="Activemenu('menu-builder')"
              routerLink="/menumanagement/menu-builder"
              [ngClass]="className === 'menu-builder' ? 'Activemenu' : ''"
              >Menu Builder</a
            >
          </li> -->
          <!-- <li>
            <a
              (click)="Activemenu('menus')"
              routerLink="/menumanagement/menus"
              [ngClass]="className === 'menus' ? 'Activemenu' : ''"
              >Menus</a
            >
          </li> -->
          <li>
            <a
              (click)="Activemenu('categories')"
              routerLink="/productmanagment/categories"
              [ngClass]="className === 'categories' ? 'Activemenu' : ''"
              >{{ "Categories" | translate | async }}</a
            >
          </li>
          <li>
            <a
              (click)="Activemenu('products')"
              routerLink="/productmanagment/products"
              [ngClass]="className === 'products' ? 'Activemenu' : ''"
              >{{ "Products" | translate | async }}</a
            >
          </li>

          <!-- <li>
            <a
              (click)="Activemenu('modifiers')"
              routerLink="/menumanagement/modifiers"
              [ngClass]="className === 'modifiers' ? 'Activemenu' : ''"
              >Modifiers</a
            >
          </li> -->
          <!-- <li>
            <a
              (click)="Activemenu('Groups-Modifiers-Add-Ons')"
              routerLink="/menumanagement/Groups-Modifiers-Add-Ons"
              [ngClass]="
                className === 'Groups-Modifiers-Add-Ons' ? 'Activemenu' : ''
              "
              >Groups</a
            >
          </li> -->
          <!-- <li>
            <a
              (click)="className = Activemenu('addons')"
              routerLink="/menumanagement/addons"
              [ngClass]="className === 'addons' ? 'Activemenu' : ''"
              >Add On</a
            >
          </li>
          <li>
            <a
              (click)="Activemenu('ingredients')"
              routerLink="/menumanagement/ingredients"
              [ngClass]="className === 'ingredients' ? 'Activemenu' : ''"
              >Ingredients</a
            >
          </li> -->

          <!-- <li>
            <a
              (click)="Activemenu('courses')"
              routerLink="/menumanagement/courses"
              [ngClass]="className === 'courses' ? 'Activemenu' : ''"
              >Courses
            </a>
          </li>-->
          <li>
            <a
              (click)="Activemenu('service-charge')"
              routerLink="/productmanagment/service-charge"
              [ngClass]="className === 'service-charge' ? 'Activemenu' : ''"
              >{{ "Service Charges" | translate | async }}</a
            >
          </li>
          <li>
            <a
              (click)="Activemenu('discounts')"
              routerLink="/productmanagment/discounts"
              [ngClass]="className === 'discounts' ? 'Activemenu' : ''"
              >{{ "Discounts" | translate | async }}</a
              >
          </li>
          <li>
            <a
              (click)="Activemenu('taxes')"
              routerLink="/productmanagment/taxes"
              [ngClass]="className === 'taxes' ? 'Activemenu' : ''"
              >{{ (taxAliasValue ? taxAliasValue : 'Taxes') | translate | async }}</a
            >
          </li>
          <!-- <li>
            <a
              (click)="Activemenu('price-comparison')"
              routerLink="/menumanagement/price-comparison"
              [ngClass]="className === 'price-comparison' ? 'Activemenu' : ''"
              >Price Comparison</a
            >
          </li> -->
        </ul>
      </div>
    </div>
  </div>
</div>
<div
  *ngIf="Showmenu === 'marketing'"
  class="sub-menu-main"
  [ngClass]="classmenu ? 'hide-sidebar' : ' '"
>
  <div>
    <div class="discription-section">
      <button class="left-menu" (click)="sendDataToTopbar()">
        <i class="fa-solid fa-chevron-left"></i>
      </button>
      <div class="heading-part">

        <img src="\assets\images\feature-page-image\Guest\Guest-Marketing.svg" width="40px" height="40px" alt="" />
        <!-- <i class="bx bx-food-menu main-icon"></i> -->

        <h4
          class="m-0 cursor-pointer"
          routerLink="/marketing"
        >
          {{ "Marketing" | translate | async }}
        </h4>
      </div>
      <div>
        <p class="f-12 m-0 menu-txt">
          {{ "Access exciting marketing updates and promotions to engage your
          customers effectively with our Marketing page." | translate | async }}
        </p>
      </div>
    </div>
    <div class="main-menu">
      <div>
        <ul class="m-0 p-0">
          <li>
            <a
              class="fw-bold"
              (click)="Activemenu('newHomepage')"
              routerLink="/marketing/newHomepage"
              [ngClass]="className === 'newHomepage' ? 'Activemenu' : ''"
              >{{ "Overview" | translate | async }}</a
            >
          </li>
          <li>
            <a
              class="fw-bold"
              (click)="Activemenu('createcampaign')"
              routerLink="/marketing/createcampaign"
              [ngClass]="className === 'createcampaign' ? 'Activemenu' : ''"
              >{{ "Create Campaign" | translate | async }}</a
            >
          </li>
          <!-- <li>
            <a
              (click)="Activemenu('newHompage')"
              routerLink="/marketing/newHomepage"
              [ngClass]="className === 'newHmepage' ? 'Activemenu' : ''"
              >{{ "Google reviews" | translate | async }}</a
            >
          </li>
          <li>
            <a
              (click)="Activemenu('newHoepage')"
              routerLink="/marketing/newHomepage"
              [ngClass]="className === 'newHoepage' ? 'Activemenu' : ''"
              >{{ "Settings" | translate | async }}</a
            >
          </li> -->
        </ul>
      </div>
    </div>
  </div>
</div>
<div
  *ngIf="Showmenu === 'sales'"
  class="sub-menu-main"
  [ngClass]="classmenu ? 'hide-sidebar' : ' '"
>
  <div>
    <div class="discription-section">
      <button class="left-menu" (click)="sendDataToTopbar()">
        <i class="fa-solid fa-chevron-left"></i>
      </button>
      <div class="heading-part">
        <img
          src="assets/images/newsidemenu-image/default/sales.svg"
          width="40px"
          height="40px"
          alt=""
        />
        <h4>{{ "Sales" | translate | async }}</h4>
      </div>
      <div>
        <p class="f-12 m-0 menu-txt">
          {{ "Streamlined sales data and performance analysis at your fingertips" | translate | async }}
        </p>
      </div>
    </div>
    <div class="main-menu">
      <div>
        <ul class="m-0 p-0">
          <li>
            <a
              class="fw-bold"
              (click)="Activemenu('transactions')"
              [ngClass]="className === 'transactions' ? 'Activemenu' : ''"
              routerLink="/sales/transactions"
              >{{ "Transactions" | translate | async }}</a
            >
          </li>
          <li>
            <a
              class="fw-bold"
              (click)="Activemenu('orders')"
              [ngClass]="className === 'orders' ? 'Activemenu' : ''"
              routerLink="/sales/orders"
              >{{ "Orders" | translate | async }}</a
            >
          </li>
          <li>
            <a
              class="fw-bold"
              (click)="Activemenu('cashdrawers')"
              [ngClass]="className === 'cashdrawers' ? 'Activemenu' : ''"
              routerLink="/sales/cashdrawers"
              >{{ "Cash Drawers" | translate | async }}</a
            >
          </li>

          <li>
            <a
              class="fw-bold"
              (click)="Activemenu('deposits')"
              [ngClass]="className === 'deposits' ? 'Activemenu' : ''"
              routerLink="/sales/deposits"
              >{{ "Deposits" | translate | async }}</a
            >
          </li>
          <li>
            <a
              class="fw-bold"
              (click)="Activemenu('service-charges')"
              [ngClass]="className === 'service-charges' ? 'Activemenu' : ''"
              routerLink="/sales/service-charges"
              >{{ "Service charges" | translate | async }}</a
            >
          </li>
          <li>
            <a
              class="fw-bold"
              (click)="Activemenu('discounts')"
              [ngClass]="className === 'discounts' ? 'Activemenu' : ''"
              routerLink="/sales/discounts"
              >{{ "Discounts" | translate | async }}</a
            >
          </li>
          <li>
            <a
              class="fw-bold"
              (click)="Activemenu('feedback')"
              [ngClass]="className === 'feedback' ? 'Activemenu' : ''"
              routerLink="/sales/feedback"
              >{{ "Feedback" | translate | async }}</a
            >
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
<div
  *ngIf="Showmenu === 'guests'"
  class="sub-menu-main"
  [ngClass]="classmenu ? 'hide-sidebar' : ' '"
>
  <div>
    <div class="discription-section">
      <button class="left-menu" (click)="sendDataToTopbar()">
        <i class="fa-solid fa-chevron-left"></i>
      </button>
      <div class="heading-part">
        <img
          src="assets/images/newsidemenu-image/default/guest.svg"
          width="40px"
          height="40px"
          alt=""
        />
        <h4>{{ "Guests" | translate | async }}</h4>
      </div>
      <div>
        <p class="f-12 m-0 menu-txt">
          {{ "Enhance guest experience and engagement with our dedicated guest management page" | translate | async }}
        </p>
      </div>
    </div>
    <div class="main-menu">
      <div>
        <ul class="m-0 p-0">
          <li>
            <a
              class="fw-bold"
              (click)="Activemenu('guestbook')"
              [ngClass]="className === 'guestbook' ? 'Activemenu' : ''"
              routerLink="/guests/guestbook"
              >{{ "Guestbook" | translate | async }}</a
            >
          </li>
          <li>
            <a
              class="fw-bold"
              (click)="Activemenu('Loyalty')"
              [ngClass]="className === 'loyalty-campaign' ? 'Activemenu' : ''"
              routerLink="/guests/loyalty-campaign"
              >{{ "Loyalty Campaign" | translate | async }}</a
            >
          </li>
          <li>
            <a
              class="fw-bold"
              (click)="Activemenu('Marketing')"
              [ngClass]="className === 'Marketing' ? 'Activemenu' : ''"
              >{{ "Marketing (Coming Soon)" | translate | async }}</a
            >
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
<div
  *ngIf="Showmenu === 'employees' && !isSuperAdmin"
  class="sub-menu-main"
  [ngClass]="classmenu ? 'hide-sidebar' : ' '"
>
  <div>
    <div class="discription-section">
      <button class="left-menu" (click)="sendDataToTopbar()">
        <i class="fa-solid fa-chevron-left"></i>
      </button>
      <div class="heading-part">
        <img
          src="assets/images/newsidemenu-image/default/workforce.svg"
          width="40px"
          height="40px"
          alt=""
        />
        <!-- <i class="bx bx-user main-icon"></i> -->
        <h4
          class="cursor-pointer"
          routerLink="/employees/employeelist/employee-home"
        >
          {{ "Workforce" | translate | async }}
        </h4>
      </div>
      <div>
        <p class="f-12 m-0 menu-txt">
          {{ "Efficiently manage your team with our user-friendly employee page" | translate | async }}
        </p>
      </div>
    </div>
    <div class="main-menu">
      <div>
        <ul class="m-0 p-0">
          <li>
            <a
              class="fw-bold"
              (click)="Activemenu('employeelist')"
              [ngClass]="className === 'employeelist' ? 'Activemenu' : ''"
              routerLink="/employees/employeelist"
              >{{ "Employees" | translate | async }}</a
            >
          </li>
          <li>
            <a
              class="fw-bold"
              (click)="Activemenu('timesheet')"
              [ngClass]="className === 'timesheet' ? 'Activemenu' : ''"
              routerLink="/employees/timesheet"
              >{{ "Timesheet" | translate | async }}</a
            >
          </li>
          <li>
            <a
              class="fw-bold"
              (click)="Activemenu('jobtypes')"
              [ngClass]="className === 'jobtypes' ? 'Activemenu' : ''"
              routerLink="/employees/jobtypes"
              >{{ "Job Types" | translate | async }}</a
            >
          </li>
          <li>
            <a
              class="fw-bold"
              (click)="Activemenu('roles')"
              [ngClass]="className === 'roles' ? 'Activemenu' : ''"
              routerLink="/employees/roles"
              >{{ "Roles" | translate | async }}</a
            >
          </li>
          <li>
            <a
              class="fw-bold"
              (click)="Activemenu('department')"
              [ngClass]="className === 'department' ? 'Activemenu' : ''"
              routerLink="/employees/department"
              >{{ "Department" | translate | async }}</a
            >
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
<div
  *ngIf="Showmenu === 'restaurant'"
  class="sub-menu-main"
  [ngClass]="classmenu ? 'hide-sidebar' : ' '"
>
  <div>
    <div class="discription-section">
      <button class="left-menu" (click)="sendDataToTopbar()">
        <i class="fa-solid fa-chevron-left"></i>
      </button>
      <div class="heading-part">
        <img
          src="assets/images/newsidemenu-image/default/Restaurant.svg"
          width="40px"
          height="40px"
          alt=""
        />
        <!-- <i class="bx bx-store main-icon"></i> -->
        <h4
          class="cursor-pointer"
          routerLink="/restaurant/general/restaurat-home"
        >
        {{ this.retailView | translate | async}}
        </h4>
      </div>
      <div>
        <p class="f-12 m-0 menu-txt">
          {{ ("Centralized control for " + this.retailView +  "-wide configurations") | translate | async }}
        </p>
      </div>
    </div>
    <div class="main-menu">
      <div>
        <ul class="m-0 p-0">
          <li>
            <a
              class="fw-bold"
              (click)="Activemenu('general')"
              [ngClass]="className === 'general' ? 'Activemenu' : ''"
              routerLink="/restaurant/general"
              >{{ "General" | translate | async }}</a
            >
          </li>
          <li>
            <a
              class="fw-bold"
              (click)="Activemenu('schedule')"
              [ngClass]="className === 'schedule' ? 'Activemenu' : ''"
              routerLink="/restaurant/schedule"
              >{{ "Schedule" | translate | async }}</a
            >
          </li>
          <li>
            <a
              class="fw-bold"
              (click)="Activemenu('revenuecenter')"
              [ngClass]="className === 'revenuecenter' ? 'Activemenu' : ''"
              routerLink="/restaurant/revenuecenter"
            >{{ "Revenue Center" | translate | async }}</a>
          </li>
          <li>
            <a
              class="fw-bold"
              (click)="Activemenu('service-areas')"
              [ngClass]="className === 'service-areas' ? 'Activemenu' : ''"
              routerLink="/restaurant/service-areas"
              >{{ "Service Areas" | translate | async }}</a
            >
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
<div
  *ngIf="Showmenu === 'onlinestore'"
  class="sub-menu-main"
  [ngClass]="classmenu ? 'hide-sidebar' : ' '"
>
  <div>
    <div class="discription-section">
      <button class="left-menu" (click)="sendDataToTopbar()">
        <i class="fa-solid fa-chevron-left"></i>
      </button>
      <div class="heading-part">
        <img
          src="assets/images/newsidemenu-image/default/online-order.svg"
          width="40px"
          height="40px"
          alt=""
        />
        <h4>{{ "Online Ordering" | translate | async }}</h4>
      </div>
      <div>
        <p class="f-12 m-0 menu-txt">
          {{ "Customize and manage your online ordering experience seamlessly" | translate | async }}
        </p>
      </div>
    </div>
    <div class="main-menu">
      <div>
        <ul class="m-0 p-0">
          <li>
            <a
              class="fw-bold"
              (click)="Activemenu('onlinestore')"
              [ngClass]="(className === 'onlinestore' || !className) ? 'Activemenu' : ''"
              routerLink="/onlinestore"
              >{{ "General" | translate | async }}</a
            >
          </li>
          <li>
            <a
              class="fw-bold"
              (click)="Activemenu('store-front-settings')"
              [ngClass]="
                className === 'store-front-settings' ? 'Activemenu' : ''
              "
              routerLink="/onlinestore/store-front-settings"
              >{{ "Storefront Settings" | translate | async }}</a
            >
          </li>
          <li>
            <a
              class="fw-bold"
              (click)="Activemenu('delivery')"
              [ngClass]="className === 'delivery' ? 'Activemenu' : ''"
              routerLink="/onlinestore/delivery"
              >{{ "Delivery" | translate | async }}</a
            >
          </li>
          <li>
            <a
              class="fw-bold"
              (click)="Activemenu('payment')"
              [ngClass]="className === 'payment' ? 'Activemenu' : ''"
              routerLink="/onlinestore/payment"
              >{{ "Payment" | translate | async }}</a
            >
          </li>
          <li>
            <a
              class="fw-bold"
              (click)="Activemenu('schedule')"
              [ngClass]="className === 'schedule' ? 'Activemenu' : ''"
              routerLink="/onlinestore/schedule"
              >{{ "Schedule" | translate | async }}</a
            >
          </li>
          <li>
            <a
              class="fw-bold"
              (click)="Activemenu('support')"
              [ngClass]="className === 'support' ? 'Activemenu' : ''"
              routerLink="/onlinestore/support"
              >{{ "Support" | translate | async }}</a
            >
          </li>
          <li>
            <a
              class="fw-bold"
              (click)="Activemenu('SalesTransaction')"
              (click)="className = 'promo-code'"
              [ngClass]="className === 'promo-code' ? 'Activemenu' : ''"
              routerLink="/onlinestore/promo-code"
              >{{ "Promo Code" | translate | async }}</a
            >
          </li>
          <li>
            <a
              class="fw-bold"
              (click)="Activemenu('qr-code')"
              [ngClass]="className === 'qr-code' ? 'Activemenu' : ''"
              routerLink="/onlinestore/qr-code"
              >{{ "QR Order at Table" | translate | async }}</a
            >
          </li>
          <li>
            <a
              class="fw-bold"
              (click)="Activemenu('driver')"
              [ngClass]="
                className == 'driver' ? 'Activemenu' : ''
              "
              routerLink="/onlinestore/driver"
              >{{ "Driver" | translate | async }}
              </a
            >
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
<div
  *ngIf="Showmenu === 'employees-setup' && !isSuperAdmin"
  class="sub-menu-main"
  [ngClass]="classmenu ? 'hide-sidebar' : ' '"
>
  <div>
    <div class="discription-section">
      <button class="left-menu" (click)="sendDataToTopbar()">
        <i class="fa-solid fa-chevron-left"></i>
      </button>
      <div class="heading-part">
        <img
          src="assets/images/newsidemenu-image/default/employee.svg"
          width="40px"
          height="40px"
          alt=""
        />
        <!-- <i class="bx bx-user main-icon"></i> -->
        <h4
          class="cursor-pointer"
          routerLink="/employees/jobtypes/employee-stup-home"
        >
          {{ "Employee" | translate | async }}
        </h4>
      </div>
      <div>
        <p class="f-12 m-0 menu-txt">{{ "Manage your workforce with ease" | translate | async }} {{ Showmenu }}</p>
      </div>
    </div>
    <div class="main-menu">
      <div>
        <ul class="m-0 p-0">
          <li>
            <a
              class="fw-bold"
              (click)="Activemenu('jobtypes')"
              [ngClass]="className === 'jobtypes' ? 'Activemenu' : ''"
              routerLink="/employees/jobtypes"
              >{{ "Job Types" | translate | async }}</a
            >
          </li>
          <li>
            <a
              class="fw-bold"
              (click)="Activemenu('roles')"
              [ngClass]="className === 'roles' ? 'Activemenu' : ''"
              routerLink="/employees/roles"
              >{{ "Roles" | translate | async }}</a
            >
          </li>
          <li>
            <a
              class="fw-bold"
              (click)="Activemenu('department')"
              [ngClass]="className === 'department' ? 'Activemenu' : ''"
              routerLink="/employees/department"
              >{{ "Department" | translate | async }}</a
            >
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
<div
  *ngIf="Showmenu === 'business-settings'"
  class="sub-menu-main"
  [ngClass]="classmenu ? 'hide-sidebar' : ' '"
>
  <div>
    <div class="discription-section">
      <button class="left-menu" (click)="sendDataToTopbar()">
        <i class="fa-solid fa-chevron-left"></i>
      </button>
      <div class="heading-part">
        <img
          src="assets/images/newsidemenu-image/default/restaurant-seatting.svg"
          width="40px"
          height="40px"
          alt=""
        />
        <h4>{{ this.retailView | translate | async }} {{ "Settings" | translate | async }}</h4>
      </div>
      <div>
        <p class="f-12 m-0 menu-txt">
          {{ ("Tailor your " + this.retailView + "'s preferences and configurations effortlessly") | translate | async }}
        </p>
      </div>
    </div>
    <div class="main-menu">
      <div>
        <ul class="m-0 p-0">
          <li>
            <a
              class="fw-bold"
              (click)="Activemenu('location')"
              [ngClass]="className === 'location' && path != '/business-settings/location/resturant-setting-home' ? 'Activemenu' : ''"
              routerLink="/business-settings/location"
              >{{ "Locations" | translate | async }}</a
            >
          </li>
          <li>
            <a
              class="fw-bold"
              (click)="Activemenu('receipt-builder')"
              [ngClass]="className === 'receipt-builder' ? 'Activemenu' : ''"
              routerLink="/business-settings/receipt-builder"
              >{{ "Receipt Builder" | translate | async }}</a
            >
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
<!-- new loyalty -->

<!-- <div
  *ngIf="Showmenu === 'loyalty'"
  class="sub-menu-main"
  [ngClass]="classmenu ? 'hide-sidebar' : ' '"
>
  <div>
    <div class="discription-section">
      <button class="left-menu" (click)="sendDataToTopbar()">
        <i class="fa-solid fa-chevron-left"></i>
      </button>
      <div class="heading-part">
        <img
          src="assets/images/newsidemenu-image/default/guest.svg"
          width="40px"
          height="40px"
          alt=""
        />
        <h4 class="m-0">Loyalty</h4>
      </div>
      <div>
        <p class="f-12 m-0 menu-txt">
          Enhance guest experience and engagement with our dedicated guest
          management page.
        </p>
      </div>
    </div>
    <div class="main-menu">
      <div>
        <ul class="m-0 p-0">
          <li>
            <ngb-accordion #acc="ngbAccordion" [activeIds]="[]">
              <ngb-panel id="config-panel-sales">
                <ng-template ngbPanelTitle>
                  <li>
                    <a
                      (click)="SalesGrossAcive = 'Loyalty'"
                      [ngClass]="
                        SalesGrossAcive === 'Loyalty' ? 'Activemenu-two' : ''
                      "
                      >Loyalty</a
                    >
                  </li>
                </ng-template>
                <ng-template ngbPanelContent>
                  <div>
                    <li class="list-data">
                      <a
                        (click)="Activemenu('campaign-manage')"
                        [ngClass]="
                          className === 'campaign-manage' ? 'Activemenu' : ''
                        "
                        routerLink="/loyalty/campaign-manage"
                        >Campaign management</a
                      >
                    </li>
                  </div>
                </ng-template>
              </ngb-panel>
            </ngb-accordion>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div> -->

<!-- new loyalty -->
<div
  *ngIf="Showmenu === 'loyalty'"
  class="sub-menu-main"
  [ngClass]="classmenu ? 'hide-sidebar' : ' '"
>
  <div>
    <div class="discription-section">
      <button class="left-menu" (click)="sendDataToTopbar()">
        <i class="fa-solid fa-chevron-left"></i>
      </button>
      <div class="heading-part">
        <img
        src="assets/images/guestbook-book.svg"
        width="40px"
        height="40px"
        alt=""
      />
        <h4>{{ "Loyalty" | translate | async }}</h4>
      </div>
      <div>
        <p class="f-12 m-0 menu-txt">
          {{ "Enhance guest experience and engagement with our dedicated guest management page" | translate | async }}
        </p>
      </div>
    </div>
    <div class="main-menu">
      <div>
        <ul class="m-0 p-0">
          <li>
            <a
              class="fw-bold"
              (click)="Activemenu('campaign-manage')"
              [ngClass]="className === 'campaign-manage' ? 'Activemenu' : ''"
              routerLink="/loyalty/campaign-manage"
              >{{ "Campaign Management" | translate | async }}</a
            >
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>

<!-- Generative AI -->

<div
  *ngIf="Showmenu === 'generativeai'"
  class="sub-menu-main"
  [ngClass]="classmenu ? 'hide-sidebar' : ' '"
>
  <div>
    <div class="discription-section">
      <button class="left-menu" (click)="sendDataToTopbar()">
        <i class="fa-solid fa-chevron-left"></i>
      </button>
      <div class="heading-part">
        <img
          src="assets/images/newsidemenu-image/default/generativeai.svg"
          width="40px"
          height="40px"
          alt=""
        />
        <h4>{{ "Generative AI" | translate | async }}</h4>
      </div>
      <div>
        <p class="f-12 m-0 menu-txt">
          {{ "Generation of dynamic and informative content" | translate | async }}
        </p>
      </div>
    </div>
    <div class="main-menu">
      <div>
        <ul class="m-0 p-0">
          <li>
            <a
              class="fw-bold"
              (click)="Activemenu('maya-ai')"
              [ngClass]="
                className === 'maya-ai' ? 'Activemenu' : ''
              "
              routerLink="/generativeai/maya-ai"
              >{{ "Home" | translate | async }}</a
            >
          </li>
          <li>
            <a
              class="fw-bold"
              (click)="Activemenu('competitive-analysis')"
              [ngClass]="
                className === 'competitive-analysis' ? 'Activemenu' : ''
              "
              routerLink="/generativeai/competitive-analysis"
              >{{ "Competitive Price analysis" | translate | async }}</a
            >
          </li>

        </ul>
      </div>
    </div>
  </div>
</div>

<!-- integration -->
<div
  *ngIf="Showmenu === 'integration-marketplace'"
  class="sub-menu-main"
  [ngClass]="classmenu ? 'hide-sidebar' : ' '"
>
  <div>
    <div class="discription-section">
      <button class="left-menu" (click)="sendDataToTopbar()">
        <i class="fa-solid fa-chevron-left"></i>
      </button>
      <div class="heading-part">
        <img
          src="assets/images/newsidemenu-image/default/integration.svg"
          width="40px"
          height="40px"
          alt=""
        />
        <h4>{{ "Integrations" | translate | async }}</h4>
      </div>
      <div>
        <p class="f-12 m-0 menu-txt">
          {{ "Connect eatOS with 3rd party applications to seamlessly manage your restaurants data from a single point." | translate | async }}
        </p>
      </div>
    </div>
    <div class="main-menu">
      <div>
        <ul class="m-0 p-0">
          <li>
            <a
              class="fw-bold"
              (click)="Activemenu('MyIntegration')"
              [ngClass]="(className === 'integration-marketplace' || !className) ? 'Activemenu' : ''"
              routerLink="/integration-marketplace"
            >
              {{ "My Integration" | translate | async }}</a
            >
          </li>

        </ul>
      </div>
    </div>
  </div>
</div>

<!-- Merchants -->

 <!-- Merchants -->
