import { AfterViewInit, Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { EventService } from '../../core/services/event.service';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { LAYOUT_WIDTH, SIDEBAR_TYPE, TOPBAR } from '../layouts.model';
import { earningLineChart, salesAnalyticsDonutChart } from './data';
import { ChartType, ChatMessage, ThreadMessage } from './saas.model';
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { ApiService } from "src/app/services/api";
import { CookieService } from "ngx-cookie-service";
@Component({
  selector: 'app-rightsidebar',
  templateUrl: './rightsidebar.component.html',
  styleUrls: ['./rightsidebar.component.scss']
})

/**
 * Rightsidebar component
 */
export class RightsidebarComponent implements OnInit {
  @ViewChild('scrollEle') scrollEle;
  @ViewChild('scrollRef') scrollRef;
  GLOBALURL: any;
  isVisible: string;
  attribute: string;
  formData: FormGroup;
  width: string;
  sidebartype: string;
  topbar: string;
  active = 1;
  constructor(public cookieService: CookieService, public http: HttpClient, private ApiService: ApiService, public formBuilder: FormBuilder, private eventService: EventService) {
    this.GLOBALURL = this.ApiService.getBaseURL();
  }
  headerTokenDetails = {
    "Content-Type": "application/json",
    Authorization: this.cookieService.get("token"),
  };
  httpOptions = { headers: new HttpHeaders(this.headerTokenDetails) };
  earningLineChart: ChartType;
  salesAnalyticsDonutChart: ChartType;
  ChatData: ChatMessage[];
  threadData: ThreadMessage[] = []; // Initialize threadData as an empty array
  // Form submit
  chatSubmit: boolean;


  ngOnInit() {
    this.width = LAYOUT_WIDTH;
    this.sidebartype = SIDEBAR_TYPE;
    this.topbar = TOPBAR;

    this._fetchData();

    this.formData = this.formBuilder.group({
      message: ['', [Validators.required]],
      thread_id: [null]
    });

    /**
     * horizontal-vertical layput set
     */
    this.attribute = document.body.getAttribute('data-layout');
    const vertical = document.getElementById('is-layout');
    if (vertical != null) {
      vertical.setAttribute('checked', 'true');
    }
    if (this.attribute == 'horizontal') {
      vertical.removeAttribute('checked');
    }
  }

  /**
   * Hide the sidebar
   */
  public hide() {
    document.body?.classList?.remove('right-bar-enabled');
  }

  /**
   * Change Topbar
   */
  changeTopbar(topbar: string) {
    this.topbar = topbar;
    this.eventService.broadcast('changeTopbar', topbar);
  }

  /**
   * Change the layout onclick
   * @param layout Change the layout
   */
  changeLayout(layout) {
    if (layout.target.checked == true)
      this.eventService.broadcast('changeLayout', 'vertical');
    else
      this.eventService.broadcast('changeLayout', 'horizontal');
  }

  changeWidth(width: string) {
    this.width = width;
    this.eventService.broadcast('changeWidth', width);
  }

  changeSidebartype(sidebar: string) {
    this.sidebartype = sidebar;
    this.eventService.broadcast('changeSidebartype', sidebar);
  }

  /**
  * Returns form
  */
  get form() {
    return this.formData.controls;
  }

  /**
  * Save the message in chat
  */
  // messageSave() {
  //   const message = this.formData.get('message').value;
  //   const currentDate = new Date();
  //   console.log(this.formData.valid)
  //   if (this.formData.valid && message) {
  //     // Message Push in Chat
  //     this.ChatData.push({
  //       align: 'right',
  //       name: 'User',
  //       message
  //     });

  //     this.scrollToBottom();

  //     var params = { "message": message, "tag": [], "level": 0 }
  //     this.http
  //       .post(this.GLOBALURL + "/ai/chat", params, this.httpOptions)
  //       .subscribe(
  //         (response: any) => {
  //           if (response.success === 1) {
  //             this.ChatData.push({
  //               align: 'left',
  //               name: 'Copilot',
  //               level: response.level,
  //               tags: response.tags,
  //               isTags: response.tags.length > 0 ? true : false,
  //               data: response.data,
  //               message: response.message
  //             });
  //             this.scrollToBottom();
  //           } else {
  //             this.ChatData.push({
  //               align: 'left',
  //               name: 'Copilot',
  //               message: 'We apologize, but at this moment, we are unable to generate a response. Please try again later or contact our support team for assistance.',
  //             });
  //           }
  //         },
  //         (error) => {
  //           this.ChatData.push({
  //             align: 'left',
  //             name: 'Copilot',
  //             message: 'We apologize, but at this moment, we are unable to generate a response. Please try again later or contact our support team for assistance.',
  //           });
  //         }
  //       );
  //     this.formData = this.formBuilder.group({
  //       message: null
  //     });
  //   }

  //   this.chatSubmit = true;
  // }

  stopResponding() {
    this.apiCalled = false;
  }
  messageSave() {
    this.threadData = [];
    const message = this.formData.get('message').value;
    const thread_id = this.formData.get('thread_id').value;
    const currentDate = new Date();
    this.apiCalled = true;
    console.log(this.formData.valid)
    if (this.formData.valid && message) {
      // Message Push in Chat
      this.threadData.push({
        align: 'right',
        role: 'user',
        content: {
          "type": "text",
          "text": message
        },
      });

      this.scrollToBottom();

      // this.formData = this.formBuilder.group({
      //   message: null,
      //   thread_id: null,
      // });


      var params = { "message": message, "isTags": false, "thread_id": thread_id }

      this.http
        .post(this.GLOBALURL + "/ai/chat", params, this.httpOptions)
        .subscribe(
          (response: any) => {
            if (response.success === 1) {
              this.apiCalled = false

              this.threadData = response.response;

              console.log(this.threadData);

              this.scrollToBottom();

              // this.formData = this.formBuilder.group({
              //   message: null,
              //   thread_id: response.response.length > 0 ? response.response[0].thread_id : null
              // });


            } else {
              this.threadData.push({
                align: 'left',
                role: 'assistant',
                content: {
                  "type": "text",
                  "text": "Error01"
                },
              });
            }
          },
          (error) => {
            this.threadData.push({
              align: 'left',
              role: 'assistant',
              content: {
                "type": "text",
                "text": "Error02"
              },
            });
          }
        );

      this.formData = this.formBuilder.group({
        message: null,
        thread_id: null,
      });

    }

    this.chatSubmit = true;
  }
  ngAfterViewInit() {
    // this.scrollEle.SimpleBar.getScrollElement().scrollTop = 100;
    // Added Error Handling
    if (this.scrollEle && this.scrollEle?.SimpleBar) {
      this.scrollEle.SimpleBar.getScrollElement().scrollTop = 100;
    }
    // this.scrollRef.SimpleBar.getScrollElement().scrollTop = 200;
    // Added Error Handling
    if (this.scrollRef && this.scrollRef?.SimpleBar) {
      this.scrollRef.SimpleBar.getScrollElement().scrollTop = 200;
    }
  }
  onListScroll() {
    if (this.scrollRef !== undefined) {
      setTimeout(() => {
        this.scrollRef.SimpleBar.getScrollElement().scrollTop =
          this.scrollRef.SimpleBar.getScrollElement().scrollHeight + 1500;
      }, 500);
    }
  }
  apiCalled = false;
  private _fetchData() {
    this.earningLineChart = earningLineChart;
    this.salesAnalyticsDonutChart = salesAnalyticsDonutChart;
    //   this.threadData = [{
    //     "id": "msg_QG2MszLFVjTTCW07c1e0Atro",
    //     "align":"left",
    //     "created_at": 1701089765,
    //     "thread_id": "thread_RPFLSYZH2pwnlc1T6441XWsN",
    //     "role": "assistant",
    //     "content": {
    //         "type": "text",
    //         "text": "Here are the total sales as per product:\n\n| Product | Total Sales |\n| --- | --- |\n| Torta Di Olio | 105.00 |\n| Caponata Siciliana | 140.00 |\n| Oysters | 96.00 |\n| Spiedini De Fontina Di Prosciutto | 60.00 |\n| Bruschetta Di Granchio | 48.00 |\n| Cookies | 548.00 |\n| Italian Green Eggs & Ham | 18.00 |\n| Barbabitole Arrosto\t| 45.00 |\n| Sofia Salumi | 164.67 |\n| Polenta Fra Diavolo | 32.00 |\n| Corona | 16.33 |\n| Apricot Bellini | 28.00 |\n| New Test Item No Variant | 120.00 |\n| Sam Miguel Light | 23.33 |\n| Burrata Di Stagione | 18.00 |\n| Fritto Misto | 104.00 |\n| Lemon Dou | 23.33 |\n| Budino Al Caramella | 120.00 |\n| Gelato | 298.00 |\n| Hawaiian | 8.00 |\n| Monaco Sour | 30.00 |\n| Torta Di Cioccolate | 232.00 |\n| Pepperoni | 8.00 |\n| Salsa Di Fagioli Bianchi Toscani | 15.00 |\n| Cavalo Nero | 28.00 |\n| Crudo Di Pesce | 66.00 |\n| Suppli Al Telefono | 36.00 |\n| Tiramisu | 240.00 |\n| Ricotta Di Pecora | 31.50 |\n| Custom Item | 99.08 |"
    //     },
    //     "assistant_id": "asst_lhI2ALFDDNODbIgzLUXY714F",
    //     "run_id": "run_kQKpj0exzunUGWgBfZdm5dgy"
    // },
    // {
    //     "id": "msg_Ra2cDNWcnayIU295indwi1ni",
    //     "align":"right",
    //     "created_at": 1701089763,
    //     "thread_id": "thread_RPFLSYZH2pwnlc1T6441XWsN",
    //     "role": "user",
    //     "content": {
    //         "type": "text",
    //         "text": "Hi"
    //     },
    //     "assistant_id": null,
    //     "run_id": null
    // }]
  }

  response: boolean = false
  type = "order";

  staticData = {
    "url": "https://eatos-beta-v3.s3.amazonaws.com/v2/eatOS/1699260561827.pdf",
    "name": "eatOS Cafe - Purple Turtle- gross_transaction_report - 11/06/2023.pdf",
    "contentType": "application/pdf"
  }
  // select promt
  // selectPromt(data: any) {
  //   // if(data)
  //   // this.isVisible = 'visible';
  //   // Message Push in Chat
  //   this.type = "inbuilt"
  //   const currentDate = new Date();
  //   this.apiCalled = true;


  //   let message: any
  //   if (data == "gross_transaction_report") {
  //     message = "Gross Sale Transaction Report"
  //   }
  //   else if (data == "enable_orderos_integration") {
  //     message = "Enable orderos integration"
  //   } else if (data == "tap_n_pay_integration") {
  //     message = "Enable orderos integration"
  //   }

  //   this.ChatData.push({
  //     align: 'right',
  //     name: 'User',
  //     message: message
  //   });

  //   this.scrollToBottom();
  //   var params = { "message": message, "isTags": message, "level": 2 }
  //   this.http
  //     .post(this.GLOBALURL + "/ai/chat", params, this.httpOptions)
  //     .subscribe(
  //       (response: any) => {
  //         if (response.success === 1) {
  //           this.ChatData.push({
  //             align: 'left',
  //             name: 'Copilot',
  //             level: response.level,
  //             tags: response.tags,
  //             isTags: response.tags.length > 0 ? true : false,
  //             data: response.data,
  //             message: response.message
  //           });
  //           this.scrollToBottom();
  //         } else {
  //           this.ChatData.push({
  //             align: 'left',
  //             name: 'Copilot',
  //             message: 'We apologize, but at this moment, we are unable to generate a response. Please try again later or contact our support team for assistance.',
  //           });
  //         }
  //       },
  //       (error) => {
  //         this.ChatData.push({
  //           align: 'left',
  //           name: 'Copilot',
  //           message: 'We apologize, but at this moment, we are unable to generate a response. Please try again later or contact our support team for assistance.',
  //         });
  //       }
  //     );

  // }
  // // select promt
  // selectTag(data: any, tag: any) {
  //   // if(data)
  //   // this.isVisible = 'visible';
  //   // Message Push in Chat
  //   this.type = "inbuilt"
  //   const currentDate = new Date();
  //   this.apiCalled = true;


  //   let message: any = tag

  //   this.ChatData.push({
  //     align: 'right',
  //     name: 'User',
  //     message: message
  //   });
  //   this.scrollToBottom();

  //   var params = { "message": message, "tag": tag, "level": data.level }
  //   this.http
  //     .post(this.GLOBALURL + "/ai/chat", params, this.httpOptions)
  //     .subscribe(
  //       (response: any) => {
  //         if (response.success === 1) {
  //           this.ChatData.push({
  //             align: 'left',
  //             name: 'Copilot',
  //             level: response.level,
  //             tags: response.tags,
  //             isTags: response.tags.length > 0 ? true : false,
  //             data: response.data,
  //             message: response.message
  //           });
  //           this.scrollToBottom();
  //         } else {
  //           this.ChatData.push({
  //             align: 'left',
  //             name: 'Copilot',
  //             message: 'We apologize, but at this moment, we are unable to generate a response. Please try again later or contact our support team for assistance.',
  //           });
  //         }
  //       },
  //       (error) => {
  //         this.ChatData.push({
  //           align: 'left',
  //           name: 'Copilot',
  //           message: 'We apologize, but at this moment, we are unable to generate a response. Please try again later or contact our support team for assistance.',
  //         });
  //       }
  //     );

  // }

  selectPromt(data: any) {
    // if(data)
    // this.isVisible = 'visible';
    // Message Push in Chat
    this.type = "inbuilt"
    const currentDate = new Date();
    this.apiCalled = true;


    let message: any
    if (data == "gross_transaction_report") {
      message = "Gross Sale Transaction Report"
    }
    else if (data == "enable_orderos_integration") {
      message = "Enable orderos integration"
    } else if (data == "tap_n_pay_integration") {
      message = "Enable orderos integration"
    }

    this.threadData.push({
      align: 'right',
      role: 'user',
      content: message
    });

    this.scrollToBottom();
    var params = { "message": message, "isTags": true, "level": 2 }
    this.http
      .post(this.GLOBALURL + "/ai/chat", params, this.httpOptions)
      .subscribe(
        (response: any) => {
          if (response.success === 1) {
            this.threadData.push({
              align: 'left',
              role: 'assistant',
              level: response.level,
              tags: response.tags,
              isTags: response.tags.length > 0 ? true : false,
              data: response.data,
              content: response.message
            });
            this.scrollToBottom();
          } else {
            this.threadData.push({
              align: 'left',
              role: 'assistant',
              content: 'We apologize, but at this moment, we are unable to generate a response. Please try again later or contact our support team for assistance.'
            });
          }
        },
        (error) => {
          this.threadData.push({
            align: 'left',
            role: 'assistant',
            content: 'We apologize, but at this moment, we are unable to generate a response. Please try again later or contact our support team for assistance.'
          });
        }
      );

  }
  // select promt
  selectTag(data: any, tag: any) {
    // if(data)
    // this.isVisible = 'visible';
    // Message Push in Chat
    this.type = "inbuilt"
    const currentDate = new Date();
    this.apiCalled = true;


    let message: any = tag

    this.threadData.push({
      align: 'right',
      role: 'user',
      content: message
    });
    this.scrollToBottom();

    var params = { "message": message, "isTags": true, "level": data.level }
    this.http
      .post(this.GLOBALURL + "/ai/chat", params, this.httpOptions)
      .subscribe(
        (response: any) => {
          if (response.success === 1) {
            this.threadData.push({
              align: 'left',
              role: 'user',
              level: response.level,
              tags: response.tags,
              isTags: response.tags.length > 0 ? true : false,
              data: response.data,
              content: response.message
            });
            this.scrollToBottom();
          } else {
            this.threadData.push({
              align: 'left',
              role: 'assistant',
              content: 'We apologize, but at this moment, we are unable to generate a response. Please try again later or contact our support team for assistance.'
            });
          }
        },
        (error) => {
          this.threadData.push({
            align: 'left',
            role: 'assistant',
            content: 'We apologize, but at this moment, we are unable to generate a response. Please try again later or contact our support team for assistance.'
          });
        }
      );

  }
  scrollToBottom() {
    if (this.scrollRef !== undefined) {
      setTimeout(() => {
        this.scrollRef.SimpleBar.getScrollElement().scrollTop =
          this.scrollRef.SimpleBar.getScrollElement().scrollHeight + 1500;
      }, 30);
    }
  }

  downloadFile(file: any) {
    const url = file.url;
    this.http.get(url, { responseType: 'blob' }).subscribe((data: Blob) => {
      const blob = new Blob([data], { type: 'application/octet-stream' });
      const downloadLink = window.document.createElement('a');
      downloadLink.href = window.URL.createObjectURL(blob);
      downloadLink.download = file.name; // Replace with the desired file name and extension
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    });
  }
}
