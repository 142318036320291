import {
  Directive,
  TemplateRef,
  ViewContainerRef,
  Input,
} from '@angular/core';
import { PermissionService } from '../core/services/permission.service';
@Directive({
  selector: '[canAccess]'
})
export class accessDirective {
  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private permissionService: PermissionService
  ) {
  }

  @Input()
  set canAccess(args:any){
    const [permissionName, value] = args
    const permission = this.permissionService.canAccess(permissionName, value)
    permission ? this.viewContainer.createEmbeddedView(this.templateRef) : this.viewContainer.clear();
  }
  
}
