import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { ApiService } from 'src/app/services/api';
import { AppState } from '../../../../store/app.state';

@Injectable({
  providedIn: 'root',
})
export class SalesOrderSummaryService {
  timeoutInterval: any;
  GLOBALURL: String = this.ApiService.getBaseURL()
  constructor(
    private http: HttpClient,
    private store: Store<AppState>,
    private cookieService : CookieService,
    private ApiService: ApiService
    ) { }

  //sending login credentials to server for validate
  getSalesOrderSummary(
    merchantId: any,
    storeId: any,
    employeeSelected: any,
    orderTypeSelected: any,
    orderStatusSelected: any,
    orderNumber: any,
    startDateDisplay: any,
    endDateDisplay: any,
    startTime: any,
    endTime: any,
    lastStartDate: any,
    lastEndDate: any,
  ): Observable<any> {
    console.log("Get sales order summary");
    console.log("START TIME", startTime);
    console.log("END TIME", endTime);
    const headerToken = {'Content-Type': 'application/json', 'Authorization': this.cookieService.get('token')}
    const httpOptions = {headers: new HttpHeaders(headerToken)};
    const params = {
        "reportName": "orders_summary_report",
        "merchantId": merchantId,
        "query": {
            "storeId": storeId,
            "merchantId": merchantId,
            "employeeIds": employeeSelected === '' ? [] : [employeeSelected],
            "orderTypes": orderTypeSelected === '' ? [] : [parseInt(orderTypeSelected)],
            "orderStatuses": orderStatusSelected === '' ? [] : [parseInt(orderStatusSelected)],
            "orderNumber": orderNumber === '' ? null : parseInt(orderNumber),
            "startDate": startDateDisplay,
            "endDate": endDateDisplay,
            // 'startTime': parseInt(startTime),
            // 'endTime': parseInt(endTime),
            'lastStartDate': lastStartDate,
            'lastEndDate': lastEndDate,
        }
    };

    if (
      (startTime !== "00:00" && endTime === "23:59") ||
      (endTime !== "23:59" && startTime === "00:00") ||
      (startTime !== "00:00" && endTime !== "23:59")
    ) {
      params.query['startTime'] = startTime;
      params.query['endTime'] = endTime;
    }

    return this.http.post<any>(
      this.GLOBALURL + '/reports/query',
      params,
      httpOptions
    ).pipe(shareReplay(1));
  }

  // Close Order
  closeOrder(order: any, storeId: string, merchantId: string): Observable<any> {
    const params = {
      orderId: order.orderId,
      orderSellStatus: 4,
      storeId: storeId,
      merchantId: merchantId,
    };
    return this.http.put(this.GLOBALURL + "/order", params);
  }

  /**
   * Export File
   * @param payload
   */
  export(payload) {
    const headerToken = {'Content-Type': 'application/json', 'Authorization': this.cookieService.get('token')}
    const httpOptions = {headers: new HttpHeaders(headerToken)};
    return this.http
    .post(this.GLOBALURL + '/reports/export', payload, httpOptions)
  }
}
