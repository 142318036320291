import {
  setLoadingSpinner,
  setErrorMessage,
} from "../../../store/sharedstate/shared.actions";
import { exhaustMap, map, catchError, tap, mergeMap } from "rxjs/operators";
import {
  locationFetchStart,
  locationFetchSuccess,
  locationSelect,
} from "./location.actions";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { AppState } from "src/app/store/app.state";
import { of } from "rxjs";
import { Router } from "@angular/router";
import { StoreLocationService } from "src/app/core/services/storelocation.service";
import { CookieService } from "ngx-cookie-service";
import { element } from "protractor";
import { locationAutoSelectBroadcastService } from "src/app/core/services/location-auto-select-broadcast.service";
import { AlertService } from "src/app/core/services/AlertService/alert.service";
import { ApiService } from "./../../../../app/services/api";

@Injectable()
export class LocationEffects {
  userData = JSON.parse(localStorage.getItem("userData"));
  loggedInUserStoreId = "";
  constructor(
    private actions$: Actions,
    private toastService: AlertService,
    private store: Store<AppState>,
    private router: Router,
    private locationService: StoreLocationService,
    private cookieService: CookieService,
    private locAutoService: locationAutoSelectBroadcastService,
    private ApiService: ApiService
  ) {
    this.loggedInUserStoreId = this.userData?.response?.storeId;
  }
  timezone: any = this.cookieService.get("timezone");
  storeId: any = this.cookieService.get("storeId");

  newStoreId: any = this.cookieService.get("newStoreId")
    ? this.cookieService.get("newStoreId")
    : "";
  //creating login effect for getting login api reponse
  getStoreLocation$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(locationFetchStart),
      exhaustMap((action) => {
        return this.locationService.getStoreLocation().pipe(
          map((data) => {
            this.store.dispatch(setErrorMessage({ message: "" }));
            let locations = [];
            // if (this.cookieService.get("superAdminPage") === "3") {
            locations = [
              {
                id: "*",
                text: "All Locations",
                currency: "$",
                currencyCode: "",
                logo: "",
                storeThemeColor: "#212121",
                storeTimezone: this.timezone,
                isActive: 1,
              },
            ];
            // }
            if (data.success == 0) {
              this.toastService.error(data.message);
            } else {
              console.log("data", data.response);
              let activeStores = [];
              data.response.forEach((element) => {
                if (element.status == 1) {
                  activeStores.push(element);
                }
              });

              //set default store location if locations = 1
              if (activeStores.length == 1) {
                locations = [];
                this.cookieService.set(
                  "storeId",
                  activeStores[0].storeId,
                  70,
                  "/"
                );
                this.locAutoService.updateApprovalMessage(
                  activeStores[0].storeId
                );
              }
              //set All location to all lacations
              if (activeStores.length > 0 && activeStores.length === 1) {
                locations = [];
              } else if (activeStores.length > 0 && activeStores.length !== 1) {
                locations = [
                  {
                    id: "*",
                    text: "All Locations",
                    currency: "$",
                    currencyCode: "",
                    logo: "",
                    storeThemeColor: "#212121",
                    storeTimezone: this.timezone,
                    isActive: 1,
                  },
                ];
              }

              data.response.forEach((element) => {
                var timezone = "";

                if (element.status == 1) {
                  locations.push({
                    id: element.storeId,
                    text: element.storeName,
                    currency: "",
                    currencyCode: "",
                    logo: element.storeLogo,
                    isDisabled: true,
                    storeThemeColor: element.storeFrontTheme,
                    storeTimezone: element.storeTimezone,
                    storeCountry: element.storeCountry,
                    zipCode: element.storeZipcode,
                    isActive: element.isActive,
                  });
                }
              });

              //set default store location if locations more than 1
              if (locations.length > 1 && this.storeId !== "*") {
                if (this.cookieService.get("newStoreId")) {
                  this.cookieService.set(
                    "storeId",
                    this.cookieService.get("newStoreId"),
                    70,
                    "/"
                  );
                  this.locAutoService.updateApprovalMessageTwo(
                    this.cookieService.get("newStoreId")
                  );
                } else {
                  //this.cookieService.set("storeId", locations[1].id, 70, '/');
                  //this.locAutoService.updateApprovalMessageTwo(locations[1].id);
                }
              }
              localStorage.setItem("locations", JSON.stringify(locations));

              this.locationService.getSelected(locations);
              this.cookieService.set("newStoreId", "", 70, "/");

              return locationFetchSuccess({ locations: locations });
            }
          }),
          catchError((errResp) => {
            //check api call fail due to internet connection loss
            let message = this.ApiService.errorMessage(errResp);
            //stop loading if any error occured
            this.store.dispatch(setLoadingSpinner({ status: false }));
            return of(locationFetchSuccess({ locations: null }));
          })
        );
      })
    );
  });
}
