import { shareReplay } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TopbarService {

  token = this.cookieService.get("token");
  storeId = this.cookieService.get("storeId");
  merchantId = this.cookieService.get("merchantId");

  GLOBALURL: String = environment.globalUrlApi;

  constructor(
    private http: HttpClient,
    private cookieService: CookieService
  ) { }

  quickStoreSettings(payload: any): Observable<any> {
    return this.http.put<any>(`${this.GLOBALURL}/settings`, payload, {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: this.cookieService.get("token"),
      }),
    })
  }

  updateNotification(payload: any): Observable<any> {
    return this.http.put<any>(`${this.GLOBALURL}/push-notification`, payload, {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: this.cookieService.get("token"),
      }),
    })
  }

  getQuickStoreSettings(): Observable<any> {
    return this.http.get<any>(`${this.GLOBALURL}/settings`, {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: this.cookieService.get("token"),
      }),
    }).pipe(shareReplay(1))
  }
}
