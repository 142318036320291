import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AppAliasService {
  private AppAliasService: BehaviorSubject<any>;
  private StoreAliasService: BehaviorSubject<any>;

  constructor() {
    this.AppAliasService = new BehaviorSubject<any>({
    "menuManagement": true,
    "productManagement" :false,
    "hardware" : true,
    "onlineOrdering" : true,
    "type": 'eatos'
  });
  this.StoreAliasService = new BehaviorSubject<any>("");
  }
  getValue(): Observable<any> {
    return this.AppAliasService.asObservable();
  }

  setValue(newValue: any): void {
    this.AppAliasService.next(newValue);
  }
  getStoreValue(): Observable<any> {
    return this.StoreAliasService.asObservable();
  }
  setStoreValue(newValue: any): void {
    this.StoreAliasService.next(newValue);
  }
}