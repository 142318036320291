import { shareReplay } from 'rxjs/operators';
import { ApiService } from 'src/app/services/api';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { Subject } from 'rxjs';
import { AlertService } from './AlertService/alert.service';

@Injectable({
  providedIn: 'root'
})
export class LocationService {
  token = this.cookieService.get("token");
  storeId = this.cookieService.get("storeId");
  merchantId = this.cookieService.get("merchantId");
  isLoadingChangeSub = new Subject<boolean>();

  GLOBALURL = this.ApiService.getBaseURL();
  constructor(
    private http: HttpClient,
    private router: Router,
    private cookieService: CookieService,
    private ApiService: ApiService,
    private toastr: AlertService
  ) { }

  createStoreLocation(payload) {
    // this.isLoadingChangeSub.next(true);
    return this.http
      .post(this.GLOBALURL + "/store", payload, {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
          Authorization: this.token,
        }),
      }).pipe(shareReplay(1))
  }
}
