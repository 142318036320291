import { StoreModule, ActionReducer, MetaReducer } from '@ngrx/store';
import { AUTH_STATE_NAME } from '../account/auth/state/auth.selector';
import { SharedState } from './sharedstate/shared.state';
import { SHARED_STATE_NAME } from './sharedstate/shared.selector';
import { SharedReducer } from './sharedstate/shared.reducer';
import { AuthState } from '../account/auth/state/auth.state';
import { AuthReducer } from '../account/auth/state/auth.reducer';
import { LocationReducer } from '../shared/ui/locationstate/location.reducers';
import { LOCATION_STATE_NAME } from '../shared/ui/locationstate/location.selectors';
import { LocationState } from '../shared/ui/locationstate/location.state';
import { SalesTransactionState } from '../pages/saletransactions/state/transaction.state';
import { SalesTransactionReducer } from '../pages/saletransactions/state/transaction.reducers';
import { SALES_TRANSACTION_SUMMARY_STATE_NAME } from '../pages/saletransactions/state/transaction.selector';
// import { AuthReducer } from '../account/auth/state/auth.reducer';
// import { AuthState } from '../account/auth/state/auth.state';
// import { LOCATION_STATE_NAME } from '../shared/ui/locationstate/location.selectors';
// import { LocationState } from '../shared/ui/locationstate/location.state';
// import { LocationReducer } from '../shared/ui/locationstate/location.reducers';
import { SALES_ORDER_SUMMARY_STATE_NAME } from '../pages/sales/orders/state/salesorders.selectors';
import { SalesOrderState } from '../pages/sales/orders/state/salesorders.state';
import { SalesOrderReducer } from '../pages/sales/orders/state/salesorders.reducers';
// import { SALES_TRANSACTION_SUMMARY_STATE_NAME } from '../pages/sales/transactions/state/transaction.selector';
// import { SalesTransactionState } from '../pages/sales/transactions/state/transaction.state';
// import { SalesTransactionReducer } from '../pages/sales/transactions/state/transaction.reducers';
// import { routerReducer, RouterReducerState } from '@ngrx/router-store';

export interface AppState {
  [SHARED_STATE_NAME]: SharedState;
  [AUTH_STATE_NAME]: AuthState;
  [LOCATION_STATE_NAME]: LocationState;
  [SALES_ORDER_SUMMARY_STATE_NAME]: SalesOrderState;
   [SALES_TRANSACTION_SUMMARY_STATE_NAME]: SalesTransactionState;
//   router: RouterReducerState;
}

export const appReducer = {
  [SHARED_STATE_NAME]: SharedReducer,
  [AUTH_STATE_NAME]: AuthReducer,
  [LOCATION_STATE_NAME]: LocationReducer,
  [SALES_ORDER_SUMMARY_STATE_NAME]: SalesOrderReducer,
  [SALES_TRANSACTION_SUMMARY_STATE_NAME]: SalesTransactionReducer
//   router: routerReducer,
};

export const metaReducers: MetaReducer<any>[] = [];
