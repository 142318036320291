import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SalesOrderState } from './salesorders.state';
export const SALES_ORDER_SUMMARY_STATE_NAME = 'salesOrderSummary';

const getSalesorderSummaryState = createFeatureSelector<SalesOrderState>(SALES_ORDER_SUMMARY_STATE_NAME);


//selector for getting Sales orders summary info 
export const getSalesOrderSummaryData = createSelector(getSalesorderSummaryState, (state) => {
  return state.data 
});

//selector for getting Sales orders meta data info
export const getSalesOrderSummaryMetaInfo = createSelector(getSalesorderSummaryState, (state) => {
  return state.meta
} )

//selector for getting Sales employee data
export const getSalesEmployeeData = createSelector(getSalesorderSummaryState, (state) => {
  return state.employee
} )

//selector for getting Sales orders data
export const getSalesOrdersData = createSelector(getSalesorderSummaryState, (state) => {
  return state.orders
} )
//selector for getting Sales orders data
export const getSalesOrdersMetaData = createSelector(getSalesorderSummaryState, (state) => {
  return state.pageMetaData
} )

//selector for getting Sales orders data
export const getSalesChildOrders = createSelector(getSalesorderSummaryState, (state) => {
  return state.childOrders
} )