import {
  Directive,
  OnInit,
  Renderer2,
  ElementRef,
  HostListener,
  TemplateRef,
  ViewContainerRef,
  Input,
  HostBinding
} from '@angular/core';
@Directive({
  selector: '[hasPermission]'
})
export class permissionDirective {
  private module: string;
  private access: Number;
  constructor(
    private element: ElementRef,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef
  ) {
  }
  userData
  ngOnInit() {

  }

  @Input()
  set hasPermission(val) {
    this.userData = JSON.parse(localStorage.getItem('userData'));
    if (val) {
      this.module = val[0] // module
      this.access = val[1] // access
    }

    this.updateView();
  }

  private updateView() {
    if (this.checkPermission()) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }

  private checkPermission() {
    const accessControls: any = this.userData? this.userData.response.role.permissions : []
    const module: any = accessControls.find(access => access.permissionName === this.module);
    if (this.access == 0 && module && module['permissionValue'] >= 1) {
      return false
    }
    else if (this.access == 0 && !module) {
      return true
    }
    else {
      return (module && module['permissionValue'] >= this.access) ? true : false;
    }

  }
}
