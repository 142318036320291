<!-- ========== Left Sidebar Start ========== -->

<ng-template #contentTemplate>
  <!--- Sidemenu -->
  <div class="" *ngIf="!SendCanavaDetails">
    <div id="sidebar-menu">
      <!-- Left Menu Start -->
      <ul class="metismenu list-unstyled" id="side-menu" #sideMenu>
        <ng-container *ngFor="let item of menuItems; index as i">
          <li
            class="main-title"
            *ngIf="item.isTitle"
            (click)="item.label === 'DASHBOARD' && navigateToHome($event)"
          >
            <!-- (click)="redirectToHome()" -->
            <a
              [href]="item.label === 'DASHBOARD' && '/home'"
              [routerLink]="item.label === 'DASHBOARD' ? '' : item.link"
              *ngIf="hasItems(item)"
              type="button"
              class="is-parent {{
                item.label
              }} pointer-event-none menu-title-active-always border-0"
              [ngClass]="{
                'has-arrow': !item.badge && !item.isTitle,
                'has-dropdown': item.badge
              }"
            >
              <!-- <i class="bx {{item.icon}}" *ngIf="item.icon"></i> -->
              <img src="{{ item.icon }}" alt="" />
              <!-- <span *ngIf="i==0" class="fas fa-cog float-end mt-1 pointer-event-visible" style="font-size: 14px; color: #6a7187;" (click)="openSidebarSettignModal()"></span> -->
              <span class="f-12 fw-700" id="nav-title" *ngIf="!item.home">
                {{ item.label | translate | async }}</span
              >
              <!-- class="pointer-event-visible" -->
              <!-- <span *ngIf="item.home" class="home-redirect" (click)="redirectToHome()"> {{ item.label | translate | async }}</span> -->
              <span
                class="badge rounded-pill float-end bg-{{ item.badge.variant }}"
                *ngIf="item.badge"
                >{{ item.badge.text | translate | async }}</span
              >
            </a>
          </li>

          <li
            *ngIf="item.isTitle"
            (click)="item.label === 'DASHBOARD' && navigateToHome($event)"
          >
            <a
              (click)="AddActiveClass(item, false)"
              [ngClass]="{
                'Activesubmenu Dashboad':
                  ActiveclassName === item.link ||
                  ActiveclassName === '/profile/profile-settings'
              }"
              [routerLink]="item.link === '/home' && (superAdminPage === '3') ? '/overview' : item.link"
              *ngIf="!hasItems(item)"
              class="side-nav-link-ref"
              routerLinkActive="active"
            >
              <div
                class="tooltip-one"
                ngbTooltipClass="custom-tooltip"
                placement="right"
                >
                <!-- ngbTooltip="{{ item.label | translate | async }}" -->
                <div class="sidebar-icon" *ngIf="item.icon">
                  <!-- <i class="bx {{item.icon}}" ></i> -->
                  <div class="sidebar-menu-theme {{ item.color }}">
                    <img src="{{ item.icon }}" alt="" />
                  </div>
                  <!-- <div class="sidebar-menu-theme {{item.color}}" *ngIf="ActiveclassName === item.link || iconeColorChane === 'Dashboard'"
                style="border-radius: 6px !important; width: 36px !important; height: 36px !important; display: flex !important; align-items: center !important; justify-content: center !important; flex-direction: column !important;">
                  <img src="{{item.Activeicon}}" alt="" />
                </div>  -->
                </div>
              </div>

              <span class="dashboard-lable"> {{ item.label | translate | async }}</span>
              <span
                class="badge rounded-pill bg-{{ item.badge.variant }} float-end"
                *ngIf="item.badge"
                >{{ item.badge.text | translate | async }}</span
              >
            </a>
            <!-- </li> -->
            <ul
              *ngIf="hasItems(item)"
              class="cus-d-block sub-menu"
              aria-expanded="false"
              style="width: 100%; padding: 0px 0px 0px 0px !important"
            >
              <li *ngFor="let subitem of item.subItems">
                <a

                  (click)="AddActiveClass(subitem, false)"
                  [ngClass]="
                    ActiveclassName === subitem.link
                      ? 'Activesubmenu ' + iconeColorChane + (subitem.label === 'Chat with us' && ' no-background')
                      : ''
                  "
                  [routerLink]="subitem.link"
                  *ngIf="!hasItems(subitem) && item.label !== 'DASHBOARD' && subitem.visible !== false"
                  accessControl
                  module="{{ subitem.module }}"
                  access="{{ subitem.access }}"
                  class="side-nav-link-ref"
                  [attr.data-parent]="subitem.parentId"
                  routerLinkActive="active"
                >
                  <div
                    class="tooltip-one"
                    ngbTooltipClass="custom-tooltip"
                    placement="right"
                    >
                    <!-- ngbTooltip="{{ subitem.label | translate | async }}" -->
                    <div class="sidebar-icon" *ngIf="subitem.icon">
                      <!-- <i class="bx {{subitem.icon}}" ></i> -->
                      <div class="{{ subitem.label !== 'Chat with us' && subitem.color }}"
                        [ngClass]="subitem.label === 'Chat with us' ? '' : 'sidebar-menu-theme'"
                      >
                        <img src="{{ subitem.icon }}" alt="" [ngStyle]="{ 'height': subitem.label == 'Loyalty' ? '20px' : null }"/>
                      </div>
                      <!-- <div class="sidebar-menu-theme {{subitem.color}}" *ngIf="ActiveclassName === subitem.link"
                        style="width: 36px !important; height: 36px !important;">
                    <img src="{{subitem.Activeicon}}"  alt=""  />
                  </div> -->
                    </div>
                  </div>

                  <span
                    [ngStyle]="{
                      'color':
                        subitem.label === 'Chat with us' && SendChatDetails
                          ? '#666666'
                          : '',
                      'font-weight':
                        subitem.label === 'Chat with us' && SendChatDetails
                          ? '500'
                          : ''
                    }"
                    [ngClass]="
                      subitem.label === 'Chat with us' && !addCssChaticon
                        ? 'Chat-us'
                        : ''
                    "
                    >{{ subitem.label | translate | async }}</span
                  >
                  <span
                    class="badge rounded-pill bg-{{
                      subitem.badge.variant
                    }} float-end"
                    *ngIf="subitem.badge"
                    >{{ subitem.badge.text | translate | async }}</span
                  >
                </a>
                <a

                  *ngIf="hasItems(subitem) && subitem.visible !== false"
                  class="side-nav-link-ref has-arrow"
                  (click)="AddActiveClass(subitem, false)"
                  [ngClass]="
                    ActiveclassName === subitem.link
                      ? 'Activesubmenu ' + iconeColorChane
                      : ''
                  "
                  href="javascript:void(0);"
                  [attr.data-parent]="subitem.parentId"
                  [routerLink]="subitem.link"
                  routerLinkActive="active"
                >
                  <div
                    class="tooltip-one"
                    ngbTooltipClass="custom-tooltip"
                    placement="right"
                    >
                    <!-- ngbTooltip="{{ subitem.label | translate | async }}" -->
                    <div class="sidebar-icon" *ngIf="subitem.icon">
                      <!-- <i class="bx {{subitem.icon}}" ></i> -->
                      <div class="sidebar-menu-theme {{ subitem.color }}">
                        <img src="{{ subitem.icon }}" alt="" />
                      </div>
                      <!-- <div class="sidebar-menu-theme {{subitem.color}}" *ngIf="ActiveclassName === subitem.link"
                  style="width: 36px !important; height: 36px !important;">
                    <img src="{{subitem.Activeicon}}"  alt="" />
                  </div> -->
                    </div>
                  </div>

                  <span>{{ subitem.label | translate | async }}</span>
                </a>
                <ul
                  *ngIf="hasItems(subitem) && subitem.visible !== false"
                  class="sub-menu mm-collapse"
                  aria-expanded="false"
                >
                  <li *ngFor="let subSubitem of subitem.subItems">
                    <a
                      *ngIf="!hasItems(subSubitem)"
                      (click)="chatWithUs(subSubitem)"
                      [attr.data-parent]="subSubitem.parentId"
                      [routerLink]="subSubitem.link"
                      routerLinkActive="active"
                      class="side-nav-link-ref"
                    >
                      <span
                        ><img
                          class="chatlogo"
                          src="../../../assets/images/chatwithuslogo.svg"
                          alt=""
                      /></span>
                      {{ subSubitem.label | translate | async }}
                    </a>
                    <a
                      *ngIf="hasItems(subSubitem)"
                      class="side-nav-link-a-ref has-arrow"
                      href="javascript:void(0);"
                      [attr.data-parent]="subSubitem.parentId"
                    >
                      {{ subSubitem.label | translate | async }}
                    </a>
                    <ul
                      *ngIf="hasItems(subSubitem)"
                      class="sub-menu mm-collapse"
                      aria-expanded="false"
                    >
                      <li *ngFor="let subSubSubitem of subSubitem.subItems">
                        <a
                          [attr.data-parent]="subSubitem.parentId"
                          [routerLink]="subSubSubitem.link"
                          routerLinkActive="active"
                          class="side-nav-link-ref"
                        >
                          {{ subSubSubitem.label | translate | async }}
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
              <!-- </span> -->
              </li>
            </ul>
          </li>
          <li *ngIf="!item.isTitle && !item.isLayout">
            <a
              *ngIf="hasItems(item)"
              type="button"
              class="is-parent"
              [ngClass]="{
                'has-arrow': !item.badge,
                'has-dropdown': item.badge
              }"
            >
              img src="{{ item.icon }}" alt="" />
              <span> {{ item.label | translate | async }}</span>
              <span
                class="badge rounded-pill float-end bg-{{ item.badge.variant }}"
                *ngIf="item.badge"
                >{{ item.badge.text | translate | async }}</span
              >
            </a>

            <a
              (click)="AddActiveClass(item, false)"
              [ngClass]="
                ActiveclassName === item.link
                  ? 'Activesubmenu ' + iconeColorChane
                  : ''
              "
              id="nav-two"
              [routerLink]="item.link"
              *ngIf="!hasItems(item)"
              class="side-nav-link-ref"
              routerLinkActive="active"
            >
              <div
                class="tooltip-one"
                ngbTooltipClass="custom-tooltip"
                placement="right"
                >
                <!-- ngbTooltip="{{ item.label | translate | async }}" -->
                <div class="sidebar-icon" *ngIf="item.icon">
                  <!-- <i class="bx {{item.icon}}" ></i> -->
                  <div class="sidebar-menu-theme {{ item.color }}">
                    <img src="{{ item.icon }}" alt="" />
                  </div>
                  <!-- <div class="sidebar-menu-theme {{item.color}}"  *ngIf="ActiveclassName === item.link"
                  style="width: 36px !important; height: 36px !important;">
                    <img src="{{item.Activeicon}}" />
                  </div> -->
                </div>
              </div>

              <span> {{ item.label | translate | async }}</span>
              <span
                class="badge rounded-pill bg-{{ item.badge.variant }} float-end"
                *ngIf="item.badge"
                >{{ item.badge.text | translate | async }}</span
              >
            </a>

            <ul *ngIf="hasItems(item)" class="sub-menu" aria-expanded="false">
              <li *ngFor="let subitem of item.subItems">
                <a
                  [routerLink]="subitem.link"
                  *ngIf="!hasItems(subitem)"
                  class="side-nav-link-ref"
                  [attr.data-parent]="subitem.parentId"
                  routerLinkActive="active"
                >
                  {{ subitem.label | translate | async }}
                  <span
                    class="badge rounded-pill bg-{{
                      subitem.badge.variant
                    }} float-end"
                    *ngIf="subitem.badge"
                    >{{ subitem.badge.text | translate | async }}</span
                  >
                </a>
                <a
                  *ngIf="hasItems(subitem)"
                  class="side-nav-link-a-ref has-arrow"
                  href="javascript:void(0);"
                  [attr.data-parent]="subitem.parentId"
                >
                  {{ subitem.label | translate | async }}
                </a>
                <ul
                  *ngIf="hasItems(subitem)"
                  class="sub-menu mm-collapse"
                  aria-expanded="false"
                >
                  <li *ngFor="let subSubitem of subitem.subItems">
                    <a
                      [attr.data-parent]="subSubitem.parentId"
                      [routerLink]="subSubitem.link"
                      routerLinkActive="active"
                      class="side-nav-link-ref"
                    >
                      {{ subSubitem.label | translate | async }}
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
        </ng-container>
      </ul>
    </div>
  </div>
  <!-- responivesidebar  -->
  <div class="" *ngIf="SendCanavaDetails">
    <div id="sidebar-menu">
      <!-- Left Menu Start -->
      <ul class="metismenu list-unstyled" id="side-menu" #sideMenu>
        <ng-container *ngFor="let item of menuItems; index as i">
          <li
            class="main-title"
            *ngIf="item.isTitle"
            (click)="item.label === 'DASHBOARD' && navigateToHome($event)"
          >
            <!-- (click)="redirectToHome()" -->
            <div>
              <a
                [href]="item.label === 'DASHBOARD' && '/home'"
                [routerLink]="item.label === 'DASHBOARD' ? '' : item.link"
                *ngIf="hasItems(item)"
                type="button"
                class="is-parent {{
                  item.label
                }} pointer-event-none menu-title-active-always border-0"
                [ngClass]="{
                  'has-arrow': !item.badge && !item.isTitle,
                  'has-dropdown': item.badge
                }"
              >
                <!-- <i class="bx {{item.icon}}" *ngIf="item.icon"></i> -->
                <img src="{{ item.icon }}" alt="" />
                <!-- <span *ngIf="i==0" class="fas fa-cog float-end mt-1 pointer-event-visible" style="font-size: 14px; color: #6a7187;" (click)="openSidebarSettignModal()"></span> -->
                <span class="f-12 fw-700" id="nav-title" *ngIf="!item.home">
                  {{ item.label | translate | async }}</span
                >
                <!-- class="pointer-event-visible" -->
                <!-- <span *ngIf="item.home" class="home-redirect" (click)="redirectToHome()"> {{ item.label | translate | async }}</span> -->
                <span
                  class="badge rounded-pill float-end bg-{{
                    item.badge.variant
                  }}"
                  *ngIf="item.badge"
                  >{{ item.badge.text | translate | async }}</span
                >
              </a>
            </div>
          </li>

          <li
            *ngIf="item.isTitle"
            (click)="item.label === 'DASHBOARD' && navigateToHome($event)"
          >
            <div>
              <a
                (click)="AddActiveClass(item, true)"
                [ngClass]="{
                  'Activesubmenu Dashboad':
                    ActiveclassName === item.link ||
                    ActiveclassName === '/profile/profile-settings'
                }"
                [routerLink]="item.link"
                *ngIf="!hasItems(item)"
                class="side-nav-link-ref"
                routerLinkActive="active"
              >
                <div
                  class="tooltip-one"
                  ngbTooltipClass="custom-tooltip"
                  placement="right"
                  >
                  <!-- ngbTooltip="{{ item.label | translate | async }}" -->
                  <div class="sidebar-icon" *ngIf="item.icon">
                    <!-- <i class="bx {{item.icon}}" ></i> -->
                    <div class="sidebar-menu-theme {{ item.color }}">
                      <img src="{{ item.icon }}" alt="" />
                    </div>
                    <!-- <div class="sidebar-menu-theme {{item.color}}" *ngIf="ActiveclassName === item.link || iconeColorChane === 'Dashboard'"
                    style="border-radius: 6px !important; width: 36px !important; height: 36px !important; display: flex !important; align-items: center !important; justify-content: center !important; flex-direction: column !important;">
                      <img src="{{item.Activeicon}}" alt="" />
                    </div>  -->
                  </div>
                </div>

                <span class="dashboard-lable">
                  {{ item.label | translate | async }}</span
                >
                <span
                  class="badge rounded-pill bg-{{
                    item.badge.variant
                  }} float-end"
                  *ngIf="item.badge"
                  >{{ item.badge.text | translate | async }}</span
                >
              </a>
              <!-- <button>
                <i class="fa-solid fa-chevron-right"></i>
              </button> -->
            </div>
            <!-- </li> -->

            <ul
              *ngIf="hasItems(item)"
              class="cus-d-block sub-menu"
              aria-expanded="false"
              style="width: 100%; padding: 0px 0px 0px 0px !important"
            >
              <li *ngFor="let subitem of item.subItems">
                <div class="d-flex justify-content-between align-items-center">
                  <a
                    (click)="AddActiveClass(subitem, true)"
                    [ngClass]="
                      ActiveclassName === subitem.link
                        ? 'Activesubmenu ' + iconeColorChane
                        : ''
                    "
                    [routerLink]="subitem.link"
                    *ngIf="!hasItems(subitem) && item.label !== 'DASHBOARD'"
                    accessControl
                    module="{{ subitem.module }}"
                    access="{{ subitem.access }}"
                    class="side-nav-link-ref position-relative w-100"
                    [attr.data-parent]="subitem.parentId"
                    routerLinkActive="active"
                  >
                    <div
                      class="tooltip-one"
                      ngbTooltipClass="custom-tooltip"
                      placement="right"
                      >
                      <!-- ngbTooltip="{{ subitem.label | translate | async }}" -->
                      <div class="sidebar-icon" *ngIf="subitem.icon">
                        <!-- <i class="bx {{subitem.icon}}" ></i> -->
                        <div class="sidebar-menu-theme {{ subitem.color }}">
                          <img src="{{ subitem.icon }}" alt="" />
                        </div>
                        <!-- <div class="sidebar-menu-theme {{subitem.color}}" *ngIf="ActiveclassName === subitem.link"
                          style="width: 36px !important; height: 36px !important;">
                      <img src="{{subitem.Activeicon}}"  alt=""  />
                    </div> -->
                      </div>
                    </div>

                    <span
                      [ngClass]="
                        subitem.label === 'Chat with us' && !addCssChaticon
                          ? 'Chat-us'
                          : ''
                      "
                      >{{ subitem.label | translate | async }}</span
                    >
                    <span
                      class="badge rounded-pill bg-{{
                        subitem.badge.variant
                      }} float-end"
                      *ngIf="subitem.badge"
                      >{{ subitem.badge.text | translate | async }}</span
                    >
                    <button *ngIf="subitem.isExpanded" class="right-menu">
                      <i class="fa-solid fa-chevron-right"></i>
                    </button>
                  </a>
                </div>
                <div class="">
                  <a
                    *ngIf="hasItems(subitem)"
                    class="side-nav-link-a-ref has-arrow"
                    (click)="AddActiveClass(subitem, true)"
                    [ngClass]="
                      ActiveclassName === subitem.link
                        ? 'Activesubmenu ' + iconeColorChane
                        : ''
                    "
                    href="javascript:void(0);"
                    [attr.data-parent]="subitem.parentId"
                    [routerLink]="subitem.link"
                    routerLinkActive="active"
                  >
                    <div
                      class="tooltip-one"
                      ngbTooltipClass="custom-tooltip"
                      placement="right"
                      >
                      <!-- ngbTooltip="{{ subitem.label | translate | async }}" -->
                      <div class="sidebar-icon" *ngIf="subitem.icon">
                        <!-- <i class="bx {{subitem.icon}}" ></i> -->
                        <div class="sidebar-menu-theme {{ subitem.color }}">
                          <img src="{{ subitem.icon }}" alt="" />
                        </div>
                        <!-- <div class="sidebar-menu-theme {{subitem.color}}" *ngIf="ActiveclassName === subitem.link"
                    style="width: 36px !important; height: 36px !important;">
                      <img src="{{subitem.Activeicon}}"  alt="" />
                    </div> -->
                      </div>
                    </div>

                    <span>{{ subitem.label | translate | async }}</span>
                  </a>
                </div>
                <ul
                  *ngIf="hasItems(subitem)"
                  class="sub-menu mm-collapse"
                  aria-expanded="false"
                >
                  <li *ngFor="let subSubitem of subitem.subItems">
                    <a
                      *ngIf="!hasItems(subSubitem)"
                      (click)="chatWithUs(subSubitem)"
                      [attr.data-parent]="subSubitem.parentId"
                      [routerLink]="subSubitem.link"
                      routerLinkActive="active"
                      class="side-nav-link-ref"
                    >
                      <span
                        ><img
                          class="chatlogo"
                          src="../../../assets/images/chatwithuslogo.svg"
                          alt=""
                      /></span>
                      {{ subSubitem.label | translate | async }}
                    </a>
                    <a
                      *ngIf="hasItems(subSubitem)"
                      class="side-nav-link-a-ref has-arrow"
                      href="javascript:void(0);"
                      [attr.data-parent]="subSubitem.parentId"
                    >
                      {{ subSubitem.label | translate | async }}
                    </a>
                    <ul
                      *ngIf="hasItems(subSubitem)"
                      class="sub-menu mm-collapse"
                      aria-expanded="false"
                    >
                      <li *ngFor="let subSubSubitem of subSubitem.subItems">
                        <a
                          [attr.data-parent]="subSubitem.parentId"
                          [routerLink]="subSubSubitem.link"
                          routerLinkActive="active"
                          class="side-nav-link-ref"
                        >
                          {{ subSubSubitem.label | translate | async }}
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <li *ngIf="!item.isTitle && !item.isLayout">
            <a
              *ngIf="hasItems(item)"
              type="button"
              class="is-parent"
              [ngClass]="{
                'has-arrow': !item.badge,
                'has-dropdown': item.badge
              }"
            >
              img src="{{ item.icon }}" alt="" />
              <span> {{ item.label | translate | async }}</span>
              <span
                class="badge rounded-pill float-end bg-{{ item.badge.variant }}"
                *ngIf="item.badge"
                >{{ item.badge.text | translate | async }}</span
              >
            </a>

            <div class="d-flex justify-content-between align-items-center">
              <a
                (click)="AddActiveClass(item, true)"
                [ngClass]="
                  ActiveclassName === item.link
                    ? 'Activesubmenu ' + iconeColorChane
                    : ''
                "
                id="nav-two"
                [routerLink]="item.link"
                *ngIf="!hasItems(item)"
                class="side-nav-link-ref position-relative w-100"
                routerLinkActive="active"
              >
                <div
                  class="tooltip-one"
                  ngbTooltipClass="custom-tooltip"
                  placement="right"
                  >
                  <!-- ngbTooltip="{{ item.label | translate | async }}" -->
                  <div class="sidebar-icon" *ngIf="item.icon">
                    <!-- <i class="bx {{item.icon}}" ></i> -->
                    <div class="sidebar-menu-theme {{ item.color }}">
                      <img src="{{ item.icon }}" alt="" />
                    </div>
                    <!-- <div class="sidebar-menu-theme {{item.color}}"  *ngIf="ActiveclassName === item.link"
                    style="width: 36px !important; height: 36px !important;">
                      <img src="{{item.Activeicon}}" />
                    </div> -->
                  </div>
                </div>

                <span> {{ item.label | translate | async }}</span>
                <span
                  class="badge rounded-pill bg-{{
                    item.badge.variant
                  }} float-end"
                  *ngIf="item.badge"
                  >{{ item.badge.text | translate | async }}</span
                >
                <button class="right-menu">
                  <i class="fa-solid fa-chevron-right"></i>
                </button>
              </a>
            </div>

            <ul *ngIf="hasItems(item)" class="sub-menu" aria-expanded="false">
              <li *ngFor="let subitem of item.subItems">
                <a
                  [routerLink]="subitem.link"
                  *ngIf="!hasItems(subitem)"
                  class="side-nav-link-ref"
                  [attr.data-parent]="subitem.parentId"
                  routerLinkActive="active"
                >
                  {{ subitem.label | translate | async }}
                  <span
                    class="badge rounded-pill bg-{{
                      subitem.badge.variant
                    }} float-end"
                    *ngIf="subitem.badge"
                    >{{ subitem.badge.text | translate | async }}</span
                  >
                </a>
                <a
                  *ngIf="hasItems(subitem)"
                  class="side-nav-link-a-ref has-arrow"
                  href="javascript:void(0);"
                  [attr.data-parent]="subitem.parentId"
                >
                  {{ subitem.label | translate | async }}
                </a>
                <ul
                  *ngIf="hasItems(subitem)"
                  class="sub-menu mm-collapse"
                  aria-expanded="false"
                >
                  <li *ngFor="let subSubitem of subitem.subItems">
                    <a
                      [attr.data-parent]="subSubitem.parentId"
                      [routerLink]="subSubitem.link"
                      routerLinkActive="active"
                      class="side-nav-link-ref"
                    >
                      {{ subSubitem.label | translate | async }}
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
        </ng-container>
      </ul>
    </div>
  </div>

  <!-- mobileresponsive -->
</ng-template>

<!-- Sidebar -->

<div class="vertical-menu">
  <ngx-simplebar class="h-100" #componentRef>
    <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
    <!-- <div *ngIf="!isCondensed">
    </div>
    <div *ngIf="isCondensed">
      <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
    </div> -->
  </ngx-simplebar>
</div>

<ng-template #sidebarSetting let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Menu Settings</h4>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="d('Cross click')"
    >
      <img class="" src="../../../assets/fonts/Path.svg" alt="" />
    </button>
  </div>
  <div class="modal-body">
    <div>
      <h6 class="modal-title" id="modal-basic-title">
        Use
        <span><img src="../../../assets/images/Group 3571.svg" /></span>&nbsp;
        this icon to re-arrange the menu setup
      </h6>
    </div>
    <div class="box-main">
      <ng-template #tmplNode let-node="node">
        <div
          class="node-item"
          [attr.data-id]="node.id"
          [attr.id]="'node-' + node.id"
        >
          <div
            class="node-title"
            *ngIf="nodes"
            (click)="node.isExpanded = !node.isExpanded"
            style="border: 0.5px solid #666666; border-radius: 2px"
          >
            <div class="justifycontent">
              <div>
                <span><img src="../../../assets/images/Group 3571.svg" /></span
                >&nbsp;
                <span style="vertical-align: middle"
                  ><i class=" bx {{ node.icon }}" style="color: #666666"></i
                ></span>
                <span
                  style="vertical-align: middle"
                  [ngClass]="
                    node.id === '1' || '2' || '3' || '4' || '5'
                      ? 'lable-heading'
                      : 'lable-heading1'
                  "
                  >&nbsp;{{ node.label }}</span
                >
              </div>
              <div>
                <i
                  class="fa fa-caret-down"
                  style="color: #666666; padding-right: 8px"
                ></i>
              </div>
            </div>
          </div>
          <div
            *ngIf="node.isExpanded && node.subItems.length"
            class="node-children"
            cdkDropList
            [cdkDropListData]="node.subItems"
            [id]="node.id"
            [cdkDropListConnectedTo]="'dropTargetIds'"
            (cdkDropListDropped)="drop($event)"
          >
            <div
              *ngFor="let child of node.subItems"
              style="color: #666666; font-size: 14px; font-weight: unset"
              cdkDrag
              (cdkDragMoved)="dragMoved($event)"
              (cdkDragStarted)="(true)"
              (cdkDragEnded)="(false)"
            >
              <ng-container
                *ngTemplateOutlet="tmplNode; context: { node: child }"
              ></ng-container>
            </div>
          </div>
        </div>
      </ng-template>

      <div
        cdkDropList
        [cdkDropListData]="nodes"
        [id]="'main'"
        [cdkDropListConnectedTo]="dropTargetIds"
        (cdkDropListDropped)="drop($event)"
      >
        <div
          *ngFor="let node of nodes"
          cdkDrag
          (cdkDragMoved)="dragMoved($event)"
        >
          <ng-container
            *ngTemplateOutlet="tmplNode; context: { node: node }"
          ></ng-container>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="saveMenu()">
      Save
    </button>
  </div>
</ng-template>
