

import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable, of, BehaviorSubject, from } from 'rxjs';
import { map, catchError, switchMap } from 'rxjs/operators';
import { ApiService } from 'src/app/services/api';
import { CookieService } from "ngx-cookie-service";
import { environment } from "src/environments/environment";
import { CryptoService } from "src/app/core/services/crypto.service";

@Injectable({
  providedIn: 'root'
})
export class TranslateService {
  private apiUrl: string = 'https://translation.googleapis.com/language/translate/v2';
  private translationsCache: { [key: string]: { [lang: string]: string } } = {};
  private currentLanguageSubject: BehaviorSubject<string>;
  public currentLanguage$: Observable<string>;
  GLOBALURL: any;
  private apiKeyPromise: Promise<string>;

  constructor(
    private http: HttpClient,
    private apiService: ApiService,
    private cookieService: CookieService,
    private cryptoService: CryptoService
  ) {
    const initialLang = localStorage.getItem('targetLanguage') || 'en';
    this.currentLanguageSubject = new BehaviorSubject<string>(initialLang);
    this.currentLanguage$ = this.currentLanguageSubject.asObservable();
    this.GLOBALURL = this.apiService.getBaseURL();
    this.apiKeyPromise = this.fetchApiKey();
  }

  private fetchApiKey(): Promise<string> {
    return this.http.get(this.GLOBALURL + "/credentialsv2", {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: this.cookieService.get("token"),
      }),
      params: new HttpParams().set("env", environment.env),
    }).toPromise().then((response: any) => this.cryptoService.decrypt(response.response.find((x: any) => x.name === "googleTranslateApiKey").value) as any)
    .catch((error: any) => {
      console.error("Error fetching google translate api key", error);
    });
  }

  setTargetLanguage(language: string): void {
    localStorage.setItem('targetLanguage', language);
    this.currentLanguageSubject.next(language);
  }

  getTargetLanguage(): string {
    return this.currentLanguageSubject.value;
  }

  translate(text: string, targetLanguage: string = 'en'): Observable<string> {
    if (targetLanguage === 'en' || targetLanguage === 'en-GB') {
      return of(text);
    }

    return from(this.apiKeyPromise).pipe(
      switchMap(apiKey => {
        if (this.translationsCache[text] && this.translationsCache[text][targetLanguage]) {
          return of(this.translationsCache[text][targetLanguage]);
        }
        const params = new HttpParams().set('q', text).set('target', targetLanguage).set('key', apiKey);
        return this.http.get<any>(this.apiUrl, { params }).pipe(
          map(response => {
            const translatedText = response.data.translations[0].translatedText;
            if (!this.translationsCache[text]) {
              this.translationsCache[text] = {};
            }
            this.translationsCache[text][targetLanguage] = translatedText;
            return translatedText;
          }),
          catchError(() => of(text))
        );
      })
    );
  }
}
