<footer style="z-index: 2;" class="footer f-12 w-100">
    <div class="footer-container">
        <div class="footer-content">
            <div class="footer-terms">
                <div class="f-terms">
                    <ul>
                        <li>
                            <a href="https://www.eatos.com/privacy-policy/">{{ "Privacy Policy" | translate | async }}</a>
                        </li>
                        <li>
                            <a href="https://www.eatos.com/legal-terms/">{{ "Legal & Terms" | translate | async }}</a>
                        </li>
                        <li>
                            <a href="https://www.eatos.com/report-fraud/">{{ "Report Fraud" | translate | async }}</a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="footer-date">
                <span class="footer-text" (click)="onclickReleaseVersion()">Version:  {{Currentversion}} © eatOS POS Inc. {{year}}. All Rights Reserved</span>
            </div>
            <div class="footer-social">
                <div class="f-social-icons">
                    <div class="f-social">
                        <a class="social-icon" href="https://www.facebook.com/myeatos" target="_blank">
                            <i class="fa-brands fa-facebook-f" aria-hidden="true"></i>
                        </a>
                    </div>
                    <div class="f-social">
                        <a class="social-icon" href="https://twitter.com/myeatos" target="_blank">
                            <!-- <i class="fab fa-twitter" aria-hidden="true"></i> -->
                            <!-- <i class="fa-brands fa-twitter" aria-hidden="true"></i> -->
                            <i class="fa-brands fa-x-twitter" aria-hidden="true"></i>
                        </a>
                    </div>
                    <div class="f-social">
                        <a class="social-icon" href="https://www.instagram.com/myeatos/" target="_blank">
                            <i class="fab fa-instagram" aria-hidden="true"></i>
                        </a>
                    </div>
                    <div class="f-social">
                        <a class="social-icon" href="https://www.linkedin.com/company/myeatos/" target="_blank">
                            <i class="fab fa-linkedin" aria-hidden="true"></i>
                        </a>
                    </div>
                    <div class="f-social">
                        <a class="social-icon" href="https://www.youtube.com/c/myeatOS" target="_blank">
                            <i class="fab fa-youtube" aria-hidden="true"></i>
                        </a>
                    </div>
                    <div class="f-social">
                        <a class="social-icon" href="https://vimeo.com/myeatos" target="_blank">
                            <i class="fab fa-vimeo" aria-hidden="true"></i>
                        </a>
                    </div>
                    <div class="f-social">
                        <a class="social-icon" href="https://www.eatos.com/brochures" target="_blank">
                            <i class="fas fa-book" aria-hidden="true"></i>
                        </a>
                    </div>
                </div>

            </div>
        </div>
    </div>
</footer>
