import { createAction, props } from "@ngrx/store";
export const FETCHING_ORDER_SUMMARY =
  "[sales orders page] sales orders summary fetch start";
export const FETCHING_ORDER_SUMMARY_SUCCESS =
  "[sales orders page] sales orders summary fetch success";
export const FETCHING_ORDER_SUMMARY_FAILURE =
  "[sales orders page] sales orders summary fetch failure";

export const FETCH_SALES_EMPLOYEE_START =
  "[sales orders page] sales employee fetch start";
export const FETCH_SALES_EMPLOYEE_SUCCESS =
  "[sales orders page] sales employee fetch success";
export const FETCH_SALES_EMPLOYEE_FAILURE =
  "[sales orders page] sales employee fetch failure";

export const FETCH_SALES_ORDERS_START =
  "[sales orders page] sales orders fetch start";
export const FETCH_SALES_ORDERS_SUCCESS =
  "[sales orders page] sales orders fetch success";
export const FETCH_SALES_ORDERS_FAILURE =
  "[sales orders page] sales orders fetch failure";
//for login start action
export const salesOrdersFetchStart = createAction(
  FETCHING_ORDER_SUMMARY,
  props<{
    merchantId: String;
    storeId: String;
    employeeSelected: String[] | null;
    orderTypeSelected: String[] | null;
    orderStatusSelected: String[] | null;
    orderNumber: String;
    startDateDisplay: String;
    endDateDisplay: String;
    startTime: String;
    endTime: String;
    lastStartDate: any;
    lastEndDate: any;
  }>()
);
//for login fail action
export const salesOrdersFetchSuccess = createAction(
  FETCHING_ORDER_SUMMARY_SUCCESS,
  props<{ data: any; meta: any }>()
);
//for login failure action
export const salesOrdersFetchFailure = createAction(
  FETCHING_ORDER_SUMMARY_FAILURE,
  props<{ loader: Boolean }>()
);
export const salesOrderFecthEmployeeStart = createAction(
  FETCH_SALES_EMPLOYEE_START
);
export const salesOrderFecthEmployeeSuccess = createAction(
  FETCH_SALES_EMPLOYEE_SUCCESS,
  props<{ employee: any }>()
);
export const salesOrderFecthEmployeeFailure = createAction(
  FETCH_SALES_EMPLOYEE_FAILURE
);

export const salesOrderFetchStart = createAction(
  FETCH_SALES_ORDERS_START,
  props<{
    merchantId: String;
    storeId: String;
    employeeSelected: String[] | null;
    orderTypeSelected: String[] | null;
    orderStatusSelected: String[] | null;
    orderNumber: String;
    startDateDisplay: String;
    endDateDisplay: String;
    startTime: String;
    endTime: String;
    page: Number;
    items: Number;
    lastStartDate: any;
    lastEndDate: any;
  }>()
);
export const salesOrderFetchSuccess = createAction(
  FETCH_SALES_ORDERS_SUCCESS,
  props<{ orders: any[]; metaData: any; childOrders: any[] }>()
);
export const salesOrderFetchFailure = createAction(FETCH_SALES_ORDERS_FAILURE);
