import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { isAuthenticated } from 'src/app/account/auth/state/auth.selector';
import { AppState } from 'src/app/store/app.state';
@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    constructor(
        private store: Store<AppState>,
        private router: Router
    ) { }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ) {
        return this.store.select(isAuthenticated).pipe(
            map((authenticate) => {
                if (!authenticate) {
                    //this.router.createUrlTree(['account/login']);
                    this.router.navigate(['account/login']);
                    return false
                    //this.router.navigate(['/account/login'], { queryParams: { returnUrl: state.url } });
                } else {
                    return true;
                }

            })
        );
    }
}


