export const SupportDetails = [
  {
    suportName: "Features of the eatOS Dashboard Home Section",
    Discroption:
      "Discover the powerful features of the eatOS Dashboard Home Section. This comprehensive guide showcases how restaurant owners can efficiently manage orders, track sales, monitor performance, and access valuable insights through the intuitive eatOS dashboard.",
    link: "https://support.eatos.com/en-us/article/features-of-the-eatos-dashboard-home-section-vk6g97/",
  },
  {
    suportName: "Managing Dashboard Profile Section",
    Discroption:
      "This comprehensive guide explores how to update business information, configure settings, and customize branding to create a strong online presence. Unlock the full potential of the eatOS Dashboard Profile Section to enhance customer engagement and drive success in the competitive restaurant industry.",
    link: "https://support.eatos.com/en-us/article/managing-dashboard-profile-section-14exwss/",
  },
  {
    suportName: "Exploring Your Dashboard Profile",
    Discroption:
      "Explore the significance of updating your login credentials regularly for enhanced account security. Learn how to effortlessly modify your password and protect your sensitive data from potential threats.",
    link: "https://support.eatos.com/en-us/article/exploring-your-dashboard-profile-i3ybzi/",
  },
  {
    suportName: "Changing Your PIN",
    Discroption:
      "In this concise article, explore the significance of changing your PIN for your eatOS account. Uncover the simple steps to update your PIN and enhance the security of your account. Discover the best practices for selecting a strong and memorable PIN to keep your sensitive information safe. Prioritize the protection of your eatOS account by mastering the process of changing your PIN regularly.",
    link: "https://support.eatos.com/en-us/article/changing-your-pin-86q32n/",
  },
  {
    suportName: "Logging Out of Your eatOS Dashboard Account",
    Discroption:
      "Discover the straightforward steps to log out securely, ensuring your account remains protected from unauthorized access. Safeguard your data and maintain control over your eatOS dashboard experience by mastering the art of logging out.",
    link: "https://support.eatos.com/en-us/article/logging-out-of-your-eatos-dashboard-account-1fmu98y/",
  },
  // {
  //   suportName: "Analytics and Reports",
  //   Discroption:
  //     "The Analytics and Reports page offers comprehensive insights into your restaurant's sales, transactions, discounts, payroll, and cashout, empowering stakeholders to make data-driven decisions and optimize business performance. Get a clear overview of financial health and customer trends with user-friendly reports and visualizations.",
  //   link: "https://support.eatos.com/en-us/article/dashboard-analytics-and-reports-gross-sales-ffx5bx/",
  // },
  {
    suportName: "Analytics and Reports - Gross Sales",
    Discroption:
      "Gain valuable business intelligence with eatOS Dashboard Analytics and Reports - Gross Sales. This comprehensive guide explores how to track and analyze gross sales data, identify trends, and make informed decisions to optimize revenue and profitability.",
    link: "https://support.eatos.com/en-us/article/dashboard-analytics-and-reports-gross-sales-ffx5bx/",
  },
  {
    suportName: "Analytics & Reports - Labor",
    Discroption:
      "Efficiently navigate to the labor analytics and reports section on the eatOS dashboard with this comprehensive guide. Uncover the power of labor data analysis, scheduling insights, and performance tracking to optimize workforce management and control labor costs.",
    link: "https://support.eatos.com/en-us/article/dashboard-labor-analytics-reports-hitz9b/",
  },
  {
    suportName: "Analytics and Reports - Exceptions",
    Discroption:
      "Unlock valuable insights and optimize restaurant performance with eatOS Analytics and Reports - Exceptions. This comprehensive guide showcases how to utilize exception reporting to identify operational inefficiencies, monitor key performance indicators, and make data-driven decisions.",
    link: "https://support.eatos.com/en-us/article/analytics-and-reports-exceptions-151swln/",
  },
  {
    suportName: "Sales",
    Discroption:
      "Explore the powerful sales dashboard offering valuable insights into restaurant sales transactions, orders, cash drawer activities, deposits, taxes, service charges, discounts, and customer feedback. Learn how to leverage specific report ranges to make informed decisions and drive success in your restaurant business.",
    link: "https://support.eatos.com/en-us/article/dashboard-sales-1da54er/",
  },
  {
    suportName: "Transactions",
    Discroption:
      "Discover the ins and outs of dashboard sales transactions in this comprehensive article. Explore completed orders with payments, transaction reports, discounts, taxes, surcharges, customer-provided tips, and employee-applied discounts. Gain a deeper understanding of tracking and analyzing sales transactions through the dashboard.",
    link: "https://support.eatos.com/en-us/article/dashboard-sales-transactions-102x0r8/",
  },
  {
    suportName: "Orders",
    Discroption:
      "Explore the functionality of viewing captured orders from your point-of-sale system in this article. Learn about order processing, transmission to the kitchen, and charging. Discover how insightful reports on order types, statuses, and times can enhance your business operations.",
    link: "https://support.eatos.com/en-us/article/dashboard-sales-orders-njtiff/",
  },
  {
    suportName: "Cash Drawers",
    Discroption:
      "Explore the benefits of a restaurant cash drawer tracking system in this article. Discover how this system automatically records activities, updates accounts with pay-in and pay-out entries, and provides day opening and closing balances. Learn how to leverage the system to generate insightful reports for improved financial analysis and a better understanding of your business financial state.",
    link: "https://support.eatos.com/en-us/article/dashboard-sales-cash-drawers-1jsjf0s/",
  },
  {
    suportName: "Sales Service Charge",
    Discroption:
      "Discover the world of service charges in the restaurant industry through this comprehensive article. Learn how these charges are applied as additional fees on customer bills or to specific order types. Gain insights into the impact of service charges on business operations and customer transactions.",
    link: "https://support.eatos.com/en-us/article/dashboard-sales-service-charge-iey6tj/",
  },
  {
    suportName: "Sales Discounts",
    Discroption:"Understand how to use the Sales Discounts page using our extensive support documents",
    link: "https://support.eatos.com/en-us/article/dashboard-sales-discount-18vczcn/",
  },
  // {
  //   suportName: "Feedback",
  //   Discroption: "Gain valuable insights and view all the feedback received by your restaurant on our Comprehensive Feedback History page.",
  //   link: "/sales/feedback",
  // },
  {
    suportName: "Menu Management",
    Discroption:
      "Learn how to optimize your restaurant operations and enhance customer satisfaction with eatOS's dashboard menu management tools. This comprehensive guide explores the power of centralized menu updates, effortless customization, and dynamic menu displays. Uncover how to utilize menu analytics, streamline menu changes, and integrate with inventory management for seamless operations.",
    link: "https://support.eatos.com/en-us/article/menu-management-for-restaurant-9mud7j/",
  },
  {
    suportName: "Menus",
    Discroption:
      "A comprehensive guide to effectively managing menus within a dashboard interface. Learn how to organize, customize, and optimize menu structures for seamless navigation and user experience. Explore best practices, tips, and tools for creating intuitive menu layouts that enhance productivity and user satisfaction.",
    link: "https://support.eatos.com/en-us/article/dashboard-menu-management-menu-g5ktfz/",
  },
  {
    suportName: "Products",
    Discroption:
      '"Dashboard Menu Management for Products: Unlock the power of efficient menu management in product dashboards. Discover expert strategies for organizing, categorizing, and presenting product menus to enhance user accessibility and drive engagement.',
    link: "https://support.eatos.com/en-us/article/dashboard-menu-management-products-15fdryu/",
  },
  {
    suportName: "Modifiers",
    Discroption:
      "Explore the art of managing menu modifiers within dashboards for enhanced user experiences. This insightful article delves into effective techniques for structuring, presenting, and customizing menu modifiers to elevate your product",
    link: "https://support.eatos.com/en-us/article/dashboard-menu-management-modifiers-6ts0ul/",
  },
  {
    suportName: "Add-Ons",
    Discroption:
      "Mastering Dashboard Menu Management for Add-ons: Unleash the potential of your menu add-ons through this comprehensive article. Explore the best practices and strategies for efficiently managing and presenting add-ons within your dashboard.",
    link: "https://support.eatos.com/en-us/article/dashboard-menu-management-add-ons-1kwmfhv/",
  },
  {
    suportName: "Groups (Modifiers/Add-Ons)",
    Discroption:
      "Learn how to create and organize groups (Modifiers/Add-Ons) effectively to offer customizable choices for your products or services. From designing intuitive user interfaces to optimizing groups (Modifiers/Add-Ons) variations, this guide provides valuable tips and techniques for enhancing your menu management system.",
    link: "https://support.eatos.com/en-us/article/dashboard-menu-management-modifier-group-1gz66xd/",
  },
  {
    suportName: "Ingredients",
    Discroption:
      "A Comprehensive Guide to Dashboard Menu Management for Ingredients: This in-depth article dives into the world of ingredient management within your dashboard. Discover how to efficiently organize and manage ingredients to streamline your menu creation process and ensure consistency across all your offerings.",
    link: "https://support.eatos.com/en-us/article/dashboard-menu-management-ingredients-ps6e3g/",
  },
  {
    suportName: "Categories",
    Discroption:
      "A Comprehensive Guide to Dashboard Menu Management for Categories: This definitive article explores the art of category management in your dashboard, providing you with essential insights to curate an intuitive and well-structured menu. Discover how to create and manage categories effectively, ensuring seamless navigation for your customers and efficient backend organization.",
    link: "https://support.eatos.com/en-us/article/dashboard-menu-management-categories-2afq9v/",
  },
  {
    suportName: "Courses",
    Discroption:
      "Delve into the world of courses, from appetizers to desserts, and learn how to structure your menu for maximum customer satisfaction. Explore the best practices for adding, editing, and organizing courses, enabling you to present your culinary offerings with finesse.",
    link: "https://support.eatos.com/en-us/article/dashboard-menu-management-courses-lhxa78/",
  },
  {
    suportName: "Menu Management Service Charges",
    Discroption:
      "Unlock the potential of service charges to boost your revenue and optimize profitability. This article delves into the intricacies of setting up and managing service charges on your dashboard. Learn how to implement various service charge models, such as fixed percentage charges, flat fees, and tiered pricing, to suit your business needs.`  ",
    link: "https://support.eatos.com/en-us/article/dashboard-menu-management-service-charge-1j1b1ky/",
  },
  {
    suportName: "Menu Management Discounts",
    Discroption:
      "A Comprehensive Guide to Dashboard Menu Management for Discounts: This article is your ultimate resource for mastering the art of discount management on your dashboard. Discover the power of strategic discounts in attracting customers, boosting sales, and fostering customer loyalty.",
    link: "https://support.eatos.com/en-us/article/dashboard-menu-management-discount-19r66xs/",
  },
  {
    suportName: "Taxes",
    Discroption:
      "A step-by-step guide to effortlessly handle taxes on your dashboard. This comprehensive article takes you through the process of setting up and managing taxes for your menu items. Learn how to configure different tax rates, apply them to specific categories or items, and handle tax exemptions.",
    link: "https://support.eatos.com/en-us/article/dashboard-menu-management-taxes-73gnqp/",
  },
  {
    suportName: "Guests",
    Discroption:
      "Effortlessly access and manage your restaurant's guestbook on the eatOS dashboard with this comprehensive guide. Discover the user-friendly interface that empowers restaurant owners and managers to keep track of valuable customer information, preferences, and dining history.",
    link: "https://support.eatos.com/en-us/article/how-to-access-the-guestbook-on-the-dashboard-cv2ndv/",
  },
  {
    suportName: "Guestbook",
    Discroption:
      "Learn how to add a guest article and share your expertise with our community. Follow our step-by-step guide to add your restaurant guest.",
    link: "https://support.eatos.com/en-us/article/add-a-guest-dw760s/",
  },
  {
    suportName: "Manage Guest Details",
    Discroption:
      "Discover how to efficiently manage guest details with our comprehensive guide. Learn the step-by-step process to handle guest information through our user-friendly dashboard. Simplify guest management and enhance your hospitality services today!",
    link: "https://support.eatos.com/en-us/article/manage-guest-details-kyl0dp/",
  },
  {
    suportName: "Employee & Timesheet",
    Discroption:
      "Discover the intuitive interface that enables restaurant owners and managers to access and manage employee information, work schedules, and time tracking data.",
    link: "https://support.eatos.com/en-us/article/dashboard-navigation-employee-timesheet-18o1fup/",
  },
  {
    suportName: "Employees",
    Discroption:
      "This article guides you through the process of adding, editing, archiving, unarchiving, and permanently deleting employees. Discover the ease of updating employee details and maintaining a well-organized workforce. Optimize your restaurant's operations with streamlined employee management.",
    link: "https://support.eatos.com/en-us/article/how-to-add-an-employee-2abmd3/",
  },
  {
    suportName: "Timesheet",
    Discroption:
      "Discover how to efficiently manage and view employee timesheets in this article. Learn how to access the timesheet section through the side nav bar, automatically calculate weekly hours worked, and preview daily hours and break times.",
    link: "https://support.eatos.com/en-us/article/dashboard-employee-timesheet-74yse2/",
  },
  {
    suportName: "Restaurant",
    Discroption:
      "Create a seamless and efficient restaurant setup with eatOS's comprehensive dashboard tools. This guide walks you through the entire process, from configuring hardware and menu management to employee settings and online ordering integration.",
    link: "https://support.eatos.com/en-us/article/complete-dashboard-restaurant-setup-1x1hnle/",
  },
  {
    suportName: "Restaurant General",
    Discroption:
      "In this article, we will explore the Dashboard Restaurant General section, a powerful platform that allows restaurant owners to manage and showcase essential data entered during account creation.",
    link: "https://support.eatos.com/en-us/article/dashboard-restaurant-general-section-12yi2ct/",
  },
  {
    suportName: "Restaurant Schedule",
    Discroption:
      "Learn how to create, edit, and manage schedules using the eatOS Dashboard, ensuring smooth coordination of staff and services. Discover the benefits of utilizing the restaurant schedule feature to optimize productivity, enhance customer service, and maintain an efficient workflow.",
    link: "https://support.eatos.com/en-us/article/dashboard-restaurant-schedule-17306cg/",
  },
  {
    suportName: "Revenue Center",
    Discroption:
      "Discover the step-by-step process of adding, editing, archiving, unarchiving, and permanently deleting Revenue Centers, enabling you to manage your restaurant operations efficiently.",
    link: "https://support.eatos.com/en-us/article/dashboard-restaurant-revenue-center-kn890p/",
  },
  {
    suportName: "Service Areas",
    Discroption:
      "This comprehensive article guides you through creating, customizing, editing, and managing service areas within your restaurant. Learn how to efficiently allocate tables, add dividers, and incorporate text to optimize your restaurant layout.",
    link: "https://support.eatos.com/en-us/article/dasboard-restautant-service-areas-11t5vn0/",
  },
  {
    suportName: "Online Ordering",
    Discroption:
      "This comprehensive resource walks you through the setup, customization, and integration of the eatOS dashboard for seamless online ordering, ensuring a convenient and delightful experience for customers while boosting your restaurant's revenue and efficiency.",
    link: "https://support.eatos.com/en-us/article/complete-online-ordering-guide-mvzdxv/",
  },
  {
    suportName: "Online Ordering General",
    Discroption:
      "Streamline your online ordering process with ease! Learn how to set up your restaurant's online ordering general settings effortlessly. Optimize your customers' experience and boost efficiency by following these simple steps to configure online ordering preferences on your eatery's platform",
    link: "https://support.eatos.com/en-us/article/how-to-set-up-online-ordering-general-setting-s91h0j/",
  },
  {
    suportName: "Storefront Settings",
    Discroption:
      "Enhance your online ordering experience with ease! Follow these quick steps to set up your restaurant's storefront settings for online orders. Optimize your storefront layout and tailor it to your unique brand, ensuring a seamless and delightful ordering process for your customers on your eatery's platform.",
    link: "https://support.eatos.com/en-us/article/how-to-set-up-online-ordering-storefront-settings-y9ck62/",
  },
  {
    suportName: "Dine-in Storefront Settings",
    Discroption:
      "Create the perfect dine-in experience! Discover how to set up your restaurant's storefront settings for in-person dining. Customize your dine-in layout and ambiance to reflect your brand, ensuring a memorable and enjoyable visit for your valued customers at your eatery.",
    link: "https://support.eatos.com/en-us/article/dine-in-storefront-settings-nqoyvh/",
  },
  {
    suportName: "Delivery",
    Discroption:
      "Master online ordering and delivery services for your business! This comprehensive guide will walk you through the step-by-step process of setting up efficient online ordering and delivery systems. Unlock new opportunities for growth and customer satisfaction with seamless and convenient services tailored to your restaurant's needs.",
    link: "https://support.eatos.com/en-us/article/online-ordering-delivery-guide-tzm156/",
  },
  {
    suportName: "Payment",
    Discroption:
      "Effortlessly set up your restaurant's online ordering payment system! Learn how to establish a secure and seamless payment process for your customers. This step-by-step guide will help you configure a reliable payment system to boost sales and enhance the ordering experience on your eatery's platform.",
    link: "https://support.eatos.com/en-us/article/setting-up-an-online-ordering-payment-system-1xq58aq/",
  },
  // {
  //   suportName: "Online Ordering Schedule",
  //   Discroption:
  //     "Create and manage delivery schedules efficiently with our Schedule page.",
  //   link: "https://support.eatos.com/en-us/article/guide-to-creating-online-ordering-promo-codes-1408lvp/",
  // },
  {
    suportName: "Promo Codes",
    Discroption:
      "Unlock marketing opportunities with online ordering promo codes! This comprehensive guide will show you how to create and implement enticing promo codes for your restaurant's online orders. Maximize customer engagement and boost sales with targeted discounts and promotions tailored to your eatery's unique offerings.",
    link: "https://support.eatos.com/en-us/article/guide-to-creating-online-ordering-promo-codes-1408lvp/",
  },
  {
    suportName: "QR Order at Table",
    Discroption:
      "Create a QR code in a snap! This easy-to-follow guide will walk you through the simple steps to generate a personalized QR code. Seamlessly link customers to your website, menu, or promotional content using QR codes, making it a breeze for them to connect with your business.",
    link: "https://support.eatos.com/en-us/article/how-to-generate-a-qr-code-nx4xhj/",
  },
  {
    suportName: "Support",
    Discroption:
      "Effortless online ordering support at your fingertips! Discover how to provide exceptional customer assistance for your restaurant's online ordering system. This guide offers practical tips and strategies to ensure a smooth and satisfying ordering experience, leading to increased customer satisfaction and loyalty.",
    link: "https://support.eatos.com/en-us/article/navigating-the-dashboard-online-ordering-support-jy7tjq/",
  },
  {
    suportName: "Employee Job and Role",
    Discroption:
      "Learn how to optimize workforce management with eatOS's comprehensive tools for configuring employee job and role settings. Customize access levels, streamline job assignments, and enhance staff performance to elevate your restaurant's efficiency and success in the competitive hospitality industry.",
    link: "https://support.eatos.com/en-us/article/configure-employee-job-and-role-settings-pzo09y/",
  },
  {
    suportName: "Job Type",
    Discroption:
      "Learn how to create and configure job types, such as server, bartender, host, and more, to effectively organize your workforce. Discover how to assign specific roles and responsibilities to each job type, enabling streamlined employee scheduling and task allocation.",
    link: "https://support.eatos.com/en-us/article/dashboard-employee-job-type-1caxyg1/",
  },
  {
    suportName: "Roles",
    Discroption:
      "Uncover the power of creating and customizing roles, such as manager, staff, supervisor, and more, to efficiently organize your workforce hierarchy. Learn how to define specific permissions and access levels for each role, ensuring secure data handling and task delegation.",
    link: "https://support.eatos.com/en-us/article/dashboard-employee-roles-d8055b/",
  },
  {
    suportName: "Department",
    Discroption:
      "Discover how to create, manage, and organize departments to streamline your workforce and enhance collaboration. Learn how to assign employees to specific departments, optimizing task allocation and project coordination.",
    link: "https://support.eatos.com/en-us/article/dashboard-employee-department-1w7ht32/",
  },
  {
    suportName: "Hardware",
    Discroption:
      "Discover the seamless process of setting up hardware for your restaurant with eatOS. From Point of Sale terminals to printers and self-service kiosks, this guide provides insights on configuring and integrating hardware components, ensuring smooth operations and enhanced customer experiences.",
    link: "https://support.eatos.com/en-us/article/dashboard-hardware-setup-9mc7gh/",
  },
  {
    suportName: "Integrations",
    Discroption:
      "Unlock seamless operations with powerful integrations! Explore the benefits of integrating essential tools and systems into your restaurant's operations. This guide will show you how to streamline processes, enhance efficiency, and elevate customer experience through strategic integrations tailored to your unique business needs",
    link: "https://support.eatos.com/en-us/article/dashboard-integrations-1d61zd3/",
  },
  {
    suportName: "Restaurant Settings",
    Discroption:
      "Explore eatOS's powerful dashboard restaurant settings for locations and receipt builder. This comprehensive guide walks you through the process of efficiently managing multiple restaurant locations, customizing receipts, and tailoring branding to enhance customer experiences.",
    link: "https://support.eatos.com/en-us/article/dashboard-settings-locations-receipt-builder-1a7qyoo/",
  },
  {
    suportName: "Locations",
    Discroption:
      "Uncover the key features and functionalities that allow you to configure and customize essential aspects of your restaurant's operation. Learn how to add and manage multiple restaurant locations seamlessly, facilitating centralized control and oversight. Explore the nuances of setting up individual restaurant profiles, including contact details, operating hours, and unique branding elements.",
    link: "https://support.eatos.com/en-us/article/dashboard-restaurant-setting-locations-i417t4/",
  },
  {
    suportName: "Receipt Builder",
    Discroption:
      "Discover the essential features and configurations that allow you to customize and optimize your receipt formats for seamless customer interactions. Learn how to personalize receipt templates with your restaurant's logo, contact information, and branding elements to enhance brand recognition.",
    link: "https://support.eatos.com/en-us/article/restaurant-receipt-builder-gbynwl/",
  },
  // {
  //   suportName: "Activity Logs",
  //   // Discroption:
  //   //   "Discover the essential features and configurations that allow you to customize and optimize your receipt formats for seamless customer interactions. Learn how to personalize receipt templates with your restaurant's logo, contact information, and branding elements to enhance brand recognition.",
  //   // link: "https://support.eatos.com/en-us/article/restaurant-receipt-builder-gbynwl/",
  // },
];
