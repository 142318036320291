import { Component, HostListener, Inject, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

import { HttpClient } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { CookieService } from 'ngx-cookie-service';
import { OneSignal } from 'onesignal-ngx';
import { Subscription } from 'rxjs';
import { autoLogin } from './account/auth/state/auth.actions';
import { getAccessToken } from './account/auth/state/auth.selector';
import { AppState } from './store/app.state';
// import { TimezoneService } from './services/timezone';
import moment from "moment";
// import { LocationService } from './core/services/location.service';
import { DOCUMENT, Location } from '@angular/common';
import { ApiService } from './services/api';
// import { WebSocketService } from './services/websocket.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  // providers: [TimezoneService, LocationService]
 // providers: [TimezoneService, LocationService,WebSocketService]
})
export class AppComponent implements OnInit, OnDestroy {
  //keep refs to subscriptions to be able to unsubscribe later
  private popupOpenSubscription!: Subscription;
  private popupCloseSubscription!: Subscription;
  private initializingSubscription!: Subscription;
  private initializedSubscription!: Subscription;
  private initializationErrorSubscription!: Subscription;
  private statusChangeSubscription!: Subscription;
  private revokeChoiceSubscription!: Subscription;
  private noCookieLawSubscription!: Subscription;
  token$: String
  urlToken: String = "";
  returnUrl: any = null
  firsturl: any;
  token: any = null;
  crossLinkLoader: Boolean = false;
  constructor(
    private store: Store<AppState>,
    private route: ActivatedRoute,
    private cookieService: CookieService,
    private router: Router,
    private http: HttpClient,
    // private moment: TimezoneService,
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    // private ccService: NgcCookieConsentService,
    private oneSignal: OneSignal,
    private apiService: ApiService,
    private location: Location,
    //private webSocket: WebSocketService
  ) {

      //this.websocketService.authenticate();

    this.checkScreenWidth(window.innerWidth);
    document.documentElement.style.setProperty('--wbColor', '#212121')
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event) => {
        const url: any = event;
        const path = url.url;
        this.firsturl = path.split("/")[1];
        if (path === '/' || path === '/profile/profile-settings') {
          localStorage.setItem('ActivemenuClass', '/home')
        }
      });
  }

  ipAddress = '0.0.0.0';
  urlsegment: any
  wsM = '';
  wsData: any = '';
  private subscription: Subscription;
  ngOnInit(): void {


    // console.log('app component',this.websocketService.isConnectedToSocket())
    // this.subscription = this.websocketService.getMessage().subscribe((data) => {
    //   console.log('wsData', data);
    //   this.wsData = data;
    // });

    //this.websocketService.connectSocket();

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    )
      .subscribe(event => {
        this.urlsegment = event

        if (this.urlsegment.url === '/account/login') {
          this.renderer.addClass(this.document.body, 'remove-overflow');
        } else {
          if(this.document && this.document.body && this.document.body.classList.contains('remove-overflow') && this.renderer) {
            this.renderer.removeClass(this.document.body, 'remove-overflow');
          }
        }

      });
    console.log("PREPROD")
    this.getIPAddress();
    this.storeCurrentLocationDetails();
    console.log(moment().startOf('day').unix())
    let currentUrl = window.location.href;
    // if(!currentUrl.includes('localhost')) {
    //   if(currentUrl.includes('.dev')){
    //     this.oneSignal.init({
    //       appId: environment.oneSignal.dev.appId,
    //       serviceWorkerPath: 'https://d4.eatos.dev/OneSignalSDKWorker.js'
    //       });
    //   }
    //   if(currentUrl.includes('.net')){
    //     this.oneSignal.init({
    //       appId: environment.oneSignal.staging.appId,
    //       serviceWorkerPath: 'https://d4.eatos.net/OneSignalSDKWorker.js'
    //       });
    //   }
    //   if(currentUrl.includes('.com') || currentUrl.includes('.us')){
    //     this.oneSignal.init({
    //       appId: environment.oneSignal.prod.appId,
    //       serviceWorkerPath: 'https://dashboard.eatos.com/OneSignalSDKWorker.js'
    //       });
    //   }
    // }
    // default test mode & page reload test mode will reset to 0
    //localStorage.setItem('isTestModeOn', '0');

    // subscribe to cookieconsent observables to react to main events
    // this.popupOpenSubscription = this.ccService.popupOpen$.subscribe(
    //   () => {
    //     // you can use this.ccService.getConfig() to do stuff...
    //   });

    // this.popupCloseSubscription = this.ccService.popupClose$.subscribe(
    //   () => {
    //     // you can use this.ccService.getConfig() to do stuff...
    //   });

    // this.initializingSubscription = this.ccService.initializing$.subscribe(
    //   (event: NgcInitializingEvent) => {
    //     // the cookieconsent is initilializing... Not yet safe to call methods like `NgcCookieConsentService.hasAnswered()`
    //     console.log(`initializing: ${JSON.stringify(event)}`);
    //   });

    // this.initializedSubscription = this.ccService.initialized$.subscribe(
    //   () => {
    //     // the cookieconsent has been successfully initialized.
    //     // It's now safe to use methods on NgcCookieConsentService that require it, like `hasAnswered()` for eg...
    //     console.log(`initialized: ${JSON.stringify(event)}`);
    //   });

    // this.initializationErrorSubscription = this.ccService.initializationError$.subscribe(
    //   (event: NgcInitializationErrorEvent) => {
    //     // the cookieconsent has failed to initialize...
    //     console.log(`initializationError: ${JSON.stringify(event.error?.message)}`);
    //   });

    // this.statusChangeSubscription = this.ccService.statusChange$.subscribe(
    //   (event: NgcStatusChangeEvent) => {
    //     // you can use this.ccService.getConfig() to do stuff...
    //   });

    // this.revokeChoiceSubscription = this.ccService.revokeChoice$.subscribe(
    //   () => {
    //     // you can use this.ccService.getConfig() to do stuff...
    //   });

    // this.noCookieLawSubscription = this.ccService.noCookieLaw$.subscribe(
    //   (event: NgcNoCookieLawEvent) => {
    //     // you can use this.ccService.getConfig() to do stuff...
    //   });

    //auto login if user token valid
    var loggedIn = localStorage.getItem("autoLogin");
    if (loggedIn == 'true') {
      this.store.dispatch(autoLogin());
    }


    /*
    ....
    Example for getting access token
    ....
      */
    this.store.select(getAccessToken).subscribe((result) => {
      this.token$ = result
    })


  }

  getIPAddress() {
    this.http.get("https://api.ipify.org/?format=json").subscribe(
      (res: any) => {
        this.ipAddress = res.ip;
        localStorage.setItem('ipAddress', this.ipAddress)
      },
      () => { }
    )
  }

  currentUserDetails: any;
  currentCountryISO: any;
  storeCurrentLocationDetails() {
    this.http.get('https://ipapi.co/json/').subscribe((data) => {
      // console.log("data_FROMGETCURRENT_LOCATION", data)
      // this.currentUserDetails = data
      // console.log("this.CurrentUserDetails", this.CurrentUserDetails)
      // this.currentCountryISO =this.currentUserDetails.country_code
      // this.apiService.currentLocationDetails = data;
      localStorage.setItem('currentLocationDetails', JSON.stringify(data));
      // console.log("this.currentCountryISO", this.currentCountryISO)
      // this.currentCountryISO = data.country_code;
      // console.log("typeof(this.currentCountryISO)", typeof(this.currentCountryISO))

    },
    (error) => {
      console.log("Error fetching current location details", error)
    });
  }

  ngOnDestroy() {
    // unsubscribe to cookieconsent observables to prevent memory leaks
    // this.popupOpenSubscription.unsubscribe();
    // this.popupCloseSubscription.unsubscribe();
    // this.initializingSubscription.unsubscribe();
    // this.initializedSubscription.unsubscribe();
    // this.initializationErrorSubscription.unsubscribe();
    // this.statusChangeSubscription.unsubscribe();
    // this.revokeChoiceSubscription.unsubscribe();
    // this.noCookieLawSubscription.unsubscribe();
    this.subscription.unsubscribe();
  }
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.checkScreenWidth(event.target.innerWidth); // Update the class on screen width change
  }

  checkScreenWidth(width: number) {
    const bodyClasses = document.body.classList;
    if (bodyClasses[0] === 'modal-open' || bodyClasses[1] === 'modal-open') {
      this.renderer.removeClass(document.body, 'modal-open');
    }
    if (this.firsturl === 'home') {
      if (width < 768) {
        this.renderer.removeClass(document.body, 'res-sidebar-enable');
        this.renderer.addClass(document.body, 'vertical-collpsed');
        this.renderer.removeClass(document.body, 'sidebar-enable');
      } else {

        this.renderer.removeClass(document.body, 'vertical-collpsed');
        this.renderer.addClass(document.body, 'sidebar-enable');
      }
    } else {
      if (width < 1024) {
        this.renderer.removeClass(document.body, 'res-sidebar-enable');
        this.renderer.removeClass(document.body, 'sidebar-enable');
        this.renderer.addClass(document.body, 'vertical-collpsed');
      } else if (width < 576) {
        this.renderer.removeClass(document.body, 'vertical-collpsed');
        this.renderer.removeClass(document.body, 'sidebar-enable');
        setTimeout(() => {
          this.renderer.addClass(document.body, 'res-sidebar-enable');
        }, 0);
      } else {
        this.renderer.removeClass(document.body, 'vertical-collpsed');
        this.renderer.removeClass(document.body, 'res-sidebar-enable');
        this.renderer.addClass(document.body, 'sidebar-enable');
      }
    }
  }
}
