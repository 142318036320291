<div *canAccess="['ChangePassword', [0, 2]]" class="modal-body">
  <div class="empty-discounts-note"
    style="display: flex; flex-direction: column; justify-content: center; align-items: center;">
    <div style="width: 100%; display: flex; justify-content: end; align-items: center;">
      <img style="padding: 5px; cursor: pointer;" src="assets\images\closeIcon.svg" class="" (click)="closeModal()" />
    </div>
    <i class="mdi mdi-lock" style="font-size: 50px;"></i>
    <div class="mt-3"></div>
    <!-- <div>You are not authorized to perform this operation</div> -->
    <!-- MESSAGE UPDATED  -->
    <div>{{ 'Oop\'s!! It appears that you do not have access to Change Password. Please get in touch with your manager.'
      | translate | async }}</div>
  </div>
</div>

<div *canAccess="['ChangePassword', [1, 3]]" class="modal-body">
  <div class="d-flex justify-content-between mb-2">
    <div class="f-20 fw-600">{{ 'Change Password' | translate | async }}</div>
    <div>
      <img style="padding: 5px; cursor: pointer" src="assets\images\closeIcon.svg" class="" (click)="closeModal()" />
    </div>
  </div>
  <div class="f-14 fw-500 mb-3">
    <span>{{ 'Change the password for your account.' | translate | async }}</span>
  </div>
  <form (keydown.enter)="$event.preventDefault()" #changePasswordForm="ngForm"
    (ngSubmit)="onSubmit(changePasswordForm)">
    <div>
      <div class="mb-3">
        <!-- Pass Validation -->
        <label for="currentPassword" class="form-label">{{ 'Current Password*' | translate | async }}</label>
        <div class="input-group auth-pass-inputgroup">
          <input accessControl module="ChangePassword" access="3" [type]="isShowCurrentPassword ? 'text' : 'password'"
            class="form-control pwd-in" id="currentPassword" placeholder="Enter Current Password"
            aria-label="Current Password" aria-describedby="current-password-addon" name="currentPassword" ngModel
            #currentPassword="ngModel" required />
          <button accessControl module="ChangePassword" access="3" class="btn btn-light ms-0 eyebtn" type="button"
            id="current-password-addon" (click)="showCurrentPassword()">
            <!-- <img src="assets/images/eye.svg"alt="" /> -->
            <i *ngIf="!NewPIN" class="fa-light fa-eye"></i>
            <i *ngIf="NewPIN" class="fa-light fa-eye-slash"></i>
          </button>
        </div>
        <div class="form-text text-danger" *ngIf="!currentPassword.valid && currentPassword.touched">
          <ng-container *ngIf="currentPassword.errors.required">{{ 'Current Password is required.' | translate | async
            }}</ng-container>
        </div>
      </div>
      <div class="mb-3">
        <label for="password" class="form-label">{{ 'New Password*' | translate | async }}</label>
        <div class="input-group auth-pass-inputgroup">
          <input accessControl module="ChangePassword" access="3" [type]="isShowPassword ? 'text' : 'password'"
            class="form-control pwd-in" id="password" placeholder="Enter New Password" aria-label="Password"
            aria-describedby="password-addon" name="password" ngModel #password="ngModel"
            pattern="(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&]{8,32}" required
            minlength="8" />
          <button accessControl module="ChangePassword" access="3" class="btn btn-light ms-0 eyebtn" type="button"
            id="password-addon" (click)="showPassword()">
            <!-- <img src="assets/images/eye.svg" alt="" /> -->
            <i *ngIf="!issnewhowPassword" class="fa-light fa-eye"></i>
            <i *ngIf="issnewhowPassword" class="fa-light fa-eye-slash"></i>
          </button>
        </div>
        <div class="form-text text-danger" *ngIf="!password.valid && password.touched">
          <ng-container *ngIf="password.errors.required">{{ 'New Password is required.' | translate | async
            }}</ng-container>
          <ng-container *ngIf="password.errors.pattern">{{ 'New Password must be 8 to 32 characters long, contain at
            least one number, one uppercase and lowercase letter and one special character.' | translate | async
            }}</ng-container>
        </div>
      </div>

      <div class="mb-4">
        <label for="confirmPassword" class="form-label">{{ 'Confirm Password*' | translate | async }}</label>
        <div class="input-group auth-pass-inputgroup">
          <input accessControl module="ChangePassword" access="3" [type]="isShowConfirmPassword ? 'text' : 'password'"
            class="form-control" id="confirmPassword" placeholder="Enter Confirm Password" aria-label="Confirm Password"
            aria-describedby="confirm-password-addon" name="confirmPassword" ngModel #confirmPassword="ngModel"
            pattern="(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&]{8,32}" required
            minlength="8" />
          <button accessControl module="ChangePassword" access="3" class="btn btn-light z-3 bg-white ms-0 eyebtn"
            type="button" id="confirm-password-addon" (click)="showConfirmPassword()">
            <!-- <img src="assets/images/eye.svg" alt="" /> -->
            <i *ngIf="!newConfirmPassword" class="fa-light fa-eye"></i>
            <i *ngIf="newConfirmPassword" class="fa-light fa-eye-slash"></i>
          </button>
        </div>
        <div class="form-text text-danger" *ngIf="!confirmPassword.valid && confirmPassword.touched">
          <ng-container *ngIf="confirmPassword.errors.required">{{ 'Confirm Password is required.' | translate | async
            }}</ng-container>
          <ng-container *ngIf="confirmPassword.errors.pattern">{{ 'Confirm Password must be 8 to 32 characters long,
            contain at least one number, one uppercase and lowercase letter and one special character.' | translate |
            async }}</ng-container>
        </div>
        <div class="form-text text-danger" *ngIf="passwordErrorMessage != ''">
          {{ passwordErrorMessage }}
        </div>
        <div class="form-text text-danger" *ngIf="
            confirmPassword.touched &&
            confirmPassword.touched &&
            password.value !== confirmPassword.value
          ">
          {{ 'Passwords do not match' | translate | async }}
        </div>
      </div>

      <div class="d-flex flex-column mb-4"></div>
      <div class="f-20 fw-600">{{ 'Password Instructions' | translate | async }}</div>
      <div>
        <li>{{ 'Password must be 8 to 32 characters long.' | translate | async }}</li>
        <li>{{ 'Contain at least one number and one uppercase.' | translate | async }}</li>
        <li>{{ 'One lowercase letter and one special character.' | translate | async }}</li>
      </div>
    </div>

    <div class="row mt-3">
      <div class="col-12">
        <button accessControl module="ChangePassword" access="3" *ngIf="!isLoading"
          class="primary_btn f-18 w-100 fw-700 text-white" type="submit">
          {{ 'Change' | translate | async }}
        </button>
        <button *ngIf="isLoading" class="primary_btn f-18 w-100 fw-700 text-white" type="button" disabled>
          <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
          <span class="pl-2">&nbsp;&nbsp;{{ 'Please wait...' | translate | async }}</span>
        </button>
      </div>
    </div>