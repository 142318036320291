import { CountryISO } from 'ngx-intl-tel-input';
import { Store } from '@ngrx/store';
import { ApiService } from './../../../services/api';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Observable, Subject } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { setLoadingSpinner } from 'src/app/store/sharedstate/shared.actions';
import { AppState } from 'src/app/store/app.state';

interface IStoreRequirement {
  Timezone:String;
  Currency:String;

  storeAddress1:String;
  storeCity:String;
  storeCountry:String;
}

@Injectable({
  providedIn: 'root',
})
export class LocationsService {

  public storeFullSettings$: Observable<any>;

  public storeRequirements:IStoreRequirement = {
    Timezone: 'Timezone',
    Currency: 'Currency',
    
    storeAddress1: 'storeAddress1',
    storeCity: 'storeCity',
    storeCountry: 'storeCountry'
  }

  token = this.cookieService.get("token");
  storeId = this.cookieService.get("storeId");
  merchantId = this.cookieService.get("merchantId");
  isLoadingChangeSub = new Subject<boolean>();

  GLOBALURL = this.ApiService.getBaseURL();
  /**
   * Location Service Constructor
   * @param http
   * @param router
   * @param cookieService
   * @param ApiService
   * @param store
   * @param toastr
   */
  constructor(
    private http: HttpClient,
    private cookieService: CookieService,
    private ApiService: ApiService,
    private store: Store<AppState>) { }

  /**
   * Get Locations
   * @returns stores
   */
  getLocations(params?) {
    // this.store.dispatch(setLoadingSpinner({ status: true }));
    return this.http
      .get(this.GLOBALURL + "/store", {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
          Authorization: this.token,
        }),
        params: params,
      }).pipe(shareReplay(1))
  }

  getArchivedLocations(params?) {
    // this.store.dispatch(setLoadingSpinner({ status: true }));
    return this.http
      .get(this.GLOBALURL + "/store?status=0", {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
          Authorization: this.token,
        }),
        params: params
      }).pipe(shareReplay(1))
  }

  /**
   * Get Location
   * @returns stores
   */
  getLocation(payload) {
    return this.http
      .get(this.GLOBALURL + "/store", {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
          Authorization: this.token,
        }),
        params: new HttpParams()
          .set("storeId", payload.storeId)
      }).pipe(shareReplay(1))
  }

  /**
   * Unarchive Locations
   * @param storeId
   * @returns store
   */
  unarchiveLocation(payload) {
    return this.http
      .patch(this.GLOBALURL + "/store", payload, {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
          Authorization: this.token,
        }),
      })
  }

  /**
   * Permanently Delete Locations
   * @param storeId
   * @returns store
   */
  permanentlyDeleteLocation(storeId: string) {
    return this.http
      .delete(this.GLOBALURL + "/store", {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
          Authorization: this.token,
        }),
        params: new HttpParams()
          .set("storeId", storeId)
          .set("isPermanent", 1),
      })
  }

  /**
   * Delete Locations
   * @param storeId
   * @returns store
   */
  deleteLocation(storeId: string) {
    return this.http
      .delete(this.GLOBALURL + "/store", {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
          Authorization: this.token,
        }),
        params: new HttpParams().set("storeId", storeId),
      })
  }

  /**
   * Create Store Location
   * @param payload
   * @returns store
   */
  createStore(payload) {
    return this.http
      .post(this.GLOBALURL + "/store", payload, {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
          Authorization: this.token,
        }),
      })
  }

  /**
   * Update Store
   * @param payload
   * @returns
   */
  updateStore(payload) {
    return this.http
      .put(this.GLOBALURL + "/store", payload, {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
          Authorization: this.token,
        }),
      })
  }



  /**
   * Upload Receipt Image
   * @param payload
   * @returns
   */
  updateReceiptImage(payload) {
    let headers = { 'enctype': 'multipart/form-data', 'Authorization': this.cookieService.get('token') }
    let httpOptionsImage = { headers: new HttpHeaders(headers) }
    return this.http.put(this.GLOBALURL + '/upload?path=images/receipts/', payload, httpOptionsImage)
  }

  /**
   * Get Timezones
   * @returns timezones
   */
  getTimezones() {
    return this.http
      .get(this.GLOBALURL + "/timezones", {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
          Authorization: this.token,
        }),
      }).pipe(shareReplay(1))
  }

  /**
   * Update Store Settings
   * @param payload
   * @returns
   */
  updateSettings(payload) {
    return this.http
      .put(this.GLOBALURL + "/settings", payload, {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
          Authorization: this.token,
        }),
      }).pipe(shareReplay(1))
  }

  /**
   * Get Setting
   * @param payload
   * @returns
   */
  getSetting(payload) {
    return this.http
      .get(this.GLOBALURL + "/settings", {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
          Authorization: this.token,
        }),
        params: new HttpParams().set("storeId", payload.storeId),
      }).pipe(shareReplay(1))
  }
  // storeCurrentCurrencyCode: any = ["USD", "$"];
  // storeCurrentCurrency: any = this.storeCurrentCurrencyCode[1];

  // async setStoreCurrency(payload, token) {
  //   await this.getStoreFullSetting(payload, token);

  //   //this.runTimeoutInterval(user);
  // }

  getStoreFullSetting(storeId, token) {
    if(!this.storeFullSettings$){
      this.storeFullSettings$ = this.http
      .get(this.GLOBALURL + "/settings", {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
          Authorization: token,
        }),
        params: new HttpParams().set("storeId", storeId),
      }).pipe(shareReplay(1))
    }

    return this.storeFullSettings$
      // try {
      //   const response: any = await this.http
      //     .get(this.GLOBALURL + "/settings", {
      //       headers: new HttpHeaders({
      //         "Content-Type": "application/json",
      //         Authorization: token,
      //       }),
      //       params: new HttpParams().set("storeId", payload.storeId),
      //     })
      //     .toPromise();
      //     console.log("response_LOCATIONSERVICE", response.response)

      //     response.response.forEach((element2) => {
      //               if (element2.settingName == 'Currency') {
      //                 var currencyCode = element2.settingValue.replace(/ /g, "").split('-');
      //                 // var currencyCode = "";
      //                 // check the currency code length
      //                 if (currencyCode?.length > 1) {
      //                   this.storeCurrentCurrencyCode = currencyCode;
      //                 } else {
      //                   // console.log("FROM_ELSE")
      //                   this.storeCurrentCurrencyCode = ["USD", "$"];
      //                 }
      //                 console.log("storeCurrentCurrencyCode", this.storeCurrentCurrencyCode)
      //                 this.storeCurrentCurrency = this.storeCurrentCurrencyCode[1];
      //                 this.cookieService.set('currency', this.storeCurrentCurrency, 70, '/');
      //                 this.cookieService.set('currencyCode', this.storeCurrentCurrencyCode, 70, '/');
      //                 localStorage.setItem('currency', this.storeCurrentCurrency);
      //               }
      //             });

      // } catch (error) {
      //   console.error("Error fetching store permissions:", error);
      //   // Handle the error as needed
      // }
  }

  /**
   * Get User Location Country
   * @returns CountryISO
   */
  getUserCountry() {
    let country: string = this.cookieService.get('storeCountry')
    // country = this.titleCasePipe.transform(country)

    if(!country) {
      return 'us'
    }

    country = country.replace(' ', '')
    let countryIso = CountryISO[country]?CountryISO[country]:CountryISO.UnitedStates
    return countryIso
  }

  /**
      * Update Store
      * @param payload
      * @returns
      */
  updateTestStore(payload) {

    return this.http
      .post("papi/store/save", payload, {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
          Authorization: this.token,
        }),
      })
  }
  /**
   * Update Store Settings
   * @param payload
   * @returns
   */
  updateTestSettings(payload) {

    return this.http
      .put("papi/settings", payload, {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
          Authorization: this.token,
        }),
      }).pipe(shareReplay(1))
  }
}
