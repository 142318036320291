import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, Subject, interval } from 'rxjs';

import { EventService } from '../core/services/event.service';
import { AppState } from '../store/app.state';
import { getLoading } from '../store/sharedstate/shared.selector';
import { AuthenticationService } from '../../app/core/services/auth.service';

import {
  LAYOUT_VERTICAL, LAYOUT_HORIZONTAL, LAYOUT_WIDTH, TOPBAR
} from './layouts.model';
import { AuthCookieService } from '../core/services/authcookies.service';
import { AuthAlertService } from '../core/services/AlertService/auth-alert.service';
import { LocationsService } from '../core/services/locations/locations.service';
import { CookieService } from 'ngx-cookie-service';
import { ApiService } from "src/app/services/api";
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})

export class LayoutComponent implements OnInit, AfterViewInit,OnDestroy {
  private destroy$ = new Subject<void>();

  // Loader
  // loading: any = false;
  // isMenuResetOnTestMode = false;
  // checkBoxValue: any = localStorage.getItem('isTestModeOn') == '1' ? "true": "false";

  // layout related config
  layoutType: string;
  layoutwidth: string;
  topbar: string;
  returnUrl: any = null
  token: any = null;
  crossLinkLoader: Boolean = false;
  rtlChnage = "left"
  showLoader: Observable<Boolean>
  constructor(
    private eventService: EventService,
    private route: ActivatedRoute,
    private store: Store<AppState>,
    private authService: AuthenticationService,
    private authCookieService: AuthCookieService,
    private router: Router,
    private authAlertService: AuthAlertService,
    // private locationService: LocationsService,
    private cookieService: CookieService,
    private ApiService: ApiService,
  ) {
  //  setInterval(() => {
  //   this.rtlChnage= this.ApiService.GetRtlData()
  //  }, 0);
   }

   storeId = this.cookieService.get("storeId");
   interval$ = interval(1000);
  ngOnInit() {
    // const token = this.cookieService.get("token");
    // const payload = {
    //     storeId: this.storeId,
    //   };
    // this.locationService.setStoreCurrency(payload, token);
    // this.loading = this.store.select(getLoading)

    // this.checkBoxValue = localStorage.getItem('isTestModeOn') == '1' ? "true": "false";
    // // handling test mode switching menu state issues
    // if(this.checkBoxValue == "true") {
    //   this.isMenuResetOnTestMode = true
    // } else {
    //   this.isMenuResetOnTestMode = false
    // }

    // default settings
    interval(1000)
    .pipe(takeUntil(this.destroy$))
    .subscribe(() => {
      this.rtlChnage= this.ApiService.GetRtlData()
    });
    this.layoutType = LAYOUT_VERTICAL;
    this.layoutType = "right";
    this.layoutwidth = LAYOUT_WIDTH;
    this.topbar = TOPBAR;

    // listen to event and change the layout, theme, etc
    this.eventService.subscribe('changeLayout', (layout) => {
      this.layoutType = layout;
    });
    this.showLoader = this.store.select(getLoading);
    this.LayoutWidth(this.layoutwidth);

    this.eventService.subscribe('changeWidth', (width) => {
      this.layoutwidth = width;
      this.LayoutWidth(this.layoutwidth);
    });
    this.token = this.route.snapshot.queryParamMap.get('token');
    this.returnUrl = this.route.snapshot.queryParamMap.get('returnUrl');
    console.log(this.token, this.returnUrl)
    if (this.token && this.returnUrl) {
      this.crossLinkLogin()
    }
    this.layoutType = "right"
  }

  ngOnDestroy(){
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngAfterViewInit() {
  }

  LayoutWidth(width: string) {
    switch (width) {
      case "fluid":
        document.body.setAttribute("data-layout-size", "fluid");
        document.body.classList.remove("vertical-collpsed");
        document.body.removeAttribute("data-layout-scrollable");
        break;
      case "boxed":
        document.body.setAttribute("data-layout-size", "boxed");
        document.body.classList.add("vertical-collpsed");
        document.body.removeAttribute("data-layout-scrollable");
        break;
      case "scrollable":
        document.body.removeAttribute("data-layout-size");
        document.body.setAttribute("data-layout-scrollable", "true");
        document.body.setAttribute("data-layout-size", "fluid");
        document.body.classList.remove("right-bar-enabled", "vertical-collpsed");
      default:
        document.body.setAttribute("data-layout-size", "fluid");
        break;
    }
  }

  /**
   * Check if the vertical layout is requested
   */
  isVerticalLayoutRequested() {
    return this.layoutType === LAYOUT_VERTICAL;
  }

  /**
   * Check if the horizontal layout is requested
   */
  isHorizontalLayoutRequested() {
    return this.layoutType === LAYOUT_HORIZONTAL;
  }
  isrightLayoutRequested() {
    return this.layoutType === 'right';
  }
  async crossLinkLogin() {
    this.crossLinkLoader = true;
    this.authService.magicLinkLogin(this.token).subscribe(res => {
      if (res.success == 0) {
        this.router.navigate['/account/login']
        //this.crossLinkLoader = false
      } else {
        this.crossLinkLoader = false;
      }
    }, (error) => {
      this.authAlertService.error(error);
      this.router.navigate['/account/login']
      //this.crossLinkLoader = false
    }
    )
  }

  // ##LOADING
  // onSettingsButtonClicked() {
  //   document.body.classList.toggle('right-bar-enabled');
  // }
  // isCondensed = false;
  // onToggleMobileMenu() {
  //   this.isCondensed = !this.isCondensed;
  //   document.body.classList.toggle('sidebar-enable');
  //   document.body.classList.toggle('vertical-collpsed');

  //   if (window.screen.width <= 768) {
  //     document.body.classList.remove('vertical-collpsed');
  //   }
  // }
}
