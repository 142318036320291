<div class="searchbar-main">
  <div class="search-header">
    <div class="back-arrow" (click)="Backfunction()">
      <i class="fa-solid fa-arrow-left" style="cursor: pointer;"></i>
    </div>
    <div class="w-100 position-relative">
      <input
        type="search"
        [placeholder]="'What are you looking for...' | translate | async"
        class="search-theme w-100"
        [(ngModel)]="searchitem"
        (keyup)="search($event.target.value)"
        (keyup.enter)="completeSearch()"
      />
      <i class="fa-solid fa-magnifying-glass search-icon"></i>
      <i
        class="fa-solid fa-xmark sraech-close"
        style="cursor: pointer;"
        *ngIf="searchitem != ''"
        (click)="clearSearched()"
      ></i>
    </div>
  </div>
  <div class="detail-data">
    <div class="recent-search" >
      <div *ngIf="searchitem === '' && rsearch?.length && this.StoreName === 'eatos'">
      <div class="recent-search-text d-flex">
        <p class="m-0 f-14 fw-600">{{ "Recent Search" | translate | async }}</p>
        <span class="m-0 f-14 fw-400" style="cursor: pointer;"
        (click)="clearResentSearch()"
          >{{ "Clear all" | translate | async }}</span>
      </div>
    </div>
    <div *ngIf="searchitem === '' && retailOsrsearch?.length && this.StoreName === 'Retailos'">
      <div class="recent-search-text d-flex">
        <p class="m-0 f-14 fw-600">{{ "Recent Search" | translate | async }}</p>
        <span class="m-0 f-14 fw-400" style="cursor: pointer;"
        (click)="clearResentSearch()"
          >{{ "Clear all" | translate | async }}</span>
      </div>
    </div>
      <ul class="search-data" *ngIf="this.StoreName === 'eatos'">
        <li class="data-box d-flex" *ngFor="let recentSearch of rsearch">
          <div class="d-flex align-items-center justify-content-between w-100" >
            <div
              (click)="QueryUpdateFuncation(recentSearch)"
              routerLink="{{ recentSearch.link }}"
            >
              <!-- <i class="fa-solid fa-arrow-right-long right-arrow"></i> -->
              <p class="f-14 fw-400 managmaent-text">{{recentSearch.link | translate | async}}</p>
              <p class="fw-600 research-text">{{ recentSearch.name | translate | async }}</p>
              <p class="solid-text mb-0">{{recentSearch.discription | translate | async}}</p>
            </div>
            <!-- <div (click)="DeleteRSearch(recentSearch.name)">
              <i class="fa-solid fa-xmark close-icon"></i>
            </div> -->
          </div>
        </li>
      </ul>
      <ul class="search-data" *ngIf="this.StoreName === 'Retailos'">
        <li class="data-box d-flex" *ngFor="let recentSearch of retailOsrsearch">
          <div class="d-flex align-items-center justify-content-between w-100" >
            <div
              (click)="QueryUpdateFuncation(recentSearch)"
              routerLink="{{ recentSearch.link }}"
            >
              <!-- <i class="fa-solid fa-arrow-right-long right-arrow"></i> -->
              <p class="f-14 fw-400 managmaent-text">{{recentSearch.link | translate | async }}</p>
              <p class="fw-600 research-text">{{ recentSearch.name | translate | async }}</p>
              <p class="solid-text mb-0">{{recentSearch.discription | translate | async}}</p>
            </div>
            <!-- <div (click)="DeleteRSearch(recentSearch.name)">
              <i class="fa-solid fa-xmark close-icon"></i>
            </div> -->
          </div>
        </li>
      </ul>
    </div>
  </div>

  <div class="detail-data">
    <div class="recent-search" *ngIf="QuickLinks.length != 0 && searchname">
      <div>
        <p class="m-0 f-14 fw-600 pb-3">{{ "Suggested search" | translate | async }}</p>
      </div>
      <ul class="search-data">
        <li class="data-box d-flex" *ngFor="let result of QuickLinks">
          <div
            class="d-flex align-items-center justify-content-between w-100"
            (click)="QueryUpdateFuncation(result)"
            [routerLink]="result.link !== '/#' ? result.link : null"
            >
            <div>
              <!-- <i class="fa-solid fa-arrow-right-long right-arrow"></i> -->
              <p class="f-14 fw-400 managmaent-text">{{result.link | translate | async}}</p>
              <p class="fw-600 research-text" [innerHTML]="highlightSearchTerm(result.name) | translate | async"></p>
              <p class="solid-text mb-0">{{result.discription | translate | async}}</p>
              <!-- <span [innerHTML]="highlightSearchTerm(result.name)"></span>
              <p>{{result.discription}}</p> -->
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>

  <div class="detail-data">
    <div class="recent-search" *ngIf="QuickLinks.length != 0 && searchname">
      <div>
        <p class="m-0 f-14 fw-600 pb-3">{{ "Support" | translate | async }}</p>
      </div>
      <ul class="search-data">
        <li class="data-box d-flex" *ngFor="let result of supportResults">
          <!-- <li class="data-box d-flex" *ngFor="let result of QuickLinks"> -->
         <a href="{{result.link}}" target="_blank">
          <div
          class="d-flex align-items-center justify-content-between w-100"

        >
          <div>
            <!-- <p class="f-14 fw-400 managmaent-text">Menu Management</p> -->

            <p class="fw-600 research-text result-data" [innerHTML]="highlightSearchTerm(result.suportName) | translate | async"></p>
            <!-- <p class="fw-600 research-text result-data" [innerHTML]="highlightSearchTerm(result.name)"></p> -->

            <!-- <p class="solid-text mb-0">{{result.support}}</p> -->
            <p class="solid-text mb-0">{{result.Discroption | translate | async}}</p>

            <!-- <img src="../../../../assets/images/queshion-mark.png" alt="" /> -->
            <!-- <p class="m-0 f-16 fw-600">menu</p> -->
            <!-- <span [innerHTML]="highlightSearchTerm(result.name)"></span>
            <p>{{result.discription}}</p> -->
          </div>
        </div>
         </a>
        </li>
      </ul>
    </div>
  </div>
  <!-- <div class="detail-data">
    <div *ngIf="!searchname && searchitem != ''" class="recent-search">
      <div>
        <p class="m-0 f-14 fw-600 pb-3">Quick Actions</p>
      </div>
      <ul class="search-data">
        <li class="data-box d-flex" *ngFor="let recentSearch of SupportQucikLinks">
          <div class="d-flex align-items-center justify-content-between w-100" >
            <div
              class="d-flex align-items-center justify-content-between w-100"
              (click)="QueryUpdateFuncation(recentSearch)"
              routerLink="{{ recentSearch.link }}"
            >
              <p class="f-14 fw-400 managmaent-text">{{recentSearch.name}}</p>
              <p class="fw-600 research-text">{{ recentSearch.name }}</p>
              <p class="solid-text mb-0">{{recentSearch.discription}}</p>
            </div>
          </div>
        </li>
      </ul>
      <div class="recent-search" >
        <div>
          <p class="m-0 f-14 fw-600 pb-3">Support</p>
        </div>
        <ul class="search-data">
          <li class="support-data d-flex" *ngFor="let result of SupportFilter">
            <a href="{{result.link}}">
              <div
                class="d-flex align-items-center justify-content-between w-100"

              >
                <div>
                  <p class="fw-600 research-text result-data">{{result.suportName}}</p>
                  <p class="f-14 mb-0 support-des">{{result.Discroption}}</p>
                </div>
              </div>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div> -->
  <div class="detail-data">
    <div class="search-detalis" >
      <div class="no-found" *ngIf="QuickLinks.length == 0 && !searching && searchitem != ''">
        <h1 class="fw-600 mb-2 text-center">{{ "No result found" | translate | async }}</h1>
        <p class="f-14 fw-400 mb-0 text-center">{{ "Try to search with different word" | translate | async }}</p>
      </div>
      <div  *ngIf="!IsLinkhide  && !searching">
        <!-- //*ngIf="searchitem === ''" -->
        <div class="box-heading">
          <p class="action-data f-14 fw-600">{{ "Quick Actions" | translate | async }}</p>
        </div>
        <div class="box-main row">
          <div
            class="content-box browser-data"
            *ngFor="let subitem of QuickLinksModule"
            (click)="QueryUpdateFuncation(subitem)"
            routerLink="{{ subitem.link }}"
          >
            <p class="mb-1 f-14 fw-600">{{ subitem.name | translate | async }}</p>
            <!-- <p class="f-14 fw-400 discription-data mb-0">{{subitem.discription}}</p> -->
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="detail-data">
    <div class="search-detalis pt-5" *ngIf="!IsLinkhide  && !searching">
      <div class="box-heading">
        <p class="f-14 fw-600">{{ "Browse" | translate | async }}</p>
      </div>
      <div class="box-main row">
        <div
          class="content-box browser-data"
          *ngFor="let subitem of Browse"
          (click)="setMenuAcrivation(subitem)"
          routerLink="{{ subitem.link }}"
        >
          <p class="m-0 f-14 fw-600">{{ subitem.name | translate | async }}</p>
        </div>
      </div>
    </div>
  </div>
</div>
