import { salesOrderFecthEmployeeSuccess, salesOrderFetchSuccess, salesOrdersFetchFailure, salesOrdersFetchStart, salesOrdersFetchSuccess } from './salesorder.actions';
import { createReducer, on } from '@ngrx/store';
import { initialState } from './salesorders.state';

const _salesOrderReducer = createReducer(
    initialState,
    on(salesOrdersFetchStart, (state) => {
        return {
            ...state,
            showLoader: true
        };
    }),
    on(salesOrdersFetchSuccess, (state, action) => {
        return {
            ...state,
            data: action.data,
            meta: action.meta,
            showLoader: false
        };
    }),
    on(salesOrdersFetchFailure, (state, action) => {
        return {
            ...state,
            showLoader: false
        };
    }),
    on(salesOrderFecthEmployeeSuccess, (state, action) => {
        return {
            ...state,
            employee: action.employee
        };
    }),
    on(salesOrderFetchSuccess, (state, action) => {
        return {
            ...state,
            childOrders: action.childOrders,
            orders: action.orders,
            pageMetaData: action.metaData
        };
    }),
);

export function SalesOrderReducer(state, action) {
    return _salesOrderReducer(state, action);
}