import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { CookieService } from "ngx-cookie-service";
import { Subject } from "rxjs";
import { environment } from "src/environments/environment";
import Swal from "sweetalert2";
import { AlertService } from "./../AlertService/alert.service";
import { ApiService } from "src/app/services/api";

@Injectable({
  providedIn: "root",
})
export class OsAppService {
  token = this.cookieService.get("token");
  storeId = this.cookieService.get("storeId");
  merchantId = this.cookieService.get("merchantId");
  isLoadingChangeSub = new Subject<boolean>();

  GLOBALURL: String;
  constructor(
    private http: HttpClient,
    private router: Router,
    private cookieService: CookieService,
    private toastr: AlertService,
    private ApiService: ApiService
  ) {}

  // create item
  newOsApp(payload) {
    this.isLoadingChangeSub.next(true);
    return this.http
      .post(this.GLOBALURL + "/os-app", payload, {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
          Authorization: this.cookieService.get("token"),
        }),
      })
      .subscribe(
        (response: any) => {
          if (response.success === 0) {
            this.toastr.clear();
            this.toastr.error(response.message);
            this.isLoadingChangeSub.next(false);
          } else {
            this.toastr.clear();
            this.toastr.success(response.message);
            this.isLoadingChangeSub.next(false);
            this.router.navigate(["master/os-apps"]);
          }
        },
        (error) => {
          const message = this.ApiService.errorMessage(error)
          this.toastr.clear();
          this.toastr.error(message);
          this.isLoadingChangeSub.next(false);
        }
      );
  }

  // get item by id
  getOsApp(appId: string) {
    return this.http.get(this.GLOBALURL + "/os-app", {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: this.token,
      }),
      params: new HttpParams().set("appId", appId),
      // .set("storeId", this.storeId)
      // .set("merchantId", this.merchantId),
    });
  }
  
  // get all items
  getOsApps() {
    return this.http.get(this.GLOBALURL + "/os-app", {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: this.token,
      }),
      // .set("storeId", this.storeId)
      // .set("merchantId", this.merchantId),
    });
  }

  // update item by id
  updateOsApp(payload) {
    this.isLoadingChangeSub.next(true);
    return this.http
      .put(this.GLOBALURL + "/os-app", payload, {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
          // Authorization: this.token,
        }),
        // params: new HttpParams()
        //   .set("storeId", this.storeId)
        //   .set("merchantId", this.merchantId),
      })
      .subscribe(
        (response: any) => {
          if (response.success === 0) {
            this.toastr.clear();
            this.toastr.error(response.message);
            this.isLoadingChangeSub.next(false);
          } else {
            this.toastr.clear();
            // this.toastr.success(response.message);
            // this.toastr.success(response.response);
            this.toastr.success("Action updated successfully.");
            this.isLoadingChangeSub.next(false);
            //this.router.navigate(["/menu/categories"]);
          }
        },
        (error) => {
          const message = this.ApiService.errorMessage(error)
          this.toastr.clear();
          this.toastr.error(message);
          this.isLoadingChangeSub.next(false);
        }
      );
  }
}
