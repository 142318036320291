//import { AuthRes } from "src/app/core/models/auth.models";

import { IOrderMetaData, IorderSummary } from "src/app/core/models/sales/orders/summary.model";

export interface SalesOrderState {
    data: IorderSummary;
    meta: IOrderMetaData;
    showLoader: Boolean;
    employee: any [];
    orders: any [];
    childOrders: any [];
    pageMetaData: any;
}

export const initialState: SalesOrderState = {
    data: {
        orders: 0,
        closedOrders: 0,
        netSales: "0.00",
        discounts: "0.00",
        serviceCharges: "0.00",
        deliveryAmount: "0.00",
        promoCodeAmount: "0.00",
        grossSales: "0.00",
        tax: "0.00",
        tips: "0.00",
        totalAmount: "0.00",
    },
    meta: {
        storeName: "",
        storeTimezone: "",
        startDate: "",
        endDate: ""
    },
    showLoader: false,
    employee:[],
    orders:[],
    childOrders: [],
    pageMetaData: null
};