import {
  HttpClient,
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, throwError } from "rxjs";

import { AuthenticationService } from "../services/auth.service";

import { CookieService } from "ngx-cookie-service";
import { catchError, filter, switchMap, take } from "rxjs/operators";
import { ApiService } from "../../services/api";
import { AlertService } from "../services/AlertService/alert.service";
import { AuthCookieService } from "../services/authcookies.service";
@Injectable()
export class JwtInterceptor implements HttpInterceptor {

  constructor(
    private authenticationService: AuthenticationService,
    private cookieService: CookieService,
    private authCookieService: AuthCookieService,
    private alertService: AlertService,
    private http: HttpClient,
    private ApiService: ApiService
  ) {
    console.log("JWT INTERCEPTOR_RUNNING");
  }
  isrefreshing: Boolean = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {

    // add auth header with jwt if account is logged in and request is to the api url
    const account = this.authenticationService.getUserFromLocalStorage();
    const isLoggedIn = account?.response?.token;
    const isApiUrl = request.url.startsWith(this.ApiService.getBaseURL());
    const token = this.cookieService.get("token");
    console.log('Intercepting request to:', request.url);
    let authreq = request;
    let addedHeader = false
    // authreq = this.AddCustomheader(request)
    if (isLoggedIn && isApiUrl) {
      console.log("Is-Logged-IN, ApiURL");
      // request = request.clone({
      //   setHeaders: { Authorization: `${token}` },
      // });
      authreq = this.AddTokenheader(request, token);
      addedHeader = true
    }

    if (!request.url.includes("ipify") && this.ApiService.getIp() !== null && request.url.includes("/api/") && !addedHeader)
      authreq = this.AddCustomheader(request);

    //return next.handle(request);
    return next.handle(authreq).pipe(
      // tap(
      //   () => {

      //   },
      //   (err: any) => {
      //     if (err instanceof HttpErrorResponse) {
      //       if (err.status !== 401) {
      //         return;
      //       } else {
      //         console.log("hello interceptor called")
      //         // this.authenticationService.logout();
      //         // window.location.href = "/account/login";
      //         return this.handleRefreshToken(request, next)
      //       }
      //       // this.router.navigate(["/login"]).then(() => {
      //       //  window.location.reload();
      //       // });
      //     }
      //   }
      // ),
      catchError((error: HttpErrorResponse) => {
        if (
          error instanceof HttpErrorResponse &&
          !authreq.url.includes("user/login") &&
          !authreq.url.includes("user/register") &&
          error.status === 401
        ) {
          return this.handleRefreshToken(request, next);
        }
        console.log(error);
        let errorMsg = error;
        // let errorMsg = error.error.message;
        // if (error.error instanceof ErrorEvent) {
        //   console.log("This is client side error");
        //   errorMsg = `Error: ${error.error.message}`;
        // } else {
        //   console.log("This is server side error");
        //   errorMsg = `Error Code: ${error.status},  Message: ${error.message}`;
        // }
        return throwError(errorMsg);
      })
    );
  }
  handleRefreshToken(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isrefreshing) {
      this.isrefreshing = true;
      this.refreshTokenSubject.next(null);
      const token = this.authenticationService.getToken();
      const check = token !== null ? true : false;
      if (token !== null) {
        return this.authenticationService.refreshToken().pipe(
          switchMap((data: any) => {
            if (data.success == 1) {
              this.isrefreshing = false;
              this.cookieService.deleteAll("/");
              //set userData in cookie
              this.authCookieService.addCookies(data);
              //set userdata in localstorage
              this.authenticationService.setUserInLocalStorage(data);
              this.refreshTokenSubject.next(data.response.token);
              return next.handle(
                this.AddTokenheader(request, data.response.token)
              );
            } else {
              this.isrefreshing = false;
              this.alertService.error("Session inavlid");
              console.log("JWT INTERCEPT ALEEEERT 1");
              //return throwError(data.response.message)
            }
          }),
          catchError((errorData) => {
            this.isrefreshing = false;
            console.log("JWT INTERCEPT ALEEEERT 2");
            this.alertService.error("Session inavlid");
            this.authenticationService.logout();
            window.location.href = "/account/login";
            return throwError(errorData);
          })
        );
      }
    } else {
      //this.isrefreshing = false;
      return this.refreshTokenSubject.pipe(
        filter((token) => token !== null),
        take(1),
        switchMap((token) => next.handle(this.AddTokenheader(request, token)))
      );
      //this.toastr.error("Session inavlid")
      // this.authenticationService.logout();
      // window.location.href = "/account/login";
    }
  }
  AddTokenheader(request: HttpRequest<any>, token: any) {
    console.log("Token, ip added");
    return request.clone({
      headers: request.headers.set("Authorization", `${token}`)
        .set('x-ip', this.ApiService.getIp())
        .set('x-client', this.ApiService.getBrowserDetails())
    });
  }
  AddCustomheader(request: HttpRequest<any>) {
      return request.clone({
        headers: request.headers.set('x-ip', this.ApiService.getIp())
          .set('x-client', this.ApiService.getBrowserDetails())
      });

  }
}
