import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import {
  NgbCollapseModule,
  NgbDatepickerModule,
  NgbTimepickerModule,
  NgbDropdownModule,
  NgbModule,
} from "@ng-bootstrap/ng-bootstrap";

import { PagetitleComponent } from "./pagetitle/pagetitle.component";
import { LoaderComponent } from "./loader/loader.component";
import { LocationMenuComponent } from "./location-menu/location-menu.component";
import { NgxIntlTelInputModule } from "ngx-intl-tel-input";
import { NgxSkeletonLoaderModule } from "ngx-skeleton-loader";
import { AlertComponent } from "./alert/alert.component";
import { AuthAlertComponent } from "./auth-alert/auth-alert.component";
import { CrosslinkButtonComponent } from "./crosslink-button/crosslink-button.component";
import { PaginateSelectComponent } from "./paginate-select/paginate-select.component";
import { NgOtpInputModule } from "ng-otp-input";
import { TransactionDetailHeaderSharedComponent } from "./modal-transactions/transaction-detail-header-shared/transaction-detail-header-shared.component";
import { TransactionDetailBodySharedComponent } from "./modal-transactions/transaction-detail-body-shared/transaction-detail-body-shared.component";
import { TransactionPartialrefundModalSharedComponent } from "./modal-transactions/transaction-partialrefund-modal-shared/transaction-partialrefund-modal-shared.component";
import { TransactionFullrefundModalSharedComponent } from "./modal-transactions/transaction-fullrefund-modal-shared/transaction-fullrefund-modal-shared.component";
import { TransactionLoaderModalSharedComponent } from "./modal-transactions/transaction-loader-modal-shared/transaction-loader-modal-shared.component";
import { TransactionOrderTableSharedComponent } from "./modal-transactions/transaction-order-table-shared/transaction-order-table-shared.component";
import { TransactionReceiptModalSharedComponent } from "./modal-transactions/transaction-receipt-modal-shared/transaction-receipt-modal-shared.component";
import { TransactionOrderEditSharedComponent } from "./modal-transactions/transaction-order-edit-shared/transaction-order-edit-shared.component";
import { TransactionDetailFooterSharedComponent } from "./modal-transactions/transaction-detail-footer-shared/transaction-detail-footer-shared.component";
import { CurrencyMaskModule } from "ng2-currency-mask";
import { TransactionSummarySharedComponent } from "./modal-transactions/transaction-summary-shared/transaction-summary-shared.component";
import { ChangePasswordComponent } from "./change-password/change-password.component";
import { ChangePinComponent } from "./change-pin/change-pin.component";
import { UiSwitchModule } from 'ngx-ui-switch';
import { RouterModule } from "@angular/router";
import { AccessControlDirective } from '../../pages/access-control.directive';
import { permissionDirective } from '../../pages/permission.directive';
import { accessDirective } from "src/app/pages/access.directive";
import { AgmCoreModule } from '@agm/core';
import { environment } from 'src/environments/environment';
import { LearnMoreComponent } from "./learn-more/learn-more.component";
import { CoreModule } from "src/app/core/core.module";
@NgModule({
  declarations: [
    AccessControlDirective,
    permissionDirective,
    accessDirective,
    PagetitleComponent,
    LoaderComponent,
    LocationMenuComponent,
    AlertComponent,
    AuthAlertComponent,
    CrosslinkButtonComponent,
    PaginateSelectComponent,
    TransactionDetailHeaderSharedComponent,
    TransactionDetailBodySharedComponent,
    TransactionPartialrefundModalSharedComponent,
    TransactionFullrefundModalSharedComponent,
    TransactionLoaderModalSharedComponent,
    TransactionOrderTableSharedComponent,
    TransactionReceiptModalSharedComponent,
    TransactionOrderEditSharedComponent,
    TransactionDetailFooterSharedComponent,
    TransactionSummarySharedComponent,
    ChangePasswordComponent,
    ChangePinComponent,
    LearnMoreComponent,
  ],
  imports: [
    CoreModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbCollapseModule,
    NgbDatepickerModule,
    NgbTimepickerModule,
    NgbDropdownModule,
    NgxIntlTelInputModule,
    NgxSkeletonLoaderModule,
    NgOtpInputModule,
    CurrencyMaskModule,
    NgbModule,
    UiSwitchModule,
    RouterModule,
    CoreModule,
    AgmCoreModule.forRoot({
      apiKey: environment.googleApiKey,
      libraries: ["places"]
    }),
    CoreModule
  ],
  exports: [
    LearnMoreComponent,
    PagetitleComponent,
    LoaderComponent,
    LocationMenuComponent,
    AlertComponent,
    AuthAlertComponent,
    CrosslinkButtonComponent,
    PaginateSelectComponent,
    TransactionDetailHeaderSharedComponent,
    TransactionDetailBodySharedComponent,
    TransactionPartialrefundModalSharedComponent,
    TransactionFullrefundModalSharedComponent,
    TransactionLoaderModalSharedComponent,
    TransactionOrderTableSharedComponent,
    TransactionReceiptModalSharedComponent,
    TransactionOrderEditSharedComponent,
    TransactionDetailFooterSharedComponent,
    TransactionSummarySharedComponent,
    ChangePasswordComponent,
    ChangePinComponent,
    AccessControlDirective,
    permissionDirective,
    accessDirective

  ],
  // providers: [LocationsService, TitleCasePipe]
})
export class UIModule { }
