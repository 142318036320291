import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
@Injectable()
export class ApiService {
  private baseURL = new BehaviorSubject<string>('');
  prodURL: string = '/api'
  private jsonUrl = 'assets/i18n/timezone.json';
  browserName
  browserVersion
  constructor(private http: HttpClient) { }

  getTimezones(): Observable<string[]> {
    return this.http.get<string[]>(this.jsonUrl);
  }

  enableMode(mode: Number): any {

    if (mode == 1) {
      //maintain isTestModeOn value on localstorage
      localStorage.setItem('isTestModeOn', '1');
      //this.baseURL.next('/papi')
    } else {
      localStorage.setItem('isTestModeOn', '0');
      //this.baseURL.next('/api')
    }
  }
  getBaseURL() {
    let isTestModeOn = localStorage.getItem('isTestModeOn')
    if (isTestModeOn == '1')
      return '/papi'
    else
      return '/api'
  }
  getIp() {
    return localStorage.getItem('ipAddress')
  }

  getCurrentLocationDetails() {
    return localStorage.getItem('currentLocationDetails');
  }

  getBrowserDetails() {
    this.browserName = this.detectBrowserName();
    this.browserVersion = this.detectBrowserVersion();
    return this.browserName + "  Version:" + this.browserVersion
  }

  detectBrowserName() {
    const agent = window.navigator.userAgent.toLowerCase()
    switch (true) {
      case agent.indexOf('edge') > -1:
        return 'EDGE';
      case agent.indexOf('opr') > -1 && !!(<any>window).opr:
        return 'OPERA';
      case agent.indexOf('chrome') > -1 && !!(<any>window).chrome:
        return 'CHROME';
      case agent.indexOf('trident') > -1:
        return 'TRIDENT';
      case agent.indexOf('firefox') > -1:
        return 'FIRFOX';
      case agent.indexOf('safari') > -1:
        return 'SAFARI';
      default:
        return 'OTHER';
    }
  }

  detectBrowserVersion() {
    var userAgent = navigator.userAgent, tem,
      matchTest = userAgent.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];

    if (/trident/i.test(matchTest[1])) {
      tem = /\brv[ :]+(\d+)/g.exec(userAgent) || [];
      return 'IE ' + (tem[1] || '');
    }
    if (matchTest[1] === 'Chrome') {
      tem = userAgent.match(/\b(OPR|Edge)\/(\d+)/);
      if (tem != null) return tem.slice(1).join(' ').replace('OPR', 'Opera');
    }
    matchTest = matchTest[2] ? [matchTest[1], matchTest[2]] : [navigator.appName, navigator.appVersion, '-?'];
    if ((tem = userAgent.match(/version\/(\d+)/i)) != null) matchTest.splice(1, 1, tem[1]);
    return matchTest.join(' ');
  }

  // message: string = "";
  errorMessage(error) {
    let message = error;
    if ( message && typeof message == 'string' &&
      message.includes(
        "Http failure response for (unknown url): 0 Unknown Error"
      )
    )
      message = "Please check the internet connection";
    else
      message = (error && error.error && error.error.message) ? error.error.message : message;

    return message;
  }

  private ShareRtlData:any ="left"

  setRtlData(data){
    console.log('check if RTL works when change this lanugae ');
    this.ShareRtlData = data
  }

  GetRtlData(){
    return this.ShareRtlData
  }

  console = () => {
    return {
      log: (...args) => null
    }
    // return console
  }

}
