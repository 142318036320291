<div *canAccess="['ChangePin', [0,2]]" class="modal-body update_num">
  <div class="empty-discounts-note"
    style="display: flex; flex-direction: column; justify-content: center; align-items: center;">
    <div style="width: 100%; display: flex; justify-content: end; align-items: center;">
      <img style="padding: 5px; cursor: pointer;" src="assets\images\closeIcon.svg" class="" (click)="closeModal()" />
    </div>
    <i class="mdi mdi-lock" style="font-size: 50px;"></i>
    <div class="mt-3"></div>
    {{ 'You are not authorized to perform this operation' | translate | async }}
  </div>
</div>

<div *canAccess="['ChangePin', [1,3]]" class="modal-body update_num">
  <div class="d-flex justify-content-between mb-2">
    <div class="f-20 fw-600">{{ 'Change PIN' | translate | async }}</div>
    <div>
      <img src="assets\images\closeIcon.svg" class="" (click)="closeModal()" />
    </div>
  </div>
  <div class="f-14 fw-500 mb-3">
    <span>{{ 'Change the PIN associated with your devices.' | translate | async }}</span>
  </div>
  <form (keydown.enter)="$event.preventDefault()" #changePasswordForm="ngForm"
    (ngSubmit)="onSubmit(changePasswordForm)">
    <!-- WORKING ON THIS PART NOW -->
    <!-- <div>
    <div class="mb-3">
      <label for="pin" class="form-label">New PIN*</label>
      <div class="otp-data">
        <input
          #inputFields="ngModel"
          type="number"
          placeholder="-"
          [(ngModel)]="pin1"
          (input)="onPinInput($event, 0)"
        >
        <input
          #inputFields="ngModel"
          type="number"
          placeholder="-"
          [(ngModel)]="pin2"
          (input)="onPinInput($event, 1)"
        >
        <input
          #inputFields="ngModel"
          type="number"
          placeholder="-"
          [(ngModel)]="pin3"
          (input)="onPinInput($event, 2)"
        >
        <input
          #inputFields="ngModel"
          type="number"
          placeholder="-"
          [(ngModel)]="pin4"
          (input)="onPinInput($event, 3)"
        >
      </div>
    </div>
    <div class="mb-4">
      <label for="confirmPin" class="form-label">Confirm PIN*</label>
      <div class="otp-data">
        <input
          #inputFields="ngModel"
          type="number"
          placeholder="-"
          [(ngModel)]="confirmPin1"
          (input)="onPinInput($event, 4)"
        >
        <input
          #inputFields="ngModel"
          type="number"
          placeholder="-"
          [(ngModel)]="confirmPin2"
          (input)="onPinInput($event, 5)"
        >
        <input
          #inputFields="ngModel"
          type="number"
          placeholder="-"
          [(ngModel)]="confirmPin3"
          (input)="onPinInput($event, 6)"
        >
        <input
          #inputFields="ngModel"
          type="number"
          placeholder="-"
          [(ngModel)]="confirmPin4"
          (input)="onPinInput($event, 7)"
        >
      </div>
    </div>
  </div> -->


    <div>
      <div class="mb-3">
        <label for="pin" class="form-label">{{ 'New PIN*' | translate | async }}</label>
        <div class="otp-data">
          <ng-otp-input #ngPinInput disabled="true" [config]="config"
            (onInputChange)="onOtpChange($event)"></ng-otp-input>
        </div>
      </div>

      <!-- <div class="mb-4">
        <label for="confirmPin" class="form-label">Confirm PIN*</label>
        <div class="otp-data">
          <ng-otp-input #ngPinInput
          [config]="confirmconfig"
          (onInputChange)="onOtpConfirmChange($event)"
        ></ng-otp-input>
        </div>
      </div> -->

      <!-- ERROR -->
      <!-- <div *ngIf="pinErrorMsg"
          class="input-invalid-error" class="text-danger">
          NEW PIN must be the same as CONFIRM PIN.
      </div> -->
    </div>
    <div class="row mt-4">
      <div class="col-12">
        <button accessControl module="ChangePin" access="3" *ngIf="!isLoading"
          class="primary_btn f-18 w-100 fw-700 text-white" type="submit">
          {{ 'Change' | translate | async }}
        </button>

        <button *ngIf="isLoading" class="primary_btn f-18 w-100 fw-700 text-white" type="button" disabled>
          <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
          <span class="pl-2">&nbsp;&nbsp;{{ 'Please wait...' | translate | async }}</span>
        </button>
      </div>
    </div>
  </form>
</div>
