import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { Router } from "@angular/router";
import { CookieService } from "ngx-cookie-service";
import { Subject, Subscription, interval } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { TranslatePipe } from "src/app/core/pipes/translate.pipe";
import { AlertService } from "src/app/core/services/AlertService/alert.service";
import { TimezoneService } from "src/app/services/timezone";
import { ApiService } from "./../../../services/api";
import { BreadcrumbService } from "./../../../services/breadcrumb";
@Component({
  selector: "app-page-title",
  templateUrl: "./pagetitle.component.html",
  styleUrls: ["./pagetitle.component.scss"],
  providers: [TranslatePipe]
})
export class PagetitleComponent implements OnInit,OnDestroy {
  private destroy$ = new Subject<void>();

  @Input() breadcrumbItems;
  @Input() title: string;
  @Input() layout: Boolean;
  @Output() changed: EventEmitter<any> = new EventEmitter();

  public Timezone:any;
  isSuperAdmin = this.cookieService.get("superAdminPage") === "3" ? true : false;

  // create array of URL for not supported trainee mode
  skipTrinaeeModeUrls = [
    "/home",
    "/reports/sales/gross-transaction",
    "/reports/sales/gross-shift",
    "/reports/sales/gross-details",
    "/reports/sales/daily-operations-report/view-report",
    "/reports/labor/cashout",
    "/reports/labor/payroll",
    "/reports/exception/discount",
    "/reports/exception/refunds",
    "/reports/exception/cancel-order",
    "/sales/transactions",
    "/sales/orders",
    "/sales/deposits",
    "/sales/service-charges",
    "/sales/discounts",
    "/sales/feedback",
    "/business-settings/location",
    "/integration-marketplace",
  ];

  constructor(
    private router: Router,
    private cookieService: CookieService,
    private ApiService: ApiService,
    private BreadcrumbService: BreadcrumbService,
    private toastr: AlertService,
    private timezoneService: TimezoneService,
    private translatePipe: TranslatePipe
  ) {
    this.Timezone = this.timezoneService.get()
  }
  today = new Date();
  dayStatus: String = "";

  private todayDateSubscription: Subscription;

  breadcrumbList = [];

  checkBoxValue: any =
    localStorage.getItem("isTestModeOn") == "1" ? true : false;
  employeeName = this.cookieService.get("employeeName");
  storeName = this.cookieService.get("storeName");
  interval$ = interval(1000);
  ngOnInit(): void {
    // this.TodayDateFormate();
    // setInterval(() => {
    //   this.TodayDateFormate();
    // }, 500);
    interval(1000)
    .pipe(takeUntil(this.destroy$))
    .subscribe(() => {
      this.TodayDateFormate();
    });
    this.startIntervalDateFormate()

    this.employeeName = this.cookieService.get("employeeName");
    // throw new Error("Method not implemented.");
    var curHr = this.today.getHours();
    this.checkDayStatus(curHr);
    this.BreadcrumbService.getValue().subscribe((value) => {
      this.breadcrumbList = value;
    });

    //this is for adding error message on those page if trainee mode is not supported
    var testModeValue: any = localStorage.getItem("isTestModeOn");

    if (this.skipTrinaeeModeUrls.includes(this.router.url) && testModeValue > 0)
      this.toastr.error("Training Mode is not applicable on this page.", {
        autoClose: true,
      });
    else this.toastr.clear();
  }

  private startIntervalDateFormate() {
    const interval$ = interval(1000);
    this.todayDateSubscription = interval$.subscribe(() => {
      this.TodayDateFormate();
    });
  }

  hasUrlBreadrumb() {
    return this.breadcrumbList?.some((e) => e.url);
  }
  formateDate: any;

  timezoneName1 = "";

  TodayDateFormate() {
    try {
      const formattedTime = this.Timezone().format("D MMM YY, h:mm A z");
      if (formattedTime && typeof formattedTime === "string") {
        // let storeTimeZone = this.cookieService.get('timezone');
        // storeTimeZone = formattedTime.split(" ").at(-1);
        this.timezoneName1 = formattedTime.split(" ").at(-1);
        // let currentTimeZone = this.cookieService.get('timezone');
        // console.log("timezoneName1", this.timezoneName1)
        // console.log("currentTimeZone", currentTimeZone)

        const dateArray = formattedTime.split(" ");
        dateArray.pop(); // Remove the last element from the array
        const dateString = dateArray.join(" "); // Convert the modified array into a string
        this.formateDate = dateString;
      }
    } catch (error) {
      console.log("An error occurred while formatting the date:", error);
    }
  }
  toggleTestModeByNewStore() {
    this.ApiService.enableMode(1);

    // save current route first
    const currentRoute = this.router.url;

    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = "reload";
    this.router.navigate([currentRoute]);
    //this.router.navigate([currentRoute]);

    //  this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
    //      this.router.navigate([currentRoute]); // navigate to same route
    //  });
  }

  toggleTestMode(value: boolean) {
    //checking store is active or not
    this.checkBoxValue = value;
    let locations = JSON.parse(localStorage.getItem("locations"));
    let location = locations.find(
      (x) => x.id === this.cookieService.get("storeId")
    );
    if (location.isActive === 2) {
      this.toastr.error("Please activate your account first.");
      this.checkBoxValue = false;
      return;
    }

    this.ApiService.enableMode(this.checkBoxValue == true ? 1 : 0);

    // save current route first
    const currentRoute = this.router.url;

    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = "reload";
    this.router.navigate([currentRoute]);
    //this.router.navigate([currentRoute]);

    //  this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
    //      this.router.navigate([currentRoute]); // navigate to same route
    //  });
  }
  change(e: any) {
    localStorage.setItem("currency", e.currency);
    this.changed.emit(e.id);
    if (e.isActive === 2) {
      this.toggleTestModeByNewStore();
    }
  }
  checkDayStatus(curHr: any) {
    console.log(curHr);
    if (curHr < 12) {
      this.dayStatus = "Good Morning";
    } else if (curHr < 18) {
      this.dayStatus = "Good Afternoon";
    } else {
      this.dayStatus = "Good Evening";
    }
  }

  ngOnDestroy(){
    if(this.todayDateSubscription){
      this.todayDateSubscription.unsubscribe()
    }

      this.destroy$.next();
      this.destroy$.complete();

  }
}
