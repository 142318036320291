import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslatePipe } from './pipes/translate.pipe';
import { TranslateService } from './services/translate.service';
import { TranslateDirective } from './directives/translate.directive';
@NgModule({
  declarations: [TranslatePipe, TranslateDirective],
  imports: [
    CommonModule
  ],
  providers: [TranslateService],
  exports: [TranslatePipe, TranslateDirective]

})
export class CoreModule { }
