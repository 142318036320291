import { shareReplay, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from "@angular/common/http";
import { Observable } from 'rxjs/Rx';
import { environment } from 'src/environments/environment';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/store/app.state';
import { CookieService } from 'ngx-cookie-service';
import { ILocation } from 'selenium-webdriver';
import { ILocaData } from '../models/location.model';
import { locationSelect, locationSelectStart } from 'src/app/shared/ui/locationstate/location.actions';
import { locationAutoSelectBroadcastService } from './location-auto-select-broadcast.service';
import { AlertService } from './AlertService/alert.service';
import { ApiService } from "src/app/services/api";
import { AuthenticationService } from './auth.service';
import { LocationsService } from './locations/locations.service';

@Injectable({
  providedIn: 'root'
})

export class StoreLocationService {
  GLOBALURL: String = environment.globalUrlApi
  userAppId: any;
  constructor(
    private http: HttpClient,
    private store: Store<AppState>,
    private cookieService: CookieService,
    private toastr: AlertService,
    private locAutoService: locationAutoSelectBroadcastService,
    private ApiService: ApiService,
    private authService: AuthenticationService,
    private locationService: LocationsService,
  ) { }

  getStoreLocation(): Observable<any> {
    // var headerToken = { "Content-Type": "application/json", "Authorization": this.cookieService.get("token") };
    // const httpOptions = {
    //   headers: new HttpHeaders(headerToken)
    // };
    // // for store location it will load store from prod url
    // // store will not fetch from preprod env
    // return this.http.get<any>(
    //   this.ApiService.prodURL + '/store?merchantId=' + localStorage.getItem('MID'),
    //   httpOptions
    // ).pipe(shareReplay(1));
    var headerToken = { "Content-Type": "application/json", "Authorization": this.cookieService.get("token") };
    const httpOptions = {
      headers: new HttpHeaders(headerToken),
      params: {
        appId: this.authService.getUserFromLocalStorage()?.response?.appId // replace 'your-app-id' with your actual appId
      }
    };
    // for store location it will load store from prod url
    // store will not fetch from preprod env
    return this.http.get<any>(
      this.ApiService.prodURL + '/AllStores',
      httpOptions
    ).pipe(shareReplay(1));
  }
  getAllStores(): Observable<any> {
    var user = this.authService.getUserFromLocalStorage()
    this.userAppId = user.response.appId;
    var headerToken = { "Content-Type": "application/json", "Authorization": this.cookieService.get("token") };
    const params = new HttpParams().set('appId', this.userAppId);
    const httpOptions = {
      headers: new HttpHeaders(headerToken),
      params: params
    };
    // for store location it will load store from prod url
    // store will not fetch from preprod env
    return this.http.get<any>(
      this.ApiService.prodURL + '/AllStores',
      httpOptions
    ).pipe(shareReplay(1));
  }
  getStoreById(): Observable<any> {
    console.log("I WAS CALLED");
    var headerToken = { "Content-Type": "application/json", "Authorization": this.cookieService.get("token") };
    const httpOptions = {
      headers: new HttpHeaders(headerToken)
    };

    return this.http.get<any>(
      this.ApiService.prodURL + '/store?storeId=' + this.cookieService.get("storeId"),
      httpOptions
    ).pipe(shareReplay(1));
  }
  // create a Function and Subscribe to the getStoreById()
  // getStoreFullInformation() {
  //   this.getStoreById().subscribe((response: any) => {
  //     if (response.success == 0) {
  //       this.toastr.error(response.message);
  //     } else {
  //       console.log("STORE_ID_INFORMATION", response.response)
  //     }
  //   },
  //     (error) => {
  //       const message = this.ApiService.errorMessage(error)
  //       this.toastr.error(message);
  //     });
  // }
  findSetting = (storeInformation, settingName) => {
    const settingExist = storeInformation.settings.find((setting) => {
      return setting.settingName === settingName;
    });
    if (settingExist === undefined) {
      return 'false';
    }
    return storeInformation.settings.filter((setting) => {
      return setting.settingName === settingName;
    })[0].settingValue;
  }

  getCurrency = (storeInformation) => {
    const currentCurrency = this.findSetting(storeInformation, 'Currency');
    const currency = currentCurrency.replace(/ /g, "").split('-');
    return currency[1];
  }
  getSelected(locations: any) {
    // console.log("locations", locations)
    // this.getStoreFullInformation();

    var headerToken = { "Content-Type": "application/json", "Authorization": this.cookieService.get("token") };
    const httpOptions = {
      headers: new HttpHeaders(headerToken)
    };
    var id = this.cookieService.get("storeId");
    // var storeCurrentCurrencyCode: any = ["USD", "$"];
    var storeCurrentCurrency: any = '';

    // if(locations.length > 1 && id == '*') {
    //   id = locations[1].id
    //   this.cookieService.set("storeId", locations[1].id, 70, '/');
    //   this.cookieService.set("storeThemeColor", '#212121', 70, '/');
    //   this.cookieService.set('storeName', locations[1].text, 70, '/');
    //   this.cookieService.set('logo', locations[1].logo, 70, '/');
    //   this.cookieService.set('currency', locations[1].currency, 70, '/');
    //   this.cookieService.set('currencyCode', locations[1].currencyCode, 70, '/');
    //   localStorage.setItem('currency', locations[1].currency);
    //   localStorage.setItem('currency', '$');
    //   selected = locations[1];
    //   this.locAutoService.updateApprovalMessage(locations[1].id)
    // }
    this.cookieService.set('storeName', "All Locations", 70, '/');
    if (id !== "*") {
      for (let index = 0; index < locations.length; index++) {
        if (locations[index].id === id) {
          this.locationService.getStoreFullSetting(id, this.cookieService.get("token")).subscribe((response: any) => {


            // console.log(`********************`)
            // console.log(`********************`)
            // console.log(response)

            if (response.success == 0) {
              this.toastr.error(response.message);
            } else {
              response.response.forEach((element2) => {
                if (element2.settingName == 'Currency') {
                  // var currencyCode = element2.settingValue.replace(/ /g, "").split('-');
                  // var currencyCode = element2.settingValue;
                var currencyCode = this.cookieService.get('currency');

                  // var currencyCode = "";
                  // check the currency code length
                  // if (currencyCode?.length > 1) {
                    if (currencyCode !== '') {
                      storeCurrentCurrency = currencyCode;
                  } else {
                    // console.log("FROM_ELSE")
                    storeCurrentCurrency = "$";
                  }
                  console.log("storeCurrentCurrencyCode", storeCurrentCurrency)
                  var locationIndex = locations[index]
                  Object.assign({}, locationIndex, { currency: currencyCode })
                  // storeCurrentCurrency = storeCurrentCurrencyCode[1];
                  // console.log("currencyCode", typeof(currencyCode))
                  // console.log("currency", typeof(currency))
                  this.cookieService.set('currency', storeCurrentCurrency, 70, '/');
                  // this.cookieService.set('currencyCode', storeCurrentCurrencyCode, 70, '/');
                  localStorage.setItem('currency', storeCurrentCurrency);
                  // locationIndex.currency = currencyCode[1];
                  // locationIndex.currencyCode = currencyCode;
                }
              });
            }
          },
            (error) => {
              const message = this.ApiService.errorMessage(error)
              this.toastr.error(error);
            });

          // this.http.get(this.GLOBALURL + "/settings?storeId=" + id, httpOptions).pipe(shareReplay(1)).subscribe((response: any) => {


          //   console.log(`********************`)
          //   console.log(`********************`)
          //   console.log(response)

          //   if (response.success == 0) {
          //     this.toastr.error(response.message);
          //   } else {
          //     response.response.forEach((element2) => {
          //       if (element2.settingName == 'Currency') {
          //         // var currencyCode = element2.settingValue.replace(/ /g, "").split('-');
          //         var currencyCode = element2.settingValue;
          //         // var currencyCode = "";
          //         // check the currency code length
          //         // if (currencyCode?.length > 1) {
          //           if (currencyCode !== '') {
          //             storeCurrentCurrency = currencyCode;
          //         } else {
          //           // console.log("FROM_ELSE")
          //           storeCurrentCurrency = "$";
          //         }
          //         console.log("storeCurrentCurrencyCode", storeCurrentCurrency)
          //         var locationIndex = locations[index]
          //         Object.assign({}, locationIndex, { currency: currencyCode })
          //         // storeCurrentCurrency = storeCurrentCurrencyCode[1];
          //         // console.log("currencyCode", typeof(currencyCode))
          //         // console.log("currency", typeof(currency))
          //         this.cookieService.set('currency', storeCurrentCurrency, 70, '/');
          //         // this.cookieService.set('currencyCode', storeCurrentCurrencyCode, 70, '/');
          //         localStorage.setItem('currency', storeCurrentCurrency);
          //         // locationIndex.currency = currencyCode[1];
          //         // locationIndex.currencyCode = currencyCode;
          //       }
          //     });
          //   }
          // },
          //   (error) => {
          //     const message = this.ApiService.errorMessage(error)
          //     this.toastr.error(error);
          //   });

          var selected = locations[index];


          this.store.dispatch(locationSelectStart({ show: true }))
          this.store.dispatch(locationSelect({ selected: selected }))
          // this.cookieService.set("storeThemeColor", selected.storeThemeColor, 70, '/');
          console.log("selected.LOCATION_DETAILS", selected)
          this.cookieService.set("storeThemeColor", '#212121', 70, '/');
          this.cookieService.set('storeName', selected.text, 70, '/');
          this.cookieService.set('logo', selected.logo, 70, '/');
          // this.cookieService.set('currency', storeCurrentCurrency, 70, '/');
          // this.cookieService.set('currencyCode', storeCurrentCurrencyCode, 70, '/');
          this.cookieService.set('storeCountry', selected.storeCountry, 70, '/');
          this.cookieService.set('timezone', selected.storeTimezone, 70, '/');

          // document.documentElement.style.setProperty('--wbColor', selected.storeThemeColor)
          document.documentElement.style.setProperty('--wbColor', '#212121')
          // console.log("My_CUrrency", this.cookieService.get('currency'))

          break;
        } else {
          selected = locations[0];
          this.store.dispatch(locationSelectStart({ show: true }))
          this.store.dispatch(locationSelect({ selected: selected }))
          this.cookieService.set('logo', selected.logo, 70, '/');
          // this.cookieService.set("storeThemeColor", selected.storeThemeColor, 70, '/');
          this.cookieService.set("storeThemeColor", '#212121', 70, '/');
          this.cookieService.set('storeName', "All Locations", 70, '/');
          this.cookieService.set('currency', '$', 70, '/');
          this.cookieService.set('currencyCode', '', 70, '/');
          this.cookieService.set('timezone', selected.storeTimezone, 70, '/');
          localStorage.setItem('currency', '$');

          //alert("2"+selected.storeTimezone)
          // document.documentElement.style.setProperty('--wbColor', selected.storeThemeColor)
          document.documentElement.style.setProperty('--wbColor', '#212121')
        }
      }
    } else {
      // document.documentElement.style.setProperty('--wbColor', locations[0].storeThemeColor)
      document.documentElement.style.setProperty('--wbColor', '#212121')
      // this.cookieService.set("storeThemeColor", locations[0].storeThemeColor, 70, '/');
      this.cookieService.set("storeThemeColor", '#212121', 70, '/');
      this.cookieService.set('storeName', locations[0].text, 70, '/');
      this.cookieService.set('logo', locations[0].logo, 70, '/');
      // this.cookieService.set('currency', locations[0].currency, 70, '/');
      this.cookieService.set('currency', '$', 70, '/');
      this.cookieService.set('currencyCode', locations[0].currencyCode, 70, '/');
      this.cookieService.set('storeCountry', locations[0].storeCountry, 70, '/');
      this.cookieService.set('timezone', locations[0].storeTimezone, 70, '/');
      localStorage.setItem('currency', locations[0].currency);
      localStorage.setItem('currency', '$');
      selected = locations[0];
      this.store.dispatch(locationSelectStart({ show: true }));
      this.store.dispatch(locationSelect({ selected: selected }));
      //alert("3"+selected.storeTimezone)
    }
    console.log("My_CUrrency", this.cookieService.get('currency'))
  }

  getTimeZone(): Observable<any> {
    return this.http.get<any>(this.GLOBALURL + '/timezones', {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: this.cookieService.get("token"),
      }),
    }).pipe(shareReplay(1))
  }

  addNewLocation(payload: any): Observable<any> {
    // for store location it will load store from prod url
    // store will not fetch from preprod env
    return this.http.post<any>(this.ApiService.prodURL + '/store', payload, {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: this.cookieService.get("token"),
      }),
    })
  }

}
