import { loginSuccess, signupSuccess, autoLogout, loginStart, loginFailure, loginWithSSOStart, magicLinkLoginStart, crossLinkLoginSuccess } from './auth.actions';
import { createReducer, on } from '@ngrx/store';
import { initialState } from './auth.state';

const _authReducer = createReducer(
  initialState,
  on(loginStart, (state) => {
    return {
      ...state,
      showLoader: true
    };
  }),
  on(magicLinkLoginStart, (state) => {
    return {
      ...state,
      showLoader: true
    };
  }),
  on(crossLinkLoginSuccess, (state, action) => {
    return {
      ...state,
      user: action.user,
      showLoader: false
    };
  }),
  on(loginSuccess, (state, action) => {
    return {
      ...state,
      user: action.user,
      showLoader: false
    };
  }),
  on(loginFailure, (state, action) => {
    return {
      ...state,
      user: null,
      showLoader: false,
      loginTry: state.loginTry + 1
    };
  }),

  on(loginWithSSOStart, (state) => {
    return {
      ...state,
      showLoader: true
    };
  }),
//   on(signupSuccess, (state, action) => {
//     return {
//       ...state,
//       user: action.user,
//     };
//   }),
  on(autoLogout, (state) => {
    console.log(state)
    return {
      ...state,
      user: null,
    };
  })
);

export function AuthReducer(state, action) {
  return _authReducer(state, action);
}
