import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from './core/guards/auth.guard';
import { LayoutComponent } from './layouts/layout.component';
import { Page404Component } from './extrapages/page404/page404.component';
import { LoginGuard } from './core/guards/login.guard';
import { EmptyScreenComponent } from './components/empty-screen/empty-screen.component';
import { ReleaseVersion } from './account/auth/release-version/release-version.component';
import { SearchbarComponent } from './pages/starter/searchbar/searchbar.component';


const routes: Routes = [
  // { path: 'account', loadChildren: () => import('./account/account.module').then(m => m.AccountModule) },
  // { path: '', component: LayoutComponent, loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule), canActivate: [AuthGuard] },
  // { path: 'pages', loadChildren: () => import('./extrapages/extrapages.module').then(m => m.ExtrapagesModule), canActivate: [AuthGuard] },
  // { path: '**', component: Page404Component },
  { path: 'account', loadChildren: () => import('./account/account.module').then(m => m.AccountModule),
  canActivate: [LoginGuard] },
  { path: '', component: LayoutComponent, loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule), canActivate: [AuthGuard] },
  { path: 'redirectLink', component: EmptyScreenComponent },
  { path: 'shared-content',loadChildren: () => import('./pages/shared-content/shared-content.module').then(m => m.SharedContentModule) },
  { path: 'login', component: LayoutComponent, loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule), canActivate: [AuthGuard] },
  { path: 'release-version', component: ReleaseVersion },
  // {
  //   path: "error",
  //   component: ErrorComponent,
  // },
  // {
  //   path: "403",
  //   component: ForbiddenComponent,
  // },
  {
    path: "search",
    component: SearchbarComponent,
  },
  {
    path: "**",
    component: Page404Component,
  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top', relativeLinkResolution: 'legacy', useHash: true })],
  exports: [RouterModule]
})

export class AppRoutingModule { }
