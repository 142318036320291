import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { CookieService } from "ngx-cookie-service";
import { Observable, Subject } from "rxjs";
import { shareReplay } from "rxjs/operators";
import { ApiService } from "src/app/services/api";
import { AlertService } from "./AlertService/alert.service";

@Injectable({
  providedIn: "root",
})
export class MenuService {
  token = this.cookieService.get("token");
  storeId = this.cookieService.get("storeId");
  merchantId = this.cookieService.get("merchantId");
  isLoadingChangeSub = new Subject<boolean>();

  DisplayIssue$: any;
  private sharedData: Subject<any> = new Subject<any>();
  sharedData$: Observable<any> = this.sharedData.asObservable();
  GLOBALURL: String;
  constructor(
    private http: HttpClient,
    private router: Router,
    private cookieService: CookieService,
    private toastr: AlertService,
    private ApiService: ApiService,
    private modalService: NgbModal
  ) {}

  setData(updatedData: any) {
    this.sharedData.next(updatedData);
  }
  addNewMenu(payload) {
    this.isLoadingChangeSub.next(true);
    return this.http
      .post(this.GLOBALURL + "/newmenu", payload, {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
          Authorization: this.token,
        }),
      })
      .subscribe(
        (response: any) => {
          if (response.success === 0) {
            this.toastr.error(response.message);
            this.isLoadingChangeSub.next(false);
          } else {
            if (this.modalService.hasOpenModals()) {
              console.log("MODAL_OPEN");
              this.isLoadingChangeSub.next(false);
              const department = response.response;
              this.modalService.dismissAll( department );
              this.toastr.clear();
              this.toastr.success(response.message);
              return
            }
            this.toastr.success(response.message);
            this.isLoadingChangeSub.next(false);
            this.router.navigate(["/menumanagement/menus"]);
          }
        },
        (error) => {
          const message = this.ApiService.errorMessage(error);
          this.toastr.error(message);
          this.isLoadingChangeSub.next(false);
        }
      );
  }

  getMenu(menuId: string) {
    return this.http
      .get(this.GLOBALURL + "/menu", {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
          Authorization: this.token,
        }),
        params: new HttpParams()
          .set("menuId", menuId)
          .set("storeId", this.storeId)
          .set("merchantId", this.merchantId),
      })
      .pipe(shareReplay(1));
  }

  getProductsByCategoryID(categoryIds: string) {
    return this.http
      .get(this.GLOBALURL + "/product", {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
          Authorization: this.token,
        }),
        params: new HttpParams()
          .set("categoryId", categoryIds)
          .set("parentCategoryProducts", 1)
          .set("storeId", this.storeId),
      })
      .pipe(shareReplay(1));
  }

  getCategoryFilter() {
    return this.http
      .get(this.GLOBALURL + "/getCategoryFilter", {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
          Authorization: this.token,
        }),
        params: new HttpParams().set("storeId", this.storeId),
      })
      .pipe(shareReplay(1));
  }

  updateMenu(payload) {
    this.isLoadingChangeSub.next(true);
    return this.http
      .put(this.GLOBALURL + "/menu", payload, {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
          Authorization: this.token,
        }),
        params: new HttpParams()
          .set("storeId", this.storeId)
          .set("merchantId", this.merchantId),
      })
      .subscribe(
        (response: any) => {
          if (response.success === 0) {
            this.toastr.error(response.message);
            this.isLoadingChangeSub.next(false);
          } else {
            this.toastr.success(response.message);
            this.isLoadingChangeSub.next(false);
            // this.router.navigate(["/menumanagement/menus"]);
          }
        },
        (error) => {
          const message = this.ApiService.errorMessage(error);
          this.toastr.error(message);
          this.isLoadingChangeSub.next(false);
        }
      );
  }

  updateMenuActiveStatus(payload) {
    // this.isLoadingChangeSub.next(true);
    payload.storeId = this.storeId;
    payload.merchantId = this.merchantId;
    return this.http.put(this.GLOBALURL + "/menu", payload, {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: this.token,
      }),
    });
  }

  exportMenu() {
    const storeId = this.cookieService.get("storeId");
    // console.log("MENU_SERVICE_THIS>ID", this.storeId);
    // console.log("MENU_SERVICE_CONST>ID", storeId)
    return this.http.post(
      this.GLOBALURL + "/menu/export",
      {
        storeId: storeId,
        merchantId: this.merchantId,
      },
      {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
          Authorization: this.token,
        }),
      }
    );
  }

  clearMenu(storeId: any) {
    return this.http.post(
      // check on d3 code
      this.GLOBALURL + "/menu/clear",
      {
        storeId: storeId,
        merchantId: this.merchantId,
      },
      {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
          Authorization: this.token,
        }),
      }
    );
  }

  uploadMenu(payload) {
    return this.http.post(this.GLOBALURL + "/menu/import", payload, {
      headers: new HttpHeaders({
        enctype: "multipart/form-data",
        // "Content-Type": "file",
        Authorization: this.token,
      }),
      params: new HttpParams()
        .set("storeId", this.storeId)
        .set("merchantId", this.merchantId),
      reportProgress: true,
      observe: "events",
    });
  }

  uploadNewMenuF(payload) {
    return this.http.post(this.GLOBALURL + "/new/menu/import", payload, {
      headers: new HttpHeaders({
        enctype: "multipart/form-data",
        // "Content-Type": "file",
        Authorization: this.token,
      }),
      params: new HttpParams()
        .set("storeId", this.storeId)
        .set("merchantId", this.merchantId),
      reportProgress: true,
      observe: "events",
    });
  }

  assignImportedMenu(payload) {
    return this.http.post(this.GLOBALURL + "/menu/assign", payload, {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: this.token,
      }),
    });
  }
}
