import { ILocaData, ILocation } from "src/app/core/models/location.model";

export interface LocationState {
  locations: ILocaData[];
  selected: ILocaData;
  show: Boolean;
}

export const initialState: LocationState = {
  locations: [],
  selected: {
    id: "",
    text: "",
    currency: "",
    currencyCode: "",
    logo: "",
    storeThemeColor: "",
    storeTimezone: "",
    storeCountry: "",
    isActive: 2
  },
  show: false
};
