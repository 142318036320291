import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { installPatch } from './app/monkey.patch';
import { environment } from './environments/environment';
import axios from 'axios';
import * as Sentry from "@sentry/angular-ivy";
import { HttpErrorResponse } from '@angular/common/http';

function checkException(exception, hint, message) {
  return (
    exception?.value?.includes(message) ||
    hint?.originalException?.message?.includes(message)
  );
}

const beforeSendCallback = (event:any, hint:any) => {
  // Check if event or hint is null or undefined
  if (!event || !hint) {
      return null;
  }

  // Check if 'exception' property exists in event
  if (!event.exception || !event.exception.values || !event.exception.values[0]) {
      return event;
  }

  const originalException = event.exception.values[0];

  // Check if 'value' property exists in originalException
  if (!originalException.value) {
      return event;
  }

  // Drop events for 401 errors
  if (hint?.originalException instanceof HttpErrorResponse && hint?.originalException?.status === 401) {
    return null;
  }

  // Check for ChunkLoadError and ignore it
  if (originalException?.type === 'ChunkLoadError' || originalException.value?.includes('ChunkLoadError')) {
    return null;
  }

  const isNonErrorException = checkException(originalException, hint, 'Non-Error exception captured');

  const unknownLogout = checkException(originalException, hint, 'You have been logged out');

  const unauthorizedError = checkException(originalException, hint, '401 Unauthorized') ||
    checkException(originalException, hint, '401 (Unauthorized)') || checkException(originalException, hint, '401 OK');

  const uknownError = checkException(originalException, hint, 'Unknown Error')
    || checkException(originalException, hint, 'unknown error');

  const reenterPassword = checkException(originalException, hint, 'Re-enter your password to login');

  const invalidSession = checkException(originalException, hint, 'Session Invalid');

  const econnRefused = checkException(originalException, hint, 'ECONNREFUSED');

  const invalidRequest = checkException(originalException, hint, 'Invalid request');

  const accessDenied = checkException(originalException, hint, 'Access denied');

  const invalidToken = checkException(originalException, hint, 'Invalid Token');

  if (
    isNonErrorException || unknownLogout || unauthorizedError || uknownError || invalidSession || reenterPassword || econnRefused || invalidRequest ||
    accessDenied || invalidToken
  ) {
    // We want to ignore those kinds of errors
    return null;
  }

  return event;
}

axios.get('https://browserapi.eatos.pro/global/query?appType=DASHBOARD')
  .then(response => {
    let currentUrl = window.location.href;
    if(!currentUrl.includes('localhost')) {
      if(currentUrl.includes('.dev')){
        Sentry.init({
          environment: 'dev',
          release: `${response.data.response.dashboardVersion}`,
          dsn: "https://f5aee8a911a1415b99b6ab22f8594ab6@o4505058481274880.ingest.us.sentry.io/4505058525708288",
          integrations: [
            // Registers and configures the Tracing integration, but we'll remove it
            // Sentry.browserTracingIntegration(),
            // Registers the Replay integration, but we'll remove it
            // Sentry.replayIntegration(),
          ],

          beforeSend: beforeSendCallback,

          // Set tracesSampleRate to 1.0 to capture 100%
          // of transactions for performance monitoring.
          // We recommend adjusting this value in production, but we'll remove it for now
          // tracesSampleRate: 1.0,

          // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
          // We'll remove this since we're not using tracing
          // tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],

          // Capture Replay for 10% of all sessions,
          // plus for 100% of sessions with an error
          // We'll remove these since we're not using session replay
          // replaysSessionSampleRate: 0.1,
          // replaysOnErrorSampleRate: 1.0,

          // Set this to true to only capture errors and ignore transactions and sessions
          // This will ensure only error monitoring is active
          enabled: true
        });
      }
      if(currentUrl.includes('.net')){

        Sentry.init({
          environment: 'staging',
          release: `${response.data.response.dashboardVersion}`,
          dsn: "https://f5aee8a911a1415b99b6ab22f8594ab6@o4505058481274880.ingest.us.sentry.io/4505058525708288",
          integrations: [
            // Registers and configures the Tracing integration, but we'll remove it
            // Sentry.browserTracingIntegration(),
            // Registers the Replay integration, but we'll remove it
            // Sentry.replayIntegration(),
          ],
          beforeSend: beforeSendCallback,
          // Set tracesSampleRate to 1.0 to capture 100%
          // of transactions for performance monitoring.
          // We recommend adjusting this value in production, but we'll remove it for now
          // tracesSampleRate: 1.0,

          // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
          // We'll remove this since we're not using tracing
          // tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],

          // Capture Replay for 10% of all sessions,
          // plus for 100% of sessions with an error
          // We'll remove these since we're not using session replay
          // replaysSessionSampleRate: 0.1,
          // replaysOnErrorSampleRate: 1.0,

          // Set this to true to only capture errors and ignore transactions and sessions
          // This will ensure only error monitoring is active
          enabled: true
        });
      }
      if(currentUrl.includes('.com') || currentUrl.includes('.us')){
        Sentry.init({
          environment: 'production',
          release: `${response.data.response.dashboardVersion}`,
          dsn: "https://f5aee8a911a1415b99b6ab22f8594ab6@o4505058481274880.ingest.us.sentry.io/4505058525708288",
          integrations: [
            // Registers and configures the Tracing integration, but we'll remove it
            // Sentry.browserTracingIntegration(),
            // Registers the Replay integration, but we'll remove it
            // Sentry.replayIntegration(),
          ],

          beforeSend: beforeSendCallback,

          // Set tracesSampleRate to 1.0 to capture 100%
          // of transactions for performance monitoring.
          // We recommend adjusting this value in production, but we'll remove it for now
          // tracesSampleRate: 1.0,

          // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
          // We'll remove this since we're not using tracing
          // tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],

          // Capture Replay for 10% of all sessions,
          // plus for 100% of sessions with an error
          // We'll remove these since we're not using session replay
          // replaysSessionSampleRate: 0.1,
          // replaysOnErrorSampleRate: 1.0,

          // Set this to true to only capture errors and ignore transactions and sessions
          // This will ensure only error monitoring is active
          enabled: true
        });
      }
    }


  })
  .catch(error => console.error(error));


if (environment.production) {
  enableProdMode();
}


platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .then(success => console.log(`Bootstrap success`))
  .catch(err => console.error(err));


