<!-- Right Sidebar -->
<!-- <div class="right-bar" (clickOutside)="hide()" [exclude]="'.right-bar-toggle'"></div> -->
<div class="right-bar">

  <ngx-simplebar class="h-100" #scrollRef>
    <div class="rightbar-title px-3 py-4">
      <!-- <a href="javascript:void(0);" class="right-bar-toggle float-end" (click)="hide();">
        <i class="mdi mdi-close noti-icon"></i>
      </a> -->

      <nav ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs gross_tabs p-0">
        <ng-container [ngbNavItem]="1">
          <a ngbNavLink class="nav_txt">{{ "Chat" | translate | async }}
          </a>
        </ng-container>
        <ng-container [ngbNavItem]="2">
          <a ngbNavLink class="nav_txt">{{ "Insights" | translate | async }}
          </a>
        </ng-container>
        <ng-container [ngbNavItem]="3" style="margin-left: auto;">
          <a (click)="hide();" class="nav_txt"><i class="mdi mdi-close noti-icon"></i>
          </a>
        </ng-container>
      </nav>

      <div class="clearfix text-center" style="margin-top: 40px;">
        <img src="assets/images/companies/img-4.png" alt="logo-sm" height="70" class="mx-auto d-block">
        <h3> <b>Copilot</b> </h3><sup>{{ "Beta" | translate | async }}</sup>
      </div>
      <div class="clearfix text-center" style="margin-top: 20px;">

        <small style="font-size: 12px;">{{ "Here are some things Copilot can help you do." | translate | async }}</small>
      </div>
      <div class="mt-4 p-4">
        <div class="card border shadow-none mb-2">
          <a href="javascript: void(0);" class="text-body">
            <div class="p-2">
              <div class="d-flex">
                <div class="avatar-xs align-self-center me-2">
                  <div class="avatar-title rounded bg-transparent text-success font-size-20">
                    <i class="mdi mdi-point-of-sale"></i>
                  </div>
                </div>

                <div class="overflow-hidden me-auto">
                  <h5 class="font-size-13 text-truncate mt-2" (click)="selectPromt('gross_transaction_report');">
                    {{ "Generate gross transaction report" | translate | async }}
                  </h5>
                </div>
              </div>
            </div>
          </a>
        </div>
        <div class="card border shadow-none mb-2">
          <a href="javascript: void(0);" class="text-body">
            <div class="p-2">
              <div class="d-flex">
                <div class="avatar-xs align-self-center me-2">
                  <div class="avatar-title rounded bg-transparent text-info font-size-20">
                    <i class="mdi mdi-information"></i>
                  </div>
                </div>

                <div class="overflow-hidden me-auto">
                  <h5 class="font-size-13 text-truncate mt-2" (click)="selectPromt('enable_orderos_integration');">
                    {{ "Enable orderos integration" | translate | async }}
                  </h5>
                </div>
              </div>
            </div>
          </a>
        </div>
        <div class="card border shadow-none mb-2">
          <a href="javascript: void(0);" class="text-body">
            <div class="p-2">
              <div class="d-flex">
                <div class="avatar-xs align-self-center me-2">
                  <div class="avatar-title rounded bg-transparent text-primary font-size-20">
                    <i class="mdi mdi-message-video"></i>
                  </div>
                </div>

                <div class="overflow-hidden me-auto">
                  <h5 class="font-size-13 text-truncate mt-2" (click)="selectPromt('tap_n_pay_integration');">
                    {{ "Watch new Tap-n-pay integration." | translate | async }}
                  </h5>
                </div>
              </div>
            </div>
          </a>
        </div>

      </div>

      <div class="clearfix text-center" style="margin-top: 20px;">

        <small style="font-size: 12px;">{{ "Copilot is powered by AI, so surprises and mistakes are possible." | translate | async }}</small>
      </div>

      <div class="card-body pb-0" style="bottom: 0;width: 100%; margin-top: 20px;min-height: 80px;">
        <div style="margin-bottom: 70px;">
          <div class="chat-conversation">
            <ul class="list-unstyled">
              <li *ngFor="let data of threadData" [ngClass]="{ 'right': data.align === 'right' }">
                <div class="conversation-list">
                  <div class="dropdown" ngbDropdown>
                    <a class="dropdown-toggle" href="javascript: void(0);" role="button" data-toggle="dropdown"
                      aria-haspopup="true" aria-expanded="false" ngbDropdownToggle>
                      <i class="bx bx-dots-vertical-rounded"></i>
                    </a>
                    <div class="dropdown-menu" ngbDropdownMenu>
                      <a class="dropdown-item" href="javascript: void(0);">{{ "Copy" | translate | async }}</a>
                      <a class="dropdown-item" href="javascript: void(0);">{{ "Save" | translate | async }}</a>
                      <a class="dropdown-item" href="javascript: void(0);">{{ "Forward" | translate | async }}</a>
                      <a class="dropdown-item" href="javascript: void(0);">{{ "Delete" | translate | async }}</a>
                    </div>
                  </div>
                  <div [ngClass]="{'ctext-wrap': data.align === 'right', 'ctext-wrap-white': data.align !== 'right'}">
                    <span class="font-size-13">

                      <ng-container class="message" *ngFor="let line of data.content.text.split('\n'); let i = index">
                        <p [innerHTML]="i === 0 ? line : '<br />' + line"></p>
                      </ng-container>
                    </span>

                    <div class="p-2" *ngIf="data.data">
                      <a href="javascript:void(0)" (click)="downloadFile(data.data);">
                        <div class="d-flex">
                          <div class="avatar-xs align-self-center me-2">
                            <div class="avatar-title rounded bg-transparent text-danger font-size-20">
                              <i class="mdi mdi-file-pdf"></i>
                            </div>
                          </div>

                          <div class="overflow-hidden me-auto" style="width: 90%;">
                            <h5 class="font-size-13 text-truncate mt-1">
                              {{data.role}} </h5>

                          </div>
                        </div>
                      </a>
                    </div>
                    <div class="clearfix text-center d-flex flex-wrap gap-2 mt-2" *ngIf="data.isTags">
                      <div *ngFor="let tag of data.tags; let i = index">
                        <button type="button" class="btn btn-sm btn-dark" (click)="selectTag(data, tag);">
                          {{ tag | translate | async }}
                        </button>
                      </div>

                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>

        </div>
      </div>
      <div *ngIf="apiCalled"
        style="position: absolute; margin-bottom: 90px;left: 50%; transform: translateX(-50%); text-align: center;">
        <button type="button" class="btn btn-outline-dark btn-sm" (click)="stopResponding();">
          <i class="bx bx-stop-circle"></i> {{ "Stop Responding" | translate | async }}
        </button>
      </div>
    </div>



  </ngx-simplebar>

  <div class="p-3 chat-input-section" style="position: absolute; bottom: 0;width: 100%;background-color: #a7a7a7;">



    <form (ngSubmit)="messageSave()" [formGroup]="formData" class="row">
      <div class="col">
        <div class="position-relative">
          <textarea class="form-control ng-pristine ng-invalid ng-touched" formControlName="message" id="searchbox"
            name="searchbox" type="text" rows="3" enterkeyhint="send" dir="" autocapitalize="off" autocomplete="off"
            aria-autocomplete="both" spellcheck="false" aria-label="Ask me anything..." autocorrect="off"
            maxlength="2000" [placeholder]="'Ask me anything...' | translate | async" autofocus=""
            [ngClass]="{'is-invalid': chatSubmit && form.message.errors}"></textarea>
          <div *ngIf="chatSubmit && form.message.errors" class="invalid-feedback">
            <span *ngIf="form.message.errors.required">{{ "Message is required." | translate | async }}</span>
          </div>
          <div class="chat-input-links">
            <ul class="list-inline mb-0">

              <li class="list-inline-item">
                <button type="submit" ngbTooltip="Submit" class="btn btn-dark chat-send w-md"> <i
                    class="mdi mdi-send"></i></button>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <!-- <div class="col-auto">
        <button type="submit" class="btn btn-primary chat-send w-md"><span
            class="d-none d-sm-inline-block me-2">Send</span> <i class="mdi mdi-send"></i></button>
      </div> -->
    </form>

  </div>
</div>
<!-- /Right-bar -->

<!-- Right bar overlay-->
<div class="rightbar-overlay"></div>
