
// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
// import { config } from '../../auth_config.json';
import * as authConfig from '../../auth_config.json';

// export const environment = {
//   production: false,
//   defaultauth: 'fackbackend',
//   firebaseConfig: {
//     apiKey: '',
//     authDomain: '',
//     databaseURL: '',
//     projectId: '',
//     storageBucket: '',
//     messagingSenderId: '',
//     appId: '',
//     measurementId: ''
//   }
// };

const { domain, clientId, authorizationParams: { audience }, apiUri, errorPath } = authConfig as {
  domain: string;
  clientId: string;
  authorizationParams: {
    audience?: string;
  },
  apiUri: string;
  errorPath: string;
};

export const environment = {
  production: false,
  defaultauth: 'fackbackend',
  frontendBaseUrl: 'http://localhost:4200/dashboard',
  globalUrlApi: "/api",
  timezone:'',
  // AuthO Testing
  auth: {
    domain,
    clientId,
    authorizationParams: {
      // ...(audience && audience !== 'YOUR_API_IDENTIFIER' ? { audience } : null),
      redirect_uri: window.location.origin,
    },
    errorPath,
  },
  // httpInterceptor: {
  //   allowedList: [`${apiUri}/*`],
  // },
  // newly added by mark, for testing oAuth
  googleAuthClientId: "326819269272-sd05evf3re8tjrcdfmpqum83ggvkfa3o.apps.googleusercontent.com",
  appleRedirectUrl: "https://browserapi.eatos.net/auth/apple",
  appleAuthClientId: "net.eatos-d4.signin",
  googleApiKey:"AIzaSyDzJkCToYLLs6vNjlwQMcscL-i8cwR-mY0",
  chatGptKey:"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",
  firebaseConfig: {
    apiKey: "AIzaSyC1QvwjWw61xye9_S3NPg9NrVTGTUC6sIQ",
    authDomain: "temp-d6bf7.firebaseapp.com",
    databaseURL: "https://temp-d6bf7.firebaseio.com",
    projectId: "temp-d6bf7",
    storageBucket: "temp-d6bf7.appspot.com",
    messagingSenderId: "111989057448",
    appId: "1:111989057448:web:dd12eb12ff5677d377df9d"
  },
  sampleSheetUrl: 'https://eatos-beta-v3.s3.amazonaws.com/v2/eatOS/1674215358493.pdf',
  oneSignal: {
    prod: {
      appId: "19cf56ac-3147-4551-b349-b3be2e6884e5"
    },
    staging: {
      appId: "aa6f38e9-d11e-4d6d-8018-e114658e0ec2"
    },
    dev: {
      appId: "da0e2a23-925f-45a1-9159-168404aa106c"
    }
  },
  env:'development',
};



/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
