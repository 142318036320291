import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { ApiService } from './../../services/api';
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})

/**
 * Footer component
 */
export class FooterComponent implements OnInit {

  // set the currenr year
  // year: number = new Date().getFullYear();
  GLOBALURL: String;
  headerToken = { 'Content-Type': 'application/json', 'Authorization': this.cookieService.get('token') }
  httpOptions2
  constructor( 
    private http: HttpClient,
    private ApiService: ApiService,
    private cookieService: CookieService,
    private router: Router,
    ) { 
    this.GLOBALURL = this.ApiService.getBaseURL();
  }


  onclickReleaseVersion(){
    this.router.navigate(['/master/release-versions']);
  }

  Currentversion :any;
  year:any;

  ngOnInit() {
    // window.dispatchEvent(new Event('resize'));

    this.httpOptions2 = { headers: new HttpHeaders(this.headerToken) };
    this.http.get(this.GLOBALURL + `/global/query?appType=DASHBOARD`, this.httpOptions2)
      .subscribe((resp: any) => {
       if(resp.success===1){
          this.Currentversion= resp.response.dashboardVersion;
          this.year = resp.response.year
          this.cookieService.set("currentVersion",this.Currentversion);
       }
      }, error => {

      })
  }

}
