import { Clipboard } from '@angular/cdk/clipboard';
import { DOCUMENT, ViewportScroller } from "@angular/common";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { AfterViewInit, Component, ElementRef, Inject, OnInit, Renderer2, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import moment from "moment";
import { CookieService } from "ngx-cookie-service";
import {
  EmitterService,
  Utilities,
} from "src/app/core/services/server-api.service";
import { ThreadMessage } from "src/app/pages/ai/ai-reports/saas.model";
import { ApiService } from "src/app/services/api";
// import React from "preact/compat";
import { Store } from "@ngrx/store";
// import 'simplebar/dist/simplebar.css'; // Import SimpleBar CSS
import { AlertService } from "src/app/core/services/AlertService/alert.service";
import { AppState } from "src/app/store/app.state";
@Component({
  selector: "app-co-pilot-chat",
  templateUrl: "./co-pilot-chat.component.html",
  styleUrls: ["./co-pilot-chat.component.scss"],
})

export class CoPilotChatComponent implements OnInit, AfterViewInit {
  @ViewChild('reportTemplate') reportTemplate: ElementRef;
  @ViewChild('scrollEle') scrollEle;
  @ViewChild('scrollRef') scrollRef;
  @ViewChild('printDiv', { static: false }) printDiv: ElementRef;
  @ViewChild('scrollMe') private myScrollContainer: ElementRef;

  reportHeader = 'Maya-Beta';
  logoUrl = '../../../assets/images/companies/mayachatai.svg';
  formData: FormGroup;
  GLOBALURL: any;

  constructor(
    @Inject(DOCUMENT) private document: any,
    private modalService: NgbModal,
    private renderer: Renderer2,
    private formBuilder: FormBuilder,
    private http: HttpClient,
    private ApiService: ApiService,
    private cookieService: CookieService,
    private clipboard: Clipboard,
    private utils: Utilities,
    private el: ElementRef,
    private toastr: AlertService,
    private scroller: ViewportScroller,
    private store: Store<AppState>,
    private elRef: ElementRef
  ) {
    this.GLOBALURL = this.ApiService.getBaseURL();
    this.formData = this.formBuilder.group({
      message: ['', [Validators.required]],
      threadId: [null],
      //threadId: ['thread_780lw7Z93BXxJ80ICI9dZ7Zi'],
      dataGenerated: false
    });
  }
  // ngAfterViewChecked(): void {
  //   throw new Error('Method not implemented.');
  // }
  feedBackFormShow: boolean = false;
  feedBackFormShow1: boolean = false;
  IsShownresult: boolean = false;
  ActiveSelectClass: any = "main";
  ActiveSelectClass1: any;
  ItemsDetails = [
    {
      Category: "Juice",
      Quantity: "21",
      Amount: "$332.00",
      Discount: "$0.00",
      Promocode: "$0.00",
    },
    {
      Category: "Liqor",
      Quantity: "16",
      Amount: "$332.00",
      Discount: "$0.00",
      Promocode: "$0.00",
    },
    {
      Category: "Brunch",
      Quantity: "4",
      Amount: "$332.00",
      Discount: "$0.00",
      Promocode: "$0.00",
    },
  ];

  Arrayresponse = [
    "Inaccurate",
    "Inappropriate",
    "Offensive",
    "Irrelevant",
    // Hide For Now
    // "Other",
  ];
  startDate: string;
  // enddate: Date;
  enddate: string;

  selectedDate: boolean = false;
  isloading: boolean = false;
  isloading2: boolean = false;
  detailsloader: boolean = false;
  checkboxDiv: boolean = false;
  Sendmessage: boolean = false;
  imageshow: boolean = false;
  thumbshowup: boolean = false;
  thumbshowdown: boolean = false;

  employeeImage: any = "";
  employeeName: any = "";
  userInput: string = "";
  characterCount: number = 0;
  isDetailsShow: boolean = false;
  isSecondDetailsShow: boolean = false;
  threadData: ThreadMessage[] = []; // Initialize threadData as an empty array
  headerTokenDetails = {
    "Content-Type": "application/json",
    Authorization: this.cookieService.get("token"),
  };
  httpOptions = { headers: new HttpHeaders(this.headerTokenDetails) };

  threadId
  chatId: any;
  category:any
  ngOnInit(): void {
    this.threadId = localStorage.getItem("threadId");
    // this.threadId = "thread_D858YIqyz0MHqrMkduMfxTBy"
    // this.resetForm();
    this.ActiveSelectClass = 'grossSales';
    this.setUserProfile();
    this.category = 'report'
    this.loadChatBot();

    // this.sendReaction();
    // // setInterval(() => {
    // //   // Assuming threadData is an array
    //   if(this.threadData.length > 0) {
    //     for (const data of this.threadData) {
    //       // this.checkReactionStatus(data);
    //       // loop and Check if the 'content' object property 'type' 'HTMl' value is present then send the data to the checkReactionStatus function
    //       if (data.content.type === 'html') {
    //         this.checkReactionStatus(data);
    //       }
    //     }
    //   }
    // // }, 1000);
  }
  ngAfterViewInit() {
    if (
      this.scrollEle && this.scrollEle?.nativeElement.SimpleBar &&
      this.scrollRef && this.scrollRef?.nativeElement.SimpleBar
    ) {
      this.scrollEle.nativeElement.SimpleBar.getScrollElement().scrollTop = 100;
      this.scrollRef.nativeElement.SimpleBar.getScrollElement().scrollTop = 200;

      const elements = this.el.nativeElement.querySelectorAll('.qtag');

      elements.forEach((element: any) => {
        this.renderer.listen(element, 'click', () => {
          this.handleClick();
        });
      });

      this.scrollToBottom();
    }
  }

//   ngAfterViewChecked() {
//     this.scrollToBottom();
// }

  handleClick() {
    console.log('Element with class "clickable-element" clicked!');
    // Your logic or function call goes here
  }
  onListScroll() {
    if (this.scrollRef !== undefined) {
      setTimeout(() => {
        this.scrollRef.SimpleBar.getScrollElement().scrollTop =
          this.scrollRef.SimpleBar.getScrollElement().scrollHeight + 1500;
      }, 500);
    }
  }

  setUserProfile() {
    this.employeeImage = this.cookieService
      .get("employeeImage")
      .replace("%", "/");

    EmitterService.get("employeeImage").subscribe((employeeImage: any) => {
      let imageLink = employeeImage;
      this.employeeImage = this.utils.getImage(imageLink);
    });
    this.employeeName = this.cookieService.get("employeeCutName");
    console.log("employeeName", this.employeeName)
  }

  timeout: boolean = false;
  dataGenerated = false
  apiCalled = false;
  level:Number = 0
  reSyncCalled = false;
  lastIdentifiedTag = "";
  /**
   * Loads the chat bot and initializes the thread data.
   */
  loadChatBot() {

    this.threadData = [];

    var params = { "message": "", "isTags": true, "threadId": null,"identifier":"report","category":this.category, "dataGenerated": false }
    const threadId = this.threadId
    console.log("Form", threadId)

    if (threadId == null || threadId == "null") {
      console.log("API called")
      this.reSyncCalled = false;
      this.http
        .post(this.GLOBALURL + "/ai/chat", params, this.httpOptions)
        .subscribe(
          (response: any) => {
            if (response.success === 1) {
              this.apiCalled = false

              this.threadData = response.response;
              if (this.threadData.length > 0) {
                for (const data of this.threadData) {
                  // this.checkReactionStatus(data);
                  // loop and Check if the 'content' object property 'type' 'HTMl' value is present then send the data to the checkReactionStatus function
                  if (data.content.type === 'html') {
                    this.checkReactionStatus(data);
                  }
                }
              }

              console.log(this.threadData);

              this.formData = this.formBuilder.group({
                message: null,
              });

              // this.chatId = response.response.length > 0 ? response.response[0].chatId : null
              this.threadId = response.response.length > 0 ? response.response[0].threadId : null
              localStorage.setItem('threadId', response.response.length > 0 ? response.response[0].threadId : null)
              //this.level = response.success === 1 ? response.level : this.level
              this.dataGenerated = response.success === 1 ? response.dataGenerated : this.dataGenerated

              // this.scrollToBottom();

            } else {
              this.timeout = true;
              this.apiCalled = false
            }
          },
          (error) => {
            this.timeout = true;
            this.apiCalled = false
            // this.threadData.push({
            //   align: 'left',
            //   role: 'assistant',
            //   content: {
            //     "type": "text",
            //     "text": "Error02"
            //   },
            // });
          }
        );

    } else {
      console.log("resync")
      this.reSync()
      this.reSyncCalled = true;
    }

  }

  /**
   * Resynchronizes the chat data by making an HTTP POST request to fetch the latest chat messages.
   * If the threadId is not null, it sends the threadId and dataGenerated parameters in the request body.
   * Updates the threadData, threadId, level, and dataGenerated properties based on the response.
   * Sets the timeout property to true if there is an error during the HTTP request.
   */
  reSync() {
    this.detailsloader = false;
    this.timeout = false;
    const threadId = this.threadId
    const dataGenerated = false; // forecefully set to false
    this.threadData = []

    if (threadId != null && threadId != "null") {
      var params = { "message": '', "isTags": false, "threadId": threadId,"identifier":"","category":this.category, "dataGenerated": dataGenerated }

      this.http
        .post(this.GLOBALURL + "/fetch/chat", params, this.httpOptions)
        .subscribe(
          (response: any) => {
            if (response.success === 1) {
              this.apiCalled = false

              this.threadData = response.response;
              this.text = response.response[0].content.text;
              response.response.forEach((element: any) => {
                if (element.content.type === 'date-picker') {
                  this.isDetailsShow = true;
                }
                if (element.content.type === 'html') {
                  this.checkReactionStatus(element);
                  this.detailsloader = true;
                  this.Sendmessage = true;
                }
              })

              this.formData = this.formBuilder.group({
                message: null,
              });
              this.threadId = response.response.length > 0 ? response.response[0].threadId : null
              localStorage.setItem('threadId', response.response.length > 0 ? response.response[0].threadId : null)
              //this.level = response.success === 1 ? response.level : this.level
              this.dataGenerated = response.success === 1 ? response.dataGenerated : this.dataGenerated

              this.scrollToBottom();

            } else {
              this.timeout = true;
            }
          },
          (error) => {
            this.timeout = true;
          }
        );
    }

  }
  checkdatagenrated(identifier: any, nextLevel: any): boolean {
    console.log("identifier", identifier, nextLevel)    
      // if (identifier == "Guest Sales Summary" && nextLevel == 3) {
      //   return false;
      // }
    if (nextLevel === 0) {
      return true;
    }
    else {
      return false;
    }
  }

  checkFeedbackShow() {
    if( this.detailsloader && !this.isSecondDetailsShow ){
      this.feedBackFormShow = true;
    }
    else if( this.feedBackFormShow == true ){
      this.feedBackFormShow1 = false;
    }
  }

  text = "";
  selectedFirstLevelTag = "";
  showDatepicker = false;
  selectedTagArray = [];
  selectTag(tag: any, classValue?: any,nextLevel?:Number) {
    console.log("this.selectedFirstLevelTag", this.selectedFirstLevelTag)
    const currentDate = new Date();
    this.detailsloader = false;
    this.apiCalled = true;

    //console.log("level", this.level)

    // if (!this.level)
    //   this.level = nextLevel
  this.checkFeedbackShow()

    let message: any = tag.heading

    this.threadData.push({
      align: 'right',
      role: 'user',
      content: {
        "type": "text",
        "text": message,
        "tags": []
      },
    });
  
    // console.log(this.threadData)
    // this.threadData = [];

    // this.scrollToBottom();
    const threadId = this.threadId
    var params = {
      "message": message,
      "isTags": true, "threadId": threadId, "tag": message,"identifier":message,"category":this.category, "dataGenerated": this.checkdatagenrated(message,nextLevel) , "level": nextLevel
    }

    this.http
      .post(this.GLOBALURL + "/ai/chat", params, this.httpOptions)
      .subscribe(
        (response: any) => {
          if (response.success === 1) {
            this.apiCalled = false

            this.threadData = response.response;
            console.log("response", response.response)
            this.text = response.response[0].content.text;
            response.response.forEach((element: any) => {
              if (element.content.type === 'date-picker') {
                this.isDetailsShow = true;
              }
              if (element.content.type === 'html') {
                this.checkReactionStatus(element);
                this.detailsloader = true;
                this.Sendmessage = true;
              }
            })
            //     // this.selectedTagArray = responseArr;
            //   }
            // });

            console.log(this.threadData);

            this.scrollToBottom();

            this.formData = this.formBuilder.group({
              message: null,
            });

            this.threadId = response.response.length > 0 ? response.response[0].threadId : null
            localStorage.setItem('threadId', response.response.length > 0 ? response.response[0].threadId : null)
            //this.level = response.success === 1 ? response.level : this.level
            this.dataGenerated = response.success === 1 ? response.dataGenerated : this.dataGenerated

          } else {
            this.timeout = true;
            this.apiCalled = false
          }
        },
        (error) => {
          this.timeout = true;
          this.apiCalled = false
          // this.threadData.push({
          //   align: 'left',
          //   role: 'assistant',
          //   content: {
          //     "type": "text",
          //     "text": "Error02"
          //   },
          // });
        }
      );
    // }

  }

  questionResponseArr = [];
  selectQandATag(tag: any) {
    console.log("this.selectedFirstLevelTag", this.selectedFirstLevelTag)
    const currentDate = new Date();
    this.apiCalled = true;

    let message: any = tag

    this.threadData.push({
      align: 'right',
      role: 'user',
      content: {
        "type": "text",
        "text": message,
        "tags": []
      },
    });

    // console.log(this.threadData)
    // this.threadData = [];

    // this.scrollToBottom();
    const threadId = this.threadId
    var params = { "message": message, "isTags": true,"identifier":message,"category":this.category,"threadId": threadId, tag: message, "dataGenerated": this.dataGenerated, "level": 1}

    this.http
      .post(this.GLOBALURL + "/ai/chat", params, this.httpOptions)
      .subscribe(
        (response: any) => {
          if (response.success === 1) {
            this.apiCalled = false
            // this.threadData = response.response.slice(-2);
            // this.threadData = response.response;
            const lastTwoObjects = response.response.slice(-2); // Get the last 2 elements
            const newArray = [];
            lastTwoObjects.forEach((obj) => {
              newArray.push(obj); // Push each object into the new array
            });
            let responseArr = {
              align: "",
              role: "",
              chatId: "",
              content: {
                "type": "",
                "text": "",
                "tags": "",
              },
            };
            // this.threadData = responseArr
            // Loop over the newArray and check if the 'content' object property 'type' value is 'text' then push the object into threadData array
            newArray.forEach((element: any) => {
              if (element.content.type === 'text') {
                responseArr = {
                  align: element.align,
                  role: element.role,
                  chatId: element.chatId,
                  content: {
                    "type": "html",
                    "text": element.content.text,
                    "tags": element.content.tags,
                  },
                };
                this.threadData.push(responseArr)
              }
            });
            // keep the Previous Value of the questionResponseArr if present and push the new response
            //   this.questionResponseArr = [...this.questionResponseArr, ...newArray];
            console.log(this.threadData);
            this.formData = this.formBuilder.group({
              message: null,
            });

            this.threadId = response.response.length > 0 ? response.response[0].threadId : null
            localStorage.setItem('threadId', response.response.length > 0 ? response.response[0].threadId : null)
            //this.level = response.success === 1 ? response.level : this.level
            this.dataGenerated = response.success === 1 ? response.dataGenerated : this.dataGenerated

          } else {
            this.timeout = true;
            this.apiCalled = false
          }
        },
        (error) => {
          this.timeout = true;
          this.apiCalled = false

        }
      );
    // }

  }
  goDown1() {
    this.scroller.scrollToAnchor("targetRed");
  }
  getInitials(text: string): string {
    // Split the text into words
    const words = text.split(' ');

    // If it's a single word, grab the first two letters
    if (words.length === 1) {
      return text.substring(0, 2).toUpperCase();
    }

    // Otherwise, extract the first letter of each word
    return words.map((word) => word[0].toUpperCase()).join('');
  }

  updateCharacterCount() {
    this.characterCount = this.userInput.length;
  }
  resetModalValues(): void {
    this.startDate = null;
    this.enddate = null;
    this.selectedDate = false;
  }
  formattedStartDate: string;
  formattedEndDate: string;
  reportData: any;
  StartDateChange(event: any, tag: any,nextLevel?:Number) {

    this.formattedStartDate = moment(this.startDate).format('DD-MM-YYYY');
    this.formattedEndDate = moment(this.enddate).format('DD-MM-YYYY');

    console.log("Start Date", this.startDate);
    console.log("End Date", this.enddate);
    console.log("nextLevel", nextLevel);
    if (this.startDate != undefined && this.enddate != undefined) {

      if (this.formattedStartDate != "Invalid date" && this.formattedEndDate != "Invalid date") {
        // this.threadData = []
        this.apiCalled = true;
        this.selectedDate = true;
        this.isloading = true;
        // setTimeout(() => {
        // this.isloading = false;
        // if (!this.isloading) {
        //   this.detailsloader = true;
        //   this.Sendmessage = true;
        // }
        // }, 3000);
        this.scrollToBottom();
        const threadId = this.threadId
        var params = {
          "message": "" + this.formattedStartDate + " to " + this.formattedEndDate,
          "isTags": false, "threadId": threadId, "tag": tag,
          // "dataGenerated": this.dataGenerated,
          "dataGenerated": this.checkdatagenrated(tag,nextLevel),
          "level": nextLevel,
          "identifier": tag,
          "category":"report",
        }

        this.http
          .post(this.GLOBALURL + "/ai/chat", params, this.httpOptions)
          .subscribe(
            (response: any) => {

              this.resetModalValues();
              if (response.success === 1) {
                this.isloading = false;
                this.apiCalled = false

                this.threadData = response.response;
                // Remove the First 4 objects from the response array and push the remaining objects into the threadData array
                // this.threadData = response.response.slice(-2);

                if (this.threadData.length > 0) {
                  for (const data of this.threadData) {
                    // this.checkReactionStatus(data);
                    // loop and Check if the 'content' object property 'type' 'HTMl' value is present then send the data to the checkReactionStatus function
                    if (data.content.type === 'html') {
                      this.checkReactionStatus(data);
                    }
                  }
                }
                console.log(this.threadData);

                this.formData = this.formBuilder.group({
                  message: null,
                });
                this.threadId = response.response.length > 0 ? response.response[0].threadId : null
                localStorage.setItem('threadId', response.response.length > 0 ? response.response[0].threadId : null)
                //this.level = response.success === 1 ? this.level + 1 : this.level
                this.dataGenerated = response.success === 1 ? response.dataGenerated : this.dataGenerated

                if (!this.isloading) {
                  this.detailsloader = true;
                  this.Sendmessage = true;
                }
                this.scrollToBottom();
              } else {
                this.isloading = false;
                this.timeout = true;
                this.apiCalled = false
                this.selectedDate = false;
              }
            },
            (error) => {
              this.isloading = false;
              this.timeout = true;
              this.apiCalled = false
              this.selectedDate = false;
              // this.threadData.push({
              //   align: 'left',
              //   role: 'assistant',
              //   content: {
              //     "type": "text",
              //     "text": "Error02"f
              //   },
              // });
            }
          );
      }

    }
    // this.selectedDate = this.startDate && this.enddate;
  }

  reportObj: any = {};
  extractDataElements(): void {
    // Extract report name and period
    const reportNameRegex = /<b>Report Name:<\/b>\s*(.*?)\s*\(/;
    const periodRegex = /Period:\s*(.*?)\s*\)/;

    const reportNameMatch = this.reportData.match(reportNameRegex);
    const periodMatch = this.reportData.match(periodRegex);

    if (reportNameMatch && periodMatch) {
      this.reportObj.reportName = reportNameMatch[1];
      this.reportObj.period = periodMatch[1];
    }

    // Extract summary of data
    const summaryOfDataRegex = /<p><b>Summary of Data:<\/b><\/p>\s*<p>(.*?)<\/p>/;
    const summaryOfDataMatch = this.reportData.match(summaryOfDataRegex);

    if (summaryOfDataMatch) {
      this.reportObj.summaryOfData = summaryOfDataMatch[1];
    }
    console.log("reportObj", this.reportObj)
  }

  parseReportName(response: string): string {
    const start = response.indexOf('<b>Report Name: ') + '<b>Report Name: '.length;
    const end = response.indexOf('</b>', start);
    return response.substring(start, end).trim();
  }

  parseDateRange(response: string): string {
    const start = response.indexOf('<p><b>Period of Date Selection: ') + '<p><b>Period of Date Selection: '.length;
    const end = response.indexOf('</b></p>', start);
    return response.substring(start, end).trim();
  }

  parseSummaryData(response: string): string[] {
    const start = response.indexOf('<ul>') + '<ul>'.length;
    const end = response.indexOf('</ul>', start);
    const summary = response.substring(start, end);
    return summary.match(/<li>(.*?)<\/li>/g).map((item) => item.replace(/<\/?[^>]+(>|$)/g, "")); // Remove HTML tags
  }

  parseResponseData(response: string): {
    reportName: string;
    dateRange: string;
    summary: string;
  } {
    const reportName = response.substring(
      response.indexOf('<b>Report Name:</b>') + '<b>Report Name:</b>'.length,
      response.indexOf('</b>')
    ).trim();

    const dateRange = response.substring(
      response.indexOf('(Period:') + '(Period:'.length,
      response.indexOf(')')
    ).trim();

    const summary = response.substring(
      response.indexOf('<p><b>Summary of Data:</b></p>') + '<p><b>Summary of Data:</b></p>'.length,
      response.indexOf('</p>')
    ).trim();

    return { reportName, dateRange, summary };
  }

  // scrollToBottom() {
  //   console.log("this.scrollRef", this.scrollRef)
  //   if (this.scrollRef !== undefined) {
  //     console.log("SCROLLING_TO_BOTTOM")
  //     setTimeout(() => {
  //       this.scrollRef.SimpleBar.getScrollElement().scrollTop =
  //         this.scrollRef.SimpleBar.getScrollElement().scrollHeight + 1500;
  //     }, 30);
  //   }
  // }
  // scrollToBottom() {
  //   console.log("this.scrollRef", this.scrollRef);
  //   if (this.scrollRef !== undefined && this.scrollRef.nativeElement.SimpleBar) {
  //     console.log("SCROLLING_TO_BOTTOM");
  //     setTimeout(() => {
  //       const simpleBarInstance = this.scrollRef.nativeElement.SimpleBar;
  //       const scrollElement = simpleBarInstance.getScrollElement();
  //       scrollElement.scrollTop = scrollElement.scrollHeight + 1500;
  //     }, 30);
  //   }
  // }
  scrollToBottom(): void {
    try {
      console.log("SCROLLING_TO_BOTTOM");
      setTimeout(() => {
        this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight;
            }, 500);
    } catch(err) {
      console.error('Could not scroll to bottom:', err);
     }
}

  // scrollToBottom(): void {
  //   try {
  //     // const chatContainer = document.getElementById('chat-container');
  //     if (this.scrollRef !== undefined) {
  //       console.log("SCROLLING_TO_BOTTOM")
  //       this.scrollRef.SimpleBar.getScrollElement().scrollTop = this.scrollRef.SimpleBar.getScrollElement().scrollHeight;
  //     }
  //   } catch (err) {
  //     console.error('Could not scroll to bottom:', err);
  //   }
  // }

  sendCustomerReaction(reaction: number, chatId: any) {
    // Reation will be 1 for thumbs up and 2 for thumbs down
    if (reaction === 0) {
      console.log("reaction === 0")
      this.thumbshowup = false;
      this.thumbshowdown = false;
    } else if (reaction === 1) {
      console.log("reaction === 1")
      this.thumbshowup = true;
      this.thumbshowdown = false;
    } else if (reaction === 2) {
      console.log("reaction === 2")
      this.thumbshowup = false;
      this.thumbshowdown = true;
    }
    const payload = {
      chatId: chatId,
      reaction: reaction,
    }
    this.http.post(this.GLOBALURL + "/update/reaction", payload, this.httpOptions).subscribe(
      (response: any) => {
        if (response.success === 0) {
          console.log(response);
        } else {
          console.log(response)
        }
      },
      (error) => {
        // console.log(error);
        let message = this.ApiService.errorMessage(error);
        // this.toastr.error(message);
      }
    );
  }

  feedbackSelection: any = {};
  feedbackInput: string = "";
  feedbackSubmitted = false;
  sendCustomerFeedback() {
    const htmlObjects = this.threadData.filter(obj => obj.content.type === "html");
    console.log("htmlObjects", htmlObjects)
    const chatId = htmlObjects[htmlObjects.length - 1].chatId;
    // Extract selected feedback options and make them an array of strings
    const selectedFeedback = Object.keys(this.feedbackSelection)
      .filter(key => this.feedbackSelection[key])
      .map(key => key)

    const payload = {
      chatId: chatId,
      feedback: this.feedbackInput ? this.feedbackInput : '',
      quickFeedbacks: selectedFeedback,
    }
    this.http.post(this.GLOBALURL + "/update/feedback", payload, this.httpOptions).subscribe(
      (response: any) => {
        if (response.success === 0) {
          console.log(response);
          this.feedbackSubmitted = false;
        } else {
          this.feedbackSubmitted = true;
          console.log(response)
        }
      },
      (error) => {
        // console.log(error);
        let message = this.ApiService.errorMessage(error);
        // this.toastr.error(message);
        this.feedbackSubmitted = false;
      }
    );
  }

  selectedTag = "";

  SetSalesAcive(value: any,  tag?: any, nextLevel?: Number,index?:any) {
    const text = tag.heading;
    console.log("text================>", text);

    const firstWord = text.trim().split(" ")[0];
    const second = text.trim().split(" ")[1];

    this.selectedTag = tag;

    this.selectTag(tag, value,nextLevel);
    if (firstWord === 'Guest' && second === undefined) {
      setTimeout(() => {
        const targetDiv = document.getElementById(firstWord);
        if (targetDiv) {
          targetDiv.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
        }, 2000);
       return
      }
      if (firstWord === 'Cash' && second === 'and') {
        setTimeout(() => {
          const targetDiv = document.getElementById(firstWord);
          if (targetDiv) {
            targetDiv.scrollIntoView({ behavior: 'smooth', block: 'start' });
          }
          }, 2000);
         return
        }
        if ( second === 'Item') {
          setTimeout(() => {
            const targetDiv = document.getElementById(second);
            if (targetDiv) {
              targetDiv.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }
            }, 2000);
           return
          }
    if (firstWord === 'Guest' || firstWord === 'Cash') {
      setTimeout(() => {
        const targetDiv = document.getElementById(second);
        if (targetDiv) {
          targetDiv.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
        }, 2000);
    }else{
      setTimeout(() => {
        const targetDiv = document.getElementById(firstWord);
        if (targetDiv) {
          targetDiv.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
        }, 2000);
    }


  }

  setID(data:any){
    const text = data;
    const firstWord = text?.trim().split(" ")[0];
    const secondword = text?.trim().split(" ")[1];

    if (firstWord === 'Guest' && secondword === undefined) {
      return firstWord;
    }
    if (firstWord === 'Cash' && secondword === 'and') {
      return firstWord;
    }
    if (secondword === 'Item') {
      return secondword;
    }
    if (firstWord === 'Guest' || firstWord === 'Cash') {
      return secondword;

    }else {
      return firstWord;
    }
  }

  onKeyDownEvent(e: any) { }
  CloseMenu() {
    this.modalService.dismissAll();
  }
  Search() {
    this.isloading2 = true;
    this.isSecondDetailsShow = true;
  }
  fullscreenWidth: boolean = false;
  fullscreen() {
    this.fullscreenWidth = !this.fullscreenWidth;
    const elements2 = this.document.querySelectorAll(".Copilot");
    const elements3 = this.document.querySelectorAll(".maya-scroll-new");
    if (this.fullscreenWidth) {

      elements2.forEach((element: any) => {
        this.renderer.setStyle(element, "width", "100%");
        this.renderer.addClass(element, "fullscreen");

      });
      // elements3.forEach((element: any) => {
      //   this.renderer.removeClass(element, "maya-scroll-full");

      // });
    } else {
      // const elements2 = this.document.querySelectorAll(".Copilot");
      elements2.forEach((element: any) => {
        // this.renderer.setStyle(element, "width", "587px");
        this.renderer.removeClass(element, "fullscreen");
      });
      // elements3.forEach((element: any) => {
      //   this.renderer.addClass(element, "maya-scroll-full");

      // });
    }
  }

  resetForm() {
    this.ActiveSelectClass = 'grossSales';
    // this.ActiveSelectClass1 = "";
    this.isDetailsShow = false;
    this.startDate = "";
    this.enddate = "";
    this.selectedDate = false;
    this.formData.reset(); // This will reset all controls in the form
    this.threadData = [];
    localStorage.setItem('threadId', null)
    this.threadId = null
    this.dataGenerated = false
    this.IsShownresult = false;
    // this.selectedFirstLevelTag = "";
    this.selectedTag = "";
    this.feedbackSelection = {};
    this.feedbackInput = "";
    this.feedbackSubmitted = false;
    this.setUserProfile();
    this.loadChatBot();
  }

  stopResponding() {
    this.apiCalled = false;
  }

  // downloadFile(file: any) {
  //   const url = file.url;
  //   this.http.get(url, { responseType: 'blob' }).subscribe((data: Blob) => {
  //     const blob = new Blob([data], { type: 'application/octet-stream' });
  //     const downloadLink = window.document.createElement('a');
  //     downloadLink.href = window.URL.createObjectURL(blob);
  //     downloadLink.download = file.name; // Replace with the desired file name and extension
  //     document.body.appendChild(downloadLink);
  //     downloadLink.click();
  //     document.body.removeChild(downloadLink);
  //   });
  // }
  downloadFile(data: any) {
    const content = data.content.text;
    // Create a new window and set its content
    const printWindow = window.open('', '_blank');
    printWindow.document.write(content);
    // Wait for the content to load
    printWindow.onload = () => {
      // Trigger the print function
      printWindow.print();
      // Close the window after printing
      printWindow.close();
    };
  }
  httpOptions1 = { headers: new HttpHeaders(this.headerTokenDetails) };
  downloadReport(chatId: any) {
   // this.store.dispatch(setLoadingSpinner({ status: true }));
    const params = {
      chatId: chatId,
    };
    this.http
      .post(this.GLOBALURL + "/download/ai-reports", params, this.httpOptions1)
      .subscribe(
        (response: any) => {
         // this.store.dispatch(setLoadingSpinner({ status: false }));
          if (response.error === true) {
            this.toastr.error(response.message);
          } else {
            let headers = new HttpHeaders();
            headers = headers.set("Accept", "application/pdf");
            this.http
              .get(response.data.url, {
                headers: headers,
                responseType: "blob",
              })
              .subscribe((blob: Blob): void => {
                var blob = new Blob([blob], {
                  type: response.data.contentType,
                });
                var url = window.URL.createObjectURL(blob);
                var anchor = document.createElement("a");
                anchor.download = response.data.name;
                anchor.href = url;
                anchor.click();
                this.toastr.success("File exported");
              });
          }
        },
        (error) => {
          //this.store.dispatch(setLoadingSpinner({ status: false }));
        }
      );
  }


  // downloadReport(data: any) {
  //   const doc = new jsPDF();
  //   const textWithoutTags = data.replace(/<[^>]*>/g, ''); // Remove HTML tags
  //   doc.setFontSize(12);
  //   doc.text(textWithoutTags, 10, 10, { maxWidth: 180 });
  //   doc.save("report.pdf");
  // }

  // downloadReport(data: any) {
  //   const pdfString = data;
  //   const blob = new Blob([pdfString], { type: 'application/pdf' });
  //   const url = URL.createObjectURL(blob);
  //   const anchor = document.createElement('a');
  //   anchor.href = url;
  //   anchor.download = 'report.pdf';
  //   anchor.click();
  //   URL.revokeObjectURL(url);
  // }


  // downloadReport(data: any) {
  //   const doc = new jsPDF();
  //   // Defining HTMLOptions with margins
  //   const options = {
  //     callback: function (pdf) {
  //       // The callback function is called after the PDF is generated
  //       pdf.save(); // Save the generated PDF
  //     },
  //     margin: 10, // You can also use an array [top, right, bottom, left] for specific margins
  //     filename: 'report.pdf',
  //     width: 180, // Max width of content on PDF
  //     windowWidth: 210, // A4 width in mm
  //     //html2canvas: { scale: 2 },
  //     //windowWidth: 210, // A4 width in mm
  //   };
  //   //doc.setFontSize(2);
  //   // Using the html method to generate PDF from HTML content
  //   const htmlData = `<div style="font-size: 7px;width:100%">${data}</div>`
  //   doc.html(htmlData, options);
  // }

  copyData(text: string) {
    const textWithoutTags = text.replace(/<[^>]*>/g, ''); // Remove HTML tags
    this.clipboard.copy(textWithoutTags);
    // Optionally provide visual feedback to the user
    // this.imageshow = true; // Assuming you'll toggle an image to indicate success
    setTimeout(() => this.imageshow = false, 2000); // Example for visual feedback
  }

  checkReactionStatus(data: any) {
    console.log("checkReactionStatus", data)
    if (data.reaction === 0) {
      console.log("reaction === 0")
      this.thumbshowup = false;
      this.thumbshowdown = false;
    } else if (data.reaction === 1) {
      console.log("reaction === 1")
      this.thumbshowup = true;
      this.thumbshowdown = false;
    } else if (data.reaction === 2) {
      console.log("reaction === 2")
      this.thumbshowup = false;
      this.thumbshowdown = true;
    }

    // check the quickfeedbacks array length and if it is greater than 0 then Assign the Values to the feedbackSelection object
    if (data.quickFeedbacks.length > 0) {
      this.feedbackSubmitted = true;
      // data.quickFeedbacks.forEach((element: any) => {
      //   this.feedbackSelection[element] = true;
      // });
    } else {
      // this.feedbackSelection = {};
      this.feedbackSubmitted = false;
    }
  }
  checkforfeedback() {
    if(this.detailsloader &&
      !this.isSecondDetailsShow && this.threadData.some(obj => obj.content.type === "html"))
      return true;
    return false;
  }
}
