<div class="alert-section">
  <div *ngFor="let alert of alerts" class="{{cssClass(alert)}} custom-alert" [ngClass]="{ customPadding: alert.completeSettings !== undefined }">
    <ng-container *ngIf="alert.completeSettings !== undefined">
      <div class="message-div">
        <div class="complete-settings">
          <div class="alert-flex"><i class="bx bx-error"></i><span [innerHTML]="(alert.message | translate | async)" class="span-message"></span></div>
          <span><button [routerLink]="['/business-settings/location/update/', cookieService.get('storeId')]" class="form-control">{{ "Finish Setup" | translate | async }}</button></span>
        </div>
      </div>
      <div class="close-div">
        <!-- <a class="close" (click)="removeAlert(alert)">&times;</a> -->
      </div>
    </ng-container>
    <ng-container *ngIf="alert.completeSettings === undefined">
      <div class="message-div"><span [innerHTML]="(alert.message | translate | async)"></span></div>
      <div class="close-div"><a class="close" (click)="removeAlert(alert)">&times;</a></div>
    </ng-container>
  </div>
</div>