<ng-container *ngIf="!crossLinkLoader">
  <!-- vertical layout -->
    <app-vertical *ngIf="rtlChnage === 'left'"></app-vertical>
    <!-- <app-loader></app-loader> -->

    <dashboardrtl *ngIf="rtlChnage === 'right'"></dashboardrtl>


    <!-- horizontal layout -->
    <app-horizontal *ngIf="isHorizontalLayoutRequested()"></app-horizontal>
</ng-container>

<!-- <ng-container *ngIf="crossLinkLoader">
    <div id="preloader2">
        <div class="allpageLoader2" style="display: block;">
            <div class=""></div>
            <div class="h-100 w-100 d-flex justify-content-center align-items-center  loadingOption" style="background-color: #FBFBFB">
                <img alt="img" height="300" src="../../assets/images/loaders/eatos_loading_0-Black.gif" width="190" height="190">
            </div>
        </div>
    </div>
</ng-container> -->
