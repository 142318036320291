<ng-container *ngIf="selected$ !== null && show$">
  <div
    id="{{ selected$?.text }}"
    class="dropdown d-inline-block m-dnone drop-dataa"
    #targetElement
    ngbDropdown
    (click)="Addhover()"
  >
    <button
      type="button"
      class="btn header-item pl-2"
      [ngClass]="isVariableChanged ? '' : 'active-loaction'"
      ngbDropdownToggle
      id="page-header-user-dropdown"
    >
      <div
        class="d-flex align-items-center gap-2 dropmenu-cus location-text w-100"
        [style.font-size]="
          selected$.text.length >= 18
            ? '14px'
            : selected$.text.length >= 15
            ? '14px'
            : '14px'
        "
        >
      <span>
        <img class="pl-3" src="assets/images/topbar-icons/location.svg" alt="">
      </span>
      <span class="location-seletion">{{ selected$?.text }}</span>
</div>
      <!-- <i class="mdi mdi-chevron-down d-none d-xl-inline-block down-arrow"></i> -->
    </button>
    <div class="dropdown-menu droupdown-theme fw-500 dropdown-menu-start"  ngbDropdownMenu >
      <a
        class="dropdown-item location-sidebar"
        [ngClass]="selected$.id === item.id ? 'active pointer-event-none' : ''"
        *ngFor="let item of locations$"
        (click)="change(item)"
        href="javascript: void(0);"
      >
        {{ item.text == "All Locations" ? (item.text | translate | async) : item.text }}</a
      >
      <div class="dropdown-divider"></div>
      <a
        class="dropdown-item mb-3"
        href="javascript: void(0);"
        (click)="openLocationModal(content)"
      >
       <span> + </span> <span>{{ "Add New Location" | translate | async }}</span></a
      >
    </div>
  </div>
</ng-container>
<ng-template #content role="document" let-modal>
  <div class="modal-header modal-doc border-botton-none">
    <h5 class="modal-title" id="myModalLabel">{{ "Add Location" | translate | async }}</h5>
    <button
      type="button"
      class="btn-close"
      data-bs-dismiss="modal"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    ></button>
  </div>
  <!-- <div class="mb-2 f-16 fw-500" style="padding-left: 16px">
    <span>Enter the details for your new location.</span>
  </div> -->
  <div class="modal-body pb-3 pt-0 cus-height">
    <div class="container-fluid p-0" [formGroup]="form" (ngSubmit)="onSubmit()">
      <div class="row mb-3">
        <div class="mt-2" style="border: none;">
          <label for="categname" class="form-label"
            >{{ "Location Name*" | translate | async }}<img src="assets\images\!.png"
          /></label>
          <div class="form-input">
            <input
              type="text"
              class="form-control"
              id="categname"
              formControlName="storeName"
              [placeholder]="'Enter Location Name' | translate | async"
            />
            <div
              class="form-text text-danger"
              *ngIf="
                !f.get('storeName').valid && f.get('storeName').touched &&
                !f.get('storeName').hasError('maxlength')
              "
            >
              {{ "Please provide a valid Location name." | translate | async }}
            </div>
            <div
              class="form-text text-danger"
              *ngIf="f.get('storeName').hasError('maxlength')"
            >
              {{ "Location name should not exceed 50 characters." | translate | async }}
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12">
          <button
          class="primary_btn f-18 w-100 fw-700 text-white"
          type="submit"
          *ngIf="!isLoading"
          [disabled]="!f.valid"
          (click)="onSubmit()"
        >
          {{ "Add" | translate | async }}
        </button>

        <button
          *ngIf="isLoading"
          class="primary_btn f-18 w-100 fw-700 text-white spinner-wrapper"
          type="button"
          disabled
        >
          <span
            class="spinner-grow spinner-grow-sm"
            role="status"
            aria-hidden="true"
          ></span>
          <span class="pl-2">&nbsp;&nbsp;{{ "Please wait..." | translate | async }}</span>
        </button>
        </div>
      </div>

      <!-- ####### NEW MODAL CHANGES ########## -->
      <!-- <nav
        ngbNav
        #nav="ngbNav"
        class="nav-tabs gross_tabs d-flex flex-row"
        [(activeId)]="active"
      >
        <ng-container [ngbNavItem]="1">
          <a ngbNavLink>Restaurant Information</a>
        </ng-container>
        <ng-container [ngbNavItem]="2">
          <a ngbNavLink>Address Information</a>
        </ng-container>
        <ng-container [ngbNavItem]="3">
          <a ngbNavLink>Restaurant Settings</a>
        </ng-container>
      </nav>
      <div *ngIf="active === 1" class="card">
        <div class="card-body">
          <div class="mb-3 fw-600 f-14">Restaurant Information</div>
          <div class="row">
            <div class="col-lg-12">
              <div class="row mb-3">
                <div class="col-sm-5 mt-2">
                  <label for="categname" class="form-label"
                    >Restaurant Name*<img src="assets\images\!.png"
                  /></label>
                </div>
                <div class="col-sm-7">
                  <input
                    type="text"
                    class="form-control"
                    id="categname"
                    formControlName="storeName"
                    placeholder="Enter Restaurant Name"
                  />
                  <div
                    class="form-text text-danger"
                    *ngIf="
                      !f.get('storeName').valid && f.get('storeName').touched
                    "
                  >
                    Please provide a valid restaurant name.
                  </div>
                </div>
              </div>
              <div class="row mb-3">
                <div class="col-sm-5 mt-2">
                  <label for="categname" class="form-label"
                    >Restaurant Type<img src="assets\images\!.png"
                  /></label>
                </div>
                <div class="col-sm-7">
                  <select
                    class="form-select f-14"
                    formControlName="storeType"
                    name="storeType"
                  >
                    <option value="" selected disabled>
                      Select Restaurant Type
                    </option>
                    <option
                      *ngFor="let restaurantType of restaurantTypes"
                      [value]="restaurantType"
                    >
                      {{ restaurantType }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="row mb-3">
                <div class="col-sm-5 mt-2">
                  <label for="categname" class="form-label">
                    Email
                    <i
                      class="f-10 fas fa-info-circle vertical-align-super"
                      ngbPopover="Here you can add/edit the restaurant email id"
                      triggers="mouseenter:mouseleave"
                      popoverTitle="Email"
                      placement="right"
                    ></i>
                  </label>
                </div>
                <div class="col-sm-7">
                  <input
                    type="email"
                    class="form-control"
                    id="categname"
                    formControlName="storeEmail"
                    placeholder="Email"
                  />
                  <div
                    class="form-text text-danger"
                    *ngIf="
                      !f.get('storeEmail').valid && f.get('storeEmail').touched
                    "
                  >
                    Please provide a valid email.
                  </div>
                </div>
              </div>
              <div class="row mb-3">
                <div class="col-sm-5 mt-2">
                  <label for="categname" class="form-label">
                    Phone Number
                    <i
                      class="f-10 fas fa-info-circle vertical-align-super"
                      ngbPopover="Here you can add/edit the restaurant phone number"
                      triggers="mouseenter:mouseleave"
                      popoverTitle="Phone Number"
                      placement="right"
                    >
                    </i>
                  </label>
                </div>
                <div class="col-sm-7">
                  <ngx-intl-tel-input
                    class="text-start"
                    [cssClass]="{ 'form-control': 1 }"
                    [preferredCountries]="preferredCountries"
                    [enableAutoCountrySelect]="true"
                    [enablePlaceholder]="true"
                    [searchCountryFlag]="true"
                    [searchCountryField]="[
                      SearchCountryField.Iso2,
                      SearchCountryField.Name
                    ]"
                    [selectFirstCountry]="true"
                    [maxLength]="10"
                    [phoneValidation]="true"
                    [customPlaceholder]="placeholder"
                    [separateDialCode]="separateDialCode"
                    name="storeMobile"
                    [separateDialCode]="true"
                    formControlName="storeMobile"
                  >
                  </ngx-intl-tel-input>
                  <div
                    class="form-text text-danger"
                    *ngIf="
                      !f.get('storeMobile').valid &&
                      f.get('storeMobile').touched
                    "
                  >
                    Please provide a valid phone number.
                  </div>
                </div>
              </div>
              <div class="row mb-3">
                <div class="col-sm-5 mt-2">
                  <label for="categname" class="form-label">
                    Website
                    <i
                      class="f-10 fas fa-info-circle vertical-align-super"
                      ngbPopover="Here you can add/edit the restaurant website"
                      triggers="mouseenter:mouseleave"
                      popoverTitle="Website"
                      placement="right"
                    ></i>
                  </label>
                </div>
                <div class="col-sm-7">
                  <input
                    type="text"
                    class="form-control"
                    id="categname"
                    formControlName="storeWebpage"
                    placeholder="Website"
                  />
                </div>
                <div
                  class="form-text text-danger"
                  *ngIf="
                    !f.get('storeWebpage').valid &&
                    f.get('storeWebpage').touched
                  "
                >
                  Please provide a valid Website.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="active === 2" class="card">
        <div class="card-body">
          <div class="mb-3 fw-600 f-14">Address Information</div>
          <div class="row">
            <div class="col-lg-12">
              <div class="row mb-3">
                <div class="col-sm-5 mt-2">
                  <label for="categname" class="form-label">
                    Address
                    <i
                      class="f-10 fas fa-info-circle vertical-align-super"
                      ngbPopover="Here you can add/edit the restaurant address"
                      triggers="mouseenter:mouseleave"
                      popoverTitle="Address"
                      placement="right"
                    ></i>
                  </label>
                  <p>
                    <span class="f-10"
                      >To guarantee precise reporting, all data is displayed in
                      accordance with the timezone and address assigned to the
                      specified location.</span
                    >
                  </p>
                </div>
                <div class="col-sm-7">
                  <input
                    type="text"
                    class="form-control mb-3"
                    id="categname"
                    formControlName="storeAddress1"
                    placeholder="Address 1"
                    id="autocomplete"
                    autocorrect="off"
                    autocapitalize="off"
                    spellcheck="off"
                    #search
                  />
                  <input
                    type="text"
                    class="form-control mb-3"
                    id="categname"
                    formControlName="storeAddress2"
                    placeholder="Address 2"
                  />
                  <input
                    type="text"
                    class="form-control mb-3"
                    id="categname"
                    formControlName="storeCity"
                    placeholder="City"
                  />
                  <input
                    type="text"
                    class="form-control mb-3"
                    id="categname"
                    formControlName="storeState"
                    placeholder="State"
                  />
                  <div class="row">
                    <div class="col-6">
                      <input
                        type="text"
                        class="form-control mb-3"
                        id="categname"
                        formControlName="storeCountry"
                        placeholder="Country"
                      />
                    </div>
                    <div class="col-6">
                      <input
                        type="text"
                        class="form-control mb-3"
                        id="categname"
                        formControlName="storeZipcode"
                        placeholder="Zip code"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-12">
              <div class="row mb-3">
                <div class="col-sm-5 mt-2">
                  <label for="categname" class="form-label">Timezone</label>
                </div>
                <div class="col-sm-7 align-self-center">
                  <input
                    class="form-select f-14 bgImg-none"
                    formControlName="storeTimezone"
                    name="timezone"
                    readonly
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="active === 2" class="card">
        <div class="card-body">
          <div class="mb-3 fw-600 f-14">Location Map</div>
          <div class="row">
            <div class="col-lg-12">
              <agm-map
                [latitude]="latitude"
                [longitude]="longitude"
                [zoom]="16"
                [disableDefaultUI]="false"
                [streetViewControl]="false"
              >
                <agm-marker
                  [latitude]="latitude"
                  [markerDraggable]="true"
                  [longitude]="longitude"
                ></agm-marker>
              </agm-map>
            </div>
          </div>
        </div>
      </div> -->

       <!-- <select class="form-select f-14" formControlName="storeTimezone" name="timezone" >
                              <option value="" selected disabled>Select Timezone</option>
                              <ng-container>
                                  <optgroup *ngFor="let timezone of timeZones" label="{{timezone.text}}">
                                      <option *ngFor="let timezone of timezone.utc" [value]="timezone">{{timezone}}
                                      </option>
                                  </optgroup>
                              </ng-container>

                          </select> -->

      <!-- <div class="card">
          <div class="card-body">
              <div class="mb-3 fw-600 f-14">
                  Restaurant Features
              </div>
              <div class="row">
                  <div class="col-lg-12">
                      <div *ngFor="let setting of settings" class="row mb-3">
                          <div class="col-sm-5 mt-2">
                              <label for="categname" class="form-label">{{ setting.displayName }}
                                <i class="f-10 fas fa-info-circle vertical-align-super"
                              [ngbPopover]= setting.ngbPopover
                              triggers="mouseenter:mouseleave"
                              [popoverTitle]= setting.popoverTitle
                              placement="right"></i>
                                    </label>
                          </div>
                          <div class="col-sm-7 align-self-center">
                              <div class="form-check form-switch"> <input class="p-0 form-check-input" type="checkbox"
                                      id="flexSwitchCheckChecked2" [(ngModel)]="setting.settingValue" [ngModelOptions]="{standalone: true}" checked></div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div> -->

      <!-- ###### NEW MODAL CHANGES ############# -->
      <!-- <div *ngIf="active === 3" class="card">
        <div class="card-body">
          <div class="mb-3 fw-600 f-14">Restaurant Settings</div>
          <div class="row">
            <div class="col-lg-12">
              <div class="row mb-3">
                <div class="col-sm-5 mt-2">
                  <label for="categname" class="form-label"
                    >Inactivity Timer</label
                  >
                </div>
                <div class="col-sm-7 align-self-center">
                  <input
                    type="range"
                    class="custom-range"
                    id="categname"
                    formControlName="inactivity"
                    min="0"
                    max="60"
                    (input)="getPrepTime($event)"
                  />

                  {{ returnPrepTime }} <label class="form-label">Min</label>
                </div>
              </div>
              <div class="row mb-3">
                <div class="col-sm-5 mt-2">
                  <label for="categname" class="form-label">Language</label>
                </div>
                <div class="col-sm-7 align-self-center">
                  <select
                    class="form-select f-14"
                    [(ngModel)]="restaurantSettings[1].settingValue"
                    [ngModelOptions]="{ standalone: true }"
                    name="language"
                  >
                    <option value="" selected disabled>Select Language</option>
                    <option
                      *ngFor="let language of languages"
                      [value]="language"
                    >
                      {{ language }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="row mb-3">
                <div class="col-sm-5 mt-2">
                  <label for="categname" class="form-label">Currency</label>
                </div>
                <div class="col-sm-7 align-self-center">
                  <select
                    class="form-select f-14"
                    [(ngModel)]="restaurantSettings[2].settingValue"
                    [ngModelOptions]="{ standalone: true }"
                    name="currency"
                  >
                    <option value="" selected disabled>Select Currency</option>
                    <option
                      *ngFor="let currency of currencies"
                      [value]="currency"
                    >
                      {{ currency }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="form-btn mb-0" *ngIf="active === 1 || active === 2">
        <div class="d-flex">
          <div>
            <button class="cancel-btn me-3" (click)="backTab()">
              {{ active === 1 ? "Cancel" : "Back" }}
            </button>
          </div>
          <div>
            <button class="save-btn" type="button" (click)="nextTab()">
              Next
            </button>
          </div>
        </div>
      </div>

      <div class="form-btn mb-0" *ngIf="active === 3">
        <div class="d-flex">
          <div>
            <button class="cancel-btn me-3" (click)="backTab()">Back</button>
          </div>
          <div>
            <button
              class="save-btn"
              type="submit"
              *ngIf="!isLoading"
              [disabled]="!f.valid"
              (click)="onSubmit()"
            >
              Save
            </button>

            <button
              *ngIf="isLoading"
              class="save-btn spinner-wrapper"
              type="button"
              disabled
            >
              <span
                class="spinner-grow spinner-grow-sm"
                role="status"
                aria-hidden="true"
              ></span>
              <span class="pl-2">&nbsp;&nbsp;Please wait...</span>
            </button>
          </div>
        </div>
      </div> -->
    </div>
  </div>
</ng-template>
