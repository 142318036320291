import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { ApiService } from "../../../../services/api";
import { AppState } from '../../../../store/app.state';
import { AlertService } from '../../AlertService/alert.service';
@Injectable({
    providedIn: 'root',
})
export class SalesFetchOrderService {
    timeoutInterval: any;
    GLOBALURL = this.ApiService.getBaseURL();
    constructor(
        private http: HttpClient,
        private toastr: AlertService,
        private store: Store<AppState>,
        private cookieService: CookieService,
        private ApiService: ApiService
    ) { }

    //sending login credentials to server for validate
    getSalesOrder(
        merchantId: any,
        storeId: any,
        employeeSelected: any,
        orderTypeSelected: any,
        orderStatusSelected: any,
        orderNumber: any,
        startDateDisplay: any,
        endDateDisplay: any,
        startTime: any,
        endTime: any,
        page: any,
        item: any
    ): Observable<any> {
        const headerToken = { 'Content-Type': 'application/json', 'Authorization': this.cookieService.get('token') }
        var params = new HttpParams()
            .set("startDate", startDateDisplay)
            .set("endDate", endDateDisplay)

        if (employeeSelected !== '') {
            params = params.append("serverId", employeeSelected)
        }
        if (orderStatusSelected !== '') {
            params = params.append("orderSellStatus", orderStatusSelected)
        }
        if (orderTypeSelected !== '') {
            params = params.append("orderType", orderTypeSelected)
        }
        // if (startTime !== '' && endTime !== '') {
        //     params = params.append("startTime", startTime)
        //     params = params.append("endTime", endTime)
        // }
        // if (storeId !== '*') {
            params = params.append("storeId", storeId)
        // }
        if (
            (startTime !== "00:00" && endTime === "23:59") ||
            (endTime !== "23:59" && startTime === "00:00") ||
            (startTime !== "00:00" && endTime !== "23:59")
        ) {
            params = params.set("startTime", startTime);
            params = params.set("endTime", endTime);
        }
        params = params.append("merchantId", merchantId)
        params = params.append("page", page);
        params = params.append("items", item);
        if (orderNumber !== '' && orderNumber !== undefined) {
            params = params.append("orderNumber", orderNumber);
        }
        const httpOptions = { headers: new HttpHeaders(headerToken), params: params };
        return this.http.get<any>(
            this.GLOBALURL + '/order',
            httpOptions
        ).pipe(shareReplay(1));
    }
    findChidlOrders = (childOrders, chOrders) => {
        if (childOrders) {
            let ordersFound;
            let ordersFilter;
            let orders = [];
            childOrders.map(orderId => {
                ordersFilter = chOrders.filter(findParentId => {
                    return findParentId.orderId === orderId
                })
                if (ordersFilter.length !== 0) {
                    orders = orders.concat(ordersFilter)
                }
                return orders
            })
            return orders
        }
    }
    sendReceiptMobile(phone: any, orderId: any) {
        const headerToken = { 'Content-Type': 'application/json', 'Authorization': this.cookieService.get('token') }
        const httpOptions1 = {headers: new HttpHeaders(headerToken)};
        var params = {
            mobile: phone,
            orderId: orderId
        }
        return this.http.post(this.GLOBALURL + '/receipt', params, httpOptions1).subscribe((response: any) => {
            if (response.success === 1) {
                this.toastr.success(response.message);
                //jQuery('#sendReceipts').modal('hide');
            } else {
                this.toastr.error(response.message)
            }
        }, (error) => {
          const message = this.ApiService.errorMessage(error)
          this.toastr.error(message);
        });
    }

    sendReceiptToEmail(email: any, orderId: any) {
        const headerToken = { 'Content-Type': 'application/json', 'Authorization': this.cookieService.get('token') }
        const httpOptions1 = {headers: new HttpHeaders(headerToken)};
        var params = {
            email: email,
            orderId: orderId
        }
        return this.http.post(this.GLOBALURL + '/receipt', params, httpOptions1).subscribe((response: any) => {
            if (response.success === 1) {
                this.toastr.success(response.message);
                //jQuery('#sendReceipts').modal('hide');
            } else {
                this.toastr.error(response.message)
                //jQuery('#sendReceipts').modal('hide');
            }
        }, (error) => {
            const message = this.ApiService.errorMessage(error)
            this.toastr.error(message);
        });
    }
    sortingColumnsByOrderId(arr, attr, order) {
        return arr.sort((first, second) => {
            if (parseInt(first[attr]) < parseInt(second[attr])) {
                if (order) {
                    return 1;
                } else {
                    return -1;
                }
            } else if (parseInt(first[attr]) > parseInt(second[attr])) {
                if (order) {
                    return -1;
                } else {
                    return 1;
                }
            }
        })

    }
}
