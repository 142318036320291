import { ITransactionSummary, ITrasactionMeta } from "src/app/core/models/sales/transactions/transaction.model";

export interface SalesTransactionState {
    data: ITransactionSummary;
    meta: ITrasactionMeta;
    showLoader: Boolean;
    employee: any[];
    activeEmployees:any [];
    transactions: any[];
    pageLoad: Boolean;
    pageMetaData: any;
}

export const initialState: SalesTransactionState = {
    data: {
        closedTransactions: 0,
        deliveryAmount: "0.00",
        discounts: "0.00",
        grossSales: "0.00",
        netSales: "0.00",
        promoCodeAmount: "0.00",
        serviceCharges: "0.00",
        tax: "0.00",
        tips: "0.00",
        totalAmount: "0.00",
        transactions: 0,
    },
    meta: {
        storeName: "",
        storeTimezone: "",
        startDate: "",
        endDate: ""
    },
    showLoader: false,
    employee: [],
    activeEmployees:[],
    transactions: [],
    pageLoad: true,
    pageMetaData: {}
};