
import { OnDestroy, Pipe, PipeTransform } from '@angular/core';
import { Store } from "@ngrx/store";
import { Observable, Subscription, of } from 'rxjs';
import { shareReplay, tap } from 'rxjs/operators';
import { AppState } from "src/app/store/app.state";
import { setLoadingSpinner } from 'src/app/store/sharedstate/shared.actions';
import { TranslateService } from '../services/translate.service';
@Pipe({
  name: 'translate',
  pure: false
})
export class TranslatePipe implements PipeTransform, OnDestroy {
  private cache: { [key: string]: Observable<string> } = {};
  private currentLang: string;
  private subscription: Subscription;

  constructor(private translateService: TranslateService, private store: Store<AppState>) {
    this.subscription = this.translateService.currentLanguage$.subscribe(lang => {
      // this.currentLang = lang;
      this.currentLang = localStorage.getItem('targetLanguage') || 'en';
      this.cache = {};  // Clear cache when language changes
    });

    this.currentLang = localStorage.getItem('targetLanguage') || 'en';
    // console.log('currentLang pipe', this.currentLang)

  }

  transform(value: string): Observable<string> {

    if (this.currentLang === 'en' || this.currentLang === 'en-GB') {
      return of(value);
    }
    const cacheKey = `${value}_${this.currentLang}`;

    if (!this.cache[cacheKey]) {
      this.store.dispatch(setLoadingSpinner({ status: true }));
      this.cache[cacheKey] = this.translateService.translate(value, this.currentLang).pipe(
        shareReplay(1),
        tap(() => this.store.dispatch(setLoadingSpinner({ status: false })))
      );
    }

    return this.cache[cacheKey];
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
 
}
