import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { isAuthenticated } from 'src/app/account/auth/state/auth.selector';
import { AppState } from 'src/app/store/app.state';
@Injectable({ providedIn: 'root' })
export class LoginGuard implements CanActivate {
    constructor(
        private store: Store<AppState>,
        private router: Router
    ) { }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ){
        return this.store.select(isAuthenticated).pipe(
            map((authenticate) => {
                if (authenticate) {
                    return this.router.createUrlTree(['/']);
                }
                return true;
            })
        );
    }
}


