import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { MapsAPILoader } from "@agm/core";
import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  NgZone,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
} from "@angular/core";
import { Store } from "@ngrx/store";
import { CookieService } from "ngx-cookie-service";
import { CountryISO, SearchCountryField } from "ngx-intl-tel-input";
import { ILocaData } from "src/app/core/models/location.model";
import { AppState } from "src/app/store/app.state";
import { environment } from "src/environments/environment";
import {
  locationFetchStart,
  locationSelect,
} from "../locationstate/location.actions";
import {
  getSelectedStoreLocation,
  getShowLocation,
  getStoreLocations,
} from "../locationstate/location.selectors";
import { Injectable } from "@angular/core";
import { StoreLocationService } from "src/app/core/services/storelocation.service";
import { Location } from "./location";
import {
  FormControl,
  FormGroup,
  Validators,
  FormBuilder,
} from "@angular/forms";
import { setLoadingSpinner } from "src/app/store/sharedstate/shared.actions";
import { locationBroadcastService } from "src/app/core/services/loaction-broadcast.service";
import { AlertService } from "src/app/core/services/AlertService/alert.service";
import { LocationService } from "src/app/core/services/location.service";
import { LocationsService } from "src/app/core/services/locations/locations.service";
import { ApiService } from "src/app/services/api";
import { delay } from "rxjs/operators";

import { ActivatedRoute, Router } from "@angular/router";
import { noWhiteSpaceValidator } from "src/app/shared/directive/validators";
import { create } from "domain";
// import { autoLocationService } from 'src/app/services/autoLocationService';
import { HttpClient } from "@angular/common/http";
// import * as moment from 'moment';
import { breadcrumbItem } from "src/app/components/breadcrumbs/breadcrumbs.model";
import { TimezoneService } from "src/app/services/timezone";
import { AppAliasService } from "src/app/core/services/app-alias.service";
import { AuthenticationService } from "src/app/core/services/auth.service";

@Component({
  selector: "app-location-menu",
  templateUrl: "./location-menu.component.html",
  styleUrls: ["./location-menu.component.scss"],
})
export class LocationMenuComponent implements OnInit {
  @Output() currentSelect: string;
  @Output() changed: EventEmitter<any> = new EventEmitter();
  @Output() newStore: EventEmitter<any> = new EventEmitter();
  @Input() class: String;
  // constructor(
  //   private cookieService: CookieService,
  //   private locationService: StoreLocationService,
  //   private toastr: AlertService,
  //   // private mapsAPILoader: MapsAPILoader,
  //   private ngZone: NgZone,
  //   private modalService: NgbModal,
  //   private locService: locationBroadcastService,
  //   private locationMenuService: LocationService,
  //   private locationsService: LocationsService,
  //   private ApiService: ApiService,
  //   private mapsAPILoader: MapsAPILoader,

  // ) { }
  active = 1;
  locations$ = [];
  selected$: any;
  show$: Boolean;
  showclass: boolean = false;
  @ViewChild("targetElement") targetElement: ElementRef;
  isVariableChanged: boolean = true;

  // restaurantTypes = ["Restaurant System", "Bar", "Quick Service", "Nightclub", "Pizza", "Fine Dining", "Casual Dining", "Deli", "Brewery", "Drive-through", "Winery", "Bakery"];
  // merchantMobile: any
  // detectChanges: boolean = true;
  // separateDialCode = true;
  // SearchCountryField = SearchCountryField;
  // CountryISO = CountryISO;
  // // preferredCountries: CountryISO[] = [this.locationsService.getUserCountry(), CountryISO.UnitedKingdom];
  // lat = 40.730610;
  // lng = -73.935242;
  // languages = ['English', 'Spanish']
  // currencies = ['USD - $', 'MXN - $', 'INR - ₹']
  // Location: Location = {} as Location
  // // storeId = this.cookieService.get('storeId');
  // @ViewChild("search")
  // public searchElementRef: ElementRef;
  // locationName: any = ''

  // ngOnInit(): void {
  //   this.locationForm = new FormGroup({
  //     restaurantName: new FormControl('', [Validators.required]),
  //     restaurantType: new FormControl(),
  //     email: new FormControl('', [Validators.email]),
  //     phoneNumber: new FormControl(),
  //     website: new FormControl(),
  //     address: new FormControl('', [Validators.required]),
  //     address2: new FormControl(),
  //     city: new FormControl(),
  //     state: new FormControl(),
  //     country: new FormControl(),
  //     zip: new FormControl(),
  //     reservations: new FormControl(),
  //     orderAhead: new FormControl(),
  //     delivery: new FormControl(),
  //     splitCheck: new FormControl(),
  //     offlineMode: new FormControl(),
  //     lrs: new FormControl(),
  //     inactivityTimer: new FormControl(),
  //     language: new FormControl(),
  //     currency: new FormControl(),
  //     offset: new FormControl('', [Validators.required]),
  //   })
  //   var componentForm = {
  //     street_number: 'short_name',
  //     locality: 'long_name',
  //     administrative_area_level_1: 'long_name',
  //     country: 'long_name',
  //     postal_code: 'short_name'
  //   };

  //   // this.mapsAPILoader.load().then(() => {
  //   //   let autocomplete1 = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, {
  //   //     types: ["address"]
  //   //   });
  //   //   autocomplete1.addListener("place_changed", () => {
  //   //     this.ngZone.run(() => {
  //   //       //get the place result
  //   //       let place: google.maps.places.PlaceResult = autocomplete1.getPlace();
  //   //       for (var i = 0; i < place.address_components.length; i++) {
  //   //         var addressType = place.address_components[i].types[0];
  //   //         if (componentForm[addressType]) {
  //   //           var val = place.address_components[i][componentForm[addressType]];
  //   //           if (addressType == "route") {
  //   //             this.Location.address = val;
  //   //           }
  //   //           if (addressType == "locality") {
  //   //             this.Location.city = val;
  //   //           }
  //   //           if (addressType == "administrative_area_level_1") {
  //   //             this.Location.state = val;
  //   //           }
  //   //           if (addressType == "postal_code") {
  //   //             this.Location.zip = val;
  //   //           }
  //   //           if (addressType == "country") {
  //   //             this.Location.country = val;
  //   //           }
  //   //         }
  //   //       }
  //   //       this.Location.address = place.name;
  //   //       //verify result
  //   //       if (place.geometry === undefined || place.geometry === null) {
  //   //         return;
  //   //       }
  //   //     });
  //   //   });
  //   // });

  //   this.getTimezonesFunction()
  //   // throw new Error("Method not implemented.");

  //   this.getSelectedStoreLocationRetry()
  //   this.getShowLocationRetry()
  //   this.getStoreLocations()
  //   // this.store.select(getSelectedStoreLocation).subscribe((result) => {
  //   //   this.selected$ = result;
  //   // })
  //   // this.store.select(getShowLocation).subscribe((result) => {
  //   //   this.show$ = result
  //   // })
  //   // this.getSelectedStoreLocationRetry()
  //   // this.getShowLocationRetry()

  //   // this.getStoreLocations()

  //   console.log('~~~~~~~~~~~~~~~~~THIS IS FIRED!~~~~~~~~~~~~~~~~')
  // }

  nextTab() {
    if (this.active === 1) {
      this.active = 2;
      return;
    }
    if (this.active === 2) {
      this.active = 3;
      return;
    }
  }

  backTab() {
    if (this.active === 1) {
      this.modalService.dismissAll();
      return;
    }
    if (this.active === 3) {
      this.active = 2;
      return;
    }
    if (this.active === 2) {
      this.active = 1;
      return;
    }
  }
  userData = JSON.parse(localStorage.getItem("userData"));
  loggedInUserStoreId = this.userData?.response?.storeId;
  getSelectedStoreLocationRetry() {
    this.store
      .select(getSelectedStoreLocation)
      .pipe(delay(200))
      .subscribe((data) => {
        if (data !== null) {
          this.selected$ = data;
        } else {
          this.getSelectedStoreLocationRetry();
        }
      });
  }

  getShowLocationRetry() {
    this.store
      .select(getShowLocation)
      .pipe(delay(200))
      .subscribe((data) => {
        if (data) {
          this.show$ = data;
        } else {
          this.getShowLocationRetry();
        }
      });
  }

  openLocationModal(content: any) {
    let user = this.authService.getUserFromLocalStorage();
    if (!user.response.isOwner) {
      this.toastr.error('You are not able to add location. Only accounts that are fully registered with subscription can create new locations.');
      return;
    }
    this.modalService.open(content, { size: "sm location", centered: true });
    this.renderer.removeClass(document.body, "modal-open");
  }

  // createStoreLocation() {
  //   const payload = {
  //     storeName: this.locationName
  //   }
  //   this.locationMenuService.createStoreLocation(payload).subscribe(
  //     (response: any) => {
  //       if (response.success === 0) {
  //         this.toastr.error(response.message);
  //         // this.isLoadingChangeSub.next(false);
  //       } else {
  //         this.cookieService.set("newStoreId", response.response.storeId, 70, '/');
  //         this.newStoreCreated()
  //         this.getStoreLocations()
  //         this.modalService.dismissAll();
  //         // this.toastr.success(response.message);
  //         // this.modalService.dismissAll()
  //         // const data = [...JSON.parse(localStorage.getItem('locations'))]
  //         // this.change(data[data.length - 1])
  //         // this.isLoadingChangeSub.next(false);
  //         // this.router.navigate(["/menumanagement/menus"]);
  //       }
  //     },
  //     (error) => {
  //       console.log(error);
  //       const message = this.ApiService.errorMessage(error)
  //       this.toastr.error(message);
  //       // this.isLoadingChangeSub.next(false);
  //     }
  //   );
  // }
  // newStoreCreated() {
  //   this.newStore.emit()
  // }

  change(item: any) {
    localStorage.setItem("isTestModeOn", "0");
    localStorage.setItem("currency", item.currency ? item.currency : "$");
    this.selected$ = item;
    // this.optionsChangeClassOut();
    this.cookieService.set("storeId", this.selected$.id, 70, "/");
    this.cookieService.set("storeName", this.selected$.text, 70, "/");
    this.cookieService.set("logo", this.selected$.logo, 70, "/");
    this.cookieService.set(
      "storeCountry",
      this.selected$.storeCountry,
      70,
      "/"
    );
    this.AppAliasService.setStoreValue(this.selected$.id);
    this.cookieService.set("timezone", this.selected$.storeTimezone, 70, "/");
    // this.cookieService.set("storeThemeColor",  this.selected$.storeThemeColor, 70, '/');
    // document.documentElement.style.setProperty('--wbColor', this.selected$.storeThemeColor)
    this.cookieService.set("storeThemeColor", "#212121", 70, "/");
    document.documentElement.style.setProperty("--wbColor", "#212121");
    // this.getSelected(item)
    this.changed.emit(this.selected$);
    // this.locService.updateApprovalMessage(this.selected$.id)
  }
  getStoreLocations() {
    // this.getSelected()
    this.store.dispatch(locationFetchStart());
  }
  // getSelected(item: any) {
  //   this.store.dispatch(locationSelect({ selected: item }))
  // }

  // phoneMsg: boolean = false;
  // validatePhone() {
  //   // if (this.merchantMobile == null) {
  //     if (!this.merchantMobile || this.merchantMobile == null || this.merchantMobile.message == undefined) {
  //     this.phoneMsg = true;
  //   } else {
  //     this.phoneMsg = false;
  //   }
  // }
  // detectChangesFunc(e) {
  //   if (typeof (e) != "object") {
  //     if (e) {
  //       this.detectChanges = false;
  //     } else {
  //       this.detectChanges = true;
  //     }
  //   }
  // }

  // timeZones: any = [];
  getTimezonesFunction() {
    this.locationServices.getTimeZone().subscribe(
      (response: any) => {
        if (response.success == 0) {
          this.toastr.error(response.message);
        } else {
          response.forEach((element) => {
            this.timeZones.push(element);
          });
        }
      },
      (error) => {
        const message = this.ApiService.errorMessage(error);
        this.toastr.error(message);
      }
    );
  }

  // // new location form
  // locationForm!: FormGroup

  // get restaurantName() {
  //   return this.locationForm.get('restaurantName')
  // }
  // get restaurantType() {
  //   return this.locationForm.get('restaurantType')
  // }
  // get email() {
  //   return this.locationForm.get('email')
  // }
  // get phoneNumber() {
  //   return this.locationForm.get('phoneNumber')
  // }
  // get website() {
  //   return this.locationForm.get('website')
  // }
  // get address() {
  //   return this.locationForm.get('address')
  // }
  // get address2() {
  //   return this.locationForm.get('address2')
  // }
  // get city() {
  //   return this.locationForm.get('city')
  // }
  // get state() {
  //   return this.locationForm.get('state')
  // }
  // get county() {
  //   return this.locationForm.get('county')
  // }
  // get zip() {
  //   return this.locationForm.get('zip')
  // }
  // get reservations() {
  //   return this.locationForm.get('reservations')
  // }
  // get orderAhead() {
  //   return this.locationForm.get('orderAhead')
  // }
  // get delivery() {
  //   return this.locationForm.get('delivery')
  // }
  // get splitCheck() {
  //   return this.locationForm.get('splitCheck')
  // }
  // get offlineMode() {
  //   return this.locationForm.get('offlineMode')
  // }
  // get lrs() {
  //   return this.locationForm.get('lrs')
  // }
  // get inactivityTimer() {
  //   return this.locationForm.get('inactivityTimer')
  // }
  // get language() {
  //   return this.locationForm.get('language')
  // }
  // get currency() {
  //   return this.locationForm.get('currency')
  // }
  // get offset() {
  //   return this.locationForm.get('offset')
  // }

  // isReservation: boolean = true
  // EDreservation() {
  //   if (this.isReservation) {
  //     this.isReservation = false
  //   } else {
  //     this.isReservation = true
  //   }
  // }

  // isOrderAhead: boolean = true
  // EDorderAhead() {
  //   if (this.isOrderAhead) {
  //     this.isOrderAhead = false
  //   } else {
  //     this.isOrderAhead = true
  //   }
  // }

  // isDelivery: boolean = true
  // EDdelivery() {
  //   if (this.isDelivery) {
  //     this.isDelivery = false
  //   } else {
  //     this.isDelivery = true
  //   }
  // }

  // isSplitCheck: boolean = true
  // EDsplitCheck() {
  //   if (this.isSplitCheck) {
  //     this.isSplitCheck = false
  //   } else {
  //     this.isSplitCheck = true
  //   }
  // }

  // isOfflineMode: boolean = true
  // EDofflineMode() {
  //   if (this.isOfflineMode) {
  //     this.isOfflineMode = false
  //   } else {
  //     this.isOfflineMode = true
  //   }
  // }

  // isLRS: boolean = true
  // EDlrs() {
  //   if (this.isLRS) {
  //     this.isLRS = false
  //   } else {
  //     this.isLRS = true
  //   }
  // }

  // locationFormReset() {
  //   this.locationForm.reset()
  // }

  // addNewLocation() {
  //   // if (this.storeId === '*' || this.storeId === undefined) {
  //   //   this.toastr.error('Please select a location first');
  //   //   return
  //   // }
  //   let payload = {
  //     storeName: this.Location.restaurantName,
  //     storeType: this.Location.restaurantType,
  //     storeEmail: this.Location.email,
  //     storeLogo: '',
  //     storeMobile: this.Location.phoneNumber.number,
  //     storeWebpage: this.Location.website,
  //     storeTimezone: this.Location.offset,
  //     storeAddress1: this.Location.address,
  //     storeAddress2: this.Location.address2,
  //     storeState: this.Location.state,
  //     storeCity: this.Location.city,
  //     storeCountry: this.Location.country,
  //     storeZipcode: this.Location.zip,
  //     storeLatitude: this.Location.lat,
  //     storeLongitude: this.Location.long
  //   };
  //   this.store.dispatch(setLoadingSpinner({ status: true }))
  //   this.locationService.addNewLocation(payload).subscribe((response: any) => {
  //     this.store.dispatch(setLoadingSpinner({ status: false }))
  //     if (response.success === 0) {
  //       this.toastr.success(response.message)
  //     } else if (!response.error) {
  //       this.toastr.success(response.message)
  //     }
  //   });
  // }
  @ViewChild("search")
  public search: ElementRef;
  public searchControl: FormControl;

  form: FormGroup;
  placeholder: string = "201-555-0123";
  settings = [
    {
      settingName: "Reservation",
      settingValue: "true",
      displayName: "Reservation",
      ngbPopover:
        "Here you can enable/disable reservation option to be visible on the application",
      popoverTitle: "Reservation",
    },
    {
      settingName: "OrderAhead",
      settingValue: "true",
      displayName: "Order Ahead",
      ngbPopover:
        "Here you can enable/disable Order Ahead option to be visible on the application",
      popoverTitle: "Order Ahead",
    },
    {
      settingName: "Delivery",
      settingValue: "true",
      displayName: "Delivery",
      ngbPopover:
        "Here you can enable/disable Delivery option to be visible on the application",
      popoverTitle: "Delivery",
    },
    {
      settingName: "SplitCheck",
      settingValue: "true",
      displayName: "Split Check",
      ngbPopover:
        "Here you can enable/disable Split Check option to be visible on the application",
      popoverTitle: "Split Check",
    },
    {
      settingName: "OfflineMode",
      settingValue: "true",
      displayName: "Offline Mode",
      ngbPopover:
        "Here you can enable/disable Offline Mode option to be visible on the application",
      popoverTitle: "Offline Mode",
    },
  ];

  restaurantSettings = [
    {
      settingName: "InactivityTimer",
      settingValue: "true",
      displayName: "Inactivity Timer",
    },
    { settingName: "Language", settingValue: "", displayName: "Language" },
    { settingName: "Currency", settingValue: "", displayName: "Currency" },
    { settingName: "Timezone", settingValue: "", displayName: "Timezone" },
  ];

  settingPayload: any;
  restaurantTypes: string[] = [
    "Restaurant System",
    "Bar",
    "Quick Service",
    "Nightclub",
    "Pizza",
    "Fine Dining",
    "Casual Dining",
    "Deli",
    "Brewery",
    "Drive-through",
    "Winery",
    "Bakery",
  ];

  languages: string[] = ["English", "Spanish"];
  currencies: string[] = ["USD-$", "MXN-$", "INR-₹"];
  timeZones: any = [];

  separateDialCode = true;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  preferredCountries: CountryISO[];

  isLoading: boolean = false;
  timeZone: any = {
    settingName: "Timezone",
    settingValue: "",
    displayName: "Timezone",
  };
  defaultVal: boolean = false;
  timezoneId: any;
  constructor(
    private locationServices: StoreLocationService,

    private fb: FormBuilder,
    private cookieService: CookieService,

    private router: Router,
    private locationService: LocationsService,
    private store: Store<AppState>,
    private toastr: AlertService,
    private route: ActivatedRoute,
    private ngZone: NgZone,
    private mapsAPILoader: MapsAPILoader,
    private timezoneService: TimezoneService,
    private ApiService: ApiService,
    private authService: AuthenticationService,

    private modalService: NgbModal,
    private renderer: Renderer2,
    // private location: autoLocationService,
    private http: HttpClient,
    private AppAliasService: AppAliasService,
  ) {
    this.form = fb.group({
      storeAddress1: [""],
      storeAddress2: [""],
      storeCity: [""],
      storeCountry: [""],
      storeEmail: ["", Validators.email],
      storeLatitude: [40.7828687],
      storeLogo: [""],
      storeLongitude: [-73.967543817],
      storeMobile: [""],
      storeName: ["", [Validators.required, noWhiteSpaceValidator, Validators.maxLength(50)]],
      storeState: [""],
      storeTimezone: [""],
      storeType: [""],
      storeWebpage: [""],
      storeZipcode: [""],

      //not intlcude
      inactivity: ["0"],
    });
  }

  // loggedInUserStoreId = "";
  ngOnInit(): void {
    // const userData = JSON.parse(localStorage.getItem("userData"));
    // this.loggedInUserStoreId = userData.response.storeId;
    const userCountry = this.locationService.getUserCountry();
    this.preferredCountries = userCountry === CountryISO.UnitedKingdom
      ? [CountryISO.UnitedKingdom]
      : [userCountry, CountryISO.UnitedKingdom];
    var componentForm = {
      street_number: "short_name",
      locality: "long_name",
      administrative_area_level_1: "long_name",
      country: "long_name",
      postal_code: "short_name",
    };

    this.store.select(getStoreLocations).subscribe((result) => {
      this.locations$ = result;
    });
    // this.getTimezonesFunction()
    //   // throw new Error("Method not implemented.");
    this.getSelectedStoreLocationRetry();
    this.getShowLocationRetry();
    this.getStoreLocations();
    this.getTimezones();
    // this.initializeMaps()
    // set default location---
    const timestamp = this.timezoneService.get()().startOf("day").unix();
    this.AppAliasService.getValue().subscribe((data) => {
      this.onLatest();
    });

  }
  onLatest() {
    this.store.select(getStoreLocations).subscribe((result) => {
      this.locations$ = result;
    });
    this.getSelectedStoreLocationRetry();
    this.getShowLocationRetry();
    this.getStoreLocations();
  }
  lat: any;
  lon: any;
  /**
   * Initialize Address Autocomplete
   */
  initializeMaps() {
    this.searchControl = new FormControl();
    var componentForm = {
      street_number: "short_name",
      locality: "long_name",
      administrative_area_level_1: "long_name",
      country: "long_name",
      postal_code: "short_name",
    };
    this.mapsAPILoader.load().then(() => {
      let autocomplete1 = new google.maps.places.Autocomplete(
        this.search.nativeElement,
        {
          types: ["address"],
        }
      );
      autocomplete1.addListener("place_changed", () => {
        this.ngZone.run(() => {
          //get the place result
          let place: google.maps.places.PlaceResult = autocomplete1.getPlace();
          this.lat = place.geometry.location.lat();
          this.lon = place.geometry.location.lng();
          this.form.controls.storeLatitude.setValue(this.lat);
          this.form.controls.storeLongitude.setValue(this.lon);
          if (place && place.address_components) {
            for (var i = 0; i < place.address_components.length; i++) {
              var addressType = place.address_components[i].types[0];
              if (componentForm[addressType]) {
                var val =
                  place.address_components[i][componentForm[addressType]];
                if (addressType == "route") {
                  this.form.controls.storeAddress1.setValue(val);
                }
                if (addressType == "locality") {
                  this.form.controls.storeCity.setValue(val);
                }
                if (addressType == "administrative_area_level_1") {
                  this.form.controls.storeState.setValue(val);
                }
                if (addressType == "postal_code") {
                  this.form.controls.storeZipcode.setValue(val);
                }
                if (addressType == "country") {
                  this.form.controls.storeCountry.setValue(val);
                }
              }
            }
          }
          this.form.controls.storeAddress1.setValue(place.name);
          //verify result
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }
          this.updateTimeZoneId();
        });
      });
    });
  }
  get returnPrepTime() {
    return this.form.get("inactivity").value;
  }
  prepTimeValue: number = 0;
  getPrepTime($event: Event) {
    const value = ($event.target as HTMLInputElement).value;
    this.prepTimeValue = +value;
  }

  /**
   * Submit form method
   */
  onSubmit() {
    if (!this.f.valid) {
      this.f.markAllAsTouched();
      window.scroll(0, 0);
      return;
    }

    // this.form.controls.storeMobile.setValue(
    //   this.form.controls.storeMobile.value?.e164Number
    // );
    // this.restaurantSettings[0].settingValue = `00:${this.returnPrepTime}`;
    // if (this.restaurantSettings[3].settingValue) {
    //   // this.restaurantSettings[3].settingValue = JSON.parse(this.restaurantSettings[3]?.settingValue)?.text
    //   this.timeZone.settingValue = JSON.parse(
    //     this.restaurantSettings[3]?.settingValue
    //   )?.utc[0];
    // }

    const createStorePayload = {
      // ...this.form.value,
      storeName: (this.form.value.storeName || "").trim(),
      appId: this.authService.getUserFromLocalStorage()?.response?.appId // replace 'your-app-id' with your actual appId
    };

    this.isLoading = true;
    // this.setSettingValues();
    this.locationService.createStore(createStorePayload).subscribe(
      (response: any) => {
        if (response.success == 1) {
          this.isLoading = false;
          if (response.success == 1) {
            this.getStoreLocations();
            this.router.navigate(["business-settings/location"]);
            this.modalService.dismissAll();
            setTimeout(() => {
              this.toastr.success(response.message);
            }, 1500);   
            // this.active = 1;
          } else {
            this.toastr.error(response.message);
          }

          // let restaurantSettingsObj = [];
          // restaurantSettingsObj = this.restaurantSettings.map((elem, i) => {
          //   if (i == 3) return this.timeZone;
          //   else return elem;
          // });
          // const payload = {
          //   storeId: response.response.storeId,
          //   // settings: this.settingPayload.concat(restaurantSettingsObj),
          // };
          // this.locationService.updateSettings(payload).subscribe(
          //   (response: any) => {
          //     if (response.success == 1) {
          //       this.router.navigate(["business-settings/location"]);
          //       this.modalService.dismissAll();
          //       // this.active = 1;
          //     } else {
          //       this.toastr.error(response.message);
          //     }
          //   },
          //   (error) => {
          //     const message = this.ApiService.errorMessage(error);
          //     this.toastr.error(message);
          //   }
          // );
        } else {
          this.isLoading = false;
          this.toastr.error(response.message);
        }
      },
      (error) => {
        this.isLoading = false;
        const message = this.ApiService.errorMessage(error);
        this.toastr.error(message);
      }
    );
  }
  @HostListener("document:click", ["$event.target"])
  onClick(target: HTMLElement) {
    if (this.targetElement) {
      if (!this.targetElement.nativeElement.contains(target)) {
        this.isVariableChanged = true;
      }
    }
  }

  Addhover() {
    this.isVariableChanged = !this.isVariableChanged;
  }
  updateTimeZoneId() {
    const timestamp = this.timezoneService.get()().startOf("day").unix();
    this.http
      .get<any>(
        `https://maps.googleapis.com/maps/api/timezone/json?location=${this.lat},${this.lon}&timestamp=${timestamp}&key=${environment.googleApiKey}`,
        {}
      )
      .subscribe((data) => {
        console.log("tiii");
        if (data.status === "OK") {
          this.form.controls.storeTimezone.setValue(data.timeZoneId);
        } else {
          console.log("faill");
        }
      });
  }

  /**
   * Set Setting Values
   */
  setSettingValues() {
    this.settingPayload = this.settings.map((data) => {
      return {
        settingName: data.settingName,
        settingValue:
          data.settingValue == "true"
            ? "Enabled"
            : data.settingValue == "false"
            ? "Disabled"
            : data.settingValue,
      };
    });
  }

  /**
   * Get Timezones
   */
  getTimezones() {
    this.store.dispatch(setLoadingSpinner({ status: true }));
    this.locationService.getTimezones().subscribe(
      (response: any) => {
        this.store.dispatch(setLoadingSpinner({ status: false }));
        if (response) {
          response.forEach((element) => {
            this.timeZones.push(element);
          });
        } else {
          this.toastr.error(response.message);
        }
      },
      (error) => {
        const message = this.ApiService.errorMessage(error);
        this.toastr.error(message);
      }
    );
  }

  /**
   * Set Timezones
   * @param event
   */
  setTimezone(event) {
    console.log("event", JSON.parse(event));

    this.form.controls.storeTimezone.setValue(JSON.parse(event).timezone);
  }

  /**
   * Back to Location List
   */
  goBack() {
    this.router.navigate(["../"], { relativeTo: this.route });
  }

  get f() {
    return this.form;
  }

  get latitude() {
    return parseFloat(this.form.controls.storeLatitude.value);
  }

  get longitude() {
    return parseFloat(this.form.controls.storeLongitude.value);
  }
}
