import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class BreadcrumbService {

  private breadcrumb: BehaviorSubject<[]>;
  facebook = new BehaviorSubject('');
  twitter = new BehaviorSubject('');
  instagram = new BehaviorSubject('');
  linkedIn = new BehaviorSubject('');
  tikTok = new BehaviorSubject('');
  constructor() {
    this.breadcrumb = new BehaviorSubject<[]>([]);
  }

  getValue(): Observable<[]> {
    return this.breadcrumb.asObservable();
  }
  setValue(newValue): void {
    this.breadcrumb.next(newValue);
  }
}
