import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class locationAutoSelectBroadcastService {
    constructor(private cookieService: CookieService) {

    }
    storeId: String = this.cookieService.get("storeId")
    private approvalStageMessage = new BehaviorSubject('');
    currentApprovalStageMessage = this.approvalStageMessage.asObservable();
    private approvalStageMessageTwo = new BehaviorSubject('');
    currentApprovalStageMessageTwo = this.approvalStageMessageTwo.asObservable();

    updateApprovalMessage(message: string) {
        this.approvalStageMessage.next(message)
    }
    updateApprovalMessageTwo(message: string) {
        this.approvalStageMessageTwo.next(message)
    }
}
