
import { createAction, props } from '@ngrx/store';
export const FETCHING_TRANSACTION_SUMMARY = '[sales transactions page] sales transactions summary fetch start';
export const FETCHING_TRANSACTION_SUMMARY_SUCCESS = '[sales transactions page] sales transactions summary fetch success';
export const FETCHING_TRANSACTION_SUMMARY_FAILURE = '[sales transactions page] sales transactions summary fetch failure';

export const FETCHING_TRANSACTION_EMPLOYEE = '[sales transactions page] sales transactions employee fetch start';
export const FETCHING_TRANSACTION_EMPLOYEE_SUCCESS = '[sales transactions page] sales transactions employee fetch success';
export const FETCHING_TRANSACTION_EMPLOYEE_FAILURE = '[sales transactions page] sales transactions employee fetch failure';

export const FETCHING_SALES_TRANSACTION = '[sales transactions page] sales transactions fetch start';
export const FETCHING_SALES_TRANSACTION_SUCCESS = '[sales transactions page] sales transactions fetch success';
export const FETCHING_SALES_TRANSACTION_FAILURE = '[sales transactions page] sales transactions fetch failure';
//for login start action
export const salesTransactionsSummaryFetchStart = createAction(
    FETCHING_TRANSACTION_SUMMARY,
    props<{
        resultEmployeeIds: any,
        resultRevenueIds: any,
        startDate: any,
        endDate: any,
        startTime: any,
        endTime: any,
        startDateDisplay: any,
        endDateDisplay: any,
        merchantId: any,
        storeIdFixed: any,
        paymentStatusId: any,
        pageLoad: Boolean,
        orderSellStatusId: any,
        orderTypeId: any,
        lastStartDate: any,
        lastEndDate: any,
        salesCardNumber: any,
        salesCardType: any,
        serviceCharge: any,
        serviceChargeId: any,
        revenueCenterId: any,
        discount: any,
        discountId: any,
        appliedAs:any
    }>()
);
//for login fail action
export const salesTransactionsSummaryFetchSuccess = createAction(
    FETCHING_TRANSACTION_SUMMARY_SUCCESS,
    props<{ data: any; meta: any }>()
);
//for login failure action
export const salesTransactionsSummaryFetchFailure = createAction(
    FETCHING_TRANSACTION_SUMMARY_FAILURE,
    props<{ loader: Boolean }>()
);
export const salesTransactionEmployeeFetchStart = createAction(
    FETCHING_TRANSACTION_EMPLOYEE
);
export const salesTransactionEmployeeFetchSuccess = createAction(
    FETCHING_TRANSACTION_EMPLOYEE_SUCCESS,
    props<{ employee: any[], activeEmployees: any[] }>()
);
export const salesTransactionEmployeeFetchFailure = createAction(
    FETCHING_TRANSACTION_EMPLOYEE_FAILURE,
    props<{ loader: Boolean }>()
);

export const salesTransactionFetchStart = createAction(
    FETCHING_SALES_TRANSACTION,
    props<{
        resultEmployeeIds: any,
        resultRevenueIds: any,
        startDate: any,
        endDate: any,
        startTime: any,
        endTime: any,
        startDateDisplay: any,
        endDateDisplay: any,
        merchantId: any,
        storeIdFixed: any,
        paymentStatusId: any,
        pageLoad: Boolean,
        orderSellStatusId: any,
        orderTypeId: any,
        page: any,
        items: any,
        resultPaymentsNames: any,
        salesCardNumber: any,
        salesCardType: any,
        resultRevenueIds2: any,
        serviceCharge:Boolean,
        serviceChargeId: any,
        discount:Boolean,
        discountId: any,
        revenueCenterId: any,
        appliedAs:any,
    }>()
)
export const salesTransactionFetchSuccess = createAction(
    FETCHING_SALES_TRANSACTION_SUCCESS,
    props<{ transactions: any, pageMetaData:any }>()
);
export const salesTransactionFetchFailure = createAction(
    FETCHING_SALES_TRANSACTION_FAILURE,
    props<{ loader: Boolean }>()
);
