import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { AlertService } from 'src/app/core/services/AlertService/alert.service';
import { SalesOrderSummaryService } from 'src/app/core/services/sales/orders/orderssummary.service';
import { SalesEmployeeService } from 'src/app/core/services/sales/orders/salesemployee.service';
import { SalesFetchOrderService } from 'src/app/core/services/sales/orders/salesfetchorders.service';
import { AppState } from 'src/app/store/app.state';
import {
  setErrorMessage,
  setLoadingSpinner,
} from '../../../../store/sharedstate/shared.actions';
import { salesOrderFecthEmployeeSuccess, salesOrderFetchStart, salesOrderFetchSuccess, salesOrdersFetchFailure, salesOrdersFetchStart, salesOrdersFetchSuccess } from './salesorder.actions';

@Injectable()
export class SalesOrderEffect {
    employeesData: any = [];
    constructor(
        private actions$: Actions,
        private authService: SalesOrderSummaryService,
        private salesEmployeeService: SalesEmployeeService,
        private salesOrderFetchService: SalesFetchOrderService,
        private toastService: AlertService,
        private store: Store<AppState>,
        private router: Router
    ) { }
    //creating login effect for getting login api reponse
    getSalesOrderSummary$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(salesOrdersFetchStart),
            mergeMap((action) => {
                return this.authService.getSalesOrderSummary(
                    action.merchantId,
                    action.storeId,
                    action.employeeSelected,
                    action.orderTypeSelected,
                    action.orderStatusSelected,
                    action.orderNumber,
                    action.startDateDisplay,
                    action.endDateDisplay,
                    action.startTime,
                    action.endTime,
                    action.lastStartDate,
                    action.lastEndDate
                ).pipe(
                    map((data) => {
                        this.store.dispatch(setErrorMessage({ message: '' }));
                        const response = data;
                        //this.store.dispatch(setLoadingSpinner({ status: false }));
                        //checking user having success reponse of 1 (authentication check)
                        if (data) {
                            return salesOrdersFetchSuccess({ data: response.data, meta: response.meta });
                        }

                    }),
                    catchError((errResp) => {
                        return of(salesOrdersFetchFailure({ loader: false }));
                    })
                );
            })
        );
    });

    //creating login effect for getting login api reponse
    getSalesEmployee$ = createEffect(() => {
        this.employeesData = []
        return this.actions$.pipe(
            ofType(salesOrdersFetchStart),
            mergeMap((action) => {
                return this.salesEmployeeService.getSalesEmployees().pipe(
                    map((data) => {
                        this.store.dispatch(setErrorMessage({ message: '' }));
                        const response = data;
                        //this.store.dispatch(setLoadingSpinner({ status: false }));
                        //checking user having success reponse of 1 (authentication check)
                        if (data.success === 0) {
                            this.toastService.error(response.message);
                        } else {
                            if (response.response.length > 0) {
                                var temp = {}
                                response.response.forEach(element => {
                                    temp = {
                                        id: element.employeeId,
                                        name: element?.employeeFirstName + ' ' + element?.employeeLastName
                                    }
                                    this.employeesData.push(temp);
                                });
                            }

                            return salesOrderFecthEmployeeSuccess({ employee: this.employeesData });
                        }

                    }),
                    catchError((errResp) => {
                        return of(salesOrdersFetchFailure({ loader: false }));
                    })
                );
            })
        );
    });

    //creating effect for getting ordres
    getSalesOrder$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(salesOrderFetchStart),
            mergeMap((action) => {
                return this.salesOrderFetchService.getSalesOrder(
                    action.merchantId,
                    action.storeId,
                    action.employeeSelected,
                    action.orderTypeSelected,
                    action.orderStatusSelected,
                    action.orderNumber,
                    action.startDateDisplay,
                    action.endDateDisplay,
                    action.startTime,
                    action.endTime,
                    action.page,
                    action.items
                ).pipe(
                    map((data) => {
                        this.store.dispatch(setErrorMessage({ message: '' }));
                        const response = data;
                        this.store.dispatch(setLoadingSpinner({ status: false }));
                        //checking user having success reponse of 1

                        if (response.success === 0) {
                            this.toastService.error(response.message);
                        } else {
                            let orders = [];
                            let numberOrdersPaid = [];
                            let childOrders = [];
                            let metaData = response?.metadata || [];
                            let orderProductsChild = [];
                            let orderProducts = [];
                            let totalCollected = 0;
                            let subTotalCollected = 0;
                            this.store.dispatch(salesOrdersFetchStart(action))
                            salesOrderFetchSuccess({ orders: orders, metaData: metaData, childOrders: childOrders });
                            if (metaData && metaData.totalPages > 0) {
                                response.childOrders.forEach(element => {
                                    orderProductsChild = [];
                                    element.seats.forEach(element2 => {
                                        element2.orderProducts.forEach(element3 => {
                                            orderProductsChild.push(element3)
                                            element.product = orderProductsChild;
                                        });
                                    });
                                    if (element.orderSellStatus == 1) {
                                        element.orderStatusName = 'Ordering';
                                    } else if (element.orderSellStatus == 2) {
                                        element.orderStatusName = 'Payment in progress';
                                    } else if (element.orderSellStatus == 3) {
                                        element.orderStatusName = 'Paid';
                                    } else if (element.orderSellStatus == 4) {
                                        element.orderStatusName = 'Completed';
                                    } else if (element.orderSellStatus == 5) {
                                        element.orderStatusName = 'Partially refunded';
                                    } else if (element.orderSellStatus == 6) {
                                        element.orderStatusName = 'Refunded';
                                    } else if (element.orderSellStatus == 7) {
                                        element.orderStatusName = 'Cancelled';
                                    } else {
                                        element.orderStatusName = 'No Data';
                                    }
                                    if (element.serverId || element.serverId !== null) {
                                        element.employeeCompleteName = element?.serverId?.employeeFirstName + " " + element?.serverId?.employeeLastName;
                                        element.employeeCustomId = element.serverId.employeeCustomId;
                                    } else {
                                        element.employeeCompleteName = 'No Employee';
                                    }
                                    if (element.orderType == 1) {
                                        element.orderTypeName = 'Restaurant';
                                    } else if (element.orderType == 2) {
                                        element.orderTypeName = 'Bar';
                                    } else if (element.orderType == 3) {
                                        element.orderTypeName = 'Take Out';
                                    } else if (element.orderType == 4) {
                                        element.orderTypeName = 'App';
                                    } else if (element.orderType == 5) {
                                        element.orderTypeName = 'Delivery';
                                    } else if (element.orderType == 6) {
                                        element.orderTypeName = 'Ahead';
                                    } else if (element.orderType == 7) {
                                        element.orderTypeName = 'Banquet';
                                    } else if (element.orderType == 8) {
                                        element.orderTypeName = 'Online Ordering';
                                    } else if (element.orderType == 10) {
                                        element.orderTypeName = 'Drive Thru';
                                    } else {
                                        element.orderTypeName = 'No Data';
                                    }
                                    childOrders.push(element);
                                });
                                response.response.forEach(element => {
                                    orderProducts = [];
                                    element.seats.forEach(element2 => {
                                        element2.orderProducts.forEach(element3 => {
                                            orderProducts.push(element3)
                                            element.product = orderProducts;
                                            element.enableChild = false;
                                            element.childOrders = this.salesOrderFetchService.findChidlOrders(element.splitOrderList, childOrders)
                                        });
                                    });
                                    if (element.orderSellStatus == 1) {
                                        element.orderStatusName = 'Ordering';
                                    } else if (element.orderSellStatus == 2) {
                                        element.orderStatusName = 'Payment in progress';
                                    } else if (element.orderSellStatus == 3) {
                                        element.orderStatusName = 'Paid';
                                    } else if (element.orderSellStatus == 4) {
                                        element.orderStatusName = 'Completed';
                                    } else if (element.orderSellStatus == 5) {
                                        element.orderStatusName = 'Partially refunded';
                                    } else if (element.orderSellStatus == 6) {
                                        element.orderStatusName = 'Refunded';
                                    } else if (element.orderSellStatus == 7) {
                                        element.orderStatusName = 'Cancelled';
                                    } else {
                                        element.orderStatusName = 'No Data';
                                    }
                                    if (element.serverId || element.serverId !== null) {
                                        element.employeeCompleteName = element?.serverId?.employeeFirstName + " " + element?.serverId?.employeeLastName;
                                        element.employeeCustomId = element.serverId.employeeCustomId;
                                    } else {
                                        element.employeeCompleteName = 'No Employee';
                                    }
                                    if (element.orderType == 1) {
                                        element.orderTypeName = 'Restaurant';
                                    } else if (element.orderType == 2) {
                                        element.orderTypeName = 'Bar';
                                    } else if (element.orderType == 3) {
                                        element.orderTypeName = 'Take Out';
                                    } else if (element.orderType == 4) {
                                        element.orderTypeName = 'App';
                                    } else if (element.orderType == 5) {
                                        element.orderTypeName = 'Delivery';
                                    } else if (element.orderType == 6) {
                                        element.orderTypeName = 'Ahead';
                                    } else if (element.orderType == 7) {
                                        element.orderTypeName = 'Banquet';
                                    } else if (element.orderType == 8) {
                                        element.orderTypeName = 'Online Ordering';
                                    } else if (element.orderType == 10) {
                                        element.orderTypeName = 'Drive Thru';
                                    } else {
                                        element.orderTypeName = 'No Data';
                                    }
                                    orders.push(element);
                                    this.salesOrderFetchService.sortingColumnsByOrderId(orders, 'orderNumber', true);
                                });
                                orders.forEach(order => {
                                    if (order.orderId !== null) {
                                        if (order.orderSellStatus >= 3) {
                                            numberOrdersPaid.push(order);
                                            if (order.orderSellStatus >= 4) {
                                                totalCollected = totalCollected + order.serviceChargeAmount;
                                                subTotalCollected = subTotalCollected + order.subTotalAmount;
                                                order.seats.forEach(seat => {
                                                    seat.orderProducts.forEach(orderProduct => {
                                                        if (orderProduct.productServeStatus != 6) {
                                                            if (orderProduct.productServeStatus == 7) {
                                                                if (orderProduct.refundReason == "QSA") {
                                                                    totalCollected = totalCollected + orderProduct.productCalculatedPrice;
                                                                }
                                                            } else {
                                                                totalCollected = totalCollected + orderProduct.productCalculatedPrice;
                                                                totalCollected = totalCollected + orderProduct.productTaxValue;
                                                            }
                                                        }
                                                    });
                                                });
                                            }
                                        }
                                    }
                                });
                            }
                            return salesOrderFetchSuccess({ orders: orders, metaData: metaData, childOrders: childOrders });
                        }

                    }),
                    catchError((err) => {
                        //stop loading if any error occured
                        this.store.dispatch(setLoadingSpinner({ status: false }));
                        return of(salesOrderFetchSuccess({ orders: [], metaData: [], childOrders: [] }));
                        // return of(salesOrderFetchFailure());
                    })

                );
            })
        );
    });
}
