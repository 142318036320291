import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { AlertService } from 'src/app/core/services/AlertService/alert.service';
import { SalesTransactionService } from 'src/app/core/services/sales/transactions/salestransaction.service.service';
import { AppState } from 'src/app/store/app.state';
import {
  setErrorMessage,
  setLoadingSpinner,
} from '../../../../app/store/sharedstate/shared.actions';
import { salesTransactionEmployeeFetchFailure, salesTransactionEmployeeFetchStart, salesTransactionEmployeeFetchSuccess, salesTransactionFetchFailure, salesTransactionFetchStart, salesTransactionFetchSuccess, salesTransactionsSummaryFetchFailure, salesTransactionsSummaryFetchStart, salesTransactionsSummaryFetchSuccess } from './transaction.actions';

@Injectable()
export class SalesTransactionEffect {
    employeesData: any = [];
    activeEmployees: any = [];
    constructor(
        private actions$: Actions,
        private transactionService: SalesTransactionService,
        private toastService: AlertService,
        private store: Store<AppState>,
        private router: Router
    ) { }
    //creating login effect for getting login api reponse
    getSalesTransactionSummary$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(salesTransactionsSummaryFetchStart),
            mergeMap((action) => {
                return this.transactionService.getSalesTransactionSummary(
                    action.resultEmployeeIds,
                    action.resultRevenueIds,
                    action.startDate,
                    action.endDate,
                    action.startTime,
                    action.endTime,
                    action.startDateDisplay,
                    action.endDateDisplay,
                    action.merchantId,
                    action.storeIdFixed,
                    action.paymentStatusId,
                    action.pageLoad,
                    action.orderSellStatusId,
                    action.orderTypeId,
                    action.lastStartDate,
                    action.lastEndDate,
                    action.salesCardNumber,
                    action.salesCardType,
                    action.serviceCharge,
                    action.serviceChargeId,
                    action.revenueCenterId,
                    action.discount,
                    action.discountId,
                    action.appliedAs
                ).pipe(
                    map((data) => {
                        this.store.dispatch(setErrorMessage({ message: '' }));
                        const response = data;
                        //this.store.dispatch(setLoadingSpinner({ status: false }));
                        //checking user having success reponse of 1 (authentication check)
                        if (data) {
                            return salesTransactionsSummaryFetchSuccess({ data: response.data, meta: response.meta });
                        }

                    }),
                    catchError((errResp) => {
                        //check api call fail due to internet connection loss
                        // if (errResp?.message && errResp?.message.includes('Http failure response for /api/user/login: 0 Unknown Error')) {
                        //     this.toastService.error("Please check the internet connection");
                        // }
                        //stop loading if any error occured
                        //this.store.dispatch(setLoadingSpinner({ status: false }));
                        return of(salesTransactionsSummaryFetchFailure({ loader: false }));
                    })
                );
            })
        );
    });
    //creating login effect for getting login api reponse
    getSalesTransactionEmployee$ = createEffect(() => {
        this.employeesData = []
        this.activeEmployees = [];
        return this.actions$.pipe(
            ofType(salesTransactionEmployeeFetchStart),
            mergeMap((action) => {
                return this.transactionService.getSalesTransactionsEmployee().pipe(
                    map((data) => {
                        this.store.dispatch(setErrorMessage({ message: '' }));
                        const response = data;
                        //this.store.dispatch(setLoadingSpinner({ status: false }));
                        //checking user having success reponse of 1 (authentication check)
                        if (data.success === 0) {
                            this.toastService.error(response.message);
                        } else {
                            if (response.response.length > 0) {
                                var temp = {}
                                response.response.forEach(element => {
                                    if (element.status === 1) {
                                        temp = {
                                            id: element.employeeId,
                                            name:
                                                element?.employeeFirstName +
                                                " " +
                                                element?.employeeLastName
                                        };
                                        this.activeEmployees.push(temp);
                                    }
                                    temp = {
                                        id: element.employeeId,
                                        name:
                                            element?.employeeFirstName +
                                            " " +
                                            element?.employeeLastName
                                    };
                                    this.employeesData.push(temp);
                                });
                            }

                            return salesTransactionEmployeeFetchSuccess({ employee: this.employeesData, activeEmployees: this.activeEmployees });
                        }

                    }),
                    catchError((errResp) => {
                        //check api call fail due to internet connection loss
                        // if (errResp?.message && errResp?.message.includes('Http failure response for /api/user/login: 0 Unknown Error')) {
                        //     this.toastService.error("Please check the internet connection");
                        // }
                        //stop loading if any error occured
                        //this.store.dispatch(setLoadingSpinner({ status: false }));
                        return of(salesTransactionEmployeeFetchFailure({ loader: false }));
                    })
                );
            })
        );
    });

    getSalesTransactions$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(salesTransactionFetchStart),
            mergeMap((action) => {
                return this.transactionService.getSalesTransactions(
                    action.resultEmployeeIds,
                    action.resultRevenueIds,
                    action.startDate,
                    action.endDate,
                    action.startTime,
                    action.endTime,
                    action.startDateDisplay,
                    action.endDateDisplay,
                    action.merchantId,
                    action.storeIdFixed,
                    action.paymentStatusId,
                    action.pageLoad,
                    action.orderSellStatusId,
                    action.orderTypeId,
                    action.page,
                    action.items,
                    action.resultPaymentsNames,
                    action.salesCardNumber,
                    action.salesCardType,
                    action.resultRevenueIds2,
                    action.serviceCharge,
                    action.serviceChargeId,
                    action.discount,
                    action.discountId,
                    action.revenueCenterId,
                    action.appliedAs
                ).pipe(
                    map((data) => {
                        let offset = 0;
                        let payments = [];
                        let paymentByStatus = [];
                        let numberPaymentsPaid = [];
                        let subTotalCollected = 0;
                        let totalCollected = 0;
                        let paidSuccesfully = 0;
                        let transactionsSize = 0;
                        let numberCountPayments = 0;
                        let isOpen = true;
                        this.store.dispatch(setErrorMessage({ message: '' }));
                        const response = data;
                        //this.store.dispatch(setLoadingSpinner({ status: false }));
                        //checking user having success reponse of 1 (authentication check)
                        if (response.success == 0) {
                            this.store.dispatch(setLoadingSpinner({ status: false }));
                            this.toastService.error(response.message);
                        } else {
                            if (response.metadata.ordersTotal > 0) {
                                response.response.forEach(payment => {
                                    if (
                                        payment.transactionIds.length > 0 &&
                                        payment.orderId !== null
                                    ) {
                                        payments.push(payment);
                                        this.transactionService.sortingColumnsByNumber(
                                            payments,
                                            "orderId",
                                            "orderNumber",
                                            true
                                        );
                                    }
                                    if (
                                        payment.paymentStatus == 1 ||
                                        payment.paymentStatus == 2
                                    ) {
                                        isOpen = false;
                                    }
                                    if (
                                        payment.paymentStatus !== 1 ||
                                        payment.paymentStatus !== 2
                                    ) {
                                        paymentByStatus.push(payment);
                                    }

                                    if (payment.orderId) {
                                        if (payment.orderId.orderSellStatus >= 4) {
                                            numberPaymentsPaid.push(payment);
                                            if (payment.paymentStatus !== 5) {
                                                // this.totalCollected = this.totalCollected + payment.paymentTipAmount;
                                                totalCollected =
                                                    totalCollected +
                                                    payment.orderId
                                                        .serviceChargeAmount;
                                            }
                                            subTotalCollected =
                                                subTotalCollected +
                                                payment.orderId.subTotalAmount;
                                            payment.orderId.seats.forEach((seat) => {
                                                seat.orderProducts.forEach(
                                                    (orderProduct: any) => {
                                                        if (
                                                            orderProduct.productServeStatus !==
                                                            6
                                                        ) {
                                                            if (
                                                                orderProduct.productServeStatus ===
                                                                7
                                                            ) {
                                                                if (
                                                                    orderProduct.refundReason ===
                                                                    "QSA"
                                                                ) {
                                                                    totalCollected =
                                                                        totalCollected +
                                                                        orderProduct.productCalculatedPrice;

                                                                }
                                                            } else {
                                                                totalCollected =
                                                                    totalCollected +
                                                                    orderProduct.productCalculatedPrice;
                                                                totalCollected = totalCollected +
                                                                    orderProduct.productTaxValue;
                                                            }
                                                        }
                                                    }
                                                );
                                            });
                                        }
                                    }
                                });
                                // TODO: log into the payment types
                                // console.log( 'this.payemnst' , this.payments);
                                paidSuccesfully = paymentByStatus.length;
                                transactionsSize = payments.length === 0 ? 0 : payments.length / 2;
                                offset = 100;
                                numberCountPayments = response.metadata.ordersTotal;
                                //this.getPaymentsFunction();
                                //this.fetchSummary();
                            } else {
                                //jQuery(".allpageLoader").css("display", "none");
                                transactionsSize = 0;
                            }
                            this.store.dispatch(setLoadingSpinner({ status: false }));
                            return salesTransactionFetchSuccess({ transactions: payments, pageMetaData: response.metadata });
                        }

                    }),
                    catchError((errResp) => {
                        this.store.dispatch(setLoadingSpinner({ status: false }));
                        //check api call fail due to internet connection loss
                        // if (errResp?.message && errResp?.message.includes('Http failure response for /api/user/login: 0 Unknown Error')) {
                        //     this.toastService.error("Please check the internet connection");
                        // }
                        //stop loading if any error occured
                        //this.store.dispatch(setLoadingSpinner({ status: false }));
                        return of(salesTransactionFetchFailure({ loader: false }));
                    })
                );
            })
        );
    });
}
