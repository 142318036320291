import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthResponseService {
  private AuthResponseService: BehaviorSubject<any>;

  constructor() {
  this.AuthResponseService = new BehaviorSubject<any>("");
  }
  getValue(): Observable<any> {
    return this.AuthResponseService.asObservable();
  }

  setValue(newValue: any): void {
    this.AuthResponseService.next(newValue);
  }

}