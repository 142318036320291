import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';
import { crossLinkLoginStart, crossLinkLoginSuccess, loginFailure, loginSuccess } from 'src/app/account/auth/state/auth.actions';
import { AlertService } from 'src/app/core/services/AlertService/alert.service';
import { AuthAlertService } from 'src/app/core/services/AlertService/auth-alert.service';
import { AuthenticationService } from 'src/app/core/services/auth.service';
import { AuthCookieService } from 'src/app/core/services/authcookies.service';
import { EventService } from 'src/app/core/services/event.service';
import { AppState } from 'src/app/store/app.state';
import { getLoading } from 'src/app/store/sharedstate/shared.selector';

@Component({
  selector: 'app-empty-screen',
  templateUrl: './empty-screen.component.html',
  styleUrls: ['./empty-screen.component.scss']
})
export class EmptyScreenComponent implements OnInit {
  crossLinkLoader: Boolean = false;
  returnUrl: any = null
  token: any = null;
  showLoader: Observable<Boolean>
  constructor(
    private eventService: EventService,
    private route: ActivatedRoute,
    private store: Store<AppState>,
    private authService: AuthenticationService,
    private authCookieService: AuthCookieService,
    private router: Router,
    private authAlertService: AuthAlertService,
    private toastService: AlertService,
    private cookieService: CookieService
  ) { }
  storageToken:any = this.cookieService.get('token')

  ngOnInit(): void {
    this.showLoader = this.store.select(getLoading);
    this.token = this.route.snapshot.queryParamMap.get('token');
    this.returnUrl = this.route.snapshot.queryParamMap.get('returnUrl');
    if (this.token && this.returnUrl) {
      if(this.token === this.storageToken) {
        this.router.navigate([this.returnUrl])
      }else {
        this.store.dispatch(crossLinkLoginStart({ token: this.token, returnUrl: this.returnUrl }));
      }
    }
  }
  async crossLinkLogin() {
    this.crossLinkLoader = true;
    this.authService.crossLinkLogin(this.token).subscribe(data => {
      const user = data;
      //checking user having success reponse of 1 (authentication check)
      if (data.success == 1) {
        //checking user have subscription or not
        if (data.response.subscription._id !== undefined) {
          /*
            Adding cookies for Auth response
          */
          this.authCookieService.addCookies(data)
          //set userdata in localstorage
          this.authService.setUserInLocalStorage(data)
          this.toastService.success(data.message)
          //set user in state
          loginSuccess({ user, redirect: true, redirect2fa: false });
          this.router.navigate(['/restaurant/general'])
          this.crossLinkLoader = false;
        } else {
          this.toastService.error("Your account doesn't have a subscription. You need to activate it on the eatOS app")
          //stop loading if any error occured
          loginFailure({ loader: false })
        }
      } else {
        this.toastService.error(data.message);
        this.router.navigate(['/account/login']);
        this.crossLinkLoader = false;
        return loginFailure({ loader: false })
      }
    }, (error) => {
      this.router.navigate(['/account/login'])
      this.authAlertService.error(error);
      this.crossLinkLoader = false;
    });
  }

}
