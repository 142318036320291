import { AbstractControl, FormControl } from "@angular/forms";

export function spaceValidators (control: AbstractControl) {
    if (control && control.value && !control.value.replace(/\s/g,'').length) {
        control.setValue('');
        console.log(control.value);
        return {required:true}

    }else{
        return null;
    }
}

export function lettersandSpace (control: AbstractControl) {
    if (control && control.value && !control.value.replace(/\s/g,'').length) {
        control.setValue('');
        console.log(control.value);
        return {required:true}

    }else{
        return null;
    }
}

export function noWhiteSpaceValidator(control: FormControl) {
  const value = String(control.value) || '';
  if(value.length) {
    if(!value.trim().length) {
      return  { whiteSpace: true }
    }
    return null
  }
  return null
}
export function noSpecialCharValidator(control: FormControl) {
  const value = control.value || '';
  const regExp: RegExp = /^[a-zA-Z0-9 ]*$/;

  if(value.length) {
    if(!regExp.test(value)) {
      return  { noSpecialChar: true }
    }
    return null
  }
  return null
}
export function noNumbersAndSpecialCharValidator(control: FormControl) {
  const value = control.value || '';
  const regExp: RegExp = /^[a-zA-Z ]*$/;

  if(value.length) {
    if(!regExp.test(value)) {
      return  { noNumbersAndSpecialChar: true }
    }
    return null
  }
  return null
}
