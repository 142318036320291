import { createFeatureSelector, createSelector } from '@ngrx/store';
import { LocationState } from './location.state';
export const LOCATION_STATE_NAME = 'locations';

const getLocationState = createFeatureSelector<LocationState>(LOCATION_STATE_NAME);


//selector for getting userAuthentication state 
export const getStoreLocations = createSelector(getLocationState, (state) => {
  return state.locations
});

//selector for getting selected store location
export const getSelectedStoreLocation = createSelector(getLocationState, (state) => {
  return state.selected
});

//show location 
export const getShowLocation = createSelector(getLocationState, (state) => {
  return state.show
});