import { ErrorHandler, Injectable } from '@angular/core';
import * as Sentry from '@sentry/angular-ivy';

@Injectable({
  providedIn: 'root',
})
export class CustomErrorHandler implements ErrorHandler {
  private sentryErrorHandler: ErrorHandler;

  constructor() {
    this.sentryErrorHandler = Sentry.createErrorHandler({
      showDialog: false,
    });
  }

  handleError(error: any): void {
    const message: string = error?.message || '';
    const stack: string = error?.stack || '';

    // Don't report known MetisMenu library error to Sentry
    if (message.includes(`Cannot read properties of null (reading 'closest')`) && stack.includes('.toggle')) {
      console.warn("Handled known error: MetisMenu.toggle causing 'Cannot read properties of null (reading 'closest')'");
    } else {
      // Delegate other errors to Sentry's error handler
      this.sentryErrorHandler.handleError(error);
    }
  }
}
