import * as CryptoJS from 'crypto-js';
import { Injectable } from "@angular/core";
import { CookieService } from "ngx-cookie-service";

@Injectable({
    providedIn: "root",
})

export class CryptoService {

    constructor(private cookieService: CookieService){}
  
    decrypt = (encryptedMessage: string): string => {
        try{

            const merchantId = this.cookieService.get("merchantId")
            const storeId = this.cookieService.get("mainStoreId")

            const ENCRYPTION_KEY = merchantId.substring(0, 16)
            const IV = storeId.substring(0, 16)

            const key = CryptoJS.enc.Utf8.parse(ENCRYPTION_KEY);
            const iv = CryptoJS.enc.Utf8.parse(IV);
            const encryptedHexStr = CryptoJS.enc.Hex.parse(encryptedMessage);
            const encryptedBase64Str = CryptoJS.enc.Base64.stringify(encryptedHexStr);
        
            const decrypted = CryptoJS.AES.decrypt(encryptedBase64Str, key, {
              iv: iv,
              mode: CryptoJS.mode.CBC,
              padding: CryptoJS.pad.Pkcs7
            });
        
            return CryptoJS.enc.Utf8.stringify(decrypted);

        }catch(e){
            console.info('CryptoService Error', e)
        }
    }

}
