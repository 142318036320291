import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { NgForm } from "@angular/forms";
import {
  NgbActiveModal,
  NgbModal,
  NgbModalConfig,
} from "@ng-bootstrap/ng-bootstrap";
import { NgOtpInputConfig } from "ng-otp-input";
import { CookieService } from "ngx-cookie-service";
import { AlertService } from "src/app/core/services/AlertService/alert.service";
import { AuthenticationService } from "src/app/core/services/auth.service";
import { PermissionService } from "src/app/core/services/permission.service";
import { ProfileService } from "src/app/core/services/profile-service/profile.service";
import { ApiService } from "src/app/services/api";

@Component({
  selector: "app-change-pin",
  templateUrl: "./change-pin.component.html",
  styleUrls: ["./change-pin.component.scss"],
  providers: [NgbModalConfig, NgbModal],
})
export class ChangePinComponent implements OnInit {
  @ViewChild("ngPinInput") public ngPinInputRef: any;
  // @ViewChild('inputFields') inputFields!: ElementRef[];

  pin1: any;
  pin2: any;
  pin3: any;
  pin4: any;
  confirmPin1: any;
  confirmPin2: any;
  confirmPin3: any;
  confirmPin4: any;

  isLoading = false;

  userData = null;

  isShowPin = false
  isShowConfirmPin = false

  config: NgOtpInputConfig = {
    length: 4,
    allowNumbersOnly: true,

    inputStyles: {
      width: "60px",
      height: "50px",
      border: "1px solid gray",
      "font-size": "15px",
    },
    containerStyles: {
      "max-width": "100%",
    },
  };
  confirmconfig: NgOtpInputConfig = {
    length: 4,
    allowNumbersOnly: true,

    inputStyles: {
      width: "60px",
      height: "50px",
      border: "1px solid gray",
      "font-size": "15px",
    },
    containerStyles: {
      "max-width": "100%",
    },
  };

  constructor(
    private activeModal: NgbActiveModal,
    private cookieService: CookieService,
    private toastr: AlertService,
    private profileService: ProfileService,
    private authService: AuthenticationService,
    private ApiService: ApiService,
    private permissionService: PermissionService
  ) {}

  ngAfterViewInit(){
    if(!this.permissionService.canAccess('ChangePin', 3)){
      this.ngPinInputRef.otpForm.disable();
    }
  }

  ngOnInit(): void {
    this.userData = this.authService.getUserFromLocalStorage();
    // Focus on the first input field on component initialization
    // setTimeout(() => this.focusInputField(0), 0);
  }

  closeModal() {
    this.activeModal.close();
  }

  showPin() {
    this.isShowPin = !this.isShowPin;
  }
  NewpinCode: string = "";
  // confirmpinCode:string = "";


  showConfirmPin() {
    this.isShowConfirmPin = !this.isShowConfirmPin;
  }

  validatePinInputOnChange(event){
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && ((charCode < 48 || charCode > 57)) && (charCode < 96 || charCode > 105)) {
      return false;
    }
    return true;
  }

  // pin: any;
  // onOtpChange(event: any) {
  //   this.pin = event;
  //   console.log("CHANGE_PIN", this.pin)
  // }

  // onPinInput(event: any, index: number) {
  //   const inputElement = event.target;
  //   const inputValue = inputElement.value;

  //   if (inputValue.length === 1 && index < this.inputFields.length - 1) {
  //     // Move focus to the next input field
  //     this.focusInputField(index + 1);
  //   } else if (inputValue.length === 0 && index > 0) {
  //     // Move focus to the previous input field if the current input is cleared
  //     this.focusInputField(index - 1);
  //   }
  // }

  // focusInputField(index: number) {
  //   const inputField = this.inputFields[index].nativeElement;
  //   inputField.focus();
  //   inputField.select();
  // }

  // on save form

  onOtpChange(event: string) {
    // this.pinErrorMsg = false;
    this.NewpinCode = event;
  }
  // onOtpConfirmChange(event: string){
  //   this.pinErrorMsg = false;
  //   this.confirmpinCode = event;
  // }
  validatePin(){
    // if(this.NewpinCode !== this.confirmpinCode){
      if(this.NewpinCode.length != 4){
      return false
    }else{
      return true
    }
  }

  // pinErrorMsg = false;
  onSubmit(form: NgForm) {
    // const storeId = this.cookieService.get("storeId");
    // if (storeId === "*") {
    //   this.toastr.clear();
    //   this.toastr.error("Please select a location first.");
    //   return;
    // }
    const matchpin = this.validatePin()
    const payload: any = {
      employeePin: this.NewpinCode,
      employeeId: this.userData.response.employeeId,
    };

    this.isLoading = true;
    this.profileService.changePin(payload).subscribe(
      (response: any) => {
        if (response.success) {
          this.isLoading = false;
          this.toastr.clear();
          this.toastr.success("PIN changed successfully.");

          this.closeModal();
        } else {
          this.isLoading = false;
          this.toastr.clear();
          this.toastr.error(response.message);
        }
      },
      (error) => {
        const message = this.ApiService.errorMessage(error)
        this.isLoading = false;
        this.toastr.clear();
        this.toastr.error(message);
      }
    );
    // if(!matchpin){
    //   this.pinErrorMsg = true;
    //   // this.toastr.error('PIN must be the same as new PIN')
    // }else{
    //   // this.pinErrorMsg = false;
    //   // const payload: any = {
    //   //   employeePin: this.NewpinCode,
    //   //   employeeId: this.userData.response.employeeId,
    //   // };

    //   // this.pinErrorMsg = false;
    //   // this.isLoading = true;
    //   // this.profileService.changePin(payload).subscribe(
    //   //   (response: any) => {
    //   //     if (response.success) {
    //   //       this.pinErrorMsg = false;
    //   //       this.isLoading = false;
    //   //       this.toastr.clear();
    //   //       this.toastr.success("PIN changed successfully.");

    //   //       this.closeModal();
    //   //     } else {
    //   //       this.pinErrorMsg = false;
    //   //       this.isLoading = false;
    //   //       this.toastr.clear();
    //   //       this.toastr.error(response.message);
    //   //     }
    //   //   },
    //   //   (error) => {
    //   //     const message = this.ApiService.errorMessage(error)
    //   //     this.pinErrorMsg = false;
    //   //     this.isLoading = false;
    //   //     this.toastr.clear();
    //   //     this.toastr.error(message);
    //   //   }
    //   // );
    // }

  }
}
