

<div id="layout-wrapper">
  <ng-template #sidebarCont let-offcanvas>
    <div class="offcanvas-body sidebar-canvas p-md-3">
      <div class="" id="side-Bar-Open" *ngIf="SetSidebar" >
        <app-sidebar
        [isCondensed]="isCondensed"
        [isMenuResetOnTestMode]="isMenuResetOnTestMode"
        (dataEvent)="receiveData($event)"
        [SendCanavaDetails]="openSidebarCanva"
        (SubmenuDetails)="receiveDataForSubmenu($event)"
      ></app-sidebar>
      </div>
      <div class="" *ngIf="SubmenuContant === 'openSubmenu'">
        <app-submenuhome class="side-menu-two" (SubmenuDetails)="receiveDataForSubmenu($event)"></app-submenuhome>
      </div>
    </div>
  </ng-template>
  <app-topbar
    (settingsButtonClicked)="onSettingsButtonClicked()"
    (mobileMenuButtonClicked)="onToggleMobileMenu()"
    (dataEvent)="OpenSidebar($event,sidebarCont)"
  >
  </app-topbar>
  <div *ngIf="checkBoxValue == 'true'" class="highlight-test-mode-container">
    <div class="horizontal-line"></div>
    <div class="content">
      <div class="trapezoid h-auto">
        <!-- YOU'RE IN TEST MODE -->
        <!-- Training Mode Activated - No Live Transaction -->
        <p class="m-0 trapezoid-text">
          {{ "Training mode active - all transactions will be demo, no live transactions allowed." | translate | async }}
        </p>
      </div>
    </div>
  </div>
  <div class="" *ngIf="!SetSidebar">
    <app-sidebar
      [isCondensed]="isCondensed"
      [isMenuResetOnTestMode]="isMenuResetOnTestMode"
      (dataEvent)="receiveData($event)"
      [SendCanavaDetails]="openSidebarCanva"
      (SubmenuDetails)="receiveDataForSubmenu($event)"
    ></app-sidebar>
  </div>
  <!-- ##LOADING *ngIf="loading | async" -->
  <!-- <div *ngIf="loading | async" id="preloader2">
      <div class="allpageLoader2">
        <div class="h-100 w-100 d-flex justify-content-center align-items-center  loadingOption"
          style="background-color: #FBFBFB">
          <img alt="img" src="../../../../assets/images/loaders/eatos_loading_0-Black.gif" width="190" height="190">
        </div>
      </div>
    </div> -->
  <div class="d-flex align-items-baseline Dashboard-main {{ setFootterClass }} {{HomeTooltripClass}}">
    <app-submenuhome class="side-menu-two side-menu-res"></app-submenuhome>
    <div class="main-content {{SetFooter}}">
      <div class="page-content ps-relative">
        <!-- <div> -->
          <app-alert></app-alert>
        <!-- </div> -->
        <!-- <app-alert></app-alert> -->
        <!-- <div class="{{HomeTooltripClass}}"> -->
          <app-page-title
            [title]="'Dashboard'"
            [layout]="false"
            (changed)="changeStore($event)"
          ></app-page-title>
        <!-- </div> -->
        <!-- content -->
        <router-outlet></router-outlet>
      </div>
      <!-- footer -->
      <app-footer></app-footer>
    </div>
  </div>

  <div class="main-content-overlay" *ngIf="loading | async" id="preloader2">
    <!-- add any loading spinner or message here -->

    <img
      alt="img"
      src="../../../../assets/images/loaders/eatos_loading_0-Black.gif"
      width="190"
      height="190"
    />
  </div>
</div>

<app-rightsidebar></app-rightsidebar>
