import { ErrorHandler, NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";

import { SimplebarAngularModule } from "simplebar-angular";
import {
  NgbDropdownModule,
  NgbTooltipModule,
} from "@ng-bootstrap/ng-bootstrap";
import { ClickOutsideModule } from "ng-click-outside";
import { UIModule } from "../shared/ui/ui.module";
import { LayoutComponent } from "./layout.component";
import { SidebarComponent } from "./sidebar/sidebar.component";
import { TopbarComponent } from "./topbar/topbar.component";
import { FooterComponent } from "./footer/footer.component";
import { RightsidebarComponent } from "./rightsidebar/rightsidebar.component";
import { HorizontalComponent } from "./horizontal/horizontal.component";
import { VerticalComponent } from "./vertical/vertical.component";
import { HorizontaltopbarComponent } from "./horizontaltopbar/horizontaltopbar.component";
import { LanguageService } from "../core/services/language.service";
import { TranslateModule } from "@ngx-translate/core";
import { NgxSkeletonLoaderModule } from "ngx-skeleton-loader";
import { SubItemComponent } from "./sidebar/sub-item/sub-item.component";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { DragAndDropManagerService } from "../core/services/drag-and-drop/drag-and-drop-manager.service";
import { MatCardModule } from "@angular/material/card";
import { ClipboardModule } from "ngx-clipboard";
import { UiSwitchModule } from "ngx-ui-switch";
import {
  NgbNavModule,
  NgbModalModule,
  NgbCollapseModule,
  NgbAccordionModule,
} from "@ng-bootstrap/ng-bootstrap";
import { SubmenuhomeComponent } from "../pages/menu/submenuhome/submenuhome.component";
import { SearchbarComponent } from "../pages/starter/searchbar/searchbar.component";
import { ReactiveFormsModule } from "@angular/forms";
import { CoPilotChatComponent } from './co-pilot-chat/co-pilot-chat.component';
import { DashboardRTLComponent } from "./dashboard-rtl/dashboardrtl.component";
// import { TopbarrtlComponent } from "../components/dashboard-rtl/topbarrtl/topbarrtl.component";
//import { KnownErrorHandler } from '../core/services/knownErrorHandler.service';
import { CoreModule } from "../core/core.module";

@NgModule({
  // tslint:disable-next-line: max-line-length
  declarations: [
    LayoutComponent,
    SidebarComponent,
    TopbarComponent,
    FooterComponent,
    RightsidebarComponent,
    HorizontalComponent,
    VerticalComponent,
    HorizontaltopbarComponent,
    SubItemComponent,
    SubmenuhomeComponent,
    SearchbarComponent,
    CoPilotChatComponent,
    DashboardRTLComponent,
  ],
  imports: [
    CommonModule,
    // TranslateModule,
    CoreModule,
    RouterModule,
    NgbDropdownModule,
    ClickOutsideModule,
    UIModule,
    SimplebarAngularModule,
    NgxSkeletonLoaderModule,
    DragDropModule,
    MatCardModule,
    ClipboardModule,
    UiSwitchModule,
    NgbTooltipModule,
    NgbNavModule,
    NgbModalModule,
    NgbCollapseModule,
    NgbAccordionModule,
    ReactiveFormsModule,
  ],
  providers: [
    //{ provide: ErrorHandler, useClass: KnownErrorHandler },
    LanguageService,
    DragAndDropManagerService,
  ],
})
export class LayoutsModule {}
